import * as React from 'react';
import {Component, Fragment} from 'react';
import classNames from 'classnames';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import * as EmailValidator from 'email-validator';

import { t } from '../../i18n';
import './LostPassword.scss';
import { saveLastLoginEmail, getLanguageCode } from '../../local-storage/local-storage';
import uuidv4 from 'uuid/v4';
import { setLostPasswordEmail } from '../../wallet-server-api/wallet-server-api';
import { getAjaxErrorMessage } from "../..//wallet-server-api/ajax-common";
import { is401Error } from "../../wallet-server-api/ajax-common";
import { Action, Dispatch } from "redux";
import { startAjaxCall, succAjaxCall, failedAjaxCall } from '../../redux-store/ajax-call/actions';
import { NoticeDialog } from '../NoticeDialog';
import {connect, batch, ReactReduxContext} from 'react-redux';
import { Header,  } from '../header/HeaderView2';
import { stripNonPrintableChars } from '../../util/Util';
import { Paper } from '@material-ui/core';
import { WalletAppState } from '../../redux-store';
import { Copywright } from '../copywright/Copywright';
import { gotoLoginPage } from '../../shopping/history/shopping-history';
import { doAJAXCall } from '../../shopping/common/ajax-call';
import { loadExternContatnsIfNecessary } from '../../external-constants/extern-constants';
import { BaseUnishellPageState, renderInitUnishellPage } from '../../unishell-page-common/unishell-page-common';

interface LostPasswordProps {
    email: string;
}

interface LostPasswordState extends BaseUnishellPageState{
    email: string;
    emailError?: string;
}

class LostPasswordView extends Component<LostPasswordProps, LostPasswordState> {
    private noticeDialog: NoticeDialog|undefined;
    constructor(props: LostPasswordProps){
        super(props);
        this.state = {email: props.email, pageInitState:'PAGE_INIT'}
    }

    setEmail = (value:string)=>{
        let email = stripNonPrintableChars(value).trim().substring(0,40);
        this.setState({email, emailError: undefined})
    }

    sendEmail = (dispatch: Dispatch, email:string)=>{
        const emailError = EmailValidator.validate(this.state.email)?undefined:t('电邮地址错误')+` [${this.state.email}]`;
        if(emailError){
            this.setState({emailError});
        }else{
            this.setState({emailError}, ()=>{
                const whatIsFor = t(`发送新密码链接`);
                const uuid = uuidv4();
                setLostPasswordEmail({email})
                .then(sth=>{
                    dispatch(succAjaxCall(whatIsFor, uuid)); 
                    this.noticeDialog!.show(whatIsFor,t('请点击电邮里重设密码链接，设置新密码后，重新登录'), ()=>{});       
                }).catch(error=>{
                    dispatch(failedAjaxCall(whatIsFor, uuid, getAjaxErrorMessage(error)));
                    if(this.noticeDialog){
                        this.noticeDialog!.show(whatIsFor, getAjaxErrorMessage(error), ()=>{
                            if(is401Error(error)){
                                gotoLoginPage();
                            } else {
                                // do nothing
                            }       
                        });
                    }else{
                        alert(getAjaxErrorMessage(error));
                        if(is401Error(error)){
                            gotoLoginPage();
                        }
                    }
                })
                dispatch(startAjaxCall(whatIsFor, uuid));
            });
        }

    }

    render(){
        if(this.state.pageInitState === 'PAGE_INIT'){
            return <Fragment>
                {
                    renderInitUnishellPage({
                        title:t('忘记密码'),
                        className: 'lost-password-page',
                        message: t('初始化...')    
                    })
                }
            </Fragment>
        }

        return (
            <ReactReduxContext.Consumer>
            { ({store})=>{
                    const backToLogin = ()=>{
                        if(this.state.email && !this.state.emailError){
                            saveLastLoginEmail(this.state.email);
                        }
                        gotoLoginPage();
                    }

                    return <div>
                                <Header title={t('忘记密码')+'?'}/>
                                <div className={'lost-password-page app-page '+getLanguageCode()}>
                                        <Paper className='instruction'>
                                            <p>1. {t('请填写注册用电子邮箱，点击 发送邮件')}.</p>
                                            <p>2. {t('系统将发送重设密码链接到您的电子邮箱')}.</p>
                                        </Paper>
                                        <div className="input-wrapper">
                                                <TextField className='login-input' name='unishell-email' label={t('注册用电子邮箱')} margin='normal' 
                                                        value={this.state.email} autoComplete='on'
                                                        onChange={(evt)=>{ this.setEmail(evt.target.value)}}/>
                                        </div>
                                        <div className="input-error-message">
                                        {this.state.emailError}
                                        </div>
                                        <Button variant="contained" color="primary"
                                                    onClick={()=>{this.sendEmail(store.dispatch, this.state.email||'')}}>{t('发送邮件')}</Button>
                                        <Button variant="contained" className='secondary-btn'
                                                    onClick={backToLogin}>{t('返回登录')}</Button>
                                        <Copywright/>
                                        <NoticeDialog ref={e=>this.noticeDialog=e!} open={false}></NoticeDialog>
                                
                                </div>
                           </div>
                }
            }
            </ReactReduxContext.Consumer>
            
        )
    }

    async componentDidMount(){
        await loadExternContatnsIfNecessary();
        this.setState({pageInitState:'PAGE_LOAD_SUCCESS'});
    }
}

export const LostPassword = connect(
    (state:WalletAppState)=>{
        return {email: state.pagePath.parms!.email||''}
    }
)(LostPasswordView);