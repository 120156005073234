import { getLanguageCode } from "../../local-storage/local-storage";

export const SET_LANGUAGE = 'SET_LANGUAGE';

export interface LanguageRecord {
    langCode: string;
}

interface SetLanguageAction {
    type: typeof SET_LANGUAGE,
    langCode: string;    
}

export function setLanguageRecord(langCode: string):SetLanguageAction {
    return {
        type:SET_LANGUAGE,
        langCode
    }
}

const init:LanguageRecord = {langCode:getLanguageCode()}

export function languageReducer(state = init, action:SetLanguageAction):LanguageRecord {
    switch(action.type){
        case SET_LANGUAGE: return {langCode: action.langCode}
        default: return state;
    }
}
