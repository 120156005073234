import { Checkbox } from '@material-ui/core';
import * as React from 'react';
import {Component, Fragment} from 'react';
import { debug } from '../../globals';
import { t } from '../../i18n';
import { PropertyListDataItem, PropertyListWidget } from '../../widget/property-list/property-list';
import { ShoppingProductTableData, SimpleShoppingProductField } from '../ajax-data/ajax-data-types';
import { BaseProductDetail, BaseProductDetailProps, BaseProductDetailState } from '../product-detail/base-product-detail';

import './health-advice-product-detail.scss'

interface HealthAdviceProductDetailProps extends BaseProductDetailProps {

}

export interface KeyIngredient{
    name: string, 
    cName:string,
    cpName:string,
    amount: string;
}

interface KeyIngredientFlags extends KeyIngredient{
    checked?: boolean
}

interface HealthAdviceProductDetailState extends BaseProductDetailState{
    keyIngredientFlags: KeyIngredientFlags[]
}

export class HealthAdviceProductDetail extends BaseProductDetail<HealthAdviceProductDetailProps, HealthAdviceProductDetailState>{
    constructor(props: BaseProductDetailProps){
        super(props);
        debug(`HealthAdviceProductDetail. state=`+JSON.stringify(this.state));
    }

    /** @override */
    getInitialState(props: HealthAdviceProductDetailProps): HealthAdviceProductDetailState {
        let keyIngredientFlags: KeyIngredientFlags[] = 
                                    (props.product.ingredients||[]).map(x=>({
                                                                                name:x.name, 
                                                                                cName: x.cName, 
                                                                                cpName: x.cpName,
                                                                                amount: x.amount, checked: false
                                                                            })
                                                                        )

        return {keyIngredientFlags, showImageOverlay: false}    
    }

    /** @override */
    renderShoppingProductTableData(tab:ShoppingProductTableData):JSX.Element{
        if(tab.name === 'field_gongxiaochengfen'){
            const state = this.state as HealthAdviceProductDetailState;

            return <div className='ingredient-list'>
            {tab.tableData.map((row, idx)=>{
                 const checked = state.keyIngredientFlags[idx].checked;
                 const ingredientData = row.map((col, idx)=>{
                        const ret:PropertyListDataItem = {name: col.label, value:this.renderSimpleShoppingProductField(col)};
                        return ret;
                    });

                return (
                    <Fragment>
                        <PropertyListWidget list={ingredientData}/>
                        <div className='key-ingredient-checkbox'>
                            <Checkbox checked={checked} 
                                                color='primary' 
                                                onChange={evt=>{
                                                    state.keyIngredientFlags[idx].checked = !checked;
                                                    this.forceUpdate(); 
                                                }} />  
                                    <label>{t('重要成分')}</label>
                        </div>
                    </Fragment>
                )    
            })
            }
            </div>
        }
        return super.renderShoppingProductTableData(tab);
    }

    getKeyIngredients():KeyIngredient[] {
        const state = this.state as HealthAdviceProductDetailState;

        return state.keyIngredientFlags.filter(x=> x.checked);
    }
}