import * as React from 'react';
import {Component, Fragment} from 'react';
import { t } from '../../i18n';
import {getDaysInMonth, format} from 'date-fns';
import './date-picker.scss';
import classnames from 'classnames';
import enLocale from "date-fns/locale/en-US";
import zhCNLocale from "date-fns/locale/zh-CN";
import { getLanguageCode } from '../../local-storage/local-storage';
import { UButton } from '../button/ubutton';
import ReactDOM from 'react-dom';

interface MyProps {
    value?: Date;
    disabled?: boolean;
    onChange: (value?: Date)=>void;

    minYear: number;
    maxYear: number;

    className?: string;
}

interface MyState {
    open?: boolean;
    year?: number;
    month?: number;
    date?: number;
    daysInMonth?: number;
}

export class DatePickerForTinyDevice extends Component<MyProps, MyState> {
    constructor(props: MyProps){
        super(props);
        this.state = {}
    }

    handleSelect = (time:Date) => {
		this.setState({open: false }, ()=>{
            this.props.onChange(time);
        });
    }

    handleCancel = () => {
		this.setState({ open: false });
	}

    render(){
        if(this.state.open){
            return this.renderOpenned();
        }else{
            return this.renderReadOnly();
        }
    }

    renderReadOnly(){
        const {value} = this.props;
        let dateString = '';
        if(value){
            dateString = format(value, 'yyyy-MM-dd');
        }
        let today = new Date();

        return <input type='text' 
                      className={classnames('u-date-picker2-text', this.props.className)}
                      value={dateString}
                      onClick={()=>{
                            if(this.props.disabled) return;

                            const {value} = this.props;
                            let year = value? value.getFullYear() : today.getFullYear();
                            let month = value? value.getMonth(): today.getMonth();
                            let date = value? value.getDate() : today.getDate();
                            let daysInMonth = getDaysInMonth(new Date(year, month, 1));
                            this.setState({open: true, year, month, date, daysInMonth});
                      }}
               />
    }

    adjustDateInMonth(year:number, month:number, date:number){
        let d = new Date(year!, month!, 1);

        let n = getDaysInMonth(d);
        if(date > n){
            return n;
        }else{
            return date;
        }
    }

    updateYear(year:number){
        let date = this.adjustDateInMonth(year, this.state.month!, this.state.date!);
        let daysInMonth = getDaysInMonth(new Date(year, this.state.month!, 1));
        this.setState({year, date, daysInMonth});
    }
    updateMonth(month:number){
        let date = this.adjustDateInMonth(this.state.year!, month, this.state.date!);
        let daysInMonth = getDaysInMonth(new Date(this.state.year!, month, 1));
        this.setState({month, date, daysInMonth});
    }
    updateDate(d:number){
        let date = this.adjustDateInMonth(this.state.year!, this.state.month!, d);
        this.setState({date});
    }

    changeYear(delta:number){
        let year = Math.max(this.state.year!+delta, this.props.minYear);
        year = Math.min(year, this.props.maxYear);
        this.updateYear(year);
    }

    changeMonth(delta:number){
        let month = this.state.month! + delta;
        month = Math.min(11, month);
        month = Math.max(0, month);
        this.updateMonth(month);
    }
    changeDate(delta: number){
        let date = this.state.date! + delta;
        if(date<1){
            date = 1;
        }else if(date > this.state.daysInMonth!){
            date = this.state.daysInMonth!;
        }
        this.updateDate(date);
    }

    renderDialog(){
        let {year, month, date} = this.state;
        
        let theDate = new Date(year!, month!, date!);

        const languageCode = getLanguageCode();
        // https://en.wikipedia.org/wiki/IETF_language_tag
        let locale = zhCNLocale;
        if(languageCode === 'en'){
            locale = enLocale;
        }

        // avoid using <input> because it will trigger mobile key pad, which we dont need here.
        return ReactDOM.createPortal(
            <div className={classnames('u-date-picker2-overlay', this.props.className)}>
                            <div className='u-date-picker2'>
                                <h5 className='display-date'>{format(theDate,'yyyy-MM-dd')}</h5>
                                <h5 className='display-day-of-week'>{format(theDate,'EEEE', {locale})}</h5>

                                <label>{t('年')}
                                    <div className='year-input'>{year}</div>
                                </label>
                                <div className='year-actions'>
                                    <div className='delta' onClick={()=>{ this.changeYear(1)}}>+1</div> 
                                    <div className='delta' onClick={()=>{ this.changeYear(5)}}>+5</div>       
                                    <div className='delta' onClick={()=>{ this.changeYear(-1)}}>-1</div> 
                                    <div className='delta' onClick={()=>{ this.changeYear(-5)}}>-5</div>       
                                </div>

                                <label>{t('月')}
                                    <div  className='month-input'>{format(theDate,'LLLL', {locale})}</div>
                                </label>
                                <div className='month-actions'>
                                    <div className='delta' onClick={()=>{ this.changeMonth(1)}}>+1</div> 
                                    <div className='delta' onClick={()=>{ this.changeMonth(3)}}>+3</div>       
                                    <div className='delta' onClick={()=>{ this.changeMonth(-1)}}>-1</div> 
                                    <div className='delta' onClick={()=>{ this.changeMonth(-3)}}>-3</div>       
                                </div>

                                <label>{t('日')}
                                    <div className='date-input'>{date}</div>
                                </label>
                                <div className='date-actions'>
                                    <div className='delta' onClick={()=>{ this.changeDate(1)}}>+1</div> 
                                    <div className='delta' onClick={()=>{ this.changeDate(7)}}>+7</div>       
                                    <div className='delta' onClick={()=>{ this.changeDate(-1)}}>-1</div> 
                                    <div className='delta' onClick={()=>{ this.changeDate(-7)}}>-7</div>       
                                </div>

                                <div className='action-buttons'>
                                    <UButton variant='contained' size='small' color='secondary'
                                             onClick={()=>{ this.setState({open:false}) }}
                                    >{t('取消')}</UButton>
                                    <UButton variant='contained' size='small' color='primary'
                                              onClick={()=>{
                                                this.setState({open:false}, ()=>{
                                                    this.props.onChange(new Date(this.state.year!, this.state.month!, this.state.date!))
                                                });
                                               }}
                                    >{t('完成')}</UButton>
                                </div>        
                            </div>
                       </div>,
            document.getElementById('root')!
        )
    }

    renderOpenned(){
        const {value} = this.props;
        let dateString = '';
        if(value){
            dateString = format(value, 'yyyy-MM-dd');
        }
      
        // use div as place holder to prevent keypad pop up in mobile phone
        return (
            <Fragment>
                <div className={classnames('u-date-picker2-text', this.props.className)}>{dateString}</div>
                {this.renderDialog()}       
            </Fragment>
        )
    }
}