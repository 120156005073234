import * as React from 'react';
import {Component, Fragment} from 'react';

import './PointsView.scss';
import {t} from '../../i18n';

import {RefreshOutlined} from '@material-ui/icons';
import { round } from '../../util/Util';
import { loadExternContatnsIfNecessary } from '../../external-constants/extern-constants';
import { Toast } from '../Toast';
import { info } from '../../globals';

interface PointsViewProps {
    points: number;
    onClickRefresh: (cb:(success:boolean)=>void)=>void;
}

interface MyState {
    staleData: boolean;
}

export class PointsView extends Component<PointsViewProps,MyState>{
    private toast: Toast | undefined;

    constructor(props:PointsViewProps){
        super(props);
        this.state = {staleData: false}
    }

    render(){
        info(`render PointsView`);

        return (
            <div className='points-view'>
                <span className='points-label'>{t('当前U点')}</span>
                <span  className='points-number'>{round(this.props.points,2)}</span>
                {this.state.staleData? <span>&nbsp;?</span>: null}
                <RefreshOutlined className='refresh-points-icon' onClick={()=>{this.props.onClickRefresh(this.refreshCB)}}/>
                <Toast close={true} duration={2500} message='' ref={e=>{this.toast=e!}} toastType='info'/>
            </div>
        )
    }

    refreshCB = (success:boolean)=>{
        this.setState({staleData: !success});
        if(success){
            this.toast!.sendMessage(t('刷新当前U点'), 'info');
        }
    }

    async componentDidMount(){
        await loadExternContatnsIfNecessary();
        this.props.onClickRefresh(this.refreshCB);
    }
}
