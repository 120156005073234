import * as React from 'react';
import {Component, Fragment} from 'react';

import './footer.scss';
import {t} from '../../i18n';
import { ReactReduxContext } from 'react-redux';

import {ReactComponent as WalletIcon}  from '../../images/wallet-fill.svg';
import {ReactComponent as ShoppingIcon}  from '../../images/mall-fill.svg';
import {ReactComponent as ToolsetIcon}  from '../../images/compass-fill.svg';
import {ReactComponent as UserIcon} from '../../images/user-fill.svg';
import {ReactComponent as NutritionistIcon} from '../../images/nutritionist-fill.svg';

import {ReactComponent as WalletFillIcon}  from '../../images/wallet-fill.svg';
import {ReactComponent as ShoppingFillIcon}  from '../../images/mall-fill.svg';
import {ReactComponent as ToolsetFillIcon}  from '../../images/compass-fill.svg';
import {ReactComponent as UserFillIcon} from '../../images/user-fill.svg';
import {ReactComponent as NutritionistFillIcon} from '../../images/nutritionist-fill.svg';


import classnames from 'classnames';

import { Toast } from '../Toast';
import { getBottomPadding } from '../../globals';
import { pointingProdServer } from '../../util/Util';
import { ConfirmDialogView } from '../confirm-dialog/ConfirmDialogView';
import { gotoShoppingPathNSavePrevPath } from '../../shopping/history/shopping-history';
import { store } from '../..';
import { SVGIconWithLabel } from '../../widget/icon-with-label/icon-label';
import { isNutritionist } from '../../api/user';


export class FooterView extends Component<{},{}> {
    toast: Toast|undefined;
    private openUrlDialog:ConfirmDialogView | undefined;

    constructor(props: {}){
        super(props);
    }

    render(){
        let style = {
        }
        if(getBottomPadding()>0){
            // @ts-ignore
            style.borderBottom = `${getBottomPadding()}px solid white`;
        }
        if(!pointingProdServer()){
            // @ts-ignore
            // style.backgroundColor = 'coral';
        }
        const nonProdIndicator = pointingProdServer()? null: <div className='non-prod-indicator'>T</div>;

        const currentPage = store.getState().pagePath.currentPage;
        const isNutritionistListPage = currentPage === 'main/tools/nutritionist-list';
        const isShoppingPage = currentPage === 'main/shopping/search';
        const isToolsPage = currentPage === 'main/tools';
        const isWalletPage = currentPage === 'main/wallet';
        const isSettingsPage = currentPage === 'main/my-profile';

        return <ReactReduxContext.Consumer>
            {({store})=>{
                // const gotoHealthAdvice = ()=> {
                //     if(isNutritionist()){
                //         gotoShoppingPath('main/tools/health-advice-by-me');
                //     }else{
                //         gotoShoppingPath('main/tools/my-health-advice');
                //     }
                // }
            
                const gotoWalletPage = ()=>{
                    gotoShoppingPathNSavePrevPath('main/wallet');
                }
                const gotoShoppingPage = ()=>{
                    gotoShoppingPathNSavePrevPath('main/shopping/search');
                }
                const gotoToolsPage = ()=>{
                    gotoShoppingPathNSavePrevPath('main/tools');
                }
                const gotoSettingsPage = ()=>{
                    gotoShoppingPathNSavePrevPath('main/my-profile');
                }
                const gotoNutritionistListPage = ()=>{
                    gotoShoppingPathNSavePrevPath('main/tools/nutritionist-list');
                }
                return <Fragment>
                        <div className='main-menu' style={style}>
                            <div className='menu-item-div'>
                                {isWalletPage?
                                <SVGIconWithLabel   Icon={WalletFillIcon} 
                                                    label={t('积分')}
                                                    className={classnames('menu-item', {active:isWalletPage})}  
                                                    onClick={gotoWalletPage}/>:
                                <SVGIconWithLabel   Icon={WalletIcon} 
                                                    label={t('积分')}
                                                    className={classnames('menu-item', {active:isWalletPage})}  
                                                    onClick={gotoWalletPage}/>
                            }
                            </div>
                            <div className='menu-item-div'>
                                {isNutritionistListPage?
                                <SVGIconWithLabel   Icon={NutritionistFillIcon} 
                                                    label={t('服务')}
                                                    className={classnames('menu-item', {active:isNutritionistListPage})} 
                                                    onClick={gotoNutritionistListPage}
                                                    />:
                                <SVGIconWithLabel Icon={NutritionistIcon} 
                                                  label={t('服务')}
                                                  className={classnames('menu-item', {active:isNutritionistListPage})}
                                                  onClick={gotoNutritionistListPage} 
                                                  />
                                }                                
                            </div>
                            <div className='menu-item-div'>
                                {isShoppingPage?
                                <SVGIconWithLabel   Icon={ShoppingFillIcon} label={t('商城')}
                                                    className={classnames('menu-item', {active:isShoppingPage})} 
                                                    onClick={gotoShoppingPage}/>:
                                <SVGIconWithLabel Icon={ShoppingIcon} label={t('商城')}
                                                    className={classnames('menu-item', {active:isShoppingPage})} 
                                                    onClick={gotoShoppingPage}/>
                                }
                            </div>
                            <div className='menu-item-div'>
                                {isToolsPage?
                                <SVGIconWithLabel   Icon={ToolsetFillIcon} 
                                                    label={t('工具')}
                                                    className={classnames('menu-item tool-set',{active:isToolsPage})} 
                                                    onClick={gotoToolsPage}/>:
                                <SVGIconWithLabel   Icon={ToolsetIcon} 
                                                    label={t('工具')}
                                                    className={classnames('menu-item tool-set',{active:isToolsPage})} 
                                                    onClick={gotoToolsPage}/>
                            }
                            </div>
                            <div className='menu-item-div'>
                                {isSettingsPage?
                                <SVGIconWithLabel   Icon={UserFillIcon} 
                                                    label={t('我的')}
                                                    className={classnames('menu-item', {active:isSettingsPage})}  
                                                    onClick={gotoSettingsPage}/>:
                                <SVGIconWithLabel   Icon={UserIcon} 
                                                    label={t('我的')}
                                                    className={classnames('menu-item', {active:isSettingsPage})}  
                                                    onClick={gotoSettingsPage}/>
                            }
                            </div>
                        </div>

                        <Toast close={true} duration={2500} message='' ref={e=>{this.toast=e!}} toastType='info'/>
                        <ConfirmDialogView ref={e=>this.openUrlDialog=e!} open={false} />
                        {nonProdIndicator}
                    </Fragment>
            }}
        </ReactReduxContext.Consumer>
        
    }
}
