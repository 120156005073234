import * as React from 'react';
import {Component, Fragment} from 'react';
import './send-message.scss';
import {t} from '../../i18n';
import { NoticeDialog } from '../NoticeDialog';
import { renderUnishellPage } from '../../unishell-page-common/unishell-page-common';
import { TextField } from '@material-ui/core';
import { UButton } from '../../widget/button/ubutton';
import { doAJAXCall } from '../../shopping/common/ajax-call';
import { sendUsMessage } from '../../wallet-server-api/wallet-server-api';


export class SendUsMessage extends Component<{},{message?:string}> {
    private errorDialog:NoticeDialog|undefined;

    constructor(props: {}){
        super(props);
        this.state = {}
    }

    getTitle(){
        return t('联系我们')
    }

    render(){
        let content = this.renderSucceedContent();
        return (
            <React.Fragment>
                { renderUnishellPage({title: this.getTitle(), className:'contact-us', content})}
                <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
            </React.Fragment>
        )   
    }

    renderSucceedContent():JSX.Element {
        return  <div className='contact-us-content'>
                    <p>{t('消息')}</p>
                    <TextField
                        variant='outlined'
                        multiline
                        minRows={5}
                        maxRows={10}
                        value={this.state.message||''}
                        onChange={(evt)=>{
                            this.setState({message: (evt.currentTarget.value||'').substring(0,1000)});
                        }}
                    />
                    <UButton
                        variant='contained'
                        color='primary'
                        disabled={!this.state.message || this.state.message.trim().length<1}
                        onClick={()=>{
                            this.sendMessage(this.state.message!);
                        }}
                    >{t('发送')}</UButton>
                </div>
    }

    sendMessage(message:string){
        doAJAXCall({
            whatIsFor: this.getTitle(),
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken)=>{
                await sendUsMessage(accessToken, message);
                this.setState({message: undefined}, ()=>{
                    this.errorDialog!.show('', t('谢谢您联系我们'), ()=>{ });
                });
            }
        })
    }
}