// Generated by IcedCoffeeScript 108.0.11
(function () {
  var ASP,
      BaseBurner,
      iced,
      make_esc,
      __iced_k,
      __iced_k_noop,
      __slice = [].slice;

  iced = require('iced-runtime');

  __iced_k = __iced_k_noop = function __iced_k_noop() {};

  make_esc = require('iced-error').make_esc;
  ASP = require('pgp-utils').util.ASP;

  exports.BaseBurner = BaseBurner = function () {
    function BaseBurner(_arg) {
      this.sign_with = _arg.sign_with, this.encrypt_for = _arg.encrypt_for, this.signing_key = _arg.signing_key, this.encryption_key = _arg.encryption_key, this.asp = _arg.asp;
      this.asp = ASP.make(this.asp);
    }

    BaseBurner.prototype._find_keys = function (cb) {
      var esc, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      esc = make_esc(cb, "find_keys");
      (function (_this) {
        return function (__iced_k) {
          __iced_deferrals = new iced.Deferrals(__iced_k, {
            parent: ___iced_passed_deferral,
            filename: "/Users/max/src/keybase/kbpgp/src/openpgp/baseburner.iced",
            funcname: "BaseBurner._find_keys"
          });

          _this._find_signing_key(esc(__iced_deferrals.defer({
            lineno: 17
          })));

          __iced_deferrals._fulfill();
        };
      })(this)(function (_this) {
        return function () {
          (function (__iced_k) {
            __iced_deferrals = new iced.Deferrals(__iced_k, {
              parent: ___iced_passed_deferral,
              filename: "/Users/max/src/keybase/kbpgp/src/openpgp/baseburner.iced",
              funcname: "BaseBurner._find_keys"
            });

            _this._find_encryption_key(esc(__iced_deferrals.defer({
              lineno: 18
            })));

            __iced_deferrals._fulfill();
          })(function () {
            (function (__iced_k) {
              __iced_deferrals = new iced.Deferrals(__iced_k, {
                parent: ___iced_passed_deferral,
                filename: "/Users/max/src/keybase/kbpgp/src/openpgp/baseburner.iced",
                funcname: "BaseBurner._find_keys"
              });

              _this._assert_one(esc(__iced_deferrals.defer({
                lineno: 19
              })));

              __iced_deferrals._fulfill();
            })(function () {
              return cb(null);
            });
          });
        };
      }(this));
    };

    BaseBurner.prototype._assert_one = function (cb) {
      var err;
      err = null;

      if (!(this.signing_key != null) && !(this.encryption_keys != null)) {
        err = new Error("need either an encryption or signing key, or both");
      }

      return cb(err);
    };

    BaseBurner.prototype._find_signing_key = function (cb) {
      var err;
      err = null;

      if (this.sign_with != null && this.signing_key != null) {
        err = new Error("specify either `sign_with` or `signing_key` but not both");
      } else if (this.sign_with != null && (this.signing_key = this.sign_with.find_signing_pgp_key()) == null) {
        err = new Error("cannot sign with the given KeyManager");
      }

      return cb(err);
    };

    BaseBurner.prototype._find_encryption_key = function (cb) {
      var arrayize, count_true, err, f, i, k, _i, _len, _ref;

      err = null;

      count_true = function count_true() {
        var e, i, v, _i, _len;

        v = 1 <= arguments.length ? __slice.call(arguments, 0) : [];
        i = 0;

        for (_i = 0, _len = v.length; _i < _len; _i++) {
          e = v[_i];

          if (e) {
            i++;
          }
        }

        return i;
      };

      arrayize = function arrayize(e) {
        if (e == null) {
          return [];
        } else if (typeof e === 'object' && Array.isArray(e)) {
          return e;
        } else {
          return [e];
        }
      };

      if (count_true(this.encrypt_for != null, this.encryption_key != null, this.encryption_keys != null) > 1) {
        err = new Error("specify only one of `encrypt_for`, `encryption_keys` and `encryption_key`");
      } else if (this.encrypt_for != null) {
        this.encryption_keys = [];
        _ref = this.encrypt_for = arrayize(this.encrypt_for);

        for (i = _i = 0, _len = _ref.length; _i < _len; i = ++_i) {
          f = _ref[i];

          if ((k = f.find_crypt_pgp_key()) != null) {
            this.encryption_keys.push(k);
          } else {
            err = new Error("cannot encrypt with the given KeyManager (i=" + i + ")");
            break;
          }
        }
      } else if (this.encryption_key != null) {
        this.encryption_keys = [this.encryption_key];
      } else if (this.encryption_keys != null) {
        this.encryption_keys = arrayize(this.encryption_keys);
      }

      return cb(err);
    };

    return BaseBurner;
  }();
}).call(this);