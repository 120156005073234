import * as React from 'react';
import {Component} from 'react';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import enLocale from "date-fns/locale/en-US";
import zhCNLocale from "date-fns/locale/zh-CN";
import './date-picker.scss';
import { getLanguageCode } from '../../local-storage/local-storage';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { t } from '../../i18n';
import classnames from 'classnames';
import { calcRationClass } from '../../util/Util';
import { DatePickerForTinyDevice } from './date-picker2';

interface MyProps {
    value?: Date;
    disabled?: boolean;
    onChange: (value?: Date)=>void;
    minYear: number;
    maxYear: number;

    className?: string;
}

export class UDatePicker extends Component<MyProps, {}> {
    constructor(props: MyProps){
        super(props);
        this.state = {}
    }

    render(){
        const {ratio} = calcRationClass();
        if(ratio <= 24){
            return <DatePickerForTinyDevice {...this.props} />
        }

        const languageCode = getLanguageCode();
        // https://en.wikipedia.org/wiki/IETF_language_tag
        let locale = zhCNLocale;
        if(languageCode === 'en'){
            locale = enLocale;
        }
        
        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
              <DatePicker value={this.props.value ? this.props.value : null}
                          initialFocusedDate={new Date()} 
                          disabled={this.props.disabled}
                          inputVariant='outlined'
                          
                          format='yyyy-MM-dd'

                          minDate={new Date(this.props.minYear, 0, 1)}
                          maxDate={new Date(this.props.maxYear, 11, 31)}

                          className={classnames('u-date-picker', this.props.className)}

                          okLabel={t('完成')}
                          cancelLabel={t('取消')}
                          
                          DialogProps={{className:classnames('u-pick-date-dialog', this.props.className)}}
                          onChange={(d: MaterialUiPickersDate)=>{
                            if(d){
                                this.props.onChange(new Date(d.getTime()));
                            }else{
                                this.props.onChange(undefined);
                            }
                          }} />
            </MuiPickersUtilsProvider>
          );
    }
}