import { Button, Checkbox, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import * as _ from 'lodash';
import * as React from 'react';
import {Component} from 'react';
import { connect } from 'react-redux';
import { PagedResponse } from '../../api/transaction';
import { t } from '../../i18n';
import { WalletAppState } from '../../redux-store';
import { formatDateTime, formatPrice, getCurrencyOption, getMaxThumbnailImgSize, getServerURL, scrollBodyToTop } from '../../util/Util';
import { NoticeDialog } from '../../view/NoticeDialog';
import { CurrencyOption, PaymentMethodOption, ShopOrderInfo, ShopOrderStatus, ProductCandidateBrief, AddressInfo2Str, LogisticCompanyInfo, ShippingRecordInfo, ShippingRecordStatus, getShoppingCartProductType } from '../ajax-data/ajax-data-types';
import { addShippingTrackNumber, deleteShippingRecord, getCourierList, getCurrencyOptionsMap, getShoppingOrder, getShippingRecordStatusOptions, getShoppingOrderStatuOptions, searchShoppingOrdersByAdmin, updateShippingRecord, ShopOrderQueryFilter, labTestSampleReceived } from '../ajax-data/shopping-server-api';
import { doAJAXCall } from '../common/ajax-call';
import { ImageWithOverlay } from '../common/image-with-overlay';
import { Pagination } from '../common/render-pagination';
import { BaseShoppingPageProps} from '../common/shopping-page-common';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { confirmOpenURL } from '../../view/open-url/open-url';
import { ConfirmDialogView } from '../../view/confirm-dialog/ConfirmDialogView';

import './order-dashboard.scss';
import { Toast } from '../../view/Toast';
import { loadExternContatnsIfNecessary } from '../../external-constants/extern-constants';
import { SimpleEmail } from '../../widget/email/email';
import { BaseUnishellPageState, renderInitErrorUnishellPage, renderInitUnishellPage, renderUnishellPage } from '../../unishell-page-common/unishell-page-common';
import { UButton } from '../../widget/button/ubutton';
import { UDatePicker } from '../../widget/date-picker/date-picker';
import { updateCurrentPathParms } from '../history/shopping-history';

export interface OrderDashboardFilter {
    page?:number;
    pageSize?: number;
    orderStatus?: string;
    fromTime?: number;
    toTime?: number;
}

export interface OrderDashboardProps extends OrderDashboardFilter, BaseShoppingPageProps {
    currencyCode: string,

}

interface OrderDashboardState extends BaseUnishellPageState {
    currencyOptionsMap?: {[key:string]:CurrencyOption[]};
    paymentMethodOptionsMap?:{[key:string]:PaymentMethodOption[]};
    orderStatusOptions?:{key:string, text:string}[];
    shippingRecordStatusOptions?:{key:string, text:string}[];


    page:number;
    pageSize: number;
    orderStatus?: string;
    fromTime?: number;
    toTime?: number;

    searchResult?:PagedResponse<ShopOrderInfo>;
    courierList?: LogisticCompanyInfo[];

    newTrackNumberMap: {[orderCode:string]: string};
    expandOrderMap:{[orderCode:string]: boolean};
}

class OrderDashboardView extends Component<OrderDashboardProps, OrderDashboardState>{
    private errorDialog:NoticeDialog|undefined;
    private toast: Toast|undefined;
    private openUrlDialog:ConfirmDialogView | undefined;

    constructor(props: OrderDashboardProps){
        super(props);
        let {
            page,
            pageSize,
            orderStatus,
            fromTime,
            toTime,
        } = this.props;
        this.state = {pageInitState:'PAGE_INIT', page: page || 0, pageSize: pageSize||20,
                      orderStatus: orderStatus||'',
                      fromTime, toTime,
                      newTrackNumberMap: {}, expandOrderMap: {},
                    }
    }
    getTitle(){
        return t('管理商城订单(需宽屏)');
    }
    render(){
        if(this.state.pageInitState === 'PAGE_INIT'){
            return <React.Fragment>
                        {renderInitUnishellPage({title: this.getTitle(), message:t('初始化...')})}
                        <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                        <Toast close={true} duration={2500} message='' ref={e=>{this.toast=e!}} toastType='info'/>
                    </React.Fragment>
        }
        if(this.state.pageInitState === 'PAGE_LOAD_FAILED'){
            return <React.Fragment>
                        {renderInitErrorUnishellPage({title: this.getTitle(), 
                                                      errorMessage: this.state.initErrorMessage||t('初始化失败'),
                                                      onTryAgain: ()=>{ this.initializeAgain();    }
                                                      })}
                        <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                        <Toast close={true} duration={2500} message='' ref={e=>{this.toast=e!}} toastType='info'/>
                    </React.Fragment>
        }
        // loading data succeeded
        let content = this.renderSucceedContent();
        return (
            <React.Fragment>
                { renderUnishellPage({title: this.getTitle(), className:'order-dashboard', content})}
                <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                <Toast close={true} duration={2500} message='' ref={e=>{this.toast=e!}} toastType='info'/>
                <ConfirmDialogView ref={e=>this.openUrlDialog=e!} open={false} />
            </React.Fragment>
        )   
    }

    renderSucceedContent():JSX.Element {
        const courier = this.state.courierList![0];
        return (
            <div className='order-dashboard-content'>
                {this.renderSearchForm()}
                <p>
                    <strong>{t('当前快递公司')}: </strong>
                    <a href={courier.webSite} target='_'>{courier.name}</a>
                </p>
                <div>
                </div>
                <Pagination data={this.state.searchResult!} page={this.state.page} pageSize={this.state.pageSize} 
                            loadData={this.loadPaginationData}
                            changePageSize={(newPageSize,newPage)=>{
                                this.loadPaginationData(newPage, newPageSize);
                            }}
                >
                { this.renderOrderBriefs() }
                </Pagination>

            </div>
        )
    }

    renderAddTrackNumber(orderCode:string):JSX.Element {
        let newTrackNumber = this.state.newTrackNumberMap[orderCode]||'';
        return (
            <div className='add-courier-track-number'>
                <TextField label={t('快递单号')} variant='standard' size='small' value={newTrackNumber}
                           onChange={(evt)=>{
                                this.state.newTrackNumberMap[orderCode] = evt.target.value;
                                this.forceUpdate();
                           }}
                /> 
                <UButton variant='contained' color='default' size='small' disabled={newTrackNumber.trim()===''}
                        onClick={()=>{
                            this.addTrackNumber(newTrackNumber.trim(), orderCode);
                        }}
                >{t('添加')}</UButton>
            </div>
        )
    }

    addTrackNumber(trackNumber:string, orderCode:string){
        doAJAXCall(
            {
                whatIsFor: t('添加快递单号'),
                errorDialog: this.errorDialog!,
                ajaxCall: async (accessToken)=>{
                    let shippingRecord = await addShippingTrackNumber(
                                                   accessToken, 
                                                   {trackNumber, orderCode, logisticCompanyId: this.state.courierList![0].code}
                                                );
                    const theOrder = this.state.searchResult!.content.find(v=>v.orderCode === orderCode)!;
                    theOrder.shippingRecordInfo = theOrder.shippingRecordInfo||[];
                    theOrder.shippingRecordInfo.push(shippingRecord);

                    const order2 = await getShoppingOrder(accessToken, orderCode, -1);
                    this.state.searchResult!.content.find(v=>v.orderCode===orderCode)!.status = order2.status;

                    this.state.newTrackNumberMap[orderCode] = '';    
                    this.forceUpdate(()=>{
                        this.toast!.sendMessage(t('添加快递单号')+`:${trackNumber}`, 'info');                         
                    });   
                }
            }
        )
    }

    renderShippingRecord(rec:ShippingRecordInfo){
        const courier = this.state.courierList!.find(v=>v.code === rec.logisticCompanyId);
        const courierName = courier? courier.name : '';

        return (
            <div className='shipping-record'>
                <div>
                    <label>{t('快递单号')}</label><br/>
                    <a href={rec.trackingUrl}
                       onClick={evt=>{
                                    evt.stopPropagation();
                                    evt.preventDefault();
                                    confirmOpenURL(rec.trackingUrl, this.openUrlDialog!);
                                }}
                    >{rec.trackNumber} ({courierName})</a>
                </div>
               <FormControl variant="standard" className='shipping-record-status'>
                    <InputLabel>{t('快递状态')}</InputLabel>
                    <Select
                        value={rec.status}
                        label={t('快递状态')}
                        onChange={(evt)=>{
                            doAJAXCall(
                                {
                                    whatIsFor: t('修改快递状态'),
                                    errorDialog: this.errorDialog!,
                                    ajaxCall: async (accessToken)=>{
                                        const rec2 = _.cloneDeep(rec);
                                        rec2.status = evt.target.value as ShippingRecordStatus;
                                        await updateShippingRecord(accessToken, rec2);
                                        const order2 = await getShoppingOrder(accessToken, rec.orderCode, -1);
                                        rec.status = rec2.status;
                                        this.state.searchResult!.content.find(v=>v.orderCode===rec.orderCode)!.status = order2.status;
                                        this.forceUpdate(()=>{
                                            this.toast!.sendMessage(t('修改快递状态')+':'+rec2.status, 'info');
                                        });
                                    }
                                }
                            )
                        }}
                    >
                    {this.state.shippingRecordStatusOptions!.filter(v=>v.key !== '').map(v=>{
                        return <MenuItem value={v.key} key={v.key}>{v.text}</MenuItem>
                    })}
                    </Select>
                </FormControl>
                <IconButton color='secondary' component="span" className='delete-button'
                            onClick={()=>{
                                doAJAXCall(
                                    {
                                        whatIsFor:t('删除快递单号'),
                                        errorDialog: this.errorDialog!,
                                        ajaxCall: async (accessToken)=>{
                                            await deleteShippingRecord(accessToken, rec.id.toString());
                                            const theOrder = this.state.searchResult!.content.find(v=>v.orderCode === rec.orderCode)!;
                                            theOrder.shippingRecordInfo=theOrder.shippingRecordInfo!.filter(v=>v.id !== rec.id);
                                            
                                            const order2 = await getShoppingOrder(accessToken, rec.orderCode, -1);
                                            this.state.searchResult!.content.find(v=>v.orderCode===rec.orderCode)!.status = order2.status;
                        
                                            this.forceUpdate(()=>{
                                                this.toast!.sendMessage(t('删除快递单号')+`:${rec.trackNumber}`, 'info');
                                            });
                                        }
                                    }
                                )
                            }}
                >
                    <CloseOutlinedIcon/>
                </IconButton>
            </div>
        )
    }

    renderOrderBriefs():JSX.Element|null {
        if(!this.state.searchResult!.content){
            return null;
        }

        const serverURL = getServerURL();
        // use table intentionally
        return (
            <div className='order-dashboard-table-wrapper'>
                <table className='order-dashboard-table'>
                    <thead>
                        <tr>
                            <th className='property-name'>{t('订单号')}</th>
                            <th className='property-name'>{t('订单类型')}</th>
                            <th className='property-name'>{t('自提')}?</th>
                            <th className='property-name'>{t('价格')}</th>
                            <th className='property-name'>{t('订单状态')}</th>
                            <th className='property-name'>{t('用户')}</th>
                            <th className='property-name'>{t('电邮')}</th>
                            <th className='property-name'>{t('电话')}</th>
                            <th className='property-name'>{t('创建时间')}</th>
                        </tr>
                    </thead>
                    <tbody>

            {this.state.searchResult!.content.map(x=>{
                let status = '';
                let statusOpt = this.state.orderStatusOptions!.find(o=> o.key === x.status);
                if(statusOpt){
                    status = statusOpt.text;
                }
                let productType = getShoppingCartProductType(x.shoppingCart);

                const currencyOption = getCurrencyOption(this.state.currencyOptionsMap!, 
                                                            this.props.languageCode!, 
                                                            x.shoppingCart.currencyCode);
                let productBriefs:ProductCandidateBrief[] = [];
                if(productType === 'chanpin'){
                    x.shoppingCart.item.forEach(item=>{
                        item.product.forEach(prod=>{
                            productBriefs.push(prod);
                        })
                    });    
                }else{
                    (x.labRequestInfo||[]).forEach(item=>{
                        productBriefs.push(item.productInfo);
                    })
                }
                
                const receivedLabTestSample = (idx:number)=>{
                    const labRequest = x.labRequestInfo![idx];

                    doAJAXCall(
                        {
                            whatIsFor: '收到检测样本',
                            errorDialog: this.errorDialog!,
                            ajaxCall: async (accessToken)=>{
                                let newLabRequest = await labTestSampleReceived(accessToken, labRequest.requestCode);
                                x.labRequestInfo![idx] = newLabRequest;

                                const order2 = await getShoppingOrder(accessToken, x.orderCode, -1);
                                x.status = order2.status;
                                // this.state.searchResult!.content.find(v=>v.orderCode===rec.orderCode)!.status = order2.status;
                                // this.forceUpdate(()=>{
                                //     this.toast!.sendMessage(t('修改快递状态')+':'+rec2.status, 'info');
                                // });

                                this.forceUpdate(()=>{
                                    this.toast!.sendMessage(t('修改健康检测状态'), 'info');
                                });
                            }
                        }
                    )
            
                }
                
                let pickup = !!x.pickupAddressInfo;


                let expandedRegularProduct = (
                    <React.Fragment>                        
                        <tr>
                        <td colSpan={9}>
                            <div className='address-col'>
                                {AddressInfo2Str((x.pickupAddressInfo || x.shippingAddressInfo)!)}
                                {x.shippingAddressInfo && x.shippingAddressInfo.identityPhoto ?
                                x.shippingAddressInfo && x.shippingAddressInfo.identityPhoto!.map(v => {
                                    return <ImageWithOverlay imageList={[v.url.startsWith('http')? v.url : serverURL+v.url]} classes='prod-thumb-img'/>
                                }) : null
                                }
                            </div>
                        </td>
                        </tr>
                        <tr>
                        <td colSpan={9}>
                            <div className='product-list' >
                                {productBriefs.map((p, idx)=>{
                                    return (
                                        <div className='product'>
                                            <div className='product-image'>
                                                    <ImageWithOverlay imageList={[p.thumbImage.startsWith('http')? p.thumbImage : serverURL+p.thumbImage]} classes='prod-thumb-img'/>
                                            </div>
                                            <div className='product-name'>
                                                {p.productName} <br/>{t('数量')}: {p.count}
                                            </div>
                                            {productType==='test'?
                                                <div className='lab-request-status'>
                                                    <div>{t('健康检测号')}:{x.labRequestInfo![idx].requestCode}</div>
                                                    <div>{t('状态')}:{x.labRequestInfo![idx].status}</div>
                                                    {x.labRequestInfo![idx].status==='Created'?
                                                        <UButton onClick={()=> receivedLabTestSample(idx) }
                                                        >{t('收到检测样本')}</UButton>
                                                        :null
                                                    }
                                                </div>
                                                :null
                                            }
                                        </div>
                                    )
                                })}
                            </div>
                        </td>
                        </tr>
                        <tr>
                        <td colSpan={9}>
                            <div className='shipping-record-row'>
                                {(x.shippingRecordInfo||[]).map(v=>{
                                    return this.renderShippingRecord(v);
                                })}
                                {!!x.shippingAddressInfo? this.renderAddTrackNumber(x.orderCode): null}
                            </div>
                        </td>
                        </tr>
                    </React.Fragment>
                )

                return (
                    <React.Fragment key={x.orderCode}>
                    <tr className='basic-info-tr'>
                        <td className='order-id'>
                            {x.orderCode}
                            <FormControlLabel control={<Checkbox checked={this.state.expandOrderMap[x.orderCode]} 
                                                         onChange={evt=>{
                                                            this.state.expandOrderMap[x.orderCode]=evt.target.checked;
                                                            this.forceUpdate();
                                                         }}
                                               />} 
                                      label={t('修改')}
                            /> 
                        </td>
                        <td>
                        {productType==='test' ? t('健康检测'): t('一般产品')}    
                        </td>
                        <td>
                            <FormControlLabel control={<Checkbox checked={pickup} />} label={t('')}/>
                        </td>
                        <td className='total-price'>{formatPrice(x.shoppingCart.totalPrice, currencyOption.symbol, currencyOption.label)}</td>
                        <td><div className={'status-'+x.status}>{status}</div></td>
                        <td>{x.userInfo.name}</td>
                        <td><SimpleEmail email={x.userInfo.email}/></td>
                        <td><SimpleEmail email={x.userInfo.phoneNumber}/></td>
                        <td className='timestamp'>{formatDateTime(new Date(x.modifiedDtm))}</td>
                    </tr>
                    {this.state.expandOrderMap[x.orderCode]? expandedRegularProduct: null}
                    </React.Fragment>
                )
                })                
            }
                </tbody>
            </table>
            </div>
        )
    }

    renderSearchForm():JSX.Element {
        let startDate = this.state.fromTime? new Date(this.state.fromTime) : undefined;
        let endDate = this.state.toTime? new Date(this.state.toTime) : undefined;
        const today = new Date();
        
        return (
            <div className='shopping-order-search-form'>
                <Grid container>
                    <Grid item xs={12} md={4}>
                        <div className='order-state'>
                            <label>{t('订单状态')}</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <FormControl variant="outlined" size='small' className='status-select'>
                                <Select value={this.state.orderStatus} 
                                        onChange={evt=>{ this.setState({orderStatus: evt.target.value as string})  }} 
                                >
                                    {this.state.orderStatusOptions!.map(x=>{
                                        return <MenuItem value={x.key}>{x.text}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </div>
                    </Grid>
                    <Grid item xs={12}  md={4}>
                        <div className='date-range'>
                            <label>{t('起始日期')}</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <UDatePicker value={startDate}
                                    minYear={2020}
                                    maxYear={today.getFullYear()+1}                            
                                    onChange={(value?:Date)=>{ 
                                            this.setState({fromTime: value? value.getTime() : undefined});
                                    }}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12}  md={4}>
                        <div className='date-range'>
                            <label>{t('截止日期')}</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <UDatePicker value={endDate}
                                    minYear={2020}
                                    maxYear={today.getFullYear()+1}                            
                                    onChange={(value?:Date)=>{ 
                                            this.setState({toTime: value? value.getTime() : undefined});
                                    }}
                            />
                        </div>
                    </Grid>

                </Grid>
                
                <UButton className='search-button' variant='contained' color='primary'
                        onClick={()=>{ this.searchOrders(this.state.page, this.state.pageSize) }}
                >{t('搜索')}</UButton>
            </div>
        )
    }

    getFromTime() {
        if(!this.state.fromTime) return undefined;

        let d = new Date(this.state.fromTime);
        d.setHours(0);
        d.setMinutes(0);
        d.setSeconds(0);

        return d.getTime();
    }
    getToTime() {
        if(!this.state.toTime) return undefined;

        let d = new Date(this.state.toTime);
        d.setHours(23);
        d.setMinutes(59);
        d.setSeconds(59);

        return d.getTime();
    }

    getFilters(): ShopOrderQueryFilter {
        const status = (!this.state.orderStatus) ? undefined : this.state.orderStatus as ShopOrderStatus;
        
        return {status:status?[status]: undefined, fromTime: this.getFromTime(), toTime: this.getToTime()}
    }

    loadPaginationData = (page:number, pageSize:number) => {
        this.searchOrders(page, pageSize)
    }

    searchOrders(page: number, pageSize:number){    
        const maxImgWidth = -1;
        doAJAXCall(
            {
                whatIsFor: '商城订单记录',
                errorDialog: this.errorDialog!,
                ajaxCall: async (accessToken)=>{
                    let searchResult = await searchShoppingOrdersByAdmin(accessToken, page, pageSize, maxImgWidth,
                                                                  this.props.currencyCode,
                                                                  this.getFilters());
                    this.setState({searchResult, page: searchResult.page, pageSize: searchResult.size, newTrackNumberMap:{}, expandOrderMap:{} })
                }
            }
        )
    }

    initializeAgain(){
        this.setState({pageInitState:'PAGE_INIT', initErrorMessage:undefined},
                      ()=>{
                          this.loadInitData();
                      })
    }

    componentDidMount(){
        this.loadInitData();
    }

    componentDidUpdate(prevProps:any, prevState:OrderDashboardState){
        if(prevState.page !== this.state.page){
            scrollBodyToTop();
        }
        let {
            page,
            pageSize,
            orderStatus,
            fromTime,
            toTime,
        } = this.state;

        let parms: OrderDashboardFilter = {
            page,
            pageSize,
            orderStatus,
            fromTime,
            toTime,
        }

        updateCurrentPathParms('main/shopping/order-dashboard', parms)
    }

    loadInitData(){
        const maxImgWidth = -1;

        doAJAXCall({
            whatIsFor: t('下载数据'),
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken)=>{
                                                await loadExternContatnsIfNecessary();
                                              let courierList = await getCourierList(accessToken);
                                              let currencyOptionsMap = getCurrencyOptionsMap();
                                              let orderStatusOptions = getShoppingOrderStatuOptions(accessToken, this.props.languageCode!);
                                              let shippingRecordStatusOptions = getShippingRecordStatusOptions(accessToken, this.props.languageCode!);
                                              let searchResult = await searchShoppingOrdersByAdmin(accessToken,
                                                                                            this.state.page, this.state.pageSize,
                                                                                            maxImgWidth,
                                                                                            this.props.currencyCode,
                                                                                            this.getFilters());
                                              this.setState({pageInitState:'PAGE_LOAD_SUCCESS', 
                                                             currencyOptionsMap,
                                                             orderStatusOptions,
                                                             shippingRecordStatusOptions,
                                                             searchResult,
                                                             courierList
                                                            });
                                           },
            onError: (errorMessage:string)=>{ this.setState({pageInitState:'PAGE_LOAD_FAILED', initErrorMessage:errorMessage})}                                           
        })                
    }

}

export const OrderDashboard = connect(
    (state:WalletAppState)=>({languageCode: state.language.langCode, currencyCode: state.currency.currencyCode})
)(OrderDashboardView);