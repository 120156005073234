import { Avatar } from '@material-ui/core';
import * as React from 'react';
import {Component, Fragment} from 'react';
import { getExternContantsInMemory, loadExternContatnsIfNecessary } from '../../../external-constants/extern-constants';
import { t } from '../../../i18n';
import { getLanguageCode } from '../../../local-storage/local-storage';
import { BaseUnishellPageState, renderInitErrorUnishellPage, renderInitUnishellPage, renderUnishellPage } from '../../../unishell-page-common/unishell-page-common';
import { NoticeDialog } from '../../../view/NoticeDialog';
import { Toast } from '../../../view/Toast';
import { CountryOption, ShoppingAddressSpec, NutritionistInfo, AddressInfo2StrArray, toImgSrc } from '../../ajax-data/ajax-data-types';
import { getCountryOptions, getAddressSpecMap, retrieveNutritionist } from '../../ajax-data/shopping-server-api';
import { doAJAXCall } from '../../common/ajax-call';
import './profile-portrait.scss';

import {ReactComponent as PhoneIcon} from '../../../images/phone.svg';
import {ReactComponent as EmailIcon} from '../../../images/email.svg';
import {ReactComponent as AddrIcon} from '../../../images/pin.svg';
import {ReactComponent as WebSiteIcon} from '../../../images/internet.svg';
import {ReactComponent as TimeIcon} from '../../../images/clock.svg';
import {ReactComponent as QRCodeIcon} from '../../../images/qr-code.svg';
import {ReactComponent as GenderIcon} from '../../../images/gender.svg';
import {ReactComponent as LanguageIcon} from '../../../images/language-choice.svg';
import {ReactComponent as LicenseIcon} from '../../../images/license.svg';
import {ReactComponent as DisabledIcon} from '../../../images/disabled.svg';
import {ReactComponent as LeftQuoteIcon} from '../../../images/left-quotes.svg';
import {ReactComponent as RightQuoteIcon} from '../../../images/right-quotes.svg';
import {ReactComponent as InsuranceIcon} from '../../../images/insurance.svg';

import {ReactComponent as WechatIcon} from '../../../images/test/wechat.svg';
import {ReactComponent as WeiboIcon} from '../../../images/test/sina-weibo.svg';
import {ReactComponent as FacebookIcon} from '../../../images/test/facebook.svg';
import {ReactComponent as LinkedInIcon} from '../../../images/test/linkedin.svg';
import {ReactComponent as YoutubeIcon} from '../../../images/test/youtube.svg';
import {ReactComponent as TiktokIcon} from '../../../images/test/tiktok.svg';
import {ReactComponent as TwitterIcon} from '../../../images/test/twitter.svg';
import {ReactComponent as PinterestIcon} from '../../../images/test/pinterest.svg';
import {ReactComponent as InstagramIcon} from '../../../images/test/instagram.svg';

import {ReactComponent as URLIcon} from '../../../images/url.svg';

import { SimplePhoneNumber } from '../../../widget/phone-number/phone-number';
import { SimpleEmail } from '../../../widget/email/email';
import { confirmOpenURL, SimpleURL } from '../../../view/open-url/open-url';
import { ConfirmDialogView } from '../../../view/confirm-dialog/ConfirmDialogView';
import { convertServiceTime } from '../../common/service-time';
import { SimpleNote } from '../../../widget/note/note';
import Editor from "rich-markdown-editor";
import { UButton } from '../../../widget/button/ubutton';
import { PropertyListDataItem, PropertyListWidget } from '../../../widget/property-list/property-list';
import { formatDate, RatioXXS } from '../../../util/Util';

interface ProfileProps{
    /** default is current username */
    nutritionistId?: string;
}

interface ProfileState extends BaseUnishellPageState {
    countryOptions?:CountryOption[];
    shoppingAddressspecMap?: {[key:string]:ShoppingAddressSpec};

    nutritionist?:NutritionistInfo;
    showResume?: boolean;
}

/**
 * render nutritionist profile on a typical mobile phone.
 */
export class NutritionistProfilePortrait extends Component<ProfileProps, ProfileState>{
    private errorDialog:NoticeDialog|undefined;
    private qrCodeDialog:NoticeDialog|undefined;
    private toast: Toast | undefined;
    private openUrlDialog:ConfirmDialogView | undefined;

    constructor(props: ProfileProps){
        super(props);
        this.state = {pageInitState:'PAGE_INIT'};
    }

    getTitle(){
        return t('营养师信息');
    }


    render(){
        if(this.state.pageInitState === 'PAGE_INIT'){
            return <React.Fragment>
                        {renderInitUnishellPage({
                            title: this.getTitle(), 
                            className:'nutritionist-profile-portait',
                        })}
                        <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                    </React.Fragment>
        }
        if(this.state.pageInitState === 'PAGE_LOAD_FAILED'){
            return <React.Fragment>
                        {renderInitErrorUnishellPage({title: this.getTitle(),
                                                      className:'nutritionist-profile-portait', 
                                                      errorMessage: this.state.initErrorMessage||t('初始化失败'),
                                                      onTryAgain: ()=>{ this.initializeAgain();    }
                                                      })}
                        <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                    </React.Fragment>
        }

        // loading data succeeded
        let content = this.renderSucceedContent();
        return (
            <React.Fragment>
                { renderUnishellPage(
                    {
                        title: this.getTitle(), 
                        className:'nutritionist-profile-portait', 
                        backgroundColor: '#7FB500',
                        content
                    }
                  )
                }
                <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                <Toast close={true} message='' toastType='info' onClose={()=>{}} ref={(e)=>{this.toast=e!}} duration={3000}/>
                <ConfirmDialogView ref={e=>this.openUrlDialog=e!} open={false} />
                <NoticeDialog className='nutritionist-qrcode-dialog' open={false} ref={e=>this.qrCodeDialog=e!}/>
            </React.Fragment>
        )   
    }

    renderSucceedContent():JSX.Element {
        return (
            <div className='nutritionist-profile-portait-content'>
                {this.renderAvatar()}
                <div className='name-title'>
                    {this.renderName()}
                    {this.renderTitles()}
                </div>
                {this.renderStatus()}
                {this.renderInstitution()}
                {this.renderExpertice()}
                {this.renderTestimonial()}
                {this.renderGender()}
                {this.renderPhone()}
                {this.renderSvcLanguages()}
                {this.renderEmail()}
                {this.renderWebAddress()}
                {this.renderSocialMedia()}
                {this.renderQRCode()}
                {this.renderInsurancePolicy()}
                {this.renderLicense()}
                {this.renderAddress()}
                {this.renderServiceTime()}
            </div>
        )
    }

    renderStatus():JSX.Element|null{
        const {nutritionist} = this.state;
        
        return nutritionist!.status === 'active'? null:
        <SimpleNote noticeType='warn' 
                    className='status-section'
                    message={<Fragment><DisabledIcon/><span>{t('未激活')}</span></Fragment>}/>
    }

    renderAvatar():JSX.Element{
        const {nutritionist} = this.state;
        let avatarurl = nutritionist!.avatar? toImgSrc(nutritionist!.avatar.url) : undefined;

        return <div className='avatar-section'>
                    <div className='green-part'/>
                    <div className='white-part'/>
                    <Avatar className='nutritionist-avatar' src={avatarurl}/>
               </div>
    }

    renderName():JSX.Element {
        return <div className='nutritionist-name'>{this.state.nutritionist!.name}</div>
    }

    renderTitles():JSX.Element {
        const titles = (this.state.nutritionist!.titles || []).map(x=>x.trim()).filter(x=>!!x);

        return <div className='titles'>
               {titles.map((x,idx)=>{
                    if(idx<titles.length-1)
                        return <Fragment>{x},&nbsp;</Fragment>
                    else
                        return <Fragment>{x}.</Fragment>
                })}
               </div>
    }

    renderGender(){
        let sex = t(this.state.nutritionist!.gender||'', 'sex');
        return <div className='gender-section'>
                <GenderIcon className='gender-icon'/>
                {sex}
               </div>
    }

    renderExpertice(){
        const nutritionist = this.state.nutritionist!;
        const closeButton = <UButton variant='contained' size='small' color='secondary' onClick={()=>{this.setState({showResume: false}) }}>
                            {t('关闭简历')}</UButton>

        return <div className='expertice'>
               {nutritionist.expertIn}
               {this.state.showResume && nutritionist.resume ?
                <div className='resume-section open'>
                    {closeButton}
                    <div className='resume'>
                    <Editor
                        readOnly={true}
                        value={nutritionist.resume}
                    />
                    </div>
                    {closeButton}
                </div>: null
               }
               {!this.state.showResume && nutritionist.resume ?
                <div className='resume-section close'>
                    <UButton variant='contained' size='small' color='secondary' onClick={()=>{ this.setState({showResume: true}) }}>
                        {t('简历')}</UButton>
                </div>: null
               }
               </div>
    }

    renderTestimonial(){
        const nutritionist = this.state.nutritionist!;
        const testimonial = nutritionist.testimonial||[];

        if(testimonial.length<=0) return null;

        return  <div className='testimonial-section'>
                    <p>{t('案例分析')}</p>
                    {testimonial.map(t=>{
                        return  <div className='testimonial'>
                                    <div className='client-name'>{t.clientName}</div>
                                    <div className='client-info'>{t.clientInfo}</div>
                                    <div className='paragraph'>
                                    {(t.testimonial||'').split('\n').map(l=>{
                                        return l? l : <br/>
                                    })}
                                    </div>
                                </div>
                    })}
                </div>
    }

    renderInstitution():JSX.Element {
        let institution = '';
        if(this.state.nutritionist!.businessAddress){
            institution = this.state.nutritionist!.businessAddress.institution||'';
        }
        return <div className='institution'>{institution}</div>
    }

    renderPhone():JSX.Element {
        return <div className='phone-section'>
                <PhoneIcon className='phone-icon'/>
                <SimplePhoneNumber phoneNumber={this.state.nutritionist!.officePhone}/>
               </div>
    }
    renderEmail():JSX.Element {
        return <div className='email-section'>
                <EmailIcon className='email-icon'/>
                <SimpleEmail email={this.state.nutritionist!.officeEmail}/>
               </div>
    }

    renderSvcLanguages():JSX.Element{
        const spokenLanguageOptions = getExternContantsInMemory().spokenLanguageOptions;
        
        const {nutritionist} = this.state;
        const langList = (nutritionist!.language||[]).map(x=> {
            let l = spokenLanguageOptions.find(v=>v.key === x);
            return l? l.label : '';
        }).filter(x=> !!x);

        return <div className='language-section'>
                <LanguageIcon className='language-icon'/>
                <div>
                {langList.join(' ')}    
                </div>
        </div>
    }

    renderAddress():JSX.Element{
        return <div className='address-section'>
                <AddrIcon className='address-icon'/>
                <div className='address'>
                    {AddressInfo2StrArray(this.state.nutritionist!.businessAddress).map(x=>{
                        return <div className='address-line'>{x}</div>
                    })}
                </div>
               </div>
    }
    renderWebAddress():JSX.Element|null{
        const url = this.state.nutritionist!.website||'';
        if(!url) return null;

        return <div className='website-section'>
                <WebSiteIcon className='website-icon'/>
                <SimpleURL url={url} label={this.state.nutritionist!.websiteName || t('我的网页')}/>
               </div>
    }

    renderSocialMedia():JSX.Element{
        const n = this.state.nutritionist!;
        const socialMediaLinks = (n.socialMediaLinks||[]).map(x=>(x||'').trim()).filter(x=>!!x);
        return (
            <Fragment>
                <div className='social-media-section'>
                {n.wechatId || n.wechatPublicId ?    
                    <Fragment>
                        <WechatIcon className='social-media-icon'/>
                        <div>
                            {n.wechatId? <Fragment>{t('微信号')}: {n.wechatId}<br/></Fragment>: null}
                            {n.wechatPublicId ? <Fragment>{t('公众号')}: {n.wechatPublicId}</Fragment> : null }
                        </div>
                    </Fragment>: null
                }
                </div>
                { socialMediaLinks.map(link=>{
                    // https://weibo.com/dealmoon
                    let icon = <URLIcon className='social-media-icon'/>;
                    if(link.match(/weibo[.]com[/]/)){
                        icon = <WeiboIcon className='social-media-icon'/>
                    }else if(link.match(/twitter[.]com[/]/)){
                        icon = <TwitterIcon className='social-media-icon'/>
                    }else if(link.match(/facebook[.]com[/]/)){
                        icon = <FacebookIcon className='social-media-icon'/>
                    }else if(link.match(/tiktok[.]com[/]/)){
                        icon = <TiktokIcon className='social-media-icon'/>
                    }else if(link.match(/linkedin[.]com[/]/)){
                        icon = <LinkedInIcon className='social-media-icon'/>
                    }else if(link.match(/pinterest[.]com[/]/)){
                        icon = <PinterestIcon className='social-media-icon'/>
                    }else if(link.match(/instagram[.]com[/]/)){
                        icon = <InstagramIcon className='social-media-icon'/>
                    }  

                    return <div className='social-media-section'>
                                {icon}
                                <div>
                                    <SimpleURL url={link}/>
                                    {/* <a href={link}>{link}</a> */}
                                </div>
                            </div>
                })
                }
            </Fragment>
        )
    }

    renderServiceTime():JSX.Element {
        return <div className='service-time-section'>
                <TimeIcon className='service-time-icon'/>
                <div>
                { (this.state.nutritionist!.serviceTime||[]).map(x=>{
                    const svctime = convertServiceTime(x);

                    return <div className='service-time-rec'>
                               <div className='service-time-part'>{svctime.daysOfWeek.join(', ')}</div>
                               <div className='service-time-part'>{svctime.time}</div>
                               <div className='tag-section'>
                               {svctime.online? <div className='service-time-part tag'>{t('在线服务')}</div>:null}
                               {svctime.inOffice? <div className='service-time-part tag'>{t('办公时间')}</div>:null}     
                               </div>
                               <div className='service-time-part note'>{svctime.notes}</div>                                                       
                           </div>
                })
                }
                </div>
               </div>
    }

    renderQRCode():JSX.Element {
        return <div className='qrcode-section'>
                <QRCodeIcon className='qrcode-icon'/>
                <a href='#' onClick={(evt)=>{
                    evt.preventDefault();
                    evt.stopPropagation();
                    this.qrCodeDialog!.show(this.state.nutritionist!.name, 
                                            <img src={toImgSrc(this.state.nutritionist!.qrCode.base64Image)} className='nutritionist-qr-code-img'/>,
                                            ()=>{});
                }}
                >{t('显示二维码')}</a>
               </div>
    }

    renderLicense():JSX.Element {
        const nutritionist = this.state.nutritionist!;
        const hasLicense = !!nutritionist.license && !!nutritionist.license.licenceImage;
        const expired = hasLicense && nutritionist.license!.expiration <= Date.now();

        let licenceProps:PropertyListDataItem[] = [];
        if(nutritionist.license){
            const l = nutritionist.license!;
            licenceProps = [
                {name: '姓名', value: l.name},
                {name: '公司', value: l.employer},
                {name: '执照号码', value: l.regNumber},
                {name: '执照类型', value: l.category},
                {name: '失效日期', value: formatDate(new Date(l.expiration))},
            ]
        }

        return <div className='license-section'>
                <LicenseIcon className='license-icon'/>
                {expired? <div className='warning'>{t('执照已失效!')}&nbsp;</div>: null}
                {hasLicense?
                   <div>
                    <PropertyListWidget list={licenceProps} showBorder={false} mergeColScreenLetterRatio={RatioXXS}/>
                    <a href='#' onClick={(evt)=>{
                        evt.preventDefault();
                        evt.stopPropagation();
                        this.qrCodeDialog!.show2({title: this.state.nutritionist!.name, 
                                                  message: <img src={toImgSrc(nutritionist!.license!.licenceImage!.url)} 
                                                                className='nutritionist-license-img'/>,
                                                  className: 'license-image-dialog',
                                                  open: true,              
                                            });
                    }}
                    >{t('显示执照照片')}</a> 
                    </div>: 
                    <div>{t('暂时无执照')}</div>
                }
               </div>
    }

    renderInsurancePolicy():JSX.Element | null {
        const nutritionist = this.state.nutritionist!;
        const hasLicense = !!nutritionist.license;

        const insurancePolicyNumber = hasLicense? nutritionist.license!.insurancePolicyNumber : '';
        return <div className='insurance-section'>
                <InsuranceIcon className='insurance-icon'/>
                <div>
                    <div className='label'>{t('保险号')}</div>
                    <div className='insurance-number'>{insurancePolicyNumber}</div>
                </div>
               </div>
    }

    initializeAgain(){
        this.setState({pageInitState:'PAGE_INIT', initErrorMessage:undefined},
                      ()=>{
                          this.loadData();
                      })
    }

    componentDidMount(){
        this.loadData();
    }

    loadData(){
        const languageCode = getLanguageCode();

        doAJAXCall({
            whatIsFor: this.getTitle(),
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken, username)=>{ 
                                    await loadExternContatnsIfNecessary();
                                    let countryOptions = getCountryOptions(accessToken, languageCode!);
                                    let shoppingAddressspecMap = getAddressSpecMap(accessToken, languageCode!);

                                    let nutritionist = await retrieveNutritionist(accessToken, this.props.nutritionistId || username);
                                    this.setState({nutritionist,countryOptions, shoppingAddressspecMap, pageInitState:'PAGE_LOAD_SUCCESS'});
                                },
            onError: (errorMessage:string)=>{ this.setState({pageInitState:'PAGE_LOAD_FAILED', initErrorMessage:errorMessage})}                                           
        })        
    }

}

