import * as React from 'react';
import {Component, FunctionComponent, SVGProps} from 'react';
import classnames from 'classnames';
import './icon-label.scss';


interface MyProps {
    className?: string;
    Icon: FunctionComponent<SVGProps<SVGSVGElement>>;
    label: string;
    iconClassName?: string;
    onClick?: ()=>void;
}

export class SVGIconWithLabel extends Component<MyProps, {}>{
    constructor(props: MyProps){
        super(props);
    }
    render(){
        const {Icon} = this.props;

        return (
            <div className={classnames('icon-with-label', this.props.className)}
                 onClick={()=>{
                     if(this.props.onClick) {
                        this.props.onClick();
                     }
                 }}
            >
                <Icon className={classnames('icon', this.props.iconClassName)}/>
                <div className='label'>{this.props.label}</div>
            </div>
        )
    }
}