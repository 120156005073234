import classnames from 'classnames';
import * as React from 'react';
import {Component, Fragment} from 'react';
import * as _ from 'lodash';
import {ReactComponent as EditIcon} from '../../images/edit.svg'
import {ReactComponent as DoneIcon} from '../../images/done.svg'
import {ReactComponent as InsertIcon} from '../../images/insert.svg'
import {ReactComponent as MoveUpIcon} from '../../images/move-up.svg'
import {ReactComponent as MoveDownIcon} from '../../images/move-down.svg'
import {ReactComponent as DeleteIcon} from '../../images/close.svg'
import './list-editor.scss';

interface MyProps<T> {
    list: T[];
    maxItems: number;
    dataRender: (arg: {item:T}, readonly: boolean, onChange: (item:T)=>void)=>JSX.Element;
    newData: ()=>T;
    title: string;
    className?: string;
    done: (data: T[])=>void;
}

interface MyState<T> {
    itemList: {item:T}[];
    editMode?: boolean;
}

/**
 * generic component to insert, delete and sort a list of items.
 */
export class ListEditor<T> extends Component< MyProps<T>, MyState<T> >{
    constructor(props: MyProps<T>){
        super(props);
        this.state = {itemList: _.cloneDeep(this.props.list.map(x=>({item: x})))}
    }

    render(){
        const {editMode} = this.state;
        return editMode ?  this.renderEditMode(): this.renderReadonly();
    }

    renderReadonly(){
        return <div className={classnames('list-editor', 'readonly', this.props.className)}>
            <div className='title'>{this.props.title}</div>
            <EditIcon className='list-editor-icon editor-icon' onClick={()=>{ this.setState({editMode: true})}}/>
            {   this.state.itemList.map(x=>{
                    return <div className='list-item'>{ this.props.dataRender(x, true, ()=>{} ) }</div>
                })
            }
        </div>
    }

    insertBefore(pos:number){
        if(this.state.itemList.length >= this.props.maxItems) return;

        this.state.itemList.splice(pos, 0, {item: this.props.newData()})
        this.forceUpdate();
    }
    moveUp(pos:number){
        if(pos>0){
            const prev = this.state.itemList[pos-1];
            this.state.itemList[pos-1] = this.state.itemList[pos];
            this.state.itemList[pos] = prev;
            this.forceUpdate();
        }
    }
    moveDown(pos:number){
        if(pos < this.state.itemList.length-1){
            const next = this.state.itemList[pos + 1];
            this.state.itemList[pos+1] = this.state.itemList[pos];
            this.state.itemList[pos] = next;
            this.forceUpdate();
        }
    }
    delete(pos:number){
        this.state.itemList.splice(pos, 1)
        this.forceUpdate();        
    }

    getList():T[]{
        return this.state.itemList.map(x=>x.item);
    }

    renderEditMode(){
        const {itemList: data} = this.state;

        return <div className={classnames('list-editor', 'edit', this.props.className)}>
            <div className='title'>{this.props.title} (max:{this.props.maxItems})</div>
            <DoneIcon className='list-editor-icon editor-icon' 
                      onClick={()=>{ this.setState({editMode: false}, ()=>{
                          this.props.done(this.state.itemList.map(x=>x.item));
                      })}}/>
            {   this.state.itemList.map((x, index)=>{
                    return <div className='list-item'>
                                <div className='action-icons'>
                                    <span>{index+1}.</span>
                                    <InsertIcon onClick={()=>{ this.insertBefore(index)}}  className='list-editor-icon insert-icon'/> 
                                    <MoveUpIcon onClick={()=>{ this.moveUp(index)}} className='list-editor-icon'/> 
                                    <MoveDownIcon onClick={()=>{ this.moveDown(index)}} className='list-editor-icon'/> 
                                    <DeleteIcon onClick={()=>{ this.delete(index)}} className='list-editor-icon delete-icon'/> 
                                </div>
                                {this.props.dataRender(x, false, (item:T)=>{ this.forceUpdate()    })}
                            </div>
                })
            }
            <div className='insert'>
                <InsertIcon onClick={()=>{ this.insertBefore(data.length)}} className='list-editor-icon'/>
            </div>
        </div>
    }
}