import { Accordion, AccordionDetails, AccordionSummary, Badge, Button, Checkbox, FormControlLabel, Radio } from '@material-ui/core';
import * as React from 'react';
import {Component, Fragment} from 'react';
import { t } from '../../i18n';
import { NoticeDialog } from '../../view/NoticeDialog';
import { CountryOption, ShippingCourierMethod, ShippingPickupMethod, BillingAddress, ShoppingAddressSpec, 
         ShoppingPickupAddress, ShippingMethod, CurrencyOption, WeightOption, PaymentMethod, ShoppingCheckoutOrder, ShippingAddress, AddressInfo2Str, PhotoInfo, ShoppingCheckoutResponse, ShopPaymentRequestData, PaymentMethodList, ProductType, ShoppingCartInfo, getShoppingCartProductType } from '../ajax-data/ajax-data-types';
import { getAddressSpecMap, getCountryOptions, getMyBillingAddresses, getPickupAddresses, getMyShippingAddresses, 
         calcShippingCostOfShoppingCart, getConfirmationOrder, 
          createBillingAddress, deleteBillingAddress, updateBillingAddress, savePhoto, 
         createShippingAddress, updateShippingAddress, deleteShippingAddress, getPickupLocation, getMyShoppingCart} from '../ajax-data/shopping-server-api';
import { doAJAXCall } from '../common/ajax-call';
import { BaseShoppingPageProps} from '../common/shopping-page-common';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import classNames from 'classnames';
import * as _ from 'lodash';

import './checkout.scss';
import { connect } from 'react-redux';
import { WalletAppState } from '../../redux-store';
import { ShoppingAddressForm } from '../common/address-form';
import { ImageWithOverlay } from '../common/image-with-overlay';
import { retrieveUnishellPoints } from '../../wallet-server-api/wallet-server-api';

import UnisLogo from '../../images/unishell-head.png';
import WechatPayLogo from '../../images/Wechat_Pay_Chinese_Logo.svg';
import AliPayLogo from '../../images/Alipay_logo.svg';
import CreditCardLogo from '../../images/credit-card-logos.png';
import { readCheckoutRequest, readLoginSession, saveCheckoutRequest } from '../../local-storage/local-storage';
import {ReactComponent as CheckSvg} from '../../images/draw-check-mark.svg';
import { RenderShoppingCartHealthAdvice2, RenderSingleShoppingCartProduct, RenderShoppingOrderSummary } from '../shopping-order/render-order-items';
import { Toast } from '../../view/Toast';
import { getPaymentSupport, getServerURL } from '../../util/Util';
import { gotoShoppingPathNSavePrevPath, gotoShoppingPathWOSavingPrevPath } from '../history/shopping-history';
import { getExternContantsInMemory, LANG_CODE_ZH_CN, loadExternContatnsIfNecessary } from '../../external-constants/extern-constants';
import { info } from '../../globals';
import { BaseUnishellPageState, renderInitErrorUnishellPage, renderInitUnishellPage, renderUnishellPage } from '../../unishell-page-common/unishell-page-common';
import { CheckBox } from '@material-ui/icons';
import { UButton } from '../../widget/button/ubutton';
import { PagedResponse } from '../../api/transaction';
import { PaginationSelect } from '../../widget/pagination-select/pagination-select';

type CheckoutStep = 'billing-address'|'shipping-method'|'payment-method'|'confirm-order';


interface ShoppingCheckoutProps extends BaseShoppingPageProps {
    currencyCode: string;
    step?: CheckoutStep;
    billingAddress?: BillingAddress;
}

interface ShoppingCheckoutState extends BaseUnishellPageState {
    shoppingCartRecord?: ShoppingCartInfo;
    productType: ProductType;

    currencyOptionsMap?: {[key:string]:CurrencyOption[]};
    weightOptionsMap?: {[key:string]:WeightOption[]};

    countryOptions?:CountryOption[];
    shoppingAddressspecMap?: {[key:string]:ShoppingAddressSpec};

    pickupAddresses?: PagedResponse<ShoppingPickupAddress>;
    myShippingAddresses?: ShippingAddress[];

    myBillingAddresses?: BillingAddress[];

    /** selected billing address */
    billingAddress?: BillingAddress;
    /** billing address being modified by user */
    billingAddressModified?: BillingAddress;

    shippingMethod?: ShippingCourierMethod|ShippingPickupMethod;
    shippingAddressModified?: ShippingAddress;

    shippingCost?: number;
    step?: CheckoutStep;

    // sub state of billing-address
    createBillingAddress?: boolean;

    // sub state of shipping-method
    shippingMethodType?: ShippingMethod["type"];

    // courier sub statw
    createShippingAddress?: boolean;

    unishellPoints?: number;

    paymentMethod?: PaymentMethod;

    checkoutResponse?: ShoppingCheckoutResponse;

    supportAlipay?: boolean;
    supportWechatPay?: boolean;
}

const PickupLocationPageSize = 4;

class ShoppingCheckoutView extends Component<ShoppingCheckoutProps, ShoppingCheckoutState>{
    private errorDialog:NoticeDialog|undefined;
    private billingAddressForm: ShoppingAddressForm | undefined;
    private shippingAddressForm: ShoppingAddressForm | undefined;
    private toast: Toast | undefined;

    constructor(props: ShoppingCheckoutProps){
        super(props);
        this.state = {pageInitState:'PAGE_INIT', productType: 'chanpin'}
    }
    getTitle(){
        if(this.state.shoppingCartRecord && this.state.productType==='test'){
            return t('结帐(健康检测)');
        }else{
            return t('结帐');
        }
    }

    useShippingAddressAsBillingAddress() {
        if(!!this.state.shippingMethod && 
            this.state.shippingMethod.type==='courier' && 
            this.state.shippingMethod._useAsBillingAddress){
           return true;
       }
       return false;
    }

    completedBillingAddress():boolean{
        return this.useShippingAddressAsBillingAddress() || !!this.state.billingAddress;
    }
    completedShippingMethod():boolean{
        return this.state.productType==='test' || !!this.state.shippingMethod;
    }
    completedPaymentMethod(): boolean {
        return !!this.state.paymentMethod;
    }

    render(){
        if(this.state.pageInitState === 'PAGE_INIT'){
            return <React.Fragment>
                        {renderInitUnishellPage({title: this.getTitle(), message:t('初始化...')})}
                        <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                        <Toast close={true} duration={2500} message='' ref={e=>{this.toast=e!}} toastType='info'/>
                    </React.Fragment>
        }
        if(this.state.pageInitState === 'PAGE_LOAD_FAILED'){
            return <React.Fragment>
                        {renderInitErrorUnishellPage({title: this.getTitle(), 
                                                      errorMessage: this.state.initErrorMessage||t('初始化失败'),
                                                      onTryAgain: ()=>{ this.initializeAgain();    }
                                                      })}
                        <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                        <Toast close={true} duration={2500} message='' ref={e=>{this.toast=e!}} toastType='info'/>
                    </React.Fragment>
        }
        // loading data succeeded
        let content = this.renderSucceedContent();
        return (
            <React.Fragment>
                { renderUnishellPage({title: this.getTitle(), className:'shopping-checkout', content})}
                <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                <Toast close={true} duration={2500} message='' ref={e=>{this.toast=e!}} toastType='info'/>
            </React.Fragment>
        )   
    }

    insufficientUnis():boolean {
        return !!this.state.checkoutResponse && this.state.paymentMethod === 'Wallet' && 
               this.state.unishellPoints! < this.state.checkoutResponse.finalPrice;
    }

    renderSucceedContent():JSX.Element {
        return (
            <div className='shopping-checkout-content'>
               { this.renderShippingAddress()}
               { this.renderBillingAddress()}
               { this.renderPaymentMethod()}
               { this.renderConfirmation()}
               <div className='checkout-actions'>
                {this.state.step === 'confirm-order' && this.state.checkoutResponse?
                 <Fragment>
                    <UButton variant='contained' color='secondary' id='shopping-button'
                            onClick={()=>{
                                gotoShoppingPathNSavePrevPath('main/shopping/search', {});
                            }}
                    >{t('继续购物')}</UButton>
                    <UButton variant='contained' color='primary' disabled={!this.state.checkoutResponse || this.insufficientUnis()}
                            onClick={ ()=>{ this.handleDoPayment() }}
                    >{t('付款')}</UButton>
                 </Fragment> : null
                }
               </div>
            </div>
        )
    }

    handleDoPayment(){
        const session = readLoginSession()!;
        const useShippingAddressAsBillingAddress = this.useShippingAddressAsBillingAddress();

        let shippingAddressId: string | undefined;
        let pickupAddressId: string | undefined;

        if(this.state.productType === 'chanpin'){
            shippingAddressId = this.state.shippingMethod!.type==='courier'? this.state.shippingMethod!.address.addressId: undefined;
            pickupAddressId = this.state.shippingMethod!.type==='pickup'? this.state.shippingMethod!.address.addressId: undefined;
        }

        let paymentData:ShopPaymentRequestData = {
                billingAddressId: useShippingAddressAsBillingAddress? this.state.shippingMethod!.address.addressId: this.state.billingAddress!.addressId,
                shippingAddressId,
                pickupAddressId,
                paymentMethod: this.state.paymentMethod!,
                totalAmount: this.state.checkoutResponse!.paymentAmount,
                currencyCode: this.state.checkoutResponse!.paymentCurrencyCode,
                username: session.profile!.username,                    
        }
        gotoShoppingPathNSavePrevPath('main/shopping/payment', {paymentData});
    }

    formatShoppingAddress(addr:BillingAddress):string {
        let stringArray:string[] = [];
    
        addr.name && stringArray.push(addr.name);
        addr.address1 && stringArray.push(addr.address1);
        addr.address2 && stringArray.push(addr.address2);
        addr.address2 && stringArray.push(addr.address3);
        addr.address2 && stringArray.push(addr.address4);
        if(addr.countryOrRegion){
            let country = this.state.countryOptions!.find(x => x.code === addr.countryOrRegion);
            if(country){
                stringArray.push(country.label);
            }
        } 
        addr.postalCode && stringArray.push(t('邮编')+': '+addr.postalCode);
    
        return stringArray.join(', ');
    }

    formatPickupAddress(addr:ShoppingPickupAddress):JSX.Element {
        return  <Fragment>
                { <div>{AddressInfo2Str(addr)}</div>}
                { <div>Tel:{addr.phoneNumber}, {addr.name}</div>}
                {addr.comment.split('\n').map(ln => <div>{ln}</div>)}
                </Fragment>
        
    }

    renderBillingAddress():JSX.Element {
        const useShippingAddressAsBillingAddress = this.useShippingAddressAsBillingAddress();
        return (
            <Accordion expanded={this.state.step==='billing-address'} 
                       className={classNames('billing-address-accordion')}
                       onChange={(evt, isExpanded)=>{ 
                            if(isExpanded){
                                this.setState({step:'billing-address'})
                            }else{
                                this.setState({step:undefined})
                            }
                        }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Badge color='primary' badgeContent='2' />
                    <div className='accordion-label'>
                        {t('账单地址')}  
                        {this.completedBillingAddress() && <CheckSvg className='done-icon'/>} &nbsp;
                        {useShippingAddressAsBillingAddress ? <span style={{fontSize:'90%', fontWeight:'normal'}}>{t('使用货运地址')}</span>: null}        
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                { 
                  this.state.createBillingAddress ?
                  <Fragment>
                      <p className='header'>{t('输入新账单地址')}</p>
                    <ShoppingAddressForm countryOptons={this.state.countryOptions!} 
                                         addressSpecMap={this.state.shoppingAddressspecMap!}
                                         addressType='BillingAddress'
                                         readonly={false}
                                         ref={ x =>{this.billingAddressForm = x!}}
                                         /> 
                    <div className='create-billing-address-actions'>
                        <UButton variant='contained' color='secondary' size='small'
                                onClick={ () => {this.setState({createBillingAddress: false})}}
                        >{t('取消')}</UButton>
                        <UButton variant='contained' color='primary' size='small'
                                onClick={() => {
                                    let {addr, hasError} = this.billingAddressForm!.validateAndGetAddress(); 
                                    if(!hasError){
                                        let newAddr = addr;
                                        doAJAXCall({
                                            whatIsFor:t('创建新账单地址'),
                                            errorDialog: this.errorDialog!,
                                            ajaxCall: async (accessToken, username)=>{
                                                newAddr!.username = username;
                                                // @ts-ignore
                                                delete newAddr!.addressId;
                                                let returnAddr = await createBillingAddress(accessToken, newAddr!);
                                                let myBillingAddresses = (this.state.myBillingAddresses || []).slice();
                                                myBillingAddresses.push(returnAddr);
                                                this.setState({myBillingAddresses, createBillingAddress: false}, 
                                                               ()=>{
                                                                   this.toast!.sendMessage(t('创建新账单地址'), 'success');
                                                               });
                                            }
                                        })
                                    }else{
                                        this.toast!.sendMessage(t(`请修正表格中的错误`), 'info');
                                    }
                                }}
                        >{t('保存')}</UButton>
                    </div>                     
                  </Fragment>
                  : this.state.billingAddressModified ?
                  <Fragment>
                      <p className='header'>{t('更新账单地址')}</p>
                    <ShoppingAddressForm countryOptons={this.state.countryOptions!} 
                                         addressSpecMap={this.state.shoppingAddressspecMap!}
                                         addressType='BillingAddress'
                                         readonly={false}
                                         initialAddress={this.state.billingAddressModified}
                                         ref={ x =>{this.billingAddressForm = x!}}
                                         /> 
                    <div className='create-billing-address-actions'>
                        <UButton variant='contained' color='secondary' size='small'
                                onClick={ () => {this.setState({billingAddressModified: undefined})}}
                        >{t('取消')}</UButton>
                        <UButton variant='contained' color='primary' size='small'
                                onClick={() => {
                                    let {addr, hasError} = this.billingAddressForm!.validateAndGetAddress(); //.addr as BillingAddress;
                                    let billingAddress = this.state.billingAddress;

                                    if(!hasError){
                                        let updatedAddr = addr as BillingAddress;
                                        doAJAXCall({
                                            whatIsFor:t('更新账单地址'),
                                            errorDialog: this.errorDialog!,
                                            ajaxCall: async (accessToken, username)=>{
                                                await updateBillingAddress(accessToken, updatedAddr!);
                                                let myBillingAddresses = (this.state.myBillingAddresses || []).slice();
                                                for(let i=0; i<myBillingAddresses.length; i++){
                                                    if(updatedAddr!.addressId === myBillingAddresses[i].addressId){
                                                        myBillingAddresses[i] = updatedAddr!;
                                                        if(billingAddress && billingAddress.addressId===updatedAddr!.addressId){
                                                            billingAddress = updatedAddr;
                                                        }
                                                    }
                                                }
                                                this.setState({myBillingAddresses,billingAddress, billingAddressModified: undefined}, 
                                                               ()=>{
                                                                   this.toast!.sendMessage(t('更新账单地址'), 'success');
                                                               });
                                            }
                                        })
                                    }else{
                                        this.toast!.sendMessage(t(`请修正表格中的错误`), 'info');
                                    }
                                }}
                        >{t('保存')}</UButton>
                    </div>    
                  </Fragment>
                  :
                  <Fragment>
                    {useShippingAddressAsBillingAddress? null:
                        <div>
                            <UButton size='small' variant='contained' color='primary' id='new-billing-address-button'
                                    onClick={()=>{ this.setState({createBillingAddress: true})  }}
                            >{t('输入新地址')}</UButton>
                        </div>
                    }
                    {(  () => {
                        if(useShippingAddressAsBillingAddress) return null;

                        let addressList = (this.state.myBillingAddresses||[]).map(x=>({...x, isBillingAddr:true}));
                        addressList = addressList.concat((this.state.myShippingAddresses||[]).map(x=>({...x, isBillingAddr:false})));
                        if(addressList.length <= 0) return null;

                        return( 
                            <Fragment>
                                <div>{t('选择一个现有的地址')}</div>
                                <table>
                                <tbody>
                                {   (addressList).map(addr=>{
                                        let checked = this.state.billingAddress && this.state.billingAddress.addressId === addr.addressId;
                                        
                                        return <tr key={addr.addressId+' '+checked}>
                                            <td>
                                                <Checkbox checked={checked} 
                                                          onChange={()=>{
                                                            const checkoutInLS = readCheckoutRequest();
                                                            if(checked){
                                                                    this.setState({billingAddress: undefined, checkoutResponse: undefined},
                                                                                  ()=>{
                                                                                        checkoutInLS.billingAddressId = undefined;
                                                                                        saveCheckoutRequest(checkoutInLS);
                                                                                    });
                                                              }else{
                                                                    this.setState({billingAddress: addr, checkoutResponse: undefined},
                                                                                  ()=>{
                                                                                    checkoutInLS.billingAddressId = addr.addressId;
                                                                                    saveCheckoutRequest(checkoutInLS);
                                                                                });
                                                              }
                                                          }}
                                                />
                                            </td>
                                            <td>{this.formatShoppingAddress(addr)} 
                                                {addr.isBillingAddr? <div className='billing-address-actions'>
                                                    <UButton size='small' variant='outlined' color='alert'
                                                            disabled={checked}
                                                            onClick={()=>{
                                                                doAJAXCall({
                                                                    whatIsFor:t('删除账单地址'),
                                                                    errorDialog: this.errorDialog!,
                                                                    ajaxCall: async (accessToken) => {
                                                                        await deleteBillingAddress(accessToken, addr.addressId);
                                                                        let myBillingAddresses = this.state.myBillingAddresses!.filter(x=> x.addressId !== addr.addressId);
                                                                        this.setState({myBillingAddresses}, ()=>{
                                                                            this.toast!.sendMessage(t('删除账单地址'), 'success');
                                                                        })
                                                                    }
                                                                })
                                                            }}

                                                    >{t('删除')}</UButton> &nbsp;
                                                    <UButton size='small' variant='outlined' color='primary'
                                                            onClick={()=>{
                                                                this.setState({billingAddressModified: addr});
                                                            }}
                                                    >{t('修改')}</UButton>
                                                </div>: null}
                                            </td>
                                        </tr>
                                    })
                                }
                                </tbody>
                            </table>
                        </Fragment>
                        )
                    }  )()}
                  </Fragment>
                }


                </AccordionDetails>
            </Accordion>
        )
    }

    loadPickupLocations = (page:number, pageSize: number, onData:(searchResult:PagedResponse<ShoppingPickupAddress>)=>void)=>{
        doAJAXCall({
            whatIsFor: t('下载数据'),
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken)=>{
                let pickupAddresses = await getPickupAddresses(accessToken, {page, pageSize});
                onData(pickupAddresses);
            }
        })
    }

    renderPickupShippingMethod():JSX.Element|null {
        if(this.state.shippingMethodType !== 'pickup'){
            return null;
        }

        const updatePickupMethod = (m:ShippingPickupMethod|undefined)=>{
            const checkoutInLS = readCheckoutRequest();
            this.setState({shippingMethod: m, checkoutResponse: undefined}, 
                            ()=>{
                                if(m){
                                    checkoutInLS.shippingMethod = {
                                        type: 'pickup',
                                        addressId: m.address.addressId
                                    }    
                                }else{
                                    checkoutInLS.shippingMethod = undefined;
                                }
                                saveCheckoutRequest(checkoutInLS);
                            });
        }
        return <div className='shipping-method-pickup'>
            <div>{t('选择一个取货地点信息')}</div>
            <PaginationSelect
                page={0}
                pageSize={PickupLocationPageSize}
                expanded={true}
                searchLabel={t('搜索取货地点信息')}
                collapseLabel={t('隐藏搜索')}
                currentSelect={this.state.shippingMethod && this.state.shippingMethod.type === 'pickup'? this.state.shippingMethod.address: undefined}
                className='select-pickup-location'
                searchResult={this.state.pickupAddresses!}
                loadPaginationData={this.loadPickupLocations}
                select={addr=>{
                    updatePickupMethod({type:'pickup', address: addr})
                 }}
                deselect={pickupAddresses=>{
                    updatePickupMethod(undefined);
                 }}
                isSame={(a1:any,a2:any)=> a1.addressId === a2.addressId}
                renderItem={(address)=>{
                    return this.formatPickupAddress(address);
                }}
            />
        </div>
    }

    onCreateShippingAddress(){
        const {addr, uploadedFrontImg, uploadedBackImg, hasError} = this.shippingAddressForm!.validateAndGetAddress();
        if(hasError){
            this.toast!.sendMessage(t(`请修正表格中的错误`), 'info');
            return;
        }
        let frontPhoto: PhotoInfo | undefined;
        let backPhoto: PhotoInfo | undefined;

        {
            const shippingAddr = addr as ShippingAddress;
            let identityPhoto = shippingAddr.identityPhoto || [];

            doAJAXCall({
                whatIsFor: t('保存新收件地址'),
                errorDialog: this.errorDialog!,
                ajaxCall: async (accessToken, username) => {
                    if(uploadedFrontImg){
                        frontPhoto = await savePhoto(accessToken, 
                                                     uploadedFrontImg.file!, 
                                                     'photo', 
                                                     JSON.stringify({side:'front', t:'china-id-front', username}));
                        identityPhoto = identityPhoto.filter(p => (p.tag||'').indexOf('front')<0);
                        identityPhoto.push(frontPhoto);
                    }
                    if(uploadedBackImg){
                        backPhoto = await savePhoto(accessToken, 
                                                    uploadedBackImg.file!, 
                                                     'photo', 
                                                     JSON.stringify({side:'back', t:'china-id-back', username}))
                        identityPhoto = identityPhoto.filter(p => (p.tag||'').indexOf('back')<0);
                        identityPhoto.push(backPhoto);
                    }
                    if(uploadedFrontImg || uploadedBackImg){
                        shippingAddr.identityPhoto = identityPhoto;
                    }
                    shippingAddr.username = username;
                    // @ts-ignore
                    delete shippingAddr.addressId;
                    let newShippingAddr = await createShippingAddress(accessToken, shippingAddr);
                    let myShippingAddresses = this.state.myShippingAddresses!;
                    myShippingAddresses!.push(newShippingAddr);
                    this.setState({myShippingAddresses, createShippingAddress: false}, 
                                   ()=>{
                                        this.toast!.sendMessage(t('保存新收件地址'),'success')
                                    }
                                )

                }
            })
        }

    }

    onUpdateShippingAddress(){
        const {addr, hasError, uploadedFrontImg, uploadedBackImg} = this.shippingAddressForm!.validateAndGetAddress();
        if(hasError){
            this.toast!.sendMessage(t(`请修正表格中的错误`), 'info');
            return;
        }
        let frontPhoto: PhotoInfo | undefined;
        let backPhoto: PhotoInfo | undefined;

        {
            const shippingAddr = addr as ShippingAddress;
            let identityPhoto = shippingAddr.identityPhoto || [];

            doAJAXCall({
                whatIsFor: t('保存收件地址'),
                errorDialog: this.errorDialog!,
                ajaxCall: async (accessToken, username) => {
                    if(uploadedFrontImg){
                        frontPhoto = await savePhoto(accessToken, 
                                                     uploadedFrontImg.file!, 
                                                     'photo', 
                                                     JSON.stringify({side:'front', t:'china-id-front', username}));
                        identityPhoto = identityPhoto.filter(p => (p.tag||'').indexOf('front')<0);
                        identityPhoto.push(frontPhoto);
                    }
                    if(uploadedBackImg){
                        backPhoto = await savePhoto(accessToken, 
                                                    uploadedBackImg.file!, 
                                                     'photo', 
                                                     JSON.stringify({side:'back', t:'china-id-back', username}))
                        identityPhoto = identityPhoto.filter(p => (p.tag||'').indexOf('back')<0);
                        identityPhoto.push(backPhoto);
                    }
                    if(uploadedFrontImg || uploadedBackImg){
                        shippingAddr.identityPhoto = identityPhoto;
                    }
                    let newShippingAddr = await updateShippingAddress(accessToken, shippingAddr);

                    let shippingMethod = this.state.shippingMethod;
                    // update modified shipping address in state
                    let myShippingAddresses = this.state.myShippingAddresses!;
                    for(let i=0; i<myShippingAddresses.length; i++){
                        if(myShippingAddresses[i].addressId === newShippingAddr.addressId){
                            myShippingAddresses[i] = newShippingAddr;
                            if(shippingMethod && shippingMethod.type==='courier'){
                                if(shippingMethod.address.addressId === newShippingAddr.addressId){
                                    shippingMethod.address = newShippingAddr;
                                }
                            }
                        }
                    }
                    this.setState({myShippingAddresses, shippingMethod, createBillingAddress: false, shippingAddressModified: undefined}, 
                                   ()=>{
                                        this.toast!.sendMessage(t('保存收件地址'),'success')
                                    }
                                )
                }
            })
        }

    }

    renderCourierShippingMethod():JSX.Element|null {
        if(this.state.shippingMethodType !== 'courier'){
            return null;
        }
        
        if(this.state.createShippingAddress){
            return (
                <div className='shipping-method-courier'>
                    <div className='header'>{t('新收件地址')}</div>
                    <ShoppingAddressForm countryOptons={this.state.countryOptions!} 
                                         addressSpecMap={this.state.shoppingAddressspecMap!}
                                         addressType='ShippingAddress'
                                         readonly={false}
                                         ref={x => { this.shippingAddressForm = x! }}
                                         /> 
                    <div className='create-shipping-address-actions'>
                        <UButton variant='contained' color='secondary' size='small'
                                onClick={()=>{ this.setState({createShippingAddress: false})}}
                        >{t('取消')}</UButton>
                        <UButton variant='contained' color='primary' size='small'
                                onClick={()=>{ this.onCreateShippingAddress() }}
                        >{t('保存')}</UButton>
                    </div>                     
                </div>
            )
        }
        if(this.state.shippingAddressModified){
            return (
                <div className='shipping-method-courier'>
                    <div className='header'>{t('修改收件地址')}</div>
                    <ShoppingAddressForm countryOptons={this.state.countryOptions!} 
                                         addressSpecMap={this.state.shoppingAddressspecMap!}
                                         addressType='ShippingAddress'
                                         initialAddress={this.state.shippingAddressModified}
                                         readonly={false}
                                         ref={x => { this.shippingAddressForm = x! }}
                                         /> 
                    <div className='create-shipping-address-actions'>
                        <UButton variant='contained' color='secondary' size='small'
                                onClick={()=>{ this.setState({shippingAddressModified: undefined})}}
                        >{t('取消')}</UButton>
                        <UButton variant='contained' color='primary' size='small'
                                onClick={()=>{ this.onUpdateShippingAddress() }}
                        >{t('保存')}</UButton>
                    </div>                     
                </div>
            )
        }

        const updateCourierMethod = (m: ShippingCourierMethod|undefined)=>{
            const checkoutInLS = readCheckoutRequest();
            this.setState({shippingMethod: m, checkoutResponse: undefined}, 
                         ()=>{
                            if(m){
                                checkoutInLS.shippingMethod = {
                                    type: 'courier',
                                    addressId: m.address.addressId, // sufficient for local storage
                                    _useAsBillingAddress: m._useAsBillingAddress
                                }
                            }else{
                                checkoutInLS.shippingMethod = undefined;
                            }
                            saveCheckoutRequest(checkoutInLS);
                         })
        }


        return (
            <div className='shipping-method-courier'>
                <div>
                    <UButton variant='contained' color='primary' size='small'
                            onClick={()=>{ this.setState({createShippingAddress: true})  }}
                    >{t('新收件地址')}</UButton>
                </div>
                <div className='header'>{t('选择一个收件地址')}</div>
                <table>
                    <tbody>
                        {this.state.myShippingAddresses!.map((shippingAddr, idx)=>{
                            const IDFrontImage = (shippingAddr.identityPhoto||[]).find(p=> (p.tag||'').indexOf('front')>0);
                            const IDBackImage = (shippingAddr.identityPhoto||[]).find(p=> (p.tag||'').indexOf('back')>0);

                            const shippingMethod = this.state.shippingMethod as ShippingCourierMethod;

                            const checked = !!shippingMethod && shippingAddr.addressId === this.state.shippingMethod!.address.addressId;
                            info(`  checked=${checked}`);
                            return <tr key={shippingAddr.addressId+' '+checked}>
                                <td>
                                    <Checkbox checked={checked} onClick={()=>{
                                        if(checked){
                                            updateCourierMethod(undefined);
                                        }else{
                                            updateCourierMethod({type:'courier', address: shippingAddr});
                                        }
                                    }}/>
                                </td>
                                <td>
                                    {this.formatShoppingAddress(shippingAddr)} 
                                    <div className='chinese-id-photos'>
                                    { IDFrontImage ? <ImageWithOverlay imageList={[getServerURL()+IDFrontImage!.url]}/> : null}
                                    { IDBackImage ? <ImageWithOverlay imageList={[getServerURL()+IDBackImage!.url]} /> : null}
                                    </div>
                                    {checked?
                                            <div>
                                                <FormControlLabel 
                                                    control={<Checkbox color='primary' checked={shippingMethod && shippingMethod._useAsBillingAddress} 
                                                    onChange={evt=>{
                                                        shippingMethod._useAsBillingAddress = !shippingMethod._useAsBillingAddress;
                                                        updateCourierMethod({type:'courier', address: shippingAddr, _useAsBillingAddress: shippingMethod._useAsBillingAddress});
                                                    }}/>} 
                                                    label={t('设为账单地址')} />
                                            </div>: null
                                    }
                                    <div className='shipping-address-actions'>
                                        <UButton size='small' variant='outlined' color='alert' disabled={checked}
                                                onClick={()=>{
                                                    doAJAXCall({
                                                        whatIsFor:t('删除收件地址'),
                                                        errorDialog: this.errorDialog!,
                                                        ajaxCall: async (accessToken) => {
                                                            await deleteShippingAddress(accessToken, shippingAddr.addressId);
                                                            let myShippingAddresses = this.state.myShippingAddresses!.filter(x=> x.addressId !== shippingAddr.addressId);
                                                            this.setState({myShippingAddresses}, ()=>{
                                                                this.toast!.sendMessage(t('删除收件地址'), 'success');
                                                            })
                                                        }
                                                    })
                                                }}
                                                
                                        >{t('删除')}</UButton> &nbsp;
                                        <UButton size='small' variant='outlined' color='primary'
                                                onClick={()=>{ this.setState({shippingAddressModified: shippingAddr})}}
                                        >{t('修改')}</UButton>
                                    </div>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
        )
    }

    getCurrencyAndWeightOption(overrideCurrency?: string){
        let currencyOptions = this.state.currencyOptionsMap![this.props.languageCode!];
        if(!currencyOptions){
            currencyOptions = this.state.currencyOptionsMap![LANG_CODE_ZH_CN];
        }
        let currencyOption = currencyOptions.find(x=>x.code === (overrideCurrency || this.props.currencyCode!));
        if(!currencyOption){
            currencyOption = currencyOptions.find(x=>x.code === 'CAD');
        }
        const currencySymbol = currencyOption!.symbol;
        const currencyLabel = currencyOption!.label;

        let weightOptions = this.state.weightOptionsMap![this.props.languageCode!];
        if(!weightOptions){
            weightOptions = this.state.weightOptionsMap![LANG_CODE_ZH_CN];
        }
        return {currencySymbol, currencyLabel, weightOptions}
    }

    renderShippingAddress():JSX.Element {
        const {currencySymbol, currencyLabel, weightOptions} = this.getCurrencyAndWeightOption();
        return (
            <Accordion expanded={this.state.step==='shipping-method'} 
                       className='shipping-address-accordion'
                       onChange={(evt, isExpanded)=>{ 
                                    if(isExpanded){
                                        this.setState({step:'shipping-method'})
                                    }else{
                                        this.setState({step:undefined})
                                    }
                                }
                        }>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Badge color='primary' badgeContent='1' />
                    <div className='accordion-label'>{t('递送方式')} 
                        {this.completedShippingMethod()? <CheckSvg className='done-icon'/> : null}
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                {this.state.productType === 'chanpin' ?
                   <div className='shipping-method-type'>
                        <FormControlLabel 
                            control={<Checkbox checked={this.state.shippingMethodType==='courier'} 
                                                onChange={()=>{
                                                    if(this.state.shippingMethodType === 'courier'){
                                                        this.setState({shippingMethodType: undefined, shippingMethod: undefined});
                                                    }else{
                                                        this.setState({shippingMethodType: 'courier', shippingMethod: undefined});
                                                    }
                                                }}/>} 
                            label={t('速递') + `(${currencySymbol}${this.state.shippingCost!.toFixed(2)}${currencyLabel})`}/>
                        <FormControlLabel 
                            control={<Checkbox checked={this.state.shippingMethodType==='pickup'}
                                                onChange={()=>{
                                                    if(this.state.shippingMethodType === 'pickup'){
                                                        this.setState({shippingMethodType: undefined, shippingMethod: undefined});
                                                    }else{
                                                        this.setState({shippingMethodType: 'pickup', shippingMethod: undefined});
                                                    }
                                                }}
                                    />} 
                            label={t('自提')}/>
                    </div>: 
                    <div>{t('请联络您的执业营养师获取采样过程和检测报告')}</div>

                }  
                {this.state.productType === 'chanpin' ?
                   <Fragment>
                        { this.renderPickupShippingMethod() }
                        { this.renderCourierShippingMethod() } 
                   </Fragment>: null
                }  
                </AccordionDetails>
            </Accordion>
        )
    }

    godoPurchasePoints(){
        gotoShoppingPathWOSavingPrevPath('main/wallet/purchase-points', 
                                    {
                                        returnPathLabel: t('返回商城'),
                                        returnPath: 'main/shopping/checkout'
                                    });
    }

    renderPaymentMethod():JSX.Element {
        const {supportWechatPay, supportAlipay} = this.state;
        
        const setPaymentMethod = (m:PaymentMethod|undefined)=>{
            const checkoutInLS = readCheckoutRequest();
            this.setState({paymentMethod: m, checkoutResponse: undefined},
                ()=>{
                    checkoutInLS.paymentMethod = m;
                    saveCheckoutRequest(checkoutInLS);
                });
        }

        return (
            <Accordion expanded={this.state.step==='payment-method'} 
                       className='payment-method-accordion'
                        onChange={(evt, isExpanded)=>{ 
                            if(isExpanded){
                                this.setState({step:'payment-method'})
                            }else{
                                this.setState({step:undefined})
                            }
                        }
            }>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Badge color='primary' badgeContent='3' />
                    <div className='accordion-label'>{t('付款方式')} {this.completedPaymentMethod() && <CheckSvg className='done-icon'/>}
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <div className='current-points'>
                        <strong>{t('当前U点')} {this.state.unishellPoints!.toFixed(2)}</strong>
                        <UButton size='small' variant='outlined' color='primary'
                                        onClick={()=>{ this.godoPurchasePoints() }}
                                >{t('充值')}</UButton>
                    </div>

                    <table>
                        <tbody>
                            <tr>
                                <td><Radio checked={this.state.paymentMethod === 'Wallet'} 
                                           onChange={()=>{
                                               if(this.state.paymentMethod === 'Wallet'){
                                                    setPaymentMethod(undefined);
                                               }else{
                                                    setPaymentMethod('Wallet');
                                               }
                                           }}/></td>
                                <td className='unis-payment-method'>
                                    <div className='line line1'>
                                        <img src={UnisLogo} className='payment-method-logo unis-logo'/> <label>{t('U点支付')}</label> 
                                        (<span className='unis-points-discount'>{t('10%折扣')}</span>)
                                    </div>
                                </td>
                            </tr>
                            {/* <tr>
                                <td><Radio checked={this.state.paymentMethod === 'WechatPay'}
                                           disabled={!supportWechatPay} 
                                           onChange={()=>{
                                               if(this.state.paymentMethod === 'WechatPay'){
                                                    setPaymentMethod(undefined);
                                               }else{
                                                    setPaymentMethod('WechatPay');
                                               }
                                           }}
                                    /></td>
                                <td className={'wechat-pay support-payment-'+supportWechatPay}>
                                    <img src={WechatPayLogo}  className='payment-method-logo'/>
                                    {supportWechatPay?null: <span className='not-supported'>&nbsp;({t('手机未设置')})</span>}
                                </td>
                            </tr> */}
                            <tr>
                                <td><Radio checked={this.state.paymentMethod === 'AliPay'}
                                            disabled={!supportAlipay}
                                           onChange={()=>{
                                            if(this.state.paymentMethod === 'AliPay'){
                                                setPaymentMethod(undefined);
                                            }else{
                                                setPaymentMethod('AliPay');
                                            }
                                        }}/></td>
                                <td  className={'ali-pay support-payment-'+supportAlipay}>
                                    <img src={AliPayLogo}  className='payment-method-logo'/>
                                    {supportAlipay?null: <span className='not-supported'>&nbsp;({t('手机未设置')})</span>}
                                </td>
                            </tr>
                            <tr>
                                <td><Radio checked={this.state.paymentMethod === 'CreditCard'}
                                           onChange={()=>{
                                            if(this.state.paymentMethod === 'CreditCard'){
                                                setPaymentMethod(undefined);
                                            }else{
                                                setPaymentMethod('CreditCard');
                                            }
                                        }}
                                /></td>
                                <td><img src={CreditCardLogo}  className='payment-method-logo'/></td>
                            </tr>
                        </tbody>
                    </table>
                </AccordionDetails>
            </Accordion>
        )
    }

    renderConfirmation():JSX.Element {
        // if user chose unishell payment method then order's currency is changed to UNIS
        let overrideCurrency = this.state.checkoutResponse? this.state.checkoutResponse!.shoppingCart.currencyCode : undefined;
        const {currencySymbol, currencyLabel, weightOptions} = this.getCurrencyAndWeightOption(overrideCurrency);

        const completed = this.completedBillingAddress() && this.completedShippingMethod() && this.completedPaymentMethod();
        return (
            <Accordion expanded={this.state.step==='confirm-order'} disabled={!completed}
                       className='confirm-order-accordion'
                        onChange={(evt, isExpanded)=>{ 
                            if(isExpanded){
                                this.loadConfirmOrder();
                            }else{
                                this.setState({step:undefined, checkoutResponse: undefined})
                            }
                        }
            }>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Badge color='primary' badgeContent='4' />
                    <div className='accordion-label'>{t('确认订单')}</div>
                </AccordionSummary>
                {this.state.checkoutResponse && 
                <AccordionDetails>
                    {this.state.checkoutResponse!.shoppingCart.item.map(item=>{
                        if(item.prescriptionCode){
                            return <RenderShoppingCartHealthAdvice2 shoppingCartItem={item} 
                                                                    currencySymbol={currencySymbol} 
                                                                    currencyLabel={currencyLabel} 
                                                                    weightOptions={weightOptions} 
                                    />;
                        }else if(item.productCode){
                            return <RenderSingleShoppingCartProduct shoppingCartItem={item} 
                                                                    currencySymbol={currencySymbol} 
                                                                    currencyLabel={currencyLabel} 
                                                                    weightOptions={weightOptions} 
                                                                    skipAction={true}
                                    />
                        }else {
                            return null;
                        }
                    })}
                
                    {/* <RenderUseCoupon couponCode={this.state.checkoutOrder!.couponCode} useCoupon={this.state.checkoutOrder!.useCoupon}/> */}
                    <RenderShoppingOrderSummary summary={this.state.checkoutResponse!} 
                                                currencySymbol={currencySymbol} 
                                                currencyLabel={currencyLabel}
                                                getCurrencyAndWeightOptionFn={this.getCurrencyAndWeightOption.bind(this)}
                    />
                
                    { this.insufficientUnis() &&
                      <div>
                          {t('当前U点')}<strong>{this.state.unishellPoints!.toFixed(2)}</strong> {t('不够支付订单')}
                          &nbsp;&nbsp;<UButton size='small' variant='outlined' color='primary'
                                              onClick={()=>{ this.godoPurchasePoints() }}
                                      >{t('充值')}</UButton>
                      </div>

                    }
                </AccordionDetails>
                }
            </Accordion>
        )
    }

    loadConfirmOrder(){
        let billingAddressId:string;

        if(this.useShippingAddressAsBillingAddress()){
            billingAddressId = this.state.shippingMethod!.address!.addressId;
        }else{
            billingAddressId = this.state.billingAddress!.addressId;
        }
        doAJAXCall({
            whatIsFor: t('准备订单'),
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken, username)=>{
                let shippingAddressId: string | undefined;
                let pickupAddressId: string | undefined;

                if(this.state.productType === 'chanpin'){
                    shippingAddressId = this.state.shippingMethod!.type==='courier'? this.state.shippingMethod!.address.addressId: undefined;
                    pickupAddressId = this.state.shippingMethod!.type==='pickup'? this.state.shippingMethod!.address.addressId: undefined;
                }

                let checkoutOrder = await getConfirmationOrder(accessToken, username,
                                                               {
                                                                   billingAddressId,
                                                                   shippingAddressId,
                                                                   pickupAddressId,
                                                                   paymentMethod: this.state.paymentMethod!
                                                                },
                                                                this.props.currencyCode);
                this.setState({checkoutResponse: checkoutOrder, step:'confirm-order'});
            }
        })
    }

    initializeAgain() {
        this.setState({pageInitState:'PAGE_INIT', initErrorMessage:undefined},
                      ()=>{
                          this.loadData();
                      })
    }


    loadData(){
        doAJAXCall({
            whatIsFor: t('下载数据'),
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken, username)=>{
                await loadExternContatnsIfNecessary();
                let currencyOptionsMap = getExternContantsInMemory().currencyOptionsMap;
                let weightOptionsMap = getExternContantsInMemory().weightOptionsMap;
                let countryOptions = getCountryOptions(accessToken, this.props.languageCode!);
                let shoppingAddressspecMap = getAddressSpecMap(accessToken, this.props.languageCode!);
                let myBillingAddresses = await getMyBillingAddresses(accessToken);

                let pickupAddresses = await getPickupAddresses(accessToken, {page:0, pageSize:PickupLocationPageSize});
                
                let myShippingAddresses = await getMyShippingAddresses(accessToken);
                let unishellPoints = await retrieveUnishellPoints(accessToken);
                let shippingCost = await calcShippingCostOfShoppingCart(accessToken, this.props.languageCode!);

                const {supportWechatPay, supportAlipay} = await getPaymentSupport();
                const checkoutInLS = readCheckoutRequest();

                let billingAddress = (myBillingAddresses||[]).concat(myShippingAddresses||[]).find(x=>checkoutInLS.billingAddressId && x.addressId === checkoutInLS.billingAddressId);
                
                let paymentMethod:PaymentMethod|undefined;
                if(checkoutInLS.paymentMethod && PaymentMethodList.some(x=>x === checkoutInLS.paymentMethod)){
                    switch(checkoutInLS.paymentMethod){
                        case 'AliPay':
                            if(supportAlipay){
                                paymentMethod='AliPay';    
                            }
                            break;
                        case 'WechatPay':
                            if(supportWechatPay){
                                paymentMethod = 'WechatPay';
                            }    
                            break;
                        default:
                            paymentMethod = checkoutInLS.paymentMethod;    
                    }
                }

                let shoppingCartRecord = await getMyShoppingCart(accessToken, username, this.props.currencyCode)
                let productType = getShoppingCartProductType(shoppingCartRecord);


                let shippingMethod:ShippingCourierMethod|ShippingPickupMethod|undefined;
                let shippingMethodType: ShippingMethod["type"]|undefined;

                if(productType==='chanpin'){
                    if(checkoutInLS.shippingMethod && checkoutInLS.shippingMethod.type === 'courier'){
                        const address = myShippingAddresses.find(x=>x.addressId === checkoutInLS.shippingMethod!.addressId);
                        if(address){
                            shippingMethod = {
                                address,
                                type: 'courier',
                                _useAsBillingAddress:checkoutInLS.shippingMethod._useAsBillingAddress 
                            };
                            shippingMethodType = 'courier';
                        }
                    }else if(checkoutInLS.shippingMethod && checkoutInLS.shippingMethod.type === 'pickup'){
                        try{
                            const address = await getPickupLocation(accessToken, checkoutInLS.shippingMethod!.addressId);
                            shippingMethod = {
                                address,
                                type: 'pickup',
                            };
                            shippingMethodType = 'pickup';
                        }catch(error){
                            // ignore it 
                        }
                    }    
                }

                this.setState({ pageInitState:'PAGE_LOAD_SUCCESS', 
                                shoppingCartRecord,
                                productType,
                                countryOptions, 
                                shoppingAddressspecMap,
                                myBillingAddresses,
                                billingAddress,
                                pickupAddresses,
                                myShippingAddresses,
                                shippingMethod,
                                shippingMethodType,
                                unishellPoints,
                                shippingCost,
                                currencyOptionsMap,
                                weightOptionsMap,
                                paymentMethod,
                                supportWechatPay, 
                                supportAlipay
                            });
            },
            onError: (errorMessage:string)=>{ this.setState({pageInitState:'PAGE_LOAD_FAILED', initErrorMessage:errorMessage})}                                           
        })        
    }

    componentDidMount(){
        this.loadData();
    }

    componentDidUpdate(){
        if(this.state.checkoutResponse){
            if(this.props.currencyCode !== this.state.checkoutResponse.shoppingCart.currencyCode &&
                this.state.checkoutResponse.shoppingCart.currencyCode !== 'UNIS'){
                // currency code changed
                this.loadConfirmOrder();
            }
        }
    }

}

export const ShoppingCheckout = connect(
    (state:WalletAppState)=>({languageCode: state.language.langCode, currencyCode: state.currency.currencyCode })
)(ShoppingCheckoutView);