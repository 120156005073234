import { AjaxCallState, AjaxCallActionTypes, StartAjaxCallAction } from './types';

const initState: AjaxCallState = {
    state: 'AJAX_CALL_SUCC', 
    whatIsFor: '',
    uuid: ''
}

export function ajaxCallReducer (state = initState, action: AjaxCallActionTypes): AjaxCallState {
    switch(action.type){
        case 'AJAX_CALL_STARTED':
            return {state: action.type, whatIsFor: action.whatIsFor, uuid: action.uuid}
        case 'AJAX_CALL_SUCC':
                return {state: action.type, whatIsFor: action.whatIsFor, uuid: action.uuid}
        case 'AJAX_CALL_FAILED':
                return {state: action.type, whatIsFor: action.whatIsFor, uuid: action.uuid, error:action.error}
        case 'AJAX_CALL_ERROR_DIALOG':
            return {state: action.type, whatIsFor: action.whatIsFor, uuid: action.uuid, error:action.error}
        default: return state;
    }
}