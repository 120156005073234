import * as _ from 'lodash';

export const SET_SHOP_USER_INFO = 'SET_SHOP_USER_INFO';

export interface ShopUserInfoRecord {
    shoppingCartItemCount: number,
    prescriptionCount: number
}

interface SetShopUserInfoAction {
    type: typeof SET_SHOP_USER_INFO,
    record:ShopUserInfoRecord,    
}

export function setShopUserInfoRecord(record:ShopUserInfoRecord):SetShopUserInfoAction {
    return {
        type:SET_SHOP_USER_INFO,
        record,
    }
}

const init:ShopUserInfoRecord = {
    shoppingCartItemCount: 0, 
    prescriptionCount: 0
}

export function shopUserInfoReducer(state = init, action:SetShopUserInfoAction):ShopUserInfoRecord {
    switch(action.type){
        case SET_SHOP_USER_INFO: 
            if(!_.isEqual(state, action.record))
            return action.record;
        default: return state;
    }
}
