// Generated by IcedCoffeeScript 108.0.11
(function () {
  var BaseX, BigInteger, buffer_to_ui8a, nbi, nbs, nbv, _ref;

  _ref = require('bn'), nbv = _ref.nbv, nbi = _ref.nbi, BigInteger = _ref.BigInteger;
  nbs = require('./bn').nbs;
  buffer_to_ui8a = require('./util').buffer_to_ui8a;

  exports.BaseX = BaseX = function () {
    function BaseX(alphabet) {
      var a, i, _i, _len, _ref1;

      this.alphabet = alphabet;
      this.base = this.alphabet.length;
      this.basebn = nbv(this.base);
      this.lookup = {};
      _ref1 = this.alphabet;

      for (i = _i = 0, _len = _ref1.length; _i < _len; i = ++_i) {
        a = _ref1[i];
        this.lookup[a] = i;
      }
    }

    BaseX.prototype.encode = function (buffer) {
      var c, chars, num, pad, q, r, _i, _len;

      num = nbi().fromBuffer(buffer);

      chars = function () {
        var _ref1, _results;

        _results = [];

        while (num.compareTo(BigInteger.ZERO) > 0) {
          _ref1 = num.divideAndRemainder(this.basebn), q = _ref1[0], r = _ref1[1];
          c = this.alphabet[r.intValue()];
          num = q;

          _results.push(c);
        }

        return _results;
      }.call(this);

      chars.reverse();
      pad = [];

      for (_i = 0, _len = buffer.length; _i < _len; _i++) {
        c = buffer[_i];

        if (c === 0) {
          pad.push(this.alphabet[0]);
        } else {
          break;
        }
      }

      return pad.concat(chars).join('');
    };

    BaseX.prototype.decode = function (str) {
      var base, c, char_index, i, num, pad, start, _i, _j, _len, _ref1;

      num = BigInteger.ZERO;
      base = BigInteger.ONE;
      i = 0;

      for (i = _i = 0, _len = str.length; _i < _len; i = ++_i) {
        c = str[i];

        if (c !== this.alphabet[0]) {
          break;
        }
      }

      start = i;
      pad = Buffer.from(function () {
        var _j, _results;

        _results = [];

        for (i = _j = 0; 0 <= start ? _j < start : _j > start; i = 0 <= start ? ++_j : --_j) {
          _results.push(0);
        }

        return _results;
      }());
      _ref1 = str.slice(start);

      for (i = _j = _ref1.length - 1; _j >= 0; i = _j += -1) {
        c = _ref1[i];

        if ((char_index = this.lookup[c]) == null) {
          throw new Error('Value passed is not a valid BaseX string.');
        }

        num = num.add(base.multiply(nbv(char_index)));
        base = base.multiply(this.basebn);
      }

      return Buffer.concat([pad, Buffer.from(num.toByteArray())]);
    };

    return BaseX;
  }();

  exports.base58 = new BaseX('123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz');
  exports.base32 = new BaseX('abcdefghijkmnpqrstuvwxyz23456789');
  exports.base91 = new BaseX('ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789`~!@#$%^&*()-_=+{}[]|;:,<>./?');
}).call(this);