import classnames from 'classnames';
import * as React from 'react';
import {Component, Fragment} from 'react';
import { getCordova } from '../../globals';
import { t } from '../../i18n';
import { ImageWithOverlay } from '../../shopping/common/image-with-overlay';
import { calcRationClass, RatioXXS } from '../../util/Util';
import { UButton } from '../button/ubutton';
import { HEqualSize } from '../equal-sizing/h-equal-size';
import { VEqualSize } from '../equal-sizing/v-equal-size';
import { ImageWithRotation } from '../image-rotate/image-rotate';
import './image-uploader.scss';

declare function getPictureFromCamera(inSource: 0|1|2, callback: (data:string)=>void):void;

export interface UploadedImage {
    /** original image uploaded */
    src?:string, 
    /** transformed image file  */
    file?:File, 
    /** transformed image */
    transformedImg?: string
}

interface MyProps {
    className?: string;
    minImgWidth:number;
    minImgHeight:number;
    currentImg?: string;

    genImageName: ()=>string;

    done: (uploadedImg?: UploadedImage)=>void;
}

interface MyState {
    uploadedImg?: UploadedImage;
}

export class ImageUploader extends Component<MyProps, MyState>{
    constructor(props: MyProps){
        super(props);
        this.state = {}
    }

    getUploadedImg(){
        return this.state.uploadedImg;
    }

    async updateTransformedImage(transformedImg:string, done: (uploadedImage:UploadedImage)=>void){
        const data = await fetch(transformedImg);
        const blob = await data.blob();

        const file= new File([blob], this.props.genImageName(), {type: "image/png"}); 
        this.setState({uploadedImg:{src:'', file, transformedImg}}, ()=>{
            done(this.state.uploadedImg!);
        });
    }

    uploadImgCordova(args:{camera: boolean}){
        const {camera} = args;

        getPictureFromCamera(camera? 1: 2, (data:string)=>{
            this.setState({uploadedImg:{src:data}}); 
        })
    }

    updateImgBrowser(f:File){
        const reader = new FileReader();
        reader.addEventListener("load", ()=>{
            this.setState({uploadedImg:{src:reader.result as string}});
        });
        reader.readAsDataURL(f);
    }

    render(){
        let imageToShow:JSX.Element | null = null;
        if(this.state.uploadedImg){
            if(this.state.uploadedImg!.transformedImg){
                imageToShow = <ImageWithOverlay imageList={[this.state.uploadedImg!.transformedImg]} classes='image-transformed'/>
            }else if(this.state.uploadedImg!.src){
                imageToShow = <ImageWithRotation 
                                    imgsrc={this.state.uploadedImg.src} 
                                    minImgWidth={this.props.minImgWidth} minImgHeight={this.props.minImgHeight}
                                    key={Date.now()}
                                    cancel={()=>{ this.setState({uploadedImg: undefined})}}
                                    done={ async (imgUrl)=>{
                                            this.state.uploadedImg!.transformedImg=imgUrl;
                                            this.updateTransformedImage(imgUrl, this.props.done);
                                    }}
                                />
            }    
        }else if(this.props.currentImg){
            imageToShow = <ImageWithOverlay imageList={[this.props.currentImg]} classes='current-image'/>;
        }
        const {ratioClass, vw,  mw, ratio} = calcRationClass();

        const uploadBtns = [
            <UButton size='small' variant='contained' color='primary'
                    onClick={()=>{ this.uploadImgCordova({camera: true}) }}
            >{t('直接拍照')}</UButton>,
            <UButton size='small' variant='contained' color='primary'
                    onClick={()=>{ this.uploadImgCordova({camera: false}) }}
            >{t('取相册图片')}</UButton>
        ];

        const fileInputId = 'image-uploader-file-input-'+Date.now();
        return (
            <div className={classnames('image-uploader', this.props.className)}>
            {imageToShow}
            {!!getCordova()?
                        <div className='upload-buttons'>
                        {ratio <= RatioXXS?
                            <VEqualSize spacing='20px' justifyContent='space-around'>
                                {uploadBtns}
                            </VEqualSize>:
                            <HEqualSize spacing='20px' justifyContent='space-around'>
                                {uploadBtns}
                            </HEqualSize>
                        }
                        </div>:
                        <div className='upload-buttons'>
                            <label htmlFor={fileInputId} className='file-input-label'>{t('取相册图片')}</label>
                            <input type='file' accept='image/png, image/jpg, image/jpeg' 
                                    id={fileInputId} 
                                    style={{visibility:'hidden', width:'10px'}}
                                onChange={(evt)=>{ this.updateImgBrowser(evt.currentTarget.files![0]) }}
                            />
                        </div>
                    }

            </div>
        )
    }
}
