import * as React from 'react';
import {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import { PagedResponse } from '../../api/transaction';
import { t } from '../../i18n';
import { WalletAppState } from '../../redux-store';
import { NoticeDialog } from '../../view/NoticeDialog';
import { AddressInfo2Str, CountryOption, NutritionistClient, ShoppingAddressSpec } from '../ajax-data/ajax-data-types';
import { getAddressSpecMap, getCountryOptions, NutritionistClientFilter, searchMyClients, updateNutritionistClient } from '../ajax-data/shopping-server-api';
import { doAJAXCall } from '../common/ajax-call';
import { SearchTextInput } from '../common/search-text-input';
import { Pagination } from '../common/render-pagination';
import { BaseShoppingPageProps, } from '../common/shopping-page-common';
import { enCA, zhCN, zhTW } from 'date-fns/locale'

import './client-list.scss';
import { formatRelative } from 'date-fns';
import { gotoShoppingPathAndSavingGivenPath, gotoShoppingPathNSavePrevPath} from '../history/shopping-history';
import { Button, Checkbox } from '@material-ui/core';
import className from 'classnames';
import { NutritionistClientForm } from './nutritionist-client-form';
import sha1 from 'sha1';
import * as _ from 'lodash';
import { Toast } from '../../view/Toast';
import { BaseUnishellPageState, renderInitErrorUnishellPage, renderInitUnishellPage, renderUnishellPage } from '../../unishell-page-common/unishell-page-common';
import { loadExternContatnsIfNecessary } from '../../external-constants/extern-constants';
import { PropertyListDataItem, PropertyListWidget } from '../../widget/property-list/property-list';
import { SimplePhoneNumber } from '../../widget/phone-number/phone-number';
import { SimpleEmail } from '../../widget/email/email';
import { UButton } from '../../widget/button/ubutton';
import { calcRationClass, RatioXXS, scrollBodyToTop } from '../../util/Util';

interface ClientListViewProps extends BaseShoppingPageProps{
    initialSearchText?: string;
    initialPage?: number;
    initialPageSize?: number;
}

interface ClientListViewState extends BaseUnishellPageState {
    countryOptions?:CountryOption[];
    shoppingAddressspecMap?: {[key:string]:ShoppingAddressSpec};

    page:number;
    pageSize: number;
    searchResult?:PagedResponse<NutritionistClient>;
    /** client that is modified right now */
    editClient?: NutritionistClient;
}

class SearchClientListView extends Component<ClientListViewProps, ClientListViewState>{
    private errorDialog:NoticeDialog | undefined;
    private searchInput:SearchTextInput | undefined;
    private clientForm:NutritionistClientForm | undefined;
    private toast: Toast | undefined;


    constructor(props: ClientListViewProps){
        super(props);
        this.state = {pageInitState:'PAGE_INIT', page:this.props.initialPage||0, pageSize: this.props.initialPageSize||10}
    }

    getTitle(){
        return t('客户列表');
    }
    render(){
        if(this.state.pageInitState === 'PAGE_INIT'){
            return <React.Fragment>
                        {renderInitUnishellPage({
                            title: this.getTitle(), 
                            className:'shopping-my-client-list',
                            message:t('初始化...')
                            })
                        }
                        <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                    </React.Fragment>
        }
        if(this.state.pageInitState === 'PAGE_LOAD_FAILED'){
            return <React.Fragment>
                        {renderInitErrorUnishellPage({
                            title: this.getTitle(), 
                            className:'shopping-my-client-list',
                            errorMessage: this.state.initErrorMessage||t('初始化失败'),
                            onTryAgain: ()=>{ this.initializeAgain();    }
                          })
                        }
                        <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                    </React.Fragment>
        }
        // loading data succeeded
        return renderUnishellPage({
            title: this.getTitle(), 
            className:'shopping-my-client-list',
            underTopHeader: this.renderSearchForm(),
            content: (
                <Fragment>
                    {this.renderSucceedContent()}
                    <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                </Fragment>
            )
        }) 
    }

    saveClient(){
        let newClient = this.clientForm!.validateAndGetClient();
        if(newClient){
            doAJAXCall({
                whatIsFor:t('保存客户'),
                errorDialog: this.errorDialog!,
                ajaxCall: async (accessToken:string) => {
                    await updateNutritionistClient(accessToken, newClient!);
                    this.state.searchResult!.content.forEach(x=>{
                        if(x.clientUsername === newClient!.clientUsername){
                            _.assign(x, newClient);
                        }
                    })
                    this.setState({editClient: undefined}, ()=>{
                        this.toast!.sendMessage(t(`保存客户`)+' '+newClient!.client, 'success');
                    })
                }
            })
        }
    }

    renderEditClient() {
        const cancelButton = <UButton variant='contained' color='secondary'
                                     onClick={()=>{ this.setState({editClient: undefined}) }}>{t('取消')}</UButton>
        const saveButton = <UButton variant='contained' color='primary'
                                   onClick={()=>{ this.saveClient() }}
                           >{t('保存')}</UButton>

        return (
            <div className='shopping-search-client-main-pane'>
                <NutritionistClientForm inputClient={this.state.editClient!} 
                                        languageCode={this.props.languageCode!}
                                        countryOptons={this.state.countryOptions!}
                                        addressSpecMap={this.state.shoppingAddressspecMap!}
                                        ref={e => this.clientForm = e!}
                                        key={sha1(JSON.stringify(this.state.editClient))}
                />

                <div className='edit-client-buttons'>
                    {cancelButton} {saveButton}
                </div>
                <Toast close={true} message='' toastType='info' onClose={()=>{}} ref={(e)=>{this.toast=e!}} duration={2500}/>
            </div>
        )    
    }

    renderSearchForm() {
        return (
            <div className='shopping-search-client-main-pane-client-search-form-wrapper'>
                <SearchTextInput placeholder={t('关键词')} initialValue={this.props.initialSearchText} 
                                        ref={x=>{this.searchInput=x!}} onSearch={this.doSearch}/>
            </div>)
    }

    renderSucceedContent():JSX.Element {
        if(this.state.editClient){
            return this.renderEditClient();
        }

        return (
            <div className='shopping-search-client-main-pane'>
                <Pagination data={this.state.searchResult!} page={this.state.page} pageSize={this.state.pageSize} 
                            loadData={this.loadPaginationData}
                            changePageSize={(newPageSize, newPage)=>{
                                this.loadPaginationData(newPage, newPageSize);
                            }}
                >
                { this.renderClientList(this.state.searchResult!.content) }
                </Pagination>
                <Toast close={true} message='' toastType='info' onClose={()=>{}} ref={(e)=>{this.toast=e!}} duration={2500}/>
            </div>
        )
    }


    renderClientList(list:NutritionistClient[]){
        const langcode = this.props.languageCode;
        let locale = zhCN;
        switch(langcode){
            case 'zh-TW':
            case 'zh-HW':
            case 'zh-MO':
                locale = zhTW;
                break;
            case 'en':{
                locale = enCA;
            }    
        }
        const {ratioClass, vw,  mw, ratio} = calcRationClass();

        return (
            list.map(x=>{
                let relativeTime = formatRelative(new Date(x.time!), new Date(), {locale})

                const createHealthAdvice = ()=>{
                    gotoShoppingPathAndSavingGivenPath(
                        'main/shopping/create-health-advice', 
                        {clientUsername:x.clientUsername, adviceType:'chanpin'},
                        'main/tools/client-list', 
                        {
                            initialSearchText:this.getSearchText(), 
                            initialPage:this.state.page, 
                            initialPageSize:this.state.pageSize,                                          
                        })
                };
                const createLabTest = ()=>{
                    gotoShoppingPathAndSavingGivenPath(
                        'main/shopping/create-health-advice', 
                        {clientUsername:x.clientUsername, adviceType:'test'},
                        'main/tools/client-list', 
                        {
                            initialSearchText:this.getSearchText(), 
                            initialPage:this.state.page, 
                            initialPageSize:this.state.pageSize,
                        })
                };

                const clientData: PropertyListDataItem[] = [];
                clientData.push({name: t('姓名'), value: x.client+(!x.active?` (${t('无效')})`:'')});
                clientData.push({
                    name: t('电邮'), 
                    value: <SimpleEmail email={x.email}/>,
                });
                clientData.push({name: t('电话'), value: <SimplePhoneNumber phoneNumber={x.phoneNumber}/>});
                if(x.gender) 
                    clientData.push({name: t('性别'), value: x.gender});
                clientData.push({name: t('地址'), value: AddressInfo2Str(x.address)});
                clientData.push({name: t('设置时间'), value: relativeTime});
                clientData.push({name: t('备注'), value: x.notes||''});
                if(x.active){ 
                    clientData.push({
                        name:'', 
                        mergeCol: true,
                        value: <div className='action-buttons'>
                                    <UButton size='small' variant='contained' color='primary' onClick={()=>{ createHealthAdvice() }}>{t('开建议书')}</UButton>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <UButton size='small' variant='contained' color='primary' onClick={()=>{ createLabTest() }}>{t('开检测单')}</UButton>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <UButton size='small' variant='outlined' color='primary' onClick={()=>{ this.setState({editClient: x}) }}>{t('编辑')}</UButton>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <UButton size='small' variant='outlined' color='primary' 
                                             onClick={()=>{ 
                                                 gotoShoppingPathNSavePrevPath('main/tools/health-advice-by-me',
                                                                              {
                                                                                  client: x
                                                                              })
                                              }}>{t('查询该客户建议书')}</UButton>
                                </div>
                    })
                }else{
                    clientData.push({name: t('有效客户'), value: x.active? t('是'): t('否')});
                };

                return <PropertyListWidget 
                            list={clientData} 
                            className={className('client-of-nutritionist', {inactive: !x.active})}
                            mergeColScreenLetterRatio={RatioXXS}
                            showBorder={true}
                        />    
            })
        )
    }

    getFilter():NutritionistClientFilter {
        return {text: this.getSearchText(), active: true}
    }

    loadPaginationData = async (page:number, pageSize:number) => {
        await this.loadData(this.getFilter(), page, pageSize);
    }

    doSearch = ()=>{
        this.loadData(this.getFilter(), this.state.page, this.state.pageSize);
    }
    getSearchText(){
        if(this.searchInput){
            return this.searchInput.getValue() || '';
        }else{
            return this.props.initialSearchText||'';
        }
    }


    componentDidMount(){
        this.loadData(this.getFilter(), this.state.page, this.state.pageSize);
    }

    componentDidUpdate(prevProps:any, prevState:ClientListViewState){
        if(prevState.page !== this.state.page){
            scrollBodyToTop();
        }
    }


    initializeAgain(){
        this.setState({pageInitState:'PAGE_INIT', initErrorMessage:undefined},
        ()=>{
            this.loadData(this.getFilter(), this.state.page, this.state.pageSize);
        })

    }

    loadData(filter:NutritionistClientFilter, page: number, pageSize:number){
        doAJAXCall({
            whatIsFor: t('下载客户列表'),
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken)=>{ 
                                            await loadExternContatnsIfNecessary();
                                            let countryOptions = getCountryOptions(accessToken, this.props.languageCode!);
                                            let shoppingAddressspecMap = getAddressSpecMap(accessToken, this.props.languageCode!);

                                            let searchResult = await searchMyClients(accessToken, page, pageSize, filter)
                                            this.setState({searchResult, page:searchResult.page, pageSize:searchResult.size, pageInitState:'PAGE_LOAD_SUCCESS',
                                                            countryOptions, shoppingAddressspecMap});
                                           },
            onError: (errorMessage:string)=>{ this.setState({pageInitState:'PAGE_LOAD_FAILED', initErrorMessage:errorMessage})}                                           
        })        
    }

}

export const SearchClientList = connect(
    (state:WalletAppState)=>({languageCode: state.language.langCode})
)(SearchClientListView);


