import * as React from 'react';
import './image-with-overlay.scss';
import classNames from 'classnames';
import { ImageOverlay } from './image-overlay';

interface ImageWithOverlayProps {
    imageList:string[];
    classes?:string;
}

interface ImageWithOverlayState {
    showOverlay: boolean;
}

export class ImageWithOverlay extends React.Component<ImageWithOverlayProps, ImageWithOverlayState>{
    constructor(props: ImageWithOverlayProps){
        super(props);
        this.state = {showOverlay: false}
    }

    render(){
        return (
            <div className={classNames('image-with-overlay', this.props.classes)}>
                <img className='the-image' src={this.props.imageList[0]} onClick={()=>{ this.setState({showOverlay: true})}}></img>
                {this.state.showOverlay && <ImageOverlay imageList={this.props.imageList} close={()=>{ this.setState({showOverlay: false}) }}/>}
            </div>
        )
    }
}