import * as React from 'react';
import {Component, Fragment} from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import './NoticeDialog.scss';
import { DialogActions, Button, DialogContent, DialogContentText } from '@material-ui/core';
import {t} from '../i18n';
import { UButton } from '../widget/button/ubutton';
import classnames from 'classnames';

interface NoticeDialogProps {
    open: boolean;
    title?: string;
    message?: string;
    className?: string;
    onClose?: ()=>void;
}

interface NoticeDialogState {
    open:boolean;
    title?: string;
    className?: string;
    message?: string|JSX.Element;
    onClose?: ()=>void;
}

export class NoticeDialog extends Component<NoticeDialogProps, NoticeDialogState>{
    constructor(props: NoticeDialogProps){
        super(props);
        this.state = {...props}
    }

    handleClose = ()=>{
        this.setState({open: false}, ()=>{
            if(this.state.onClose){
                this.state.onClose();
            }
        });
    }

    show(title:string, message:string|JSX.Element,onClose:()=>void){
        this.setState({title, message, onClose, open:true})
    }
    show2(args:NoticeDialogState, postRender?:()=>void){
        this.setState({...args}, postRender)
    }

    isOpen(){
        return !!this.state.open;
    }

    render(){
        if(!this.state.open) return null;
        const fullScreen = window.screen.width<=600;

        return (
            <div>
            <Dialog onClose={this.handleClose} 
                    // aria-labelledby="simple-dialog-title" 
                    scroll='paper'
                    // fullScreen={fullScreen}
                    maxWidth={fullScreen? 'xl' : 'lg'} 
                    open={this.state.open} 
                    className={classnames('error-dialog', this.state.className)}>
                <DialogTitle id="simple-dialog-title">{this.state.title}</DialogTitle>
                <DialogContent>
                    <div className='error-dialog-body'>
                        {this.state.message}
                    </div>
                </DialogContent>
                <DialogActions>
                    <UButton size='small' variant='outlined' onClick={this.handleClose} color='default'><strong>{t('关闭')}</strong></UButton>
                </DialogActions>
            </Dialog>
            </div>
        )
    }
}