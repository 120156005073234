import * as React from 'react';
import {Component, Fragment} from 'react';
import { ProductCategory } from '../ajax-data/ajax-data-types';
import './simple-shop-search-form.scss';
import {ReactComponent as FilterIcon} from '../../images/filter-fill.svg';
import {ReactComponent as CloseIcon} from '../../images/close.svg';

import { t } from '../../i18n';
import { SearchTextInput } from './search-text-input';
import { findProductCategory, findProductCategoryPath, findSiblingProductCategories } from './shopping-page-common';
import ReactDOM from 'react-dom';
import { calcRationClass } from '../../util/Util';
import classnames from 'classnames';
import { CategoryTree } from './category-tree';


interface MyProps {
    initialSearchText?:string;
    categoryName?:string;
    productCategories:ProductCategory[];

    doSearch: (searchText:string)=>void;
    selectCategory: (searchText:string, category: ProductCategory)=> void;
    initPageSize: number;
    changePageSize: (pageSize:number) => void;
}

interface MyState {
    showCategories: boolean;
}

export class SimpleShopSearchForm extends Component<MyProps, MyState> {
    private searchInput:SearchTextInput|undefined;
    private wrapperId = 'categories-wrapper-'+Date.now();

    constructor(props: MyProps){
        super(props);
        this.state = {showCategories:false}
    }

    getSearchText(){
        return this.searchInput!.getValue();
    }

    doSearch = ()=>{
        this.props.doSearch(this.searchInput!.getValue());
    }

    showCategories(){
        this.setState({showCategories: true});
    }
    render(){
        return (
            <div className='simple-shop-search-form'>
                <div className={classnames('keywords-input-wrapper with-category')}>
                    {
                        <FilterIcon className='filter-icon' onClick={()=>{ this.showCategories() }}/>
                    }

                    <SearchTextInput placeholder={t('搜索产品')} 
                                    initialValue={this.props.initialSearchText} 
                                    ref={x=>{this.searchInput=x!}} 
                                    onSearch={this.doSearch}/>
                </div>
                {this.state.showCategories? this.renderCategories2(): null}
            </div>
        )
    }

    getCurrentCategory() {
        let category:ProductCategory|undefined;
        if(this.props.categoryName){
            category = findProductCategory(this.props.categoryName, this.props.productCategories); 
        }
        return category;
    }

    changeCategory = (c:ProductCategory) => {
        const myself = c.name === this.props.categoryName;
        if(!myself){
            this.setState({showCategories: false}, ()=>{
                this.props.selectCategory(this.searchInput!.getValue(), c);
            });
        }
    }
    renderCategories2(){
        const currentPath = findProductCategoryPath([], this.props.categoryName||'', this.props.productCategories);
        return ReactDOM.createPortal(
                    <div tabIndex={0} className='categories-wrapper' id={this.wrapperId}
                        onMouseDown={()=>{ document.getElementById(this.wrapperId)!.focus()}}
                    >
                        <div className='categories'>
                            <div className='header'>{t('产品分类')}</div>
                            <CategoryTree 
                                currentPath={currentPath} 
                                wholeCategory={this.props.productCategories}
                                selectCategory={this.changeCategory}
                                key={Date.now()}
                            />
                            <div className='close'>
                                <span onClick={()=>{
                                        this.setState({showCategories: false});
                                    }}>{t('关闭')}
                                </span>
                            </div>
                            <CloseIcon id='close-icon' onClick={()=>{
                                    this.setState({showCategories: false});
                                }}
                            />

                        </div>
                    </div>,
                    document.getElementById('root')!
                )
    }
}