// Generated by IcedCoffeeScript 108.0.11
(function () {
  var Base,
      TweetNaCl,
      b2u,
      bufeq_secure,
      u2b,
      _ref,
      __hasProp = {}.hasOwnProperty,
      __extends = function __extends(child, parent) {
    for (var key in parent) {
      if (__hasProp.call(parent, key)) child[key] = parent[key];
    }

    function ctor() {
      this.constructor = child;
    }

    ctor.prototype = parent.prototype;
    child.prototype = new ctor();
    child.__super__ = parent.prototype;
    return child;
  };

  bufeq_secure = require('./util').bufeq_secure;
  _ref = require('./base'), b2u = _ref.b2u, u2b = _ref.u2b, Base = _ref.Base;

  exports.TweetNaCl = TweetNaCl = function (_super) {
    __extends(TweetNaCl, _super);

    function TweetNaCl() {
      return TweetNaCl.__super__.constructor.apply(this, arguments);
    }

    TweetNaCl.prototype.verify = function (_arg) {
      var detached, err, payload, r_payload, sig;
      payload = _arg.payload, sig = _arg.sig, detached = _arg.detached;
      err = null;

      if (detached) {
        if (payload == null) {
          payload = Buffer.alloc(0);
        }

        if (!this.lib.js.sign.detached.verify(b2u(payload), b2u(sig), b2u(this.publicKey))) {
          err = new Error("Signature failed to verify");
        }
      } else if ((r_payload = this.lib.js.sign.open(b2u(sig), b2u(this.publicKey))) == null) {
        err = new Error("signature didn't verify");
      } else if ((r_payload = u2b(r_payload)) == null) {
        err = new Error("failed to convert from a Uint8Array to a buffer");
      } else if (payload != null && !bufeq_secure(r_payload, payload)) {
        err = new Error("got unexpected payload");
      } else {
        payload = r_payload;
      }

      if (err != null) {
        payload = null;
      }

      return [err, payload];
    };

    TweetNaCl.prototype.sign = function (_arg) {
      var detached, f, payload;
      payload = _arg.payload, detached = _arg.detached;
      f = detached ? this.lib.js.sign.detached : this.lib.js.sign;
      return u2b(f(b2u(payload), b2u(this.secretKey)));
    };

    TweetNaCl.prototype.encrypt = function (_arg) {
      var nonce, plaintext, pubkey;
      plaintext = _arg.plaintext, nonce = _arg.nonce, pubkey = _arg.pubkey;
      return u2b(this.lib.js.box(plaintext, nonce, pubkey, this.secretKey));
    };

    TweetNaCl.prototype.secretbox = function (_arg) {
      var nonce, plaintext;
      plaintext = _arg.plaintext, nonce = _arg.nonce;
      return u2b(this.lib.js.secretbox(plaintext, nonce, this.secretKey));
    };

    TweetNaCl.prototype.decrypt = function (_arg) {
      var ciphertext, nonce, opened, pubkey;
      ciphertext = _arg.ciphertext, nonce = _arg.nonce, pubkey = _arg.pubkey;
      opened = this.lib.js.box.open(b2u(ciphertext), nonce, pubkey, this.secretKey);

      if (!opened) {
        throw new Error('TweetNaCl box_open failed!');
      } else {
        return u2b(opened);
      }
    };

    TweetNaCl.prototype.secretbox_open = function (_arg) {
      var ciphertext, nonce, opened;
      ciphertext = _arg.ciphertext, nonce = _arg.nonce;
      opened = this.lib.js.secretbox.open(b2u(ciphertext), nonce, this.secretKey);

      if (!opened) {
        throw new Error('TweetNaCl secretbox_open failed!');
      } else {
        return u2b(opened);
      }
    };

    TweetNaCl.prototype.box_beforenm = function (_arg) {
      var pubkey, seckey;
      pubkey = _arg.pubkey, seckey = _arg.seckey;
      return u2b(this.lib.js.box.before(pubkey, seckey));
    };

    TweetNaCl.prototype.box_open_afternm = function (_arg) {
      var ciphertext, nonce, opened, secret;
      ciphertext = _arg.ciphertext, nonce = _arg.nonce, secret = _arg.secret;
      opened = this.lib.js.box.open.after(b2u(ciphertext), nonce, secret);

      if (!opened) {
        throw new Error('TweetNaCl box_open_afternm failed!');
      } else {
        return u2b(opened);
      }
    };

    TweetNaCl.prototype.scalarmult_base = function (n) {
      return Buffer.from(this.lib.js.scalarMult.base(n));
    };

    TweetNaCl.prototype.scalarmult = function (n, P) {
      return Buffer.from(this.lib.js.scalarMult(n, P));
    };

    return TweetNaCl;
  }(Base);
}).call(this);