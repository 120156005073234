import * as React from 'react';
import {Component, Fragment} from 'react';

import './SearchUsersView.scss';
import {t} from '../../i18n';
import { PagedResponse} from '../../api/transaction';
import { cloneUser, hasRole, ROLE_NUTRITIONIST, ROLE_USER, SearchUserParms, translateRole, translateRoles, UserProfile } from '../../api/user';
import { NoticeDialog } from '../NoticeDialog';
import { ReactReduxContext } from 'react-redux';
import { WalletAppState } from '../../redux-store';
import { Button, Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import { getRoleList, removeNutritionistRole, searchUsers, updateUserProfile } from '../../wallet-server-api/wallet-server-api';
import {UserUpdateRequest} from '../../api/user';
import { Toast } from '../Toast';
import { BaseUnishellPageState, renderInitErrorUnishellPage, renderInitUnishellPage, renderUnishellPage } from '../../unishell-page-common/unishell-page-common';
import { gotoLoginPage, gotoShoppingPathNSavePrevPath, updateCurrentPathParms } from '../../shopping/history/shopping-history';
import { doAJAXCall } from '../../shopping/common/ajax-call';
import { loadExternContatnsIfNecessary } from '../../external-constants/extern-constants';
import { SimpleEmail } from '../../widget/email/email';
import { Pagination } from '../../shopping/common/render-pagination';
import { PropertyListDataItem, PropertyListWidget } from '../../widget/property-list/property-list';
import { UButton } from '../../widget/button/ubutton';
import { RatioXXS, scrollBodyToTop } from '../../util/Util';
import { retrieveNutritionist } from '../../shopping/ajax-data/shopping-server-api';
import { NutritionistInfo } from '../../shopping/ajax-data/ajax-data-types';
import sha1 from 'sha1';

export interface SearchUsersViewFilter {
    page?: number;
    pageSize?: number;
    nameKeyword?:string;
    emailKeyword?:string;
    selectedRoles?:{roleName:string, checked:boolean}[];
}
interface SearchUsersViewProps extends SearchUsersViewFilter{
    dispatch: (x:any)=>void;
}

interface SearchUsersViewState extends BaseUnishellPageState{
    data?: PagedResponse<UserProfile>;
    page: number;
    pageSize: number;
    roles?: string[];
    nutritionistList?: NutritionistInfo[];

    nameKeyword?:string;
    emailKeyword?:string;
    selectedRoles?:{roleName:string, checked:boolean}[];
}

export class SearchUsersView extends Component<SearchUsersViewProps, SearchUsersViewState> {
    private errorDialog: NoticeDialog|undefined;
    private toast: Toast|undefined;


    constructor(props: SearchUsersViewProps){
        super(props);
        const {page, pageSize, nameKeyword, emailKeyword, selectedRoles} = this.props;

        this.state = {pageInitState: 'PAGE_INIT', page: page || 0, pageSize: pageSize || 10, nameKeyword, emailKeyword, selectedRoles};
    }

    updatePathParms(){
        const {page, pageSize, nameKeyword, emailKeyword, selectedRoles} = this.state;
        let parms: SearchUsersViewFilter = {
            nameKeyword,
            emailKeyword,
            selectedRoles,
            page,
            pageSize
       };
        updateCurrentPathParms('main/tools/search-users', parms)        
    }


    render() {
        if(this.state.pageInitState === 'PAGE_INIT'){
            return (
                <Fragment>
                { renderInitUnishellPage({
                    title: t('查询用户'),
                    className: 'search-users-page',
                })}
                <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                <Toast close={true} duration={2500} message='' ref={e=>{this.toast=e!}} toastType='info'/>
                </Fragment>
            )
        }
        if(this.state.pageInitState === 'PAGE_LOAD_FAILED'){
            return (
                <Fragment>
                { renderInitErrorUnishellPage({
                    title: t('查询用户'),
                    className: 'search-users-page',
                      errorMessage: this.state.initErrorMessage!,
                      onTryAgain: ()=>{
                          this.init();
                      }                    
                })}
                <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                <Toast close={true} duration={2500} message='' ref={e=>{this.toast=e!}} toastType='info'/>
                </Fragment>
            )
        }

        return <ReactReduxContext.Consumer>
            { ({store})=> {
                const state:WalletAppState = store.getState();

                const content =( 
                    <div className='search-users-content'>
                        {this.renderSearchForm()}
                        {this.state.data && this.renderUserList()}
                        <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                        <Toast close={true} duration={2500} message='' ref={e=>{this.toast=e!}} toastType='info'/>
                    </div>
                );
                return renderUnishellPage({
                    title: t('查询用户'),
                    className: 'search-users-page',
                    content
                })


            }}
            </ReactReduxContext.Consumer>
    }


    renderSearchForm() {
        return (
            <Fragment>
                <div className='search-user-form'>
                    <TextField value={this.state.nameKeyword} spellCheck={false} label={t('用户名关键字')} 
                                variant='outlined' size='small'
                                onChange={(e)=>{this.setState({nameKeyword:e.target.value})}}
                                className='keyword-input'/>
                    <TextField value={this.state.emailKeyword} spellCheck={false} label={t('电邮关键字')} 
                                onChange={(e)=>{this.setState({emailKeyword:e.target.value})}}
                                variant='outlined'  size='small'
                                className='keyword-input'/>
                    
                    <p className='filter-label'>{t('过滤角色')}</p>
                    <div className='filter'>
                        {
                            this.state.selectedRoles!.map((x,idx) => {
                                const toggleRole = ()=> {
                                    x.checked = !x.checked;
                                    this.forceUpdate();
                                };
                                return <FormControlLabel 
                                        control={<Checkbox color='primary' 
                                                           checked={x.checked} 
                                                           onChange={e=>{ toggleRole() } }
                                                />}
                                        label={translateRole(x.roleName)}
                                    />

                            })
                        }
                    </div>
                </div>
                <div className='search-user-form-buttons'>
                        <UButton variant='contained' color='primary' className='search-user-button'
                                onClick={()=>{
                                    this.queryUsers(0, this.state.pageSize)
                                }}>{t('查询')}
                        </UButton>
                </div>

            </Fragment>

        )
    }

    queryUsers = (page:number, pageSize:number) => {
        let whatIsFor:string = t('读取角色列表');
        let request:SearchUserParms = {
            page, 
            pageSize,
        };
        if(this.state.selectedRoles!.some(x=>x.checked)){
            request.roles = this.state.selectedRoles!.filter(x=>x.checked).map(x=>x.roleName) || [ROLE_USER];
        }
        if(this.state.nameKeyword){
            request.nameKeyword = this.state.nameKeyword
        }
        if(this.state.emailKeyword){
            request.emailKeyword = this.state.emailKeyword
        }
        doAJAXCall({
            whatIsFor,
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken)=>{
                const data = await searchUsers(accessToken, request);

                const nutritionistUsers = data.content.filter(x=> hasRole(x.roles, ROLE_NUTRITIONIST));
                
                let nutritionistList = await Promise.all(nutritionistUsers.map(x=>{
                                return retrieveNutritionist(accessToken, x.username);
                            }));

                this.setState({data, page: data.page, pageSize: data.size, nutritionistList});
            }
        })
    } 

    renderUserList() {
        if(this.state.data!.totalElements<=0){
            return <div className='notice'>{t('暂无符合条件用户')}</div>

        }

        const userList = this.state.data!.content.map(user=>{
            let roleList = user.roles.map(r=> translateRole(r)).join(',');
            let action: JSX.Element;
            const isNutritionist = hasRole(user.roles, ROLE_NUTRITIONIST);

            if(isNutritionist){
                action =(
                    <Fragment>
                        <UButton variant='outlined' color='alert' size='small' onClick={()=>{
                            this.cancelNutritionist(user);
                        }}>{t('取消营养师')}</UButton>
                        <UButton variant='outlined' color='primary' size='small' className='edit-nutritionist-button'
                            onClick={()=>{
                                gotoShoppingPathNSavePrevPath('main/shopping/edit-nutritionist-profile', 
                                                {nutritionistId: user.username, editMode: true});
                            }}
                        >
                            {t('编辑档案')}
                        </UButton>
                        <UButton variant='outlined' color='primary' size='small' className='edit-licence'
                            onClick={()=>{
                                gotoShoppingPathNSavePrevPath('main/shopping/edit-licence', 
                                                {nutritionistId: user.username});
                            }}
                        >
                            {t('编辑执照')}
                        </UButton>
                    </Fragment>
                );
            }else{
                action = <UButton variant='outlined' color='primary' size='small' onClick={()=>{
                    let newUser = cloneUser(user);
                    newUser.roles.push(ROLE_NUTRITIONIST);
                    this.setNutritionist(newUser);
                }}>{t('设为营养师')}</UButton>
            }

            const userProps:PropertyListDataItem[] = [
                {name: t('电邮'), value: <SimpleEmail email={user.userEmail}/>},
                {name: t('用户姓名'), value: user.name},
                {name: t('电话'), value: user.phoneNumber},
                {name: t('角色'), value: roleList},
            ];
            let key = '';
            if(isNutritionist){
                const nutritionist = this.state.nutritionistList!.find(x=>x.username === user.username);
                if(nutritionist){
                    userProps.push({
                        name: t('公司电邮'),
                        value: nutritionist.officeEmail, 
                    });
                    userProps.push({
                        name: t('公司电话'),
                        value: nutritionist.officePhone, 
                    });
                    userProps.push({
                        name: t('称呼'),
                        value: nutritionist.name, 
                    });  
                    key = sha1(JSON.stringify(user)+nutritionist.officeEmail+nutritionist.officePhone+nutritionist.name);  
                }
            }
            if(!key){
                key = sha1(JSON.stringify(user));
            }
            userProps.push({name: '', value: action, valueClassName: 'action-row', mergeCol: true})

            return <PropertyListWidget list={userProps} showBorder={true} className='user-table'
                                       mergeColScreenLetterRatio={RatioXXS}
                                       key={key}
                    />
        });

        return <Pagination data={this.state.data!} 
                           page={this.state.page} 
                           pageSize={this.state.pageSize}
                           loadData={this.queryUsers}
                           classNames='user-list'
                           changePageSize={(newPageSize, newPage)=>{ 
                                this.queryUsers(newPage, newPageSize); 
                            }}
                >
                    {userList}
                </Pagination>
    }

    cancelNutritionist(user:UserProfile){
        let whatIsFor:string = t('更新用户');

        doAJAXCall({
            whatIsFor,
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken)=>{
                await removeNutritionistRole(accessToken, user.username);

                user.roles = user.roles.filter(x=> x !== ROLE_NUTRITIONIST);
                let nutritionistList = this.state.nutritionistList!.filter(x=> x.username !== user.username);
                this.setState({nutritionistList});
                this.toast!.sendMessage(t('更新用户')+' '+user.userEmail, 'info', false);
            }
        })
    }

    setNutritionist(user:UserProfile){
        let whatIsFor:string = t('更新用户');

        let request: UserUpdateRequest = {
            email: user.userEmail,
            roles: user.roles,
            name: user.name
        }
        doAJAXCall({
            whatIsFor,
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken)=>{
                await updateUserProfile(accessToken, request);
                this.state.data!.content.forEach( u => {
                    if(u.userEmail === user.userEmail){
                        u.roles = user.roles.slice();
                        u.name = user.name;
                    }
                });
                let nutritonist = await retrieveNutritionist(accessToken, user.username);
                let nutritionistList = this.state.nutritionistList!.filter(x=>x.username !== user.userEmail);
                nutritionistList.push(nutritonist);
                this.setState({nutritionistList}, ()=>{
                    this.toast!.sendMessage(t('更新用户')+' '+user.userEmail, 'info', false);
                });
            }
        })
    }

    init(){
        this.loadRoles ();        
    }

    componentDidMount() {
        this.init();
    }
    componentDidUpdate(prevProps:any, prevState:SearchUsersViewState){
        if(prevState.page !== this.state.page){
            scrollBodyToTop();
        }
        this.updatePathParms();
    }

    loadRoles() {
        let whatIsFor:string = t('读取角色列表');
        doAJAXCall({
            whatIsFor,
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken)=>{
                await loadExternContatnsIfNecessary();
                const roles = (await getRoleList(accessToken)).filter(x=> x !== ROLE_USER);
                let selectedRoles = roles.map(x=>({
                    roleName:x, 
                    checked: (this.props.selectedRoles||[]).some(r => r.roleName === x && r.checked)
                }));
                // ROLE_USER is implicit for every user
                this.setState({pageInitState: 'PAGE_LOAD_SUCCESS', roles, selectedRoles}, ()=>{
                    this.queryUsers(this.state.page, this.state.pageSize);
                });
            },
            onError: (errorMessage)=>{
                this.setState({pageInitState:'PAGE_LOAD_FAILED', initErrorMessage: errorMessage});
            }
        })
    }

    logout() {
        gotoLoginPage();
    }
}
