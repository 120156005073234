import { AxiosPromise } from "axios";
import { info } from "../globals";
import { t } from "../i18n";

export interface ServerApiResponse {
    success: boolean;
    message: string;
}

/*
{
  // `data` is the response that was provided by the server
  data: {},
 
  // `status` is the HTTP status code from the server response
  status: 200,
 
  // `statusText` is the HTTP status message from the server response
  statusText: 'OK',
 
  // `headers` the headers that the server responded with
  // All header names are lower cased
  headers: {},
 
  // `config` is the config that was provided to `axios` for the request
  config: {},
 
  // `request` is the request that generated this response
  // It is the last ClientRequest instance in node.js (in redirects)
  // and an XMLHttpRequest instance the browser
  request: {}
}*/

export async function processHttpOK<T>(axiosPromise: AxiosPromise) {
    let httpResponse = await axiosPromise;
    // info('processHttpOK')
    // info(httpResponse);
    let response = httpResponse.data as T;

    return response;
}

export function isStatusError(error: any, status:number): boolean {
    return error.response && error.response.status === status;
}

export function is401Error(error: any): boolean {
    return error.response && error.response.status === 401;
}
export function is404Error(error: any): boolean {
    return error.response && error.response.status === 404;
}

const REACT_APP_DUMP_ERROR = process.env.REACT_APP_DUMP_ERROR === 'true';

export function getAjaxErrorMessage(error: any): string {
    if(REACT_APP_DUMP_ERROR){
        info('getAjaxErrorMessage', error);
        info(JSON.stringify(error,null,4));
        if(error instanceof Error){
            return (error as Error).message;
        }
    }
    if(typeof error === 'string'){
        return error as string;
    }
    if (!error.response) {
        return t('系统错误') + ": " + error.message || '';
    }
    if ([502,503,504].some(x=> x === error.response.status)) {
        return t('返回状态码') + ": " + error.response.status+': '+t('服务器失联');
    }
    if (error.response.status === 401) {
        return t('登录失败');
    }
    
    if (!error.response.data) {
        return t('返回状态码') + ": " + error.response.status;
    } else {
        if (typeof error.response.data === 'object') {
            if (typeof error.response.data.message === 'string' && error.response.data.errorCode) {
                return error.response.data.message;
            }
        }
        if (error.response.status === 404) {
            return t('暂无信息');
        }
        let msg = t('返回状态码') + ": " + error.response.status + ': ' + 
                  (typeof error.response.data === 'string' ? error.response.data : JSON.stringify(error.response.data));
        // info(msg);
        return msg.substring(0, 1024);
    }
}


