// Generated by IcedCoffeeScript 108.0.11
(function () {
  var C,
      Ch,
      Parser,
      armor,
      bufeq_fast,
      decode,
      decode_strict,
      katch,
      mdecode,
      uint_to_buffer,
      _ref,
      __hasProp = {}.hasOwnProperty,
      __extends = function __extends(child, parent) {
    for (var key in parent) {
      if (__hasProp.call(parent, key)) child[key] = parent[key];
    }

    function ctor() {
      this.constructor = child;
    }

    ctor.prototype = parent.prototype;
    child.prototype = new ctor();
    child.__super__ = parent.prototype;
    return child;
  };

  _ref = require('../util'), katch = _ref.katch, bufeq_fast = _ref.bufeq_fast, uint_to_buffer = _ref.uint_to_buffer;
  C = require('../const');
  Ch = require('../header');
  armor = require('pgp-utils').armor;

  exports.encode = function (type, data) {
    var mt;
    mt = C.openpgp.message_types;

    type = function () {
      switch (type) {
        case mt.public_key:
          return "PUBLIC KEY BLOCK";

        case mt.private_key:
          return "PRIVATE KEY BLOCK";

        case mt.signature:
          return "SIGNATURE";

        case mt.generic:
          return "MESSAGE";

        default:
          throw new Error("Cannot encode tag type " + type);
      }
    }();

    return armor.encode(Ch, type, data);
  };

  Parser = function (_super) {
    __extends(Parser, _super);

    function Parser() {
      return Parser.__super__.constructor.apply(this, arguments);
    }

    Parser.prototype.parse_type = function () {
      var mt;
      mt = C.openpgp.message_types;

      this.ret.type = function () {
        switch (this.type) {
          case "PUBLIC KEY BLOCK":
            return mt.public_key;

          case "PRIVATE KEY BLOCK":
            return mt.private_key;

          case "SIGNED MESSAGE":
            if (this.ret.clearsign) {
              return mt.clearsign;
            } else {
              throw new Error("Signed message, but not clear-signed");
            }

            break;

          case "SIGNATURE":
            return mt.signature;

          case "MESSAGE":
            return mt.generic;

          default:
            throw new Error("Unknown message type: " + this.type);
        }
      }.call(this);

      return this.ret.fields.type = this.type;
    };

    return Parser;
  }(armor.Parser);

  exports.Message = armor.Message;

  exports.decode = decode = function decode(data) {
    return katch(function () {
      return new Parser(data).parse();
    });
  };

  exports.mdecode = mdecode = function mdecode(data) {
    return katch(function () {
      return new Parser(data).mparse();
    });
  };

  exports.decode_strict = decode_strict = function decode_strict(data) {
    return katch(function () {
      return new Parser(data, {
        strict: true
      }).parse();
    });
  };
}).call(this);