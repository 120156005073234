import * as React from 'react';
import {Fragment, } from 'react';
import classNames from 'classnames';

import './unishell-page-common.scss';
import { Header,  } from '../view/header/HeaderView2';
import { FooterView } from '../view/footer/footer';
import { t } from '../i18n';
import { getLanguageCode } from '../local-storage/local-storage';
import { UButton } from '../widget/button/ubutton';

export type PAGE_STATE = 'PAGE_INIT' | 'PAGE_LOAD_SUCCESS' | 'PAGE_LOAD_FAILED';

export interface BaseUnishellPageState {
    pageInitState: PAGE_STATE; 
    initErrorMessage?: string;
}


export interface RenderUnishellPageParms {
    /** header title */
    title:string;

    /** optional classes */
    className?: string;

    underTopHeader?: JSX.Element;

    /** middle content */
    content: JSX.Element;  

    skipFooter?: boolean;

    noMenuIcon?: boolean;

    customeGoBack?: ()=>void;

    backgroundColor?: string;
}

/**
 * render shopping page with header, content, and footer.
 * 
 * @param args 
 */
export function renderUnishellPage(args: RenderUnishellPageParms):JSX.Element{
    const lang = getLanguageCode();
    let classes = classNames('unishell-page app-page '+lang, args.className);                              

    return (
        <div className={classes}>
            <Header title={args.title} 
                         noMenuIcon={args.noMenuIcon} 
                         customeGoBack={args.customeGoBack}
                         backgroundColor={args.backgroundColor}
                         underTopHeader={args.underTopHeader}
                         />
            <div className='unishell-page-content'>
            { args.content }
            </div>             
            {args.skipFooter? null : <FooterView/>}
        </div>
    )
}

export interface RenderInitUnishellPageParms {
    /** header title */
    title:string;
    /** optional classes */
    className?: string;
    /** message to display in the middle of page */
    message?:string;  

    noMenuIcon?: boolean;

    customeGoBack?: ()=>void;
}

export function renderInitUnishellPage(args:RenderInitUnishellPageParms):JSX.Element{
    const {title, className, message,  noMenuIcon, customeGoBack} = args;
    let content = <div id='init-message'>
                     {message || t('初始化...') }
                  </div>
    return renderUnishellPage({title, className, content, skipFooter: true, noMenuIcon, customeGoBack});                                          
}

export interface RenderInitErrorUnishellPageParms {
    /** header title */
    title:string;
    /** optional classes */
    className?: string;
    /** initialization error message to display */
    errorMessage:string; 
    
    onTryAgain?: ()=>void;

    noMenuIcon?: boolean;

    customeGoBack?: ()=>void;
}

export function renderInitErrorUnishellPage(args:RenderInitErrorUnishellPageParms):JSX.Element{
    const {title, className, errorMessage, onTryAgain, noMenuIcon, customeGoBack} = args;
    let content = <div id='init-error-message'>
                     <div className='message'>{errorMessage}</div>
                     {onTryAgain ? <UButton variant='contained' color='primary' onClick={onTryAgain}>{t('再试一次')}</UButton>: null}
                  </div>
    return renderUnishellPage({title, className, content, noMenuIcon, customeGoBack});                                          
}
