import * as React from 'react';
import {Component, Fragment} from 'react';

import './ManualTransferView.scss';
import {t} from '../../i18n';
import { ReactReduxContext, batch } from 'react-redux';
import { Divider, Button, TextField, } from '@material-ui/core';
import { Points } from '../../container/Points';
import { PointsInputView } from './PointsInputView';
import {WalletAppState} from '../../redux-store';
import { RecipientSelectorView } from './RecipientSelectorView';
import { Store } from 'redux';
import { TransferDetail } from '../../api/transaction';
import { readPersistData, readLoginSession, readLastLoginEmail } from '../../local-storage/local-storage';
import { Toast } from '../Toast';
import { loadPrivateKey, signMessage } from '../../cypto/crypto';
import { transferUnishellPoints, retrieveUnishellPoints, retrieveUserSummaryByUsername, getPaymentConfig } from '../../wallet-server-api/wallet-server-api';
import { setPointsRecord } from '../../redux-store/points';
import { NoticeDialog } from '../NoticeDialog';
import { FooterView } from '../footer/footer';
import { genPaymentKey, encryptPayment } from '../../util/Util';
import {PasswordDialogView} from '../password-dialog/PasswordDialogView';
import { PaymentConfig } from '../../api/common';
import { BaseUnishellPageState, renderInitErrorUnishellPage, renderInitUnishellPage, renderUnishellPage } from '../../unishell-page-common/unishell-page-common';
import { gotoLoginPage, shoppingGoBack } from '../../shopping/history/shopping-history';
import { doAJAXCall } from '../../shopping/common/ajax-call';
import { loadExternContatnsIfNecessary } from '../../external-constants/extern-constants';
import { info } from '../../globals';
import { UButton } from '../../widget/button/ubutton';

interface ManualTransferViewProps {
    dispatch: (x:any)=>void
}

interface ManualTransferViewState extends BaseUnishellPageState{
    paymentConfig?: PaymentConfig;
    points: string;
    pointsError:string;
    recipientAddr: string;
    recipientAddrError: string;
    memo:string;
    transferDone: boolean;
}
const TOAST_DURATION = 2000;

export class ManualTransferView extends Component<ManualTransferViewProps, ManualTransferViewState>{
    private passwordDialog:PasswordDialogView | undefined;
    private toast:Toast|undefined;
    private errorDialog:NoticeDialog|undefined;

    constructor(props:ManualTransferViewProps){
        super(props);
        this.state = {pageInitState:'PAGE_INIT', points:'', pointsError: '', recipientAddr: '', recipientAddrError: '', memo: '', transferDone:false}
    }

    doTransfer = (store:Store, password:string)=>{
        const nonce = ''+Math.random();
        const transferDetail:TransferDetail = {points:Number.parseFloat(this.state.points), 
                                               toUsername: this.state.recipientAddr, 
                                               memo:this.state.memo, nonce, time:Date.now()}
        const paymentKey = genPaymentKey(readLastLoginEmail()!.toLowerCase()+password);

        const session = readLoginSession();
        if(!session){
            gotoLoginPage();
            return;
        }
        const localData = readPersistData(session!.profile!.username);
        info(`read local storage`);
        if(!localData){
            this.toast!.sendMessage(t('数据出错。请联系Unishell客服'), 'error', false);
            return;
        }

        let whatIsFor:string = t('转移Unishell');
        doAJAXCall({
            whatIsFor,
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken)=>{
                const keyManager = await loadPrivateKey(localData!.keyPair.priKey);
                const {signedMessage} = await signMessage(keyManager, transferDetail);
                let encryptedData = encryptPayment(signedMessage, paymentKey);
                await transferUnishellPoints(accessToken, 
                                            {keyId: localData.pkeyHash, data: encryptedData});    
                let newPoints = await retrieveUnishellPoints(session!.token!.accessToken);
                store.dispatch(setPointsRecord(newPoints));

                this.setState({transferDone: true}, ()=>{
                    this.toast!.sendMessage(whatIsFor+t(' 成功'), 'success', false, 
                                            ()=>{  
                                                shoppingGoBack();
                                            });
                });    
            }
        })
    }

    updateMemo = (value:string)=>{
        let v = value.substring(0,120);
        let lnCnt = 0;
        for (let i = 0; i < v.length; i++) {
            if(v.charAt(i)==='\n'){
                lnCnt ++;
                if(lnCnt>=3){
                    this.setState({memo: v.substring(0,i)});
                    return;
                }
            }
        }
        this.setState({memo:v})
    }

    render(){
        if(this.state.pageInitState === 'PAGE_INIT'){
            return <Fragment>
                {
                    renderInitUnishellPage({
                        title: t('积分转出'),
                        className: 'manual-transfer-page',
                        message: t('初始化...')
                    })
                }
                <Toast close={true} message='' toastType='info' onClose={()=>{}} ref={(e)=>{this.toast=e!}} duration={TOAST_DURATION}/>
                <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>                   
            </Fragment>;
        }
        if(this.state.pageInitState === 'PAGE_LOAD_FAILED'){
            return <Fragment>
                {
                    renderInitErrorUnishellPage({
                        title: t('积分转出'),
                        errorMessage: this.state.initErrorMessage!,
                        onTryAgain: ()=>{

                        }
                    })
                }
                <Toast close={true} message='' toastType='info' onClose={()=>{}} ref={(e)=>{this.toast=e!}} duration={TOAST_DURATION}/>
                <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>                   
            </Fragment>
        }
        const session = readLoginSession();

        return (
            <ReactReduxContext.Consumer>
            { ({store})=>{
                const state:WalletAppState = store.getState();
                
                const validatePoints = (points:string)=>{
                    // @ts-ignore
                    if(!points || isNaN(points)){
                        return t('错误数量格式');
                    }else{
                        const pts = Number.parseFloat(points);
                        if(pts<=0){
                            return t('转移数量不够');
                        }else if(pts>this.state.paymentConfig!.transferMax){
                            return t('转移数量不超过'+' '+this.state.paymentConfig!.transferMax);
                        }else if(!session!.profile!.roles.some(v=>v==='ROLE_ADMIN') && state.points.points<pts){
                            return t('账户数量不够');
                        }else{
                            return '';
                        }
                    }
                }
            
                const pointsValidator = (points:string)=> {
                    this.setState({points, pointsError:validatePoints(points)})                        
                }

                const validateRecipientAddr = (recipientAddr:string)=> {
                    if(recipientAddr && /^.{16,40}$/.test(recipientAddr)){
                        if(recipientAddr === session!.profile!.username){
                            return t('不能给自己发点');
                        }
                        return '';
                    }else{
                        return t('错误地址格式');
                    }
                }

                const validatePassword = (password:string)=>{
                    return password ? undefined: t('输入登录密码');
                }
                const recipientAddrValidator = (recipientAddr:string)=> {
                    this.setState({recipientAddr, recipientAddrError: validateRecipientAddr(recipientAddr)});
                }
                const handleConfirmation = (password:string, confirmed:boolean)=>{
                    if(confirmed){
                        this.doTransfer(store, password);
                    }
                }
                const clickSubmit = ()=>{
                    const pointsError = validatePoints(this.state.points);
                    const recipientAddrError = validateRecipientAddr(this.state.recipientAddr);

                    if(recipientAddrError || pointsError){
                        this.setState({recipientAddrError, pointsError});
                        return;
                    }
                    const whatIsFor = t('核实地址');
                    doAJAXCall({
                        whatIsFor,
                        errorDialog: this.errorDialog!,
                        ajaxCall: async (accessToken)=>{
                            let summary = await retrieveUserSummaryByUsername(accessToken, this.state.recipientAddr);
                            this.setState({pointsError:validatePoints(this.state.points), 
                                recipientAddrError: validateRecipientAddr(this.state.recipientAddr)},
                                ()=>{
                                    if(!this.state.pointsError && !this.state.recipientAddrError){
                                         this.passwordDialog!.show(
                                                     <Fragment>{t(`确认转移`)} {this.state.points} {t('点给')}<br/>
                                                        {summary.name} ({summary.userEmail})
                                                     </Fragment>);
                                     }
                                })    
                        },
                        onError: (errorMessage)=>{
                            this.setState({recipientAddrError:t('无法核实地址')})
                        }
                    })
                }
            
                const content = (
                    <Fragment>
                        <Points/>
                        <Divider/>
                        <div className='manual-transfer-form'>
                            <RecipientSelectorView recipientAddr={this.state.recipientAddr} error={this.state.recipientAddrError} updateRecipientAddr={recipientAddrValidator}/>
                            <PointsInputView points={this.state.points} error={this.state.pointsError} updatePoints={pointsValidator} />
                            <div className='memo-input-view'>
                                <TextField value={this.state.memo} spellCheck={false} label={t('备注')} 
                                        onChange={(e)=>{ this.updateMemo(e.target.value) } }
                                        multiline rows={1} rowsMax={3} className='memo-input'
                                />
                            </div>

                            <div className='submit-button'>
                                <UButton className='manual-transfer-submit-btn' 
                                        variant='contained' 
                                        color='primary' 
                                        onClick={clickSubmit} 
                                        disabled={this.state.transferDone}>{t('提交')}</UButton>
                            </div>
                        </div>
                        <FooterView/>

                        <Toast close={true} message='' toastType='info' onClose={()=>{}} ref={(e)=>{this.toast=e!}} duration={TOAST_DURATION}/>

                        <PasswordDialogView confirmLabel={t('同意')} cancelLabel={t('取消')} 
                                            onClose={handleConfirmation}
                                            ref={e=>{this.passwordDialog=e!}}
                        />                   
                        <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>                   
                    </Fragment>
                );
                return renderUnishellPage({
                    title: t('积分转出'),
                    className: 'manual-transfer-page',
                    content
                });    
            }}
            </ReactReduxContext.Consumer>
        )
    }

    componentDidMount(){
        let whatIsFor = "初始化";
        doAJAXCall({
            whatIsFor,
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken)=>{
                await loadExternContatnsIfNecessary();
                const paymentConfig = await getPaymentConfig(accessToken);
                this.setState({pageInitState: 'PAGE_LOAD_SUCCESS', paymentConfig});
            },
            onError: ()=>{
                shoppingGoBack();
            }
        });
    }
}