import * as React from 'react';
import {Component, Fragment} from 'react';

import './ClubTerms.scss';
import { TermsView } from '../Terms';
import { t } from '../../i18n';
import { NoticeDialog } from '../NoticeDialog';
import uuidv4 from 'uuid/v4';
import { getLanguageCode, readLoginSession } from '../../local-storage/local-storage';
import { readTerms } from '../../wallet-server-api/wallet-server-api';
import { getAjaxErrorMessage } from "../..//wallet-server-api/ajax-common";
import { succAjaxCall, failedAjaxCall, startAjaxCall } from '../../redux-store/ajax-call/actions';
import { BaseUnishellPageState, renderInitErrorUnishellPage, renderInitUnishellPage, renderUnishellPage } from '../../unishell-page-common/unishell-page-common';
import { loadExternContatnsIfNecessary } from '../../external-constants/extern-constants';

interface MyState extends BaseUnishellPageState {
    terms?:string
}

export class ClubTermsView extends Component<{
                                                dispatch: (x: any)=>void, 
                                                onClickBack?:()=>void,
                                                noLoginSession?: boolean
                                            },
                                            MyState>{
    private errorDialog: NoticeDialog|undefined;

    constructor(props:any){
        super(props);
        this.state = {pageInitState:'PAGE_INIT'};
    }

    render (){
        const {noLoginSession} = this.props;
        
        if(this.state.pageInitState === 'PAGE_INIT'){
            return (
                <Fragment>
                { renderInitUnishellPage({
                      title:t('用户规则'),
                      className: 'unishell-club-terms-page',
                      noMenuIcon: noLoginSession,
                      customeGoBack: this.props.onClickBack                    
                })}
                <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                </Fragment>
            )
        }
        if(this.state.pageInitState === 'PAGE_LOAD_FAILED'){
            return (
                <Fragment>
                { renderInitErrorUnishellPage({
                      title:t('用户规则'),
                      className: 'unishell-club-terms-page',                    
                      errorMessage: this.state.initErrorMessage!,
                      noMenuIcon: noLoginSession,
                      customeGoBack: this.props.onClickBack,
                      onTryAgain: ()=>{
                          this.load();
                      }                    
                })}
                <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                </Fragment>
            )
        }


        const content = (
            <Fragment>
                <TermsView dispatch={this.props.dispatch} terms={this.state.terms!}/>
                <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>                   
            </Fragment>
        )

        return renderUnishellPage({
            title: t('用户规则'),
            className: 'unishell-club-terms-page',
            noMenuIcon: noLoginSession,
            skipFooter: noLoginSession,
            customeGoBack: this.props.onClickBack,                    
            content
        })
    }

   
    load(){
        let whatIsFor = t("初始化");
        const uuid = uuidv4();
        const envNoticeTimeout = process.env.REACT_APP_NOTICE_TIMEOUT || '60'; // seconds

        const timeout = parseInt(envNoticeTimeout)*1000;
        let version = Math.floor((Date.now() / timeout))
        let langCode = getLanguageCode();

        whatIsFor = t("读取应用设置");
        loadExternContatnsIfNecessary()
        .then(()=>{
            whatIsFor = t("读取会员条款");
            return readTerms(langCode, version+'')
        }).then(terms=>{
            this.props.dispatch (succAjaxCall(whatIsFor, uuid)); 
            this.setState({terms, pageInitState:'PAGE_LOAD_SUCCESS'});
        }).catch(error=>{
            const errMsg = getAjaxErrorMessage(error);
            this.props.dispatch (failedAjaxCall(whatIsFor, uuid, errMsg));
            this.errorDialog!.show(whatIsFor+t(' 失败'), errMsg, 
                                   ()=>{
                                       this.setState({pageInitState:'PAGE_LOAD_FAILED', initErrorMessage:errMsg})
                                   });
        })
        this.props.dispatch (startAjaxCall(whatIsFor, uuid));
    }

    componentDidMount() {
        this.load();
    }
}