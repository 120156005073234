export const SET_CURRENCY = 'SET_CURRENCY';

export interface CurrencyRecord {
    currencyCode: string;
}

interface SetCurrencyAction {
    type: typeof SET_CURRENCY,
    langCode: string;    
}

export function setCurrencyRecord(langCode: string):SetCurrencyAction {
    return {
        type:SET_CURRENCY,
        langCode
    }
}

const init:CurrencyRecord = {currencyCode:'CAD'}

export function currencyReducer(state = init, action:SetCurrencyAction):CurrencyRecord {
    switch(action.type){
        case SET_CURRENCY: return {currencyCode: action.langCode}
        default: return state;
    }
}
