import * as React from 'react';
import {Component, Fragment} from 'react';

import './ReceiveView.scss';
import {t} from '../../i18n';
import { Divider, Button } from '@material-ui/core';
import {Toast} from '../Toast';
import { ReactReduxContext } from 'react-redux';
import { readLoginSession, getLanguageCode } from '../../local-storage/local-storage';
import { Header,  } from '../header/HeaderView2';
import { FooterView } from '../footer/footer';
import { BaseUnishellPageState, renderInitUnishellPage } from '../../unishell-page-common/unishell-page-common';
import { NoticeDialog } from '../NoticeDialog';
import { loadExternContatnsIfNecessary } from '../../external-constants/extern-constants';
import { info } from '../../globals';
import { UButton } from '../../widget/button/ubutton';

interface ReceiveViewProps {
    /** base64 image */
    qrCodeImage: string;
    /** recipientAddr */
    recipientAddr: string;
}

export class ReceiveView extends Component<ReceiveViewProps,BaseUnishellPageState>{
    private toast:Toast|undefined = undefined;

    constructor(props:ReceiveViewProps){
        super(props);
        this.state = {pageInitState:'PAGE_INIT'}
    }

    clearSelection()
    {
        if (window.getSelection) {
            // @ts-ignore
            window.getSelection() && window.getSelection().removeAllRanges();
            // @ts-ignore
        } else if (document.selection) { 
            // @ts-ignore
            document.selection.empty();
        }
    }

    copyAddress = ()=>{
        const copyTextarea = document.getElementById('receive-view-addr-textarea')! as HTMLTextAreaElement;
        copyTextarea.focus();
        copyTextarea.select();
        copyTextarea.setSelectionRange(0, 99999); /*For mobile devices*/

        try {
            let successful = document.execCommand('copy');
            let msg = successful ? 'successful' : 'unsuccessful';
            info('Copying text command was ' + msg);
            this.clearSelection();
            if(successful){
                this.toast!.sendMessage(t('拷贝到剪贴板'), 'info', false);
            }else{
                this.toast!.sendMessage(t('拷贝失败'), 'error', false);
            }
        } catch (err) {
            info('Oops, unable to copy');
            this.toast!.sendMessage(t('拷贝失败'), 'error', false);
        }
    }

    render(){
        if(this.state.pageInitState === 'PAGE_INIT'){
            return (
                <Fragment>
                { renderInitUnishellPage({
                      title:t('积分转入'),
                      className: 'receive-view'                    
                })}
                </Fragment>
            )
        }

        return (
            <ReactReduxContext.Consumer>
            {({store})=>{
                let session = readLoginSession();

                return <div className={'receive-view app-page '+getLanguageCode()}>
                         <Header title={t('积分转入')}/>
                         <div className='address-panels'>
                            <div className='receive-view-prompt1'>{t('请对方扫描下面的二维码')}</div>
                            <div className='user-name'>{session!.profile!.name}</div>
                            <div className='receive-view-qrcode-image'>
                                <img src={"data:image/png;base64, "+this.props.qrCodeImage} alt="二维码" />
                            </div>
                            <Divider/>

                            <div className='receive-view-prompt2'>{t('请复制地址给转账方')}</div>
                            <div className='receive-view-recipient-addr' >
                                <textarea id='receive-view-addr-textarea' spellCheck={false} readOnly={false} defaultValue={this.props.recipientAddr}>
                            </textarea>
                            </div>
                            <div className='receive-view-recipient-copy-addr'>
                                    <UButton variant='contained' color='primary' onClick={this.copyAddress}>{t('拷贝地址')}</UButton>
                            </div>
                         </div>

                
                <FooterView/>
                <Toast close={true} message='' toastType='info' onClose={()=>{}} ref={(e)=>{this.toast=e!}}/>
            </div> 
            }}    
            </ReactReduxContext.Consumer>
            
        )
    }

    async componentDidMount(){
        await loadExternContatnsIfNecessary();
        this.setState({pageInitState: 'PAGE_LOAD_SUCCESS'});
    }
}
