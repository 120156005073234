// Generated by IcedCoffeeScript 108.0.11
(function () {
  var ASP,
      C,
      Decryptor,
      MDC,
      MDC_Parser,
      PKESK,
      PKESK_Parser,
      Packet,
      SEIPD,
      SEIPD_Parser,
      SHA1,
      asymmetric,
      bufeq_fast,
      bufeq_secure,
      encrypt,
      iced,
      make_esc,
      streamers,
      uint_to_buffer,
      __iced_k,
      __iced_k_noop,
      _ref,
      _ref1,
      _ref2,
      __hasProp = {}.hasOwnProperty,
      __extends = function __extends(child, parent) {
    for (var key in parent) {
      if (__hasProp.call(parent, key)) child[key] = parent[key];
    }

    function ctor() {
      this.constructor = child;
    }

    ctor.prototype = parent.prototype;
    child.prototype = new ctor();
    child.__super__ = parent.prototype;
    return child;
  };

  iced = require('iced-runtime');

  __iced_k = __iced_k_noop = function __iced_k_noop() {};

  Packet = require('./base').Packet;
  C = require('../../const').openpgp;
  asymmetric = require('../../asymmetric');
  _ref = require('../../hash'), SHA1 = _ref.SHA1, streamers = _ref.streamers;
  _ref1 = require('../../util'), uint_to_buffer = _ref1.uint_to_buffer, bufeq_secure = _ref1.bufeq_secure, bufeq_fast = _ref1.bufeq_fast;
  _ref2 = require('../ocfb'), encrypt = _ref2.encrypt, Decryptor = _ref2.Decryptor;
  ASP = require('pgp-utils').util.ASP;
  make_esc = require('iced-error').make_esc;

  PKESK = function (_super) {
    __extends(PKESK, _super);

    function PKESK(_arg) {
      this.crypto_type = _arg.crypto_type, this.key_id = _arg.key_id, this.ekey = _arg.ekey;
    }

    PKESK.parse = function (slice) {
      return new PKESK_Parser(slice).parse();
    };

    PKESK.prototype.to_esk_packet = function () {
      return this;
    };

    PKESK.prototype.get_key_id = function () {
      return this.key_id;
    };

    PKESK.prototype.write_unframed = function (cb) {
      var bufs, err, ret;
      bufs = [uint_to_buffer(8, C.versions.PKESK), this.key_id, uint_to_buffer(8, this.crypto_type), this.ekey.output()];
      ret = Buffer.concat(bufs);
      err = null;
      return cb(err, ret);
    };

    PKESK.prototype.write = function (cb) {
      var err, ret, unframed, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      ret = null;
      (function (_this) {
        return function (__iced_k) {
          __iced_deferrals = new iced.Deferrals(__iced_k, {
            parent: ___iced_passed_deferral,
            filename: "/Users/max/src/keybase/kbpgp/src/openpgp/packet/sess.iced",
            funcname: "PKESK.write"
          });

          _this.write_unframed(__iced_deferrals.defer({
            assign_fn: function () {
              return function () {
                err = arguments[0];
                return unframed = arguments[1];
              };
            }(),
            lineno: 36
          }));

          __iced_deferrals._fulfill();
        };
      })(this)(function (_this) {
        return function () {
          if (typeof err === "undefined" || err === null) {
            ret = _this.frame_packet(C.packet_tags.PKESK, unframed);
          }

          return cb(err, ret);
        };
      }(this));
    };

    return PKESK;
  }(Packet);

  SEIPD = function (_super) {
    __extends(SEIPD, _super);

    function SEIPD(_arg) {
      this.ciphertext = _arg.ciphertext;
    }

    SEIPD.parse = function (slice) {
      return new SEIPD_Parser(slice).parse();
    };

    SEIPD.prototype.to_enc_data_packet = function () {
      return this;
    };

    SEIPD.prototype.check = function () {};

    SEIPD.prototype.decrypt = function (_arg, cb) {
      var asp, cipher, eng, err, esc, mdc, plaintext, prefix, pt, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      cipher = _arg.cipher, asp = _arg.asp;
      eng = new Decryptor({
        cipher: cipher,
        ciphertext: this.ciphertext,
        asp: asp
      });
      esc = make_esc(cb, "SEIPD::decrypt");
      asp = ASP.make(asp);
      (function (_this) {
        return function (__iced_k) {
          __iced_deferrals = new iced.Deferrals(__iced_k, {
            parent: ___iced_passed_deferral,
            filename: "/Users/max/src/keybase/kbpgp/src/openpgp/packet/sess.iced",
            funcname: "SEIPD.decrypt"
          });
          eng.check(esc(__iced_deferrals.defer({
            lineno: 63
          })));

          __iced_deferrals._fulfill();
        };
      })(this)(function (_this) {
        return function () {
          (function (__iced_k) {
            __iced_deferrals = new iced.Deferrals(__iced_k, {
              parent: ___iced_passed_deferral,
              filename: "/Users/max/src/keybase/kbpgp/src/openpgp/packet/sess.iced",
              funcname: "SEIPD.decrypt"
            });
            eng.dec(esc(__iced_deferrals.defer({
              assign_fn: function () {
                return function () {
                  return pt = arguments[0];
                };
              }(),
              lineno: 64
            })));

            __iced_deferrals._fulfill();
          })(function () {
            var _ref3;

            _ref3 = MDC.parse(pt), mdc = _ref3[0], plaintext = _ref3[1];
            prefix = eng.get_prefix();

            (function (__iced_k) {
              __iced_deferrals = new iced.Deferrals(__iced_k, {
                parent: ___iced_passed_deferral,
                filename: "/Users/max/src/keybase/kbpgp/src/openpgp/packet/sess.iced",
                funcname: "SEIPD.decrypt"
              });
              mdc.compute({
                prefix: prefix,
                plaintext: plaintext,
                asp: asp
              }, esc(__iced_deferrals.defer({
                lineno: 70
              })));

              __iced_deferrals._fulfill();
            })(function () {
              err = mdc.check() ? null : new Error("MDC mismatch");
              return cb(err, plaintext);
            });
          });
        };
      }(this));
    };

    SEIPD.prototype.encrypt = function (_arg, cb) {
      var asp, cipher, esc, mdc, mdc_buf, plaintext, prefixrandom, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      cipher = _arg.cipher, plaintext = _arg.plaintext, prefixrandom = _arg.prefixrandom, asp = _arg.asp;
      mdc = new MDC({});
      esc = make_esc(cb, "SEIPD::encrypt");
      asp = ASP.make(asp);
      (function (_this) {
        return function (__iced_k) {
          __iced_deferrals = new iced.Deferrals(__iced_k, {
            parent: ___iced_passed_deferral,
            filename: "/Users/max/src/keybase/kbpgp/src/openpgp/packet/sess.iced",
            funcname: "SEIPD.encrypt"
          });
          mdc.compute({
            plaintext: plaintext,
            prefix: prefixrandom,
            asp: asp
          }, esc(__iced_deferrals.defer({
            assign_fn: function () {
              return function () {
                return mdc_buf = arguments[0];
              };
            }(),
            lineno: 81
          })));

          __iced_deferrals._fulfill();
        };
      })(this)(function (_this) {
        return function () {
          plaintext = Buffer.concat([plaintext, MDC.header, mdc_buf]);

          (function (__iced_k) {
            __iced_deferrals = new iced.Deferrals(__iced_k, {
              parent: ___iced_passed_deferral,
              filename: "/Users/max/src/keybase/kbpgp/src/openpgp/packet/sess.iced",
              funcname: "SEIPD.encrypt"
            });
            encrypt({
              cipher: cipher,
              plaintext: plaintext,
              prefixrandom: prefixrandom
            }, esc(__iced_deferrals.defer({
              assign_fn: function (__slot_1) {
                return function () {
                  return __slot_1.ciphertext = arguments[0];
                };
              }(_this),
              lineno: 83
            })));

            __iced_deferrals._fulfill();
          })(function () {
            return cb(null);
          });
        };
      }(this));
    };

    SEIPD.prototype.write_unframed = function (cb) {
      var err, ret;
      err = ret = null;
      ret = Buffer.concat([uint_to_buffer(8, C.versions.SEIPD), this.ciphertext]);
      return cb(err, ret);
    };

    SEIPD.prototype.write = function (cb) {
      var err, ret, unframed, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      ret = err = null;
      (function (_this) {
        return function (__iced_k) {
          __iced_deferrals = new iced.Deferrals(__iced_k, {
            parent: ___iced_passed_deferral,
            filename: "/Users/max/src/keybase/kbpgp/src/openpgp/packet/sess.iced",
            funcname: "SEIPD.write"
          });

          _this.write_unframed(__iced_deferrals.defer({
            assign_fn: function () {
              return function () {
                err = arguments[0];
                return unframed = arguments[1];
              };
            }(),
            lineno: 97
          }));

          __iced_deferrals._fulfill();
        };
      })(this)(function (_this) {
        return function () {
          if (err == null) {
            ret = _this.frame_packet(C.packet_tags.SEIPD, unframed);
          }

          return cb(err, ret);
        };
      }(this));
    };

    return SEIPD;
  }(Packet);

  MDC = function (_super) {
    __extends(MDC, _super);

    MDC.header = Buffer.from([0xc0 | C.packet_tags.MDC, SHA1.output_length]);
    MDC.prototype.header = MDC.header;

    function MDC(_arg) {
      this.digest = _arg.digest;
    }

    MDC.parse = function (buf) {
      return new MDC_Parser(buf).parse();
    };

    MDC.prototype.compute = function (_arg, cb) {
      var asp, esc, hasher, i, plaintext, prefix, step, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      plaintext = _arg.plaintext, prefix = _arg.prefix, asp = _arg.asp;
      asp = ASP.make(asp);
      hasher = streamers.SHA1();
      hasher.update(Buffer.concat([prefix, prefix.slice(-2)]));
      esc = make_esc(cb, "MDC::compute");
      step = 0x100000;
      (function (_this) {
        return function (__iced_k) {
          var _begin, _end, _i, _positive, _results, _step, _while2;

          i = 0;
          _begin = 0;
          _end = plaintext.length;
          _step = step;
          _positive = _step > 0;

          _while2 = function _while(__iced_k) {
            var _break, _continue, _next;

            _break = __iced_k;

            _continue = function _continue() {
              return iced.trampoline(function () {
                i += _step;
                return _while2(__iced_k);
              });
            };

            _next = _continue;

            if (!!(_positive === true && i >= plaintext.length || _positive === false && i <= plaintext.length)) {
              return _break();
            } else {
              hasher.update(plaintext.slice(i, i + step));

              (function (__iced_k) {
                __iced_deferrals = new iced.Deferrals(__iced_k, {
                  parent: ___iced_passed_deferral,
                  filename: "/Users/max/src/keybase/kbpgp/src/openpgp/packet/sess.iced",
                  funcname: "MDC.compute"
                });
                asp.progress({
                  what: "MDC",
                  total: plaintext.length,
                  i: i
                }, esc(__iced_deferrals.defer({
                  lineno: 122
                })));

                __iced_deferrals._fulfill();
              })(_next);
            }
          };

          _while2(__iced_k);
        };
      })(this)(function (_this) {
        return function () {
          hasher.update(_this.header);
          _this.computed = hasher();
          return cb(null, _this.computed);
        };
      }(this));
    };

    MDC.prototype.check = function () {
      return bufeq_secure(this.digest, this.computed);
    };

    return MDC;
  }(Packet);

  MDC_Parser = function () {
    function MDC_Parser(buf) {
      this.buf = buf;
    }

    MDC_Parser.prototype.parse = function () {
      var chunk, digest, hl, len, rem;
      hl = MDC.header.length;
      len = SHA1.output_length + hl;
      rem = this.buf.slice(0, -len);
      chunk = this.buf.slice(-len);

      if (!bufeq_fast(chunk.slice(0, hl), MDC.header)) {
        throw new Error('Missing MDC header');
      }

      digest = chunk.slice(hl);
      return [new MDC({
        digest: digest
      }), rem];
    };

    return MDC_Parser;
  }();

  SEIPD_Parser = function () {
    function SEIPD_Parser(slice) {
      this.slice = slice;
    }

    SEIPD_Parser.prototype.payload_split = function (raw) {};

    SEIPD_Parser.prototype.parse = function () {
      var ciphertext, v;

      if ((v = this.slice.read_uint8()) !== C.versions.SEIPD) {
        throw new Error("Unknown SEIPD version " + v);
      }

      ciphertext = this.slice.consume_rest_to_buffer();
      return new SEIPD({
        ciphertext: ciphertext
      });
    };

    return SEIPD_Parser;
  }();

  PKESK_Parser = function () {
    function PKESK_Parser(slice) {
      this.slice = slice;
    }

    PKESK_Parser.prototype.parse = function () {
      var crypto_type, ekey, key_id, klass, v;

      if ((v = this.slice.read_uint8()) !== C.versions.PKESK) {
        throw new Error("Unknown PKESK version: " + v);
      }

      key_id = this.slice.read_buffer(8);
      crypto_type = this.slice.read_uint8();
      klass = asymmetric.get_class(crypto_type);
      ekey = klass.parse_output(this.slice.consume_rest_to_buffer());
      return new PKESK({
        crypto_type: crypto_type,
        key_id: key_id,
        ekey: ekey
      });
    };

    return PKESK_Parser;
  }();

  exports.SEIPD = SEIPD;
  exports.PKESK = PKESK;
  exports.MDC = MDC;
}).call(this);