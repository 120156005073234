// Generated by IcedCoffeeScript 108.0.11
(function () {
  var Generic,
      Packet,
      __hasProp = {}.hasOwnProperty,
      __extends = function __extends(child, parent) {
    for (var key in parent) {
      if (__hasProp.call(parent, key)) child[key] = parent[key];
    }

    function ctor() {
      this.constructor = child;
    }

    ctor.prototype = parent.prototype;
    child.prototype = new ctor();
    child.__super__ = parent.prototype;
    return child;
  };

  Packet = require('./base').Packet;

  Generic = function (_super) {
    __extends(Generic, _super);

    function Generic(type, body) {
      this.type = type;
      this.body = body;
    }

    return Generic;
  }(Packet);

  exports.Generic = Generic;
}).call(this);