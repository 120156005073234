import { Button, Checkbox, TextField } from '@material-ui/core';
import classNames from 'classnames';
import * as React from 'react';
import {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import { store } from '../..';
import { LANG_CODE_ZH_CN, loadExternContatnsIfNecessary } from '../../external-constants/extern-constants';
import { t } from '../../i18n';
import { WalletAppState } from '../../redux-store';
import { setShopUserInfoRecord } from '../../redux-store/shop-user';
import { BaseUnishellPageState, renderInitErrorUnishellPage, renderInitUnishellPage, renderUnishellPage } from '../../unishell-page-common/unishell-page-common';
import { setShopUserInfo } from '../../view/header/HeaderView2';
import { NoticeDialog } from '../../view/NoticeDialog';
import { Toast } from '../../view/Toast';
import { HTMLDialog } from '../../wallet-server-api/html-dialog/html-dialog';
import { readLabTestInstruction } from '../../wallet-server-api/wallet-server-api';
import { UButton } from '../../widget/button/ubutton';
import { CurrencyOption, WeightOption, ShoppingCartInfo, ShoppingCartItemInfo, ProductType, getShoppingCartProductType } from '../ajax-data/ajax-data-types';
import { getMyShoppingCart, getWeightOptionsMap, removeHealthAdviceToShoppingCard, setProductToShoppingCard, getCurrencyOptionsMap, getMyShopUserInfo } from '../ajax-data/shopping-server-api';
import { doAJAXCall } from '../common/ajax-call';
import { BaseShoppingPageProps, } from '../common/shopping-page-common';
import { gotoShoppingPathNSavePrevPath } from '../history/shopping-history';
import { RenderShoppingCardSummary, RenderShoppingCartHealthAdvice2, RenderSingleShoppingCartProduct} from '../shopping-order/render-order-items';

import './my-shopping-cart.scss';

export interface MyShoppingCartViewProps extends BaseShoppingPageProps{
    languageCode:string;
    currencyCode: string
}

export interface MyShoppingCartViewState extends BaseUnishellPageState{
    currencyOptionsMap?: {[key:string]:CurrencyOption[]};
    weightOptionsMap?: {[key:string]:WeightOption[]};
    shoppingCartRecord?: ShoppingCartInfo;
}

class MyShoppingCartView extends Component<MyShoppingCartViewProps, MyShoppingCartViewState>{
    private errorDialog:NoticeDialog|undefined;
    private toast: Toast | undefined;

    constructor(props: MyShoppingCartViewProps){
        super(props);
        this.state = {pageInitState:'PAGE_INIT'}
    }

    getTitle(){
        const {shoppingCartRecord} = this.state;

        if(shoppingCartRecord && getShoppingCartProductType(shoppingCartRecord)==='test'){
            return t('购物车')+'('+t('健康检测')+')' 
        }
        return t('购物车');
    }
    render(){
        if(this.state.pageInitState === 'PAGE_INIT'){
            return <Fragment>
                        {renderInitUnishellPage({title: this.getTitle(), message:t('初始化...')})}
                        <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                        <Toast close={true} duration={2500} message='' ref={e=>{this.toast=e!}} toastType='info'/>
                    </Fragment>
        }
        if(this.state.pageInitState === 'PAGE_LOAD_FAILED'){
            return <Fragment>
                        {renderInitErrorUnishellPage({title: this.getTitle(), 
                                                      errorMessage: this.state.initErrorMessage||t('初始化失败'),
                                                      onTryAgain: ()=>{ this.initializeAgain();    }
                                                      })}
                        <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                        <Toast close={true} duration={2500} message='' ref={e=>{this.toast=e!}} toastType='info'/>
                    </Fragment>
        }
        // loading data succeeded
        let content = this.renderSucceedContent();
        return (
            <React.Fragment>
                { renderUnishellPage({title: this.getTitle(), className:'shopping-cart-page', content})}
                <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                <Toast close={true} duration={2500} message='' ref={e=>{this.toast=e!}} toastType='info'/>
            </React.Fragment>
        )
    }

    updateItemCount = (newItem:ShoppingCartItemInfo)=>{
        doAJAXCall(
            {
                whatIsFor: t('更新购物车'),
                errorDialog: this.errorDialog!,
                ajaxCall: async (accessToken, username)=>{
                    let newShoppingCart: ShoppingCartInfo|undefined;
                    if(newItem.productCode){
                        newShoppingCart = await  setProductToShoppingCard(accessToken, username, newItem.productCode, newItem.count, this.props.currencyCode);
                    }else if(newItem.prescriptionCode){
                        newShoppingCart = await  removeHealthAdviceToShoppingCard(accessToken, username, newItem.prescriptionCode, this.props.currencyCode);
                    }
                    await setShopUserInfo();
        
                    this.setState({shoppingCartRecord: newShoppingCart}, ()=>{
                        this.toast!.sendMessage(t('更新购物车成功'), 'success');
                    })
                }
            }
        )
    }

    renderSucceedContent():JSX.Element {
        const isLabTest = getShoppingCartProductType(this.state.shoppingCartRecord!)==='test';
        const {currencySymbol, currencyLabel, weightOptions} = this.getCurrencyAndWeightOption(); 
        return (
            <div className='shopping-cart'>
            {this.state.shoppingCartRecord!.item.length>0 ?
                this.state.shoppingCartRecord!.item.map(item=>{
                    if(item.prescriptionCode){
                        return  <RenderShoppingCartHealthAdvice2 shoppingCartItem={item} 
                                                                currencySymbol={currencySymbol} 
                                                                currencyLabel={currencyLabel} 
                                                                weightOptions={weightOptions}
                                                                updateItemCount={this.updateItemCount} 
                                />
                    }else if(item.productCode){
                        return  <RenderSingleShoppingCartProduct shoppingCartItem={item} 
                                                                currencySymbol={currencySymbol} 
                                                                currencyLabel={currencyLabel} 
                                                                weightOptions={weightOptions} 
                                                                skipAction={false}
                                                                updateItemCount={this.updateItemCount}
                                />
                    }else {
                        return null;
                    }
                }):
                <p>{t('空购物车')}</p>
            }
            {isLabTest? <div style={{marginBottom: '20px'}}>
                            <HTMLDialog 
                                title={t('健康检测操作指南')}
                                errorDialog={this.errorDialog!}
                                loadHTMLFn={readLabTestInstruction}
                            />
                        </div>: null

            }
            <RenderShoppingCardSummary summary={this.state.shoppingCartRecord!} currencySymbol={currencySymbol} currencyLabel={currencyLabel} weightOptions={weightOptions}/>
            {this.renderButtons()}
            </div>
        )
    }

    getCurrencyAndWeightOption(){
        let currencyOptions = this.state.currencyOptionsMap![this.props.languageCode];
        if(!currencyOptions){
            currencyOptions = this.state.currencyOptionsMap![LANG_CODE_ZH_CN];
        }
        let currencyOption = currencyOptions.find(x=>x.code === this.props.currencyCode);
        if(!currencyOption){
            currencyOption = currencyOptions.find(x=>x.code === 'CAD');
        }
        const currencySymbol = currencyOption!.symbol;
        const currencyLabel = currencyOption!.label;

        let weightOptions = this.state.weightOptionsMap![this.props.languageCode];
        if(!weightOptions){
            weightOptions = this.state.weightOptionsMap![LANG_CODE_ZH_CN];
        }
        return {currencySymbol, currencyLabel, weightOptions}
    }

    renderButtons():JSX.Element {
        return (
            <div className='shopping-cart-actions'>
                <UButton variant='contained' color='secondary' size='small'
                        onClick={()=>{
                            gotoShoppingPathNSavePrevPath('main/shopping/search', {});
                        }}
                >{t('继续购物')}</UButton>
                <UButton variant='contained' color='secondary' size='small'
                        onClick={()=>{
                            this.loadData(()=>{
                                this.toast!.sendMessage(t('刷新成功'), 'success');
                            });
                        }}
                >{t('刷新')}</UButton>
                <UButton variant='contained' color='primary' size='small'
                        disabled={!this.state.shoppingCartRecord || this.state.shoppingCartRecord.item.length<=0}
                        onClick={()=>{
                            gotoShoppingPathNSavePrevPath('main/shopping/checkout');
                        }}
                >{t('结帐')}</UButton>
            </div>
        )
    }

    loadData(done?: ()=>void){
        doAJAXCall({
            whatIsFor: t('下载数据'),
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken, username)=>{
                await loadExternContatnsIfNecessary();
                let currencyOptionsMap = getCurrencyOptionsMap();
                let weightOptionsMap = getWeightOptionsMap();
                let shoppingCartRecord = await getMyShoppingCart(accessToken, username, this.props.currencyCode)
                        
                this.setState({pageInitState:'PAGE_LOAD_SUCCESS', 
                                currencyOptionsMap,
                                weightOptionsMap,
                                shoppingCartRecord
                                }, 
                                ()=>{
                                    if(done) done();
                                });
            },
            onError: (errorMessage:string)=>{ this.setState({pageInitState:'PAGE_LOAD_FAILED', initErrorMessage:errorMessage})}                                           
        })
    }

    componentDidMount(){
        this.loadData();
    }

    initializeAgain(){
        this.setState({pageInitState:'PAGE_INIT', initErrorMessage:undefined},
                      ()=>{
                          this.loadData();
                      })
    }

    componentDidUpdate(){
        if(this.state.pageInitState==='PAGE_LOAD_SUCCESS' && this.state.shoppingCartRecord){
            if(this.state.shoppingCartRecord.currencyCode !== this.props.currencyCode){
                this.loadData();
            }
        }
    }
}

export const MyShoppingCart = connect(
    (state:WalletAppState)=>({languageCode:state.language.langCode, currencyCode: state.currency.currencyCode})
)(MyShoppingCartView);