import * as React from 'react';
import {Component, Fragment} from 'react';
import './HeaderView2.scss';
import { connect, ReactReduxContext } from 'react-redux';
import { getLanguageCode, getValidLoginSession } from '../../local-storage/local-storage';
import { getCordova, getTopPadding } from '../../globals';
import { gotoShoppingPathNSavePrevPath, shoppingGoBack } from '../../shopping/history/shopping-history';
import { ReactComponent as MenuIcon} from '../../images/menu.svg';
import { UnishellTreeMenus } from '../../tree-menu/tree-menu';
import {ReactComponent as LeftArray} from '../../images/left-chevron.svg';
import {ReactComponent as ShoppingCartIcon} from '../../images/shopping-cart-fill.svg';
import {ReactComponent as AdviceIcon} from '../../images/advice-fill.svg';
import classnames from 'classnames';
import { isNutritionist } from '../../api/user';
import { getMyShopUserInfo } from '../../shopping/ajax-data/shopping-server-api';
import { setShopUserInfoRecord, ShopUserInfoRecord } from '../../redux-store/shop-user';
import { WalletAppState } from '../../redux-store';
import { store } from '../..';

interface HeaderView2Props extends ShopUserInfoRecord{
    title: string;
    noMenuIcon?: boolean;
    customeGoBack?: ()=>void;
    backgroundColor?: string;
    underTopHeader?: JSX.Element;
    languageCode:string;
}

interface HeaderView2State {
}

class HeaderView extends Component<HeaderView2Props,HeaderView2State>{
    private menu: UnishellTreeMenus | undefined;

    constructor(props:HeaderView2Props){
        super(props);
        this.state = {}
    }

    gotoHealthAdvice(){
        if(isNutritionist()){
            gotoShoppingPathNSavePrevPath('main/tools/health-advice-by-me');
        }else{
            gotoShoppingPathNSavePrevPath('main/tools/my-health-advice');
        }
    }


    render(){
        let {noMenuIcon} = this.props;
        let iAMNutritionis = isNutritionist();

        let style:React.CSSProperties = {
        }
       // if(getTopPadding()>0){
        //    style.borderTop = `${getTopPadding()}px solid white`;
        //}
        if(this.props.backgroundColor){
            style.background = 'unset';
            style.backgroundColor = this.props.backgroundColor;
        }
        return <ReactReduxContext.Consumer>
               {({store})=>{
                    return (
                        <div id='top-header-wrapper' className='top-header-wrapper-class'>
                            <div className={classnames('header2',getLanguageCode(), {"no-menu-icon":noMenuIcon})} 
                                style={style} id='top-header'>
                                <div className='left-icons'>
                                    <LeftArray id='page-back-icon' onClick={()=>{
                                        if(this.props.customeGoBack){
                                            this.props.customeGoBack();
                                        }else{
                                            shoppingGoBack();                                    
                                        }
                                    }}/>
                                    {noMenuIcon? null:<MenuIcon className='unishell-menu-icon' onClick={()=>{ this.menu!.open() }}/>}
                                </div>

                                <div className='title'>{this.props.title}</div>

                                <div className='right-icons'>
                                    {noMenuIcon? null:
                                        this.props.prescriptionCount>0?
                                        <div  className='with-red-dot'>
                                            <AdviceIcon className='health-advice-icon'
                                                    onClick={()=>{ this.gotoHealthAdvice();}}
                                            />
                                            <div className='red-dot' />                                           
                                        </div>: 
                                        <AdviceIcon className='health-advice-icon'
                                                onClick={()=>{ this.gotoHealthAdvice();}}
                                        />
                                    }
                                    {noMenuIcon? null:
                                        this.props.shoppingCartItemCount>0 ?
                                        <div className='with-red-dot'>
                                            <ShoppingCartIcon className='shopping-cart-icon'
                                                            onClick={()=>{
                                                                gotoShoppingPathNSavePrevPath('main/shopping/my-shopping-cart');
                                            }} 
                                            />
                                            <div className='red-dot' />                                           
                                        </div> 
                                        : 
                                        <ShoppingCartIcon className='shopping-cart-icon'
                                                        onClick={()=>{
                                                            gotoShoppingPathNSavePrevPath('main/shopping/my-shopping-cart');
                                        }}
                                    />}
                                </div>
                                {noMenuIcon? null: <UnishellTreeMenus 
                                                        key={'tree-menu-'+this.props.languageCode} 
                                                        ref={e=>{this.menu = e!}}/>}
                            </div>
                            <div id='under-top-header'>
                            {this.props.underTopHeader || null}                                                            
                            </div>
                        </div>    
                    )
               }}
               </ReactReduxContext.Consumer>
    }

    setStatusBarColor(){
        const globalEverywhere = window || global || {};
        // @ts-ignore
        if(!!getCordova() && globalEverywhere.StatusBar){
            // @ts-ignore
            globalEverywhere.StatusBar.backgroundColorByHexString(
                this.props.noMenuIcon? '#ffffff': this.props.backgroundColor || '#1F7D2C');
        }
    }

    componentDidUpdate(prevProps: HeaderView2Props, prevState: any, snapshot: any){
        if(prevProps.backgroundColor !== this.props.backgroundColor){
            this.setStatusBarColor();
        }
    }

    componentDidMount(){
        this.setStatusBarColor();
        setShopUserInfo();
    }

}

export async function setShopUserInfo(){
    try{
        // optional step
        let loginSession = getValidLoginSession();
        if(loginSession){
            let shopUserInfo = await getMyShopUserInfo(loginSession.token!.accessToken);
            store.dispatch(setShopUserInfoRecord(
                {
                    shoppingCartItemCount: shopUserInfo.shoppingCartItemCount,
                    prescriptionCount: shopUserInfo.prescriptionCount
                }
            ))
        }    
    }catch(error){
    }
}

export const Header = connect(
    (state:WalletAppState)=>{ 
        return {
            shoppingCartItemCount: state.shopUserInfo.shoppingCartItemCount,
            prescriptionCount: state.shopUserInfo.prescriptionCount,
            languageCode: state.language.langCode
        }  
    }
)(HeaderView);
