import { Button } from '@material-ui/core';
import {loadStripe, StripeCardElement, Stripe, StripeCardNumberElement, StripeErrorType, StripeError, PaymentIntent} from '@stripe/stripe-js';
import {Elements,ElementsConsumer, CardElement, CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements} from '@stripe/react-stripe-js';

import * as React from 'react';
import {Component} from 'react';
import { t } from '../../i18n';
import { NoticeDialog } from '../../view/NoticeDialog';
import { getStripeKey} from '../../wallet-server-api/wallet-server-api';
import { CurrencyOption, PaymentMethodOption, ShopPaymentRequestData } from '../ajax-data/ajax-data-types';
import { doAJAXCall } from '../common/ajax-call';
import './credit-card-payment.scss';
import {getCurrencyOption, formatPrice, getStripeErrorMessage} from '../../util/Util';
import { completeShoppingStripePayment, prepareShoppingPayment } from '../ajax-data/shopping-server-api';
import { PaymentSuccess } from './payment-success';
import { gotoShoppingPathNSavePrevPath } from '../history/shopping-history';
import { loadExternContatnsIfNecessary } from '../../external-constants/extern-constants';
import { UButton } from '../../widget/button/ubutton';
import { setShopUserInfo } from '../../view/header/HeaderView2';

interface CrditCardPaymentProps {
    languageCode: string;
    currencyOptionsMap: {[key:string]:CurrencyOption[]};
    paymentMethodOptionsMap:{[key:string]:PaymentMethodOption[]};
    paymentData: ShopPaymentRequestData;
}

interface CrditCardPaymentState {
    stripePK?:string;
    stripePromise?:Promise<Stripe|null>;

    paymentDone: boolean;
    orderId?: string;
}

export class CreditCardPayment extends Component<CrditCardPaymentProps, CrditCardPaymentState>{
    private errorDialog: NoticeDialog|undefined;

    private processingStripePayment = false;
    private debugMessages:string[] = [];

    constructor(props: CrditCardPaymentProps){
        super(props);
        this.state = {paymentDone: false};
    }
    appendDebugMessage = (msg:string): void=>{
        // this.debugMessages.push(Date.now()+" "+msg);
    }

    confirmStripePayment = (stripe: Stripe, card: StripeCardNumberElement) => {
        const nonce = ''+Math.random();
        this.props.paymentData.timestamp = Date.now();
        this.props.paymentData.nonce = nonce;

        doAJAXCall({
            whatIsFor: t('信用卡支付'),
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken)=>{
                let response = await prepareShoppingPayment(accessToken, this.props.paymentData);
                let result = await stripe.confirmCardPayment(response.stripeClientSecret!, 
                                                                    { payment_method:{
                                                                        card,
                                                                        billing_details: {}
                                                                        }
                                                                    });

                let orderCode = response.orderCode;
                if(result.error){
                    // no need to wait for response
                    completeShoppingStripePayment(accessToken, {isSuccess: false, orderCode, result: result.error.message!});
                    throw getStripeErrorMessage(result.error!);
                }else{
                    if (result.paymentIntent!.status === 'succeeded') {
                        // Show a success message to your customer
                        // There's a risk of the customer closing the window before callback
                        // execution. Set up a webhook or plugin to listen for the
                        // payment_intent.succeeded event that handles any business critical
                        // post-payment actions.
                        this.appendDebugMessage(" calling payment succeeded");
    
                        await completeShoppingStripePayment(accessToken, {isSuccess: true, orderCode, result: 'intent id='+result.paymentIntent!.id});
                        await setShopUserInfo();
                        this.setState({orderId: orderCode, paymentDone: true});
                    }else{
                        await completeShoppingStripePayment(accessToken, {isSuccess: false, orderCode, result: "payment status="+result.paymentIntent!.status});
                        throw "payment status="+result.paymentIntent!.status;    
                    }
                }                                        
                this.processingStripePayment = false;
            },
            onError: (errorMessage:string)=>{
                this.processingStripePayment = false;
            }
        });
    }

    render(){
        const currencyOption = getCurrencyOption(this.props.currencyOptionsMap, this.props.languageCode, this.props.paymentData.currencyCode);
        if(this.state.paymentDone){
            return (
                <div className='credit-card-payment'>
                    <PaymentSuccess languageCode={this.props.languageCode} amount={this.props.paymentData.totalAmount}
                                    currencyCode={this.props.paymentData.currencyCode}
                                    orderId={this.state.orderId!}
                                    currencyOptionsMap={this.props.currencyOptionsMap}
                                    paymentMethodCode='CreditCard'
                                    paymentMethodOptionsMap={this.props.paymentMethodOptionsMap} 
                    />
                    <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                </div>
            )
        }
        this.processingStripePayment = false;

        const CARD_ELEMENT_OPTIONS = {
            style: {
              base: {
                color: "#32325d",
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "16px",
                "::placeholder": {
                  color: "#aab7c4",
                },
              },
              invalid: {
                color: "#fa755a",
                iconColor: "#fa755a",
              },
            },
          };

        return (
            <div className='credit-card-payment'>
            <div className='header'>
                <h3>{t('信用卡支付')}</h3>
            </div>
            <table className='order-info'>
                <tbody>
                    {/* <tr>
                        <td className='label'>{t('订单号')}</td>
                        <td>{this.props.order.orderId}</td>
                    </tr> */}
                    <tr>
                        <td className='label'>{t('金额')}</td>
                        <td className='amount'>{formatPrice(this.props.paymentData.totalAmount, currencyOption.symbol, currencyOption.label)}</td>
                    </tr>
                </tbody>
            </table>
            { this.state.stripePromise &&
            <div className='stripe-wrapper'>
                <Elements stripe={this.state.stripePromise}>
                        <ElementsConsumer>
                        {({stripe, elements}) => {
                            const onSubmit = (event:any)=>{
                                const t0 = Date.now();
                                this.appendDebugMessage(" stripe pay starts");
                                event.preventDefault();
                                if (!stripe || !elements) {
                                    // Stripe.js has not yet loaded.
                                    // Make  sure to disable form submission until Stripe.js has loaded.
                                    this.appendDebugMessage(" stripe.js is not ready");
                                    return;
                                }
                                if(this.processingStripePayment){ 
                                    this.appendDebugMessage(" user double click submit button");
                                    return; // user double click submit button
                                }

                                this.processingStripePayment = true;
                            
                                const card = elements.getElement(CardNumberElement)!;
                                const t1 = Date.now();
                                this.appendDebugMessage('call confirm stripe pay after '+(t1-t0)+" ms");
                                this.confirmStripePayment(stripe, card);
                            }
                            
                            return (
                            <form className='stripe-form' >
                                <label>{t('信用卡号')+(this.state.stripePK!.startsWith('pk_test') ? ' (test)': '')}</label><CardNumberElement options={CARD_ELEMENT_OPTIONS}/>
                                <label>{t('失效 月/年')}</label><CardExpiryElement  options={CARD_ELEMENT_OPTIONS}/>
                                <label>{t('验证码')}</label><CardCvcElement  options={CARD_ELEMENT_OPTIONS}/>

                                <div className='action-buttons'>
                                    <UButton variant='contained' color='primary' disabled={!stripe || !elements} 
                                            onClick={(evt)=>{
                                                // alert('click payment button');
                                                this.debugMessages = [];
                                                this.appendDebugMessage('click payment button');
                                                onSubmit(evt);
                                    }}>{t('支付')}</UButton>

                                    <UButton variant='contained' color='secondary' id='shopping-button'
                                            onClick={()=>{
                                                gotoShoppingPathNSavePrevPath('main/shopping/search', {});
                                            }}
                                    >{t('继续购物')}</UButton>
                                </div>
                            </form>
                            )}
                        }
                        </ElementsConsumer>
                </Elements>
            </div>
            }
         
            <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
        </div>

        )
    }

    componentDidMount(){
        doAJAXCall({
            whatIsFor: t('初始化Stripe接口'),
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken)=>{
                await loadExternContatnsIfNecessary();
                let stripePK = await getStripeKey(accessToken);
                let stripePromise = loadStripe(stripePK);
                this.setState({stripePK, stripePromise})
            }
        })
    }
}
