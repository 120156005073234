import { Button, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { isFinite } from 'lodash';
import * as React from 'react';
import {Component} from 'react';
import { connect } from 'react-redux';
import { PagedResponse } from '../../api/transaction';
import { loadExternContatnsIfNecessary } from '../../external-constants/extern-constants';
import { t } from '../../i18n';
import { WalletAppState } from '../../redux-store';
import { BaseUnishellPageState, renderInitErrorUnishellPage, renderInitUnishellPage, renderUnishellPage } from '../../unishell-page-common/unishell-page-common';
import { calcRationClass, formatDateTime, formatPrice, getCurrencyOption, getMaxThumbnailImgSize, getServerURL, RatioXXS, scrollBodyToTop } from '../../util/Util';
import { ConfirmDialogView } from '../../view/confirm-dialog/ConfirmDialogView';
import { NoticeDialog } from '../../view/NoticeDialog';
import { confirmOpenURL } from '../../view/open-url/open-url';
import { PropertyListDataItem, PropertyListWidget } from '../../widget/property-list/property-list';
import { CurrencyOption, PaymentMethodOption, ShopOrderInfo, ShopOrderStatus, ProductCandidateBrief, AddressInfo2Str, getShoppingCartProductType } from '../ajax-data/ajax-data-types';
import { getCurrencyOptionsMap, getPaymentMethodOptionMap,  getShippingRecordStatusOptions, getShoppingOrderStatuOptions, searchShoppingOrders } from '../ajax-data/shopping-server-api';
import { doAJAXCall } from '../common/ajax-call';
import { ImageWithOverlay } from '../common/image-with-overlay';
import { Pagination } from '../common/render-pagination';
import { BaseShoppingPageProps} from '../common/shopping-page-common';
import { gotoShoppingPathNSavePrevPath, updateCurrentPathParms } from '../history/shopping-history';
import {ReactComponent as FilterIcon} from '../../images/filter-fill.svg';


import './shopping-order-list.scss';
import { SearchTextInput } from '../common/search-text-input';
import { UDatePicker } from '../../widget/date-picker/date-picker';
import { HTMLDialog } from '../../wallet-server-api/html-dialog/html-dialog';
import { getLanguageCode } from '../../local-storage/local-storage';
import { readLabTestInstruction } from '../../wallet-server-api/wallet-server-api';

export interface ShopOrderSearchFilter {
    text?: string;
    page?: number;
    pageSize?: number;
    status?: string;
    fromTime?: number;
    toTime?: number;
}

interface ShoppingOrderListProps extends ShopOrderSearchFilter, BaseShoppingPageProps {
    currencyCode: string,
}

interface ShoppingOrderListState extends ShopOrderSearchFilter, BaseUnishellPageState {
    currencyOptionsMap?: {[key:string]:CurrencyOption[]};
    paymentMethodOptionsMap?:{[key:string]:PaymentMethodOption[]};
    orderStatusOptions?:{key:string, text:string}[];
    lastNDaysOptions?:{key:number, text:string}[];
    shippingRecordStatusOptions?:{key:string, text:string}[];

    page: number;
    pageSize: number;

    text: undefined;

    expendSearchForm?: boolean;

    searchResult?:PagedResponse<ShopOrderInfo>;
}

class ShoppingOrderListView extends Component<ShoppingOrderListProps, ShoppingOrderListState>{
    private errorDialog:NoticeDialog|undefined;
    private openUrlDialog:ConfirmDialogView | undefined;
    private searchInput: SearchTextInput | undefined;


    constructor(props: ShoppingOrderListProps){
        super(props);
        const {page, pageSize, status, fromTime, toTime} = this.props;

        this.state = {pageInitState:'PAGE_INIT', text: undefined, page: page||0, pageSize: pageSize||10, 
                      status: status ? status : '', fromTime, toTime}
    }
    getTitle(){
        return t('商城订单记录');
    }
    render(){
        if(this.state.pageInitState === 'PAGE_INIT'){
            return <React.Fragment>
                        {renderInitUnishellPage({title: this.getTitle(), message:t('初始化...')})}
                        <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                    </React.Fragment>
        }
        if(this.state.pageInitState === 'PAGE_LOAD_FAILED'){
            return <React.Fragment>
                        {renderInitErrorUnishellPage({title: this.getTitle(), 
                                                      errorMessage: this.state.initErrorMessage||t('初始化失败'),
                                                      onTryAgain: ()=>{ this.initializeAgain();    }
                                                      })}
                        <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                    </React.Fragment>
        }
        // loading data succeeded
        let content = this.renderSucceedContent();
        return (
            <React.Fragment>
                { renderUnishellPage({
                    title: this.getTitle(), 
                    className:'shopping-order-list', 
                    content,
                    underTopHeader: this.renderSearchForm2()
                })}
                <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                <ConfirmDialogView ref={e=>this.openUrlDialog=e!} open={false} />
            </React.Fragment>
        )   
    }

    renderSucceedContent():JSX.Element {
        return (
            <div className='shopping-order-list-content'>
                <Pagination data={this.state.searchResult!} page={this.state.page!} pageSize={this.state.pageSize!} 
                            loadData={this.loadPaginationData}
                            changePageSize={(newPageSize, newPage)=>{
                                this.loadPaginationData(newPage, newPageSize);
                            }}   
                >
                { this.renderOrderBriefs() }
                </Pagination>

            </div>
        )
    }

    renderOrderBriefs():JSX.Element {
        const serverURL = getServerURL();

        return (
            <div className='shopping-order-list-table-wrapper'>
            {
                this.state.searchResult!.content.map(x=>{
                    let status = '';
                    let statusOpt = this.state.orderStatusOptions!.find(o=> o.key === x.status);
                    if(statusOpt){
                        status = statusOpt.text;
                    }

                    const currencyOption = getCurrencyOption(this.state.currencyOptionsMap!, 
                                                             this.props.languageCode!, 
                                                             x.shoppingCart.currencyCode);
                    let productBriefs:ProductCandidateBrief[] = [];
                    x.shoppingCart.item.forEach(item=>{
                        item.product.forEach(prod=>{
                            productBriefs.push(prod);
                        })
                    });

                    let productType = getShoppingCartProductType(x.shoppingCart);

                    let pickup = !!x.pickupAddressInfo ? t('自提') : t('快递');

                    let htmlDialog = <HTMLDialog 
                                        title={t('健康检测操作指南')}
                                        errorDialog={this.errorDialog!}
                                        loadHTMLFn={readLabTestInstruction}
                                     />;

                    const orderProps:PropertyListDataItem[]=[
                        {name: t('订单号'), value: x.orderCode},
                        {name: t('订单类型'),value: productType==='test' ? t('健康检测'): t('一般产品')},
                        {name: '', value: htmlDialog, mergeCol:true, ignore: productType!=='test' },
                        {name: t('创建时间'), value: formatDateTime(new Date(x.createDtm))},
                        {name: t('更新时间'), value: formatDateTime(new Date(x.modifiedDtm))},
                        {name: t('总计'), valueClassName:'total-price', value: formatPrice(x.shoppingCart.totalPrice, currencyOption.symbol, currencyOption.label)},
                        {name: t('订单状态'), value: status},
                        {name: t('备注'), value: (x.notes||[]).join('; ')},
                        {
                            name: pickup, 
                            value: AddressInfo2Str((x.pickupAddressInfo || x.shippingAddressInfo)!),
                            ignore: productType==='test'
                        },
                        {name: 
                            t('快递单号'), 
                            ignore: !x.shippingRecordInfo || x.shippingRecordInfo.length<=0,
                            value: <div>
                                        {(x.shippingRecordInfo||[]).map(rec=>{
                                            const opt = this.state.shippingRecordStatusOptions!.find(v=>v.key===rec.status);
                                            const status = opt ? opt.text : ''; 
                                            return <p>
                                                        <a href={rec.trackingUrl} target='_'
                                                        onClick={evt=>{
                                                            evt.stopPropagation();
                                                            evt.preventDefault();
                                                            confirmOpenURL(rec.trackingUrl, this.openUrlDialog!);
                                                        }}
                                                        >{rec.trackNumber} ({status})</a>
                                                    </p>
                                        })}    
                                    </div>
                        },
                    ];
                    productBriefs.forEach(p=>{
                        orderProps.push({
                            name: <ImageWithOverlay imageList={[p.thumbImage.startsWith('http')? p.thumbImage : serverURL+p.thumbImage]} classes='prod-thumb-img'/>,
                            value: <div>
                                        <a href='#' onClick={evt=>{
                                                evt.stopPropagation();
                                                evt.preventDefault();
                                                gotoShoppingPathNSavePrevPath('main/shopping/product', {productCode: p.productCode})
                                            }}>{p.productName}</a> 
                                        <br/>数量 {p.count}
                                    </div>
                        })
                    })
                    return <PropertyListWidget list={orderProps} showBorder={true} mergeColScreenLetterRatio={RatioXXS} 
                                                className='shopping-order-list-table'/>
                })                
            }
            </div>
        )
    }

    renderSearchForm2():JSX.Element {
        const {} = this.state;
        let xs = 12;
        const {ratioClass, vw,  mw, ratio} = calcRationClass();
        if(ratio > RatioXXS * 3){
            xs = 4;
        }
        let startDate = this.state.fromTime? new Date(this.state.fromTime) : undefined;
        let endDate = this.state.toTime? new Date(this.state.toTime) : undefined;
        const today = new Date();

        return (
            <div className='shopping-order-list-search-order-form'>
                <div className='keyword-input'>
                    <FilterIcon className='filter-icon' 
                                onClick={()=>{ this.setState({expendSearchForm: !this.state.expendSearchForm })  }}/>
                    <SearchTextInput 
                        placeholder={t('搜索订单')} 
                        initialValue={this.props.text} 
                        ref={x=>{this.searchInput=x!}} 
                        onSearch={this.doSearch}/>
                </div>

                {this.state.expendSearchForm? 
                <Grid container className='search-order-filter' spacing={0}>
                    <Grid item xs={xs as 12}>
                        <div>
                            <label>{t(`订单状态`)}</label>
                        </div>
                        <FormControl variant="standard" size='small' className='status-select'>
                            <Select value={this.state.status} 
                                    onChange={evt=>{ this.setState({status: evt.target.value as string})  }} 
                            >
                                {this.state.orderStatusOptions!.map(x=>{
                                    return <MenuItem value={x.key}>{x.text}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={xs as 12}>
                        <div className='date-range'>
                            <label>{t('起始日期')}</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <UDatePicker value={startDate}
                                    minYear={2020}
                                    maxYear={today.getFullYear()+1}                            
                                    onChange={(value?:Date)=>{ 
                                            this.setState({fromTime: value? value.getTime() : undefined});
                                    }}
                            />
                        </div>

                    </Grid>
                    <Grid item xs={xs as 12}>
                        <div className='date-range'>
                            <label>{t('截止日期')}</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <UDatePicker value={endDate}
                                    minYear={2020}
                                    maxYear={today.getFullYear()+1}                            
                                    onChange={(value?:Date)=>{ 
                                            this.setState({toTime: value? value.getTime() : undefined});
                                    }}
                            />
                        </div>
                    </Grid>
            </Grid>
                :null
                }
            </div>)
    }

    doSearch = ()=>{
        this.loadPaginationData(this.state.page, this.state.pageSize);
    }

    getFilters(){
        const text = (this.searchInput? this.searchInput!.getValue() : this.props.text||'').trim();
        const status = (!this.state.status) ? undefined : this.state.status;
        const {fromTime, toTime} = this.state;

        return {text, status, fromTime, toTime}
    }

    loadPaginationData = (page:number, pageSize:number) => {
        this.searchOrders(page, pageSize)
    }

    searchOrders(page: number, pageSize:number){    
        const {maxImgWidth, maxImgHeight} = getMaxThumbnailImgSize();
        doAJAXCall(
            {
                whatIsFor: '商城订单记录',
                errorDialog: this.errorDialog!,
                ajaxCall: async (accessToken)=>{
                    let searchResult = await searchShoppingOrders(accessToken, page, pageSize, maxImgWidth,
                                                                  this.props.currencyCode,
                                                                  this.getFilters());
                    this.setState({searchResult, page: searchResult.page, pageSize: searchResult.size})
                }
            }
        )
    }

    initializeAgain(){
        this.setState({pageInitState:'PAGE_INIT', initErrorMessage:undefined},
                      ()=>{
                          this.loadInitData();
                      })
    }

    componentDidMount(){
        this.loadInitData();
    }

    loadInitData(){
        const {maxImgWidth} = getMaxThumbnailImgSize();

        doAJAXCall({
            whatIsFor: t('下载数据'),
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken)=>{
                                              await loadExternContatnsIfNecessary();
                                              let currencyOptionsMap = getCurrencyOptionsMap();
                                              let paymentMethodOptionsMap = getPaymentMethodOptionMap();
                                              let orderStatusOptions = getShoppingOrderStatuOptions(accessToken, this.props.languageCode!);
                                              let shippingRecordStatusOptions = getShippingRecordStatusOptions(accessToken, this.props.languageCode!);
                                              let searchResult = await searchShoppingOrders(accessToken,
                                                                                            this.state.page!, this.state.pageSize!,
                                                                                            maxImgWidth,
                                                                                            this.props.currencyCode,
                                                                                            this.getFilters());
                                              this.setState({pageInitState:'PAGE_LOAD_SUCCESS', 
                                                             currencyOptionsMap,
                                                             paymentMethodOptionsMap,
                                                             orderStatusOptions,
                                                             shippingRecordStatusOptions,
                                                             searchResult,
                                                            });
                                           },
            onError: (errorMessage:string)=>{ this.setState({pageInitState:'PAGE_LOAD_FAILED', initErrorMessage:errorMessage})}                                           
        })                
    }
    componentDidUpdate(prevProps:any, prevState:ShoppingOrderListState){
        if(prevState.page !== this.state.page){
            scrollBodyToTop();
        }
        const {page, pageSize} = this.state;
        let parms:ShopOrderSearchFilter = {...this.getFilters(), page, pageSize }
        updateCurrentPathParms('main/shopping/my-orders', parms)
    }

}

export const ShoppingOrderList = connect(
    (state:WalletAppState)=>({languageCode: state.language.langCode, currencyCode: state.currency.currencyCode})
)(ShoppingOrderListView);