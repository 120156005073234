import * as React from 'react';
import {Component, Fragment} from 'react';
// @ts-ignore
import Img from 'react-image';

interface MyProps {
    src:string, 
    alt?:string, 
    className?:string, 
    onClick?:()=>void,
    debug?: boolean
}

// set by app init code. file:///data/user/0/com.unishell.Wallet/files/files/app/
// declare var manifestRoot:string;
/**
 * display <img> that supports app upgrade. App upgrade may copy new and modifed image files to local cache
 * instead of replacing existing image file along with app js code.
 */
export class ImageWithFallback extends React.PureComponent<MyProps> {
    render (){
        const srcArray:string[] = [this.props.src];
        // @ts-ignore
        if(window.manifestRoot && window.manifestRoot.startsWith('file:') && this.props.src.startsWith('./')) {
            // @ts-ignore
            let src1 = this.props.src.replace(/^[.][/]/g, window.manifestRoot);
            srcArray.unshift(src1);
            if(this.props.debug){
                alert(`ImageWithFallback srcArray=${srcArray.join(', ')}`);
            }
            return <Img src={srcArray} className={this.props.className} onClick={this.props.onClick} alt={this.props.alt}/>
        }else{
            if(this.props.debug){
                alert(`ImageWithFallback src=${this.props.src}`);
            }
            return <img src={this.props.src} className={this.props.className}  onClick={this.props.onClick} alt={this.props.alt}/>
        }
    }
}