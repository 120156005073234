// Generated by IcedCoffeeScript 108.0.11
(function () {
  var C,
      KeyWrapper,
      Lifespan,
      Primary,
      Subkey,
      __hasProp = {}.hasOwnProperty,
      __extends = function __extends(child, parent) {
    for (var key in parent) {
      if (__hasProp.call(parent, key)) child[key] = parent[key];
    }

    function ctor() {
      this.constructor = child;
    }

    ctor.prototype = parent.prototype;
    child.prototype = new ctor();
    child.__super__ = parent.prototype;
    return child;
  };

  C = require('./const');

  Lifespan = function () {
    function Lifespan(_arg) {
      this.generated = _arg.generated, this.expire_in = _arg.expire_in;

      if (this.expire_in === void 0) {
        this.expire_in = C.default_key_expire_in;
      }
    }

    Lifespan.prototype.expires_earlier_than = function (l2) {
      if (!l2.expire_in && this.expire_in) {
        return true;
      } else if (this.expire_in && !l2.expire_in) {
        return false;
      } else if (!this.expire_in && !l2.expire_in) {
        return false;
      } else {
        return this.generated + this.expire_in < l2.generated + l2.expire_in;
      }
    };

    Lifespan.prototype.copy = function () {
      return new Lifespan({
        generated: this.generated,
        expire_in: this.expire_in
      });
    };

    return Lifespan;
  }();

  KeyWrapper = function () {
    function KeyWrapper(_arg) {
      this.key = _arg.key, this.lifespan = _arg.lifespan, this._pgp = _arg._pgp, this._keybase = _arg._keybase, this.flags = _arg.flags;
    }

    KeyWrapper.prototype.ekid = function () {
      return this.key.ekid();
    };

    KeyWrapper.prototype.overwrite_with_omitting_revokes = function (kw2) {
      if (kw2._pgp.is_revoked()) {
        return;
      }

      if (this._pgp.is_revoked() || this.lifespan.expires_earlier_than(kw2.lifespan)) {
        return this.key = kw2.key, this.lifespan = kw2.lifespan, this._pgp = kw2._pgp, this._keybase = kw2._keybase, this.flags = kw2.flags, kw2;
      }
    };

    return KeyWrapper;
  }();

  Subkey = function (_super) {
    __extends(Subkey, _super);

    function Subkey(_arg) {
      var flags, key, lifespan, _keybase, _pgp;

      key = _arg.key, flags = _arg.flags, _pgp = _arg._pgp, _keybase = _arg._keybase, this.desc = _arg.desc, lifespan = _arg.lifespan, this.primary = _arg.primary;

      Subkey.__super__.constructor.call(this, {
        key: key,
        lifespan: lifespan,
        flags: flags,
        _pgp: _pgp,
        _keybase: _keybase
      });
    }

    return Subkey;
  }(KeyWrapper);

  Primary = function (_super) {
    __extends(Primary, _super);

    function Primary(_arg) {
      var flags, key, lifespan, _keybase, _pgp;

      key = _arg.key, lifespan = _arg.lifespan, flags = _arg.flags, _pgp = _arg._pgp, _keybase = _arg._keybase;

      Primary.__super__.constructor.call(this, {
        key: key,
        lifespan: lifespan,
        flags: flags,
        _pgp: _pgp,
        _keybase: _keybase
      });
    }

    return Primary;
  }(KeyWrapper);

  exports.Lifespan = Lifespan;
  exports.Subkey = Subkey;
  exports.Primary = Primary;
}).call(this);