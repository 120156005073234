import classnames from 'classnames';
import * as React from 'react';
import {Component, Fragment} from 'react';
import uuidv4 from 'uuid/v4';
import { t } from '../../i18n';
import { NoticeDialog } from '../../view/NoticeDialog';
import './read-more.scss';

interface MyProps {
    title: string;
    threshold: number;
    className?: string;
}

interface MyState {
    showMore?: boolean;
    contentId: string;
}

export class ReadMore extends Component<MyProps, MyState>{
    private moreDialog:NoticeDialog | undefined;

    constructor(props: MyProps){
        super(props);
        this.state = {contentId: uuidv4()}
    }

    render(){

        return <div className={classnames('read-more-wrapper', this.props.className)}>
                    <div className='content' id={this.state.contentId}>
                        {this.props.children}
                    </div>
                    {this.state.showMore? 
                        <div>
                            <button className='read-more-button' onClick={()=>{ this.showMoreDialog() }}>{t('更多内容...')}</button>
                        </div>: null
                    }
                    <NoticeDialog 
                        open={false} 
                        className={classnames('read-more-dialog', this.props.className)}
                        ref={e=>this.moreDialog=e!}
                    />
               </div>
    }

    showMoreDialog(){
        this.moreDialog!.show(  this.props.title, 
                                <div className='content-in-dialog'>
                                    {this.props.children}
                                </div>,
                                ()=>{})
    }

    componentDidMount(){
        const contentDiv = document.getElementById(this.state.contentId)!;
        if(contentDiv.scrollHeight - contentDiv.clientHeight > this.props.threshold){
            this.setState({showMore: true});
        }
    }
}
