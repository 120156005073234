// Generated by IcedCoffeeScript 108.0.11
(function () {
  var C,
      SignatureEngine,
      SignatureEngineInterface,
      burn,
      decode,
      decode_sig,
      get_sig_body,
      iced,
      make_esc,
      processor,
      __iced_k,
      __iced_k_noop,
      __hasProp = {}.hasOwnProperty,
      __extends = function __extends(child, parent) {
    for (var key in parent) {
      if (__hasProp.call(parent, key)) child[key] = parent[key];
    }

    function ctor() {
      this.constructor = child;
    }

    ctor.prototype = parent.prototype;
    child.prototype = new ctor();
    child.__super__ = parent.prototype;
    return child;
  };

  iced = require('iced-runtime');

  __iced_k = __iced_k_noop = function __iced_k_noop() {};

  make_esc = require('iced-error').make_esc;
  burn = require('./burner').burn;
  processor = require('./processor');
  decode = require('./armor').decode;
  C = require('../const');
  SignatureEngineInterface = require('../kmi').SignatureEngineInterface;

  exports.decode_sig = decode_sig = function decode_sig(_arg) {
    var armored, err, msg, mt, _ref;

    armored = _arg.armored;
    _ref = decode(armored), err = _ref[0], msg = _ref[1];
    mt = C.openpgp.message_types;

    if (err == null && msg.type !== mt.generic) {
      err = new Error("wrong message type; expected a generic message; got " + msg.type);
    }

    return [err, msg];
  };

  exports.get_sig_body = get_sig_body = function get_sig_body(_arg) {
    var armored, err, msg, res, _ref;

    armored = _arg.armored;
    res = null;
    _ref = decode_sig({
      armored: armored
    }), err = _ref[0], msg = _ref[1];

    if (err == null) {
      res = msg.body;
    }

    return [err, res];
  };

  exports.SignatureEngine = SignatureEngine = function (_super) {
    __extends(SignatureEngine, _super);

    function SignatureEngine(_arg) {
      this.km = _arg.km;
    }

    SignatureEngine.prototype.get_km = function () {
      return this.km;
    };

    SignatureEngine.prototype.box = function (msg, cb, opts) {
      var err, out, signing_key, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);

      if (opts == null) {
        opts = {};
      }

      out = {
        type: "pgp"
      };
      (function (_this) {
        return function (__iced_k) {
          if (opts.prefix != null) {
            return __iced_k(err = new Error("prefixes cannot be used with PGP"));
          } else {
            (function (__iced_k) {
              if ((signing_key = _this.km.find_signing_pgp_key()) != null) {
                (function (__iced_k) {
                  __iced_deferrals = new iced.Deferrals(__iced_k, {
                    parent: ___iced_passed_deferral,
                    filename: "/Users/max/src/keybase/kbpgp/src/openpgp/sigeng.iced",
                    funcname: "SignatureEngine.box"
                  });
                  burn({
                    msg: msg,
                    signing_key: signing_key,
                    opts: opts
                  }, __iced_deferrals.defer({
                    assign_fn: function (__slot_1, __slot_2) {
                      return function () {
                        err = arguments[0];
                        __slot_1.pgp = arguments[1];
                        return __slot_2.raw = arguments[2];
                      };
                    }(out, out),
                    lineno: 38
                  }));

                  __iced_deferrals._fulfill();
                })(function () {
                  return __iced_k(err == null ? out.armored = out.pgp : void 0);
                });
              } else {
                return __iced_k(err = new Error("No signing key found"));
              }
            })(__iced_k);
          }
        };
      })(this)(function (_this) {
        return function () {
          return cb(err, out);
        };
      }(this));
    };

    SignatureEngine.prototype.get_unverified_payload_from_raw_sig_body = function (_arg, cb) {
      var body, err, esc, l, literals, m, n, payload, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      body = _arg.body;
      esc = make_esc(cb, "get_payload_from_raw_sig_body");
      payload = null;
      m = new processor.Message({});
      (function (_this) {
        return function (__iced_k) {
          __iced_deferrals = new iced.Deferrals(__iced_k, {
            parent: ___iced_passed_deferral,
            filename: "/Users/max/src/keybase/kbpgp/src/openpgp/sigeng.iced",
            funcname: "SignatureEngine.get_unverified_payload_from_raw_sig_body"
          });
          m.parse_and_inflate(body, esc(__iced_deferrals.defer({
            assign_fn: function () {
              return function () {
                return literals = arguments[0];
              };
            }(),
            lineno: 49
          })));

          __iced_deferrals._fulfill();
        };
      })(this)(function (_this) {
        return function () {
          if ((n = literals.length) !== 1 || (l = literals[0]) == null) {
            err = new Error("Got " + n + " literals; only wanted 1");
          } else {
            payload = l.data;
          }

          return cb(err, payload);
        };
      }(this));
    };

    SignatureEngine.prototype.get_body = function (args, cb) {
      var err, res, _ref;

      _ref = get_sig_body(args), err = _ref[0], res = _ref[1];
      return cb(err, res);
    };

    SignatureEngine.prototype.decode = function (armored, cb) {
      var err, msg, _ref;

      _ref = decode_sig({
        armored: armored
      }), err = _ref[0], msg = _ref[1];
      return cb(err, msg);
    };

    SignatureEngine.prototype.unbox = function (msg, cb, opts) {
      var eng, esc, literals, payload, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);

      if (opts == null) {
        opts = {};
      }

      esc = make_esc(cb, "SignatureEngine::unbox");
      (function (_this) {
        return function (__iced_k) {
          if (typeof msg === 'string') {
            (function (__iced_k) {
              __iced_deferrals = new iced.Deferrals(__iced_k, {
                parent: ___iced_passed_deferral,
                filename: "/Users/max/src/keybase/kbpgp/src/openpgp/sigeng.iced",
                funcname: "SignatureEngine.unbox"
              });

              _this.decode(msg, esc(__iced_deferrals.defer({
                assign_fn: function () {
                  return function () {
                    return msg = arguments[0];
                  };
                }(),
                lineno: 73
              })));

              __iced_deferrals._fulfill();
            })(__iced_k);
          } else {
            return __iced_k();
          }
        };
      })(this)(function (_this) {
        return function () {
          opts.keyfetch = _this.km;
          opts.strict = true;
          eng = new processor.Message(opts);

          (function (__iced_k) {
            __iced_deferrals = new iced.Deferrals(__iced_k, {
              parent: ___iced_passed_deferral,
              filename: "/Users/max/src/keybase/kbpgp/src/openpgp/sigeng.iced",
              funcname: "SignatureEngine.unbox"
            });
            eng.parse_and_process({
              body: msg.body
            }, esc(__iced_deferrals.defer({
              assign_fn: function () {
                return function () {
                  return literals = arguments[0];
                };
              }(),
              lineno: 77
            })));

            __iced_deferrals._fulfill();
          })(function () {
            (function (__iced_k) {
              __iced_deferrals = new iced.Deferrals(__iced_k, {
                parent: ___iced_passed_deferral,
                filename: "/Users/max/src/keybase/kbpgp/src/openpgp/sigeng.iced",
                funcname: "SignatureEngine.unbox"
              });

              _this._check_result(literals, esc(__iced_deferrals.defer({
                assign_fn: function () {
                  return function () {
                    return payload = arguments[0];
                  };
                }(),
                lineno: 78
              })));

              __iced_deferrals._fulfill();
            })(function () {
              return cb(null, payload, msg.body);
            });
          });
        };
      }(this));
    };

    SignatureEngine.prototype._check_result = function (literals, cb) {
      var b, err, l, n, payload, sw, _ref;

      err = payload = null;

      if ((n = literals.length) !== 1 || (l = literals[0]) == null) {
        err = new Error("Expected only one pgp literal; got " + n);
      } else if ((sw = (_ref = l.get_data_signer()) != null ? _ref.sig : void 0) == null) {
        err = new Error("Expected a signature on the payload message");
      } else if (this.km.find_pgp_key(b = sw.get_key_id()) == null) {
        err = new Error("Failed sanity check; didn't have a key for '" + b.toString('hex') + "'");
      } else {
        payload = l.data;
      }

      return cb(err, payload);
    };

    return SignatureEngine;
  }(SignatureEngineInterface);
}).call(this);