import * as React from 'react';
import {Component} from 'react';
import './payment-success.scss';

import successIcon from './checked.svg';
import { t } from '../../i18n';
import { Button } from '@material-ui/core';
import { CurrencyOption, PaymentMethod, PaymentMethodOption } from '../ajax-data/ajax-data-types';
import { getCurrencyOption, getPaymentMethodOption } from '../../util/Util';
import { gotoShoppingPathNSavePrevPath } from '../history/shopping-history';
import { UButton } from '../../widget/button/ubutton';

export function PaymentSuccess(args:{   languageCode: string,
                                        paymentMethodCode: PaymentMethod,
                                        orderId: string,
                                        amount: number,
                                        currencyCode: string,
                                        currencyOptionsMap: {[key:string]:CurrencyOption[]},
                                        paymentMethodOptionsMap:{[key:string]:PaymentMethodOption[]}
                                    }):JSX.Element
{
    const currencyOption = getCurrencyOption(args.currencyOptionsMap, args.languageCode, args.currencyCode);
    const pmOption = getPaymentMethodOption(args.paymentMethodOptionsMap, args.languageCode, args.paymentMethodCode);

    return (
        <div className='payment-success'>
           <img className='success-icon' src={successIcon} />
           <div className='header'>{t('支付成功')}</div>
           <table>
               <tbody>
                   <tr>
                       <td>{t('支付方式')}</td>
                       <td>{pmOption.label}</td>
                   </tr>

                   <tr>
                       <td>{t('订单号')}</td>
                       <td>{args.orderId}</td>
                   </tr>

                   <tr>
                       <td>{t('支付金额')}</td>
                       <td className='amount'>{currencyOption.symbol}  {args.amount.toFixed(2)} {currencyOption.label}</td>
                   </tr>

               </tbody>
           </table>

           <UButton variant='contained' color='primary'
                   onClick={()=>{
                        gotoShoppingPathNSavePrevPath('main/shopping/search', {});
                    }}
           >{t('继续购物')}</UButton>
        </div>
    )
}