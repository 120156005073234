import * as React from 'react';
import {Component} from 'react';
import classNames from 'classnames';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button } from '@material-ui/core';
import {t} from '../../i18n';
import './PromptDialogView.scss';
import { UButton } from '../../widget/button/ubutton';

/**
 * @param ok true if user clicks Ok button; false if user clicks Cancel Button
 * @param value user input text
 */
export type OnCloseFn = (ok:boolean, value:string|undefined)=>void;

interface PromptDialogProps {
    title?: string;
    message?:JSX.Element;
    handleClose?: OnCloseFn;
    yesLabel?: string;
    noLabel?: string;
    open: boolean;
    id?:string
}


interface PromptDialogState {
    open: boolean;
    title?: string;
    message?:JSX.Element;
}

/**
 * A small stateful dialog.
 * 
 * Caller can call show() method to show the dialog and ask user to confirm or cancel an action. Or
 * caller can change key in <PromptDialog open={true} tile='...' messag={...} key='...'/> to cause PromptDialog to
 * re-render.
 */
export class PromptDialogView extends Component<PromptDialogProps,PromptDialogState>{
    private inputElem:HTMLInputElement|undefined;

    constructor(props:PromptDialogProps){
        super(props);
        let {open, title, message} = {...this.props};
        this.state = {open, title, message}
    }

    /**
     * caller can call it directly to show the dialog and ask user to confirm or cancel an action.
     * 
     * @param title 
     * @param message 
     */
    public show(title:string, message: JSX.Element, value?:string){
        if(this.inputElem) this.inputElem!.value = value || '';
        this.setState({title, message, open:true});
    }

    private handleClose=(ok:boolean)=>{
        this.setState({open: false});
        if(this.props.handleClose){
            this.props.handleClose(ok, this.inputElem ? this.inputElem!.value:undefined);
        }
    }
        
    render(){
        return (
            <Dialog  open={this.state.open} aria-labelledby="alert-dialog-title" 
                     aria-describedby="alert-dialog-description"
                     fullWidth={true} maxWidth='xl' className='prompt-dialog'>
                <DialogTitle id="alert-dialog-title">{this.state.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {this.state.message}
                    </DialogContentText>
                    <input className='prompt-dialog-input' type='text' ref={e => {this.inputElem = e!}}/>
                </DialogContent>
                <DialogActions>
                <UButton onClick={()=>{this.handleClose(true)}} color="primary">
                    {this.props.yesLabel||t('同意')}
                </UButton>
                <UButton onClick={()=>{this.handleClose(false)}} color="primary">
                    {this.props.noLabel||t('取消')}
                </UButton>
                </DialogActions>
            </Dialog>
        )
    }
}
