import { String } from 'aws-sdk/clients/ecs';
import * as React from 'react';
import {Component, Fragment} from 'react'
import { t } from '../../i18n';
import { doAJAXCall } from '../../shopping/common/ajax-call';
import { renderUnishellPage } from '../../unishell-page-common/unishell-page-common';
import { updateProductIndex,showLatestProductIndexTask } from '../../wallet-server-api/wallet-server-api';
import { UButton } from '../../widget/button/ubutton';
import { SimpleNote } from '../../widget/note/note';
import { NoticeDialog } from '../NoticeDialog';
import { Toast } from '../Toast';
import './update-prod-index.scss';

export class UpdateProdIndex extends Component<{},{updateMsg?: String}>{
    private errorDialog: NoticeDialog|undefined;
    private toast: Toast | undefined;

    constructor(props: {}){
        super(props);
        this.state = {updateMsg: ''}
    }

    getTitle(){
        return t('更新搜索引擎');
    }

    showLatestProductIndexTask() {
        doAJAXCall({
            whatIsFor: this.getTitle(),
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken)=>{
                let result = await showLatestProductIndexTask(accessToken);
                let numOfProducts = result.chanpinCount;
                let numOfShuyus = result.termCount;
                let createdAt = new Date(result.createdAt);
                let duration = result.durationMill/1000;
                
                let updateMsg = `${t('更新')} ${numOfProducts} ${t('产品')} ${numOfShuyus} ${t('术语')}, ${t('状态')}: ${result.status}, ${t('创建于')}: ${createdAt.toLocaleString()}, ${t('运行时间(秒)')}: ${duration}, ${t('任务号')}: ${result.taskId}`;
                this.setState({updateMsg}, ()=>{
                    this.toast!.sendMessage(updateMsg, 'success');
                })
            }
        })
    }

    updateProductIndex() {
        doAJAXCall({
            whatIsFor: this.getTitle(),
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken)=>{
                let result = await updateProductIndex(accessToken);
                let numOfProducts = result.chanpinCount;
                let numOfShuyus = result.termCount;
                let createdAt = new Date(result.createdAt);
                let duration = result.durationMill/1000;
                
                let updateMsg = `${t('更新')} ${numOfProducts} ${t('产品')} ${numOfShuyus} ${t('术语')}, ${t('状态')}: ${result.status}, ${t('创建于')}: ${createdAt.toLocaleString()}, ${t('运行时间(秒)')}: ${duration}, ${t('任务号')}: ${result.taskId}`;
                this.setState({updateMsg}, ()=>{
                    this.toast!.sendMessage(updateMsg, 'success');
                })
            }
        })
    }

    render(){
        const content =(
            <div className='update-prod-index-content'>
                <SimpleNote noticeType='warn' 
                            message={t('更新产品搜索引擎. 此操作可能花较长时间.')}
                />
                
                { this.state.updateMsg ? 
                  <SimpleNote noticeType='success' message={this.state.updateMsg}/> : null
                }

                <UButton variant='contained' color='primary' 
                         onClick={()=>{ this.showLatestProductIndexTask() }}
                >{t('查询最近的更新状态')}</UButton>
                <UButton variant='contained' color='primary' 
                         onClick={()=>{ this.updateProductIndex() }}
                >{this.getTitle()}</UButton>
                <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>     
                <Toast close={true} message='' toastType='info' onClose={()=>{}} ref={(e)=>{this.toast=e!}} duration={2000}/>              
            </div>
        );

        return renderUnishellPage({
            title: t('更新搜索引擎'),
            className: 'update-prod-index-page',
            content
        });        
    }
}
