import * as React from 'react';
import { Component } from 'react';
import classNames from 'classnames';
import { IconButton, InputBase } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import uuidv4 from 'uuid/v4';
import './search-text-input.scss';

export interface SearchTextInputProps {
    placeholder:string;        
    className?: string;
    initialValue?:string;
    onSearch?: (search:string)=>void;
} 

export class SearchTextInput extends Component<SearchTextInputProps> {
    private id = uuidv4();

    constructor(props: SearchTextInputProps) {
        super(props);
    }

    callOnSearch(text:string){
        if(this.props.onSearch){
            this.props.onSearch(text);
        }
    }

    render() {
        let classes = classNames('shopping-product-search', this.props.className);

        return (
            <div className={classes}>
                <InputBase id={this.id} className='search-input' placeholder={this.props.placeholder} 
                           onKeyPress={(evt)=>{evt.key=='Enter' && this.callOnSearch(this.getValue()) }}
                />
                <IconButton className='search-button'
                            onClick={ () => {this.callOnSearch(this.getValue());} }
                >
                    <SearchIcon />
                </IconButton>
            </div>
        );
    }

    setValue(value: string) {
        let input = document.getElementById(this.id) as HTMLInputElement;
        input.value = value || '';
    }

    getValue() {
        let input = document.getElementById(this.id) as HTMLInputElement;
        return input? input.value || '' : this.props.initialValue||'';
    }

    componentDidMount() {
        if (this.props.initialValue) {
            let input = document.getElementById(this.id) as HTMLInputElement;
            input.value = this.props.initialValue;
        }
    }
}
