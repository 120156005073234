// Generated by IcedCoffeeScript 108.0.11
(function () {
  var BaseKey,
      BaseKeyPair,
      K,
      NaclDh,
      Pair,
      Priv,
      Pub,
      SRF,
      TYPE,
      b2u,
      bufeq_fast,
      bufeq_secure,
      genseed,
      iced,
      kbnacl,
      konst,
      prefix_signature_payload,
      u2b,
      __iced_k,
      __iced_k_noop,
      _ref,
      __hasProp = {}.hasOwnProperty,
      __extends = function __extends(child, parent) {
    for (var key in parent) {
      if (__hasProp.call(parent, key)) child[key] = parent[key];
    }

    function ctor() {
      this.constructor = child;
    }

    ctor.prototype = parent.prototype;
    child.prototype = new ctor();
    child.__super__ = parent.prototype;
    return child;
  };

  iced = require('iced-runtime');

  __iced_k = __iced_k_noop = function __iced_k_noop() {};

  kbnacl = require('keybase-nacl');
  SRF = require('../rand').SRF;
  konst = require('../const');
  K = konst.kb;
  _ref = require('../util'), prefix_signature_payload = _ref.prefix_signature_payload, genseed = _ref.genseed, bufeq_secure = _ref.bufeq_secure, bufeq_fast = _ref.bufeq_fast;
  BaseKey = require('../basekeypair').BaseKey;
  BaseKeyPair = require('./base').BaseKeyPair;
  NaclDh = require('./dh').Pair;
  TYPE = K.public_key_algorithms.NACL_EDDSA;

  exports.b2u = b2u = function b2u(b) {
    return new Uint8Array(b);
  };

  exports.u2b = u2b = function u2b(u) {
    return Buffer.from(u);
  };

  Pub = function () {
    Pub.HEADER = Buffer.from([K.kid.version, TYPE]);
    Pub.TRAILER = Buffer.from([K.kid.trailer]);
    Pub.LEN = Pub.HEADER.length + Pub.TRAILER.length + kbnacl.sign.publicKeyLength;

    function Pub(key) {
      this.key = key;
    }

    Pub.alloc_kb = function (kid) {
      var err, key;
      err = key = null;
      err = kid.length !== Pub.LEN ? new Error("bad key length") : !bufeq_fast(kid.slice(-1), Pub.TRAILER) ? new Error("bad trailing byte") : !bufeq_fast(kid.slice(0, 2), Pub.HEADER) ? new Error("bad header") : (key = new Pub(kid.slice(2, -1)), null);
      return [err, key];
    };

    Pub.prototype.serialize = function () {
      return this.key;
    };

    Pub.prototype.nbits = function () {
      return 255;
    };

    Pub.prototype.read_params = function (sb) {};

    Pub.prototype.verify = function (_arg, cb) {
      var detached, err, naclw, payload, prefix, sig, _ref1;

      payload = _arg.payload, sig = _arg.sig, detached = _arg.detached, prefix = _arg.prefix;
      naclw = kbnacl.alloc({
        publicKey: this.key
      });
      payload = prefix_signature_payload(prefix, payload);
      _ref1 = naclw.verify({
        payload: payload,
        sig: sig,
        detached: detached
      }), err = _ref1[0], payload = _ref1[1];
      return cb(err, payload);
    };

    return Pub;
  }();

  Priv = function () {
    function Priv(key) {
      this.key = key;
    }

    Priv.prototype.alloc = function (raw) {
      var err, key;
      err = key = null;

      if (raw.length !== sign.secretKeyLength) {
        err = new Error("Bad secret key length");
      } else {
        key = new Priv(raw);
      }

      return [err, key];
    };

    Priv.prototype.sign = function (_arg, cb) {
      var detached, naclw, payload, sig;
      payload = _arg.payload, detached = _arg.detached;
      naclw = kbnacl.alloc({
        secretKey: this.key
      });
      sig = naclw.sign({
        payload: payload,
        detached: detached
      });
      return cb(sig);
    };

    return Priv;
  }();

  Pair = function (_super) {
    __extends(Pair, _super);

    Pair.Pub = Pub;
    Pair.prototype.Pub = Pub;
    Pair.Priv = Priv;
    Pair.prototype.Priv = Priv;
    Pair.type = K.public_key_algorithms.NACL_EDDSA;
    Pair.prototype.type = Pair.type;

    Pair.prototype.get_type = function () {
      return this.type;
    };

    Pair.klass_name = "EDDSA";

    function Pair(_arg) {
      var priv, pub;
      pub = _arg.pub, priv = _arg.priv;

      Pair.__super__.constructor.call(this, {
        pub: pub,
        priv: priv
      });
    }

    Pair.prototype.can_encrypt = function () {
      return false;
    };

    Pair.prototype.hash = function () {
      return this.serialize();
    };

    Pair.prototype.sign_kb = function (_arg, cb) {
      var detached, err, payload, prefix, sig, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      payload = _arg.payload, detached = _arg.detached, prefix = _arg.prefix;
      err = sig = null;
      (function (_this) {
        return function (__iced_k) {
          if (_this.priv != null) {
            payload = prefix_signature_payload(prefix, payload);

            (function (__iced_k) {
              __iced_deferrals = new iced.Deferrals(__iced_k, {
                parent: ___iced_passed_deferral,
                filename: "/Users/max/src/keybase/kbpgp/src/nacl/eddsa.iced",
                funcname: "Pair.sign_kb"
              });

              _this.priv.sign({
                payload: payload,
                detached: detached
              }, __iced_deferrals.defer({
                assign_fn: function () {
                  return function () {
                    return sig = arguments[0];
                  };
                }(),
                lineno: 110
              }));

              __iced_deferrals._fulfill();
            })(__iced_k);
          } else {
            return __iced_k(err = new Error("no secret key available"));
          }
        };
      })(this)(function (_this) {
        return function () {
          return cb(err, sig);
        };
      }(this));
    };

    Pair.prototype.verify_kb = function (_arg, cb) {
      var detached, payload, prefix, sig;
      payload = _arg.payload, sig = _arg.sig, detached = _arg.detached, prefix = _arg.prefix;
      return this.pub.verify({
        payload: payload,
        sig: sig,
        detached: detached,
        prefix: prefix
      }, cb);
    };

    Pair.subkey_algo = function (flags) {
      if (flags & (C.key_flags.certify_keys | C.key_flags.sign_data)) {
        return Pair;
      } else {
        return NaclDh;
      }
    };

    Pair.prototype.fulfills_flags = function (flags) {
      var good_for;
      good_for = C.key_flags.certify_keys | C.key_flags.sign_data;
      return (flags & good_for) === flags;
    };

    Pair.prototype.verify_unpad_and_check_hash = function (_arg, cb) {
      var data, hash, hasher, sig;
      sig = _arg.sig, data = _arg.data, hasher = _arg.hasher, hash = _arg.hash;
      return cb(new Error("verify_unpad_and_check_hash unsupported"));
    };

    Pair.prototype.pad_and_sign = function (data, _arg, cb) {
      var hasher;
      hasher = _arg.hasher;
      return cb(new Error("pad_and_sign unsupported"));
    };

    Pair.parse_kb = function (pub_raw) {
      return BaseKeyPair.parse_kb(Pair, pub_raw);
    };

    Pair.parse_sig = function (slice) {
      var err;
      err = new Error("@parse_sig unsupported");
      throw err;
    };

    Pair.read_sig_from_buf = function (buf) {
      var err;
      err = new Error("@read_sig_from_buf unsupported");
      return [err];
    };

    Pair.import_private = function (_arg, cb) {
      var a, b, err, priv, pub, raw, ret;
      raw = _arg.raw;

      if ((a = raw.length) !== (b = kbnacl.sign.secretKeyLength)) {
        err = new Error("Bad length: expected " + b + "} bytes, but got " + a);
      } else {
        pub = new Pub(raw.slice(-kbnacl.sign.publicKeyLength));
        priv = new Priv(raw);
        ret = new Pair({
          priv: priv,
          pub: pub
        });
      }

      return cb(err, ret);
    };

    Pair.generate = function (_arg, cb) {
      var arg, err, naclw, priv, pub, publicKey, ret, secretKey, seed, server_half, split, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      seed = _arg.seed, split = _arg.split, server_half = _arg.server_half;
      arg = {
        seed: seed,
        split: split,
        len: kbnacl.sign.seedLength,
        server_half: server_half
      };
      (function (_this) {
        return function (__iced_k) {
          __iced_deferrals = new iced.Deferrals(__iced_k, {
            parent: ___iced_passed_deferral,
            filename: "/Users/max/src/keybase/kbpgp/src/nacl/eddsa.iced",
            funcname: "Pair.generate"
          });
          genseed(arg, __iced_deferrals.defer({
            assign_fn: function () {
              return function () {
                err = arguments[0];
                server_half = arguments[1].server_half;
                return seed = arguments[1].seed;
              };
            }(),
            lineno: 187
          }));

          __iced_deferrals._fulfill();
        };
      })(this)(function (_this) {
        return function () {
          var _ref1;

          ret = null;

          if (typeof err === "undefined" || err === null) {
            naclw = kbnacl.alloc({});
            _ref1 = naclw.genFromSeed({
              seed: seed
            }), secretKey = _ref1.secretKey, publicKey = _ref1.publicKey;
            pub = new Pub(publicKey);
            priv = new Priv(secretKey);
            ret = new Pair({
              pub: pub,
              priv: priv
            });
          }

          return cb(err, ret, server_half);
        };
      }(this));
    };

    return Pair;
  }(BaseKeyPair);

  exports.EdDSA = exports.Pair = Pair;
}).call(this);