import { Grid, Radio } from '@material-ui/core';
import * as React from 'react';
import {Component} from 'react';
import { connect } from 'react-redux';
import { LANG_CODE_EN, LANG_CODE_ZH_CN, loadExternContatnsIfNecessary } from '../../external-constants/extern-constants';
import { t } from '../../i18n';
import { WalletAppState } from '../../redux-store';
import { renderInitUnishellPage, renderInitErrorUnishellPage, renderUnishellPage, BaseUnishellPageState } from '../../unishell-page-common/unishell-page-common';
import { NoticeDialog } from '../../view/NoticeDialog';
import { CurrencyOption } from '../ajax-data/ajax-data-types';
import { getCurrencyOptionsMap, saveShoppingCurrency } from '../ajax-data/shopping-server-api';
import { doAJAXCall } from '../common/ajax-call';
import { BaseShoppingPageProps, } from '../common/shopping-page-common';
import {ReactComponent as UnisCurrency} from '../../images/u-logo-black.svg';

import './switch-currency.scss';
import { setCurrencyRecord } from '../../redux-store/currency';
import { store } from '../..';
import { calcRationClass, RatioXXS } from '../../util/Util';
import { SimpleNote } from '../../widget/note/note';

interface MyProps extends BaseShoppingPageProps{
    currencyCode: string;
}
interface MyState extends BaseUnishellPageState {
    currencyOptions?: CurrencyOption[];    
    currencyCode: string;
}

class SwitchCurrencyView extends Component<MyProps,MyState>{
    private errorDialog:NoticeDialog|undefined;

    constructor(props: MyProps){
        super(props);
        this.state = {pageInitState: 'PAGE_INIT',             
                      currencyCode: this.props.currencyCode }
    }

    getTitle(){
        return t('计价货币');
    }

    render(){
        if(this.state.pageInitState === 'PAGE_INIT'){
            return <React.Fragment>
                        {renderInitUnishellPage(
                            {
                                title: this.getTitle(), 
                                className:'shopping-switch-currency',
                                message:t('初始化...')
                            })
                        }
                        <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                    </React.Fragment>
        }
        if(this.state.pageInitState === 'PAGE_LOAD_FAILED'){
            return <React.Fragment>
                        {renderInitErrorUnishellPage(
                            {
                                title: this.getTitle(), 
                                className:'shopping-switch-currency',
                                errorMessage: this.state.initErrorMessage||t('初始化失败'),
                            })
                        }
                        <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                    </React.Fragment>
        }

        
        return renderUnishellPage({
            title: this.getTitle(), 
            className:'shopping-switch-currency',
            content:(
                <React.Fragment>
                    { this.renderSucceedContent() }
                    <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                </React.Fragment>
            )
        }) 

    }

    async switchCurrency(newCode:string){
        await doAJAXCall({
            whatIsFor: t('切换计价货币'),
            // @ts-ignore
            errorDialog: window.errorDialog,
            ajaxCall: async (accessToken)=>{
                await saveShoppingCurrency(accessToken, newCode);
                this.setState({currencyCode: newCode}, ()=>{
                    store.dispatch(setCurrencyRecord(newCode));
                });
            },
            onError: (errorMessage)=>{
                this.forceUpdate(); // refresh currency radio buttons
            }
        })
    }

    renderSucceedContent(){
        const {ratioClass, vw,  mw, ratio} = calcRationClass();
        let xs = this.props.languageCode === LANG_CODE_EN ? 12 : 6;
        if(ratio < RatioXXS){
            xs = 12;
        }
        return (
            <div>
                <SimpleNote noticeType='info'
                            message={t('币种只用于显示商品价格。')}
                />
                <h3 className='currency-header'>{t('价格币种')}  {this.getCurrencyLabel()}</h3>
                <Grid container spacing={0}>
                {
                    this.state.currencyOptions!.map(x=>{
                        let symbol = x.code === 'UNIS' ? <UnisCurrency className='unis-currency-symbol'/> : x.symbol;

                        return <Grid item xs={xs as 6} key={x.code}>
                                <div className= {x.code+'-currency-grid-item'} >
                                    <Radio checked={x.code === this.state.currencyCode}
                                            value={x.code}
                                            onChange={evt=>{ this.switchCurrency(evt.currentTarget.value) }}
                                    />
                                    <label>{symbol}</label> &nbsp; <label>{x.label}</label>
                                </div>
                                </Grid>
                    })
                }    
                </Grid>
            </div>
        )
    }

    getCurrencyLabel(){
        let currencyOptions = this.state.currencyOptions!;
        let currency = currencyOptions.find(x=> x.code === this.state.currencyCode);
        if(!currency){
            // currency = {code:'CAD', symbol:'$', label:'加元'};
            currency = {code:'CNY', symbol:'￥', label:'人民币'};
        }
        return currency.label;
    }



    componentDidMount(){
        doAJAXCall({
            whatIsFor: t('初始化'),
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken)=>{
                await loadExternContatnsIfNecessary();
                let currencyOptionsMap = getCurrencyOptionsMap();
                let currencyOptions = currencyOptionsMap[this.props.languageCode!] || currencyOptionsMap[LANG_CODE_ZH_CN];
                this.setState({pageInitState: 'PAGE_LOAD_SUCCESS', currencyOptions})
            },
            onError: (errorMsg)=>{
                this.setState({pageInitState:'PAGE_LOAD_FAILED', initErrorMessage: errorMsg});
            }
        })
    }
}

export const SwitchCurrency = connect(
    (state:WalletAppState)=>({languageCode:state.language.langCode, 
                              currencyCode: state.currency.currencyCode})
)(SwitchCurrencyView)