// Generated by IcedCoffeeScript 108.0.11
(function () {
  var C, WordArray, algos, alloc, alloc_or_throw, decorate, k, make_hasher, make_streamer, streamers, triplesec, v, _lookup, _ref;

  C = require('./const').openpgp;
  triplesec = require('triplesec');
  WordArray = triplesec.WordArray;
  algos = triplesec.hash;

  decorate = function decorate(f, klass, name, type) {
    f.type = type;
    f.algname = name;
    f.output_length = klass.output_size;
    f.klass = klass;
    return f;
  };

  make_hasher = function make_hasher(klass, name, type) {
    var f;

    if (klass != null) {
      f = function f(x) {
        return new klass().bufhash(x);
      };

      return decorate(f, klass, name, type);
    } else {
      return null;
    }
  };

  make_streamer = function make_streamer(klass, name, type) {
    return function () {
      var obj, ret;
      obj = new klass();

      ret = function ret(buf) {
        return obj.clone().finalize(buf != null ? WordArray.from_buffer(buf) : null).to_buffer();
      };

      ret.update = function (buf) {
        if (buf != null) {
          obj.update(WordArray.from_buffer(buf));
        }

        return this;
      };

      return decorate(ret, klass, name, type);
    };
  };

  _lookup = {};
  exports.streamers = streamers = {};
  _ref = C.hash_algorithms;

  for (k in _ref) {
    v = _ref[k];
    _lookup[v] = k;
    exports[k] = make_hasher(algos[k], k, v);
    streamers[k] = make_streamer(algos[k], k, v);
  }

  exports.alloc = alloc = function alloc(typ) {
    var klass, name, ret;
    ret = null;
    name = _lookup[typ];

    if (name != null) {
      klass = algos[name];
    }

    if (klass != null) {
      ret = make_hasher(klass, name, typ);
    }

    return ret;
  };

  exports.alloc_or_throw = alloc_or_throw = function alloc_or_throw(typ) {
    var ret;
    ret = alloc(typ);

    if (!ret) {
      throw new Error("unknown hash type: " + typ);
    }

    return ret;
  };
}).call(this);