import * as React from 'react';

import { getCordova, info, openUrl } from "../../globals";
import { ConfirmDialogView } from "../confirm-dialog/ConfirmDialogView";
import { t } from "../../i18n";

/**
 * open dialog ask user to confirm open url using browser
 * @param link 
 * @param openUrlDialog 
 */
export function confirmOpenURL(link:string, openUrlDialog: ConfirmDialogView): void {
    info('open '+link);
    if (getCordova()){
        openUrlDialog!.show({
            title: t('离开Unishell应用'), 
            message: <div className='open-url-message'>{t('跳转到')+' '+link}<br/><br/>{t('您可以关闭浏览器后返回Unishell应用')}</div>, 
            handleClose:    (ok:boolean, clientData?: any)=>{
                                if(ok){
                                    openUrl(link);
                                }
                            }})                         
    }else{
        openUrlDialog!.show({
            title: t('离开Unishell应用'), 
            message: <div className='open-url-message'>{t('跳转到')+' '+link}</div>, 
            handleClose:    (ok:boolean, clientData?: any)=>{
                                if(ok){
                                    openUrl(link);
                                }
                            }})                         
    }
}

export function SimpleURL(args:{url: string, label?: string}):JSX.Element {
    return <a   href={args.url} 
                onClick={evt=>{
                            evt.stopPropagation();
                            evt.preventDefault();
                            // @ts-ignore
                            if(window.openUrlDialog){
                                // @ts-ignore
                                confirmOpenURL(args.url, window.openUrlDialog)
                            }else{
                                openUrl(args.url);
                            }
                        }}>
            {args.label || args.url}
           </a>;
}