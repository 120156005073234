import { AppError } from "../../api/common";
import { DocSearchRequest } from "../../api/docs";
import { SnappayMethod } from "../../api/purchase-points";
import { t } from "../../i18n";
import { getServerURL } from "../../util/Util";
import { UploadedImage } from "../../widget/image-uploader/image-uploader";

export interface ShopUserInfo {
    username:string;
    name:string;
    /** backend set if it is null first time */
    currencyCode:string;
    /** username of nitritionist */ 
    nutritionist?:string;
    /** timestamp when the user chose the nutritionist */
    nutritionistLinkTime?: number;

    comment?: string;

    shoppingCartItemCount: number,
    prescriptionCount: number
}

export interface ProductCategory {
    /** used as id. should be unique. used in ajax call */
    name:string;
    /** language specific label */
    label:string;
    /** is not leaf if has at least one children */
    children?: ProductCategory[];
}

export type ProductType = 'test'|'chanpin';

/**
 * product brief used in search product list
 */
export interface ProductCandidateBrief{
    thumbImage:string;
    productCode:string;
    /** language specific product name */
    productName:string;
    /** optional */
    originalPrice?:number;
    price:number;
    currencyCode:string;

    /** 应用范围 */
    application?: string;
    /** 产品特点 */
    feature?: string;
    
    productType: ProductType;
    
    /** used in shopping cart */
    count: number;
}

export interface CurrencyOption {
    code: string;
    symbol: string;
    label: string;
}

export interface PaymentMethodOption {
    code: PaymentMethod;
    label: string;
}

export interface WeightOption {
    code: string;
    label: string;
}


export type DisplayOption = 'is_warning'|'mark_terms'|'tap_to_enlarge';


export interface SimpleShoppingProductField {
    /** drupal field name */
    name: string;
    label: string;
    value: string|number|boolean|null;
    /** additional value display requests. for example, 'is_warning', 'mark_terms' */
    display?: DisplayOption[];
}
export interface ShoppingProductFieldGroup {
    /** group name */
    name: string;
    /** group name */
    label: string;
    fieldList: SimpleShoppingProductField[] 
}
export interface ShoppingProductTableData {
    /** table name */
    name: string;
    /** talbe label */
    label: string;
    tableData: SimpleShoppingProductField[][];
}

/**
 * scalar (e.g 风险提示) or field list (基本信息), table (功效成份). For example, 
 */
export type ShoppingProductDetailTab = SimpleShoppingProductField | ShoppingProductFieldGroup | ShoppingProductTableData;

export function isSimpleShoppingProductField(tab:ShoppingProductDetailTab){
    return !!(tab as SimpleShoppingProductField).value;
}
export function isShoppingProductFieldGroup(tab:ShoppingProductDetailTab){
    return !!(tab as ShoppingProductFieldGroup).fieldList;
}
export function isShoppingProductTableData(tab:ShoppingProductDetailTab){
    return !!(tab as ShoppingProductTableData).tableData;
}
export function isWarningTab(tab:ShoppingProductDetailTab){
    return !!(tab as SimpleShoppingProductField).display && (tab as SimpleShoppingProductField).display!.some(x=> x === 'is_warning');
}
export function isMarkTermTab(tab:ShoppingProductDetailTab){
    return !!(tab as SimpleShoppingProductField).display && (tab as SimpleShoppingProductField).display!.some(x=> x === 'mark_terms');
}
export function isTapToEnlargeTab(tab:ShoppingProductDetailTab){
    return !!(tab as SimpleShoppingProductField).display && (tab as SimpleShoppingProductField).display!.some(x=> x === 'tap_to_enlarge');
}

export interface ShoppingProductIngredient{
    /** Latin or English name */
    name:string;
    /** Chinese translation */
    cName: string; 
    /** health advice name */
    cpName: string; 
    amount: string;
}

export interface ShoppingProductDetail {
    /** full sized image */
    productImageList: string[];
    /** name in user's language */
    productName: string;
    price: number;
    originalPrice?: number;
    currencyCode: string;
    documentId: string;
    productCode:string;
    productType: 'test' | 'chanpin';

    /**
     * headers are displayed beside product image.
     * example headers are 原产地, 特殊膳食, 批准机构, 编号
     */
    headers: SimpleShoppingProductField[];    

    /** the message in user's language if the product needs 健康建议书 */
    requireHealthAdviceMessage?: string;

    tabs: ShoppingProductDetailTab[];

    ingredients:ShoppingProductIngredient[];
}

export function appendIngredientTabData(product:ShoppingProductDetail, isNutritionist:boolean){
    if(product.tabs.some(x=> x.name === 'field_gongxiaochengfen')) return;

    let gongxiaochengfenData = (product.ingredients||[])
                                .map(x=> {
                                    return isNutritionist?
                                    [ 
                                        {name:'yanliaomingchen', label:t('原料名称'), value:x.cName},
                                        {name:'yanliao_latin_mingchen', label:t('拉丁文名称'), value:x.name},
                                        {name:'field_jianyishuchengfen', label:t('建议书成分'), value:x.cpName},
                                        {name:'yanliaohangliang', label:t('原料含量'), value:x.amount},
                                    ]:
                                    [ 
                                        {name:'yanliao_latin_mingchen', label:t('拉丁文名称'), value:x.name},
                                        {name:'yanliaohangliang', label:t('原料含量'), value:x.amount},
                                    ]
                                });
    // convert and add ingredient tableData to be displayed in product details                                                                                                                  
    if(gongxiaochengfenData.length>0){
        // testing product does not have ingredients
        product.tabs.push({name:'field_gongxiaochengfen', 
        label:'功效成份', 
        tableData:gongxiaochengfenData})    
    }
}

export function getProductCode(product:ShoppingProductDetail){
    return product.productCode;
}

export interface AddressInfo {
    addressId:string;
    /** ISO alpha-3 country or region code. see https://www.iso.org/obp/ui/#search */
    countryOrRegion: string;
    institution?: string;
    postalCode?: string;

    // addressN is interpreted differently depends on countryOrRegion.
    address1: string;
    address2: string;
    address3: string;
    address4: string;
}

export function AddressInfo2StrArray(addressInfo: AddressInfo):string[] {
    if(!addressInfo) return [];
    
    let list: string[] = [];

    if(addressInfo.institution){
        list.push(addressInfo.institution);
    }
    if(addressInfo.address1){
        list.push(addressInfo.address1);
    }
    if(addressInfo.address2){
        list.push(addressInfo.address2);
    }

    let line = '';
    if(addressInfo.address3){
        line = (addressInfo.address3);
    }
    if(addressInfo.address4){
        line += (' '+addressInfo.address4);
    }
    list.push(line);

    line = t(addressInfo.countryOrRegion, 'iso_country');
    if(addressInfo.postalCode){
        line += (' '+addressInfo.postalCode);
    }
    list.push(line);

    return list;
}

/**
 * convert AddressInfo to string representation.
 * 
 * @param addressInfo 
 */
export function AddressInfo2Str(addressInfo: AddressInfo):string {
    if(!addressInfo) return '';
    
    let list: string[] = [];

    if(addressInfo.institution){
        list.push(addressInfo.institution);
    }
    if(addressInfo.address1){
        if(list.length > 0){ list.push(', '); }
        list.push(addressInfo.address1);
    }
    if(addressInfo.address2){
        if(list.length > 0){ list.push(', '); }
        list.push(addressInfo.address2);
    }
    if(addressInfo.address3){
        if(list.length > 0){ list.push(', '); }
        list.push(addressInfo.address3);
    }
    if(addressInfo.address4){
        if(list.length > 0){ list.push(', '); }
        list.push(addressInfo.address4);
    }

    list.push(' ');
    list.push(t(addressInfo.countryOrRegion, 'iso_country'));

    if(addressInfo.postalCode){
        list.push(' ');
        list.push(addressInfo.postalCode);
    }

    return list.join('');
}

export interface NutritionistServiceTimeInfo {
    id?: number;
    timeZone?: number;
    from: number;
    to: number;
    /**  sun, 1 mon, 2 tue.. 6 sat */
    dayOfWeek?: number[];
    online?: boolean;
    inOffice?: boolean;
    notes?: string;
}

export interface NutritionistTestimonial {
    clientName: string;
    clientInfo?: string;
    testimonial: string;
}

export interface NutritionistBrief {
    /** PK */
    username: string;
    /** full legal name */
    name: string;
    /** avatar image url */
    avatar?:PhotoInfo;

    officePhone:string;
    officeEmail: string;

    businessAddress: AddressInfo;
    
    gender?: 'male'|'female';
    
    language?: string[];

    expertIn?: string;
    /** 头衔 职称 证书 学历 等 */
    titles?: string[];

    status?:'active' | 'inactive'; // TODO: ?
}

export interface PhotoInfo {
    photoId: string,
    url: string;
    tag?: string;
}

export interface NutritionistLicenceInfo {
    /** unishell system user name/id */
    nutritionistUsername: string, 
    /** legal name */
    name: string;
    category:string;
    employer:string;
    regNumber:string;
    insurancePolicyNumber: string;
    /** system time milliseconds */
    expiration:number,
    licenceImage?: PhotoInfo;
}

export interface NutritionistLicenceFormData extends NutritionistLicenceInfo{
    uploadLicenceImgFile?:File;
    rotate?: number;
    hasError?: boolean;
}

export function toImgSrc(urlOrBase64Img: string):string{
    if(!urlOrBase64Img) return '';
    if(urlOrBase64Img.startsWith('data:image/')){
        return urlOrBase64Img;
    }
    if(urlOrBase64Img.startsWith('http')){
        return urlOrBase64Img
    }
    
    if(urlOrBase64Img.startsWith('/server/')){
        return getServerURL()+urlOrBase64Img;
    }

    return 'data:image/png;base64,'+urlOrBase64Img;
}

export interface QRCodeResponse {
    code:string;
    base64Image: string;
}

export interface NutritionistInfo extends NutritionistBrief{
    serviceTime?: NutritionistServiceTimeInfo[];

    website?: string;
    websiteName?: string;

    wechatId?: string;
    wechatQRCode?: PhotoInfo;
    wechatPublicId?: string;
    wechatPublicQRCode?: PhotoInfo;

    socialMediaLinks?: string[];

    testimonial?: NutritionistTestimonial[];

    /** in markdown format */
    resume?: string;

    qrCode: QRCodeResponse;
    license?: NutritionistLicenceInfo;
    // otherCertificates?: {description:string, image:string}[]
}

export interface NutritionistFormData extends NutritionistInfo{
    uploadedAvatar?: UploadedImage;
}

export interface NutritionistClient {
    clientUsername: string;
    active: boolean;
    /** client name */
    client: string;
    email: string;
    phoneNumber: string;
    address: AddressInfo;
    gender?: string;
    notes?: string;
    /** when the user became my client */
    time: number;
}

export type HealthAdviceStatus = 'READY'|'IN_CART'|'VOIDED'|'EXPIRED'|'USED';



export interface HealthAdviceItemInfo {
    productCode:string,
    productName:string,
    productType: 'test' | 'chanpin',
    /** number of products */
    count: number;
    keyIngredient: ShoppingProductIngredient[];
}

export interface HealthAdvice {
    /** 编号 */
    prescriptionCode?: string;
    /** 标题 */
    title: string;
    description: string;
    /** expire timestamp */
    expiration: number;

    createdDtm: number;
    modifiedTime: number;
    
    state: HealthAdviceStatus;

    orderId?: string;
    orderStatus?: string;

    item: HealthAdviceItemInfo[];

    nutritionistUsername: string;
    clientUsername: string;
}

export function getHealthAdviceProductType(healthAdvice: HealthAdvice):ProductType{
    let productType: ProductType = 'chanpin';

    for(let item of healthAdvice.item){
        if(item.productType === 'test'){
            productType = 'test';
            break;    
        }else{
            productType = 'chanpin';
            break;
        }
}    
    return productType;
}


/**
 * mapped to Java PrescriptionInfo
 */
export interface NutritionistHealthAdvice extends HealthAdvice{
    client: NutritionistClient;
}
/**
 * mapped to Java PrescriptionInfo
 */
export interface ClientHealthAdvice extends HealthAdvice{
    nutritionistUsername: string;
    nutritionist:NutritionistBrief            
}

/**
 * Single shopping cart item.
 * 
 * Java: public class ShoppingCartItemInfo
 */
export interface ShoppingCartItemInfo {
    /** has value if it is not from health advice  */
    productCode?: string;
    /** has value if it is health advice */
    prescriptionCode?: string;
    /** total number of product units */
    count: number;

    product: ProductCandidateBrief[];
}

/**
 * Shopping cart
 * 
 * Java: public class ShoppingCartInfo
 */
export interface ShoppingCartInfo {
    username: string;
    /** total number of product units */
    unitCount: number;
    totalPrice: number;
    /** originalTotalPrice is null, it means originalTotalPrice==totalPrice */
    originalTotalPrice: number;
    currencyCode: string;

    item: ShoppingCartItemInfo[];
}

export function getShoppingCartProductType(shoppingCartRecord: ShoppingCartInfo):ProductType{
    let productType: ProductType = 'chanpin';

    for(let item of shoppingCartRecord.item){
        for(let p of item.product){
            if(p.productType === 'test'){
                productType = 'test';
                break;    
            }else{
                productType = 'chanpin';
                break;
            }
        }
    }    
    return productType;
}



/**
 * shopping cart data types
 * @deprecated
 */
export interface BaseShoppingCartItem {
    type: 'HEALTH_ADVICE'|'PRODUCT';
    toBeCheckedOut: boolean;
}

/**
 * @deprecated
 */
export interface ShoppingCartProduct extends BaseShoppingCartItem{
    type:'PRODUCT';
    productImage: string;
    productCode: string;
    productName: string;
    originalPrice?:number;
    price:number;
    currencyCode:string;
    count: number;
    totalPrice: number;
    weight?: number;
    weightCode?: string;
    totalWeight?: number;
}

/**
 * @deprecated
 */
export interface ShoppingCartHealthAdvice extends BaseShoppingCartItem {
    type: 'HEALTH_ADVICE';
    healthAdviceCode: string;
    products: ShoppingCartProduct[];
}

/**
 * @deprecated
 */
export interface ShoppingOrderSummary {
    totalWeight?: number;
    weightCode?: string;
    subTotal: number;
    currencyCode:string;
    shippingCost?: number;
    discounts?: {
        discount: number;
        discountDescription: string;    
    }[];
    salesTax?: number;
    crossBorderECommerceTax?: number;
    total: number;
}

/**
 * @deprecated
 */
export interface ShoppingCartRecord {
    items: (ShoppingCartProduct|ShoppingCartHealthAdvice)[];
    couponCode?: string;
    useCoupon: boolean;
    
    summary: ShoppingOrderSummary;
}

export type PaymentMethod = 'Wallet'|'WechatPay'|'AliPay'|'CreditCard';
export const PaymentMethodList:PaymentMethod[] = ['Wallet','WechatPay','AliPay','CreditCard']


export interface CheckoutRequest {
    billingAddressId: string;
    /** either pickupAddressId or shippingAddressId exists */
    shippingAddressId?: string;
    /** either pickupAddressId or shippingAddressId exists */
    pickupAddressId?: string;
    paymentMethod: PaymentMethod;
}

export interface DiscountInfo {
    code:string;
    discount:string;
    currencyCode: string;
    amount: number;
}

export interface FeeInfo {
    code:string; 
    name:string;
    currencyCode: string;
    amount: number;
}

export interface ShoppingCheckoutResponse {
    shoppingCart: ShoppingCartInfo;
    discount: DiscountInfo[];
    fee: FeeInfo[];
    finalPrice: number;

    paymentAmount: number;
    paymentCurrencyCode: string;
}

export interface ShopPaymentRequestData extends CheckoutRequest {
    username: string;
    totalAmount: number;
    currencyCode: string;
    timestamp?: number;
    nonce?: string;
}

export interface CreateShopOrderRequest {
    /** user's key hash */
    keyId: string;
    /** encypted shop payment data */
    data: string;
}

export interface CreateShopOrderResponse {
    orderCode: string;
    paymentMethod: PaymentMethod;
    amount: string;
    currency: string;
    orderStatus: string;
    notes: string[];

    /** optional. available for stripe credit card payment */
    stripeClientSecret?: string;
    /** optional. available for wechat pay and Ali pay */
    snappaySDKRequest?: string;
}

/**
 * @deprecated
 */
export interface ShoppingCheckoutOrder extends ShoppingCartRecord {
    billingAddress: BillingAddress;
    shippingMethod: ShippingCourierMethod|ShippingPickupMethod;
    paymentMethod: PaymentMethod;
} 

/*
 * billing address
 */
export interface BillingAddress extends AddressInfo{
    name: string;
    phoneNumber?: string;
    username: string;
}

export interface ShippingAddress extends BillingAddress{
    /**
     * identityPhoto[0]: 身份证正面照 used in Mainland China shipping address
     * identifyPhoto[1]: 身份证反面照  used in Mainland China shipping address   
     */
    identityPhoto?: PhotoInfo[];
}

export interface CountryOption {
    code: string;
    label: string;
}

export interface ShoppingAddressPropSpec {
    label: string;
    notes?: string; // e.g expected values, etc. which is used in validation error.
    placeholder?: string;
    options?: {key:string, label:string}[]; // | ((addr:AddressInfo)=>string[]);

    type?: 'trimed-text'; // default is trimed-text
    
    isMandatory?: boolean;
    regExp?: {pattern: string, flags: string};
}

export interface ShoppingAddressSpec {
    name: ShoppingAddressPropSpec; 
    phoneNumber: ShoppingAddressPropSpec;
    countryOrRegion: ShoppingAddressPropSpec;
    institution: ShoppingAddressPropSpec;
    postalCode: ShoppingAddressPropSpec;

    address1: ShoppingAddressPropSpec;
    address2: ShoppingAddressPropSpec;
    address3: ShoppingAddressPropSpec;
    address4: ShoppingAddressPropSpec;

    IDFrontImage?: ShoppingAddressPropSpec; 
    IDBackImage?: ShoppingAddressPropSpec;    
}

export interface ShippingMethod{
    type: 'courier' | 'pickup';
}

export interface ShippingCourierMethod extends ShippingMethod{
    type: 'courier';
    address: BillingAddress;

    /** used by ui to handle shipping address same as billing address */
    _useAsBillingAddress?: boolean;
}

export interface ShoppingPickupAddress extends AddressInfo {
    /* additional notes **/
    comment: string;
    pharmacyId: string;
    name: string;
    phoneNumber: string;
}

export interface ShippingPickupMethod extends ShippingMethod {
    type: 'pickup';
    address: ShoppingPickupAddress;
}

export interface CheckoutRequestInLocalStorage {
    username?: string;
    billingAddressId?: string;
    shippingMethod?: {
        type: 'courier' | 'pickup',
        addressId: string,
        _useAsBillingAddress?: boolean
    },
    paymentMethod?: PaymentMethod;
}


export type ShopOrderStatus = 'CREATED'|'PAYMENT_PENDING'|'PAYMENT_REJECTED'|'PAID'|'SHIPPED'|'READY_FOR_PICKUP'|'PICKED_UP'|'DELIVERED'|'CANCELLED'|'EXPIRED';

// export type CommissionStatus = 'CREATED'|'PENDING'|'PAID'|'CANCELLED';

export type ShippingRecordStatus = 'Created' | 'Delivered' | 'Voided'

export interface ShippingRecordInfo {
    id: number;
    trackNumber:string;
    orderCode:string;
    logisticCompanyId:string;
    status: ShippingRecordStatus;
    trackingUrl:string;
}
/*
{
    "productType": "test",
    "productCode": "CI 20190003-01",
    "thumbImage": "https://apiserver.unishell.com:8000/server/v1/docs/picture/sites/default/files/%E7%BB%86%E8%83%9E%E7%82%8E%E7%97%87%E6%A3%80%E6%B5%8B%201_0.jpeg?time=1677953705519",
    "productName": "炎症因子检测",
    "price": 599.99,
    "originalPrice": null,
    "currencyCode": "CAD",
    "application": null,
    "feature": null,
    "count": 1
}
{
    "productType": "test",
    "productCode": "CI 20190003-01",
    "thumbImage": "https://apiserver.unishell.com:8000/server/v1/docs/picture/sites/default/files/%E7%BB%86%E8%83%9E%E7%82%8E%E7%97%87%E6%A3%80%E6%B5%8B%201_0.jpeg?time=1677953705519",
    "productName": "炎症因子检测",
    "price": 599.99,
    "originalPrice": null,
    "currencyCode": "CAD",
    "application": null,
    "feature": null,
    "count": 1
}
*/
/** this is partial fields of Java LabRequestInfo class */
export interface LabRequestInfoRec
{
    productInfo: ProductCandidateBrief,
    requestCode: string,
    orderCode: string,
    orderUsername: string,
    status: 'Created' |  'SampleReceived' |  'ReportReceived' | 'SampleRejected',
}


export interface ShopOrderInfo extends ShoppingCheckoutResponse {
    orderCode: string;
    paymentMethod: PaymentMethod;
    status: ShopOrderStatus;
    createDtm: number;
    modifiedDtm: number;
    error: string[];
    notes: string[];
    billingAddressInfo: BillingAddress;
    shippingAddressInfo?: ShippingAddress;
    pickupAddressInfo?: ShoppingPickupAddress;
    /** applies for lab "test" order */
    labRequestInfo?: LabRequestInfoRec[];
    userInfo: {
        name: string;
        email: string;
        phoneNumber:string;
    };
    shippingRecordInfo?: ShippingRecordInfo[];
}

/**
 * @deprecated
 */
export type ShoppingOrderStatus = '创建'|'付费中'|'已付费'|'已发货'|'已取件'|'付费失败'|'取消' | '退货';

/**
 * @deprecated
 */
export interface ShoppingOrderBreif {
    orderId: string;
    createdTimestamp: number;
    modifiedTimestamp: number;
    status: ShoppingOrderStatus; 
    total: number;
    currencyCode: string;
    paymentMethod:PaymentMethod;
    description: string;
}

/**
 * @deprecated
 */
export interface ShoppingOrder extends ShoppingCheckoutOrder {
    orderId: string;
    createdTimestamp: number;
    modifiedTimestamp: number;

    states: {status: ShoppingOrderStatus, timestamp: number, notes?: string}[]
}

export interface ShoppingUnisPayDetail {
    amount:number;
    time: number;
    nonce:string;
}


export interface ShoppingSnappayPrepareRequest {
    amount: number, 
    currencyCode:string, // SnappayPrepareResponse uses 'Unishell'
    method:SnappayMethod
}

export interface ShoppingSnappayPrepareResponse extends AppError {
    orderId:string;
    sdkRequest:string;
    amount:number;
    currencyCode:string;
    method: SnappayMethod;
}

export interface ShoppingSnappayCompleteRequest{
    orderCode: string;
    result: string;
    isSuccess: boolean;
    method: PaymentMethod;
  }

export interface advancedSearchShoppingProductFilter extends DocSearchRequest{
    thumbnailWidth: number;
}

export interface LogisticCompanyInfo {
    code:string;
    name:string;
    webSite:string;
}

export interface PharmacistInfo {
    pharmacyId:string;
    username:string;
    pharmacyInfo: PharmacyInfo;
}

export interface PharmacyInfo {
    name:string;
    phoneNumber:string;
    comment: string;
    addressInfo: AddressInfo;
    status: 'Active'|'Suspended';
    pharmacyId: string;
}


export type ShopOrderCommissionStatus = 'CREATED' | 'PENDING' | 'PAID' | 'CANCELLED' 

export interface ShopOrderCommissionInfoEntry {
    product: ProductCandidateBrief;
    commissionSubTotal: number;
    commission: number;
    status: ShopOrderCommissionStatus;
    referenceId: number;
}


export interface ShopOrderCommissionInfo {
    commissionItem: ShopOrderCommissionInfoEntry[];
    commissionTotal: number;
    orderUserName: string;
    nutritionistName: string;
    nutritionistUsername: string;
    orderCode: string;
    orderCreateDtm: number;
    orderModifiedDtm: number;
    orderStatus: ShopOrderStatus
    commissionStatus: ShopOrderCommissionStatus
}

export interface ShopOrderCommissionDistributionReport {
    timestamp: number;
    latestOrderCreatedDtm: number;
    username: string;
    totalAmount: number;
    orderCount: number;
    commissionCount: number;
    commissions: ShopOrderCommissionInfo[];
}


export interface CommissionDistributionRequest {
    keyId: string;
    data: string;
}
