import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import React, { Component, Fragment } from 'react';
import { t } from '../../i18n';
import { getLanguageCode } from '../../local-storage/local-storage';
import { doAJAXCall } from '../../shopping/common/ajax-call';
import { NoticeDialog } from '../../view/NoticeDialog';
import { UButton } from '../../widget/button/ubutton';

import './html-dialog.scss';

interface Props {
    loadHTMLFn: (langCode: string, version:string)=>Promise<string>;
    title: string;
    errorDialog: NoticeDialog;
}
interface State {
    html?: string;
}

export class HTMLDialog extends Component<Props, State>{
    constructor(props: Props){
        super(props);
        this.state = {}
    }

    render(): React.ReactNode {
        const {title} = this.props;
        const {html} = this.state;

        if(!html){
            return <Fragment>
                        <UButton size='small' variant='outlined' disabled={!!html}
                                 onClick={this.loadHTML}
                        >{title}</UButton>
                  </Fragment> 
        }

        return <Fragment>
                    <UButton size='small' variant='outlined'>{title}</UButton>
                    <Dialog className='html-dialog' maxWidth='xl' onClose={this.handleClose}
                            open={!!html}>
                        <DialogContent>
                            <div dangerouslySetInnerHTML={{__html: html}} />                
                        </DialogContent>
                        <DialogActions>
                            <UButton size='small' variant='outlined' color='default' onClick={this.handleClose}>
                                {t('关闭')}
                            </UButton>
                        </DialogActions>
                    </Dialog>
                </Fragment>
    }

    handleClose = ()=>{
        this.setState({html: undefined})
    }

    loadHTML = () => {
        const {title, errorDialog, loadHTMLFn} = this.props;
        const envNoticeTimeout = process.env.REACT_APP_NOTICE_TIMEOUT || '60'; // seconds
        const timeout = parseInt(envNoticeTimeout)*1000;

        let version = Math.floor((Date.now() / timeout))
        let langCode = getLanguageCode();

        doAJAXCall({
            whatIsFor: t('读取')+title,
            errorDialog,
            ajaxCall: async () => {
                let html = await loadHTMLFn(langCode, version+'');
                this.setState({html});
            },
        })
    }

}