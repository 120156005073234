import * as React from 'react';
import {Component, Fragment} from 'react'
import { CountryOption, NutritionistFormData, NutritionistInfo, BillingAddress, ShoppingAddressSpec, AddressInfo2Str } from '../ajax-data/ajax-data-types';
import './edit-licence.scss';
import classNames from 'classnames';
import * as _ from 'lodash';
import { t } from '../../i18n';
import { NutritionLicenceForm } from '../nutritionist/licence-form';
import { Toast } from '../../view/Toast';
import { amIAdmin, amIUserWithUsername, getLanguageCode } from '../../local-storage/local-storage';
import sha1 from 'sha1';
import { loadExternContatnsIfNecessary } from '../../external-constants/extern-constants';
import { UButton } from '../../widget/button/ubutton';
import { BaseUnishellPageState, renderInitErrorUnishellPage, renderInitUnishellPage, renderUnishellPage } from '../../unishell-page-common/unishell-page-common';
import { NoticeDialog } from '../../view/NoticeDialog';
import { doAJAXCall } from '../common/ajax-call';
import { retrieveNutritionist, saveNutritionistLicence, savePhoto } from '../ajax-data/shopping-server-api';

interface EditLicenceProps {
    nutritionistId: string;
}

interface EditLicenceState extends BaseUnishellPageState{
    nutritionist?: NutritionistInfo;
    errors: {[key:string]:string};
}


export class EditLicence extends React.Component<EditLicenceProps, EditLicenceState>{
    private licenceForm:NutritionLicenceForm|undefined;
    private toast:Toast|undefined;
    private errorDialog:NoticeDialog|undefined;

    constructor(props: EditLicenceProps){
        super(props);
        this.state = {pageInitState:'PAGE_INIT',errors:{}}
    }

    getTitle(): string {
        return t('编辑执照');
    }


    render(){
        if(this.state.pageInitState === 'PAGE_INIT'){
            return <React.Fragment>
                        {renderInitUnishellPage({
                            title: this.getTitle(), 
                            className:'edit-licence-page',
                        })}
                        <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                    </React.Fragment>
        }
        if(this.state.pageInitState === 'PAGE_LOAD_FAILED'){
            return <React.Fragment>
                        {renderInitErrorUnishellPage({title: this.getTitle(),
                                                      className:'edit-licence-page', 
                                                      errorMessage: this.state.initErrorMessage||t('初始化失败'),
                                                      onTryAgain: ()=>{ this.initializeAgain();    }
                                                      })}
                        <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                    </React.Fragment>
        }
        let content = this.renderSucceedContent();
        return (
            <React.Fragment>
                { renderUnishellPage(
                    {
                        title: this.getTitle(), 
                        className:'edit-licence-page', 
                        content
                    }
                  )
                }
                <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                <Toast close={true} message='' toastType='info' onClose={()=>{}} ref={(e)=>{this.toast=e!}} duration={3000}/>
            </React.Fragment>
        )   
    }

    renderSucceedContent():JSX.Element {
        let n = this.state.nutritionist!;
        const languageCode = getLanguageCode();

        return (
            <div className='edit-nutritionist-content'>
                <h3 className='name'>{t('营养师')}: {n.name}</h3>
                <p className='email'>{n.officeEmail}</p>

                <NutritionLicenceForm username={n.username} licence={n.license} 
                                      readonly={false} 
                                      ref={e => { this.licenceForm = e! }}
                                      key={sha1(n.username+JSON.stringify(n.license))}
                                      languageCode={languageCode}
                                      />
                <UButton variant='contained' color='primary' className='save-btn'
                    onClick={()=>{ this.saveLicence() }}
                >{t('保存')}</UButton>                                      
            </div>
        )
    }

    saveLicence(){
        let licenceFormData = this.licenceForm!.validateAndGetLicenceData();
        if(licenceFormData.hasError){
            this.toast!.sendMessage(t('表格中有错误'), 'warning');
            return;
        }

        let title = t('保存营养师执照');
        let succMsgs: string[] = [];

        doAJAXCall({
            whatIsFor: t('保存营养师执照'),
            whatIsForFn: ()=> { return title},
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken, username)=>{ 
                if(licenceFormData.uploadLicenceImgFile){
                    title = t('保存营养师执照图像');
                    let rotate = licenceFormData.rotate;
                    let photoInfo = await savePhoto(accessToken, licenceFormData.uploadLicenceImgFile, 'photo',
                                                    JSON.stringify({t:'licence',username, rotate}));
                    licenceFormData.licenceImage = photoInfo;
                    succMsgs.push(title);
                }
                
                title = t('保存营养师执照');
                let licence = await saveNutritionistLicence(accessToken, licenceFormData);
                licenceFormData = licence;
                succMsgs.push(title);

                title = t('营养师信息');
                let nutritionist = await retrieveNutritionist(accessToken, this.props.nutritionistId);
                this.setState({nutritionist}, ()=>{
                    this.toast!.sendMessage(succMsgs.join('. '), 'success');
                });
            }
        })
    }

    initializeAgain(){
        this.setState({pageInitState:'PAGE_INIT', initErrorMessage:undefined},
                      ()=>{
                          this.loadData();
                      })
    }

    componentDidMount(){
        const isAdmin = amIAdmin();
        if(!isAdmin){
            this.setState({pageInitState:'PAGE_LOAD_FAILED', initErrorMessage: t('只有管理员可编辑执照')})
        }else{
            this.loadData();
        }
    }

    loadData(){
        doAJAXCall({
            whatIsFor: t('营养师信息'),
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken, username)=>{ 
                                    await loadExternContatnsIfNecessary();
                                    let nutritionist = await retrieveNutritionist(accessToken, this.props.nutritionistId);
                                    this.setState({nutritionist,pageInitState:'PAGE_LOAD_SUCCESS'});
                                },
            onError: (errorMessage:string)=>{ this.setState({pageInitState:'PAGE_LOAD_FAILED', initErrorMessage:errorMessage})}                                           
        })        
    }

}