// Generated by IcedCoffeeScript 108.0.11
(function () {
  var C,
      K,
      Packet,
      Signature,
      eddsa,
      iced,
      konst,
      make_esc,
      __iced_k,
      __iced_k_noop,
      __hasProp = {}.hasOwnProperty,
      __extends = function __extends(child, parent) {
    for (var key in parent) {
      if (__hasProp.call(parent, key)) child[key] = parent[key];
    }

    function ctor() {
      this.constructor = child;
    }

    ctor.prototype = parent.prototype;
    child.prototype = new ctor();
    child.__super__ = parent.prototype;
    return child;
  };

  iced = require('iced-runtime');

  __iced_k = __iced_k_noop = function __iced_k_noop() {};

  konst = require('../../const');
  K = konst.kb;
  C = konst.openpgp;
  Packet = require('./base').Packet;
  make_esc = require('iced-error').make_esc;
  eddsa = require('../../nacl/main').eddsa;

  Signature = function (_super) {
    __extends(Signature, _super);

    Signature.SIG_TYPE = K.public_key_algorithms.NACL_EDDSA;
    Signature.HASH_TYPE = C.hash_algorithms.SHA512;

    Signature.tag = function () {
      return K.packet_tags.signature;
    };

    Signature.prototype.tag = function () {
      return Signature.tag();
    };

    function Signature(_arg) {
      var prefix;
      this.key = _arg.key, this.payload = _arg.payload, this.sig = _arg.sig, this.detached = _arg.detached, prefix = _arg.prefix;

      if (prefix != null) {
        this.prefix = prefix;
      }

      Signature.__super__.constructor.call(this);
    }

    Signature.prototype.get_packet_body = function () {
      var hash_type, ret, sig_type;
      sig_type = Signature.SIG_TYPE;
      hash_type = Signature.HASH_TYPE;
      ret = {
        key: this.key,
        payload: this.payload,
        sig: this.sig,
        detached: this.detached,
        sig_type: sig_type,
        hash_type: hash_type
      };

      if (this.prefix != null) {
        ret.prefix = this.prefix;
      }

      return ret;
    };

    Signature.alloc = function (_arg) {
      var a, b, body, err, ret, tag;
      tag = _arg.tag, body = _arg.body;
      ret = null;
      err = tag !== Signature.tag() ? new Error("wrong tag found: " + tag) : (a = body.hash_type) !== (b = Signature.HASH_TYPE) ? new Error("Expected SHA512 (type " + b + "); got " + a) : (a = body.sig_type) !== (b = Signature.SIG_TYPE) ? err = new Error("Expected EDDSA (type " + b + "); got " + a) : (ret = new Signature(body), null);

      if (err != null) {
        throw err;
      }

      return ret;
    };

    Signature.prototype.is_signature = function () {
      return true;
    };

    Signature.prototype.verify = function (cb) {
      var err, esc, km, pair, ___iced_passed_deferral, __iced_deferrals, __iced_k, _ref;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      esc = make_esc(cb, "verify");
      err = km = null;
      _ref = eddsa.Pair.parse_kb(this.key), err = _ref[0], pair = _ref[1];
      (function (_this) {
        return function (__iced_k) {
          if (err == null) {
            (function (__iced_k) {
              __iced_deferrals = new iced.Deferrals(__iced_k, {
                parent: ___iced_passed_deferral,
                filename: "/Users/max/src/keybase/kbpgp/src/keybase/packet/signature.iced",
                funcname: "Signature.verify"
              });
              pair.verify_kb(_this, esc(__iced_deferrals.defer({
                lineno: 61
              })));

              __iced_deferrals._fulfill();
            })(__iced_k);
          } else {
            return __iced_k();
          }
        };
      })(this)(function (_this) {
        return function () {
          return cb(err, {
            keypair: pair,
            payload: _this.payload
          });
        };
      }(this));
    };

    Signature.prototype.unbox = function (params, cb) {
      var err, res, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      (function (_this) {
        return function (__iced_k) {
          __iced_deferrals = new iced.Deferrals(__iced_k, {
            parent: ___iced_passed_deferral,
            filename: "/Users/max/src/keybase/kbpgp/src/keybase/packet/signature.iced",
            funcname: "Signature.unbox"
          });

          _this.verify(__iced_deferrals.defer({
            assign_fn: function () {
              return function () {
                err = arguments[0];
                return res = arguments[1];
              };
            }(),
            lineno: 67
          }));

          __iced_deferrals._fulfill();
        };
      })(this)(function (_this) {
        return function () {
          return cb(err, res);
        };
      }(this));
    };

    Signature.box = function (_arg, cb) {
      var detached, esc, km, packet, pair, payload, prefix, sig, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      km = _arg.km, prefix = _arg.prefix, payload = _arg.payload;
      esc = make_esc(cb, "@sign");
      pair = km.get_keypair();
      detached = true;
      (function (_this) {
        return function (__iced_k) {
          __iced_deferrals = new iced.Deferrals(__iced_k, {
            parent: ___iced_passed_deferral,
            filename: "/Users/max/src/keybase/kbpgp/src/keybase/packet/signature.iced",
            funcname: "Signature.box"
          });
          pair.sign_kb({
            payload: payload,
            prefix: prefix,
            detached: detached
          }, esc(__iced_deferrals.defer({
            assign_fn: function () {
              return function () {
                return sig = arguments[0];
              };
            }(),
            lineno: 76
          })));

          __iced_deferrals._fulfill();
        };
      })(this)(function (_this) {
        return function () {
          packet = new Signature({
            key: pair.ekid(),
            payload: payload,
            sig: sig,
            detached: detached,
            prefix: prefix
          });
          return cb(null, packet);
        };
      }(this));
    };

    return Signature;
  }(Packet);

  exports.Signature = Signature;
  exports.sign = Signature.sign;
}).call(this);