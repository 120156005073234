import { TextField } from '@material-ui/core';
import classnames from 'classnames';
import * as React from 'react';
import { Component, Fragment } from 'react';
import { ReactReduxContext } from 'react-redux';
import { hasRoleDev, translateRoles } from '../../api/user';
import { loadExternContatnsIfNecessary } from '../../external-constants/extern-constants';
import { getCordova, getManifest } from '../../globals';
import { t } from '../../i18n';
import { getLanguageCode, readLastLoginEmail, readLoginSession, saveLoginSession } from '../../local-storage/local-storage';
import { setLanguageRecord } from '../../redux-store/language';
import { doAJAXCall } from '../../shopping/common/ajax-call';
import { gotoLoginPage } from '../../shopping/history/shopping-history';
import { BaseUnishellPageState, renderInitUnishellPage, renderUnishellPage } from '../../unishell-page-common/unishell-page-common';
import { getServerURL, pointingProdServer, pointingUATServer, RatioXXS, switchToProdServer, switchToUATServer } from '../../util/Util';
import { updateMyUserProfile } from '../../wallet-server-api/wallet-server-api';
import { UButton } from '../../widget/button/ubutton';
import { PropertyListDataItem, PropertyListWidget } from '../../widget/property-list/property-list';
import { LangView } from '../language/LangView';
import { NoticeDialog } from '../NoticeDialog';
import { Toast } from '../Toast';
import './SettingsView.scss';


interface UserNameProps {
    name:string;
    saveName: (newName:string)=>void;
}

class UserName extends Component<UserNameProps, {name:string, edit: boolean}>{
    private errorDialog: NoticeDialog|undefined;

    constructor(props: UserNameProps){
        super(props);
        this.state = {name: this.props.name, edit: false}
    }

    updateUserName = (name:string)=>{
        doAJAXCall({
            whatIsFor: t('保存用户名'),
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken)=>{
                await updateMyUserProfile(accessToken, {name});
                this.setState({edit: false, name});
                this.props.saveName(name);
            }
        })
    }

    render(){
        return(
            <div className='user-name'>
            { this.state.edit ? 
              <Fragment>
                  <TextField value={this.state.name} variant='outlined' size='small' id='name-text-field'
                             onChange={(evt)=>{
                                 this.setState({name: (evt.currentTarget.value||'').substring(0,40)})
                             }}
                  />
                <div className='edit-buttons'>
                    <UButton size='small' variant='contained' color='primary'
                            onClick={()=>{
                                let name = this.state.name.trim();
                                if(!name){
                                    this.errorDialog!.show(t('保存用户名'), t('请输入用户名'), ()=>{});
                                }else{
                                    this.updateUserName(name);
                                    this.props.saveName(name);
                                }
                            }}
                    >{t('保存')}</UButton>
                    <UButton size='small' variant='contained' color='secondary'
                            onClick={()=>{
                                this.setState({edit: false, name: this.props.name})
                            }}
                    >{t('取消')}</UButton>
                </div>
                <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>                   
              </Fragment>
              :
              <Fragment>
                {this.props.name}
                <div  className={classnames('edit-buttons', {edit: this.state.edit})}>
                    <UButton size='small' variant='outlined' color='primary' onClick={()=>{
                        this.setState({edit: true, name: this.props.name})
                    }}>{t('编辑')}</UButton>
                </div>
              </Fragment>  
            }    
            </div>
        )
    }
}

interface PhoneNumberProps {
    phoneNumber:string;
    savePhoneNumber: (newPhoneNumber:string)=>void;
}

class PhoneNumber extends Component<PhoneNumberProps, {phoneNumber:string, edit: boolean}>{
    private errorDialog: NoticeDialog|undefined;

    constructor(props: PhoneNumberProps){
        super(props);
        this.state = {phoneNumber: this.props.phoneNumber, edit: false}
    }

    updatePhoneNumber = (phoneNumber:string)=>{
        doAJAXCall({
            whatIsFor: t('保存电话号码'),
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken)=>{
                await updateMyUserProfile(accessToken, {phoneNumber});
                this.setState({edit: false, phoneNumber});
                this.props.savePhoneNumber(phoneNumber);
            }
        })
    }

    render(){
        return(
            <div className='phone-number'>
            { this.state.edit ? 
              <Fragment>
                  <TextField value={this.state.phoneNumber} variant='outlined' size='small' id='phone-text-field'
                             onChange={(evt)=>{
                                 this.setState({phoneNumber: (evt.currentTarget.value||'').replace(/[^0-9.()-]/g,'').substring(0,20)})
                             }}
                  />
                <div className='edit-buttons'>
                    <UButton size='small' variant='contained' color='primary'
                            onClick={()=>{
                                let phoneNumber = this.state.phoneNumber.trim();
                                if(!phoneNumber){
                                    this.errorDialog!.show(t('保存电话号码'), t('请输入电话号码'), ()=>{});
                                }else{
                                    this.updatePhoneNumber(phoneNumber);
                                    this.props.savePhoneNumber(phoneNumber);
                                }
                            }}
                    >{t('保存')}</UButton>
                    <UButton size='small' variant='contained' color='default'
                            onClick={()=>{
                                this.setState({edit: false, phoneNumber: this.props.phoneNumber})
                            }}
                    >{t('取消')}</UButton>
                </div>
                <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>                   
              </Fragment>
              :
              <Fragment>
                {this.props.phoneNumber}
                <div  className={classnames('edit-buttons', {edit: this.state.edit})}>
                    <UButton size='small' variant='outlined' color='primary' onClick={()=>{
                        this.setState({edit: true, phoneNumber: this.props.phoneNumber})
                    }}>{t('编辑')}</UButton>
                </div>
              </Fragment>  
            }    
            </div>
        )
    }
}

interface MyState extends BaseUnishellPageState{
}

export class SettingsView extends Component<{dispatch: (x:any)=>void;},MyState>{
    private toast:Toast|undefined;
    private noticeDialog: NoticeDialog | undefined;
    private errorDialog: NoticeDialog|undefined;

    constructor(props: any){
        super(props);
        this.state = {pageInitState:'PAGE_INIT'}
    }

    appVersion = ()=> {
       // @ts-ignore 
       getCordova().getAppVersion.getVersionNumber().then( (version:string) => {
          let buildVersion=getManifest().buildVersion;
          this.noticeDialog!.show(t('版本信息'), version+"#"+buildVersion, ()=>{});
    });
    }



    updateUserName = (name:string)=>{
        const session = readLoginSession()!;
        session.profile!.name = name;
        saveLoginSession(session);
        this.forceUpdate();
    }
    updatePhoneNumber = (phoneNumber:string)=>{
        const session = readLoginSession()!;
        session.profile!.phoneNumber = phoneNumber;
        saveLoginSession(session);
        this.forceUpdate();
    }

    render(){
        if(this.state.pageInitState === 'PAGE_INIT'){
            return <Fragment>
                {
                    renderInitUnishellPage({
                        title: t('我的账号'),
                        className: 'user-settings-panel',
                        message: t('初始化...')    
                    })
                }
            </Fragment>;
        }

        const session = readLoginSession()!;

        return (
            <ReactReduxContext.Consumer>
            { ({store})=>{
                let email = readLastLoginEmail();
                const updateLanguage = (newLangCode:string) => {
                    store.dispatch(setLanguageRecord(newLangCode));
                }

                const lang = getLanguageCode();

                const switchUatServer = () => {
                    switchToUATServer();
                    gotoLoginPage();

                }
                const switchProdServer = () => {
                    switchToProdServer();
                    gotoLoginPage();
                }

                const propData:PropertyListDataItem[] = [];
                propData.push({name: t('电子邮箱'), value: email});
                propData.push({name: t('用户名'), value: <UserName name={session.profile!.name} 
                                                                  saveName={this.updateUserName}
                                                        />});
                propData.push({name: t('电话'), value: <PhoneNumber phoneNumber={session.profile!.phoneNumber}
                                                                    savePhoneNumber={this.updatePhoneNumber}
                                                       />})                                        
                propData.push({name: t('语言'), value: <LangView updateLanguage={updateLanguage} color='black'/>});
                propData.push({name: t('身份'), value: translateRoles(session.profile!.roles).join(', ')});
                if(!pointingProdServer()) 
                    propData.push({name: t('服务器'), value: getServerURL()});
                if(hasRoleDev(session.profile!.roles) && pointingProdServer()){
                    propData.push({name: t('切换服务器'), value: <UButton onClick={switchUatServer}>UAT</UButton>});
                }    
                if(hasRoleDev(session.profile!.roles) && pointingUATServer()){
                    propData.push({name: t('切换服务器'), value: <UButton onClick={switchProdServer}>PROD</UButton>});
                }
                
                const content = (
                <Fragment>
                    <div className={'user-settings-panel '+lang}>
                        {  <Fragment>
                                <div className='property-wrapper'>
                                    <PropertyListWidget list={propData} 
                                                        showBorder={true} 
                                                        key={getLanguageCode()}
                                                        mergeColScreenLetterRatio={RatioXXS}
                                    />
                                </div>
                            </Fragment>
                        }
                    </div>
                    <Toast close={true} message='' toastType='info' onClose={()=>{}} ref={(e)=>{this.toast=e!}}/>

                    <NoticeDialog ref={e=>this.noticeDialog=e!} open={false}></NoticeDialog>
                    <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>                   
                </Fragment>
                );
                return renderUnishellPage({
                    title: t('我的账号'),
                    className: 'settings-page',
                    content
                })
             }  
            }
            </ReactReduxContext.Consumer>
        )
    }

    async componentDidMount(){
        await loadExternContatnsIfNecessary();
        this.setState({pageInitState:'PAGE_LOAD_SUCCESS'});
    }
}
