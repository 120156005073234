import { Button, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { isFinite } from 'lodash';
import * as React from 'react';
import {Component} from 'react';
import { connect } from 'react-redux';
import { PagedResponse } from '../../api/transaction';
import { t } from '../../i18n';
import { WalletAppState } from '../../redux-store';
import { calcRationClass, formatDateTime, formatPrice, getCurrencyOption, getMaxThumbnailImgSize, getServerURL, RatioXS, RatioXXS, scrollBodyToTop } from '../../util/Util';
import { ConfirmDialogView } from '../../view/confirm-dialog/ConfirmDialogView';
import { NoticeDialog } from '../../view/NoticeDialog';
import { confirmOpenURL } from '../../view/open-url/open-url';
import { CurrencyOption, PaymentMethodOption, ShopOrderInfo, ShopOrderStatus, ProductCandidateBrief, AddressInfo2Str, PharmacistInfo } from '../ajax-data/ajax-data-types';
import { createPickupRecord, getCurrencyOptionsMap, getPaymentMethodOptionMap, getPharmcistInfo, getShoppingOrder, getShippingRecordStatusOptions, getShoppingOrderStatuOptions, searchShoppingOrdersByPharmacist, markOrderReadyForPickup, ShopOrderQueryFilter } from '../ajax-data/shopping-server-api';
import { doAJAXCall } from '../common/ajax-call';
import { ImageWithOverlay } from '../common/image-with-overlay';
import { Pagination } from '../common/render-pagination';
import { BaseShoppingPageProps} from '../common/shopping-page-common';

import { Toast } from '../../view/Toast';

import './pharmacy-dashboard.scss';
import { loadExternContatnsIfNecessary } from '../../external-constants/extern-constants';
import { BaseUnishellPageState, renderInitErrorUnishellPage, renderInitUnishellPage, renderUnishellPage } from '../../unishell-page-common/unishell-page-common';
import { UButton } from '../../widget/button/ubutton';
import { PropertyListDataItem, PropertyListWidget } from '../../widget/property-list/property-list';
import { SimpleEmail } from '../../widget/email/email';
import { SimplePhoneNumber } from '../../widget/phone-number/phone-number';
import { UDatePicker } from '../../widget/date-picker/date-picker';
import {ReactComponent as FilterIcon} from '../../images/filter-fill.svg';
import { updateCurrentPathParms } from '../history/shopping-history';

export interface PharmacyDashboardFilter {
    page?: number;
    pageSize?: number;
    status?: string;
    fromTime?: number;
    toTime?: number;
} 
interface PharmacyDashboardProps extends PharmacyDashboardFilter, BaseShoppingPageProps {
    currencyCode: string,
}

interface PharmacyDashboardState extends PharmacyDashboardFilter, BaseUnishellPageState {
    currencyOptionsMap?: {[key:string]:CurrencyOption[]};
    paymentMethodOptionsMap?:{[key:string]:PaymentMethodOption[]};
    orderStatusOptions?:{key:string, text:string}[];
    shippingRecordStatusOptions?:{key:string, text:string}[];

    pharmacistInfo?:PharmacistInfo;

    page:number;
    pageSize: number;

    expendSearchForm: boolean;

    searchResult?:PagedResponse<ShopOrderInfo>;
}

class PharmacyDashboardView extends Component<PharmacyDashboardProps, PharmacyDashboardState>{
    private errorDialog:NoticeDialog|undefined;
    private openUrlDialog:ConfirmDialogView | undefined;
    private toast: Toast|undefined;


    constructor(props: PharmacyDashboardProps){
        super(props);
        this.state = {pageInitState:'PAGE_INIT', expendSearchForm: false, page: this.props.page || 0, pageSize: this.props.pageSize||10,
                      status: this.props.status||'', fromTime: this.props.fromTime, toTime: this.props.toTime,
                    }
    }
    getTitle(){
        return t('取货点订单');
    }
    render(){
        if(this.state.pageInitState === 'PAGE_INIT'){
            return <React.Fragment>
                        {renderInitUnishellPage({title: this.getTitle(), message:t('初始化...')})}
                        <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                        <Toast close={true} duration={2500} message='' ref={e=>{this.toast=e!}} toastType='info'/>
                    </React.Fragment>
        }
        if(this.state.pageInitState === 'PAGE_LOAD_FAILED'){
            return <React.Fragment>
                        {renderInitErrorUnishellPage({title: this.getTitle(), 
                                                      errorMessage: this.state.initErrorMessage||t('初始化失败'),
                                                      onTryAgain: ()=>{ this.initializeAgain();    }
                                                      })}
                        <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                        <Toast close={true} duration={2500} message='' ref={e=>{this.toast=e!}} toastType='info'/>
                    </React.Fragment>
        }
        // loading data succeeded
        let content = this.renderSucceedContent();
        return (
            <React.Fragment>
                { renderUnishellPage({
                    title: this.getTitle(), 
                    className:'pharmacy-dashboard', 
                    underTopHeader: this.renderSearchForm(),
                    content
                })}
                <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                <ConfirmDialogView ref={e=>this.openUrlDialog=e!} open={false} />
                <Toast close={true} duration={2500} message='' ref={e=>{this.toast=e!}} toastType='info'/>
            </React.Fragment>
        )   
    }

    renderSucceedContent():JSX.Element {
        return (
            <div className='pharmacy-dashboard-content'>

                <div className='pharmacy-info'>
                    <p><strong>{this.state.pharmacistInfo!.pharmacyInfo.name}</strong></p>
                    <p>{AddressInfo2Str(this.state.pharmacistInfo!.pharmacyInfo.addressInfo)}</p>
                </div>


                <Pagination data={this.state.searchResult!} page={this.state.page} pageSize={this.state.pageSize} 
                            loadData={this.loadPaginationData}
                            changePageSize={(newPageSize, newPage)=>{
                                this.loadPaginationData(newPage, newPageSize);
                            }} 
                >  
                { this.renderOrderBriefs() }
                </Pagination>

            </div>
        )
    }

    readyForPickup(order:ShopOrderInfo){
        const {maxImgWidth} = getMaxThumbnailImgSize();

        doAJAXCall(
            {
                whatIsFor: t('通知客户取货'),
                errorDialog: this.errorDialog!,
                ajaxCall: async (accessToken)=>{
                    await markOrderReadyForPickup(accessToken, order.orderCode, maxImgWidth);
                    const order2 = await getShoppingOrder(accessToken, order.orderCode, maxImgWidth);
                    for(let i=0; i<this.state.searchResult!.content.length; i++){
                        if(this.state.searchResult!.content[i].orderCode === order.orderCode){
                            this.state.searchResult!.content[i] = order2;
                            break;
                        }
                    }
                    this.forceUpdate(()=>{
                        this.toast!.sendMessage(t('通知客户取货'), 'info');
                    });                    
                }
            }
        )
    }


    setPickedUpStatus(order:ShopOrderInfo){
        const {maxImgWidth} = getMaxThumbnailImgSize();

        doAJAXCall(
            {
                whatIsFor: t('标记客户已取货'),
                errorDialog: this.errorDialog!,
                ajaxCall: async (accessToken)=>{
                    await createPickupRecord(accessToken, order.orderCode, this.state.pharmacistInfo!.pharmacyId);
                    const order2 = await getShoppingOrder(accessToken, order.orderCode, maxImgWidth);
                    for(let i=0; i<this.state.searchResult!.content.length; i++){
                        if(this.state.searchResult!.content[i].orderCode === order.orderCode){
                            this.state.searchResult!.content[i] = order2;
                            break;
                        }
                    }
                    this.forceUpdate(()=>{
                        this.toast!.sendMessage(t('标记客户已取货'), 'info');
                    });                    
                }
            }
        )
    }

    renderOrderBriefs():JSX.Element {
        const serverURL = getServerURL();

        return (
            <div className='pharmacy-dashboard-table-wrapper'>
            {
                this.state.searchResult!.content.map(x=>{
                    let status = '';
                    let statusOpt = this.state.orderStatusOptions!.find(o=> o.key === x.status);
                    if(statusOpt){
                        status = statusOpt.text;
                    }

                    const currencyOption = getCurrencyOption(this.state.currencyOptionsMap!, 
                                                             this.props.languageCode!, 
                                                             x.shoppingCart.currencyCode);
                    let productBriefs:ProductCandidateBrief[] = [];
                    x.shoppingCart.item.forEach(item=>{
                        item.product.forEach(prod=>{
                            productBriefs.push(prod);
                        })
                    });
                    let pickup = !!x.pickupAddressInfo ? t('自提') : t('快递');

                    let orderProps:PropertyListDataItem[] = [
                        {name:t('订单号'), value: x.orderCode, valueClassName:'order-id'},
                        {name:t('客户'), value: x.userInfo.name},
                        {name:t('客户电邮'), value: <SimpleEmail email={x.userInfo.email}/>},
                        {name:t('客户电话'), value: <SimplePhoneNumber phoneNumber={x.userInfo.phoneNumber}/>, ignore:!x.userInfo.phoneNumber},
                        {name:t('创建时间'), value: formatDateTime(new Date(x.createDtm)), valueClassName:'timestamp'},
                        {name:t('更新时间'), value: formatDateTime(new Date(x.modifiedDtm)), valueClassName:'timestamp'},
                        {name:t('总计'), valueClassName:'total-price', value: formatPrice(x.shoppingCart.totalPrice, currencyOption.symbol, currencyOption.label)},
                        {
                            name:t('订单状态'), 
                            valueClassName: 'status-'+x.status,
                            value: <React.Fragment>
                                        <div className={'status-'+x.status}>{status}</div>
                                        { x.status === 'PAID' && !!x.pickupAddressInfo ?
                                        <UButton variant='outlined' color='primary' size='small'
                                                onClick={()=>{
                                                    this.readyForPickup(x);
                                                }}
                                        >{t('通知客户取货')}</UButton> : null
                                        }
                                        { x.status === 'READY_FOR_PICKUP' && !!x.pickupAddressInfo ?
                                        <UButton variant='outlined' color='primary' size='small'
                                                onClick={()=>{
                                                    this.setPickedUpStatus(x);
                                                }}
                                        >{t('标记客户已取货')}</UButton> : null
                                        }
                                </React.Fragment>
                        },
                        {name:t('备注'), value: (x.notes||[]).join('; ')},
                    ];
                    {productBriefs.map(p=>{
                        orderProps.push({
                            name: <ImageWithOverlay imageList={[p.thumbImage.startsWith('http')? p.thumbImage : serverURL+p.thumbImage]} classes='prod-thumb-img'/>,
                            value: <div>{p.productName} <br/>数量 {p.count}</div>
                        })
                    })};
                    orderProps.push({
                        name:pickup, 
                        value:AddressInfo2Str((x.pickupAddressInfo || x.shippingAddressInfo)!), 
                        valueClassName: 'address-col'
                    });
                    if(x.shippingRecordInfo && x.shippingRecordInfo!.length>0){
                        orderProps.push({
                            name: t('快递单号'),
                            value: <div>
                                    {x.shippingRecordInfo!.map(rec=>{
                                        const opt = this.state.shippingRecordStatusOptions!.find(v=>v.key===rec.status);
                                        const status = opt ? opt.text : ''; 
                                        return <p>
                                                    <a href={rec.trackingUrl} target='_'
                                                    onClick={evt=>{
                                                        evt.stopPropagation();
                                                        evt.preventDefault();
                                                        confirmOpenURL(rec.trackingUrl, this.openUrlDialog!);
                                                    }}
                                                    >{rec.trackNumber} ({status})</a>
                                                </p>
                                    })}    
                                    </div>
                        })
                    }
                    return <PropertyListWidget list={orderProps} showBorder={true} className='pharmacy-dashboard-table'/>                                                             
                })                
            }
            </div>
        )
    }

    renderSearchForm():JSX.Element {
        let startDate = this.state.fromTime? new Date(this.state.fromTime) : undefined;
        let endDate = this.state.toTime? new Date(this.state.toTime) : undefined;
        
        // https://en.wikipedia.org/wiki/IETF_language_tag
        let locale = 'zh';
        if(this.props.languageCode === 'en'){
            locale = 'en';
        }

        const {ratioClass, vw,  mw, ratio} = calcRationClass();
        let xs1 = 12;
        let xs2 = 12;
        if(ratio >= RatioXXS){
            xs1 = 4;
            xs2 = 8;
        }
        const today = new Date();

        return (
            <div className='pharmacy-dashboard-content-shopping-order-search-form'>
                <FilterIcon className='filter-icon' onClick={()=>{ this.setState({expendSearchForm: !this.state.expendSearchForm })  }}/>
                {this.state.expendSearchForm? <Grid container spacing={0} className='filter-grid'>
                    <Grid item xs={xs1 as 12}>
                        <div className='date-label'>{t('起始日期')}</div>
                    </Grid>
                    <Grid item xs={xs2 as 12}>
                        <UDatePicker value={startDate}
                                    minYear={2020}
                                    maxYear={today.getFullYear()+1}                        
                                    onChange={(value?:Date)=>{ 
                                            this.setState({fromTime: value? value.getTime(): undefined});
                                    }}
                        />
                    </Grid>
                    <Grid item xs={xs1 as 12}>
                        <div className='date-label'>{t('截止日期')}</div>
                    </Grid>
                    <Grid item xs={xs2 as 12}>
                        <UDatePicker value={endDate}
                                    minYear={2020}
                                    maxYear={today.getFullYear()+1}                        
                                    onChange={(value?:Date)=>{ 
                                            this.setState({toTime: value? value.getTime() : undefined});
                                    }}
                        />
                    </Grid>
                    <Grid item xs={xs1 as 12}>
                        <div className='status-label'>{t('订单状态')}</div>
                    </Grid>
                    <Grid item xs={xs2 as 12}>
                        <FormControl variant="standard" size='small' className='status-select'>
                                    <InputLabel></InputLabel>
                                    <Select value={this.state.status} 
                                            onChange={evt=>{ this.setState({status: evt.target.value as string})  }} 
                                    >
                                        {this.state.orderStatusOptions!.map(x=>{
                                            return <MenuItem value={x.key}>{x.text}</MenuItem>
                                        })}
                                    </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                :null
                }

                
                {this.state.expendSearchForm ?
                    <UButton className='search-button' variant='contained' color='primary'
                            onClick={()=>{ this.searchOrders(this.state.page, this.state.pageSize) }}
                    >{t('搜索')}</UButton>
                    : null
                }
            </div>)
    }

    getFromTime() {
        if(!this.state.fromTime) return undefined;

        let d = new Date(this.state.fromTime);
        d.setHours(0);
        d.setMinutes(0);
        d.setSeconds(0);

        return d.getTime();
    }
    getToTime() {
        if(!this.state.toTime) return undefined;

        let d = new Date(this.state.toTime);
        d.setHours(23);
        d.setMinutes(59);
        d.setSeconds(59);

        return d.getTime();
    }

    getFilters(): ShopOrderQueryFilter {
        const status = this.state;
       
        return {
                status: (!this.state.status) ? undefined : [this.state.status], 
                fromTime: this.getFromTime(), 
                toTime: this.getToTime()
            }
    }

    loadPaginationData = (page:number, pageSize:number) => {
        this.searchOrders(page, pageSize)
    }

    searchOrders(page: number, pageSize:number){    
        const {maxImgWidth, maxImgHeight} = getMaxThumbnailImgSize();
        doAJAXCall(
            {
                whatIsFor: '取货点订单',
                errorDialog: this.errorDialog!,
                ajaxCall: async (accessToken)=>{
                    let searchResult = await searchShoppingOrdersByPharmacist(accessToken, page, pageSize, maxImgWidth,
                                                                  this.props.currencyCode,
                                                                  this.getFilters());
                    this.setState({searchResult, page:searchResult.page, pageSize:searchResult.size})
                }
            }
        )
    }

    initializeAgain(){
        this.setState({pageInitState:'PAGE_INIT', initErrorMessage:undefined},
                      ()=>{
                          this.loadInitData();
                      })
    }

    componentDidMount(){
        this.loadInitData();
    }

    componentDidUpdate(prevProps:any, prevState:PharmacyDashboardState){
        if(prevState.page !== this.state.page){
            scrollBodyToTop();
        }
        const {status, fromTime, toTime} = this.getFilters();
        let parms:PharmacyDashboardFilter = {
            fromTime, toTime, status: status? status[0]: undefined, page: this.state.page, pageSize: this.state.pageSize
        }
        updateCurrentPathParms('main/shopping/pharmacy-dashboard', parms);
    }


    loadInitData(){
        const {maxImgWidth} = getMaxThumbnailImgSize();

        doAJAXCall({
            whatIsFor: t('下载数据'),
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken, username)=>{
                                            await loadExternContatnsIfNecessary();
                                            let currencyOptionsMap = getCurrencyOptionsMap();
                                            let pharmacistInfo = await getPharmcistInfo(accessToken, username);
                                              let paymentMethodOptionsMap = getPaymentMethodOptionMap();
                                              let orderStatusOptions = getShoppingOrderStatuOptions(accessToken, this.props.languageCode!);
                                              let shippingRecordStatusOptions = getShippingRecordStatusOptions(accessToken, this.props.languageCode!);

                                              let searchResult = await searchShoppingOrdersByPharmacist(accessToken,
                                                                                            this.state.page, this.state.pageSize,
                                                                                            maxImgWidth,
                                                                                            this.props.currencyCode,
                                                                                            this.getFilters());
                                              this.setState({pageInitState:'PAGE_LOAD_SUCCESS', 
                                                             currencyOptionsMap,
                                                             paymentMethodOptionsMap,
                                                             orderStatusOptions,
                                                             shippingRecordStatusOptions,
                                                             searchResult,
                                                             pharmacistInfo
                                                            });
                                           },
            onError: (errorMessage:string)=>{ this.setState({pageInitState:'PAGE_LOAD_FAILED', initErrorMessage:errorMessage})}                                           
        })                
    }

}

export const PharmacyDashboard = connect(
    (state:WalletAppState)=>({languageCode: state.language.langCode, currencyCode: state.currency.currencyCode})
)(PharmacyDashboardView);