// Generated by IcedCoffeeScript 108.0.11
(function () {
  var
  /*Sodium,*/
  TweetNaCl, e, nacl_c, nacl_js;
  nacl_js = require('tweetnacl/nacl-fast');
  nacl_c = null; //   Sodium = require('./sodium').Sodium;

  TweetNaCl = require('./tweetnacl').TweetNaCl;

  try {
    nacl_c = null; //require("sodium").api;
  } catch (_error) {
    e = _error;
  }

  exports.sign = {
    publicKeyLength: nacl_js.sign.publicKeyLength,
    secretKeyLength: nacl_js.sign.secretKeyLength,
    signatureLength: nacl_js.sign.signatureLength,
    seedLength: nacl_js.sign.seedLength
  };

  exports.alloc = function (_arg) {
    var force_js, publicKey, ret, secretKey;
    publicKey = _arg.publicKey, secretKey = _arg.secretKey, force_js = _arg.force_js;
    ret = force_js || nacl_c == null ? new TweetNaCl({
      publicKey: publicKey,
      secretKey: secretKey
    }) : null;
    /*new Sodium({
    publicKey: publicKey,
    secretKey: secretKey
    });*/

    ret.lib = {
      c: nacl_c,
      js: nacl_js
    };
    return ret;
  };
}).call(this);