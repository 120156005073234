import * as React from 'react';
import {Component} from 'react';
import './three-state-checkbox.scss';
import uuidv4 from 'uuid/v4';
import { Checkbox, FormControlLabel } from '@material-ui/core';

interface MyProps {
    status?: boolean;
    trueLabel: string;
    falseLabel: string;
    disabled?: boolean;
    color?: 'primary' | 'secondary' |'default';
    onChange?: (status?: boolean)=>void;
}

export class ThreeStateCheckbox extends Component<MyProps, {trueId:string, falseId:string}>{
    constructor(props: MyProps){
        super(props);
        this.state = {trueId: uuidv4(), falseId: uuidv4()}
    }

    updateStatus(status?: boolean){
        if(this.props.onChange){
            this.props.onChange(status);
        }
    }

    render(){
        const {trueLabel, falseLabel, status, disabled, color} = this.props;
        const {trueId, falseId} = this.state;
        const checkTrue = status !== undefined && status;
        const checkFalse = status !== undefined && !status;
        const checkNeither = status === undefined;

        return( 
            <div className='three-state-checkbox'>
                <FormControlLabel 
                    control={<Checkbox 
                                checked={checkTrue}  
                                disabled={disabled}
                                color={color || 'default'} 
                                onClick={()=>{   
                                            if(checkNeither){
                                                this.updateStatus(true);
                                            }else if(checkTrue){
                                                this.updateStatus(undefined);
                                            }else {
                                                this.updateStatus(true);
                                            }
                                        }
                                }/>
                            } 
                    label={trueLabel}/>

                <FormControlLabel 
                    control={<Checkbox 
                                checked={checkFalse}  
                                disabled={disabled}
                                color={color || 'default'} 
                                onClick={()=>{   
                                                if(checkNeither){
                                                    this.updateStatus(false);
                                                }else if(checkTrue){
                                                    this.updateStatus(false);
                                                }else {
                                                    this.updateStatus(undefined);
                                                }
                                            }
                                }/>
                            } 
                    label={falseLabel}/>
            </div>
        )
    }
}
