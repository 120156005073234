import { getExternContantsInMemory, Translation } from "./external-constants/extern-constants";
import { warn } from "./globals";
import { getLanguageCode } from "./local-storage/local-storage";


/**
 * translate simplified Chinese text based on current language code and optional context.
 * 
 * @param text 
 * @param context support diff translations of same text depends on where the text is used. For example, 'unishellPay'
 */
export function t(text:string, context?:string):string {
    let langCode:string = getLanguageCode();
    
    let resources = getExternContantsInMemory().translations;
    if(!resources[langCode]){
        warn(`i18n:invalid language code ${langCode}`);
        return text;
    }
    if(!resources[langCode].translation){
        warn(`i18n:missing translation for language code ${langCode}`);
        return text;
    }
    if(context){
        if(typeof (resources[langCode].translation[context]) !== 'object'){
            warn(`i18n:context ${context} in translation for language code ${langCode} is not object`);
            return text;
        }else{
            return (resources[langCode].translation[context] as Translation)[text] as string || text;
        }
    }else{
        return resources[langCode].translation[text] as string || text; 
    }
}

// @ts-ignore
window.t = t;
