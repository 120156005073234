import * as React from 'react';
import {Component, Fragment} from 'react';
import classNames from 'classnames';

import './AssetView.scss';
import {t} from '../../i18n';
import { ReactReduxContext, batch } from 'react-redux';
import { retrieveUnishellPoints } from '../../wallet-server-api/wallet-server-api';
import { round } from '../../util/Util';
import { BaseUnishellPageState, renderInitErrorUnishellPage, renderInitUnishellPage, renderUnishellPage } from '../../unishell-page-common/unishell-page-common';
import { NoticeDialog } from '../NoticeDialog';
import { doAJAXCall } from '../../shopping/common/ajax-call';
import { loadExternContatnsIfNecessary } from '../../external-constants/extern-constants';
import { gotoShoppingPathNSavePrevPath } from '../../shopping/history/shopping-history';
import { UButton } from '../../widget/button/ubutton';


interface AssetViewProps {
   dispatch: (x:any)=>void;
}

interface AssertViewState extends BaseUnishellPageState {
    pts: number;
}

export class AssetView extends Component<AssetViewProps, AssertViewState> {
    private errorDialog: NoticeDialog|undefined;

    constructor(props: AssetViewProps){
        super(props);
        this.state = {pageInitState:'PAGE_INIT', pts: 0};
    }

    getTitle(){
        return t('会员积分');
    }

    render(){
        const title = t('会员积分');

        return <ReactReduxContext.Consumer>
            {({store})=>{
                if(this.state.pageInitState === 'PAGE_INIT'){
                    return (
                        <Fragment>
                            {
                                renderInitUnishellPage({
                                    title,
                                    className: 'asset-page',
                                    message: t('初始化...')
                                })
                            }
                            <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>                   
                        </Fragment>
                    )
                }
                if(this.state.pageInitState === 'PAGE_LOAD_FAILED'){
                    return <Fragment>
                        {
                            renderInitErrorUnishellPage({
                                title,
                                className: 'asset-page',
                                errorMessage: this.state.initErrorMessage!,
                                onTryAgain: ()=>{
                                    this.refresh();
                                }
                            })
                        }
                        <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>                   
                    </Fragment>
                }

                let pointsClasses = classNames('points', {positive:this.state.pts>0, negative:this.state.pts<0})

                const content = (
                    <div>
                        <div className='unis-points'>
                            <div className='currency'>{t('会员积分') || 'UNIS'}</div>
                            <div className={pointsClasses}>{this.state.pts<0?'':round(this.state.pts, 2)}</div>
                        </div>
                        <div className='refresh'>
                            <UButton variant='contained' color='primary'
                                    onClick={()=>{
                                        gotoShoppingPathNSavePrevPath('main/wallet/purchase-points');
                                    }}
                            >{t('会员充值')}</UButton>

                            <UButton variant='contained' color='secondary'
                                    onClick={()=>{
                                        this.refresh();
                                    }}
                            >{t('刷新')}</UButton>

                        </div>
                    </div>
                )
                return <Fragment>
                        {
                            renderUnishellPage({
                                title,
                                className: 'asset-page',
                                content
                            })
                        }
                        <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>                   
                    </Fragment>
            }}
        </ReactReduxContext.Consumer>
    }

    private refresh = () => {
        doAJAXCall({
            whatIsFor: t('更新数量'),
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken)=>{
                await loadExternContatnsIfNecessary();

                let newPoints = await retrieveUnishellPoints(accessToken);
                batch(()=>{
                    this.setState({pageInitState: 'PAGE_LOAD_SUCCESS', pts: newPoints});
                });
            },
            onError: (errorMsg)=>{
                this.setState({pageInitState: 'PAGE_LOAD_FAILED', initErrorMessage: errorMsg})
            }

        })
    }

    componentDidMount(){
       this.refresh();
    }
}