import * as React from 'react';
import {Component, Fragment} from 'react';

import placeholderImg from '../../images/image-placeholder-350x350.png'
import { ImageWithFallback } from '../image-fallback/ImageWithFallback';

interface ImageWithPlaceholderProps {
    imgSrc?: string;
    imgClassName?:string;
}

interface ImageWithPlaceholderState {
    loaded: boolean;
}

/**
 * load and show slow external image
 */
export class ImageWithPlaceholder extends Component<ImageWithPlaceholderProps, ImageWithPlaceholderState> {
    constructor(props: ImageWithPlaceholderProps){
        super(props);
        this.state = {loaded: false}
    }

    render() {
        const imgLoaded = ()=>{
            this.setState({loaded: true});
        }
        return( 
            <Fragment>
                {(!this.state.loaded || !this.props.imgSrc) && <ImageWithFallback src={placeholderImg} className={this.props.imgClassName}/>}
                <img src={this.props.imgSrc} className={this.props.imgClassName} 
                     style={this.state.loaded? {} : {display: 'none'} } 
                     onLoad={ imgLoaded }
                />
            </Fragment>
        )

    }
}