import * as React from 'react';
import {Component} from 'react';
import { connect } from 'react-redux';
import { loadExternContatnsIfNecessary } from '../../external-constants/extern-constants';
import { t } from '../../i18n';
import { WalletAppState } from '../../redux-store';
import { BaseUnishellPageState, renderInitErrorUnishellPage, renderInitUnishellPage, renderUnishellPage } from '../../unishell-page-common/unishell-page-common';
import { NoticeDialog } from '../../view/NoticeDialog';
import { CurrencyOption, PaymentMethodOption, ShopPaymentRequestData, ShoppingOrder } from '../ajax-data/ajax-data-types';
import { getCurrencyOptionsMap, getPaymentMethodOptionMap, retrieveShoppingCartOrder } from '../ajax-data/shopping-server-api';
import { doAJAXCall } from '../common/ajax-call';
import { BaseShoppingPageProps} from '../common/shopping-page-common';
import { AliPayment } from './ali-payment';
import { CreditCardPayment } from './credit-card-payment';
import './payment.scss';
import { UnisPayment } from './unis-payment';
import { WechatPayment } from './wechat-payment';



interface PaymentProps extends BaseShoppingPageProps {
    paymentData: ShopPaymentRequestData
}

interface PaymentState extends BaseUnishellPageState {
    currencyOptionsMap?: {[key:string]:CurrencyOption[]};
    paymentMethodOptionsMap?:{[key:string]:PaymentMethodOption[]}
    order?: ShoppingOrder;
}

class ShoppingPaymentView extends Component<PaymentProps, PaymentState>{
    private errorDialog:NoticeDialog|undefined;

    constructor(props: PaymentProps){
        super(props);
        this.state = {pageInitState:'PAGE_INIT'}
    }
    getTitle(){
        return t('商城付款');
    }

    render(){
        if(this.state.pageInitState === 'PAGE_INIT'){
            return <React.Fragment>
                        {renderInitUnishellPage({title: this.getTitle(), message:t('初始化...')})}
                        <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                    </React.Fragment>
        }
        if(this.state.pageInitState === 'PAGE_LOAD_FAILED'){
            return <React.Fragment>
                        {renderInitErrorUnishellPage({title: this.getTitle(), 
                                                      errorMessage: this.state.initErrorMessage||t('初始化失败'),
                                                      onTryAgain: ()=>{ this.initializeAgain();    }
                                                      })}
                        <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                    </React.Fragment>
        }
        // loading data succeeded
        let content = this.renderSucceedContent();
        return (
            <React.Fragment>
                { renderUnishellPage({title: this.getTitle(), className:'shopping-payment', content})}
                <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
            </React.Fragment>
        )   
    }
 
    renderSucceedContent():JSX.Element {

        let content: JSX.Element;
        if(this.props.paymentData.paymentMethod === 'Wallet'){
            content = <UnisPayment languageCode={this.props.languageCode!} currencyOptionsMap={this.state.currencyOptionsMap!}
                                   paymentMethodOptionsMap={this.state.paymentMethodOptionsMap!}
                                   paymentData={this.props.paymentData}/>
        }else if(this.props.paymentData.paymentMethod === 'CreditCard'){
            content = <CreditCardPayment languageCode={this.props.languageCode!} currencyOptionsMap={this.state.currencyOptionsMap!}
                                   paymentMethodOptionsMap={this.state.paymentMethodOptionsMap!}
                                   paymentData={this.props.paymentData}/>

        }else  if(this.props.paymentData.paymentMethod === 'WechatPay'){
            content = <WechatPayment languageCode={this.props.languageCode!} currencyOptionsMap={this.state.currencyOptionsMap!}
                                   paymentMethodOptionsMap={this.state.paymentMethodOptionsMap!}
                                   paymentData={this.props.paymentData}
                                   order={this.state.order!}/>
        }else  if(this.props.paymentData.paymentMethod === 'AliPay'){
            content = <AliPayment languageCode={this.props.languageCode!} currencyOptionsMap={this.state.currencyOptionsMap!}
                                   paymentMethodOptionsMap={this.state.paymentMethodOptionsMap!}
                                   paymentData={this.props.paymentData}
                                   order={this.state.order!}/>
        }else{
            alert(t(`错误付费方式`)+":"+this.state.order!.paymentMethod);
            content = <div>{t(`错误付费方式`)+":"+this.state.order!.paymentMethod}</div>
        }

        return (
            <div className='shopping-payment-content'>
            {content}
            </div>
        )
    }
    initializeAgain() {
        this.setState({pageInitState:'PAGE_INIT', initErrorMessage:undefined},
                      ()=>{
                          this.loadData();
                      })
    }
    componentDidMount(){
        this.loadData();
    }

    loadData(){
        doAJAXCall({
            whatIsFor: t('下载数据'),
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken)=>{
                                              await loadExternContatnsIfNecessary();
                                              let currencyOptionsMap = getCurrencyOptionsMap();
                                              let paymentMethodOptionsMap = getPaymentMethodOptionMap();
                                              let order = await retrieveShoppingCartOrder(accessToken);
                                              this.setState({pageInitState:'PAGE_LOAD_SUCCESS', 
                                                             currencyOptionsMap,
                                                             paymentMethodOptionsMap,
                                                             order
                                                            });
                                           },
            onError: (errorMessage:string)=>{ this.setState({pageInitState:'PAGE_LOAD_FAILED', initErrorMessage:errorMessage})}                                           
        })                
    }
}

export const ShoppingPayment = connect(
    (state:WalletAppState)=>{ return {languageCode: state.language.langCode }  }
)(ShoppingPaymentView);