import * as React from 'react';
import './image-overlay.scss';
import CloseIcon from '@material-ui/icons/Close';
import {ReactComponent as ArrowRightIcon} from './right-arrow.svg';
import {ReactComponent as ArrowLeftIcon} from './left-arrow.svg';


export function ImageOverlay(props:{imageList:string[], close:()=>void}):JSX.Element {
    const [index, setIndex] = React.useState(0);

    return (
        <div className='image-overlay' onClick={()=>{ props.close(); }}>
           <CloseIcon className='close-icon' onClick={()=>{props.close(); }}/>
           {props.imageList.length>1 && <ArrowLeftIcon className='arrow-left-icon' 
                                                      onClick={(evt)=>{ 
                                                                        evt.stopPropagation();
                                                                        let i = index - 1;
                                                                        if(i<0) i = props.imageList.length - 1;
                                                                        setIndex(i); 
                                                                      } }/>}

           <img className='the-image' src={props.imageList[index % props.imageList.length]} 
                onClick={ (evt)=>{ evt.stopPropagation(); } }/>

           {props.imageList.length>1 && <ArrowRightIcon  className='arrow-right-icon' 
                                                         onClick={(evt)=>{
                                                                            evt.stopPropagation(); 
                                                                            setIndex((index+1) % props.imageList.length); 
                                                                          } }/>}
        </div>
    )
}
