import * as React from 'react';
import {Component, Fragment} from 'react';

import './TransferListView.scss';
import {t} from '../../i18n';
import { PagedResponse, TransferSummary } from '../../api/transaction';
import { ReactReduxContext } from 'react-redux';
import { retrieveTransferList } from '../../wallet-server-api/wallet-server-api';
import {WalletAppState} from '../../redux-store';
import { NoticeDialog } from '../NoticeDialog';
// @ts-ignore
import dataFormat from 'date-format';
import classNames from 'classnames';
import { readLoginSession, getLanguageCode } from '../../local-storage/local-storage';
import { BaseUnishellPageState, renderInitErrorUnishellPage, renderInitUnishellPage, renderUnishellPage } from '../../unishell-page-common/unishell-page-common';
import { Paper } from '@material-ui/core';
import { doAJAXCall } from '../../shopping/common/ajax-call';
import { LANG_CODE_EN, loadExternContatnsIfNecessary } from '../../external-constants/extern-constants';
import { Pagination } from '../../shopping/common/render-pagination';
import { PropertyListDataItem, PropertyListWidget } from '../../widget/property-list/property-list';
import { RatioXXS, scrollBodyToTop } from '../../util/Util';

interface TransferListViewProps {
    dispatch: (x:any)=>void;
}
interface TransferListViewState extends BaseUnishellPageState {
    data?: PagedResponse<TransferSummary>;
    myUsername?:string;
    page: number;
    pageSize: number;

}

export class TransferListView extends Component<TransferListViewProps, TransferListViewState>{
    private errorDialog: NoticeDialog|undefined;
    private touchStartTime: number = 0;
    private touchStartX: number = 0;
    private touchStartY: number = 0;

    constructor(props: TransferListViewProps){
        super(props);
        this.state = {page:0, pageSize:6, data:undefined, pageInitState: 'PAGE_INIT'};
    }

    loadPaginationData = (page:number, pageSize:number) => {
        this.loadData(page, pageSize, false);
    }

    loadData = (page: number, pageSize: number,
                initialize: boolean,
                callback?: (errorMessage?:string)=>void)=>{
        let whatIsFor:string = t('读交易记录');
        doAJAXCall({
            whatIsFor,
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken, myUsername)=>{
                if(initialize){
                    await loadExternContatnsIfNecessary();
                }
                const data = await retrieveTransferList(accessToken, page, this.state.pageSize);
                if(callback){
                    callback!();
                }
                this.setState({data, myUsername, page: data.page, pageSize:data.size});    
            },
            onError: (errorMessage)=>{
                if(callback){
                    callback!(errorMessage);
                }                                                                
            }
        })
    }

    renderTransferRecord(){

    }

    renderTransferData = (myUsername:string)=>{
        if(!this.state.data!.totalElements){
            return <Fragment>
                        <div className='notice'>{t('暂无交易记录')}</div>
                    </Fragment>
        }

        let list = this.state.data!.content.map((item,idx)=>{
            const isDebt = myUsername===item.senderUsername;
            const isCredit = myUsername===item.receiverUsername;
            const pointsClass = classNames({debt:isDebt, credit:isCredit})
            const recordClass = classNames('transfer-record', {odd:idx%2===1, even:idx%2===0});
            let transferType:string = item.requestType;
            if(getLanguageCode() !== LANG_CODE_EN){
                switch(item.requestType){
                    case 'REFILL': transferType = t('充值'); break;
                    case 'SEND':   transferType = t('转账'); break;
                    case 'PAYMENT':   transferType = t('支付'); break;
                }
            }

            const txProps:PropertyListDataItem[] = [
                {name: t('时间'), value: dataFormat('yyyy-MM-dd hh:mm:ss', new Date(item.createdDtm))},
                {name: t('对方'), value: <div>{item.receiverName}<br/>{item.receiverEmail}</div>, ignore: !isDebt},
                {name: t('对方'), value: <div>{item.senderName}<br/>{item.senderEmail}</div>, ignore: !isCredit},
                {name: t('数量'), value: (item.points * 100 / 100).toFixed(2), valueClassName: pointsClass},
                {name: t('类型'), value: transferType},
                {name: t('备注'), value: item.memo},
            ]

             // TODO: use transfer id as key
             return <div key={item.createdDtm.toString()+'.'+idx+'.'+this.state.page}>
                     <PropertyListWidget list={txProps} className={recordClass}
                                          showBorder={true} mergeColScreenLetterRatio={RatioXXS}/>
                    </div>
        })


        return <Pagination data={this.state.data!} 
                           page={this.state.page} 
                           pageSize={this.state.pageSize}
                           loadData={this.loadPaginationData}
                           classNames='transfer-record-list'
                           changePageSize={(newPageSize, newPage)=>{
                               this.loadPaginationData(newPage, newPageSize);
                           }}
                >
                    {list}
                </Pagination>
    }

    render(){
        if(this.state.pageInitState === 'PAGE_INIT'){
            return <Fragment>
                {
                    renderInitUnishellPage(
                        {
                            title: t('交易记录'),
                            className: 'transfer-list-page',
                            message: t('初始化...')    
                        }
                    )
                }
                <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
            </Fragment> 
        }
        if(this.state.pageInitState === 'PAGE_LOAD_FAILED'){
            return <Fragment>
                {
                    renderInitErrorUnishellPage({
                        title: t('交易记录'),
                        className: 'transfer-list-page',
                        errorMessage: this.state.initErrorMessage!,
                        onTryAgain: ()=>{
                            this.loadData2();
                        }
                    })
                }
                <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
            </Fragment>
        }

        const session = readLoginSession()!;

        return (
            <ReactReduxContext.Consumer>
            { ({store})=>{
                const state:WalletAppState = store.getState();
                const myPath = state.pagePath.currentPage;

                let dataTable = null;

                if(this.state.data){
                    dataTable = this.renderTransferData(session.profile!.username);
                }

                
                const content =(
                    <Fragment>
                            {dataTable}
                            <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>                   
                    </Fragment>
                );
                return renderUnishellPage({
                    title: t('交易记录'),
                    className: 'transfer-list-page',
                    content
                });        
            }}
            </ReactReduxContext.Consumer>
            
        )
    }

    loadData2(){
        this.loadData(this.state.page, this.state.pageSize,
                      true,
                      (error)=>{
                          if(error){
                            this.setState({pageInitState: 'PAGE_LOAD_FAILED', initErrorMessage: error})
                          }else{
                            this.setState({pageInitState: 'PAGE_LOAD_SUCCESS'})
                          }
                      });        
    }
    componentDidMount(){
        this.loadData2();
    }

    componentDidUpdate(prevProps:any, prevState:TransferListViewState){
        if(prevState.page !== this.state.page){
            scrollBodyToTop();
        }
    }
}