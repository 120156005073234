import * as React from 'react';

import { CURRENT_PAGE } from '../redux-store/path/types';
import { t } from '../i18n';
import { gotoLoginPage } from '../shopping/history/shopping-history';
import { ROLE_ADMIN, ROLE_NUTRITIONIST, ROLE_PHARMACIST, ROLE_SHOP_GNGINC } from '../api/user';

declare function scanNutritionistQRCode():void;
declare function scanQRCode():void;
declare function scanOrderCode():void;
declare function scanBarCode():void;

type Role = typeof ROLE_NUTRITIONIST |typeof  ROLE_ADMIN |typeof  ROLE_PHARMACIST | typeof ROLE_SHOP_GNGINC;

export interface BaseMenuItemData {
    type: 'leaf' | 'dir';
    label: string;
    /** optional roles to access the menu item if user has one of the roles*/
    permitRoles?: Role[];
    /** optional roles to block access the menu item if user has one of the roles */
    // negatePermitRoles?: Role[];
    /** optional id used to identify this menu item data */
    id?: string;
}
export interface MenuItemData extends BaseMenuItemData {
    type: 'leaf';
    placeholder?: boolean;
    menuPath?: CURRENT_PAGE;
    params?: {[key:string]: any};
    onClick?: ()=>void;
    isAction?: boolean;
}
export interface MenuItemDirData extends BaseMenuItemData {
    type: 'dir';
    children:(MenuItemData|MenuItemDirData)[];
}

export function getMenuData():(MenuItemDirData|MenuItemData)[]{ 
    return [
    {
        type: 'dir', label: '恩及商城',
        children: [
            {type: 'leaf', label: t('恩及商城'), menuPath: 'main/shopping/search', params:{}},
            {type: 'leaf', label: t('购物车'), menuPath: 'main/shopping/my-shopping-cart'},
            {type: 'leaf', label: t('商城订单记录'), menuPath: 'main/shopping/my-orders'},
            {type: 'leaf', label: t('计价货币'), menuPath: 'main/shopping/switch-currency'},
        ]
    },
    {type: 'leaf', label: t('取货点订单'), menuPath: 'main/shopping/pharmacy-dashboard', permitRoles:['ROLE_PHARMACIST'], id:'pickup_orders'},
    {
        label: t('积分'), type: 'dir', 
        children:[
            {type: 'leaf', label: t('会员积分'), menuPath: 'main/wallet/asset'},
            {type: 'leaf', label: t('会员充值'), menuPath: 'main/wallet/purchase-points'},
            {type: 'leaf', label: t('扫码转出'), onClick: ()=>{ scanQRCode() }, isAction: true },
            {type: 'leaf', label: t('积分转出'), menuPath: 'main/wallet/receive-transfer/manual-transfer'},
            {type: 'leaf', label: t('积分转入'),  menuPath: 'main/wallet/receive-transfer/receive'},
            {type: 'leaf', label: t('交易记录'), menuPath: 'main/wallet/transfer-list'},
            {type: 'leaf', label: t('积分首页'), menuPath: 'main/wallet'},
        ]
    },
    {
        type: 'dir', label: '通用工具', id: 'general_tools',
        children: [
            {type: 'leaf', label: t('联系我们'), menuPath: 'main/tools/send-message'},
            {type: 'leaf', label: t('取货地点信息'), menuPath: 'main/tools/pickup-location'},
            {type: 'leaf', label: t('扫码查询产品'),  onClick: ()=>{ scanBarCode() }, isAction: true },
            {type: 'leaf', label: t('商城订单记录'), menuPath: 'main/shopping/my-orders'},
            {type: 'leaf', label: t('版本信息'), menuPath: 'main/tools/upgrade-app'},
        ]
    },
    {
        type: 'dir', label: '用户工具', id: 'client_tools',
        children: [
            {type: 'leaf', label: t('专业服务查询'), menuPath: 'main/tools/nutritionist-list',},
            {type: 'leaf', label: t('我的专业服务'), menuPath: 'main/tools/my-nutritionist',},
            {type: 'leaf', label: t('收到的健康建议'), menuPath: 'main/tools/my-health-advice'},
            {type: 'leaf', label: t('扫码选择服务'), onClick: ()=>{ scanNutritionistQRCode()}, isAction: true},
            {type: 'leaf', label: t('商城订单记录'), menuPath: 'main/shopping/my-orders'},
        ]
    },
    {
        type: 'dir', label: t('专用工具'), permitRoles:['ROLE_NUTRITIONIST'], id: 'nutritionist_tools',
        children: [
            {type: 'leaf', label: t('客户列表'), menuPath: 'main/tools/client-list'},
            {type: 'leaf', label: t('发出的健康建议'), menuPath: 'main/tools/health-advice-by-me'},
            {type: 'leaf', label: t('检索产品'), menuPath: 'main/tools/search-products'},
            {type: 'leaf', label: t('我的收藏'), menuPath: 'main/tools/my-bookmarks'},
            {type: 'leaf', label: t('服务收益'), menuPath: 'main/shopping/my-commissions'},
            {type: 'leaf', label: t('我的档案'), menuPath: 'main/shopping/nutritionist-profile'},
        ]
    },
    {
        type: 'dir', label: t('商城管理员工具'), permitRoles:['ROLE_SHOP_GNGINC'], id: 'shop_gnginc_tools',
        children: [
            {type: 'leaf', label: t('Natrual Zenith 上个月咨询费(Deprecated)'), menuPath: 'main/tools/commission'},
            {type: 'leaf', label: t('恩及商城上个月咨询费'), onClick: ()=>{ alert('to do')}},
        ]
    },
    {
        type: 'dir', label: t('管理员工具'), permitRoles:['ROLE_ADMIN'], id: 'admin_tools',
        children: [
            {type: 'leaf', label: t('检索产品'), menuPath: 'main/tools/search-products'},
            {type: 'leaf', label: t('查询用户'), menuPath: 'main/tools/search-users'},
            {type: 'leaf', label: t('管理商城订单(需宽屏)'), menuPath: 'main/shopping/order-dashboard'},
            {type: 'leaf', label: t('查询商城咨询费'), menuPath: 'main/shopping/all-commissions'},
            {type: 'leaf', label: t('分配商城咨询费'), menuPath: 'main/shopping/distribute-commissions'},
            {type: 'leaf', label: t('更新搜索引擎'), menuPath: 'main/tools/update-prod-index'},
        ]
    },
    {
        type:'dir', label: t('我的'),
        children: [
            {type: 'leaf', label: t('我的账号(包括切换语言)'), menuPath: 'main/my-profile/settings'},
            {type: 'leaf', label: t('计价货币'), menuPath: 'main/shopping/switch-currency'},
            {type: 'leaf', label: t('用户规则'), menuPath: 'main/tools/terms'},
            {type: 'leaf', label: t('隐私政策'), menuPath: 'main/tools/privacy'},
        ]
    },
    {
        type: 'leaf', label: '', placeholder: true, onClick: ()=>{ }
    },
    {
        type: 'leaf', label: t('登出'), onClick: ()=>{ gotoLoginPage(); }, isAction: true
    }
];
}
