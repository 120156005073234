import { asyncDelay, getServerURL } from "../../util/Util";
import {  processHttpOK,  } from "../../wallet-server-api/ajax-common";
import axios from 'axios';
import {ClientHealthAdvice, NutritionistClient, CurrencyOption,   
    NutritionistHealthAdvice,   
    NutritionistInfo,   
        ProductCategory, ProductCandidateBrief, CountryOption, 
        ShoppingAddressSpec, BillingAddress, 
         ShoppingCheckoutOrder, ShoppingOrder, 
        ShoppingSnappayPrepareRequest, ShoppingSnappayPrepareResponse, ShoppingSnappayCompleteRequest, 
          ShopUserInfo, ShoppingProductDetail, NutritionistLicenceInfo, 
        PhotoInfo, advancedSearchShoppingProductFilter, ShoppingCartInfo, ShoppingPickupAddress, 
        ShippingAddress, CheckoutRequest, ShoppingCheckoutResponse, CreateShopOrderRequest, 
        CreateShopOrderResponse, ShopPaymentRequestData, ShopOrderStatus, ShopOrderInfo, 
        ShopOrderCommissionStatus, ShopOrderCommissionInfo, LogisticCompanyInfo, 
        ShippingRecordInfo, PharmacistInfo, NutritionistBrief, CommissionDistributionRequest, ShopOrderCommissionDistributionReport, LabRequestInfoRec} from './ajax-data-types';
import { getLanguageCode } from "../../local-storage/local-storage";
import { PagedResponse } from "../../api/transaction";
import { dflt_CommissionStatusOptions, dflt_HealthAdviceStatusOptions, dflt_LastNDaysOptions,  
         dflt_ShoppingAddressSpecMap, dflt_ShoppingOrderStatusOptions,  
         generateSampleNutritionistList, 
         sampleShoppingOrder,          
        } from "./samples/sample-data";
import { SearchHealthAdviceFilter } from "../common/search-health-advice-form";


import * as _ from 'lodash';
import { getExternContantsInMemory, LANG_CODE_ZH_CN, loadExternContatnsIfNecessary } from "../../external-constants/extern-constants";
import { ShopOrderSearchFilter } from "../my-orders/shopping-order-list";

/**
 * 
 * @param accessToken 
 * @param languageCode
 * @returns product category tree 
 */
export async function getProductCategories(accessToken:string, languageCode:string){
    const serverURL = getServerURL();

    return processHttpOK<ProductCategory[]>(
        axios.get(`${serverURL}/server/v1/shop/productCategories`,
                  {headers: {'Authorization': "Bearer " + accessToken,
                             "accept-language":languageCode
                            }
                   }
                 )
    );
}

export async function searchShoppingProducts (accessToken:string, page:number, pageSize:number,
                                              thumbnailWidth:number,
                                              filters:{
                                                  searchText?: string, categoryName?: string
                                              },
                                              currencyCode:string) {
    const serverURL = getServerURL();
    let languageCode = getLanguageCode();

    return processHttpOK<PagedResponse<ProductCandidateBrief>> (
        axios.post(`${serverURL}/server/v1/shop/query/productSpec`, 
                   {categoryName: filters.categoryName,
                    searchText: filters.searchText,
                    page: page,
                    size: pageSize,
                    thumbnailWidth: thumbnailWidth
                   }, 
                   {headers: {'Authorization': "Bearer " + accessToken,
                                "accept-language":languageCode,
                                'Currency-Code': currencyCode
                            }
                    })
    )
}

export async function getLabTestProducts(accessToken:string,
                                         currencyCode:string){
    const serverURL = getServerURL();
    let languageCode = getLanguageCode();

    return processHttpOK<ProductCandidateBrief[]> (
        axios.get(`${serverURL}/server/v1/shop/labProductSpecs`, 
                   {headers: {'Authorization': "Bearer " + accessToken,
                                "accept-language":languageCode,
                                'Currency-Code': currencyCode
                            }
                    })
    )    
}

/**
 * advanced search used by nutritionist.
 * 
 * @param accessToken 
 * @param filters 
 */
export async function advancedSearchShoppingProducts(accessToken: string, 
                                                     filters: advancedSearchShoppingProductFilter,
                                                     currencyCode:string) {
    const serverURL = getServerURL();
    let languageCode = getLanguageCode();

    return processHttpOK<PagedResponse<ProductCandidateBrief>> (
        axios.post(`${serverURL}/server/v1/shop/search/productSpec`, 
                    filters, 
                   {headers: {'Authorization': "Bearer " + accessToken,
                                "accept-language":languageCode,
                                'Currency-Code': currencyCode
                            }
                    })
    );
}


export function getCurrencyOptionsMap(){
    return getExternContantsInMemory().currencyOptionsMap;
}

export async function saveShoppingCurrency(accessToken:string, currencyCode:string){
    const serverURL = getServerURL();
    let languageCode = getLanguageCode();

    let shopUserInfo = await processHttpOK<ShopUserInfo>(
        axios.get(`${serverURL}/server/v1/shop/user`,
                    {headers: {'Authorization': "Bearer " + accessToken,
                               "accept-language":languageCode
                              }
                    }
                  )
    );
    shopUserInfo.currencyCode = currencyCode;

    return await processHttpOK<ShopUserInfo>(
        axios.put(`${serverURL}/server/v1/shop/user`,
                    shopUserInfo,
                    {headers: {'Authorization': "Bearer " + accessToken,
                               "accept-language":languageCode
                              }
                    }
                  )
    );
}

export function getPaymentMethodOptionMap() {
    return (getExternContantsInMemory()).paymentMethodOptionsMap;
}

export function getWeightOptionsMap(){
    return getExternContantsInMemory().weightOptionsMap;
}

export async function retreiveShoppingProductDetail(accessToken:string,productCode:string, currencyCode:string){
    const serverURL = getServerURL();
    let languageCode = getLanguageCode();

    return await processHttpOK<ShoppingProductDetail> (
        axios.get(`${serverURL}/server/v1/shop/productSpec/${productCode}`,
                   {headers: {'Authorization': "Bearer " + accessToken,
                                "accept-language":languageCode,
                                'Currency-Code': currencyCode
                            }
                    })
    )
}

export async function retreiveShoppingProductDetailByBarCode(accessToken:string,barCode:string){
    const serverURL = getServerURL();
    let languageCode = getLanguageCode();

    return await processHttpOK<{productCode:string, productDocumentId:string, productType:string}> (
        axios.get(`${serverURL}/server/v1/barcode/${barCode}`,
                   {headers: {'Authorization': "Bearer " + accessToken,
                                "accept-language":languageCode,
                            }
                    })
    )
}


let _myNutritionist:NutritionistInfo|undefined;

export async function getMyShopUserInfo(accessToken:string){
    let serverURL = getServerURL();
    let languageCode = getLanguageCode();

    let shopUserInfo = await processHttpOK<ShopUserInfo>(
        axios.get(`${serverURL}/server/v1/shop/user`,
                    {headers: {'Authorization': "Bearer " + accessToken,
                                "accept-language":languageCode
                              }
                    }
                 )
        )
    return shopUserInfo;
}

export async function updateShopUserInfo(accessToken:string, shopUserInfo: ShopUserInfo){
    let serverURL = getServerURL();
    let languageCode = getLanguageCode();

    return await processHttpOK<ShopUserInfo>(
                     axios.put(`${serverURL}/server/v1/shop/user`, 
                                shopUserInfo, 
                                {
                                    headers: {
                                        'Authorization': "Bearer " + accessToken,
                                        "accept-language":languageCode
                                    }
                                }
                              )
                 )
}

/**
 * retrieve my nutritionist profile 
 * @param accessToken access token of client
 */
export async function retrieveMyNutritionist(accessToken:string, nutritionistUsername:string){
    let serverURL = getServerURL();
    let languageCode = getLanguageCode();

    // let shopUserInfo = await processHttpOK<ShopUserInfo>(
    // axios.get(`${serverURL}/server/v1/shop/user`,
    //             {headers: {'Authorization': "Bearer " + accessToken,
    //                         "accept-language":languageCode
    //                         }
    //             }
    //             )
    // )
    // if(!shopUserInfo.nutritionist){
    //     return undefined;
    // }
    let nutritionist = await processHttpOK<NutritionistInfo>( 
                                axios.get(`${serverURL}/server/v1/nutritionist/${encodeURIComponent(nutritionistUsername)}`, 
                                            {headers: {'Authorization': "Bearer " + accessToken,
                                                        "accept-language":languageCode
                                                        }
                                            })
                                );
    return nutritionist;  

    // return asyncDelay(  {   
    //                         nutritionist: _myNutritionist
    //                         ,setTimestamp: _myNutritionist ? (Date.now()-1000*60*60*24): undefined
    //                     }, 400)
}
export async function setMyNutritionist(accessToken:string, id:string){
    let nutritionist = generateSampleNutritionistList().find(x=> x.username === id)!;
    _myNutritionist = nutritionist;
    await asyncDelay(undefined, 400);
}


export async function retrieveNutritionist(accessToken:string, username:string){
    const serverURL = getServerURL();
    let languageCode = getLanguageCode();

    let nutritionist = await processHttpOK<NutritionistInfo>( 
            axios.get(`${serverURL}/server/v1/nutritionist/${encodeURIComponent(username)}`, 
                        {headers: {'Authorization': "Bearer " + accessToken,
                                    "accept-language":languageCode
                                    }
                        })
            );
    return nutritionist;        

    // let nutritionist = generateSampleNutritionistList().find(x=> x.username === id)!;
    // return asyncDelay(  {   
    //                         nutritionist: nutritionist
    //                         ,setTimestamp: _myNutritionist&& _myNutritionist.username===nutritionist.username ? (Date.now()-1000*60*60*24): undefined // optional
    //                     }, 400)
}

/**
 * 
 * @param accessToken 
 * @param photoFile 
 * @param fieldName field name that identify the image data. e.g photo.
 */
export async function savePhoto(accessToken:string, photoFile:File, fieldName:string, tagValue: string){
    const serverURL = getServerURL();
    let languageCode = getLanguageCode();

    const data = new FormData();
    data.append(fieldName, photoFile, photoFile.name);
    data.append('tag', tagValue);

    let photoInfo = await processHttpOK<PhotoInfo>(
            axios.post(`${serverURL}/server/v1/photo`, 
                        data,
                        {headers: {'Authorization': "Bearer " + accessToken,
                                    "accept-language":languageCode,
                                    "Content-Type": "multipart/form-data"
                                    }
                        })
        );
    return photoInfo;    
}

export async function saveNutritionistLicence(accessToken:string, data:NutritionistLicenceInfo){
    const serverURL = getServerURL();
    let languageCode = getLanguageCode();

    let licence = await processHttpOK<NutritionistLicenceInfo>( 
        axios.post(`${serverURL}/server/v1/nutritionistLicense`, 
                    data,
                    {headers: {'Authorization': "Bearer " + accessToken,
                                "accept-language":languageCode
                                }
                    })
        );
    return licence;        
}

export async function saveNutritionist(accessToken:string, data:NutritionistInfo){
    const serverURL = getServerURL();
    let languageCode = getLanguageCode();

    let nutritionist = await processHttpOK<NutritionistInfo>( 
            axios.post(`${serverURL}/server/v1/nutritionist`, 
                        data,
                        {headers: {'Authorization': "Bearer " + accessToken,
                                    "accept-language":languageCode
                                    }
                        })
            );
    return nutritionist;        

}

export interface NutritionistSearchFilter {
    text?:string
    gender?: string
    status?: string
}

export async function searchNutritionists(accessToken:string, page: number, pageSize:number,
                                          filters:NutritionistSearchFilter)
{
    const serverURL = getServerURL();
    let languageCode = getLanguageCode();
  
    let list = await processHttpOK<PagedResponse<NutritionistBrief>> (
        axios.post(`${serverURL}/server/v1/nutritionist/search?page=${page}&size=${pageSize}`,
                    filters,
                   {headers: {'Authorization': "Bearer " + accessToken,
                                "accept-language":languageCode
                            }
                    })
    )
    return list;




    // let sampleList = generateSampleNutritionistList();
    // if(filters.searchText){
    //     sampleList = sampleList.filter(x=> JSON.stringify(x).indexOf(filters.searchText!)>=0);
    // }
    
    // let list = sampleList.slice((page)*pageSize, (page+1)*pageSize);
    // let result: PagedResponse<NutritionistBrief> = {
    //     totalElements: sampleList.length,
    //     page,
    //     size:pageSize,
    //     totalPages: Math.ceil(sampleList.length/pageSize),
    //     last:false,
    //     content: list
    // };

    // return await asyncDelay(result, 500);
}

export interface NutritionistClientFilter {
    text?:string,
    active?: boolean,
}
export async function searchMyClients(accessToken:string, page: number, pageSize:number,
                                      filters:NutritionistClientFilter)
{
    const serverURL = getServerURL();
    let languageCode = getLanguageCode();
    return await processHttpOK<PagedResponse<NutritionistClient>>(
              axios.post(`${serverURL}/server/v1/nutritionistClient/query/myClients?page=${page}&size=${pageSize}`, 
                        filters,
                        {headers:   {'Authorization': "Bearer " + accessToken,
                                        "accept-language":languageCode
                                    }
                        })
          );

    // let sampleList = genSampleClients();
    // if (filters.searchText) {
    //     sampleList = sampleList.filter(x => JSON.stringify(x).indexOf(filters.searchText!) >= 0);
    // }

    // let list = sampleList.slice((page) * pageSize, (page + 1) * pageSize);
    // let result: PagedResponse<ClientOfNutritionist> = {
    //     totalElements: sampleList.length,
    //     page,
    //     size: pageSize,
    //     totalPages: Math.ceil(sampleList.length / pageSize),
    //     last: false,
    //     content: list
    // };

    // return await asyncDelay(result, 500);
}

export async function updateNutritionistClient(accessToken:string, client:NutritionistClient){
    const serverURL = getServerURL();
    let languageCode = getLanguageCode();
    return await processHttpOK<NutritionistClient>(
              axios.put(`${serverURL}/server/v1/nutritionistClient/${encodeURIComponent(client.clientUsername)}`, 
                        client,
                        {headers:   {'Authorization': "Bearer " + accessToken,
                                        "accept-language":languageCode
                                    }
                        })
          );
}


export async function retrieveClient(accessToken:string, clientUsername:string){
    const serverURL = getServerURL();
    let languageCode = getLanguageCode();
    return await processHttpOK<NutritionistClient>(
              axios.get(`${serverURL}/server/v1/nutritionistClient/${encodeURIComponent(clientUsername)}`, 
                        {headers:   {'Authorization': "Bearer " + accessToken,
                                        "accept-language":languageCode
                                    }
                        })
          );

          // let sampleList = genSampleClients();
    // if(clientEmail === 'zhang876@163.com'){
    //     return asyncDelay(sampleList[0], 400);
    // }else{
    //     return asyncDelay(sampleList[1], 400);
    // }
}

export async function createHealthAdvice(accessToken: string, data:NutritionistHealthAdvice){
    const serverURL = getServerURL();
    let languageCode = getLanguageCode();

    return await processHttpOK<NutritionistHealthAdvice>(
        axios.post(`${serverURL}/server/v1/prescription`, 
                   data,
                  {headers:   {'Authorization': "Bearer " + accessToken,
                                  "accept-language":languageCode
                              }
                  })
    );
}


export function getHealthAdviceStatuOptions(accessToken:string, languageCode:string){
    const externConstants = getExternContantsInMemory();
    return externConstants.healthAdviceStatusOptions[languageCode] || externConstants.healthAdviceStatusOptions[LANG_CODE_ZH_CN];
}


export interface NutritionistPrescriptionFilter {
    state?: string;
    text?: string;
    clientUsername?: string;
}

export async function searchHealthAdviceByMe(accessToken:string, page: number, pageSize:number,
                                            filters:NutritionistPrescriptionFilter)
{
    const serverURL = getServerURL();
    let languageCode = getLanguageCode();

    return await processHttpOK<PagedResponse<NutritionistHealthAdvice>>(
        axios.post(`${serverURL}/server/v1/prescription/query/fromNutritionist?page=${page}&size=${pageSize}`,
                  {
                      text: filters.text,
                      state: filters.state? [filters.state] : undefined,
                      clientUsername: filters.clientUsername
                  }, 
                  {headers:   {'Authorization': "Bearer " + accessToken,
                                  "accept-language":languageCode
                              }
                  })
    );
/*
    let sampleList = await genSampleHealthAdviceByMe(getLanguageCode());
    if (filters.keywords) {
        sampleList = sampleList.filter(x => JSON.stringify(x).indexOf(filters.keywords!) >= 0);
    }

    let list = sampleList.slice((page) * pageSize, (page + 1) * pageSize);
    let result: PagedResponse<NutritionistHealthAdvice> = {
        totalElements: sampleList.length,
        page,
        size: pageSize,
        totalPages: Math.ceil(sampleList.length / pageSize),
        last: false,
        content: list
    };

    return await asyncDelay(result, 500);
*/    
}

export interface ClientPrescriptionFilter {
    text?:string, 
    state?:string,
    nutritionistUsername?: string
}

export async function searchClientHealthAdvice(accessToken: string, page: number, pageSize: number,
                                                filters: ClientPrescriptionFilter) {

    const serverURL = getServerURL();
    let languageCode = getLanguageCode();

    return await processHttpOK<PagedResponse<ClientHealthAdvice>>(
        axios.post(`${serverURL}/server/v1/prescription/query/fromClient?page=${page}&size=${pageSize}`, 
                    {
                        text: filters.text,
                        state: filters.state ? [filters.state] : undefined
                    },
                    {headers:   {'Authorization': "Bearer " + accessToken,
                                    "accept-language":languageCode
                                }
                    })
    );

    /*
    let sampleList = await genSampleClientHealthAdvice(getLanguageCode());
    if (filters.keywords) {
        sampleList = sampleList.filter(x => JSON.stringify(x).indexOf(filters.keywords!) >= 0);
    }

    let list = sampleList.slice((page) * pageSize, (page + 1) * pageSize);
    list = _.cloneDeep(list);
    list.forEach(x=>{
        // @ts-ignore
        x.client = undefined;
    })

    let result: PagedResponse<ClientHealthAdvice> = {
        totalElements: sampleList.length,
        page,
        size: pageSize,
        totalPages: Math.ceil(sampleList.length / pageSize),
        last: false,
        content: list
    };

    return await asyncDelay(result, 500);
    */
}

export async function cancelHealthAdvice(accessToken: string, prescriptionCode:string){
    const serverURL = getServerURL();
    let languageCode = getLanguageCode();

    return await processHttpOK<NutritionistHealthAdvice>(
        axios.put(`${serverURL}/server/v1/prescription/void/${prescriptionCode}`,
                    {}, 
                    {headers:   {'Authorization': "Bearer " + accessToken,
                                    "accept-language":languageCode
                                }
                    })
    );

}

export async function getMyShoppingCart(accessToken: string, 
                                        username:string,
                                        currencyCode:string):Promise<ShoppingCartInfo> {
    const serverURL = getServerURL();
    let languageCode = getLanguageCode();

    return await processHttpOK<ShoppingCartInfo>(
        axios.get(`${serverURL}/server/v1/shop/cart/${encodeURIComponent(username)}`,
                    {headers:   {'Authorization': "Bearer " + accessToken,
                                    "accept-language":languageCode,
                                    "Currency-Code": currencyCode
                                }
                    })
    );
}

export async function addProductToShoppingCard(accessToken: 
                                               string, 
                                               username:string, 
                                               productCode:string, 
                                               count:number,
                                               currencyCode:string){
    const serverURL = getServerURL();
    let languageCode = getLanguageCode();

    return await processHttpOK<ShoppingCartInfo>(
        axios.patch(`${serverURL}/server/v1/shop/cart/item/${encodeURIComponent(username)}`,
                    {productCode, count},
                    {headers:   {'Authorization': "Bearer " + accessToken,
                                    "accept-language":languageCode,
                                    'Currency-Code': currencyCode
                                }
                    })
    );
}

export async function setProductToShoppingCard(accessToken: string, 
                                               username:string, 
                                               productCode:string, 
                                               count:number,
                                               currencyCode:string){
    const serverURL = getServerURL();
    let languageCode = getLanguageCode();

    return await processHttpOK<ShoppingCartInfo>(
        axios.put(`${serverURL}/server/v1/shop/cart/item/${encodeURIComponent(username)}`,
                    {productCode, count},
                    {headers:   {'Authorization': "Bearer " + accessToken,
                                    "accept-language":languageCode,
                                    'Currency-Code': currencyCode
                                }
                    })
    );
}


export async function addHealthAdviceToShoppingCard(accessToken: string, 
                                                    username:string, 
                                                    prescriptionCode:string,
                                                    currencyCode:string){
    const serverURL = getServerURL();
    let languageCode = getLanguageCode();

    return await processHttpOK<ShoppingCartInfo>(
        axios.patch(`${serverURL}/server/v1/shop/cart/item/${encodeURIComponent(username)}`,
                    {prescriptionCode, count:1},
                    {headers:   {'Authorization': "Bearer " + accessToken,
                                    "accept-language":languageCode,
                                    'Currency-Code': currencyCode
                                }
                    })
    );
}

export async function removeHealthAdviceToShoppingCard(accessToken: string, 
                                                       username:string, 
                                                       prescriptionCode:string,
                                                       currencyCode:string){
    const serverURL = getServerURL();
    let languageCode = getLanguageCode();

    return await processHttpOK<ShoppingCartInfo>(
        axios.put(`${serverURL}/server/v1/shop/cart/item/${encodeURIComponent(username)}`,
                    {prescriptionCode, count:0},
                    {headers:   {'Authorization': "Bearer " + accessToken,
                                    "accept-language":languageCode,
                                    'Currency-Code': currencyCode
                                }
                    })
    );
}


export async function calcShippingCostOfShoppingCart(accessToken:string, currencyCode:string){
    return asyncDelay(15, 400);
}

export function getCountryOptions(accessToken:string, languageCode:string):CountryOption[] {
    const externConstants = getExternContantsInMemory();
    return externConstants.countryOptionsMap[languageCode] || externConstants.countryOptionsMap[LANG_CODE_ZH_CN];
}

export function getAddressSpecMap(accessToken:string, languageCode:string):{[key:string]:ShoppingAddressSpec} {
    const externConstants = getExternContantsInMemory();
    return externConstants.shoppingAddressSpecMap[languageCode] || externConstants.shoppingAddressSpecMap[LANG_CODE_ZH_CN];
}

export async function getMyBillingAddresses(accessToken:string){
    const serverURL = getServerURL();
    let languageCode = getLanguageCode();

    return await processHttpOK<BillingAddress[]>(
        axios.get(`${serverURL}/server/v1/shop/billingAddress`,
                    {headers:   {'Authorization': "Bearer " + accessToken,
                                    "accept-language":languageCode
                                }
                    })
    );
    // return asyncDelay(sampleBillingAddresses, 400);
}
export async function createBillingAddress(accessToken:string, addr:BillingAddress){
    const serverURL = getServerURL();
    let languageCode = getLanguageCode();

    return await processHttpOK<BillingAddress>(
        axios.put(`${serverURL}/server/v1/shop/billingAddress`,
                    addr,
                    {headers:   {'Authorization': "Bearer " + accessToken,
                                    "accept-language":languageCode
                                }
                    })
    );
}

export async function updateBillingAddress(accessToken:string, addr:BillingAddress){
    const serverURL = getServerURL();
    let languageCode = getLanguageCode();

    return await processHttpOK<BillingAddress>(
        axios.put(`${serverURL}/server/v1/shop/billingAddress`,
                    addr,
                    {headers:   {'Authorization': "Bearer " + accessToken,
                                    "accept-language":languageCode
                                }
                    })
    );
}

export async function getPickupLocation(accessToken:string, addressId:string){
    const serverURL = getServerURL();
    let languageCode = getLanguageCode();

    let response = await processHttpOK<ShoppingPickupAddress>(
        axios.get(`${serverURL}/server/v1/shop/pickupLocation/${encodeURIComponent(addressId)}`,
                    {headers:   {'Authorization': "Bearer " + accessToken,
                                    "accept-language":languageCode
                                }
                    })
    );
    return response;
}

export async function getPickupAddresses(accessToken:string, filter?: {page:number, pageSize: number}){
    //     return asyncDelay(samplePickupAddresses, 400);
    let _filter = filter || {page: 0, pageSize: 10};
    const {page, pageSize} = _filter;

    const serverURL = getServerURL();
    let languageCode = getLanguageCode();

    let response = await processHttpOK<PagedResponse<ShoppingPickupAddress>>(
        axios.get(`${serverURL}/server/v1/shop/pickupLocation?page=${page}&size=${pageSize}`,
                    {headers:   {'Authorization': "Bearer " + accessToken,
                                    "accept-language":languageCode
                                }
                    })
    );
    return response;
}



export async function deleteBillingAddress(accessToken:string, addrId:string){
    const serverURL = getServerURL();
    let languageCode = getLanguageCode();

    return await processHttpOK<void>(
        axios.delete(`${serverURL}/server/v1/shop/billingAddress/${encodeURIComponent(addrId)}`,
                    {headers:   {'Authorization': "Bearer " + accessToken,
                                    "accept-language":languageCode
                                }
                    })
    );
}

export async function getMyShippingAddresses(accessToken:string){
    const serverURL = getServerURL();
    let languageCode = getLanguageCode();

    return await processHttpOK<ShippingAddress[]>(
        axios.get(`${serverURL}/server/v1/shop/shippingAddress`,
                    {headers:   {'Authorization': "Bearer " + accessToken,
                                 "accept-language":languageCode
                                }
                    })
    );

    // return asyncDelay(sampleShippingAddresses, 400);
}

export async function createShippingAddress(accessToken:string, addr:ShippingAddress){
    const serverURL = getServerURL();
    let languageCode = getLanguageCode();

    return await processHttpOK<ShippingAddress>(
        axios.put(`${serverURL}/server/v1/shop/shippingAddress`,
                    addr,
                    {headers:   {'Authorization': "Bearer " + accessToken,
                                    "accept-language":languageCode
                                }
                    })
    );
}

export async function updateShippingAddress(accessToken:string, addr:ShippingAddress){
    const serverURL = getServerURL();
    let languageCode = getLanguageCode();

    return await processHttpOK<ShippingAddress>(
        axios.put(`${serverURL}/server/v1/shop/shippingAddress`,
                    addr,
                    {headers:   {'Authorization': "Bearer " + accessToken,
                                    "accept-language":languageCode
                                }
                    })
    );
}


export async function deleteShippingAddress(accessToken:string, addrId:string){
    const serverURL = getServerURL();
    let languageCode = getLanguageCode();

    return await processHttpOK<void>(
        axios.delete(`${serverURL}/server/v1/shop/shippingAddress/${encodeURIComponent(addrId)}`,
                    {headers:   {'Authorization': "Bearer " + accessToken,
                                    "accept-language":languageCode
                                }
                    })
    );
}


export async function getConfirmationOrder(accessToken:string,username:string, 
                                           request:CheckoutRequest,
                                           currencyCode:string){
    const serverURL = getServerURL();
    let languageCode = getLanguageCode();

    return await processHttpOK<ShoppingCheckoutResponse>(
        axios.post(`${serverURL}/server/v1/shop/cart/checkout/${encodeURIComponent(username)}`,
                    request,
                    {headers:   {'Authorization': "Bearer " + accessToken,
                                 "accept-language":languageCode,
                                 "Currency-Code": currencyCode
                                }
                    })
    );


    /*
    const checkoutOrder:ShoppingCheckoutOrder = {
        ... _.cloneDeep(sampleShoppingCart),
        billingAddress,
        shippingMethod,
        paymentMethod
    };
    if(shippingMethod.type === 'courier'){
        checkoutOrder.summary.shippingCost = 16;
        checkoutOrder.summary.total += 16;
    }
    if(paymentMethod === 'Wallet'){
        checkoutOrder.summary.discounts = (checkoutOrder.summary.discounts||[]);
        checkoutOrder.summary.discounts!.push({
            discount: checkoutOrder.summary.subTotal / 10,
            discountDescription: `U点支付10%折扣`
        })
        checkoutOrder.summary.subTotal -= checkoutOrder.summary.subTotal / 10;

        checkoutOrder.summary.currencyCode = 'UNIS';
        for(let item of (checkoutOrder.items || [])){
            if(item.type === 'PRODUCT'){
                item.currencyCode = 'UNIS';
            }else if(item.type === 'HEALTH_ADVICE'){
                (item.products || []).forEach(x=>{
                    x.currencyCode = 'UNIS';
                })
            }
        }
    }
    checkoutOrder.summary.salesTax = checkoutOrder.summary.subTotal * 0.13;
    checkoutOrder.summary.total *= 1.13;
    
    return asyncDelay(checkoutOrder, 500);
    */
}

export function saveShoppingCheckoutOrder(accessToken:string, checkoutOrder:ShoppingCheckoutOrder){
    return asyncDelay({success: true, message: 'OK'}, 500);
}

export function retrieveShoppingCartOrder(accessToken:string):Promise<ShoppingOrder>{
    return asyncDelay(sampleShoppingOrder, 500);
}

export async function ShoppingUnisPay(accessToken:string, request:CreateShopOrderRequest) {
    const serverURL = getServerURL();
    let languageCode = getLanguageCode();

    return await processHttpOK<CreateShopOrderResponse>(
        axios.post(`${serverURL}/server/v1/shop/order`,
                    request,
                    {headers:   {'Authorization': "Bearer " + accessToken,
                                    "accept-language":languageCode
                                }
                    })
    );
}

export async function prepareShoppingPayment(accessToken:string, request:ShopPaymentRequestData){
    const serverURL = getServerURL();
    let languageCode = getLanguageCode();

    return await processHttpOK<CreateShopOrderResponse>(
        axios.post(`${serverURL}/server/v1/shop/order`,
                    request,
                    {headers:   {'Authorization': "Bearer " + accessToken,
                                    "accept-language":languageCode
                                }
                    })
    );
}

export async function completeShoppingStripePayment(accessToken:string, 
                                                    request:{isSuccess: boolean, orderCode:string, result?: string}){
    const serverURL = getServerURL();
    let languageCode = getLanguageCode();

    return await processHttpOK<PaymentResponse>(
        axios.post(`${serverURL}/server/v1/shop/order/completeStripe`,
                    request,
                    {headers:   {'Authorization': "Bearer " + accessToken,
                                    "accept-language":languageCode
                                }
                    })
    );
}

export async function completeShoppingSnappay(accessToken:string, request:ShoppingSnappayCompleteRequest){
    const serverURL = getServerURL();
    return processHttpOK<PaymentResponse>(
        axios.post(`${serverURL}/server/v1/shop/order/completeSnappay`, request, 
                    {headers: {'Authorization': "Bearer " + accessToken,"accept-language":getLanguageCode()}})
    )
}


export async function searchShoppingOrders (accessToken:string, 
                                            page: number, pageSize: number,
                                            thumbnailWidth: number,
                                            currencyCode:string,
                                            filter:ShopOrderSearchFilter) 
{
    const serverURL = getServerURL();
    let languageCode = getLanguageCode();

    const {text, status, fromTime, toTime} = filter; 

    return processHttpOK<PagedResponse<ShopOrderInfo>> (
        axios.post(`${serverURL}/server/v1/shop/order/search?thumbnailWidth=${thumbnailWidth}&page=${page}&size=${pageSize}`, 
                    { 
                        text, fromTime, toTime, 
                        status: status ? [status] : undefined
                    },
                    {headers: {'Authorization': "Bearer " + accessToken,
                                "accept-language":languageCode,
                                'Currency-Code': currencyCode
                            }
                    })
    )
}

export async function labTestSampleReceived(accessToken:string, requestCode: string){
    const serverURL = getServerURL();
    let languageCode = getLanguageCode();

    return processHttpOK<LabRequestInfoRec> (
        axios.post(`${serverURL}/server/v1/lab/request/${requestCode}/sampleReceived`, 
                    {},
                    {headers: {'Authorization': "Bearer " + accessToken,
                                "accept-language":languageCode,
                            }
                    })
    )
}

export async function getShoppingOrder(accessToken:string, orderCode:string, thumbnailWidth: number){
    const serverURL = getServerURL();
    let languageCode = getLanguageCode();

    return processHttpOK<ShopOrderInfo> (
        axios.get(`${serverURL}/server/v1/shop/order/${encodeURIComponent(orderCode)}?thumbnailWidth=${thumbnailWidth}`, 
                   {headers: {'Authorization': "Bearer " + accessToken,
                                "accept-language":languageCode,
                            }
                    })
    )
}

export interface ShopOrderQueryFilter {
    nutritionistUsername?: string;
    fromTime?: number;
    toTime?: number;
    clientUsername?: string;
    status?: string[];
    text?: string;
}

export async function searchShoppingOrdersByAdmin (accessToken:string, 
                                            page:number, 
                                            pageSize:number,
                                            thumbnailWidth: number,
                                            currencyCode:string,
                                            filters?:ShopOrderQueryFilter) 
{
    const serverURL = getServerURL();
    let languageCode = getLanguageCode();

    return processHttpOK<PagedResponse<ShopOrderInfo>> (
        axios.post(`${serverURL}/server/v1/shop/order/search?thumbnailWidth=${thumbnailWidth}&page=${page}&size=${pageSize}`,
                   filters || {},
                   {headers: {'Authorization': "Bearer " + accessToken,
                                "accept-language":languageCode,
                                'Currency-Code': currencyCode
                            }
                    })
    )
}

export async function searchShoppingOrdersByPharmacist (accessToken:string, 
                                            page:number, 
                                            pageSize:number,
                                            thumbnailWidth: number,
                                            currencyCode:string,
                                            filters:ShopOrderQueryFilter) 
{
    const serverURL = getServerURL();
    let languageCode = getLanguageCode();

    return processHttpOK<PagedResponse<ShopOrderInfo>> (
        axios.post(`${serverURL}/server/v1/shop/order/myPharmacy?thumbnailWidth=${thumbnailWidth}&page=${page}&size=${pageSize}`,
                   filters,
                   {headers: {'Authorization': "Bearer " + accessToken,
                                "accept-language":languageCode,
                                'Currency-Code': currencyCode
                            }
                    })
    )
}

export function getShoppingOrderStatuOptions(accessToken:string, languageCode:string){
    const externConstants = getExternContantsInMemory();
    return externConstants.shoppingOrderStatusOptions[languageCode] || externConstants.shoppingOrderStatusOptions[LANG_CODE_ZH_CN];
}

/** @deprecated */
export function getLastNDaysOptions(accessToken:string, languageCode:string){
    const externConstants = getExternContantsInMemory();
    return externConstants.lastNDaysOptions[languageCode] || externConstants.lastNDaysOptions[LANG_CODE_ZH_CN];
}

export function getCommissionStatuOptions(accessToken:string, languageCode:string){
    const externConstants = getExternContantsInMemory();
    return externConstants.commissionStatusOptions[languageCode] || externConstants.lastNDaysOptions[LANG_CODE_ZH_CN];
}

export function getShippingRecordStatusOptions(accessToken:string, language:string){
    const externConstants = getExternContantsInMemory();
    return externConstants.shippingRecordStatusOptions[language] || externConstants.shippingRecordStatusOptions[LANG_CODE_ZH_CN];
}
export function getLicenceCategoryOptions(language:string){
    const externConstants = getExternContantsInMemory();
    return externConstants.licenceCategoryOptions[language] || externConstants.licenceCategoryOptions[LANG_CODE_ZH_CN];
}

export async function searchMyShopOrderCommissions(
                        accessToken:string, 
                        page:number, 
                        pageSize:number,
                        thumbnailWidth: number,
                        filters?: {
                            commissionStatus?: ShopOrderCommissionStatus,
                            fromTime?: number,
                            toTime?: number
                        }
                                                ){
    const serverURL = getServerURL();
    let languageCode = getLanguageCode();
    let qstring = `thumbnailWidth=${thumbnailWidth}&page=${page}&size=${pageSize}`;
    if(filters){
        if(filters.commissionStatus){
            qstring = qstring+`&status=${filters.commissionStatus}`;
        }
        if(filters.fromTime){
            qstring = qstring+`&fromTime=${filters.fromTime}`;
        }
        if(filters.toTime){
            qstring = qstring+`&toTime=${filters.toTime}`;
        }
    }

    return processHttpOK<PagedResponse<ShopOrderCommissionInfo>> (
        axios.get(`${serverURL}/server/v1/shop/orderCommission?${qstring}`, 
                    {headers: {'Authorization': "Bearer " + accessToken,
                                "accept-language":languageCode,
                            }
                    })
    )
                                                
}

/**
 * Search all shopping order commissions used by Admin.
 * 
 * @param accessToken 
 * @param page 
 * @param pageSize 
 * @param thumbnailWidth 
 * @param filters 
 */
export async function searchAllShopOrderCommissions(accessToken: string,
                                                    page: number,
                                                    pageSize: number,
                                                    thumbnailWidth: number,
                                                    filters?: {
                                                        commissionStatus?: ShopOrderCommissionStatus,
                                                        fromTime?: number,
                                                        toTime?: number
                                                    }
                                                ) 
{
    const serverURL = getServerURL();
    let languageCode = getLanguageCode();
    let qstring = `thumbnailWidth=${thumbnailWidth}&page=${page}&size=${pageSize}`;
    if (filters) {
        if (filters.commissionStatus) {
            qstring = qstring + `&status=${filters.commissionStatus}`;
        }
        if (filters.fromTime) {
            qstring = qstring + `&fromTime=${filters.fromTime}`;
        }
        if (filters.toTime) {
            qstring = qstring + `&toTime=${filters.toTime}`;
        }
    }

    return processHttpOK<PagedResponse<ShopOrderCommissionInfo>> (
        axios.get(`${serverURL}/server/v1/shop/orderCommission/all?${qstring}`, 
                    {headers: {'Authorization': "Bearer " + accessToken,
                                "accept-language":languageCode,
                            }
                    }
                 )
    )
                            
}


export async function getShopOrderCommissionDistributionReport(accessToken:string, thumbnailWidth: number){
    const serverURL = getServerURL();
    let languageCode = getLanguageCode();

    return processHttpOK<ShopOrderCommissionDistributionReport>(
        axios.get(`${serverURL}/server/v1/shop/orderCommission/distributionReport?thumbnailWidth=${thumbnailWidth}`,
                    {headers:  {
                                'Authorization': "Bearer " + accessToken,
                                    "accept-language":languageCode,
                                }
                    }
                  )
    )
}

export async function distributeShopOrderCommissions(accessToken:string, request:CommissionDistributionRequest){
    const serverURL = getServerURL();
    let languageCode = getLanguageCode();

    return processHttpOK<any>(
        axios.post(`${serverURL}/server/v1/shop/orderCommission/distribute`,
                    request,
                    {headers:  {
                                'Authorization': "Bearer " + accessToken,
                                    "accept-language":languageCode,
                                }
                    }
                  )
    )
}

export async function getCourierList(accessToken:string) {
    const serverURL = getServerURL();
    let languageCode = getLanguageCode();

    return processHttpOK<LogisticCompanyInfo[]> (
        axios.get(`${serverURL}/utils/logistics`,
                   {headers: {'Authorization': "Bearer " + accessToken,
                                "accept-language":languageCode,
                            }
                    })
    )
}

export async function addShippingTrackNumber(accessToken: string, 
                                             payload: {trackNumber:string, 
                                                       orderCode:string, 
                                                       logisticCompanyId:string
                                                       }
                                            ){
    const serverURL = getServerURL();
    let languageCode = getLanguageCode();

    return processHttpOK<ShippingRecordInfo> (
        axios.post(`${serverURL}/server/v1/shop/orderShippingRecord`,
                    payload,
                   {headers: {'Authorization': "Bearer " + accessToken,
                                "accept-language":languageCode,
                            }
                    })
    )
}

export async function deleteShippingRecord(accessToken:string, id:string){
    const serverURL = getServerURL();
    let languageCode = getLanguageCode();

    return processHttpOK<any>(
        axios.delete(`${serverURL}/server/v1/shop/orderShippingRecord/${encodeURIComponent(id)}`, 
                     {headers: {'Authorization': "Bearer " + accessToken,
                                "accept-language":languageCode,
                               }
                     }
                    )
    )
}

export async function updateShippingRecord(accessToken: string, rec:ShippingRecordInfo,){
    const serverURL = getServerURL();
    let languageCode = getLanguageCode();

    return processHttpOK<ShippingRecordInfo> (
        axios.put(`${serverURL}/server/v1/shop/orderShippingRecord`,
                    rec,
                   {headers: {'Authorization': "Bearer " + accessToken,
                                "accept-language":languageCode,
                            }
                    })
    )
}

export async function getPharmcistInfo (accessToken:string, username:string){
    const serverURL = getServerURL();
    let languageCode = getLanguageCode();

    return processHttpOK<PharmacistInfo> (
        axios.get(`${serverURL}/server/v1/shop/pharmacist/${encodeURIComponent(username)}`,
                   {headers: {'Authorization': "Bearer " + accessToken,
                                "accept-language":languageCode,
                            }
                    })
    )

}

export async function setReadyForPickup(accessToken:string, orderCode:string){
    const serverURL = getServerURL();
    let languageCode = getLanguageCode();

    return processHttpOK<any> (
        axios.post(`${serverURL}/server/v1/shop/order/myPharmacy/pickupReady/${encodeURIComponent(orderCode)}`,
                   {},
                   {headers: {'Authorization': "Bearer " + accessToken,
                                "accept-language":languageCode,
                            }
                    })
    )
}

export async function createPickupRecord(accessToken:string, orderCode:string, pharmacyId:string){
    const serverURL = getServerURL();
    let languageCode = getLanguageCode();

    return processHttpOK<any> (
        axios.post(`${serverURL}/server/v1/shop/orderPickupRecord`,
                   {orderCode, pharmacyId},
                   {headers: {'Authorization': "Bearer " + accessToken,
                                "accept-language":languageCode,
                            }
                    })
    )
}

export async function markOrderReadyForPickup(accessToken:string, orderCode:string, thumbnailWidth:number){
    const serverURL = getServerURL();
    let languageCode = getLanguageCode();

    return processHttpOK<ShopOrderInfo> (
        axios.post(`${serverURL}/server/v1/shop/order/myPharmacy/pickupReady/${orderCode}?thumbnailWidth=${thumbnailWidth}`,
                   {},
                   {headers: {'Authorization': "Bearer " + accessToken,
                                "accept-language":languageCode,
                            }
                    })
    )
}