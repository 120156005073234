// Generated by IcedCoffeeScript 108.0.11
(function () {
  var AES, C, CAST5, SlicerBuffer, WordArray, checksum2, ecc_pkcs5_unpad_data, export_key_pgp, get_cipher, import_key_pgp, triplesec, uint_to_buffer;
  C = require('./const').openpgp.symmetric_key_algorithms;
  triplesec = require('triplesec');
  AES = triplesec.ciphers.AES;
  CAST5 = require('./openpgp/cast5').CAST5;
  SlicerBuffer = require('./openpgp/buffer').SlicerBuffer;
  WordArray = triplesec.WordArray;
  uint_to_buffer = require('./util').uint_to_buffer;
  ecc_pkcs5_unpad_data = require('./pad').ecc_pkcs5_unpad_data;

  exports.get_cipher = get_cipher = function get_cipher(n) {
    var ret;
    n || (n = C.AES256);

    ret = function () {
      switch (n) {
        case C.AES128:
          return {
            klass: AES,
            key_size: 16
          };

        case C.AES192:
          return {
            klass: AES,
            key_size: 24
          };

        case C.AES256:
          return {
            klass: AES,
            key_size: 32
          };

        case C.CAST5:
          return {
            klass: CAST5,
            key_size: CAST5.keySize
          };

        default:
          throw new Error("unknown cipher: " + n);
      }
    }();

    ret.type = n;
    return ret;
  };

  exports.checksum2 = checksum2 = function checksum2(buf) {
    var i, res, _i, _ref;

    res = 0;

    for (i = _i = 0, _ref = buf.length; 0 <= _ref ? _i < _ref : _i > _ref; i = 0 <= _ref ? ++_i : --_i) {
      res = res + buf.readUInt8(i) & 0xffff;
    }

    return res;
  };

  exports.import_key_pgp = import_key_pgp = function import_key_pgp(msg, pkcs5_padding) {
    var checksum, cipher, err, key, ret, sb;

    if (pkcs5_padding == null) {
      pkcs5_padding = false;
    }

    sb = new SlicerBuffer(msg);
    ret = err = null;
    cipher = get_cipher(sb.read_uint8());
    key = sb.read_buffer(cipher.key_size);
    checksum = sb.read_uint16();
    err = checksum2(key) !== checksum ? new Error("Checksum mismatch") : pkcs5_padding ? ecc_pkcs5_unpad_data(msg, sb.offset()) : !sb.rem() ? null : new Error("Junk at the end of input");

    if (err != null) {
      throw err;
    }

    return new cipher.klass(WordArray.from_buffer(key));
  };

  exports.export_key_pgp = export_key_pgp = function export_key_pgp(algo_id, key) {
    var csum;
    csum = checksum2(key);
    return Buffer.concat([Buffer.from([algo_id]), key, uint_to_buffer(16, csum)]);
  };
}).call(this);