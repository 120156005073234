import * as React from 'react';
import {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import { PagedResponse } from '../../api/transaction';
import { loadExternContatnsIfNecessary } from '../../external-constants/extern-constants';
import { t } from '../../i18n';
import { WalletAppState } from '../../redux-store';
import { BaseUnishellPageState, renderInitErrorUnishellPage, renderInitUnishellPage, renderUnishellPage } from '../../unishell-page-common/unishell-page-common';
import { calcRationClass, formatDate, RatioXXS, scrollBodyToTop } from '../../util/Util';
import { ConfirmDialogView } from '../../view/confirm-dialog/ConfirmDialogView';
import { setShopUserInfo } from '../../view/header/HeaderView2';
import { NoticeDialog } from '../../view/NoticeDialog';
import { Toast } from '../../view/Toast';
import { HTMLDialog } from '../../wallet-server-api/html-dialog/html-dialog';
import { readLabTestInstruction } from '../../wallet-server-api/wallet-server-api';
import { UButton } from '../../widget/button/ubutton';
import { SimpleEmail } from '../../widget/email/email';
import { SimplePhoneNumber } from '../../widget/phone-number/phone-number';
import { PropertyListDataItem, PropertyListWidget } from '../../widget/property-list/property-list';
import { AddressInfo2Str, ClientHealthAdvice, getHealthAdviceProductType, } from '../ajax-data/ajax-data-types';
import { searchClientHealthAdvice, addHealthAdviceToShoppingCard, getHealthAdviceStatuOptions, ClientPrescriptionFilter } from '../ajax-data/shopping-server-api';
import { doAJAXCall } from '../common/ajax-call';
import { Pagination } from '../common/render-pagination';
import { ClientSearchHealthAdviceFilter, SearchHealthAdviceFilter, SearchHealthAdviceForm } from '../common/search-health-advice-form';
import { BaseShoppingPageProps, } from '../common/shopping-page-common';
import { gotoShoppingPathNSavePrevPath, updateCurrentPathParms } from '../history/shopping-history';

import './my-health-advice.scss';

export interface SearchClientHealthAdviceFilter extends ClientSearchHealthAdviceFilter{
    page?: number;
    pageSize?: number;
} 
interface SearchClientHealthAdviceProps extends BaseShoppingPageProps, SearchClientHealthAdviceFilter {
    currencyCode:string
}

export interface SearchClientHealthAdviceState extends BaseUnishellPageState, ClientSearchHealthAdviceFilter{
    statusOptions?:{key:string, text:string}[];
    page: number;
    pageSize: number;
    searchResult?:PagedResponse<ClientHealthAdvice>;
}

class SearchClientHealthAdviceView extends Component<SearchClientHealthAdviceProps, SearchClientHealthAdviceState>{
    private errorDialog:NoticeDialog|undefined;
    private searchFilterForm: SearchHealthAdviceForm|undefined;
    private toast: Toast | undefined;
    private healthAdviceToast: Toast | undefined; // used to notify apply health advice
    private confirmDialog:ConfirmDialogView | undefined;

    constructor(props: SearchClientHealthAdviceProps){
        super(props);
        let {state, text, page, pageSize} = this.props;

        this.state = {pageInitState:'PAGE_INIT', state, text, page:page||0, pageSize:pageSize||10}
    }

    getTitle(){
        return t('我的健康建议书');
    }

    render(){
        if(this.state.pageInitState === 'PAGE_INIT'){
            return <React.Fragment>
                        {renderInitUnishellPage(
                            {
                                title: this.getTitle(), 
                                className:'shopping-my-health-advice',
                                message:t('初始化...')
                            })
                        }
                        <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                        <Toast close={true} duration={2500} message='' ref={e=>{this.toast=e!}} toastType='success'/>
                        <Toast close={true} duration={2500} message='' ref={e=>{this.healthAdviceToast=e!}} 
                               toastType='info' />
                    </React.Fragment>
        }
        if(this.state.pageInitState === 'PAGE_LOAD_FAILED'){
            return <React.Fragment>
                        {renderInitErrorUnishellPage(
                            {
                                title: this.getTitle(), 
                                className:'shopping-my-health-advice',
                                errorMessage: this.state.initErrorMessage||t('初始化失败'),
                                onTryAgain: ()=>{ this.initializeAgain();    }
                            })
                        }
                        <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                        <Toast close={true} duration={2500} message='' ref={e=>{this.toast=e!}} toastType='success'/>
                        <Toast close={true} duration={2500} message='' ref={e=>{this.healthAdviceToast=e!}} 
                                            toastType='info' />
                    </React.Fragment>
        }
        // loading data succeeded
        return renderUnishellPage({
            title: this.getTitle(), 
            className:'shopping-my-health-advice',
            underTopHeader: this.renderSearchForm(),
            content:(
                <React.Fragment>
                    { this.renderSucceedContent() }
                    <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                    <ConfirmDialogView open={false} ref={e=>this.confirmDialog=e!} />
                    <Toast close={true} duration={2500} message='' ref={e=>{this.toast=e!}} toastType='success'/>
                    <Toast close={true} duration={2500} message='' ref={e=>{this.healthAdviceToast=e!}} 
                        toastType='info' />
                </React.Fragment>
            )
        })
    }

    renderSearchForm(){
        return (
            <div className='shopping-my-health-advice-search-form-wrapper'>
                <SearchHealthAdviceForm statusOptions={this.state.statusOptions!}
                                        text={this.state.text} state={this.state.state}
                                        ref={e => { this.searchFilterForm = e! }}
                                        doSearch={(filter)=>{
                                            this.loadPaginationData(this.state.page, this.state.pageSize);
                                         }}
                />
            </div>)
    }

    renderSucceedContent():JSX.Element {
        return (
            <div className='shopping-my-health-advice-main-pane'>
                <Pagination data={this.state.searchResult!} page={this.state.page} pageSize={this.state.pageSize} 
                            loadData={this.loadPaginationData}
                            changePageSize={(newPageSize, newPage)=>{
                                this.loadPaginationData(newPage, newPageSize);
                            }}
                >    
                { this.renderHealthAdviceList() }
                </Pagination>

            </div>
        )
    }

    addToShoppingCart(advice:ClientHealthAdvice){
        this.confirmDialog!.show({
            title: '友情提示',
            message: <React.Fragment>
                        {t('apply_health_advice_msg').split('\n').map(l=><p>{l}</p>)}
                     </React.Fragment>,
            handleClose: (agree)=>{
                if(agree){
                    doAJAXCall(
                        {
                            whatIsFor: t('加入购物车'),
                            errorDialog: this.errorDialog!,
                            ajaxCall: async (accessToken: string, username:string)=>{
                                await addHealthAdviceToShoppingCard(accessToken,username,advice.prescriptionCode!, this.props.currencyCode);
                                advice.state = 'IN_CART';
                                await setShopUserInfo();
                                this.forceUpdate(()=>{
                                    gotoShoppingPathNSavePrevPath('main/shopping/my-shopping-cart');
                                    // this.toast!.sendMessage(t('健康建议书加入购物车'), 'success');
                                });
                            }
                        }
                    )    
                }
            }
        })
    }

    renderHealthAdviceList():JSX.Element {
        return (
            <div className='health-advice-list'>
            {
                this.state.searchResult!.content.map(x=>{
                    const expire = new Date(x.expiration);
                    const created = new Date(x.createdDtm);
                    const products = x.item||[];
                    const nutritionist = x.nutritionist;

                    const productType = getHealthAdviceProductType(x);
                    
                    let option = this.state.statusOptions!.find(option=>option.key === x.state);
                    let status = option? option.text : x.state;

                    const adviceData:PropertyListDataItem[] = [];

                    adviceData.push({name:t('编号'), value: x.prescriptionCode, valueClassName:'prescription-code'});
                    adviceData.push({name:'类型', value:productType==='test'? t('健康检测'): t('一般产品')});
                    if(productType === 'test'){
                        adviceData.push({
                            name: '',
                            value: <HTMLDialog 
                                        title={t('健康检测操作指南')}
                                        errorDialog={this.errorDialog!}
                                        loadHTMLFn={readLabTestInstruction}
                                    />,
                            mergeCol: true,        
                        });
                    };
                    adviceData.push({name:t('标题'), value: x.title});
                    adviceData.push({
                                        name:t('描述'), 
                                        value: <Fragment>
                                                {(x.description||'').split('\n').map(l=>{
                                                    if(l) return <div>{l}</div>
                                                    else return <br/>
                                                })}
                                                </Fragment>
                                    });
                    adviceData.push({
                        name:t('状态'), 
                        value: status
                    });
                    adviceData.push({name:t('创建日期'), value: formatDate(created)});
                    adviceData.push({name:t('有效日期'), value: formatDate(expire)});

                    adviceData.push({
                        name:t('营养师姓名'), 
                        value: <div>{nutritionist.name} &nbsp; &nbsp;
                                    <UButton variant='contained' color='primary' size='small'
                                            onClick={()=>{
                                                gotoShoppingPathNSavePrevPath('main/shopping/nutritionist-profile',
                                                                            {nutritionistId: nutritionist.username})
                                            }}
                                    >{t('详细档案')}</UButton>
                               </div>, 
                        mergeCol: true
                    });
                    adviceData.push({name:t('营养师电邮'), value: <SimpleEmail email={nutritionist.officeEmail}/>, mergeCol: true});
                    adviceData.push({name:t('营养师电话'), value: <SimplePhoneNumber phoneNumber={nutritionist.officePhone}/>, mergeCol: true});
                    adviceData.push({name:t('营养师地址'), value: AddressInfo2Str(nutritionist.businessAddress), mergeCol: true});

                    adviceData.push({
                        name:t('健康建议'), 
                        mergeCol: true,
                        value: <React.Fragment>
                                {
                                    products.map((p, idx1)=>{
                                        return <div>
                                                   {p.productType==='test'?
                                                    <div>{t('数量')}: {p.count} <strong>{p.productName}</strong></div>:
                                                    <Fragment>
                                                        <div>{t('数量')}: {p.count}</div>
                                                        <ol>
                                                            {p.keyIngredient.map((v,idx2)=>{
                                                                return  <li className='key-ingredients-paragraph'>{`${v.name}/${v.cpName} (${v.amount})`}</li>;
                                                                })
                                                            }
                                                        </ol>
                                                    </Fragment>
                                                   }
                                                    
                                               </div>
                                    })
                                }                            
                                </React.Fragment>
                    });
                    if(x.state === 'READY'){
                        adviceData.push({
                            name: '',
                            valueClassName:'apply-health-advice-button',
                            mergeCol: true,
                            value: <UButton variant='contained' color='primary' size='small'
                                            onClick={()=>{
                                                this.addToShoppingCart(x);
                                            }}
                                    >{t('执行健康建议书')} {x.prescriptionCode}</UButton>
                        })
                    }
                    return <PropertyListWidget list={adviceData} showBorder={true} className='health-advice-readonly'
                                                mergeColScreenLetterRatio={RatioXXS}/>
                })
            }
            </div>
        )
    }

    searchHealthAdvice(page:number, pageSize:number, filters: ClientPrescriptionFilter){
        doAJAXCall({
            whatIsFor:t(`下载数据`),
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken)=>{
                let searchResult = await searchClientHealthAdvice(accessToken, page, pageSize, filters);
                this.setState({searchResult, page: searchResult.page, pageSize: searchResult.size});                                                
            },
            onError: (errorMessage)=>{
            }
        })

    }

    loadPaginationData = (page:number, pageSize:number) => {
        this.searchHealthAdvice(page, pageSize, this.searchFilterForm!.getSearchHealthAdviceFilter())
    }

    initializeAgain(){
        this.setState({pageInitState:'PAGE_INIT', initErrorMessage:undefined},
                      ()=>{
                          this.loadData();
                      })
    }


    componentDidMount(){
        this.loadData();
    }

    loadData(){
        doAJAXCall({
            whatIsFor:t(`下载数据`),
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken)=>{
                await loadExternContatnsIfNecessary();
                let statusOptions = getHealthAdviceStatuOptions(accessToken, this.props.languageCode!);
                let searchResult = await searchClientHealthAdvice(accessToken, this.state.page, this.state.pageSize, 
                                                                  {
                                                                      text: this.props.text,
                                                                      state: this.props.state,
                                                                  });
                this.setState({pageInitState:'PAGE_LOAD_SUCCESS', statusOptions, searchResult,
                               page: searchResult.page});                                                
            },
            onError: (errorMessage)=>{
                this.setState({pageInitState:'PAGE_LOAD_FAILED', initErrorMessage: errorMessage});
            }
        })
    }
    componentDidUpdate(prevProps:any, prevState:SearchClientHealthAdviceState){
        if(prevState.page !== this.state.page){
            scrollBodyToTop();
        }
        let {page, pageSize} = this.state;
        let {text, state} = this.searchFilterForm!.getSearchHealthAdviceFilter();
        let parms:SearchClientHealthAdviceFilter = {
            page, pageSize, text, state
        }
        updateCurrentPathParms('main/tools/my-health-advice', parms)
    }
}

export const SearchClientHealthAdvice = connect(
    (state:WalletAppState)=>({languageCode: state.language.langCode, currencyCode: state.currency.currencyCode})
)(SearchClientHealthAdviceView);