import * as React from 'react';
import {Component, Fragment} from 'react';

import './ToolsView.scss';
import {t} from '../../i18n';
import { ReactReduxContext } from 'react-redux';
import { Header,  } from '../header/HeaderView2';
import { FooterView } from '../footer/footer';

import { getLanguageCode, readLoginSession } from '../../local-storage/local-storage';
import { Toast } from '../Toast';
import { hasRole, hasRoleAdmin, isPharmacist, ROLE_NUTRITIONIST, ROLE_SHOP_GNGINC } from '../../api/user';
import { gotoLoginPage, gotoShoppingPathNSavePrevPath } from '../../shopping/history/shopping-history';
import { BaseUnishellPageState, renderInitErrorUnishellPage, renderInitUnishellPage } from '../../unishell-page-common/unishell-page-common';
import { loadExternContatnsIfNecessary } from '../../external-constants/extern-constants';
import { ListMenu } from '../list-menu/list-menu';
import { getMenuData, MenuItemData, MenuItemDirData, } from '../../tree-menu/common';

export class ToolsView extends Component<{}, BaseUnishellPageState> {
    toast: Toast | undefined;

    constructor(props: {}){
        super(props);
        this.state = {pageInitState:'PAGE_INIT'}
    }

    toImplement = ()=>{
        this.toast!.sendMessage(t('新功能即将上线'), 'info')
    }

    render() {
        if(this.state.pageInitState === 'PAGE_INIT'){
            return <Fragment>
                {
                    renderInitUnishellPage({
                        title: t('工具'),
                        className: 'tools-page',
                        message: t('初始化...')    
                    })
                }
                <Toast close={true} duration={2500} message='' ref={e=>{this.toast=e!}} toastType='info'/>
            </Fragment>;
        }
        if(this.state.pageInitState === 'PAGE_LOAD_FAILED'){
            return <Fragment>
                    {
                        renderInitErrorUnishellPage({
                            title: t('工具'),
                            className: 'tools-page',
                            errorMessage: this.state.initErrorMessage!
                        })
                    }
                    </Fragment>
        }

        return <ReactReduxContext.Consumer>
            { ({store}) => {
                const logout = ()=>{
                    gotoLoginPage();
                }
                const loginSession = readLoginSession()!;
                if(!loginSession) logout();
                
                const isShipGNGInc = hasRole(loginSession.profile!.roles, ROLE_SHOP_GNGINC);
                const isAdmin = hasRoleAdmin(loginSession.profile!.roles);

                const isNutritionist = hasRole(loginSession.profile!.roles, ROLE_NUTRITIONIST);

                let iAMPharmacist = isPharmacist();

                const _menuData = getMenuData();

                const generalToolsMenuItems = _menuData.find(x=>x.id==='general_tools')! as MenuItemDirData;
                const clientToolsMenuItems = _menuData.find(x=>x.id==='client_tools')! as MenuItemDirData;
                const nutritionistToolsMenuItems = _menuData.find(x=>x.id==='nutritionist_tools')! as MenuItemDirData;
                const adminToolsMenuItems = _menuData.find(x=>x.id==='admin_tools')! as MenuItemDirData;
                const shopGNGIncToolsMenuItems = _menuData.find(x=>x.id==='shop_gnginc_tools')! as MenuItemDirData;
                const pickupSiteMenuItem = _menuData.find(x=>x.id === 'pickup_orders') as MenuItemData;

                return <div className={'tools-page app-page '+getLanguageCode()}>
                        <Header title={t('工具')}/>
                        <div className='tools-menu-list'>
                            <ListMenu label={generalToolsMenuItems.label}>
                            {
                                generalToolsMenuItems.children.map(x=>{
                                    if(x.type === 'leaf'){
                                        return <ListMenu label={x.label} 
                                                         onClick={()=>{
                                                                if(x.menuPath){
                                                                    gotoShoppingPathNSavePrevPath(x.menuPath)
                                                                }else if(x.onClick){
                                                                    x.onClick();
                                                                }
                                                            }}
                                                          isActionButton={x.isAction}  
                                                />
                                    }
                                })
                            }
                            </ListMenu>

                            <ListMenu label={clientToolsMenuItems.label}>
                            {
                                clientToolsMenuItems.children.map(x=>{
                                    if(x.type === 'leaf'){
                                        return <ListMenu label={x.label} 
                                                         onClick={()=>{
                                                            if(x.menuPath){
                                                                gotoShoppingPathNSavePrevPath(x.menuPath)
                                                            }else if(x.onClick){
                                                                x.onClick();
                                                            }
                                                        }}
                                                        isActionButton={x.isAction}
                                                />
                                    }
                                })
                            }
                            </ListMenu>

                            {
                                isNutritionist ? 
                                <ListMenu label={nutritionistToolsMenuItems.label}>
                                {
                                    nutritionistToolsMenuItems.children.map(x=>{
                                        if(x.type === 'leaf'){
                                            return <ListMenu label={x.label} 
                                                             onClick={()=>{
                                                                    if(x.menuPath){
                                                                        gotoShoppingPathNSavePrevPath(x.menuPath)
                                                                    }else if(x.onClick){
                                                                        x.onClick();
                                                                    }
                                                              }}
                                                              isActionButton={x.isAction}
                                                    />
                                        }else{
                                            return null;
                                        }
                                    })
                                }
                                </ListMenu>
                                : null
                            }
                            {
                                iAMPharmacist ? 
                                <ListMenu label={pickupSiteMenuItem.label} 
                                            onClick={()=>{
                                                if(pickupSiteMenuItem.menuPath){
                                                    gotoShoppingPathNSavePrevPath(pickupSiteMenuItem.menuPath)
                                                }else if(pickupSiteMenuItem.onClick){
                                                    pickupSiteMenuItem.onClick();
                                                }
                                            }}/> :null
                            }

                            {
                                isShipGNGInc ? 
                                <ListMenu label={shopGNGIncToolsMenuItems.label}>
                                {
                                   shopGNGIncToolsMenuItems.children.map(x=>{
                                    if(x.type === 'leaf'){
                                        return <ListMenu label={x.label} 
                                                         onClick={()=>{
                                                            if(x.menuPath){
                                                                gotoShoppingPathNSavePrevPath(x.menuPath)
                                                            }else if(x.onClick){
                                                                x.onClick();
                                                            }
                                                        }}
                                                        isActionButton={x.isAction}
                                                />
                                    }else{
                                        return null;
                                    }
                                }) 
                                }
                                </ListMenu> 
                                :null
                            }

                            {isAdmin ? 
                             <ListMenu label={adminToolsMenuItems.label}>
                            {
                                adminToolsMenuItems.children.map(x=>{
                                    if(x.type === 'leaf'){
                                        return <ListMenu label={x.label} 
                                                         onClick={()=>{
                                                            if(x.menuPath){
                                                                gotoShoppingPathNSavePrevPath(x.menuPath)
                                                            }else if(x.onClick){
                                                                x.onClick();
                                                            }
                                                        }}
                                                        isActionButton={x.isAction}
                                                />
                                    }
                                })
                            }
                             </ListMenu>
                            : null
                            }
                        </div>

                        <Toast close={true} duration={2500} message='' ref={e=>{this.toast=e!}} toastType='info'/>

                        <FooterView/>
                </div>
            }}
        </ReactReduxContext.Consumer>
    }

    async componentDidMount(){
        await loadExternContatnsIfNecessary();
        this.setState({pageInitState:'PAGE_LOAD_SUCCESS'});
    }
}