// Generated by IcedCoffeeScript 108.0.11
(function () {
  var C,
      Literal,
      LiteralParser,
      Packet,
      asymmetric,
      iced,
      uint_to_buffer,
      __iced_k,
      __iced_k_noop,
      __hasProp = {}.hasOwnProperty,
      __extends = function __extends(child, parent) {
    for (var key in parent) {
      if (__hasProp.call(parent, key)) child[key] = parent[key];
    }

    function ctor() {
      this.constructor = child;
    }

    ctor.prototype = parent.prototype;
    child.prototype = new ctor();
    child.__super__ = parent.prototype;
    return child;
  },
      __indexOf = [].indexOf || function (item) {
    for (var i = 0, l = this.length; i < l; i++) {
      if (i in this && this[i] === item) return i;
    }

    return -1;
  };

  iced = require('iced-runtime');

  __iced_k = __iced_k_noop = function __iced_k_noop() {};

  Packet = require('./base').Packet;
  C = require('../../const').openpgp;
  asymmetric = require('../../asymmetric');
  uint_to_buffer = require('../../util').uint_to_buffer;

  Literal = function (_super) {
    __extends(Literal, _super);

    function Literal(_arg) {
      this.format = _arg.format, this.filename = _arg.filename, this.date = _arg.date, this.data = _arg.data;

      Literal.__super__.constructor.call(this);
    }

    Literal.parse = function (slice) {
      return new LiteralParser(slice).parse();
    };

    Literal.prototype.toString = function (encoding) {
      if (encoding == null) {
        encoding = null;
      }

      return this.data.toString(encoding || this.buffer_format());
    };

    Literal.prototype.toBuffer = function () {
      return this.data;
    };

    Literal.prototype.buffer_format = function () {
      switch (this.format) {
        case C.literal_formats.text:
          return 'ascii';

        case C.literal_formats.utf8:
          return 'utf8';

        default:
          return 'binary';
      }
    };

    Literal.prototype.to_signature_payload = function () {
      return Buffer.concat([this.data]);
    };

    Literal.prototype.write_unframed = function (cb) {
      var bufs, ret;
      this.filename || (this.filename = Buffer.from([]));
      bufs = [Buffer.from([this.format]), uint_to_buffer(8, this.filename.length), this.filename, uint_to_buffer(32, this.date), this.data];
      ret = Buffer.concat(bufs);
      return cb(null, ret);
    };

    Literal.prototype.write = function (cb) {
      var err, raw, ret, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      err = ret = null;
      (function (_this) {
        return function (__iced_k) {
          __iced_deferrals = new iced.Deferrals(__iced_k, {
            parent: ___iced_passed_deferral,
            filename: "/Users/max/src/keybase/kbpgp/src/openpgp/packet/literal.iced",
            funcname: "Literal.write"
          });

          _this.write_unframed(__iced_deferrals.defer({
            assign_fn: function () {
              return function () {
                err = arguments[0];
                return raw = arguments[1];
              };
            }(),
            lineno: 41
          }));

          __iced_deferrals._fulfill();
        };
      })(this)(function (_this) {
        return function () {
          if (err == null) {
            ret = _this.frame_packet(C.packet_tags.literal, raw);
          }

          return cb(err, ret);
        };
      }(this));
    };

    Literal.prototype.to_literal = function () {
      return this;
    };

    return Literal;
  }(Packet);

  LiteralParser = function () {
    function LiteralParser(slice) {
      this.slice = slice;
    }

    LiteralParser.prototype.parse = function () {
      var data, date, filename, format, k, known_formats, v;

      known_formats = function () {
        var _ref, _results;

        _ref = C.literal_formats;
        _results = [];

        for (k in _ref) {
          v = _ref[k];

          _results.push(v);
        }

        return _results;
      }();

      format = this.slice.read_uint8();

      if (__indexOf.call(known_formats, format) < 0) {
        throw new Error("unknwon format: " + format);
      }

      filename = this.slice.read_string();
      date = this.slice.read_uint32();
      data = this.slice.consume_rest_to_buffer();
      return new Literal({
        format: format,
        filename: filename,
        date: date,
        data: data
      });
    };

    return LiteralParser;
  }();

  exports.Literal = Literal;
}).call(this);