import { createStore, combineReducers, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import {ajaxCallReducer} from './ajax-call/reducers';
import {pathPathReducer} from './path/reducers';
import {pointsReducer } from "./points";
import {languageReducer} from './language';
import { currencyReducer } from "./currency";
import {shopUserInfoReducer} from './shop-user';

const rootReducer = combineReducers({
    ajaxCall: ajaxCallReducer,
    pagePath: pathPathReducer,
    points: pointsReducer,
    language: languageReducer,
    currency: currencyReducer,
    shopUserInfo: shopUserInfoReducer
})

export type WalletAppState = ReturnType<typeof rootReducer>;

export default function configureStore() {
    const middlewares = [thunkMiddleware];
    const middleWareEnhancer = applyMiddleware(...middlewares);
  
    const store = createStore(
      rootReducer,
      composeWithDevTools(middleWareEnhancer)
    );
  
    return store;
  }
  