import { Grid } from '@material-ui/core';
import * as React from 'react';
import { t } from '../../i18n';
import { getServerURL, RatioXXS } from '../../util/Util';
import { UButton } from '../../widget/button/ubutton';
import { SimpleEmail } from '../../widget/email/email';
import { SimplePhoneNumber } from '../../widget/phone-number/phone-number';
import { PropertyListDataItem, PropertyListWidget } from '../../widget/property-list/property-list';
import { AddressInfo2Str, AddressInfo2StrArray, NutritionistInfo, toImgSrc } from '../ajax-data/ajax-data-types';
import { displayServiceTime } from '../common/service-time';
import { gotoShoppingPathNSavePrevPath } from '../history/shopping-history';

import './nutritionist.scss';

export function NutritionistDetailRender(args:{detail:NutritionistInfo}){
    const [showLicenceImg, setShowLicenseImg] = React.useState(false);
    const [showQRCodeImg, setShowQRCodeImg] = React.useState(false);

    const {detail} = args;

    let sex = t(detail.gender||'', 'sex');
    const serverURL = getServerURL();

    const propList: PropertyListDataItem[] = [
        {name: 
            t('姓名'), 
            value: <div>
                    {detail.name} &nbsp;&nbsp;
                    <UButton variant='contained' color='primary' size='small'
                             onClick={()=>{
                                 gotoShoppingPathNSavePrevPath('main/shopping/nutritionist-profile',
                                                               {nutritionistId: detail.username})
                             }}
                    >{t('详细档案')}</UButton>
                   </div>
        },
        {name: t('电邮'), value: <SimpleEmail email={detail.officeEmail}/>},
        {name: t('电话'), value: <SimplePhoneNumber phoneNumber={detail.officePhone}/>},
        {
            name: t('地址'), 
            value: <div className='address'>
                        {AddressInfo2StrArray(detail.businessAddress).map(x=>{
                            return <div className='address-line'>{x}</div>
                        })}
                    </div>
        },
    ];
    return (
        <div className='nutritionist-detail'>
            <PropertyListWidget list={propList} showBorder={true} mergeColScreenLetterRatio={RatioXXS}/>
            <div className='service-time'>
                <p>{t('服务时间')}</p>
                {detail.serviceTime ? 
                detail.serviceTime!.map(x=>{
                    return displayServiceTime(x)
                }) 
                : null}                
            </div>
        </div>
    )
}