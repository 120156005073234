import {ECCKeyPair} from '../cypto/crypto';
import { LoginSessionState } from '../redux-store/session';
import jwt_decode from 'jwt-decode';
import { getExternContantsInMemory, LANG_CODE_EN, LANG_CODE_ZH_CN } from '../external-constants/extern-constants';
import { CheckoutRequest, CheckoutRequestInLocalStorage } from '../shopping/ajax-data/ajax-data-types';

export interface PersistData {
    username: string;
    version: '1.0';
    keyPair: ECCKeyPair;
    pkeyHash: string;
}

export function genLocalStorageKey(userIdentifier:string, category:string) {
    return 'unishell::'+userIdentifier+'::'+category;
}

export function readPersistData(userIdentifier:string):PersistData|undefined {
    const json = localStorage.getItem(genLocalStorageKey('_','key'));
    if(json){
        let data = JSON.parse(json) as PersistData;
        if(data.username !== userIdentifier){
            return undefined;
        }else{
            return data;
        }
    }else{
        return undefined;
    }
}

export function savePersistData(userIdentifier:string, data:PersistData ){
    //info(`savePersistData ${userIdentifier}`);
    const theKey = genLocalStorageKey('_','key');
    localStorage.setItem(theKey, JSON.stringify(data));

    // for sake of security, let's remove old keys
    let todelete: string[] = [];
    for(let i=0, len=localStorage.length; i<len;  i++){
        let key=localStorage.key(i);
        if(key && key != 'unishell::_::key' && key!.startsWith('unishell::') && key!.endsWith('::key')){
            todelete.push(key);
        }
    }
    todelete.forEach(key=>{
        localStorage.removeItem(key);
    })
}

interface LastLoginEmail {
    version: '1.0';
    email: string;
    timestamp: number;
}

export function saveLastLoginEmail(email:string){
    const data:LastLoginEmail = {email, timestamp: Date.now(), version:'1.0'}
    localStorage.setItem(genLocalStorageKey('_','loginEmail'), JSON.stringify(data));
}

export function readLastLoginEmail():string|undefined {
    const json = localStorage.getItem(genLocalStorageKey('_','loginEmail'));
    if(json){
        return (JSON.parse(json) as LastLoginEmail).email;
    }else{
        return undefined;
    }
}

interface LoginSession {
    version: '1.0';
    session: LoginSessionState|null;
}

export function saveLoginSession(session:LoginSessionState|null){
    const data:LoginSession = {version:'1.0', session}
    localStorage.setItem(genLocalStorageKey('_','loginSession'), JSON.stringify(data));
}

export function readLoginSession():LoginSessionState|null {
    const json = localStorage.getItem(genLocalStorageKey('_','loginSession'));
    if(json){
        return (JSON.parse(json) as LoginSession).session;
    }else{
        return null;
    }
}

export function getValidLoginSession() {
    let savedSession = readLoginSession();
    if(savedSession && savedSession.token){
        const jwt = (jwt_decode(savedSession.token.accessToken)) as {exp:number};
        if(Date.now()/1000+60*30 < jwt.exp){ // jwf not expires yet in 30 min, which is enough for clock shift
            return savedSession;
        }
    }
    return null;
}

export function amIAdmin(){
    let isAdmin = false;
        
    let session = getValidLoginSession();
    if(session && session.profile && session.profile.roles.some(r=> r === 'ROLE_ADMIN')){
        isAdmin = true;
    }

    return isAdmin;
}
export function amINutritionist(){
    let isAdmin = false;
        
    let session = getValidLoginSession();
    if(session && session.profile && session.profile.roles.some(r=> r === 'ROLE_NUTRITIONIST')){
        isAdmin = true;
    }

    return isAdmin;
}

export function amIUserWithUsername(username:string){
    let session = getValidLoginSession();
    if(session && session.profile && session.profile.username === username){
        return true;
    }
    return false;
}

export function genLanguageLocalStorageKey():string {
    return 'unishell::language';
}



export function getLanguageOptions(){
    return getExternContantsInMemory().languageOptions;
}

/**
 * get current language code from local storage or fallback to default code.
 */
export function getLanguageCode(): string{
    let langCode = localStorage.getItem(genLanguageLocalStorageKey());
    if(!langCode){
        langCode = LANG_CODE_ZH_CN; // falback to initial lang code
    }
    if(langCode !== LANG_CODE_EN && langCode !== LANG_CODE_ZH_CN){
        langCode = LANG_CODE_ZH_CN;
    }
    return langCode;
}

/**
 * store language code in local storage.
 * 
 * @param langCode 
 */
export function setLanguageCode(langCode:string):void {
    localStorage.setItem(genLanguageLocalStorageKey(), langCode);
}

export function setLanguageCodeFromUserProfile(langCode:string):void {
    if(!getLanguageCode()){
        setLanguageCode(langCode);
    }
}

/**
 * get checkout request settings from local storage
 */
export function readCheckoutRequest():CheckoutRequestInLocalStorage {
    let session = getValidLoginSession();
    if(session){
        const json = localStorage.getItem(genLocalStorageKey('_','checkoutRequest'));
        if(json){
            let data = JSON.parse(json) as CheckoutRequestInLocalStorage;
            if(data.username !== session.profile!.username){
                return {username: session.profile!.username}; // not my data
            }else{
                return data;
            }
        }else{
            return {}; // no such data in local storage
        }        
    }else{
        return {}; // invalid login session
    }
}

/**
 * save checkout request in local storage
 * @param data 
 */
export function saveCheckoutRequest(data: CheckoutRequestInLocalStorage){
    localStorage.setItem(genLocalStorageKey('_','checkoutRequest'), JSON.stringify(data));
}

