// Generated by IcedCoffeeScript 108.0.11
(function () {
  var Base, C, iced, konst, __iced_k, __iced_k_noop;

  iced = require('iced-runtime');

  __iced_k = __iced_k_noop = function __iced_k_noop() {};

  konst = require('../const');
  C = konst.openpgp;

  exports.Base = Base = function () {
    function Base(_arg) {
      this.packets = _arg.packets, this.keyfetch = _arg.keyfetch;
      this._sig = null;
    }

    Base.prototype._find_signature = function (cb) {
      var err, n;
      err = (n = this.packets.length) !== 1 ? new Error("Expected one signature packet; got " + n) : (this._sig = this.packets[0]).tag !== C.packet_tags.signature ? new Error("Expected a signature packet; but got type=" + this.packets[0].tag) : null;
      return cb(err);
    };

    Base.prototype._fetch_key = function (cb) {
      var err, i, key_id, keymat, km, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      key_id = this._sig.get_key_id();
      (function (_this) {
        return function (__iced_k) {
          __iced_deferrals = new iced.Deferrals(__iced_k, {
            parent: ___iced_passed_deferral,
            filename: "/Users/max/src/keybase/kbpgp/src/openpgp/verifier.iced",
            funcname: "Base._fetch_key"
          });

          _this.keyfetch.fetch([key_id], konst.ops.verify, __iced_deferrals.defer({
            assign_fn: function () {
              return function () {
                err = arguments[0];
                km = arguments[1];
                return i = arguments[2];
              };
            }(),
            lineno: 28
          }));

          __iced_deferrals._fulfill();
        };
      })(this)(function (_this) {
        return function () {
          if (typeof err === "undefined" || err === null) {
            keymat = km.find_pgp_key_material(key_id);
            _this._sig.key = keymat.key;
            _this._sig.key_manager = km;
            _this._sig.subkey_material = keymat;
          }

          return cb(err);
        };
      }(this));
    };

    return Base;
  }();
}).call(this);