import * as React from 'react';
import {Fragment, } from 'react';
import './shopping-page-common.scss';
import { ProductCategory } from '../ajax-data/ajax-data-types';

export type PAGE_STATE = 'PAGE_INIT' | 'PAGE_LOAD_SUCCESS' | 'PAGE_LOAD_FAILED';

export interface BaseShoppingPageProps {
    languageCode?:string;
}

/**
 * 
 * @param name 
 * @param categories 
 */
export function findProductCategory(name:string, categories:ProductCategory[]):ProductCategory|undefined {
    let target = categories.find(x=>x.name === name);
    if(target){
        return target;
    }
    for(let x of categories){
        if(x.children){
            target = findProductCategory(name, x.children);
            if(target){
                return target;
            }
        }
    }
    return undefined;
}

/**
 * 
 * @param ancestor 
 * @param name 
 * @param categories
 * @returns complete path of the given product category 
 */
export function findProductCategoryPath(ancestor:ProductCategory[], 
                                        name:string, 
                                        categories:ProductCategory[]):ProductCategory[] {
    if(!name){
        return [];
    }             

    let target = categories.find(x=>x.name === name);
    if(target){
        let newAncestor = Array.from(ancestor);
        newAncestor.push(target);
        return newAncestor;
    }
    for(let x of categories){
        if(x.children){
            let newAncestor = Array.from(ancestor);
            newAncestor.push(x);
            let result = findProductCategoryPath(newAncestor, name, x.children);
            if(result.length>0){
                return result;
            }
        }
    }
    return [];
}


export function findSiblingProductCategories(name:string, categories:ProductCategory[]):ProductCategory[] {
    if(categories.some(x=> x.name === name)){
        return categories;
    }
    for(let x of categories){
        if(x.children){
            let target = findSiblingProductCategories(name, x.children);
            if(target){
                return target;
            }
        }
    }
    return [];
}


