import * as React from 'react';
import {Component, Fragment} from 'react';
import './Toast.scss';
import { Snackbar, Button, IconButton, SnackbarOrigin } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { info } from '../globals';
import ReactDOM from 'react-dom';

type ToastType = 'success'|'info'|'warning'|'error';

interface ToastProps {
    message:string;
    toastType: ToastType;
    anchorOrigin?: SnackbarOrigin,
    close?: boolean;
    duration?:number;
    onClose?: ()=>void;
}
interface ToastState {
    open:boolean, 
    message:string, 
    toastType:ToastType,
    onClose?: ()=>void
}

export class Toast extends Component<ToastProps,ToastState> {
    constructor(props: ToastProps){
        super(props);
        this.state = {open: !props.close, message:props.message, toastType:props.toastType, onClose: props.onClose}
    }

    handleClose = (evt: any, reason:string)=>{
        info(`toast close ${reason}`);
        this.setState({open: false}, ()=>{
            this.state.onClose && this.state.onClose()
        })
    }
    handleClose2 = ()=>{
        this.setState({open: false}, ()=>{
            this.state.onClose && this.state.onClose()
        })
    }

    sendMessage(message:string, toastType:ToastType, close:boolean = false, onClose?: ()=>void){
        this.setState({message, toastType, open: !close, onClose: onClose||this.state.onClose})
    }

    render(){
        // put Snackbar under our root to avoid css corruption from ancestor nodes.
        return (
            ReactDOM.createPortal(
                <Snackbar   open={this.state.open} autoHideDuration={this.props.duration || 2000} 
                            onClose={this.handleClose}
                            className={'toast-snackbar toast-snackar-'+this.state.toastType}
                            anchorOrigin={this.props.anchorOrigin}
                            message={<span>{this.state.message}</span>}
                            action={[
                                    <IconButton key="close" aria-label="close" color="inherit" onClick={this.handleClose2}>
                                        <CloseIcon />
                                    </IconButton>,
                                ]}
                />,
                document.getElementById('root')!
            )
        )
    }
}