// Generated by IcedCoffeeScript 108.0.11
(function () {
  var IV, WordArray, bufeq_secure, setup, split64, unwrap, wrap;
  WordArray = require('triplesec').WordArray;
  bufeq_secure = require('./util').bufeq_secure;
  IV = WordArray.from_hex("A6A6A6A6A6A6A6A6");

  split64 = function split64(wa) {
    return wa.split(wa.words.length >> 1);
  };

  exports.wrap = wrap = function wrap(_arg) {
    var A, AES, B, C, P, R, cipher, err, i, j, key, plaintext, r, t, _i, _j, _k, _len, _len1, _ref;

    plaintext = _arg.plaintext, key = _arg.key, cipher = _arg.cipher;
    _ref = setup({
      input: plaintext,
      key: key,
      cipher: cipher
    }), err = _ref[0], P = _ref[1], AES = _ref[2];

    if (err != null) {
      throw err;
    }

    A = IV;
    R = P;
    t = new WordArray([0, 0]);

    for (j = _i = 0; _i < 6; j = ++_i) {
      for (i = _j = 0, _len = R.length; _j < _len; i = ++_j) {
        r = R[i];
        t.words[1]++;
        B = A.clone().concat(r);
        AES.encryptBlock(B.words);
        A = B.slice(0, 2);
        R[i] = B.slice(2, 4);
        A.xor(t, {});
      }
    }

    C = A;

    for (_k = 0, _len1 = R.length; _k < _len1; _k++) {
      r = R[_k];
      C.concat(r);
    }

    return C.to_buffer();
  };

  setup = function setup(_arg) {
    var AES, K, P, a, b, cipher, err, input, key, klass;
    input = _arg.input, key = _arg.key, cipher = _arg.cipher;
    P = split64(WordArray.from_buffer(input));
    K = WordArray.from_buffer(key);
    klass = cipher.klass;
    AES = new klass(K);
    err = null;

    if ((a = cipher.key_size) !== (b = key.length)) {
      err = new Error("Bad key, needed " + a + " bytes, but got " + b);
    }

    return [err, P, AES];
  };

  exports.unwrap = unwrap = function unwrap(_arg) {
    var A, AES, B, C, P, R, cipher, ciphertext, err, i, j, key, n, r, t, _i, _j, _k, _len, _ref;

    ciphertext = _arg.ciphertext, key = _arg.key, cipher = _arg.cipher;
    _ref = setup({
      input: ciphertext,
      key: key,
      cipher: cipher
    }), err = _ref[0], C = _ref[1], AES = _ref[2], n = _ref[3];

    if (err != null) {
      return [err, null];
    }

    A = C[0];
    R = C.slice(1);
    t = new WordArray([0, 6 * R.length]);

    for (j = _i = 0; _i < 6; j = ++_i) {
      for (i = _j = R.length - 1; _j >= 0; i = _j += -1) {
        r = R[i];
        A.xor(t, {});
        B = A.clone().concat(r);
        AES.decryptBlock(B.words);
        A = B.slice(0, 2);
        R[i] = B.slice(2, 4);
        t.words[1]--;
      }
    }

    if (A.equal(IV)) {
      P = new WordArray([]);

      for (_k = 0, _len = R.length; _k < _len; _k++) {
        r = R[_k];
        P.concat(r);
      }

      return [null, P.to_buffer()];
    } else {
      return [new Error("integrity check failure; got bad IV in decryption"), null];
    }
  };
}).call(this);