import { Button, Grid, MobileStepper, Paper, TextField } from '@material-ui/core';
import * as React from 'react';
import {Component, Fragment} from 'react';
import { t } from '../../i18n';
import { NoticeDialog } from '../../view/NoticeDialog';
import { NutritionistClient, getProductCode, NutritionistHealthAdvice, ProductCandidateBrief, ShoppingProductDetail, AddressInfo2Str, appendIngredientTabData, HealthAdviceItemInfo, ProductType } from '../ajax-data/ajax-data-types';
import { advancedSearchShoppingProducts, createHealthAdvice, getCurrencyOptionsMap, getLabTestProducts, retreiveShoppingProductDetail, retrieveClient } from '../ajax-data/shopping-server-api';
import { doAJAXCall } from '../common/ajax-call';
import { BaseShoppingPageProps, } from '../common/shopping-page-common';
import TextareaAutosize from 'react-textarea-autosize';
import { connect } from 'react-redux';
import { WalletAppState } from '../../redux-store';
import classnames from 'classnames';
import './create-health-advice.scss';
import { AdvancedProductSearchFilter, AdvancedProductSearchForm } from '../common/advanced-product-search-form';
import { getDocSpecs, readLabTestInstruction } from '../../wallet-server-api/wallet-server-api';
import { DocSpecResponse } from '../../api/docs';
import { PagedResponse } from '../../api/transaction';
import { calcRationClass, formatPrice, getCurrencyOption, getMaxThumbnailImgSize, getViewportSize, RatioXXS, RatioXXXS, scrollBodyToTop } from '../../util/Util';
import { Pagination } from '../common/render-pagination';
import { HealthAdviceProductDetail, KeyIngredient } from './health-advice-product-detail';
import { shoppingGoBack, shoppingGoBackWOConfirmation } from '../history/shopping-history';
import { Toast } from '../../view/Toast';
import { genLocalStorageKey, getValidLoginSession, readLoginSession } from '../../local-storage/local-storage';
import { renderInitUnishellPage, renderInitErrorUnishellPage, renderUnishellPage, BaseUnishellPageState } from '../../unishell-page-common/unishell-page-common';
import { loadExternContatnsIfNecessary } from '../../external-constants/extern-constants';
import { SimplePhoneNumber } from '../../widget/phone-number/phone-number';
import { SimpleEmail } from '../../widget/email/email';
import { PropertyListDataItem, PropertyListWidget } from '../../widget/property-list/property-list';
import { SimpleNote } from '../../widget/note/note';
import { ConfirmDialogView } from '../../view/confirm-dialog/ConfirmDialogView';
import { UButton } from '../../widget/button/ubutton';
import { HEqualSize } from '../../widget/equal-sizing/h-equal-size';
import { VEqualSize } from '../../widget/equal-sizing/v-equal-size';
import { ShoppingProductBrief } from '../common/product-brief';
import { setShopUserInfo } from '../../view/header/HeaderView2';
import { HTMLDialog } from '../../wallet-server-api/html-dialog/html-dialog';


interface CreateHealthAdviceViewProps extends BaseShoppingPageProps{
    currencyCode:string;
    clientUsername: string;
    adviceType: ProductType;
}

type TabValue = 'general-info-tab'|'search-product-tab'|'product-detail'|'view-advice';

interface CreateHealthAdviceViewState extends BaseUnishellPageState{
    tabValue: TabValue
    client?: NutritionistClient;
    docSpecs?: DocSpecResponse;

    title:string;
    description:string;
    expireDays:number;

    titleError?:string;
    descriptionError?:string;
    expireDaysError?:string;

    searchFilters: AdvancedProductSearchFilter;

    page:number;
    pageSize: number;

    searchResult?:PagedResponse<ProductCandidateBrief>;

    labProducts?: ProductCandidateBrief[];

    products:   {   productCode:string,
                    productName:string,
                    /** number of products */
                    count: number;
                    keyIngredients: KeyIngredient[];
                    productType: 'test'|'chanpin';
                }[];

    product?: ShoppingProductDetail;  
}

interface AdviceInLS {
    username:string;
    clientUsername: string;
    title:string;
    description:string;
    expireDays:number;
    products:   {   
        productCode:string,
        productName:string,
        /** number of products */
        count: number;
        keyIngredients: KeyIngredient[];
        productType: 'test'|'chanpin';
    }[];
}

class CreateHealthAdviceView extends Component<CreateHealthAdviceViewProps, CreateHealthAdviceViewState>{
    private errorDialog:NoticeDialog|undefined;
    private toast: Toast | undefined;
    private confirmDialogInstance:ConfirmDialogView|undefined;

    private searchForm:AdvancedProductSearchForm|undefined;
    private productDetail:HealthAdviceProductDetail|undefined;

    constructor(props: CreateHealthAdviceViewProps){
        super(props);
        this.state = {pageInitState:'PAGE_INIT', tabValue:'general-info-tab', 
                      title:'', description:'', expireDays: 10, searchFilters:{},
                      page: 0, pageSize: 10 , products:[] ,
                    }
    }

    saveAdviceInLS(){
        let session = getValidLoginSession();
        if(session){
            let data:AdviceInLS = {
                username: session.profile!.username,
                clientUsername: this.props.clientUsername,
                description: this.state.description,
                title: this.state.title,
                expireDays:this.state.expireDays,
                products: this.state.products,
            }
            localStorage.setItem(genLocalStorageKey('_','healthAdvice'), JSON.stringify(data));    
        }
    }

    deleteAdviceLS(){
        localStorage.removeItem(genLocalStorageKey('_','healthAdvice'));
    }

    readAdviceInLS(clientUsername:string): AdviceInLS | undefined {
        const {adviceType} = this.props;

        let session = getValidLoginSession();
        if(!session){
            return undefined;
        }
        const json = localStorage.getItem(genLocalStorageKey('_','healthAdvice'));
        if(!json){
            return undefined;
        }
        try{
            let data = JSON.parse(json) as AdviceInLS;
            if(data.username !== session.profile!.username){
                return undefined;
            }
            if(data.clientUsername !== clientUsername){
                return undefined;
            }

            if(data.products){
                data.products = data.products.filter(x=>{
                    if(adviceType==='test'){
                        return x.productType === 'test';
                    }else{
                        return x.productType !== 'test';
                    }
                })
            }
            return data;
        }catch(error){
            return undefined;
        }
    }

    getTitle(){
        const {adviceType} = this.props;

        return adviceType!=='test'? t('开健康产品建议书') : t('开健康检测单子');
    }

    goBack = ()=>{
        this.deleteAdviceLS();
        shoppingGoBackWOConfirmation();
    }

    validateBasicInfo(){
        let hasError = false;

        let titleError = '';
        if(!this.state.title.trim()){
            hasError = true;
            titleError = t('请输入标题');
        }
        let descriptionError = '';
        if(!this.state.description.trim()){
            hasError = true;
            descriptionError = t('请输入描述');
        }
        let expireDaysError = '';
        if(this.state.expireDays < 1){
            hasError = true;
            expireDaysError = t('请输入有效期');
        }
        return {hasError, titleError, descriptionError, expireDaysError}
    }

    createHealthAdvice(){
        const {hasError, titleError, descriptionError, expireDaysError} = this.validateBasicInfo();
        if(hasError){
            this.setState({titleError, descriptionError, expireDaysError}, ()=>{
                this.confirmDialogInstance!.show({
                    title: t('创建建议书'),
                    message: <p>{t(`请修正基本信息表格中的错误`)}</p>,
                    yesLabel: t('关闭'),
                    disableNoButton: true,
                    handleClose: ()=>{
                        this.setState({tabValue:'general-info-tab'});
                    }
                })
            });
            return;
        }

        let items:HealthAdviceItemInfo[] = this.state.products!.filter(p=>p.count>0).map(p=>{
            let item:HealthAdviceItemInfo={
                productCode: p.productCode,
                productName: p.productName,
                count: p.count,
                productType: p.productType==='test'? 'test':'chanpin',
                keyIngredient: p.keyIngredients 
            }

            return item;
        });
        if(items.length < 1){
            this.toast!.sendMessage(t(`请加入产品`), 'info');
            return;
        }

        let nutritionistUsername = readLoginSession()!.profile!.username;
        const now = Date.now();
        let newHealthAdvice:NutritionistHealthAdvice = {
            expiration: now + (this.state.expireDays)*1000*3600*24,
            createdDtm: now,
            modifiedTime: now,
            clientUsername: this.state.client!.clientUsername,
            nutritionistUsername,
            client: this.state.client!,
            state:'READY',
            title: this.state.title,
            description: this.state.description,
            item: items
        }

        doAJAXCall({
            whatIsFor: t('创建建议书'),
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken:string)=>{
                let reply = await createHealthAdvice(accessToken, newHealthAdvice);
                await setShopUserInfo();
                this.toast!.sendMessage(t('创建建议书')+reply.prescriptionCode, 'success', false, ()=>{
                    this.goBack();
                })
            }
        })
    }

    renderProductsInHealthAdvice():JSX.Element{
        const {adviceType} = this.props;

        const adviceProps:PropertyListDataItem[] = [
            {name: '', value: this.state.client!.client}
        ];
        if(this.state.products.length<1){
            adviceProps.push({
                name: '',
                mergeCol: true,
                value: <SimpleNote message={t('建议书没有产品')} noticeType='info'/>
            })
        }
        this.state.products.forEach((x,idx)=>{
            adviceProps.push({
                name: t('产品')+' '+(idx+1),
                value: <div className='prod-in-advice'>
                            <label>{x.productType==='test'?t('健康检测'):''}</label> &nbsp;
                            <strong>{x.productName}</strong> {t('数量')}: 
                            <span style={{fontFamily:'monospace'}}>{x.count}</span>
                            <div className='prod-in-advice-actions'>
                                <UButton variant='outlined' size='small' color='alert'
                                        onClick={()=>{
                                            let products = this.state.products.filter((v,index)=> index != idx );
                                            this.setState({products});
                                        }}
                                >{t('删除')}</UButton>
                                <UButton variant='outlined' size='small' color='primary'
                                        onClick={()=>{
                                            let product = this.state.products.find((v,index)=> index === idx );
                                            if(product){
                                                product.count --;
                                                let products = this.state.products.filter((v,index)=> v.count >= 1);
                                                this.setState({products});
                                            }
                                        }}
                                >{t('减少')}</UButton>
                                <UButton variant='outlined' size='small' color='primary'
                                        onClick={()=>{
                                            let product = this.state.products.find((v,index)=> index === idx );
                                            if(product){
                                                product.count ++;
                                                this.forceUpdate();
                                            }
                                        }}
                                >{t('增加')}</UButton>
                            </div>

                            <ol>
                            {x.keyIngredients.map((v,idx2)=>{
                                return  <li className='key-ingredients-paragraph'>{`${v.cName}/${v.name}/${v.cpName} (${v.amount})`}</li>;
                                })
                            }
                            </ol>                             
                       </div>
            })
        })
        const {ratioClass, vw,  mw, ratio} = calcRationClass();

        const actionButtons = [
                            <UButton color='secondary' variant='contained' size='small'
                                    onClick={()=>{ this.goBack() }}
                            >{t(`取消/返回`)}</UButton>,

                            <UButton color='primary' variant='contained' size='small'
                                    onClick={()=>{
                                        this.setState({tabValue:'search-product-tab'})
                                    }}
                            >
                                {adviceType!=='test'? t('检索产品') : t('健康检测')}
                            </UButton>,
                            <UButton color='primary' size='small'  variant='contained'
                                    disabled={!this.state.products || this.state.products.length<1}
                                    onClick={()=>{ this.createHealthAdvice() }}
                            >{t(`创建`)}</UButton>
                        ]

        return (
                <div className='products-in-health-advice'>
                    <PropertyListWidget list={adviceProps} showBorder={true} mergeColScreenLetterRatio={RatioXXS}/>
                    {ratio<RatioXXS ? 
                     <VEqualSize justifyContent='space-around' spacing='10px' className='v-table-footer'>
                         {actionButtons}
                     </VEqualSize>:

                    <HEqualSize justifyContent='space-around' spacing='10px' className='h-table-footer'>
                        {actionButtons}
                    </HEqualSize>
                    }
                </div>
        )
    }

    render(){
        if(this.state.pageInitState === 'PAGE_INIT'){
            return <React.Fragment>
                        <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                        {renderInitUnishellPage(
                            {
                                title: this.getTitle(), 
                                className:'shopping-create-health-advice',
                                message:t('初始化...')
                            })
                        }
                    </React.Fragment>
        }
        if(this.state.pageInitState === 'PAGE_LOAD_FAILED'){
            return <React.Fragment>
                        <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                        {renderInitErrorUnishellPage(
                            {
                                title: this.getTitle(), 
                                className:'shopping-create-health-advice',
                                errorMessage: this.state.initErrorMessage||t('初始化失败'),
                                onTryAgain: ()=>{ this.initializeAgain();    }
                            })
                        }
                    </React.Fragment>
        }
        // loading data succeeded
        return renderUnishellPage({
            title: this.getTitle(), 
            className:'shopping-create-health-advice',
            underTopHeader: this.renderUnderHeader(),
            content: (
                <React.Fragment>
                    <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                    <ConfirmDialogView open={false} ref={e=>this.confirmDialogInstance=e!}/>
                    {this.renderSucceedContent()}
                </React.Fragment>
            )
        })
    }

    renderStep(label:string, tab:TabValue, hide:boolean){
        return <div className={classnames('step', {'hide-step': hide})}
                    onClick={()=>{
                        if(hide) return;
                        this.setState({tabValue: tab})
                    }}
               >{label}</div>
    }

    renderSubTitle(){
        switch(this.state.tabValue){
            case 'general-info-tab':
            { 
                return <div className='subtitle-header'>
                        <MobileStepper
                            variant='dots'
                            steps={4}
                            position='static'
                            activeStep={0}
                            backButton={
                                this.renderStep(t('上一步'), 'general-info-tab', true)     
                            }
                            nextButton={
                                this.renderStep(t('下一步'), 'search-product-tab', !this.state.title || !this.state.description)     
                            }
                        />
                       </div>
            }
            case 'search-product-tab': 
            {
                return <div className='subtitle-header'>
                        <MobileStepper
                            variant='dots'
                            steps={4}
                            position='static'
                            activeStep={1}
                            backButton={
                                this.renderStep(t('上一步'), 'general-info-tab', false)
                            }
                            nextButton={
                                this.renderStep(t('下一步'), 'product-detail', false)
                            }
                        />
                       </div>
            }
            case 'product-detail': 
            {   
                return <div className='subtitle-header'>
                        <MobileStepper
                            variant='dots'
                            steps={4}
                            position='static'
                            activeStep={2}
                            backButton={
                                this.renderStep(t('上一步'), 'search-product-tab', false)
                            }
                            nextButton={
                                this.renderStep(t('下一步'), 'view-advice', false)
                            }
                        />
                       </div>
            }
            case 'view-advice': 
            {    
                return <div className='subtitle-header'>
                        <MobileStepper
                            variant='dots'
                            steps={4}
                            position='static'
                            activeStep={3}
                            backButton={
                                this.renderStep(t('上一步'), 'product-detail', false)
                            }
                            nextButton={
                                this.renderStep(t('下一步'), 'view-advice', true)
                            }
                        />
                       </div>
            }
        }
    }

    renderUnderHeader(){
        const {adviceType} = this.props;

        const searchForm = (
            this.state.tabValue==='search-product-tab' && adviceType==='chanpin' ?
            <div className='advanced-product-search-form-wrapper'>
                    <AdvancedProductSearchForm 
                        {...this.state.searchFilters} docSpecs={this.state.docSpecs!} languageCode={this.props.languageCode!}
                        ref={e=>{ this.searchForm = e!}}
                        triggerSearch={(filters)=>{ 
                            this.searchProducts(this.state.page, this.state.pageSize, filters)
                        }} 
                    />
            </div>:null);

        return (
            <div className='create-health-advice-under-header'>
                {this.renderSubTitle()}    
                {searchForm}
            </div>)
    }

    renderSucceedContent():JSX.Element{
        return (
            <Fragment>
                {this.state.tabValue==='general-info-tab'? this.renderGeneralInformation(): null}
                {this.state.tabValue==='search-product-tab'? this.renderProductTab(): null}
                {this.state.tabValue==='product-detail'? this.renderProductDetail(): null}
                {this.state.tabValue==='view-advice'? this.renderHealthAdvice(): null}

                <Toast close={true} message='' toastType='info' onClose={()=>{}} ref={(e)=>{this.toast=e!}} duration={2500}/>
            </Fragment>
        )
    }

    renderGeneralInformation():JSX.Element {
        const client = this.state.client!;
        const clientProps:PropertyListDataItem[] = [
            {name: t('姓名'), value: client.client},
            {name: t('电邮'), value: <SimpleEmail email={client.email}/>},
            {name: t('电话'), value: <SimplePhoneNumber phoneNumber={client.phoneNumber}/>},
            {name: t('地址'), value: AddressInfo2Str(client.address)},
            {name: t('备注'), value: client.notes||''},
        ];
        
        return (
            <div className='shopping-create-health-advice-content'>
                <h3>{t(`客户信息`)}</h3>

                <PropertyListWidget list={clientProps} className='client-of-nutrtionist' showBorder={true} mergeColScreenLetterRatio={RatioXXS}/>

                <div id='health-advice-title' >
                    <TextField label={t(`标题`)} variant='outlined' size='small' 
                               value={this.state.title}
                               onChange={(evt)=>{
                                   this.setState({title: evt.currentTarget.value, titleError: ''});
                               }}
                    />
                    <div className='error-msg'>{this.state.titleError}</div>
                </div>

                <div id='health-advice-description'>
                    <div>{t(`描述`)} ({t(`不超过1000字`)})</div>
                    <TextareaAutosize minRows={5} 
                                      value={this.state.description}
                                      onChange={(evt)=>{
                                        this.setState({description: evt.currentTarget.value, descriptionError:''});
                                    }}
     
                    />
                    <div className='error-msg'>{this.state.descriptionError}</div>
                </div>

                <div id='health-advice-expiry'>
                    {t(`有效期`)}  
                    <TextField type='number' id='health-advice-expire-days' variant='outlined' size='small'
                               value={this.state.expireDays}
                               
                               onChange={(evt)=>{  
                                   let n = parseInt(evt.currentTarget.value);
                                   this.setState({expireDays: n, expireDaysError: ''});                                         
                               }}
                    /> 
                    {t(`天`)}
                </div>
                <div className='table-footer'>
                    {this.props.adviceType==='test'? 
                        <HTMLDialog 
                            title={t('健康检测操作指南')}
                            errorDialog={this.errorDialog!}
                            loadHTMLFn={readLabTestInstruction}
                        />:null
                    }
                    <UButton variant='contained' color='primary' size='small'
                            onClick={()=>{
                                this.setState({tabValue:'search-product-tab'})
                            }}>{this.props.adviceType==='chanpin'? t('检索产品'): t('健康检测')}</UButton>
                </div>
                <div className='error-msg' style={{width: '100%'}}>{this.state.expireDaysError}</div>
        </div>
        )
    }

    renderProductTab():JSX.Element {
        const {adviceType} = this.props;

        if(adviceType === 'chanpin'){
            return this.renderSearchProducts();
        }else{
            return this.renderLabTestProducts();
        }
    }

    renderSearchProducts():JSX.Element{
        return (
            <div className='shopping-create-health-advice-content'>
                <SimpleNote message={t('点击产品图片查看详情')} noticeType='info'/>

                <Pagination data={this.state.searchResult!} page={this.state.page} pageSize={this.state.pageSize} 
                            loadData={this.loadPaginationData}
                            changePageSize={(newPageSize, newPage)=>{
                                this.loadPaginationData(newPage, newPageSize);
                            }}
                >
                { this.renderProductBriefList(this.state.searchResult!.content) }
                </Pagination>

            </div>
        )
    }

    renderLabTestProducts():JSX.Element {
        return (
            <div className='shopping-create-health-advice-content'>
                <SimpleNote message={t('点击产品图片查看详情')} noticeType='info'/>

                { this.renderProductBriefList(this.state.labProducts!) }
            </div>
        )

    }

    addProductToHealthAdvice(productCode:string, 
                             productName:string, 
                             productType:'test'|'chanpin'){
        const {adviceType} = this.props;

        let keyIngredients = [] as KeyIngredient[];
        if(adviceType!=='test'){
            keyIngredients = this.productDetail!.getKeyIngredients();
            if(!keyIngredients || keyIngredients.length<1){
                this.errorDialog!.show(t('加入建议书'), t('请选择重要功效成分'), ()=>{});
                return;
            }    
        }

        let products = this.state.products;
        let p = products.find(x=> x.productCode === productCode);
        if(p){
            p.count ++;
            p.keyIngredients = keyIngredients;
        }else{
            products.push({productCode, productName, productType, count: 1, keyIngredients})
        }
        this.setState({products}, ()=>{
            this.toast!.sendMessage(t('加入产品')+productName, 'info');
        });
    }

    loadProductDetail(productCode:string){
        const {adviceType} = this.props;

        doAJAXCall({
            whatIsFor: t('下载产品细节'),
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken)=>{ 
                            let product = await retreiveShoppingProductDetail(accessToken, 
                                                                            productCode, 
                                                                            this.props.currencyCode);
                            if(adviceType==='chanpin'){
                                if(!product.ingredients){
                                    alert(t(`该产品没有功效成份`));
                                    return;    
                                }else{
                                    appendIngredientTabData(product, true);
                                }
                            }
                            this.setState({product, tabValue:'product-detail'});
                        },
            onError: (errorMessage:string)=>{ this.setState({pageInitState:'PAGE_LOAD_FAILED', initErrorMessage:errorMessage})}                                           
        })
    }

    renderProductBriefList = (list:ProductCandidateBrief[]):JSX.Element => {
        const {maxImgWidth, maxImgHeight} = getMaxThumbnailImgSize();
        const {ratioClass, vw,  mw, ratio} = calcRationClass();
        let xs = 12;

        if(ratio >= RatioXXXS*2){
            xs = 6;
        }
        if(ratio >= RatioXXXS*3){
            xs = 4;
        }
        if(ratio >= RatioXXXS*4){
            xs = 3;
        }
        if(ratio >= RatioXXXS*6){
            xs = 2;
        } 
        if(ratio >= RatioXXXS*12){
            xs = 1
        }

        const currencyOptionsMap = getCurrencyOptionsMap();

        // set Grid spacing=0 to avoid overflow-x which causing horizontal bar to appear!
        return (
            <Grid container spacing={0}>
            {
                list.map((prod, idx)=>{
                    const currencyOption = getCurrencyOption(currencyOptionsMap, 
                                                            this.props.languageCode!, 
                                                            prod.currencyCode);

                    return  <Grid item xs={xs as 6} key={idx+'-'+prod.productName}>
                                <ShoppingProductBrief
                                    prod={prod}
                                    currencyOption={currencyOption}
                                    onClickImage={()=>{
                                        this.loadProductDetail(prod.productCode);
                                    }}
                                />
                            </Grid>
                })
            }
            </Grid>
        )
    }

    renderProductDetail():JSX.Element{
        const {adviceType} = this.props;
        const product = this.state.product!;
        const productCode = product ? getProductCode(product): undefined;

        return (
            <div className='shopping-create-health-advice-content'>
                {product ?
                    <Fragment>
                        {adviceType==='chanpin'? <SimpleNote message={t('选择功效成份，然后加入建议书')} noticeType='info'/>:null}

                        <HealthAdviceProductDetail product={product} languageCode={this.props.languageCode!} 
                                                    currencyCode={this.props.currencyCode} highlightWords={[]}
                                                    ref={e=>{ this.productDetail = e! }}
                        >                                               
                        </HealthAdviceProductDetail>   
                        <Grid container className='action-buttons'>
                            <Grid item xs={12} sm={6}>
                                <UButton color='secondary' variant='contained' size='small' 
                                    onClick={()=>{ 
                                        this.setState({tabValue:'search-product-tab'})
                                    }}>{t('继续搜索产品')}</UButton>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <UButton color='secondary' variant='contained' size='small'
                                        onClick={()=>{ this.goBack() }}
                                >{t(`取消/返回`)}</UButton>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <UButton color='secondary' variant='contained' size='small' 
                                    onClick={()=>{ 
                                        this.setState({tabValue:'view-advice'})
                                    }}>{t('查看建议书')}</UButton>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <UButton className='add-product-detail-to-health-advice' 
                                    variant='contained' color='primary' size='small'
                                    onClick={()=>{ this.addProductToHealthAdvice(
                                                            productCode!, 
                                                            product.productName, 
                                                            product.productType==='test'? 'test':'chanpin'
                                                        )  
                                                 }
                                            }
                                    >{t(`加入建议书`)}</UButton>
                            </Grid>
                        </Grid>      
                    </Fragment> :
                    <div className='search-product-btn-wrapper'>
                        <UButton color='primary' variant='contained' size='small' 
                                    onClick={()=>{ 
                                        this.setState({tabValue:'search-product-tab'})
                                    }}>{t('请检索产品')}</UButton>
                    </div>
                }
            </div>
        )
    }

    renderHealthAdvice():JSX.Element{
        return (
            <div className='shopping-create-health-advice-content'>
                {this.renderProductsInHealthAdvice()}
            </div>
        )
    }

    componentDidMount(){
        this.loadData();
    }
    
    componentDidUpdate(prevProps:any, prevState:CreateHealthAdviceViewState){
        if(prevState.page !== this.state.page){
            scrollBodyToTop();
        }
        this.saveAdviceInLS();
    }


    initializeAgain(){
        this.setState({pageInitState:'PAGE_INIT', initErrorMessage:undefined},
                      ()=>{
                          this.loadData();
                      })
    }
    loadPaginationData = (page:number, pageSize:number)=>{
        this.searchProducts(page, pageSize, this.searchForm!.getFilters())
    }
    searchProducts = (page:number, pageSize:number, filters:AdvancedProductSearchFilter) => {
        const {maxImgWidth, maxImgHeight} = getMaxThumbnailImgSize();

        doAJAXCall({
                        whatIsFor:t(`搜索产品`),
                        errorDialog: this.errorDialog!,
                        ajaxCall: async (accessToken)=>{
                            const {maxImgWidth} = getMaxThumbnailImgSize();
                            await loadExternContatnsIfNecessary();
                            let searchResult = await advancedSearchShoppingProducts(
                                                        accessToken, 
                                                        {
                                                            type:'chanpin', 
                                                            thumbnailWidth: maxImgWidth,
                                                            application: filters.symptomKeywords, 
                                                            classification: filters.professionalChoice,
                                                            declaration: filters.functionalChoice,
                                                            name: filters.productName, 
                                                            ingredient:filters.ingredient,
                                                            fullText: filters.fullTextKeywords, 
                                                            fuzziness: filters.fuzziness,
                                                            page: page, 
                                                            size: pageSize
                                                        },
                                                        this.props.currencyCode
                                                    );
                            this.setState({searchResult, page: searchResult.page, pageSize: searchResult.size});    
                        }
                   });
    }


    loadData(){
        const {adviceType, currencyCode} = this.props;

        doAJAXCall({
                    whatIsFor:t(`下载数据`),
                    errorDialog: this.errorDialog!,
                    ajaxCall: async (accessToken)=>{
                        let client = await retrieveClient(accessToken, this.props.clientUsername);
                        let docSpecs = await getDocSpecs(accessToken);

                        const {maxImgWidth, maxImgHeight} = getMaxThumbnailImgSize();

                        const {title, description, expireDays, products} = this.readAdviceInLS(this.props.clientUsername) || {
                                                    username: '',
                                                    clientUsername: '',
                                                    description: this.state.description,
                                                    title: this.state.title,
                                                    expireDays: this.state.expireDays,
                                                    products: this.state.products,            
                                            };


                        let searchResult = adviceType!=='test'? await advancedSearchShoppingProducts(
                                                        accessToken, 
                                                        {
                                                            type:'chanpin', 
                                                            thumbnailWidth: maxImgWidth,
                                                            application: this.state.searchFilters.symptomKeywords, 
                                                            classification: this.state.searchFilters.professionalChoice? undefined : this.state.searchFilters.professionalChoice as string,
                                                            declaration: this.state.searchFilters.functionalChoice?undefined : this.state.searchFilters.functionalChoice as string,
                                                            name: this.state.searchFilters.productName, 
                                                            ingredient:this.state.searchFilters.ingredient,
                                                            fullText: this.state.searchFilters.fullTextKeywords, 
                                                            fuzziness: this.state.searchFilters.fuzziness,
                                                            page: this.state.page, 
                                                            size: this.state.pageSize,
                                                        },
                                                        currencyCode
                                                ): undefined;

                        let labProducts = adviceType==='test'? await getLabTestProducts(accessToken, currencyCode): undefined;

                        this.setState({
                                        client, 
                                        docSpecs, 
                                        searchResult, 
                                        labProducts,
                                        pageInitState:'PAGE_LOAD_SUCCESS', 
                                        page:searchResult? searchResult.page: 0,
                                        title, 
                                        description, 
                                        expireDays, 
                                        products
                                    })
                    },
                    onError: (errorMessage:string)=>{ this.setState({pageInitState:'PAGE_LOAD_FAILED', initErrorMessage:errorMessage})}
                })
    }
}

export const CreateHealthAdvice = connect(
    (state:WalletAppState)=>({languageCode: state.language.langCode, currencyCode: state.currency.currencyCode})
)(CreateHealthAdviceView);