import * as React from 'react';
import { MenuItemData, MenuItemDirData } from './common';
import classnames from 'classnames';
import {ReactComponent as LeftArrowIcon} from '../images/solid-left-arrow.svg';
import { doIHaveOneOfRoles } from '../api/user';

interface MyProps {
    menuDirData: MenuItemDirData;
    /** 0 for top level menus */
    level: number;
    /** is it current active menu dir pane */
    active: boolean;
    /** index of menu dir item that is selected */
    currentMenuIdx?: number;

    /** menu select index up to this menu dir */
    currentMenuIdxArray: {idxOfActiveMenuDirItem:number}[];

    selectMenuDirFn: (level:number, menuDirIndex: number)=>void;
    backMenuDirFn: ()=>void;
    clickLeafMenu: (menuData:MenuItemData)=>void;

}
interface MyState {
    slide?: boolean;
    slideOut?: boolean;
}

export class UnishellTreePane extends React.Component<MyProps, MyState>{
    constructor(props: MyProps){
        super(props);
        this.state = {slide: false}
    }

    render() {
        const {menuDirData} = this.props;
        return (
            <div className={classnames('menu-dir', 
                                       'level'+this.props.level, 
                                       {active: this.props.active},
                                       {inactive: !this.props.active},
                                       {slide: !!this.state.slide},
                                       {'slide-out': !!this.state.slideOut}
                                       )}
                onClick={evt=>{
                    evt.stopPropagation();
                    evt.preventDefault();
                }}                                       
            >
                <div className='menu-dir-title'>
                    <LeftArrowIcon className='back-icon' 
                                    onClick={(evt)=>{
                                        evt.stopPropagation();
                                        evt.preventDefault();
                                        if(this.props.level === 0){
                                            this.props.backMenuDirFn(); // no need to do animation
                                        }else{
                                            this.setState({slide: false, slideOut: true}, ()=>{
                                                setTimeout(()=>{
                                                    this.props.backMenuDirFn();
                                                }, 500)
                                            })    
                                        }
                                    }}/>    
                    {menuDirData.label}
                </div>
                {menuDirData.children.map((x, idx)=>{
                    if(x.permitRoles !== undefined){
                        if(!doIHaveOneOfRoles(x.permitRoles)){
                            return null; // skip this menu item
                        }
                    }
                    
                    if(x.type === 'leaf'){
                        return <div className={classnames('menu-item',x.type, {select:idx===this.props.currentMenuIdx})}
                                    onClick={(evt)=>{
                                        evt.stopPropagation();
                                        evt.preventDefault();
                                        this.props.clickLeafMenu(x);
                                    }}
                                    key={x.label}
                               >{x.label}</div>
                    }else{
                        return <div className={classnames('menu-item',x.type, {select:idx===this.props.currentMenuIdx})}
                                    onClick={(evt)=>{
                                        evt.stopPropagation();
                                        evt.preventDefault();
                                        if(this.props.active){
                                            this.props.selectMenuDirFn(this.props.level, idx);
                                        }
                                    }}
                                    key={x.label}
                                ><span>{x.label}</span> <strong>{'>'}</strong></div>
                    }
                })}
            </div>
        )
    }
    componentDidMount(){
        if(this.props.level>0){
            setTimeout(()=>{
                this.setState({slide: true});
            }, 100);
        }
    }
}