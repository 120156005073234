
// keep track current page to display
export const SET_PAGE_PATH = 'SET_PAGE_PATH';

export type CURRENT_PAGE = 'login' | 'login/register-user'
                            |'lost-password'
                            |'unishell-pay'
                            | 'main'
                            | 'main/tools' 
                            | 'main/wallet' 
                            | 'main/wallet/asset' 
                            | 'main/wallet/purchase-points'
                            | 'main/wallet/transfer-list'
                            // | 'main/wallet/receive-transfer'
                            | 'main/wallet/payment-order-list' 

                            | 'main/wallet/receive-transfer/receive' 
                            | 'main/wallet/receive-transfer/manual-transfer' 
                            | 'main/wallet/receive-transfer/scan-transfer'
                            | 'main/wallet/receive-transfer/transfer-list'

                            | 'main/my-profile'
                            | 'main/my-profile/settings'
                            | 'main/tools/upgrade-app'
                            | 'main/tools/terms'
                            | 'main/tools/privacy'
                            | 'main/tools/send-message'
                            | 'main/tools/pickup-location'
                            | 'main/tools/update-prod-index'
                            | 'main/tools/commission'
                            | 'main/tools/search-users'
                            | 'main/tools/search-products'
                            | 'main/tools/my-bookmarks'
                            | 'main/shopping/search'
                            | 'main/shopping/product'
                            | 'main/tools/my-nutritionist'
                            | 'main/tools/set-nutritionist'
                            | 'main/tools/my-health-advice'
                            | 'main/tools/nutritionist-list'
                            | 'main/shopping/nutritionist-profile'
                            | 'main/shopping/edit-nutritionist-profile'
                            | 'main/shopping/edit-licence'
                            | 'main/tools/client-list'
                            | 'main/shopping/create-health-advice'
                            | 'main/tools/health-advice-by-me'
                            | 'main/shopping/my-shopping-cart'
                            | 'main/shopping/checkout'
                            | 'main/shopping/payment'
                            | 'main/shopping/my-orders'
                            | 'main/shopping/pharmacy-dashboard'
                            | 'main/shopping/order-dashboard'
                            | 'main/shopping/my-commissions'
                            | 'main/shopping/all-commissions'
                            | 'main/shopping/distribute-commissions'
                            | 'main/shopping/switch-currency'
                            | ''
                            ; 
export interface PagePathState {
    currentPage: CURRENT_PAGE;
    parms?: {[key: string]: any};

    prevPage?: CURRENT_PAGE;
    prevParms?: {[key: string]: any};
}

export interface SetPagePathAction {
    type: typeof SET_PAGE_PATH;
    currentPage: CURRENT_PAGE;
    parms?: {[key: string]: any}
}

export type PagePathActionTypes = SetPagePathAction;

export function backPath(path:CURRENT_PAGE):CURRENT_PAGE {
    let idx = path.lastIndexOf('/');
    if(idx<0){
        return '';
    }else{
        // @ts-ignore
        return path.substring(0, idx);
    }
}

export function getPathParm<T>(parms: {[key: string]: any}|undefined, key:string, dflt:T){
    if(parms){
        if(parms[key] !== undefined){
            return parms[key] as T;
        }
    }
    return dflt;
}