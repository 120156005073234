import Checkbox from '@material-ui/core/Checkbox';
import * as _ from 'lodash';
import * as React from 'react';
import {Component} from 'react';
import { connect } from 'react-redux';
import { loadPrivateKey, signMessage } from '../../cypto/crypto';
import { loadExternContatnsIfNecessary } from '../../external-constants/extern-constants';
import { t } from '../../i18n';
import { readLastLoginEmail, readLoginSession, readPersistData } from '../../local-storage/local-storage';
import { WalletAppState } from '../../redux-store';
import { BaseUnishellPageState, renderInitErrorUnishellPage, renderInitUnishellPage, renderUnishellPage } from '../../unishell-page-common/unishell-page-common';
import { encryptPayment, formatDateTime, formatPrice, genPaymentKey, getCurrencyOption, getMaxThumbnailImgSize, getServerURL, RatioXXS } from '../../util/Util';
import { NoticeDialog } from '../../view/NoticeDialog';
import { PasswordDialogView } from '../../view/password-dialog/PasswordDialogView';
import { Toast } from '../../view/Toast';
import { UButton } from '../../widget/button/ubutton';
import { PropertyListDataItem, PropertyListWidget } from '../../widget/property-list/property-list';
import { CurrencyOption, ProductCandidateBrief, ShopOrderCommissionDistributionReport, ShopOrderCommissionInfo } from '../ajax-data/ajax-data-types';
import { distributeShopOrderCommissions, getCommissionStatuOptions, getCurrencyOptionsMap, getShopOrderCommissionDistributionReport } from '../ajax-data/shopping-server-api';
import { doAJAXCall } from '../common/ajax-call';
import { ImageWithOverlay } from '../common/image-with-overlay';
import { BaseShoppingPageProps } from '../common/shopping-page-common';
import { gotoLoginPage } from '../history/shopping-history';

import './distribute-commission.scss';

interface Props extends BaseShoppingPageProps{

}

interface States extends BaseUnishellPageState{
    currencyOptionsMap?: {[key:string]:CurrencyOption[]};
    commissionStatusOptions?:{key:string, text:string}[];

    report?: ShopOrderCommissionDistributionReport;
    skipOrders: {[key:string]: boolean};
}

class DistributeCommissionView extends Component<Props, States>{
    private errorDialog:NoticeDialog|undefined;
    private passwordDialog: PasswordDialogView | undefined;
    private toast: Toast | undefined;

    constructor(props:Props){
        super(props);

        this.state = {pageInitState:'PAGE_INIT', skipOrders: {}}
    }

    getTitle(){
        return t('分配商城咨询费');
    }

    render(){
        if(this.state.pageInitState === 'PAGE_INIT'){
            return <React.Fragment>
                        {renderInitUnishellPage({title: this.getTitle(), message:t('初始化...')})}
                        <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                    </React.Fragment>
        }
        if(this.state.pageInitState === 'PAGE_LOAD_FAILED'){
            return <React.Fragment>
                        {renderInitErrorUnishellPage({title: this.getTitle(), 
                                                      errorMessage: this.state.initErrorMessage||t('初始化失败'),
                                                      onTryAgain: ()=>{ this.initializeAgain();    }
                                                      })}
                        <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                    </React.Fragment>
        }
        // loading data succeeded
        let content = this.renderSucceedContent();
        return (
            <React.Fragment>
                { renderUnishellPage({title: this.getTitle(), className:'distribute-shop-order-commissions', content})}
                <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                <PasswordDialogView confirmLabel={t('同意')} cancelLabel={t('取消')} 
                                    onClose={()=>{ }}
                                    ref={e=>{this.passwordDialog=e!}} 
                />
                <Toast close={true} message='' toastType='info' onClose={()=>{}} ref={(e)=>{this.toast=e!}} duration={2500}/>
            </React.Fragment>
        )   
    }

    renderSucceedContent():JSX.Element {
        return (
            <div className='distribute-shop-order-commissions-main-pane'>
                {this.renderSummary()}
                {this.renderCommissions(this.state.report!.commissions)}
            </div>
        )
    }

    distribute(arg:{
                        totalAmount: number;
                        orderCount: number;
                        commissionCount: number;
                        latestOrderCreatedDtm: number;
                        commissions: ShopOrderCommissionInfo[]
                }){

        const effectiveReport = _.cloneDeep(this.state.report!); 

        effectiveReport.commissions = arg.commissions;
        effectiveReport.commissionCount = arg.commissionCount;
        effectiveReport.totalAmount = arg.totalAmount;
        effectiveReport.orderCount = arg.orderCount;
        effectiveReport.latestOrderCreatedDtm = arg.latestOrderCreatedDtm;
        effectiveReport.timestamp = Date.now();

        const session = readLoginSession();
        if(!session){
            gotoLoginPage();
            return;
        }
        const localData = readPersistData(session!.profile!.username);
        if(!localData){
            this.toast!.sendMessage(t('数据出错。请联系Unishell客服'), 'error', false);
            return;
        }

        const handleConfirmation = (password:string, confirmed:boolean)=>{
            if(!confirmed){
                return;
            }
            const paymentKey = genPaymentKey(readLastLoginEmail()!.toLowerCase()+password);
            const nonce = ''+Math.random();
            // @ts-ignore
            effectiveReport.nonce = nonce;
        
            doAJAXCall({
                whatIsFor:t('分配商城咨询费'),
                errorDialog: this.errorDialog!,
                ajaxCall: async (accessToken)=>{
                    let keyManager = await loadPrivateKey(localData!.keyPair.priKey);
                    let {signedMessage} = await signMessage(keyManager, effectiveReport);
                    let encryptedData = encryptPayment(signedMessage, paymentKey);
                    await distributeShopOrderCommissions(accessToken, 
                                                        {keyId: localData.pkeyHash, data: encryptedData}); 
                    const {maxImgWidth} = getMaxThumbnailImgSize();
                    let report = await getShopOrderCommissionDistributionReport(accessToken, maxImgWidth);
                    this.setState({report}, ()=>{
                        this.toast!.sendMessage(t('分配咨询费成功'), 'success', false);
                    });    
                }
            })
        }

        this.passwordDialog!.show(<div>{t(`确认分配商城咨询费`)}</div>, handleConfirmation);
    }

    renderSummary():JSX.Element{
        const selectedCommissions = this.state.report!.commissions.filter(x=>!this.state.skipOrders[x.orderCode]);
        
        let totalAmount = 0;
        selectedCommissions.forEach(x=>{
            totalAmount += x.commissionTotal;
        });

        let orderCount = selectedCommissions.length;

        let commissionCount = 0;
        selectedCommissions.forEach(x=>{
            x.commissionItem.forEach(v=>{
                commissionCount ++;
            })
        });

        const nowTimestamp = Date.now(); 

        let oldest = nowTimestamp+10000;
        let latestOrderCreatedDtm = 0;

        selectedCommissions.forEach(x=>{
            oldest = Math.min(oldest, x.orderModifiedDtm);
            latestOrderCreatedDtm = Math.max(latestOrderCreatedDtm, x.orderModifiedDtm);
        })

        const currencyOption = getCurrencyOption(this.state.currencyOptionsMap!, 
                                                this.props.languageCode!, 
                                                'UNIS');

        const summaryProps:PropertyListDataItem[]=[
            {name:t('总金额'), valueClassName:'price', value: formatPrice(totalAmount, currencyOption.symbol, currencyOption.label)},
            {name:t('订单数'), value: orderCount},
            {name:t('产品数'), value: commissionCount},
            {name:t('最近时间'), ignore: orderCount <= 0, value: formatDateTime(new Date(latestOrderCreatedDtm))},
            {name:t('最远时间'), ignore: orderCount <= 0, value: formatDateTime(new Date(oldest))},
        ];

        return (
            <div className='summary'>
                <h3>{t('小结')}</h3>
                <PropertyListWidget list={summaryProps} showBorder={true} mergeColScreenLetterRatio={RatioXXS}/>
                <UButton color='primary' variant='contained' className='distribute-button' disabled={orderCount <= 0}
                         onClick={()=>{
                             this.distribute({
                                 totalAmount,
                                 latestOrderCreatedDtm,
                                 orderCount,
                                 commissionCount,
                                 commissions: selectedCommissions
                             })
                         }}
                >{t('分配咨询费')}</UButton>
            </div>
        )
    }

    renderCommissions(content:ShopOrderCommissionInfo[]){
        const serverURL = getServerURL();

        return (
            content.map(x=>{
                let status = '';
                let statusOpt = this.state.commissionStatusOptions!.find(o=> o.key === x.commissionStatus);
                if(statusOpt){
                    status = statusOpt.text;
                }

                const currencyOption = getCurrencyOption(this.state.currencyOptionsMap!, 
                                                         this.props.languageCode!, 
                                                         'UNIS');
                let productBriefs:ProductCandidateBrief[] = [];
                x.commissionItem.forEach(item=>{
                    productBriefs.push(item.product);
                })                                                             

                const checked = !this.state.skipOrders[x.orderCode];

                const orderProps:PropertyListDataItem[]=[
                    {
                        name: t('咨询费'), 
                        valueClassName:'price', 
                        value: <div>
                                {formatPrice(x.commissionTotal, currencyOption.symbol, currencyOption.label)}
                                &nbsp;&nbsp;
                                <Checkbox   checked={checked} color='primary' 
                                            onChange={()=>{
                                                if(checked){
                                                    this.state.skipOrders[x.orderCode] = true;
                                                }else{
                                                    delete this.state.skipOrders[x.orderCode];
                                                }
                                                this.forceUpdate();
                                            }} />
                               </div>
                    },
                    {name: t('状态'), value: status},
                    {name: t('订单号'), value: x.orderCode},
                    {name: t('营养师'), value: x.nutritionistName},
                    {name: t('客户'), value: x.orderUserName},
                    {name: t('更新'), value: formatDateTime(new Date(x.orderModifiedDtm))},
                ];
                x.commissionItem.forEach((item, index)=>{
                    orderProps.push({
                        name: <div>
                                <p>{t('产品')}#{index+1}</p>
                                <ImageWithOverlay imageList={[item.product.thumbImage.startsWith('http')? item.product.thumbImage : serverURL+item.product.thumbImage]} classes='prod-thumb-img'/>
                            </div>,
                        value: <div>
                                    <p>{t('名称')}:{item.product.productName} x {item.product.count}</p>
                                    <p>{t('咨询费')}:<span className='price'>{formatPrice(item.commissionSubTotal, currencyOption.symbol, currencyOption.label)}</span></p>
                               </div>                            
                    })
                })
          

                return <div key={x.orderCode} className='shop-order-commission'>
                           <PropertyListWidget list={orderProps} showBorder={true} mergeColScreenLetterRatio={RatioXXS}/>
                        </div>
            })
        )
    }


    componentDidMount(){
        this.loadData(true);
    }

    initializeAgain(){
        this.loadData(true);
    }

    loadData(isInitialization:boolean){
        const {maxImgWidth} = getMaxThumbnailImgSize();

        doAJAXCall({
            whatIsFor: t('下载商城咨询费'),
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken)=>{ 
                    let report = await getShopOrderCommissionDistributionReport(accessToken, maxImgWidth);
                    const skipOrders: {[key:string]: boolean} = {};

                    // in case missing nutritionist username
                    report.commissions.filter(x=> !x.nutritionistUsername).forEach(x=>{                        
                        skipOrders[x.orderCode] = true;
                    });

                    if(isInitialization){
                        await loadExternContatnsIfNecessary();
                        let currencyOptionsMap = getCurrencyOptionsMap();
                        let commissionStatusOptions = await getCommissionStatuOptions(accessToken, this.props.languageCode!);
    
                        this.setState({report, pageInitState:'PAGE_LOAD_SUCCESS', currencyOptionsMap, commissionStatusOptions, skipOrders});    
                    }else{
                        this.setState({report, skipOrders});    
                    }                                                
            },
            onError: (errorMessage:string)=>{ this.setState({pageInitState:'PAGE_LOAD_FAILED', initErrorMessage:errorMessage})}                                           
        })        
    }
} 


export const DistributeCommission = connect(
    (state:WalletAppState)=>({languageCode: state.language.langCode})
)(DistributeCommissionView);
