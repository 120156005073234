import * as React from 'react';
import {Component, Fragment} from 'react';

import './PurchasePointsView.scss';
import {t} from '../../i18n';
import { ReactReduxContext } from 'react-redux';
import { Paper, Button } from '@material-ui/core';
import { WalletAppState } from '../../redux-store';
import { getBrainTreeClientToken, createBrainTreeTransaction, 
         prepareSnappay, completeSnappay, getCheckoutBrief, 
         prepareStripePayment, completeStripePayment,getStripeKey, getPaymentConfig } from '../../wallet-server-api/wallet-server-api';
import { getAjaxErrorMessage } from "../..//wallet-server-api/ajax-common";
import { NoticeDialog } from '../NoticeDialog';
import { readLoginSession, getLanguageCode } from '../../local-storage/local-storage';

import TextField from '@material-ui/core/TextField';
import { calcRationClass, getPaymentSupport, getStripeErrorMessage, isNumeric, RatioXXS, round } from '../../util/Util';
import {CheckCircle} from '@material-ui/icons';
import { UnishellOrderSummary } from '../../api/purchase-points';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import { Radio } from '@material-ui/core';

import { ImageWithFallback } from '../image-fallback/ImageWithFallback';
import AlipayLogo  from '../../images/Alipay_logo.svg';
import WechatpayLogo from '../../images/Wechat_Pay_Chinese_Logo.svg';
import PaypalLogo from '../../images/paypal.png';
import CreditCardLog from '../../images/credit-card-logos.png';

// @ts-ignore
import dataFormat from 'date-format';
import { getCordova, checkPaymentAppAvailability, getWechat, info } from '../../globals';
import { SnappayCompleteRequest, SnappayMethod } from '../../api/purchase-points';

import {Elements,ElementsConsumer, CardElement, CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements} from '@stripe/react-stripe-js';
import {loadStripe, StripeCardElement, Stripe, StripeCardNumberElement, StripeErrorType, StripeError} from '@stripe/stripe-js';
import './StripeCard.scss';
import { PaymentConfig } from '../../api/common';
import { BaseUnishellPageState, renderInitErrorUnishellPage, renderInitUnishellPage, renderUnishellPage } from '../../unishell-page-common/unishell-page-common';
import { gotoLoginPage, shoppingGoBack } from '../../shopping/history/shopping-history';
import { doAJAXCall } from '../../shopping/common/ajax-call';
import { LANG_CODE_EN, loadExternContatnsIfNecessary } from '../../external-constants/extern-constants';
import { UButton } from '../../widget/button/ubutton';
import { VEqualSize } from '../../widget/equal-sizing/v-equal-size';
import { SimpleNote } from '../../widget/note/note';
import classnames from 'classnames';
import { PropertyListDataItem, PropertyListWidget } from '../../widget/property-list/property-list';

let stripePK:string = ''; //'pk_test_xxxxxx';
let stripePromise:Promise<Stripe|null>; // loadStripe(stripePK);

type PaymentMethod = 'paypal'|'alipay'|'wechatpay'|'stripe';

interface MyState extends BaseUnishellPageState {
    paymentConfig?: PaymentConfig,
    purchaseStep: number; // start from 0 to 20
    
    pointsInput?: string;
    pointsError?: string;
    points?: number;

    paymentMethod? : PaymentMethod;

    braintreeClientToken?: string; // braintree client token
    braintreeInstance?: any;

    orderSummary?:UnishellOrderSummary;

    dispatch?:(action:any)=>void;

    t0?: number; // start time to check order status

    supportAlipay?: boolean;
    supportWechatPay?: boolean;
}

interface MyProps {
    dispatch:(x:any)=>void
    returnPath?: string;
    returnPathLabel?: string;
}

declare var braintree:any;

export class PurchasePointsView extends Component<MyProps,MyState>{
    private errorDialog: NoticeDialog | undefined;
    private dropinContainer: HTMLDivElement | undefined;
    private processingStripePayment = false;

    private debugMessages:string[] = [];

    constructor(props:MyProps){
        super(props);
        this.state = {purchaseStep:0, pageInitState:'PAGE_INIT'};
    }

    resetState = ()=>{
        this.processingStripePayment = false;
        this.setState({purchaseStep:1, paymentMethod:undefined, pointsError:undefined, braintreeInstance:undefined, 
            braintreeClientToken:undefined}, ()=>{ this.cleanupBrantreeContainer() })
    }

    completeSnappayResult = (request:SnappayCompleteRequest)=>{
        const whatIsFor = t('完成付费');
        doAJAXCall({
            whatIsFor,
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken)=>{
                const response = await completeSnappay(accessToken, request);
                if(request.isSuccess){
                    // @ts-ignore
                    let orderSummary:UnishellOrderSummary = {
                        points: this.state.points!,
                        orderCode: request.orderCode,
                        time: Date.now(),
                        paymentMethod: request.method
                    }
                    this.setState({purchaseStep:10, t0: Date.now(), orderSummary, points:undefined, pointsInput:undefined, 
                                   pointsError:undefined, braintreeInstance:undefined, braintreeClientToken:undefined}, 
                                   ()=>{ this.cleanupBrantreeContainer() });
                }else{
                    this.resetState();
                }    
            },
            onError: (errorMessage)=>{
                if(request.isSuccess){
                    this.resetState();
                }
            }
        })
    }

    callAlipayApp = (payInfo:string, method: SnappayMethod, orderCode:string)=>{
        if(getCordova()){
            getCordova().plugins.alipay.payment(payInfo, (e: any)=>{
                this.completeSnappayResult({isSuccess: true, orderCode, method, result:JSON.stringify(e)})
              },
              (e: any)=>{
                  this.errorDialog!.show(t("付费失败"), t("付费失败") + e.resultStatus, ()=>{
                    this.completeSnappayResult({isSuccess: false, orderCode, method, result:t("付费失败") + e.resultStatus})
                  } )
              });   
        }else{
            this.errorDialog!.show(t("付费失败"), t("不支持支付宝"), ()=>{
                this.completeSnappayResult({isSuccess: false, orderCode, method, result:"not running in app"})
              } )
        }
    }

    initAlipay = () => {
        const whatIsFor = t('初始化支付宝');
        doAJAXCall({
            whatIsFor,
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken)=>{
                const response = await prepareSnappay(accessToken, {points: this.state.points!, method: 'AliPay'});
                this.setState({purchaseStep:4.2}, ()=>{
                    // alert('init ali pay '+JSON.stringify(response));
                    let payInfo: string = response.sdkRequest;
                    let method =response.method as SnappayMethod;
                    let orderCode: string=response.orderCode;
         
                    this.callAlipayApp(payInfo, method, orderCode);
                })    
            },
            onError: ()=>{
                this.resetState();
            }
        })
    }

    callWechatPayApp = (payInfo:any, method: SnappayMethod, orderCode:string)=>{
        if(getCordova()){
            getWechat().sendPaymentRequest(payInfo, ()=>{
                this.completeSnappayResult({isSuccess: true, orderCode, method, result:''})
              },
              (error: string)=>{
                  this.errorDialog!.show(t("付费失败"), t("付费失败") + ':' + error, ()=>{
                    this.completeSnappayResult({isSuccess: false, orderCode, method, result:error})
                  } )
              });   
        }else{ // 
            this.errorDialog!.show(t("付费失败"), t("不支持微信支付"), ()=>{
                this.completeSnappayResult({isSuccess: false, orderCode, method, result:"not running in app"})
              } )
        }
    }


    initWechatPay = () => {
        const whatIsFor = t('初始化微信支付');
        doAJAXCall({
            whatIsFor,
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken)=>{
                const response = await prepareSnappay(accessToken, {points: this.state.points!, method: 'WechatPay'});
                this.setState({purchaseStep:5.2}, ()=>{
                    // alert('init wechat pay '+JSON.stringify(response));
                    let payInfo = JSON.parse(response.sdkRequest);
                    let method =response.method as SnappayMethod;
                    let orderCode: string=response.orderCode;
         
                    this.callWechatPayApp(payInfo, method, orderCode);
                })
            },
            onError: ()=>{
                this.resetState();
            }
        })
    }

    cleanupBrantreeContainer = ()=>{
        if(this.state.braintreeInstance) return;

        let div = document.getElementById('dropin-container');
        if(div){
            div!.innerHTML = '';
        }
    }

    initStripe = ()=>{
        let publicKey:string = '';
        const whatIsFor = t('初始化Stripe接口');
        doAJAXCall({
            whatIsFor,
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken)=>{
                const pk = await getStripeKey(accessToken);
                publicKey = pk; // save it
                if(pk !== stripePK){
                    info(Date.now()+" loadStripe");
                    stripePromise = loadStripe(pk);
                }else{
                    info(Date.now()+" reuse stripePromise");
                }
                stripePK = publicKey; // save public key
                this.setState({paymentMethod:'stripe', purchaseStep: 6.2});    
            },
            onError: ()=>{
                this.resetState();
            }
        })
    }

    initBrainTreeDropin = ()=>{
        const whatIsFor = t('初始化Paypal');
        doAJAXCall({
            whatIsFor,
            errorDialog:this.errorDialog!,
            ajaxCall: async (accessToken)=>{
                const clientToken = await getBrainTreeClientToken(accessToken);
                this.setState({purchaseStep:3.1, paymentMethod:'paypal'}, ()=>{
                    braintree.dropin.create(
                    {
                        authorization: clientToken,
                        container: '#dropin-container',
                        locale: "zh-CN",
                        paypal: {
                            flow: 'vault'
                        }
                        /*,
                        card : {
                            vault: {
                                vaultCard :true,
                                allowVaultCardOverride: true
                            }
                        }*/
                    }
                    ).then((instance:any)=>{
                        info('brain train initialized succeeded');
                        this.setState({purchaseStep:3.2, braintreeInstance: instance, braintreeClientToken: clientToken});
                    }).catch((createErr:Error)=>{
                        info('brain train initialization failed');
                        info(createErr);
                        this.errorDialog!.show(whatIsFor+t(' 失败'), createErr.message, 
                                                ()=>{
                                                    this.setState({purchaseStep:1, paymentMethod: undefined});
                                                })
                    })
                }) 
            },
            onError: ()=>{
                this.resetState();
            }
        })
    }

    onBraintreePurchase = ()=>{
        this.state.braintreeInstance!.requestPaymentMethod((err:any, payload:any)=>{
            info('onBraintreePurchase', err);
            info('onBraintreePurchase', payload);
            if(err){
                this.errorDialog!.show(t('付费失败'), ''+err, ()=>{});
                return;
            }
            const whatIsFor = t('Paypal支付');
            doAJAXCall({
                whatIsFor,
                errorDialog: this.errorDialog!,
                ajaxCall: async (accessToken)=>{
                    const checkoutResponse = await createBrainTreeTransaction(accessToken, {points: this.state.points!, paymentNonce: payload.nonce});
                    if(checkoutResponse.isSuccess && checkoutResponse.orderSummary){
                        this.state.braintreeInstance.teardown(()=>{
                            this.setState({purchaseStep:10, t0: Date.now(), orderSummary:checkoutResponse.orderSummary, points:undefined, pointsInput:undefined, pointsError:undefined, 
                                            braintreeInstance:undefined, braintreeClientToken:undefined}, ()=>{ this.cleanupBrantreeContainer() });
                        });
                    }else{
                        let status = t(' 失败');
                        if(checkoutResponse.orderSummary){
                            status = checkoutResponse.orderSummary.errorCode!;
                        }
    
                        this.errorDialog!.show(t('付费失败'), status, ()=>{
                            this.state.braintreeInstance.teardown(()=>{
                                this.resetState();
                            });
                        });    
                    }    
                }, 
                onError: ()=>{
                    this.resetState();
                }
            })
        })
    }


    appendDebugMessage = (msg:string): void=>{
        // this.debugMessages.push(Date.now()+" "+msg);
    }

    confirmStripePayment = (stripe: Stripe, card: StripeCardNumberElement, accessToken:string, dispatch:(action:any)=>void) => {
        let whatIsFor = t('信用卡支付');
        let orderCode:string;

        doAJAXCall({
            whatIsFor,
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken)=>{
                this.appendDebugMessage(" calling parepare stripe payment");
                const response = await prepareStripePayment(accessToken, {points: this.state.points!, currency:'CAD'});
                orderCode = response.orderCode;
                this.appendDebugMessage(" calling stripe.confirmCardPayment");
                const result = await stripe.confirmCardPayment(response.clientSecret, 
                                                { payment_method:{
                                                    card,
                                                    billing_details: {}
                                                    }
                                                });
                if(result.error){
                    this.appendDebugMessage(" payment failed");
                    completeStripePayment(accessToken, {isSuccess: false, orderCode, memo: result.error.message!});
                    // info(result.error.code, result.error.type);
                    this.errorDialog!.show(t('付费失败'), getStripeErrorMessage(result.error!), ()=>{
                        this.resetState();
                    });    
                }else{
                    // The payment has been processed!
                    if (result.paymentIntent!.status === 'succeeded') {
                        // Show a success message to your customer
                        // There's a risk of the customer closing the window before callback
                        // execution. Set up a webhook or plugin to listen for the
                        // payment_intent.succeeded event that handles any business critical
                        // post-payment actions.
                        this.appendDebugMessage(" calling payment succeeded");
    
                        completeStripePayment(accessToken, {isSuccess: true, orderCode, memo: 'intent id='+result.paymentIntent!.id});
                        // @ts-ignore
                        let orderSummary:UnishellOrderSummary = {
                            points: this.state.points!,
                            orderCode: orderCode,
                            time: Date.now(),
                            paymentMethod: 'CreditCard'
                        }
                        this.setState({purchaseStep:10, t0: Date.now(), orderSummary, points:undefined, pointsInput:undefined, 
                                    pointsError:undefined, braintreeInstance:undefined, braintreeClientToken:undefined}, 
                                    ()=>{ this.cleanupBrantreeContainer() });
                    }else{
                        this.errorDialog!.show(t('付费失败'), "payment status="+result.paymentIntent!.status, ()=>{
                                this.resetState();
                            });    
                    }
                }
                this.processingStripePayment = false;                                
            },
            onError: ()=>{
                this.resetState();
                this.processingStripePayment = false;
            }            
        })
    }

    getOrderStatus = (tryAgain: ()=>void)=>{
        const whatIsFor = t('等待支付系统确认');
        doAJAXCall({
            whatIsFor,
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken)=>{
                const summary = await getCheckoutBrief(accessToken, this.state.orderSummary!.orderCode);
                if(summary.isSuccess && summary.transferSummary){
                    this.setState({purchaseStep:20, orderSummary:summary.orderSummary});
                }else{
                    tryAgain();
                }    
            },
            errorMessageFromatter: (error)=>{
                return <div>
                            <div>{t('订单号')+':'+this.state.orderSummary!.orderCode}</div>
                            {getAjaxErrorMessage(error)}
                        </div>
            },
            onError: ()=>{
                this.resetState();
            }
        })
    }

    cancelPurchase = (dispatch:(action:any)=>void):void=>{
        if(this.state.braintreeInstance){
            this.state.braintreeInstance.teardown(()=>{
                if(this.props.returnPath){
                    shoppingGoBack();
                    // store.dispatch(setCurrentPathPath(this.props.returnPath! as CURRENT_PAGE))
                }else{
                    shoppingGoBack();
                }
            });
        } else {
            if(this.props.returnPath){
                shoppingGoBack();
                // store.dispatch(setCurrentPathPath(this.props.returnPath! as CURRENT_PAGE))
            }else{
                shoppingGoBack();
            }
    }
    }

    getPaymentLogoURL = ()=>{
        let paymentLogo: string;
        if(this.state.paymentMethod === 'alipay'){
            paymentLogo = AlipayLogo;
        }else if(this.state.paymentMethod === 'wechatpay'){
            paymentLogo = WechatpayLogo;
        }else if(this.state.paymentMethod === 'paypal'){
            paymentLogo = PaypalLogo;
        }else if(this.state.paymentMethod === 'stripe'){
            paymentLogo = CreditCardLog;
        }else{
            throw Error("invalid this.state.paymentMethod "+this.state.paymentMethod);
        }
        return paymentLogo;
    }

    render(){
        const {supportWechatPay, supportAlipay} = this.state;
        return (
            <ReactReduxContext.Consumer>
            {({store})=>{
                const appState:WalletAppState = store.getState();
                const isEnglish = getLanguageCode() === LANG_CODE_EN;

                const setPurchasePoints = ()=>{
                    this.setState({purchaseStep:2, points:parseFloat(this.state.pointsInput!), dispatch: store.dispatch});
                }

                const updatePoints = (value:string)=>{
                    if(!value){
                        this.setState({pointsInput:value, pointsError: t('数值格式不对')});
                        return;
                    }
                    value = value.trim();
                    value = value.replace(/[^0-9.]/g, '');
                    info('updatePoints '+value);
                    if(!isNumeric(value)){
                        this.setState({pointsInput:value,  pointsError: t('数值格式不对')});  
                        return;                     
                    }else{
                        // allow decimal 2
                        let n = parseFloat(value);
                        let decimalIdx = value.lastIndexOf('.');
                        if(decimalIdx>=0 && decimalIdx+3<=value.length-1){
                            value = value.substring(0, decimalIdx+3);
                            n = parseFloat(value);
                        }
                        let refillMin = this.state.paymentConfig!.refillMin;
                        let refillMax = this.state.paymentConfig!.refillMax;
                        if(n<refillMin || n>refillMax){
                            this.setState({pointsInput:value, points:undefined,  pointsError: t('请输入 ')+refillMin+t(' 至 ')+refillMax+t('加元')});  
                        }else{
                            this.setState({pointsInput:value, points:n, pointsError: undefined});  
                        }
                    }
                }

                const setPoints = (points:number) => {
                    this.setState({purchaseStep:2, points, dispatch: store.dispatch});
                }

                const setPaymentMethod = (paymentMethod: PaymentMethod) => {
                    if(paymentMethod === 'alipay'){
                        this.setState({purchaseStep:4.1, paymentMethod: 'alipay'}, ()=>{
                            this.initAlipay();
                        })
                    }else if(paymentMethod === 'wechatpay'){
                        this.setState({purchaseStep:5.1, paymentMethod: 'wechatpay'}, ()=>{
                            this.initWechatPay();
                        })
                    }else if(paymentMethod === 'paypal'){
                        this.initBrainTreeDropin();
                    }else if(paymentMethod === 'stripe'){
                        this.initStripe();
                    }
                }

                let step1 = null, step2=null, step3_1=null, step3_2=null, step4_1=null, step4_2=null, step5_1=null, step5_2=null, step6_2=null, step10=null, step20=null, step30=null;
                if(this.state.pageInitState === 'PAGE_INIT'){
                    return <Fragment>
                        {
                            renderInitUnishellPage({
                                title: t('会员充值','WalletPageView'),
                                className: 'purchase-points-page',
                                message: t('初始化...')    
                            })
                        }
                        <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>                   
                    </Fragment>
                }
                if(this.state.pageInitState === 'PAGE_LOAD_FAILED'){
                    return <Fragment>
                        {
                            renderInitErrorUnishellPage({
                                title: t('会员充值','WalletPageView'),
                                className: 'purchase-points-page',
                                errorMessage: this.state.initErrorMessage!,
                                onTryAgain: ()=>{
                                    this.init();
                                }
                            })

                        }
                        <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>                   
                    </Fragment>
                }
                if(this.state.purchaseStep===1){
                    step1 = <div className='purchase-points-step purchase-points-step-1'>
                                <SimpleNote noticeType='info' message={t('1积分 = 1加元')}/>
                                <div className='label sub-label'>&nbsp;&nbsp;{t('a) 选择充值数量')}</div>
                                <Grid container className='purchase-button-grid'>
                                    <Grid item xs={4}> <UButton variant='outlined' color='primary' onClick={ ()=>{ setPoints(100) } }>
                                        {isEnglish? '100 Points': 100+t('点')}</UButton> 
                                    </Grid>
                                    <Grid item xs={4}> <UButton variant='outlined' color='primary' onClick={ ()=>{ setPoints(200) } }>
                                    {isEnglish? '200 Points': 200+t('点')}</UButton> 
                                    </Grid>
                                    <Grid item xs={4}> <UButton variant='outlined' color='primary' onClick={ ()=>{ setPoints(300) } }>
                                    {isEnglish? '300 Points': 300+t('点')}</UButton> 
                                    </Grid>
                                    <Grid item xs={4}> <UButton variant='outlined' color='primary' onClick={ ()=>{ setPoints(400) } }>
                                    {isEnglish? '400 Points': 400+t('点')}</UButton> 
                                    </Grid>
                                    <Grid item xs={4}> <UButton variant='outlined' color='primary' onClick={ ()=>{ setPoints(600) } }>
                                    {isEnglish? '600 Points': 600+t('点')}</UButton> 
                                    </Grid>
                                    <Grid item xs={4}> <UButton variant='outlined' color='primary' onClick={ ()=>{ setPoints(800) } }>
                                    {isEnglish? '800 Points': 800+t('点')}</UButton> 
                                    </Grid>
                                </Grid>
                                <div className='label sub-label'>&nbsp;&nbsp;{t('b) 或输入充值数量')+''}</div>
                                <TextField type='number' value={this.state.pointsInput||''} placeholder={t('充值数量')}
                                           onInput={ 
                                               // @ts-ignore
                                               evt=>{info(evt.target);    updatePoints(evt.target.value)
                                               }}
                                            onKeyDown={evt=>{
                                                if(evt.key === 'Enter'){
                                                    if(isNumeric(this.state.pointsInput!) || !this.state.pointsError){
                                                        evt.preventDefault();
                                                        setPurchasePoints();
                                                    }
                                                }
                                            }}   
                                />
                                <div className='points-error'>
                                    {this.state.pointsError}
                                </div>
                                <div className='confirm-points-button'>
                                    <UButton variant='contained' color='primary' 
                                            disabled={!isNumeric(this.state.pointsInput!) || !!this.state.pointsError}
                                            onClick={setPurchasePoints}
                                    >{t('下一步')}</UButton>
                                </div>
                                <div className='cancel-purchase-button'>
                                    <UButton variant='contained' color='secondary' 
                                             onClick={()=>{ this.cancelPurchase(store.dispatch) } }>{t('取消')}</UButton>
                                </div>
                                            {/* test code
                                <VEqualSize justifyContent='space-around' spacing='20px' className='action-buttons'>
                                    <UButton variant='contained' color='primary' 
                                            disabled={!isNumeric(this.state.pointsInput!) || !!this.state.pointsError}
                                            onClick={setPurchasePoints}
                                    >{t('下一步')}</UButton>
                                    <UButton variant='contained' color='secondary' 
                                             onClick={()=>{ this.cancelPurchase(store.dispatch) } }>{t('取消')}</UButton>

                                </VEqualSize> */}
                            </div>
                }else if(this.state.purchaseStep === 2){
                    step2 = <div className='purchase-points-step purchase-points-step-2'>
                                <div className='label'>{t('1 充值金额(加元)')} {this.state.points} <CheckCircle className='check-circle'/></div>
                                <div className='label'>{t('2 选择付款方式')}</div>
                                <div className='payment-method-radio-group'>
                                    <div className={'payment-method-radio payment-method-radio-alipay'+(supportAlipay?"":" disabled")}>
                                        <Radio checked={this.state.paymentMethod==='alipay'} size='small' disabled={!supportAlipay}
                                               onChange={evt=>{ supportAlipay && setPaymentMethod('alipay') } } />
                                        <ImageWithFallback src={AlipayLogo} debug={false}
                                                           alt={t('支付宝')}/>
                                        {supportAlipay?null: <span className='not-supported'>&nbsp;({t('手机未设置')})</span>}
                                    </div>
                                    {/* <div className={'payment-method-radio payment-method-radio-wechatpay'+(supportWechatPay?"":" disabled")}>
                                        <Radio checked={this.state.paymentMethod==='wechatpay'} size='small' disabled={!supportWechatPay}
                                               onChange={evt=>{ supportWechatPay && setPaymentMethod('wechatpay') } }/>
                                        <ImageWithFallback src={WechatpayLogo}
                                                            alt={t('微信支付')}/>
                                        {supportWechatPay?null: <span className='not-supported'>&nbsp;({t('手机未设置')})</span>}
                                    </div> */}
                                    {/* <div className={'payment-method-radio payment-method-radio-paypal'}>
                                        <Radio checked={this.state.paymentMethod==='paypal'} size='small' onChange={evt=>{ setPaymentMethod('paypal') } }/>
                                        <ImageWithFallback src={PaypalLogo} onClick={ ()=>{ setPaymentMethod('paypal') } } alt={t('信用卡，Paypal')}/>
                                    </div> */}
                                    <div className={'payment-method-radio payment-method-radio-stripe'}>
                                        <Radio checked={this.state.paymentMethod==='paypal'} size='small' onChange={evt=>{ setPaymentMethod('stripe') } }/>
                                        <ImageWithFallback src={CreditCardLog} alt={t('信用卡，Stripe')}/>
                                    </div>
                                </div>
                                <div className='cancel-purchase-button'>
                                        <UButton variant='contained' color='secondary' onClick={()=>{ this.cancelPurchase(store.dispatch) } }>{t('取消')}</UButton>
                                </div>
                            </div>

                }else if(this.state.purchaseStep === 3.1){
                    step3_1 = <div className='purchase-points-step purchase-points-step-2'>
                                <div className='label'>{t('1 充值金额(加元)')} {this.state.points} <CheckCircle className='check-circle'/></div>
                                <div className='label'>{t('2 付款方式')} &nbsp;<ImageWithFallback src={this.getPaymentLogoURL()} className='payment-method-img'/></div>
                                <div className='label'>{t('3 初始化Paypal ...')}</div>
                            </div>
                }else if(this.state.purchaseStep === 3.2){
                    step3_2 = <div className='purchase-points-step purchase-points-step-3'>
                                <div className='label'>{t('1 充值金额(加元)')} {this.state.points} <CheckCircle className='check-circle'/></div>
                                <div className='label'>{t('2 付款方式')} &nbsp;<ImageWithFallback src={this.getPaymentLogoURL()!}  className='payment-method-img'/></div>
                                <div className='label'>{t('3 初始化Paypal')} <CheckCircle className='check-circle'/></div>
                                <div className='label'>{t('4 选择付款方式, 填写表格, 点击付费按钮')}</div>
                            </div>
                }else if(this.state.purchaseStep === 4.1 ){
                    step4_1 = <div className='purchase-points-step purchase-points-step-2'>
                                <div className='label'>{t('1 充值金额(加元)')} {this.state.points} <CheckCircle className='check-circle'/></div>
                                <div className='label'>{t('2 付款方式')} &nbsp;<ImageWithFallback src={this.getPaymentLogoURL()!}  className='payment-method-img'/></div>
                                <div className='label'>{t('3 初始化支付宝')} ...</div>
                            </div>
                }else if(this.state.purchaseStep === 4.2){
                    step4_2 = <div className='purchase-points-step purchase-points-step-3'>
                                <div className='label'>{t('1 充值金额(加元)')} {this.state.points} <CheckCircle className='check-circle'/></div>
                                <div className='label'>{t('2 付款方式')} &nbsp;<ImageWithFallback src={this.getPaymentLogoURL()!}  className='payment-method-img'/></div>
                                <div className='label'>{t('3 初始化支付宝')} <CheckCircle className='check-circle'/></div>
                                <div className='label'>{t('4 调用支付宝')}</div>
                            </div>
                }else if(this.state.purchaseStep === 5.1 ){
                    step5_1 = <div className='purchase-points-step purchase-points-step-2'>
                                <div className='label'>{t('1 充值金额(加元)')} {this.state.points} <CheckCircle className='check-circle'/></div>
                                <div className='label'>{t('2 付款方式')} &nbsp;<ImageWithFallback src={this.getPaymentLogoURL()!}  className='payment-method-img'/></div>
                                <div className='label'>{t('3 初始化微信支付')} ...</div>
                            </div>
                }else if(this.state.purchaseStep === 5.2){
                    step5_2 = <div className='purchase-points-step purchase-points-step-3'>
                                <div className='label'>{t('1 充值金额(加元)')} {this.state.points} <CheckCircle className='check-circle'/></div>
                                <div className='label'>{t('2 付款方式')} &nbsp;<ImageWithFallback src={this.getPaymentLogoURL()!}  className='payment-method-img'/></div>
                                <div className='label'>{t('3 初始化微信支付')} <CheckCircle className='check-circle'/></div>
                                <div className='label'>{t('4 调用微信支付')}</div>
                            </div>
                }else if(this.state.purchaseStep === 6.2){
                    this.processingStripePayment = false;

                    const CARD_ELEMENT_OPTIONS = {
                        style: {
                          base: {
                            color: "#32325d",
                            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                            fontSmoothing: "antialiased",
                            fontSize: "16px",
                            "::placeholder": {
                              color: "#aab7c4",
                            },
                          },
                          invalid: {
                            color: "#fa755a",
                            iconColor: "#fa755a",
                          },
                        },
                      };

                    
                    step6_2 = <div className='purchase-points-step purchase-points-step-3'>
                                <div className='label'>{t('1 充值金额(加元)')} {this.state.points} <CheckCircle className='check-circle'/></div>
                                <div className='label'>{t('2 付款方式')} &nbsp;<ImageWithFallback src={this.getPaymentLogoURL()!}  className='payment-method-img'/></div>
                                <div className='label'>{t('3 初始化支付接口')} <CheckCircle className='check-circle'/></div>
                                <div className='label'>{t('4 填写信用卡信息, 点击付费')}</div>
                                <div className='stripe-wrapper'>
                                    <Elements stripe={stripePromise}>
                                    <ElementsConsumer>
                                    {({stripe, elements}) => {
                                        const onSubmit = (event:any)=>{
                                            const t0 = Date.now();
                                            this.appendDebugMessage(" stripe pay starts");
                                            event.preventDefault();
                                            if (!stripe || !elements) {
                                                // Stripe.js has not yet loaded.
                                                // Make  sure to disable form submission until Stripe.js has loaded.
                                                this.appendDebugMessage(" stripe.js is not ready");
                                                return;
                                            }
                                            if(this.processingStripePayment){ 
                                                this.appendDebugMessage(" user double click submit button");
                                                return; // user double click submit button
                                            }

                                            this.processingStripePayment = true;
                                        
                                            const card = elements.getElement(CardNumberElement)!;
                                            const t1 = Date.now();
                                            this.appendDebugMessage('call confirm stripe pay after '+(t1-t0)+" ms");
                                            this.confirmStripePayment(stripe, card, readLoginSession()!.token!.accessToken, store.dispatch);
                                        }
                                        
                                        return (
                                        <form className='stripe-form' >
                                            {/* <span>Card Number</span><CardNumberElement/>
                                            <span>Expire Date</span><CardExpiryElement/>
                                            <span>CVC</span><CardCvcElement/> */}
                                            {/* <CardElement options={CARD_ELEMENT_OPTIONS}/> */}
                                            <label>{t('信用卡号')+(stripePK.startsWith('pk_test') ? ' (test)': '')}</label><CardNumberElement options={CARD_ELEMENT_OPTIONS}/>
                                            <label>{t('失效 月/年')}</label><CardExpiryElement  options={CARD_ELEMENT_OPTIONS}/>
                                            <label>{t('验证码')}</label><CardCvcElement  options={CARD_ELEMENT_OPTIONS}/>
                                            <div className='confirm-purchase-button'>
                                                <UButton variant='contained' color='primary' disabled={!stripe || !elements} 
                                                        onClick={(evt)=>{
                                                            // alert('click payment button');
                                                            this.debugMessages = [];
                                                            this.appendDebugMessage('click payment button');
                                                            onSubmit(evt);
                                                            }}>{t('付费')}</UButton>
                                                <UButton variant='contained' color='secondary' onClick={()=>{ this.cancelPurchase(store.dispatch) } }>{t('取消')}</UButton>
                                            </div>
                                            <div className='cancel-purchase-button'>
                                            </div>
                                        </form>
                                        )}
                                    }
                                    </ElementsConsumer>

                                    </Elements>
                                </div>
                            </div>
                }
                
                
                else if(this.state.purchaseStep === 10){// purchase points pending
                    let t1 = Date.now();

                    step10 = <div className='purchase-points-step purchase-points-step-10'>
                                <div className='label'>{t('充值金额(加元)')} {this.state.points} <CheckCircle className='check-circle'/></div>
                                <div className='label'>{t('付款方式')} &nbsp;<ImageWithFallback src={this.getPaymentLogoURL()!}  className='payment-method-img'/></div>
                                <div className='label'>{t('用户支付')} <CheckCircle className='check-circle'/></div>
                                <div className='label'>{t('等待支付系统确认')} ... {round(Math.max(0, (15000-(t1-this.state.t0!))/1000),0)}秒</div>
                            </div>
                }else if(this.state.purchaseStep === 20){// purchase points succeeded
                    let d = new Date(this.state.orderSummary!.time);
                    const purchaseProps: PropertyListDataItem[] = [
                        {name: t('数量'), value: this.state.orderSummary!.points},
                        {name: t('金额'), value: this.state.orderSummary!.points+t('加元')},
                        {name: t('订单'), value: this.state.orderSummary!.orderCode},
                        {name: t('方式'), value: this.state.orderSummary!.paymentMethod},
                        {name: t('时间'), value: dataFormat('yyyy-MM-dd hh:mm:ss', d)},
                    ]

                    step20 = <div className='purchase-points-step purchase-points-step-20'>
                                <div className='label'>{t('充值成功')} !<CheckCircle className='check-circle'/></div>
                                <div className='notice'>
                                {t('充值记录发送到您的电子邮箱')}
                                </div>
                                <PropertyListWidget list={purchaseProps} showBorder={true} mergeColScreenLetterRatio={RatioXXS}/>
                                <div className='return-purchase-button'>
                                { this.props.returnPath ?
                                    <UButton variant='contained' color='primary' 
                                            onClick={()=>{ 
                                                shoppingGoBack();
                                                // store.dispatch(setCurrentPathPath(this.props.returnPath! as CURRENT_PAGE))
                                            } }>{this.props.returnPathLabel!}</UButton>
                                    :
                                    <UButton variant='contained' color='primary' 
                                            onClick={()=>{ 
                                                this.cancelPurchase(store.dispatch) 
                                            } }>{t('返回')}</UButton> 
                                }    
                                </div>

                           </div>
                }else if(this.state.purchaseStep === 30){// check order state timeout

                    step30 = <div className='purchase-points-step purchase-points-step-10'>
                                <div className='label'>{t('充值金额(加元)')} {this.state.points} <CheckCircle className='check-circle'/></div>
                                <div className='label'>{t('付款方式')} &nbsp;<ImageWithFallback src={this.getPaymentLogoURL()!}  className='payment-method-img'/></div>
                                <div className='label'>{t('用户支付')} <CheckCircle className='check-circle'/></div>
                                <div className='label'>{t('等待支付系统确认超时')}</div>
                            </div>
                }
                const {ratioClass, vw,  mw, ratio} = calcRationClass();

                const content =( 
                        <Fragment>
                            <div className={classnames('purchase-points-steps',ratioClass)}>
                                {step1}
                                {step2}
                                {step3_1}
                                {step3_2}
                                {step4_1}
                                {step4_2}
                                {step5_1}
                                {step5_2}
                                {step6_2}
                                {step10}
                                {step20}
                                {step30}
                                <div id='dropin-container' ref={(e)=>{ this.dropinContainer = e! }}>
                                </div>
                                { this.state.purchaseStep === 3.2? 
                                <div className='purchase-points-step purchase-points-step-3'>
                                    <div className='confirm-purchase-button'>
                                        <UButton variant='contained' color='primary' 
                                                onClick={()=>{ this.onBraintreePurchase() }}>{t('付费')}</UButton>
                                    </div>
                                    <div className='cancel-purchase-button'>
                                        <UButton variant='contained' color='secondary' onClick={()=>{ this.cancelPurchase(store.dispatch) } }>{t('取消')}</UButton>
                                    </div>
                                </div>:null
                                }
                            </div>
                            <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>   
                            {
                                this.debugMessages.map(msg=>{
                                    return <div>{msg}</div>
                                })
                            }                
                        </Fragment>
                );

                return renderUnishellPage({
                    title: t('会员充值','WalletPageView'),
                    className: 'purchase-points-page',
                    content        
                })
            }}
            </ReactReduxContext.Consumer>
            
        )
    }

    init(){
        let whatIsFor = "初始化";
        doAJAXCall({
            whatIsFor,
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken)=>{
                await loadExternContatnsIfNecessary();
                const paymentConfig = await getPaymentConfig(accessToken); 
                const {supportWechatPay, supportAlipay} = await getPaymentSupport();

                this.setState({pageInitState:'PAGE_LOAD_SUCCESS', purchaseStep: 1, paymentConfig, supportWechatPay, supportAlipay});
            },
            onError: (errorMessage)=>{
                this.setState({pageInitState:'PAGE_LOAD_FAILED', initErrorMessage: errorMessage});
            }
        })
    }

    componentDidMount(){
        this.init();
    }

    componentDidUpdate(){
        if(this.state.purchaseStep === 10){
            let t0 = this.state.t0!;

            const tryAgain = ()=>{
                let t1 = Date.now();
                if(t1-t0<15000){
                    this.forceUpdate();
                    setTimeout(()=>{
                        this.getOrderStatus(tryAgain);
                    }, 500);        
                }else{
                    // this.setState({purchaseStep:30});
                    this.errorDialog!.show(t('确认支付'), <div>{t('等待支付系统确认超时')}<div>{t('订单号')+':'+this.state.orderSummary!.orderCode}</div></div>, ()=>{
                        this.resetState();
                    });
                }
            }

            setTimeout(()=>{
                this.getOrderStatus(tryAgain);
            }, 1000);
        }
    }


}