import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import configureStore from "./redux-store";
import './index.scss';
import 'normalize.css';
import {App} from './App';
import * as serviceWorker from './serviceWorker';
import { ScanQRCodeResult } from './view/transfer/ScanTransferView';
import { setCurrentPathPath } from './redux-store/path/actions';
import { t } from './i18n';
import { retrieveUserSummaryByQRCode, refreshToken, getPaymentOrder, webInvokedUnishellApp, getDoc } from './wallet-server-api/wallet-server-api';
import { getAjaxErrorMessage, is404Error } from ".//wallet-server-api/ajax-common";
import { is401Error } from "./wallet-server-api/ajax-common";
// @ts-ignore
import {WalletAppState} from './redux-store'
import { saveLoginSession,readLoginSession, readPersistData, getValidLoginSession, getLanguageCode, setLanguageCode, genLanguageLocalStorageKey } from './local-storage/local-storage';
import jwt_decode from 'jwt-decode';
import { SessionToken, LoginSessionState } from './redux-store/session';
import IdleTimer from 'react-idle-timer'
import { loadPrivateKey, signMessage } from './cypto/crypto';
import { backPath } from './redux-store/path/types';
import { ScanOrderCodeResult } from './view/unishell-pay/UnishellPayView';
import { Base64 } from 'js-base64';
import base64URL from 'base64-url';
import { replaceURLHistory } from './util/Util';
import { NoticeDialog } from './view/NoticeDialog';
import { gotoLoginPage, gotoShoppingPathNSavePrevPath, peekShoppingPathRecord, shoppingGoBack } from './shopping/history/shopping-history';
import { retreiveShoppingProductDetailByBarCode } from './shopping/ajax-data/shopping-server-api';
import { hasRole, isNutritionist, ROLE_NUTRITIONIST } from './api/user';
import { info } from './globals';
import { ConfirmDialogView } from './view/confirm-dialog/ConfirmDialogView';

export const store = configureStore();

var DEBUG = true;
if(!DEBUG){
    // @ts-ignore
    if(!window.console) window.console = {};
    var methods = ["log", "debug", "warn", "info"];
    for(var i=0;i<methods.length;i++){
        // @ts-ignore
        console[methods[i]] = function(){};
    }
}

declare global {
    interface Window { 
        handleScanQRCodeResult: (result: ScanQRCodeResult)=>void; 
        handleScanOrderCodeResult: (result:ScanOrderCodeResult)=>void;
        handleScanBarCodeResult: (result: ScanQRCodeResult)=>void;
        handleScanNutritionistQRCodeResult: (result: ScanQRCodeResult)=>void;
    }
}

/**
 * check QR code prefix.
 * 
 * @param result save cancelled flag and error message in result
 * @param prefix prefix of QR code
 * @param expectedPrefix 
 */
function checkQRCodePrefix(result: ScanQRCodeResult, prefix:string, expectedPrefix:string, errorMessage:string){
    if(prefix !== expectedPrefix){
        result.message=errorMessage;
        result.cancelled = true;
// switch(prefix){
//             case 'Q':
//                 result.message=t('请用 扫码转出 功能扫描转账二维码');
//                 result.cancelled = true;
//                 break;
//             case 'O':
//                 result.message=t('请用 U点支付 功能扫描支付二维码');
//                 result.cancelled = true;
//                 break;
//             case 'N':
//                 result.message=t('请扫码选择服务');
//                 result.cancelled = true;
//                 break;    
//             default:
//                 result.message=t('您扫描了其他二维码');
//                 result.cancelled = true;
//         }
    }else{
        result.cancelled = false;
    }
}


window.handleScanQRCodeResult = (result: ScanQRCodeResult)=>{
    if(result.cancelled){
        result.message=t('您取消了转账操作');
    }else if(result.format !== 'QR_CODE'){
        result.message=t('您扫描了其他应用程序二维码')+":"+result.format;
        result.cancelled = true;
    }else if(!result.text){
        result.message=t('扫描二维码失败');
        result.cancelled = true;
    }else {
        const decodeToken = base64URL.decode(result.text);
        if(decodeToken){
            info(`decodeToken ${decodeToken} length=${decodeToken.length}`);
        }
        if(decodeToken.length>=15 && decodeToken.length<=17){
            checkQRCodePrefix(result, decodeToken[0], 'Q', t('请用 扫码转出 功能扫描转账二维码'));
        }
    }
    if(result.cancelled){
        alert(result.message);
    }else{
        let loginSession = getValidLoginSession();
        if(loginSession !== null){
            retrieveUserSummaryByQRCode(loginSession.token!.accessToken, result.text)
            .then((userSummary)=>{
                 result.name = userSummary.name;
                 result.username = userSummary.username;
                 result.userEmail = userSummary.userEmail;
                 store.dispatch(setCurrentPathPath('main/wallet/receive-transfer/scan-transfer', {result}))
            }).catch((error)=>{
                result.message=t('验证二维码失败:')+getAjaxErrorMessage(error);
                alert(t('验证二维码失败:')+getAjaxErrorMessage(error));
                if(is401Error(error)){
                    gotoLoginPage();
                }else{
                    // do nothing
                }
            })
        }else{
            gotoLoginPage();
        }
    }
}

window.handleScanOrderCodeResult = (result:ScanOrderCodeResult)=>{
    if(result.cancelled){
        result.message=t('您取消了支付操作');
    }else if(result.format !== 'QR_CODE'){
        result.message=t('您扫描了其他应用程序二维码')+":"+result.format;
        result.cancelled = true;
    }else if(!result.text){
        result.message=t('扫描二维码失败');
        result.cancelled = true;
    }else {
        const decodeToken = Base64.decode(result.text);
        if(decodeToken){
            info(`decodeToken.length=${decodeToken.length}`);
        }
        if(decodeToken.length>=15 && decodeToken.length<=17){
            checkQRCodePrefix(result, decodeToken[0], 'O', t('请用 U点支付 功能扫描支付二维码'));
        }
    }
    if(result.cancelled){
        alert(result.message);
    } else {
        const orderCode = result.text;
        let loginSession = getValidLoginSession();
        if(loginSession){
            getPaymentOrder(loginSession.token!.accessToken, orderCode)
            .then(order=>{
                store.dispatch(setCurrentPathPath('unishell-pay', {orderCode, caller:'buyer'}));
            }).catch(error=>{
                alert(t('验证二维码失败:'+getAjaxErrorMessage(error)));
                if(is401Error(error)){
                    gotoLoginPage();
                }else{
                    // do nothing
                }
            })
        }else{
            gotoLoginPage();
        }
    }     
}

window.handleScanBarCodeResult = async (result: ScanQRCodeResult)=>{
    if(result.cancelled){
        result.message=t('您取消了扫码查询产品操作');
    }else if(!result.text){
        result.message=t('扫码查询产品失败');
        result.cancelled = true;
    }else {
        // TODO: check bar code
    }
    if(result.cancelled){
        alert(result.message);
    }else{
        let loginSession = getValidLoginSession();
        if(loginSession !== null){
            try{
                const response = await retreiveShoppingProductDetailByBarCode(loginSession.token!.accessToken, result.text);
                info(`retreiveShoppingProductDetailByBarCode response ${JSON.stringify(response)}`);
                if(isNutritionist()){
                    const currentProd = await getDoc(loginSession!.token!.accessToken, 'chanpin', response.productDocumentId);
                    gotoShoppingPathNSavePrevPath('main/tools/search-products', {currentProd});                        
                }else{
                    gotoShoppingPathNSavePrevPath('main/shopping/product', {productCode:response.productCode});                    
                }
            }catch(error){
                result.message=t('验证条形码失败:')+getAjaxErrorMessage(error);
                if(is404Error(error)){
                    result.message = t('没有找到该产品')+':'+result.text;    
                }
                alert(result.message);
                if(is401Error(error)){
                    gotoLoginPage();
                }
            };
        }else{
            gotoLoginPage();
        }
    }
}

window.handleScanNutritionistQRCodeResult = (result:ScanOrderCodeResult)=>{
    if(result.cancelled){
        result.message=t('您取消了扫码选择服务');
    }else if(result.format !== 'QR_CODE'){
        result.message=t('您扫描了其他应用程序二维码')+":"+result.format;
        result.cancelled = true;
    }else if(!result.text){
        result.message=t('扫描二维码失败');
        result.cancelled = true;
    }else {
        const decodeToken = Base64.decode(result.text);
        if(decodeToken){
            info(`decodeToken.length=${decodeToken.length} ${decodeToken[0]}`);
        }
        if(decodeToken.length>=15 && decodeToken.length<=17){
            checkQRCodePrefix(result, decodeToken[0], 'Q', t('请扫码选择服务'));
        }
    }
    if(result.cancelled){
        alert(result.message);
    } else {
        let loginSession = getValidLoginSession();
        if(loginSession){
            retrieveUserSummaryByQRCode(loginSession.token!.accessToken, result.text)
            .then(user=>{
                if(hasRole(user.roles||[], ROLE_NUTRITIONIST)){
                    gotoShoppingPathNSavePrevPath('main/tools/set-nutritionist', {nutritionistUsername: user.username});                        
                }else{
                    throw Error(t('非营养师二维码'));
                }
            }).catch(error=>{
                alert(t('验证二维码失败:'+getAjaxErrorMessage(error)));
                if(is401Error(error)){
                    gotoLoginPage();
                }else{
                    // do nothing
                }
            })
        }else{
            gotoLoginPage();
        }
    }     
}

/**
 * 
 * @param text either order code or formatted order code copy & pasted from merchant website.
 * @param checkExpiry true means ignore stale formatted order code.
 * @returns optional order code if possible. may return undefined if text is sure not order code.
 */
// @ts-ignore
window.getPastedOrderCode = (text:string, checkExpiry:boolean):string|undefined => {
    if(!text) return undefined;
    
    // VU5JU0hFTExfT1JERVJDT0RF is base64 encoding of UNISHELL_ORDERCODE
    const prefix='VU5JU0hFTExfT1JERVJDT0RF';
    if(text.startsWith(prefix)){
        let jsonString=text.substring(prefix.length);
        if(jsonString){
          try{
              let json = JSON.parse(Base64.decode(jsonString)) as {orderCode:string, timestamp:number};
              if(json.orderCode && json.timestamp && typeof json.timestamp === 'number'){
                if(checkExpiry && !(Date.now() - json.timestamp > 0 && Date.now()-json.timestamp < 1000*60*60)){ 
                  // it is recent copy & paste order code
                    return undefined; 
                }else{
                    return json.orderCode; 
                }
              }
          }catch(error){
            // ignore
            return undefined;
          }
        }else{
            return undefined;
        }
    }else{
        // assume it is direct order code
        return text;
    }
}

// to be called from merchant web page
// @ts-ignore
window.handlePayment = (orderCode:string)=>{
    webInvokedUnishellApp(orderCode);
    // alert(`webInvokedUnishellApp(${orderCode})`);
    let loginSession = getValidLoginSession();
    if(loginSession){
        let action = t('读取订单');
        getPaymentOrder(loginSession!.token!.accessToken, orderCode)
        .then(order=>{
            // @ts-ignore
            window.merchantOrder = undefined;
            replaceURLHistory({}, 'Unishell', '/');
            store.dispatch(setCurrentPathPath('unishell-pay', {orderCode, caller:'merchant'}));
        }).catch(error=>{
            alert(action+t(' 失败')+":"+getAjaxErrorMessage(error));
            if(is401Error(error)){
                gotoLoginPage();
            }else{
                // do nothing
            }
        })
    }else{
        replaceURLHistory({}, 'Unishell', '/merchantOrder/'+orderCode);
        gotoLoginPage();
    }
}

/**
 * functions to be called by app back button.
 *  @returns true to indicate quite app 
 */
// @ts-ignore
window.handleBackButton = (): boolean => {
    const appState = store.getState();

    if(appState && appState.pagePath && appState.pagePath.currentPage){
        {
            let prevPath = peekShoppingPathRecord();
            if(prevPath){
                shoppingGoBack();
                return false;
            }
        }
    } 

    return true;
}

info(`NODE_ENV=${process.env.NODE_ENV} REACT_APP_WALLET_SERVER_URL=${process.env.REACT_APP_WALLET_SERVER_URL}`);

if(process.env.NODE_ENV !== 'production'){
    // define function simulating scan qr code
    // @ts-ignore
    window.scanQRCode = ()=>{
        let qrcode = prompt('recipient QRCode', 'UbfpH9I51VYR3T-YfhfP7k8');
        if(qrcode !== null){
            setTimeout(()=>{
                let result: ScanQRCodeResult = {
                    text: qrcode!, format:'QR_CODE', cancelled: false, message:'', 
                }
                window.handleScanQRCodeResult(result);
                },200);
        }else{
            setTimeout(()=>{
                let result: ScanQRCodeResult = {
                    text: qrcode!, format:'QR_CODE', cancelled: true, message:t('您取消了转账操作')
                }
                window.handleScanQRCodeResult(result);
                },200);
        }
    }

    // @ts-ignore
    window.scanOrderCode = ()=>{
        let qrcode = prompt('orderCode', '');
        if(qrcode !== null){
            setTimeout(()=>{
                let result: ScanOrderCodeResult = {
                    text: qrcode!, format:'QR_CODE', cancelled: false, message:'', 
                }
                window.handleScanOrderCodeResult(result);
                },200);
        }else{
            setTimeout(()=>{
                let result: ScanOrderCodeResult = {
                    text: '', format:'QR_CODE', cancelled: true, message:t('您取消了Unishell Pay')
                }
                window.handleScanOrderCodeResult(result);
                },200);
        }
    }
    // @ts-ignore
    window.scanBarCode = ()=>{
        let qrcode = prompt('Product bar code', '628341007412');
        if(qrcode !== null){
            setTimeout(()=>{
                let result: ScanQRCodeResult = {
                    text: qrcode!, format:'BAR_CODE', cancelled: false, message:'', 
                }
                window.handleScanBarCodeResult(result);
                },200);
        }else{
            setTimeout(()=>{
                let result: ScanQRCodeResult = {
                    text: '628341007412', format:'BAR_CODE', cancelled: true, message:t('您取消了扫码查询产品操作')
                }
                window.handleScanBarCodeResult(result);
                },200);
        }
    }

    // @ts-ignore
    window.scanNutritionistQRCode = ()=>{
        let qrcode = prompt('nutritionist QR code', 'UZvNaMLQ_LM8-THehILo1aI');
        if(qrcode !== null){
            setTimeout(()=>{
                let result: ScanOrderCodeResult = {
                    text: qrcode!, format:'QR_CODE', cancelled: false, message:'', 
                }
                window.handleScanNutritionistQRCodeResult(result);
                },200);
        }else{
            setTimeout(()=>{
                let result: ScanOrderCodeResult = {
                    text: '', format:'QR_CODE', cancelled: true, message:t('您取消了扫码选择服务')
                }
                window.handleScanNutritionistQRCodeResult(result);
                },200);
        }
    }

}

let inactiveTimeout = 60*20; // minutes
if(process.env.REACT_APP_INACTIVE_TIMEOUT){
    inactiveTimeout = Number.parseInt(process.env.REACT_APP_INACTIVE_TIMEOUT);
}

const onIdle = ()=>{
    info('The user is not interacting with the page');
    const loginSession = readLoginSession();
    if(loginSession){
        gotoLoginPage()
    }
}

const onAction = ()=>{
    info('user is active. renew jwf token if necessary');
    const state:WalletAppState = store.getState();
    let savedSession = readLoginSession();
    if(savedSession && savedSession.token && state.pagePath.currentPage !== 'login'){ //
        const jwt = (jwt_decode(savedSession.token.accessToken)) as {exp:number};
        if(Date.now()/1000+60*30 > jwt.exp /*|| Date.now()/1000> savedSession.token.createdDtm+60 */){ // jwf will expire soon
            info('renewing jwf token');
            const localData = readPersistData(savedSession.profile!.username);
            loadPrivateKey(localData!.keyPair.priKey)
            .then(keyManager=>{
                info(`loaded private key manager`)
                return signMessage(keyManager, savedSession!.token!.accessToken!)
            }).then(({signedMessage})=>{
                info(`signed transfer detail`);
                return refreshToken(savedSession!.token!.accessToken, {keyId: localData!.pkeyHash, data:signedMessage})
            }).then(authResponse=>{
                const jwt = (jwt_decode(authResponse.accessToken)) as {exp:number};
                let token:SessionToken = {...authResponse, expireTimestamp: jwt.exp, createdDtm: Date.now()/1000};
                const sessionData: LoginSessionState = {token, profile:{...savedSession!.profile!}}
                saveLoginSession(sessionData);
                info('renewed jwf token '+JSON.stringify(jwt));
            }).catch(error=>{
                info('renew jwt token failed:'+getAjaxErrorMessage(error));
            })
        }
    }
}

if(!localStorage.getItem(genLanguageLocalStorageKey())){
    // @ts-ignore
    let language:string =navigator.language;
    if(language.startsWith("zh-")){
         language = 'zh-CN';
     }
     else {
         language = 'en';
     }
     setLanguageCode(language);
}



info(`REACT_APP_WALLET_SERVER_URL=${process.env.REACT_APP_WALLET_SERVER_URL}`);
ReactDOM.render(
    <Provider store={store}>
        <IdleTimer element={document} onIdle={onIdle} onAction={onAction} timeout={inactiveTimeout*1000}
                   throttle={60000}
        >
            <NoticeDialog open={false} 
                          ref={e=>{
                                     // @ts-ignore
                                     window.errorDialog=e!
                                  }
                              }
            />
            <ConfirmDialogView 
                ref={e=>
                    // @ts-ignore
                    window.openUrlDialog=e!
                } 
                open={false} 
            />
            <App />                   
        </IdleTimer>
    </Provider>, 
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
