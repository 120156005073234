// Generated by IcedCoffeeScript 108.0.11
(function () {
  var EUI,
      KeyFetcher,
      KeyManagerInterface,
      SignatureEngineInterface,
      iced,
      make_esc,
      __iced_k,
      __iced_k_noop,
      __hasProp = {}.hasOwnProperty,
      __extends = function __extends(child, parent) {
    for (var key in parent) {
      if (__hasProp.call(parent, key)) child[key] = parent[key];
    }

    function ctor() {
      this.constructor = child;
    }

    ctor.prototype = parent.prototype;
    child.prototype = new ctor();
    child.__super__ = parent.prototype;
    return child;
  };

  iced = require('iced-runtime');

  __iced_k = __iced_k_noop = function __iced_k_noop() {};

  KeyFetcher = require('./keyfetch').KeyFetcher;
  make_esc = require('iced-error').make_esc;
  EUI = new Error("not implemented");

  exports.KeyManagerInterface = KeyManagerInterface = function (_super) {
    __extends(KeyManagerInterface, _super);

    function KeyManagerInterface() {}

    KeyManagerInterface.prototype.get_pgp_fingerprint = function () {
      return null;
    };

    KeyManagerInterface.prototype.get_ekid = function () {
      return null;
    };

    KeyManagerInterface.prototype.get_fp2 = function () {
      return null;
    };

    KeyManagerInterface.prototype.get_fp2_formatted = function () {
      return null;
    };

    KeyManagerInterface.prototype.get_type = function () {
      return null;
    };

    KeyManagerInterface.prototype.check_public_eq = function (km2) {
      return this.EUI;
    };

    KeyManagerInterface.prototype.get_userids = function () {
      return [];
    };

    KeyManagerInterface.prototype.get_primary_keypair = function () {
      return null;
    };

    KeyManagerInterface.prototype.get_all_pgp_key_materials = function () {
      return [];
    };

    KeyManagerInterface.prototype.get_userids_mark_primary = function () {
      return [];
    };

    KeyManagerInterface.prototype.make_sig_eng = function () {
      return null;
    };

    KeyManagerInterface.prototype.export_pgp_public = function (opts, cb) {
      return cb(null, null);
    };

    KeyManagerInterface.prototype.export_pgp_private = function (opts, cb) {
      return cb(null, null);
    };

    KeyManagerInterface.prototype.export_public = function (_arg, cb) {
      var asp, regen;
      asp = _arg.asp, regen = _arg.regen;
      return cb(EUI, null);
    };

    KeyManagerInterface.prototype.export_private = function (_arg, cb) {
      var asp, p3skb, passphrase;
      asp = _arg.asp, passphrase = _arg.passphrase, p3skb = _arg.p3skb;
      return cb(EUI);
    };

    KeyManagerInterface.prototype.can_verify = function () {
      return false;
    };

    KeyManagerInterface.prototype.can_sign = function () {
      return false;
    };

    KeyManagerInterface.prototype.can_encrypt = function () {
      return false;
    };

    KeyManagerInterface.prototype.can_decrypt = function () {
      return false;
    };

    KeyManagerInterface.prototype.get_all_pgp_key_ids = function () {
      return [];
    };

    KeyManagerInterface.prototype.pgp_full_hash = function (opts, cb) {
      return cb(null, null);
    };

    return KeyManagerInterface;
  }(KeyFetcher);

  exports.SignatureEngineInterface = SignatureEngineInterface = function () {
    function SignatureEngineInterface(_arg) {
      this.km = _arg.km;
    }

    SignatureEngineInterface.prototype.get_km = function () {
      return this.km;
    };

    SignatureEngineInterface.prototype.box = function (msg, cb) {
      return cb(EUI);
    };

    SignatureEngineInterface.prototype.unbox = function (msb, cb) {
      return cb(EUI);
    };

    SignatureEngineInterface.prototype.get_body = function (_arg, cb) {
      var armored;
      armored = _arg.armored;
      return cb(EUI);
    };

    SignatureEngineInterface.prototype.get_unverified_payload_from_raw_sig_body = function (_arg, cb) {
      var body;
      body = _arg.body;
      return cb(EUI);
    };

    SignatureEngineInterface.prototype.get_body_and_unverified_payload = function (_arg, cb) {
      var armored, body, esc, payload, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      armored = _arg.armored;
      esc = make_esc(cb, "get_body_and_unverified_payload");
      (function (_this) {
        return function (__iced_k) {
          __iced_deferrals = new iced.Deferrals(__iced_k, {
            parent: ___iced_passed_deferral,
            filename: "/Users/max/src/keybase/kbpgp/src/kmi.iced",
            funcname: "SignatureEngineInterface.get_body_and_unverified_payload"
          });

          _this.get_body({
            armored: armored
          }, esc(__iced_deferrals.defer({
            assign_fn: function () {
              return function () {
                return body = arguments[0];
              };
            }(),
            lineno: 96
          })));

          __iced_deferrals._fulfill();
        };
      })(this)(function (_this) {
        return function () {
          (function (__iced_k) {
            __iced_deferrals = new iced.Deferrals(__iced_k, {
              parent: ___iced_passed_deferral,
              filename: "/Users/max/src/keybase/kbpgp/src/kmi.iced",
              funcname: "SignatureEngineInterface.get_body_and_unverified_payload"
            });

            _this.get_unverified_payload_from_raw_sig_body({
              body: body
            }, esc(__iced_deferrals.defer({
              assign_fn: function () {
                return function () {
                  return payload = arguments[0];
                };
              }(),
              lineno: 97
            })));

            __iced_deferrals._fulfill();
          })(function () {
            return cb(null, body, payload);
          });
        };
      }(this));
    };

    return SignatureEngineInterface;
  }();
}).call(this);