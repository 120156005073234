import * as React from 'react';
import {Component} from 'react';
import { getCordova } from '../../globals';
import './email.scss';

interface MyProps {
    email: string;
}

declare function emailTo(email:string):void;

/**
 * simple email render.
 */
export class SimpleEmail extends Component<MyProps, {}> {
    constructor(props:MyProps){
        super(props);
    }

    render(){
        return <div className='simple-email' 
                  onClick={ (evt)=>{
                    if(getCordova()){
                        evt.stopPropagation();
                        evt.preventDefault();
                        emailTo(this.props.email);
                    }
                  }}>
                {this.props.email}
            </div>;
    }
}



