import * as React from 'react';
import {Component, Fragment} from 'react';
import './term-link.scss';

/**
 * locate {#term} in text
 * @param text 
 */
export function parseTerms(text:string){
    if(!text) text = '';
    let result: {type:'text'|'term', value:string}[] = [];
    let state: 'text'|'term' = 'text';
    let buf = '';
    for(let i=0; i<text.length; i++){
        let ch = text.charAt(i);
        switch(state){
            case 'text': 
                if(ch === '{' && text.charAt(i+1) === '#'){
                    result.push({type:'text', value: buf});
                    buf = '';
                    state = 'term';
                    i++;
                }else{
                    buf += ch;
                }
                break;
            case 'term':
                if(ch === '}'){
                    result.push({type:'term', value: buf});
                    buf = '';
                    state = 'text';
                }else{
                    buf += ch;
                }
        }
    }
    if(buf){
        result.push({type:'text', value: buf});
    }

    return result;
}

export class TermLink extends Component<{term:string, highlightWords?:string[], onClickTerm?:(term:string)=>void },
{}> {
render(){
let isHighlighted = (this.props.highlightWords||[]).some(x=> x === this.props.term);
let className = 'shuyu' + (isHighlighted?' highlight':'');
return <span className={className} onClick={()=>{ this.props.onClickTerm && this.props.onClickTerm(this.props.term); }}>{this.props.term}</span>;
}
}
