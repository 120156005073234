import * as React from 'react';
import {Component, Fragment} from 'react';
import classNames from 'classnames';
import { PagedResponse } from '../../api/transaction';
import { t } from '../../i18n';
import './render-pagination.scss';
import classnames from 'classnames';
import uuidv4 from 'uuid/v4';
import { PageSizeOption, PageSizeSelector } from './page-size';

const pageSizeOptions:PageSizeOption[] = [
    {key:'10', text:'10'},
    {key:'20', text:'20'},
    {key:'40', text:'40'},
]

interface PaginationProps<T> {
    classNames?:string,
    data: PagedResponse<T>;
    page: number;
    pageSize: number;
    // renderData:(data:T[])=>JSX.Element,
    loadData:(page:number, pageSize:number)=>any;

    changePageSize?: (pageSize:number, page:number) => void;

    /**
     * optional scroll to top to be called after prev and next page done.
     * Default implementation is to scroll body to top (in case there are scroll Y)
     */
    scrollTop?: ()=>void;
}

/**
 * Generic render of pagination data. Content is passed as children.
 */
export class Pagination<T> extends Component<PaginationProps<T>, {id:string}>{
    private touchStartTime: number = 0;
    private touchStartX: number = 0;
    private touchStartY: number = 0;

    constructor(props:PaginationProps<T>){
        super(props);
        this.state = {id: uuidv4()}
    }

    prevPage = ()=>{
        let page = Math.max(0, this.props.page-1);
        if(page < this.props.page){
            this.props.loadData(page, this.props.pageSize);
        }
    }
    nextPage = ()=>{
        let page = Math.min(this.props.data.totalPages-1, this.props.page+1);
        if(page > this.props.page){
            this.props.loadData(page, this.props.pageSize);
        }
    }
    refresh = () => {
        this.props.loadData(this.props.page, this.props.pageSize);
    }

    onSwipeLeft = () => {
        if(this.props.data.page>0){
            this.prevPage();
        }
    }
    onSwipeRight = () => {
        if(this.props.data.page<this.props.data.totalPages-1){
            this.nextPage();
        }
    }
    onTouchStart = (evt:React.TouchEvent)=>{
        this.touchStartTime = Date.now();
        this.touchStartX = evt.changedTouches[0].pageX;
        this.touchStartY = evt.changedTouches[0].pageY;
    }
    onTouchEnd = (evt:React.TouchEvent)=>{
        let elapsedtime = Date.now()-this.touchStartTime;
        let deltaX = evt.changedTouches[0].pageX - this.touchStartX;
        let deltaY = evt.changedTouches[0].pageY - this.touchStartY;

        const allowedTime = 250;
        const threshold = 100;
        if(elapsedtime<allowedTime){
            if(Math.abs(deltaX)>threshold && Math.abs(deltaX)>Math.abs(deltaY)*2){
                evt.preventDefault();
                if(deltaX>0){
                    this.onSwipeRight();
                }else{
                    this.onSwipeLeft();
                }
            }
        }
    }

    renderFooter(side:'top'|'bottom'){
        let footer = <Fragment>
                        <div className={'pagination-list-footer0 '+side}>
                            <div className='page-count'>
                                <span>{t('共 ')+this.props.data!.totalElements+t(' 条')}</span>
                                &nbsp;
                                <span>{(this.props.data!.page+1)}/{this.props.data!.totalPages}</span>
                            </div>
                            {side === 'top' && this.props.changePageSize ? 
                            <PageSizeSelector 
                                initialKey={this.props.pageSize+''} 
                                label={t(`显示`)} 
                                options={pageSizeOptions}
                                onOptionChanged={key=>{
                                            let newPageSize = parseInt(key);
                                            let newPage = Math.floor(this.props.page *this.props.pageSize / newPageSize);

                                            this.props.changePageSize!(newPageSize, newPage);                                            
                                }}/> 
                                : null
                        }
                        </div>

                        <div className={'pagination-list-footer '+side}>
                            <span className={classnames('clickable',{disabled:this.props.data!.page<=0})} onClick={this.prevPage}>{t('上页 ')}</span>
                            <span className='clickable refresh' onClick={this.refresh}>{t('刷新')}</span>
                            <span className={classnames('clickable',{disabled:this.props.data!.page>=this.props.data!.totalPages-1})} onClick={this.nextPage}>{t('下页')}</span>
                        </div>
                     </Fragment>
        return footer;
    }
    render(){
        let classes = classNames('pagination-list', this.props.classNames);
    
        if(this.props.data!.totalElements===0){
            return  <div className={classes}>{this.renderFooter('top')}</div>
        }
    
        // let list = this.props.renderData(this.props.data!.content);
        return (
            <div className={classes} id={this.state.id}>
                    {this.renderFooter('top')}
                    <div className='data-list-wrapper'  onTouchMove={(evt)=>{ evt.preventDefault() }} 
                         onTouchStart={this.onTouchStart} onTouchEnd={this.onTouchEnd}>
                    {this.props.children}
                    </div>
                    {this.renderFooter('bottom')}
            </div>
        )        
    }
}


