import * as React from 'react';
import {Component, Fragment} from 'react';
import classNames from 'classnames';
import uuidv4 from 'uuid/v4'
import './page-size.scss';

export interface PageSizeOption {
    key: string;
    text: string;
}

interface PageSizeProps {
    /** label text shown before select */
    label:string;
    options: PageSizeOption[];
    initialKey:string;
    classNames?: string;
    onOptionChanged?:(key:string)=>void;
}

interface PageSizeState {
    currentKey:string;
}

export class PageSizeSelector extends Component<PageSizeProps, PageSizeState >{
    private selectId = uuidv4();

    constructor(props:PageSizeProps){
        super(props);
        this.state = {currentKey: props.initialKey}
    }

    optionChanged(key:string){
        this.setState({currentKey:key}, ()=>{
            if(this.props.onOptionChanged){
                this.props.onOptionChanged(key);
            }
        })
    }

    render(){
        let classes = classNames('shopping-page-size-selector', this.props.classNames);
        return (
            <div className={classes}>
                <label htmlFor={this.selectId} className='shopping-page-size-label'>{this.props.label}</label>
                <select name={this.selectId} id={this.selectId} defaultValue={this.state.currentKey} 
                        onChange={evt=>{ this.optionChanged(evt.currentTarget.value) }}>
                {
                    this.props.options.map(x=>{
                        return <option key={x.key} value={x.key}>{x.text}</option>
                    })
                }
                </select>
            </div>
        )
    }

}