import * as React from 'react';
import {Component, Fragment} from 'react';
import {connect, ReactReduxContext, batch} from 'react-redux';
import {WalletAppState} from '../redux-store';
import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { SignupRequest } from '../api/signup-request';
import {t} from '../i18n';

import { startAjaxCall,succAjaxCall,failedAjaxCall} from '../redux-store/ajax-call/actions';
import {signup} from '../wallet-server-api/wallet-server-api';
import { getAjaxErrorMessage } from "..//wallet-server-api/ajax-common";
import uuidv4 from 'uuid/v4';
import { SignupView } from '../view/SignupView';
import { NoticeDialog } from '../view/NoticeDialog';
import { Toast } from '../view/Toast';
import { saveLastLoginEmail } from '../local-storage/local-storage';
import { gotoLoginPage } from '../shopping/history/shopping-history';
import { BaseUnishellPageState, renderInitUnishellPage } from '../unishell-page-common/unishell-page-common';
import { loadExternContatnsIfNecessary } from '../external-constants/extern-constants';
import { ConfirmDialogView } from '../view/confirm-dialog/ConfirmDialogView';

export class Signup extends Component<{},BaseUnishellPageState> {
    private errorDialog: NoticeDialog|undefined;
    private snackbar: Toast|undefined;
    private confirmDialog:ConfirmDialogView|undefined;

    constructor(props:{}){
        super(props);
        this.state = {pageInitState:'PAGE_INIT'}
    }

    render() {
        if(this.state.pageInitState === 'PAGE_INIT'){
            return (
                <Fragment>
                { renderInitUnishellPage({
                      title:t('会员注册'),
                      className: 'login-page',
                      noMenuIcon: true                    
                })}
                </Fragment>
            )
        }

        return (
            <ReactReduxContext.Consumer>
            { ({store})=>{
                const gotoLoginPage1 = ()=>{
                    gotoLoginPage();
                }
                const gotoLoginPage2 = (password:string)=>{
                    gotoLoginPage({notice:t('请点击电邮里账号激活链接后，再登录'), password});
                }
                

                const thunkSignup = (request:SignupRequest)=>{
                    const whatIsFor = t('会员注册');
                    const uuid = uuidv4();
                    // alert('payment key='+genPaymentKey(request.email+request.password));

                    signup(request).then((result)=>{
                        store.dispatch(succAjaxCall(whatIsFor, uuid));
                        saveLastLoginEmail(request.email);
                        this.confirmDialog!.show({
                            title: whatIsFor+t(' 成功'),
                            message: <p>{t('请点击电邮里账号激活链接')}</p>,
                            yesLabel: t('收到'),
                            disableNoButton: true,
                            handleClose: ()=>{
                                gotoLoginPage2(request.password);
                            }
                        })
                        // this.snackbar!.sendMessage(whatIsFor+t(' 成功')+'. '+t('请点击电邮里账号激活链接'), 'success', false, ()=>{
                        //     gotoLoginPage2(request.password);
                        // })
                    }).catch(error=>{
                        store.dispatch(failedAjaxCall(whatIsFor,uuid, getAjaxErrorMessage(error)));
                        this.errorDialog!.show(whatIsFor+t(' 失败'), getAjaxErrorMessage(error), ()=>{})
                    });
                    this.setState({})
                    
                    store.dispatch(startAjaxCall(whatIsFor, uuid));
                }

                return <Fragment>
                            <SignupView signup={thunkSignup} gotoLoginPage={gotoLoginPage1} dispatch={store.dispatch}/>
                            <NoticeDialog open={false} ref={e=> {this.errorDialog=e!}} title='' message=''/>
                            <ConfirmDialogView open={false} ref={e=>this.confirmDialog=e!} />
                            <Toast message='' toastType='success' close={true} ref={e=>{this.snackbar=e!}} duration={1000*10}/> 
                        </Fragment>
                }
            }    
            </ReactReduxContext.Consumer>
        )
    }

    async componentDidMount(){
        await loadExternContatnsIfNecessary();
        this.setState({pageInitState:'PAGE_LOAD_SUCCESS'});
    }
}



