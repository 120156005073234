import { store } from "../..";
import { getValidLoginSession,  } from "../../local-storage/local-storage";
import uuidv4 from 'uuid/v4';
import { failedAjaxCall, startAjaxCall, succAjaxCall } from "../../redux-store/ajax-call/actions";
import { getAjaxErrorMessage, is401Error } from "../../wallet-server-api/ajax-common";
import { NoticeDialog } from "../../view/NoticeDialog";
import { t } from "../../i18n";
import { gotoLoginPage,  } from "../history/shopping-history";

function logout() {
    gotoLoginPage();
}

/**
 * generic template function that does ajax call and displays overlay
 * 
 * @param args whatIsFor - ajax call name; 
 *             whatIsForFn - optional function to override `whatIsFor`
 *             withoutLoginSession - optional function to indicate calling this function w/o login session
 *             errorDialog - erorr dialog to display error message
 *             ajaxCall - function does the async calls
 *             errorMessageFromatter - customize content shown in error dialog
 *             onError - callback if encounter non-authentication error 
 */
export async function doAJAXCall<T>(args:{ 
                                        whatIsFor:string,
                                        whatIsForFn?: ()=>string,
                                        withoutLoginSession?: boolean,
                                        ajaxCall: (accessToken:string, username:string, roles:string[])=>Promise<T>,
                                        errorDialog:NoticeDialog, 
                                        errorMessageFromatter?: (error:any)=>string|JSX.Element,
                                        onError?: (errorMessage:string, error: any)=>any; 
                           }): Promise<T|undefined> {
    let {whatIsFor,whatIsForFn, withoutLoginSession: withoutLogin, ajaxCall: asyncCall,errorMessageFromatter, errorDialog, onError} = args;                               
    const expectedLoginSession = !withoutLogin;                            

    if(whatIsForFn){
        whatIsFor = whatIsForFn();
    }

    let loginSession = getValidLoginSession()!;
    if(expectedLoginSession){
        if(!loginSession){ 
            logout();
            return undefined;
        }    
    }
    const accessToken = expectedLoginSession? loginSession.token!.accessToken: '';
    const username = expectedLoginSession? loginSession.profile!.username: '';
    const roles = expectedLoginSession? loginSession.profile!.roles: [];

    let uuid = uuidv4();

    store.dispatch (startAjaxCall(whatIsFor, uuid));
    try{
        let result = await asyncCall(accessToken, username, roles);
        store.dispatch (succAjaxCall(whatIsFor, uuid));
        return result;
    }catch(error){
        let errorMessage = getAjaxErrorMessage(error);
        let errorMessage2 = errorMessageFromatter? errorMessageFromatter(error): null;
        const title = whatIsForFn? whatIsForFn() || whatIsFor : whatIsFor;

        store.dispatch (failedAjaxCall(whatIsFor, uuid, errorMessage));
        if(expectedLoginSession && is401Error(error)){
            errorDialog!.show(title+t(' 失败'), errorMessage2 || errorMessage, 
            ()=>{
                gotoLoginPage();
            });
        }else{
            errorDialog!.show(title+t(' 失败'), errorMessage2 || errorMessage, 
            ()=>{
                onError && onError(errorMessage, error);
            });
        }
    } 
}