// Generated by IcedCoffeeScript 108.0.11
(function () {
  var BaseKeyPair,
      Pair,
      __hasProp = {}.hasOwnProperty,
      __extends = function __extends(child, parent) {
    for (var key in parent) {
      if (__hasProp.call(parent, key)) child[key] = parent[key];
    }

    function ctor() {
      this.constructor = child;
    }

    ctor.prototype = parent.prototype;
    child.prototype = new ctor();
    child.__super__ = parent.prototype;
    return child;
  };

  BaseKeyPair = require('../basekeypair').BaseKeyPair;

  exports.BaseKeyPair = Pair = function (_super) {
    __extends(Pair, _super);

    function Pair() {
      return Pair.__super__.constructor.apply(this, arguments);
    }

    Pair.prototype.export_secret_key_kb = function (args, cb) {
      var err, res, _ref;

      err = res = null;

      if ((res = (_ref = this.priv) != null ? _ref.key : void 0) == null) {
        err = new Error("no private key available");
      }

      return cb(err, res);
    };

    return Pair;
  }(BaseKeyPair);
}).call(this);