import { Grid } from '@material-ui/core';
import * as React from 'react';
import {Component, Fragment} from 'react';
import { formatPrice } from '../../util/Util';
import { CurrencyOption, ProductCandidateBrief } from '../ajax-data/ajax-data-types';
import uuidv4 from 'uuid/v4';
import './product-brief.scss';
import { NoticeDialog } from '../../view/NoticeDialog';
import { t } from '../../i18n';
import { MultilineField } from '../../view/search-products/multiline-field';
import { doAJAXCall } from './ajax-call';
import { getShuyu } from '../../wallet-server-api/wallet-server-api';


interface MyProps {
    prod: ProductCandidateBrief;
    currencyOption: CurrencyOption;
    onClickImage: ()=> void;
}

interface MyState {
    imageId: string;

    imageWrapperId: string;
    imageWrapperHeight?: number;

    descriptionId: string;
    descOrigHeight?: number;

    imageHeight?: number;
}

export class ShoppingProductBrief extends Component<MyProps, MyState>{
    private errorDialog:NoticeDialog|undefined;
    private termDialog:NoticeDialog| undefined;
    private moreDialog:NoticeDialog | undefined;

    constructor(props: MyProps){
        super(props);
        this.state = {imageWrapperId: uuidv4(), imageId:uuidv4(), descriptionId: uuidv4()}
    }

    showMoreDialog(){
        this.moreDialog!.show(this.props.prod.productName, 
                              <Fragment>
                                {this.props.prod.application?
                                    <MultilineField
                                        lines={this.props.prod.application!}
                                        skipShuyu={false}
                                        onClickTerm={this.displayShuyu}
                                    />: null
                                }
                                {this.props.prod.feature? 
                                  <Fragment>
                                    <br/><br/>
                                    <MultilineField
                                        lines={this.props.prod.feature!}
                                        skipShuyu={false}
                                        onClickTerm={this.displayShuyu}
                                    />
                                  </Fragment>:null
                                }
                              </Fragment>, 
                              ()=>{})
    }

    displayShuyu = async (term:string)=>{
        let whatIsFor:string = t('读取术语');
        doAJAXCall({
            whatIsFor,
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken)=>{
                let termDef = await getShuyu(term, accessToken);
                if(this.moreDialog!.isOpen()){
                    this.moreDialog!.show2({open: false}, ()=>{
                        this.termDialog!.show(term, 
                            <MultilineField label='' lines={termDef.fields.field_mingci} skipShuyu={true} onClickTerm={()=>{}}/> , 
                            ()=>{
                                this.moreDialog!.show2({open: true});
                            })        
                    });
                }else{
                    this.termDialog!.show(term, 
                        <MultilineField label='' lines={termDef.fields.field_mingci} skipShuyu={true} onClickTerm={()=>{}}/> , 
                        ()=>{})    
                }
            }
        })
    }

    render(){
        const {prod, onClickImage, currencyOption} = this.props;
        const {imageWrapperHeight} = this.state;

        let showMore = false;
        const descStyle:React.CSSProperties = {};
        const imageStyle:React.CSSProperties = {};
        if(imageWrapperHeight){
            if(this.state.imageHeight){
                imageStyle.height = this.state.imageHeight!+'px';
                imageStyle.width = 'auto';
            }
            descStyle.height = imageWrapperHeight.toFixed(2)+'px';
            if(this.state.descOrigHeight! - imageWrapperHeight > 8){// todo: a better way to detect if text is hidden
                showMore = true;
            }
        }

        return  (
        <div className='shopping-product-brief-wrapper'>
            <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
            <NoticeDialog open={false} ref={e=>this.termDialog=e!}/>
            <NoticeDialog open={false} ref={e=>this.moreDialog=e!}/>

            <div className='shopping-product-brief'>
                <Grid container spacing={0} className='image-n-description'>
                    <Grid item xs={5} className='image-grid-item'>
                        <div className='thumbnail-img-wrapper'  id={this.state.imageWrapperId}>
                            <img src={prod.thumbImage} className='thumb-img' id={this.state.imageId} style={imageStyle}
                                onClick={()=>{ onClickImage();  }}
                                onLoad={()=>{ this.onImageLoaded() }}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={7} className='description-grid-item'>
                        <div className='product-description' style={descStyle} key={Date.now()} id={this.state.descriptionId}>
                            {prod.application? 
                                <MultilineField lines={prod.application!}
                                                skipShuyu={false}
                                                onClickTerm={this.displayShuyu} 
                                />
                                : null
                            }                    
                            {prod.feature? 
                                <Fragment>
                                    <br/><br/>
                                    <MultilineField lines={prod.feature!}
                                                    skipShuyu={false}
                                                    onClickTerm={this.displayShuyu} 
                                    />
                                </Fragment>
                                : null
                            }
                        </div>   
                        {showMore? <div>
                                        <button className='read-more-button' onClick={()=>{ this.showMoreDialog() }}>{t('更多内容...')}</button>
                                    </div>: null}                                                 
                    </Grid>
                </Grid>                
                
                <div className='product-name' onClick={()=>{ onClickImage() }}
                >{prod.productName}</div>
                <div className='product-price'>{formatPrice(prod.price, currencyOption.symbol, currencyOption.code)}</div>
            </div>
        </div>    
        )
    }

    onImageLoaded(){
        const imageWrapperNode = document.getElementById(this.state.imageWrapperId)!;
        const {height} = imageWrapperNode.getBoundingClientRect();
        const descriptionNode = document.getElementById(this.state.descriptionId)!;
        const descHeight = descriptionNode.getBoundingClientRect().height;

        const imageNode = document.getElementById(this.state.imageId)!;
        let imageWidth = imageNode.getBoundingClientRect().width;
        let imageHeight = 0; 
        if(imageNode.getBoundingClientRect().height > imageWidth * 2.5){
            imageHeight = imageWidth * 2.5;
        }
        
        if(!this.state.imageWrapperHeight){
            this.setState({imageWrapperHeight: height, descOrigHeight: descHeight, imageHeight: imageHeight>0? imageHeight: undefined});
        }
    }
}