export const SET_POINTS = 'SET_POINTS';

export interface PointsRecord {
    points: number;
    timestamp: number;
}

interface SetPointsAction {
    type: typeof SET_POINTS,
    points: number;    
    timestamp: number;
}

export function setPointsRecord(points:number):SetPointsAction {
    return {
        type:SET_POINTS,
        points,
        timestamp: Date.now()
    }
}

const init:PointsRecord = {points:0, timestamp:0}

export function pointsReducer(state = init, action:SetPointsAction):PointsRecord {
    switch(action.type){
        case SET_POINTS: return {points:action.points, timestamp: action.timestamp}
        default: return state;
    }
}
