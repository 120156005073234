import * as React from 'react';
import {Component, Fragment} from 'react';

import './Copywright.scss';
import {t} from '../../i18n';

interface CopywrightProps {
}

export class Copywright extends Component<CopywrightProps,{}>{
    constructor(props:CopywrightProps){
        super(props);
    }

    render(){
        return (
            <div className='footer'>
                {t('版权所有')} 爱信培恩(广东)健康管理有限公司{' 2019-'+(new Date()).getFullYear()}
            </div>
        )
    }
}

