import { t } from "../i18n";
import { readLoginSession } from "../local-storage/local-storage";

export interface JwtAuthenticationResponse {
    accessToken:string; // jwf token
    tokenType: string;
}

export interface LoginRequest{
    usernameOrEmail:string;
    password: string;
    deviceInfo?: string;
    clientType?: string;
}

export interface UserSummary {
    id: number;
    /** system generated uuid */
    username: string; 
    /** login name */
    name: string;  
    phoneNumber: string;
    /** user roles */
    roles: string[];  
    language: string;  
    userEmail:string;
}

export interface UserProfile extends UserSummary{
    /** default is CAD */
    currency?: string;
}

export interface QRCodeResponse {
    code: string;
    base64Image: string;
}

export interface RefreshRequest {
    keyId: string; // user's public key id
    data: string;  // signed current jwt token using user's private key
}

export interface ResetPasswordRequest {
    email: string;
}

export interface ProfileRequest {
    language?: string;
    name?: string;
    phoneNumber?: string;
}

export interface SearchUserParms {
    nameKeyword?: string;
    emailKeyword?: string;
    roles?: string[];
    page: number;
    pageSize: number;
}

export interface UserUpdateRequest {
    email: string;
    language?: string;
    name?: string;
    phoneNumber?: string;
    roles?: string[];
}

export const ROLE_USER = 'ROLE_USER';
export const ROLE_NUTRITIONIST = 'ROLE_NUTRITIONIST';
export const ROLE_ADMIN = 'ROLE_ADMIN';
export const ROLE_PHARMACIST = 'ROLE_PHARMACIST';
/** psuedo role */
export const ROLE_SHOP_GNGINC = 'ROLE_SHOP_GNGINC'

export function translateRoles (roles:string[]):string[] {
    return roles.map(role=>{
        switch(role){
            case 'ROLE_USER': return t('会员');
            case 'ROLE_ADMIN': return t('管理员');
            case 'ROLE_DEV': return t('开发者');
            case ROLE_NUTRITIONIST: return t('营养师');
            case ROLE_PHARMACIST: return t('药剂师');
            case ROLE_SHOP_GNGINC: return t('商城');
            default: return role;
        }
    })
}

export function translateRole(roleName:string){
    switch(roleName){
        case 'ROLE_USER': return t('会员');
        case 'ROLE_ADMIN': return t('管理员');
        case 'ROLE_DEV': return t('开发者');
        case ROLE_NUTRITIONIST: return t('营养师');
        case ROLE_PHARMACIST: return t('药剂师');
        case ROLE_SHOP_GNGINC: return t('商城');
        default: return roleName;
    }
}

export function hasRoleDev (roles:string[]):boolean {
    return roles.some(role=>role==='ROLE_DEV');
}

export function hasRoleAdmin (roles:string[]):boolean {
    return roles.some(role=>role==='ROLE_ADMIN');
}

export function isAdmin(): boolean {
    const loginSession = readLoginSession()!;
    
    if(loginSession){
        return hasRole(loginSession.profile!.roles, ROLE_ADMIN);
    }
    return false;
}

export function isNutritionist(): boolean {
    const loginSession = readLoginSession()!;
    
    if(loginSession){
        return hasRole(loginSession.profile!.roles, ROLE_NUTRITIONIST);
    }
    return false;
}

export function isPharmacist(): boolean {
    const loginSession = readLoginSession()!;
    
    if(loginSession){
        return hasRole(loginSession.profile!.roles, ROLE_PHARMACIST);
    }
    return false;
}

export function hasRole (roles:string[], roleName:string):boolean {
    return roles.some(role=>role===roleName);
}

export function cloneUser(user:UserProfile): UserProfile {
    return JSON.parse(JSON.stringify(user));
}

export function doIHaveOneOfRoles(roles:string[]){
    const loginSession = readLoginSession()!;
    
    if(loginSession){
        const myRoles = loginSession.profile!.roles;
        for(let r of roles){
            if(hasRole(myRoles, r)){
                return true;
            }
        }
    }
    return false;

}
