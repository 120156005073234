import * as React from 'react';
import {Component, Fragment} from 'react';
import {connect, batch, ReactReduxContext} from 'react-redux';
import {WalletAppState} from '../redux-store';
import {t} from '../i18n';
import { ReceiveView } from '../view/receive/ReceiveView';
import { readLoginSession } from '../local-storage/local-storage';

export class Receive extends Component<{},{}>
{
    constructor(props:{}){
        super(props);
    }

    render(){
        const session = readLoginSession()!;

        return (
            <ReactReduxContext.Consumer>
            {
                (({store})=>{
                    return <ReceiveView qrCodeImage={session.profile!.base64Image} 
                                          recipientAddr={session.profile!.username}
                           />
                })
            }
            </ReactReduxContext.Consumer>)
    }
}
