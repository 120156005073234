import { getLanguageCode } from "../local-storage/local-storage";

export interface SignupRequest {
    name: string;
    phoneNumber: string;
    email: string;
    password: string;
    confirmPassword?: string;
    language: string;
};

export function getEmptySignupRequest() {
    const req:SignupRequest={name:'', phoneNumber:'',email:'',password:'',confirmPassword:'', language:getLanguageCode()}
    return req;
}

