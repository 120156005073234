import React from 'react';
import deepEqual from "deep-equal";
import { store } from "../..";
import { saveLoginSession } from "../../local-storage/local-storage";
import { setCurrentPathPath } from "../../redux-store/path/actions";
import { CURRENT_PAGE } from "../../redux-store/path/types";
import { BaseShoppingPageProps } from "../common/shopping-page-common";
import { ConfirmDialogView } from '../../view/confirm-dialog/ConfirmDialogView';
import { t } from '../../i18n';

export interface PathRecord {
    path: CURRENT_PAGE;
    parms?: {[key:string]:any}
}

export interface ShoppingProductSearchProps extends BaseShoppingPageProps{
    initialSearchText?:string;
    categoryName?:string;
    currencyCode?:string;
    page?: number;
    pageSize?: number;
}

export interface ShoppingSearchProductPath extends PathRecord {
    path: 'main/shopping/search',
    parms:  ShoppingProductSearchProps; 
}

let _pathRecordStack:PathRecord[] = [];
const MAX_STACK_DEPTH = 100;


export function pushShoppingSearchProductPath(path:ShoppingSearchProductPath){
    _pathRecordStack.push(path);
    limitStackSize();
}

export function peekShoppingPathRecord() {
    if(_pathRecordStack.length > 0){
        return _pathRecordStack[_pathRecordStack.length-1];
    }else{
        return undefined;
    }
}

export function clearShoppingPathRecordStack(){
    _pathRecordStack = [];
}

/**
 * It is used by a page to update path parms so that user can return back to its last state when hit back icon.
 * @param currPath 
 * @param newParms 
 */
export function updateCurrentPathParms(currPath: CURRENT_PAGE, newParms?:{[key:string]:any}){
    if( store && store.getState() && store.getState().pagePath && 
        currPath === store.getState().pagePath.currentPage){
            store.getState().pagePath.parms = newParms; 
       }
}

export function mergeCurrentPathParms(currPath: CURRENT_PAGE, newParms?:{[key:string]:any}){
    if( store && store.getState() && store.getState().pagePath && 
        currPath === store.getState().pagePath.currentPage){
            const oldParms = store.getState().pagePath.parms || {};
            store.getState().pagePath.parms =  {...oldParms, ...newParms}; 
       }
}

/**
 * go to a new page. save current page path and parameters stored in Redux store in history.
 * 
 * @param newPath 
 * @param newParms 
 */
export function gotoShoppingPathNSavePrevPath(newPath:CURRENT_PAGE, newParms?:{[key:string]:any}){

    if(store && store.getState() && store.getState().pagePath && 
       newPath === store.getState().pagePath.currentPage && deepEqual(newParms, store.getState().pagePath.parms)){
        return; // we are at same page now
    }
    pushShoppingPathRecord({path: store.getState().pagePath.currentPage!, parms: store.getState().pagePath.parms});
    store.dispatch(setCurrentPathPath(newPath, newParms));
}

/**
 * save the given prev page path and parameters in history, and then go to a new page. 
 * 
 * @param newPath 
 * @param newParms 
 * @param prevPath 
 * @param prevParms 
 */
export function gotoShoppingPathAndSavingGivenPath(
                                        newPath:CURRENT_PAGE, 
                                        newParms:{[key:string]:any}|undefined,
                                        prevPath:CURRENT_PAGE, 
                                        prevParms?:{[key:string]:any}){
    if(newPath === prevPath && deepEqual(newParms, prevParms)){
        return; // we are at same page now
    }
    pushShoppingPathRecord({path: prevPath, parms: prevParms});
    store.dispatch(setCurrentPathPath(newPath, newParms));
}

/**
 * go to a new page w/o saving current page path and parameters stored in Redux store in history.
 * 
 * @param newPath 
 * @param newParms 
 */
export function gotoShoppingPathWOSavingPrevPath(newPath:CURRENT_PAGE, newParms?:{[key:string]:any}){
    if(store && store.getState() && store.getState().pagePath){
        if(newPath === store.getState().pagePath.currentPage && deepEqual(newParms, store.getState().pagePath.parms)){
            return; // we are at same page now
        }
    }
    store.dispatch(setCurrentPathPath(newPath, newParms));
}

export function clearStackNGotoShoppingPath(newPath:CURRENT_PAGE, newParms?:{[key:string]:any}){
    clearShoppingPathRecordStack();
    gotoShoppingPathNSavePrevPath(newPath, newParms);
}

export function gotoLoginPage(newParms?:{[key:string]:any}){
    saveLoginSession(null);
    clearShoppingPathRecordStack();
    store.dispatch(setCurrentPathPath('login', newParms));
}

export function pushShoppingHomePath(){
    _pathRecordStack.push({path:'main/shopping/search', parms: {}});
    limitStackSize();
}


export function popShoppingPath():PathRecord|undefined{
    return _pathRecordStack.pop();
}

export function pushShoppingPathRecord(pathRec:PathRecord){
    if(['main/shopping/checkout', 'main/shopping/payment'].some(x => x === pathRec.path)){
        return; // skip very non-self-reliant pages
    }
    _pathRecordStack.push(pathRec);
    limitStackSize();
}

function limitStackSize(){
    if(_pathRecordStack.length>MAX_STACK_DEPTH){
        // keep most recent paths
        _pathRecordStack = _pathRecordStack.slice(_pathRecordStack.length-MAX_STACK_DEPTH, _pathRecordStack.length);
    }
}

export function shoppingGoBack() {
    const backFn = ()=>{
        let shoppingPath = popShoppingPath();
        if(shoppingPath){
            store.dispatch(setCurrentPathPath(shoppingPath.path, shoppingPath.parms));
        }else{
            store.dispatch(setCurrentPathPath('main/shopping/search', {}));
        }    
    }

    if(store && store.getState() && store.getState().pagePath && store.getState().pagePath.currentPage === 'main/shopping/create-health-advice'){
        // @ts-ignore
        let confirmDailog = window.openUrlDialog as ConfirmDialogView;
        if(confirmDailog){
            confirmDailog.show({
                title: t('退出开具建议书'),
                message: <div>{t('退出开具建议书?')}</div>,
                handleClose: (ok:boolean) => {
                    if(ok){
                        backFn();
                    }
                }
            })
        }else{
            backFn();
        }
    }else{
        backFn();
    }
}

export function shoppingGoBackWOConfirmation() {
    let shoppingPath = popShoppingPath();
    if(shoppingPath){
        store.dispatch(setCurrentPathPath(shoppingPath.path, shoppingPath.parms));
    }else{
        store.dispatch(setCurrentPathPath('main/shopping/search', {}));
    }    
}

// @ts-ignore
window.get_pathRecordStack = ()=>{ return _pathRecordStack}



