import * as React from 'react';
import {Component} from 'react';
import { getValidReactChildren } from '../../util/Util';
import './h-equal-size.scss';
import classNames from 'classnames';
import { truncateSync } from 'fs';
import classnames from 'classnames';


/**
 * place N number of children horizontally with equal width.
 * 
 */
interface MyProps {
    className?: string;
    justifyContent: 'space-around'|'space-between';
    spacing?: string;
}

export class HEqualSize extends Component<MyProps, {initialized?:boolean}>{
    constructor(props: MyProps){
        super(props);
        this.state = {}
    }

    render(){
        const {className, spacing} = this.props;
        const style:React.CSSProperties = {}
        
        if(spacing){
            style.paddingLeft = spacing;
            style.paddingRight = spacing;
            style.boxSizing = 'border-box';
            style.columnGap = spacing;
        }
        
        const children = getValidReactChildren(this.props.children)

        return (
            <div className={classNames('h-equal-size', className)} style={style}>
            {children.map(c=>{
                return <div className={classnames('h-equal-size-child', {initialized: this.state.initialized})}>{c}</div>
            })}    
            </div>
        )
    }
    componentDidMount(){
        this.setState({initialized: true})
    }
}