import { Button, Checkbox, createTheme, FormControl, FormControlLabel, Grid, IconButton, InputBase, InputLabel, MenuItem, Paper, RadioGroup, Select, TextField, ThemeProvider } from '@material-ui/core';
import * as React from 'react';
import {Component, Fragment} from 'react';
import { DocSpecResponse } from '../../api/docs';
import { t } from '../../i18n';
import {ReactComponent as FilterIcon} from '../../images/filter.svg';

import './advanced-product-search-form.scss';
import { BaseShoppingPageProps} from './shopping-page-common';
import { SearchTextInput } from './search-text-input';
import * as _ from 'lodash';
import { UButton } from '../../widget/button/ubutton';
import ReactDOM from 'react-dom';
import { calcRationClass, RatioXS } from '../../util/Util';

export interface AdvancedProductSearchFilter {
    symptomKeywords?:string;
    professionalChoice?: string;
    functionalChoice?: string;
    productName?:string;
    ingredient?:string;
    fullTextKeywords?: string;
    fuzziness?:boolean;
}

interface AdvancedSearchFormProps extends BaseShoppingPageProps, AdvancedProductSearchFilter{
    languageCode: string;
    docSpecs: DocSpecResponse;

    expandSearchForm?: boolean;

    triggerSearch: (filter:AdvancedProductSearchFilter)=>void;
}

interface AdvancedSearchFormState extends AdvancedProductSearchFilter{
    expandSearchForm: boolean;
}

export class AdvancedProductSearchForm extends Component<AdvancedSearchFormProps,AdvancedSearchFormState>{
    private searchInput:SearchTextInput|undefined;
    private wrapperId = 'extra-search-form-wrapper'+Date.now();                    


    constructor(props:AdvancedSearchFormProps){
        super(props);

        this.state = {symptomKeywords: this.props.symptomKeywords||'',
                      professionalChoice: this.props.professionalChoice,
                      functionalChoice: this.props.functionalChoice,
                      productName: this.props.productName||'',
                      ingredient: this.props.ingredient||'',
                      fullTextKeywords: this.props.fullTextKeywords||'',
                      fuzziness: this.props.fuzziness===undefined? true: !!this.props.fuzziness,
                      expandSearchForm: !!this.props.expandSearchForm
                    }
    }

    doSearch = ()=>{
        const state = _.cloneDeep(this.state) as AdvancedSearchFormState;
        state.symptomKeywords = this.searchInput!.getValue();
        this.setState({expandSearchForm: false}, ()=>{
            this.props.triggerSearch(state);
        });
    }
    
    render(){
        let extraSearchFilter:JSX.Element|null=null;
        if(this.state.expandSearchForm){
            const zhuanyefenleiSpec = this.props.docSpecs.content.find(x=>x.name==='chanpin')!.fieldSpecs['field_zhuanyefenlei'];
           
            let professionalChoice = this.state.expandSearchForm && zhuanyefenleiSpec? 
                                    <FormControl className='extra-search-form-control'>
                                        <InputLabel htmlFor="select-zhuanyefenlei">{t('专业分类')}</InputLabel>
                                        {/* use native select on mobile */}
                                        <Select value={this.state.professionalChoice||''} 
                                                onChange={(evt)=>{ this.setState({professionalChoice: evt.target.value as string})}} 
                                                inputProps={{name: 'select-zhuanyefenlei', id: 'select-zhuanyefenlei'}}>
                                            {(()=>{
                                                let options = Object.keys(zhuanyefenleiSpec.valueMappings!).map(key=>{
                                                    return <MenuItem  key={key} 
                                                                    value={key}
                                                            >
                                                            {zhuanyefenleiSpec.valueMappings![key]}
                                                        </MenuItem>
                                                });
                                                options.unshift(<MenuItem key='' value="">{t('任何分类')}</MenuItem>)

                                                return options;
                                             })()}        
                                        </Select>
                                    </FormControl>: 
                                    null;
    
            const gongnengfenleiSpec = this.props.docSpecs.content.find(x=>x.name==='chanpin')!.fieldSpecs['field_gongnengfenlei'];
            let functionalChoice = <FormControl className='extra-search-form-control'>
                                        <InputLabel htmlFor="select-gongnengfenlei">{t('功能分类')}</InputLabel>
                                        <Select value={this.state.functionalChoice||''} 
                                                onChange={(evt)=>{ this.setState({functionalChoice: evt.target.value as string})}} 
                                                inputProps={{name: 'select-gongnengfenlei', id: 'select-gongnengfenlei'}}>
                                        {(()=>{
                                            let options = Object.keys(gongnengfenleiSpec.valueMappings!).map(key=>{
                                                                return <MenuItem key={key} value={key}>{gongnengfenleiSpec.valueMappings![key]}</MenuItem>
                                                            });
                                            options.unshift(<MenuItem aria-label="None" key='' value="" >{t('任何功能')}</MenuItem>);
                                            return options;
                                        })()}            
                                        </Select>
                                    </FormControl>;

            let productNameSearch = <TextField label={t('产品名称关键词')} className='extra-search-form-control'
                                                value={this.state.productName}
                                                onChange={(evt)=>{ this.setState({productName: evt.target.value}) }}
                                                onKeyPress={(evt)=>{
                                                if(evt.key === 'Enter'){
                                                    this.doSearch();
                                                }
                                            }}
                                            placeholder={t('关键词1 关键词2 ...')}
                                    />
            let ingredientSearch = <TextField label={t('原料名称关键词')} className='extra-search-form-control'
                                    value={this.state.ingredient}
                                    onChange={(evt)=>{ this.setState({ingredient: evt.target.value}) }}
                                    onKeyPress={(evt)=>{
                                        if(evt.key === 'Enter'){
                                            this.doSearch();
                                    }
                                    }}
                                    placeholder={t('关键词1 关键词2 ...')}
                            />
            let fullTextSearch = <TextField label={t('全文搜索')} className='extra-search-form-control fulltext-search'
                                            value={this.state.fullTextKeywords}
                                            onChange={(evt)=>{ this.setState({fullTextKeywords: evt.target.value})}} 
                                            onKeyPress={(evt)=>{
                                                if(evt.key === 'Enter'){
                                                    this.doSearch();
                                                }
                                            }}
                                            placeholder={t('关键词1 关键词2 ...')}></TextField>
            let fuzziness = <RadioGroup>
                                <FormControlLabel 
                                    control={<Checkbox checked={!this.state.fuzziness} color='primary'
                                                        onChange={(e)=>{ this.setState({fuzziness: !e.target.checked}) }} />} 
                                    label={t('更精确匹配关键词')}
                                />
                            </RadioGroup>;
                            
            const {ratioClass, vw,  mw, ratio} = calcRationClass();
            let xs1 = ratio >= RatioXS ? 6 : 12;
            let xs2 = ratio >= RatioXS*2? 6 : 12;

            extraSearchFilter = ReactDOM.createPortal(
                <div className='extra-search-form-wrapper' tabIndex={0} id={this.wrapperId} 
                     onMouseDown={()=>{
                         document.getElementById(this.wrapperId)!.focus();
                     }}>
                    <Paper className={'extra-search-form '+ratioClass}>
                        <div className='header'>{t('高级搜索选项')}</div>
                        <Grid container spacing={0}>
                            <Grid item xs={xs1 as 12}>{professionalChoice}</Grid>
                            <Grid item xs={xs1 as 12}>{functionalChoice}</Grid>
                            <Grid item xs={xs2 as 12}>{productNameSearch}</Grid>
                            <Grid item xs={xs2 as 12}>{ingredientSearch}</Grid>
                            <Grid item xs={xs2 as 12}>{fullTextSearch}</Grid>
                            <Grid item xs={xs2 as 12}>{fuzziness}</Grid>
                            <Grid item xs={12} className='action-buttons'>
                                <UButton variant='contained' size='small' color='secondary'
                                        onClick={()=>{ this.setState({expandSearchForm: false})}}
                                >{t('关闭')}</UButton>
                                <UButton variant='contained' size='small' color='primary'
                                        onClick={()=>{ 
                                            this.doSearch();
                                        }}
                                >{t('搜索')}</UButton>
                            </Grid>
                        </Grid>
                    </Paper>
                </div>,
                document.getElementById('root')!
            )
        }

        const theme = createTheme({
            breakpoints: {
              values: {
                xs: 0,
                sm: 300,
                md: 600,
                lg: 1280,
                xl: 1920,
              },
            },
          });

        let content = (
            <div className='advanced-product-search-form'>
                <FilterIcon className='filter-icon' onClick={()=>{
                    this.setState({expandSearchForm: !this.state.expandSearchForm});
                }}/>
                <SearchTextInput placeholder={t('应用范围关键词1 关键词2 ...')} 
                                 initialValue={this.state.symptomKeywords} 
                                 ref={x=>{this.searchInput=x!}} 
                                 onSearch={()=>{
                                     this.doSearch();
                                    }
                                }/>
                {extraSearchFilter}                
            </div>    
        )

        return <ThemeProvider theme={theme}>
                {content}
              </ThemeProvider>
    }

    getFilters():AdvancedProductSearchFilter{
        let filter:AdvancedProductSearchFilter = {symptomKeywords: this.state.symptomKeywords||'',
                                                    professionalChoice: this.state.professionalChoice,
                                                    functionalChoice: this.state.functionalChoice,
                                                    productName: this.state.productName||'',
                                                    ingredient: this.state.ingredient||'',
                                                    fullTextKeywords: this.state.fullTextKeywords||'',
                                                    fuzziness: this.state.fuzziness===undefined? true: !!this.state.fuzziness,
                                                }
        return filter;
    }
}

