// Generated by IcedCoffeeScript 108.0.11
(function () {
  exports.bufeq_secure = function (x, y) {
    var check, i, ret;

    ret = function () {
      var _i, _ref;

      if (x == null && y == null) {
        return true;
      } else if (x == null || y == null) {
        return false;
      } else if (x.length !== y.length) {
        return false;
      } else {
        check = 0;

        for (i = _i = 0, _ref = x.length; 0 <= _ref ? _i < _ref : _i > _ref; i = 0 <= _ref ? ++_i : --_i) {
          check |= x.readUInt8(i) ^ y.readUInt8(i);
        }

        return check === 0;
      }
    }();

    return ret;
  };
}).call(this);