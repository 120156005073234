import * as React from 'react';
import {Component, Fragment} from 'react';

import './PaymentOrderListView.scss';
import {t} from '../../i18n';
import { PagedResponse, MerchantOrderBrief } from '../../api/transaction';
import { ReactReduxContext } from 'react-redux';
import { retrievePaymentOrderList } from '../../wallet-server-api/wallet-server-api';
import {WalletAppState} from '../../redux-store';
import { NoticeDialog } from '../NoticeDialog';
// @ts-ignore
import dataFormat from 'date-format';
import classNames from 'classnames';
import { readLoginSession, getLanguageCode } from '../../local-storage/local-storage';
import { BaseUnishellPageState, renderInitErrorUnishellPage, renderInitUnishellPage, renderUnishellPage } from '../../unishell-page-common/unishell-page-common';
import { gotoLoginPage } from '../../shopping/history/shopping-history';
import { doAJAXCall } from '../../shopping/common/ajax-call';
import { LANG_CODE_EN, loadExternContatnsIfNecessary } from '../../external-constants/extern-constants';
import { Pagination } from '../../shopping/common/render-pagination';
import { PropertyListDataItem, PropertyListWidget } from '../../widget/property-list/property-list';
import { RatioXXS, scrollBodyToTop } from '../../util/Util';

interface PaymentOrderListViewProps {
    dispatch: (x:any)=>void;
}
interface PaymentOrderListViewState extends BaseUnishellPageState {
    data?: PagedResponse<MerchantOrderBrief>;
    myUsername?:string;
    page: number;
    pageSize: number;

}

export class PaymentOrderListView extends Component<PaymentOrderListViewProps, PaymentOrderListViewState>{
    private errorDialog: NoticeDialog|undefined;

    constructor(props: PaymentOrderListViewProps){
        super(props);
        this.state = {page:0, pageSize:6, data:undefined, pageInitState:'PAGE_INIT'};
    }

    loadPaginationData = (page:number, pageSize: number)=>{
        this.loadData(page, false);
    }

    loadData = (page:number, initialize:boolean, callback?: (error?: string)=>void)=>{
        let whatIsFor:string = t('读订单记录');

        doAJAXCall({
            whatIsFor,
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken, myUsername)=>{
                if(initialize) await loadExternContatnsIfNecessary();
                const data = await retrievePaymentOrderList(accessToken, page, this.state.pageSize)
                if(callback){
                    callback!();
                }
                this.setState({data, myUsername, page:data.page, pageSize: data.size});
            },
            onError: (errorMessage)=>{
                if(callback){
                    callback!(errorMessage);
                }
            }
        });
    }

    renderTransferData = (myUsername:string)=>{
        if(!this.state.data!.totalElements){
            return <Fragment>
                        <div className='notice'>{t('暂无订单记录')}</div>
                    </Fragment>
        }

        let list = this.state.data!.content.map((item,idx)=>{
            const recordClass = classNames('payment-order', {odd:idx%2===1, even:idx%2===0});
            let status = item.status;
            if(getLanguageCode() !== LANG_CODE_EN){
                switch(item.status){
                    case 'CREATED': status = t('创建', 'paymentOrderList'); break;
                    case 'PAID': status = t('已付', 'paymentOrderList'); break;
                    case 'CANCELLED': status = t('取消', 'paymentOrderList'); break;
                    case 'EXPIRED': status = t('过期', 'paymentOrderList'); break;
                }
            }

            const orderProps:PropertyListDataItem[] = [
                {name: t('时间'), value: dataFormat('yyyy-MM-dd hh:mm:ss',item.createdDtm? new Date(item.createdDtm): new Date())},
                {name: t('商家'), value: item.merhantName},
                {name: t('商品'), value: item.orderDescription},
                {name: t('金额'), value: <strong style={{color:'red'}}>{(item.amount * 100 / 100).toFixed(2)}</strong>},
                {name: t('状态'), value: status},
            ]
            if(item.cancelReason){
                orderProps.push({name: t('取消订单'), value: item.cancelReason})
            }
            return <PropertyListWidget list={orderProps} showBorder={true} className='payment-order'
                                       mergeColScreenLetterRatio={RatioXXS}/>
        });
        return <Pagination data={this.state.data!} 
                        page={this.state.page} 
                        pageSize={this.state.pageSize}
                        loadData={this.loadPaginationData}
                        changePageSize={(newPageSize, newPage)=>{
                            this.loadPaginationData(newPage, newPageSize);
                        }}
                        classNames='payment-order-list'
                >
                {list}
                </Pagination>
    }

    getTitle(){
        return t('商业用户订单记录', 'paymentOrderList')
    }

    render(){
        const session = readLoginSession()!;

        return (
            <ReactReduxContext.Consumer>
            { ({store})=>{
                const state:WalletAppState = store.getState();
                const myPath = state.pagePath.currentPage;

                if(this.state.pageInitState === 'PAGE_INIT'){
                    return <Fragment>
                        {
                            renderInitUnishellPage({
                                title: this.getTitle(),
                                className: 'payment-list-page',
                                message: t('初始化...')    
                            })
                        }
                        <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                    </Fragment>
                }
                if(this.state.pageInitState === 'PAGE_LOAD_FAILED'){
                    return <Fragment>
                        {
                            renderInitErrorUnishellPage({
                                title:this.getTitle(),
                                className: 'payment-list-page',
                                errorMessage: this.state.initErrorMessage!,
                                onTryAgain: ()=>{
                                    this.init();
                                }
                            })
                        }
                        <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                    </Fragment>
                }
                
                let dataTable = null;

                if(this.state.data){
                    dataTable = this.renderTransferData(session.profile!.username);
                }

                
                const content = (
                    <Fragment>
                            {dataTable}
                            <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>                   
                    </Fragment>
                );
                return renderUnishellPage(
                    {
                        title:this.getTitle(),
                        className: 'payment-list-page',
                        content
                    }
                )
            }}
            </ReactReduxContext.Consumer>
            
        )
    }

    init(){
        const loginSession = readLoginSession();
        if(!loginSession){
            gotoLoginPage();
            return;
        }

        this.loadData(this.state.page,
                      true,
                      (error)=>{
                          if(error){
                            this.setState({pageInitState: 'PAGE_LOAD_FAILED', initErrorMessage: error})
                          }else{
                            this.setState({pageInitState: 'PAGE_LOAD_SUCCESS'})
                        }
                      });

    }

    componentDidMount(){
        this.init();
    }
    componentDidUpdate(prevProps:any, prevState:PaymentOrderListViewState){
        if(prevState.page !== this.state.page){
            scrollBodyToTop();
        }
    }

}