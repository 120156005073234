import * as React from 'react';
import {Component, Fragment} from 'react';
import {connect, batch, ReactReduxContext} from 'react-redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk'

import {WalletAppState} from '../redux-store';
import {t} from '../i18n';
import { PointsView } from '../view/points/PointsView';
import { Action } from 'redux';
import {startAjaxCall,succAjaxCall,failedAjaxCall, errorDialogAjaxCall} from '../redux-store/ajax-call/actions';
import {setPointsRecord} from '../redux-store/points'
import {retrieveUnishellPoints} from '../wallet-server-api/wallet-server-api';
import { getAjaxErrorMessage } from "..//wallet-server-api/ajax-common";
import { is401Error } from "../wallet-server-api/ajax-common";
import uuidv4 from 'uuid/v4';
import { readLoginSession } from '../local-storage/local-storage';
import { gotoLoginPage } from '../shopping/history/shopping-history';

// thunk action creator
function createRefreshPointsThunk(cb:(success:boolean)=>void ): ThunkAction<void, WalletAppState, null, Action<any>> {
    // return thunk action
    return async (dispatch, getState) => {
        const whatIsFor = t('更新数量');
        const uuid = uuidv4();
        const session = readLoginSession()!;
        if(!session){
            gotoLoginPage()
            return;
        }
        retrieveUnishellPoints(session.token!.accessToken)
        .then(newPoints=>{
            cb(true);
            batch(()=>{
                dispatch(succAjaxCall(whatIsFor, uuid));
                dispatch(setPointsRecord(newPoints));
            })
        }).catch(error=>{
            if(is401Error(error)){
                dispatch(failedAjaxCall(whatIsFor, uuid, getAjaxErrorMessage(error)));
                gotoLoginPage();
            }else{
                dispatch(errorDialogAjaxCall(whatIsFor, uuid, getAjaxErrorMessage(error)));
            }
            cb(false);
        })

        dispatch(startAjaxCall(whatIsFor, uuid));// TODO: overlay does not show up!!!
    }
}

export const Points = connect(
    (state:WalletAppState)=>{
        return {points: state.points.points }
    },
    (dispatch:ThunkDispatch<WalletAppState,null, Action<any>>)=>{
        return {
            onClickRefresh: (cb:(success:boolean)=>void)=>{ dispatch(createRefreshPointsThunk(cb))  }
        }
    }
)(PointsView);