import classnames from 'classnames';
import * as React from 'react';
import {FunctionComponent} from 'react';
import './note.scss';

interface MyProps {
    noticeType: 'success' | 'info' | 'warn' | 'error';
    message: string|JSX.Element;
    className?: string;
}

export const SimpleNote:FunctionComponent<MyProps> = (props)=>{
    return  <div className={classnames('simple-note',props.noticeType, props.className)}>{props.message}</div>
}
