// Generated by IcedCoffeeScript 108.0.11
(function () {
  var C, calc_checksum, encode_length, fit_to_size, ops_to_keyflags;
  C = require('../const');

  exports.make_time_packet = function (d) {
    var b;
    d || (d = Math.floor(Date.now() / 1000));
    b = Buffer.alloc(4);
    b.writeUInt32BE(d, 0);
    return b;
  };

  exports.calc_checksum = calc_checksum = function calc_checksum(text) {
    var i, ret, _i, _ref;

    ret = 0;

    for (i = _i = 0, _ref = text.length; 0 <= _ref ? _i < _ref : _i > _ref; i = 0 <= _ref ? ++_i : --_i) {
      ret = (ret + text.readUInt8(i)) % 65536;
    }

    return ret;
  };

  exports.encode_length = encode_length = function encode_length(l, five_byte) {
    var ret;

    if (five_byte == null) {
      five_byte = false;
    }

    ret = null;

    if (l >= 8384 || five_byte) {
      ret = Buffer.alloc(5);
      ret.writeUInt8(0xff, 0);
      ret.writeUInt32BE(l, 1);
    } else if (l < 192) {
      ret = Buffer.alloc(1);
      ret.writeUInt8(l, 0);
    } else if (l >= 192 && l < 8384) {
      ret = Buffer.alloc(2);
      ret.writeUInt16BE(l - 192 + (192 << 8), 0);
    }

    return ret;
  };

  exports.ops_to_keyflags = ops_to_keyflags = function ops_to_keyflags(ops) {
    var out;
    out = 0;

    if (ops & C.ops.encrypt) {
      out |= C.openpgp.key_flags.encrypt_comm;
    }

    if (ops & C.ops.decrypt) {
      out |= C.openpgp.key_flags.encrypt_comm;
    }

    if (ops & C.ops.verify) {
      out |= C.openpgp.key_flags.sign_data;
    }

    if (ops & C.ops.sign) {
      out |= C.openpgp.key_flags.sign_data;
    }

    return out;
  };

  exports.fit_to_size = fit_to_size = function fit_to_size(size, buf) {
    var l;
    l = size - buf.length;

    if (l === 0) {
      return buf;
    } else if (l > 0) {
      return Buffer.concat([Buffer.alloc(l), buf]);
    } else if (l < 0) {
      return buf.slice(-size);
    }
  };
}).call(this);