// Generated by IcedCoffeeScript 108.0.11
(function () {
  var ASP,
      C,
      ECDSA,
      Encryptor,
      Engine,
      K,
      KeyBlock,
      KeyFetched,
      KeyFetcher,
      KeyManager,
      KeyManagerInterface,
      Lifespan,
      Message,
      P3SKB,
      PgpEngine,
      Primary,
      RSA,
      SHA256,
      SignatureEngine,
      Subkey,
      assert_no_nulls,
      athrow,
      bufeq_secure,
      bufferify,
      decode,
      decode_strict,
      encode,
      errors,
      format_pgp_fingerprint_2,
      iced,
      katch,
      konst,
      lifespan_from_keywrapper_and_time,
      make_esc,
      opkts,
      ops_to_keyflags,
      parse,
      read_base64,
      unix_time,
      unseal,
      __iced_k,
      __iced_k_noop,
      _ref,
      _ref1,
      _ref2,
      _ref3,
      _ref4,
      __hasProp = {}.hasOwnProperty,
      __extends = function __extends(child, parent) {
    for (var key in parent) {
      if (__hasProp.call(parent, key)) child[key] = parent[key];
    }

    function ctor() {
      this.constructor = child;
    }

    ctor.prototype = parent.prototype;
    child.prototype = new ctor();
    child.__super__ = parent.prototype;
    return child;
  },
      __slice = [].slice;

  iced = require('iced-runtime');

  __iced_k = __iced_k_noop = function __iced_k_noop() {};

  RSA = require('../rsa').RSA;
  ECDSA = require('../ecc/ecdsa').ECDSA;
  SHA256 = require('../hash').SHA256;
  konst = require('../const');
  K = konst.kb;
  C = konst.openpgp;
  make_esc = require('iced-error').make_esc;
  errors = require('../errors').errors;
  _ref = require('../util'), format_pgp_fingerprint_2 = _ref.format_pgp_fingerprint_2, athrow = _ref.athrow, assert_no_nulls = _ref.assert_no_nulls, ASP = _ref.ASP, katch = _ref.katch, bufeq_secure = _ref.bufeq_secure, unix_time = _ref.unix_time, bufferify = _ref.bufferify;
  ops_to_keyflags = require('./util').ops_to_keyflags;
  _ref1 = require('../keywrapper'), Lifespan = _ref1.Lifespan, Subkey = _ref1.Subkey, Primary = _ref1.Primary;
  _ref2 = require('./armor'), Message = _ref2.Message, encode = _ref2.encode, decode = _ref2.decode, decode_strict = _ref2.decode_strict;
  parse = require('./parser').parse;
  KeyBlock = require('./processor').KeyBlock;
  opkts = require('./packet/all');
  _ref3 = require('../keybase/encode'), read_base64 = _ref3.read_base64, unseal = _ref3.unseal;
  P3SKB = require('../keybase/packet/p3skb').P3SKB;
  _ref4 = require('../keyfetch'), KeyFetcher = _ref4.KeyFetcher, KeyFetched = _ref4.KeyFetched;
  SignatureEngine = require('./sigeng').SignatureEngine;
  Encryptor = require('triplesec').Encryptor;
  KeyManagerInterface = require('../kmi').KeyManagerInterface;

  Engine = function () {
    function Engine(_arg) {
      var k, _i, _len, _ref5;

      this.primary = _arg.primary, this.subkeys = _arg.subkeys, this.userids = _arg.userids, this.key_manager = _arg.key_manager;
      this.packets = [];
      this.messages = [];

      this._allocate_key_packets();

      _ref5 = this.subkeys;

      for (_i = 0, _len = _ref5.length; _i < _len; _i++) {
        k = _ref5[_i];
        k.primary = this.primary;
      }

      this._index_keys();

      true;
    }

    Engine.prototype._index_keys = function () {
      var k, _i, _len, _ref5, _results;

      this._index = {};
      _ref5 = this._all_keys();
      _results = [];

      for (_i = 0, _len = _ref5.length; _i < _len; _i++) {
        k = _ref5[_i];

        _results.push(this._index[this.ekid(k)] = k);
      }

      return _results;
    };

    Engine.prototype.ekid = function (k) {
      return this.key(k).ekid();
    };

    Engine.prototype._allocate_key_packets = function () {
      var key, _i, _len, _ref5, _results;

      this._v_allocate_key_packet(this.primary, {
        subkey: false
      });

      _ref5 = this.subkeys;
      _results = [];

      for (_i = 0, _len = _ref5.length; _i < _len; _i++) {
        key = _ref5[_i];

        _results.push(this._v_allocate_key_packet(key, {
          subkey: true
        }));
      }

      return _results;
    };

    Engine.prototype._all_keys = function () {
      return [this.primary].concat(this.subkeys);
    };

    Engine.prototype.self_sign_primary = function (args, cb) {
      return this._v_self_sign_primary(args, cb);
    };

    Engine.prototype.get_all_key_materials = function () {
      var k;
      return [[this.key(this.primary), true]].concat(function () {
        var _i, _len, _ref5, _results;

        _ref5 = this.subkeys;
        _results = [];

        for (_i = 0, _len = _ref5.length; _i < _len; _i++) {
          k = _ref5[_i];

          _results.push([this.key(k), false]);
        }

        return _results;
      }.call(this));
    };

    Engine.prototype.sign_subkeys = function (_arg, cb) {
      var asp, err, subkey, time, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      time = _arg.time, asp = _arg.asp;
      err = null;
      (function (_this) {
        return function (__iced_k) {
          var _i, _len, _ref5, _results, _while2;

          _ref5 = _this.subkeys;
          _len = _ref5.length;
          _i = 0;

          _while2 = function _while(__iced_k) {
            var _break, _continue, _next;

            _break = __iced_k;

            _continue = function _continue() {
              return iced.trampoline(function () {
                ++_i;
                return _while2(__iced_k);
              });
            };

            _next = _continue;

            if (!(_i < _len)) {
              return _break();
            } else {
              subkey = _ref5[_i];

              if (err == null) {
                (function (__iced_k) {
                  __iced_deferrals = new iced.Deferrals(__iced_k, {
                    parent: ___iced_passed_deferral,
                    filename: "/Users/max/src/keybase/kbpgp/src/openpgp/keymanager.iced",
                    funcname: "Engine.sign_subkeys"
                  });

                  _this._v_sign_subkey({
                    asp: asp,
                    time: time,
                    subkey: subkey
                  }, __iced_deferrals.defer({
                    assign_fn: function () {
                      return function () {
                        return err = arguments[0];
                      };
                    }(),
                    lineno: 76
                  }));

                  __iced_deferrals._fulfill();
                })(_next);
              } else {
                return _continue();
              }
            }
          };

          _while2(__iced_k);
        };
      })(this)(function (_this) {
        return function () {
          return cb(err);
        };
      }(this));
    };

    Engine.prototype.get_subkey_materials = function () {
      var k, _i, _len, _ref5, _results;

      _ref5 = this.subkeys;
      _results = [];

      for (_i = 0, _len = _ref5.length; _i < _len; _i++) {
        k = _ref5[_i];

        _results.push(this.key(k));
      }

      return _results;
    };

    Engine.prototype.is_locked = function () {
      var i, k, _i, _len, _ref5;

      _ref5 = this._all_keys();

      for (i = _i = 0, _len = _ref5.length; _i < _len; i = ++_i) {
        k = _ref5[i];

        if (this.key(k).is_locked()) {
          return true;
        }
      }

      return false;
    };

    Engine.prototype.has_private = function () {
      var k, _i, _len, _ref5;

      _ref5 = this._all_keys();

      for (_i = 0, _len = _ref5.length; _i < _len; _i++) {
        k = _ref5[_i];

        if (this.key(k).has_private()) {
          return true;
        }
      }

      return false;
    };

    Engine.prototype.sign = function (_arg, cb) {
      var asp, err, time, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      asp = _arg.asp, time = _arg.time;
      (function (_this) {
        return function (__iced_k) {
          __iced_deferrals = new iced.Deferrals(__iced_k, {
            parent: ___iced_passed_deferral,
            filename: "/Users/max/src/keybase/kbpgp/src/openpgp/keymanager.iced",
            funcname: "Engine.sign"
          });

          _this.self_sign_primary({
            asp: asp,
            time: time
          }, __iced_deferrals.defer({
            assign_fn: function () {
              return function () {
                return err = arguments[0];
              };
            }(),
            lineno: 100
          }));

          __iced_deferrals._fulfill();
        };
      })(this)(function (_this) {
        return function () {
          (function (__iced_k) {
            if (typeof err === "undefined" || err === null) {
              (function (__iced_k) {
                __iced_deferrals = new iced.Deferrals(__iced_k, {
                  parent: ___iced_passed_deferral,
                  filename: "/Users/max/src/keybase/kbpgp/src/openpgp/keymanager.iced",
                  funcname: "Engine.sign"
                });

                _this.sign_subkeys({
                  asp: asp,
                  time: time
                }, __iced_deferrals.defer({
                  assign_fn: function () {
                    return function () {
                      return err = arguments[0];
                    };
                  }(),
                  lineno: 101
                }));

                __iced_deferrals._fulfill();
              })(__iced_k);
            } else {
              return __iced_k();
            }
          })(function () {
            return cb(err);
          });
        };
      }(this));
    };

    Engine.prototype.check_eq = function (eng2) {
      var err, i, key, _i, _len, _ref5;

      err = null;

      if (!this.primary.key.eq(eng2.primary.key)) {
        err = new Error("Primary keys don't match");
      } else if (this.subkeys.length !== eng2.subkeys.length) {
        err = new Error("different # of subkeys");
      } else {
        _ref5 = this.subkeys;

        for (i = _i = 0, _len = _ref5.length; _i < _len; i = ++_i) {
          key = _ref5[i];

          if (this.err == null) {
            if (!key.key.eq(eng2.subkeys[i].key)) {
              err = new Error("subkey " + i + " doesn't match");
            }
          }
        }
      }

      return err;
    };

    Engine.prototype.merge_private = function (eng2) {
      var err, i, k, _i, _len, _ref5;

      err = this._merge_private_primary(eng2);

      if (err == null) {
        _ref5 = eng2.subkeys;

        for (i = _i = 0, _len = _ref5.length; _i < _len; i = ++_i) {
          k = _ref5[i];

          if ((err = this._merge_private_subkey(k, i)) != null) {
            break;
          }
        }
      }

      return err;
    };

    Engine.prototype._merge_private_primary = function (eng2) {
      var err;
      err = !this.key(eng2.primary).has_secret_key_material() ? null : this._merge_1_private(this.primary, eng2.primary) ? null : new Error("primary public key doesn't match private key");
      return err;
    };

    Engine.prototype._merge_private_subkey = function (k2, i) {
      var ekid, err, k;
      err = !this.key(k2).has_secret_key_material() ? null : (ekid = this.ekid(k2)) == null ? new Error("Subkey " + i + " is malformed") : (k = this._index[ekid]) == null ? new Error("Subkey " + i + " wasn't found in public key") : this._merge_1_private(k, k2) ? null : new Error("subkey " + i + " can't be merged");
      return err;
    };

    Engine.prototype.unlock_keys = function (_arg, cb) {
      var asp, esc, i, passphrase, subkey, tsenc, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      asp = _arg.asp, passphrase = _arg.passphrase, tsenc = _arg.tsenc;
      esc = make_esc(cb, "Engine::unlock_keys");
      (function (_this) {
        return function (__iced_k) {
          __iced_deferrals = new iced.Deferrals(__iced_k, {
            parent: ___iced_passed_deferral,
            filename: "/Users/max/src/keybase/kbpgp/src/openpgp/keymanager.iced",
            funcname: "Engine.unlock_keys"
          });

          _this.key(_this.primary).unlock({
            asp: asp,
            tsenc: tsenc,
            passphrase: passphrase
          }, esc(__iced_deferrals.defer({
            lineno: 149
          })));

          __iced_deferrals._fulfill();
        };
      })(this)(function (_this) {
        return function () {
          (function (__iced_k) {
            var _i, _len, _ref5, _results, _while3;

            _ref5 = _this.subkeys;
            _len = _ref5.length;
            i = 0;

            _while3 = function _while(__iced_k) {
              var _break, _continue, _next;

              _break = __iced_k;

              _continue = function _continue() {
                return iced.trampoline(function () {
                  ++i;
                  return _while3(__iced_k);
                });
              };

              _next = _continue;

              if (!(i < _len)) {
                return _break();
              } else {
                subkey = _ref5[i];

                if (_this.key(subkey).has_private()) {
                  (function (__iced_k) {
                    __iced_deferrals = new iced.Deferrals(__iced_k, {
                      parent: ___iced_passed_deferral,
                      filename: "/Users/max/src/keybase/kbpgp/src/openpgp/keymanager.iced",
                      funcname: "Engine.unlock_keys"
                    });

                    _this.key(subkey).unlock({
                      asp: asp,
                      tsenc: tsenc,
                      passphrase: passphrase
                    }, esc(__iced_deferrals.defer({
                      lineno: 151
                    })));

                    __iced_deferrals._fulfill();
                  })(_next);
                } else {
                  return _continue();
                }
              }
            };

            _while3(__iced_k);
          })(function () {
            return cb(null);
          });
        };
      }(this));
    };

    Engine.prototype.export_keys_to_keyring = function (km) {
      var k, x;

      x = function (_this) {
        return function (key_wrapper, is_primary) {
          return {
            km: km,
            is_primary: is_primary,
            key_wrapper: key_wrapper,
            key_material: _this.key(key_wrapper),
            key: _this.key(key_wrapper).key
          };
        };
      }(this);

      return [x(this.primary, true)].concat(function () {
        var _i, _len, _ref5, _results;

        _ref5 = this.subkeys;
        _results = [];

        for (_i = 0, _len = _ref5.length; _i < _len; _i++) {
          k = _ref5[_i];

          _results.push(x(k, false));
        }

        return _results;
      }.call(this));
    };

    Engine.prototype._merge_1_private = function (k1, k2) {
      if (bufeq_secure(this.ekid(k1), this.ekid(k2))) {
        this.key(k1).merge_private(this.key(k2));
        return true;
      } else {
        return false;
      }
    };

    Engine.prototype.merge_subkey_omitting_revokes = function (k) {
      var ekid, kw;
      ekid = k.ekid();

      if ((kw = this._index[ekid]) != null) {
        return kw.overwrite_with_omitting_revokes(k);
      } else {
        this._index[ekid] = k;
        return this.subkeys.push(k);
      }
    };

    Engine.prototype.merge_public_omitting_revokes = function (pgpeng2) {
      this.primary.overwrite_with_omitting_revokes(pgpeng2.primary);
      return this.merge_all_subkeys_omitting_revokes(pgpeng2);
    };

    Engine.prototype.merge_all_subkeys_omitting_revokes = function (pgpeng2) {
      var subkey, _i, _len, _ref5, _results;

      _ref5 = pgpeng2.subkeys;
      _results = [];

      for (_i = 0, _len = _ref5.length; _i < _len; _i++) {
        subkey = _ref5[_i];

        _results.push(this.merge_subkey_omitting_revokes(subkey));
      }

      return _results;
    };

    Engine.prototype.check_not_expired = function (_arg) {
      var err, now, subkey_material;
      subkey_material = _arg.subkey_material, now = _arg.now;
      now || (now = unix_time());
      err = this.key(this.primary).check_not_expired({
        now: now
      });

      if (err == null) {
        err = subkey_material.check_not_expired({
          now: now
        });
      }

      return err;
    };

    return Engine;
  }();

  lifespan_from_keywrapper_and_time = function lifespan_from_keywrapper_and_time(_arg) {
    var key_wrapper, ret, time;
    key_wrapper = _arg.key_wrapper, time = _arg.time;
    ret = key_wrapper.lifespan;

    if (time != null) {
      ret = ret.copy();
      ret.generated = time;
    }

    return ret;
  };

  PgpEngine = function (_super) {
    __extends(PgpEngine, _super);

    function PgpEngine(_arg) {
      var key_manager, primary, subkeys, userids;
      primary = _arg.primary, subkeys = _arg.subkeys, userids = _arg.userids, this.user_attributes = _arg.user_attributes, key_manager = _arg.key_manager;

      PgpEngine.__super__.constructor.call(this, {
        primary: primary,
        subkeys: subkeys,
        userids: userids,
        key_manager: key_manager
      });
    }

    PgpEngine.prototype.key = function (k) {
      return k._pgp;
    };

    PgpEngine.prototype._v_allocate_key_packet = function (key, opts) {
      if (key._pgp == null) {
        return key._pgp = new opkts.KeyMaterial({
          key: key.key,
          timestamp: key.lifespan.generated,
          flags: key.flags,
          opts: opts
        });
      }
    };

    PgpEngine.prototype._v_self_sign_primary = function (_arg, cb) {
      var asp, err, lifespan, raw_payload, sigs, time, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      time = _arg.time, asp = _arg.asp, raw_payload = _arg.raw_payload;
      lifespan = lifespan_from_keywrapper_and_time({
        key_wrapper: this.primary,
        time: time
      });
      (function (_this) {
        return function (__iced_k) {
          __iced_deferrals = new iced.Deferrals(__iced_k, {
            parent: ___iced_passed_deferral,
            filename: "/Users/max/src/keybase/kbpgp/src/openpgp/keymanager.iced",
            funcname: "PgpEngine._v_self_sign_primary"
          });

          _this.key(_this.primary).self_sign_key({
            lifespan: lifespan,
            userids: _this.userids,
            raw_payload: raw_payload
          }, __iced_deferrals.defer({
            assign_fn: function () {
              return function () {
                err = arguments[0];
                return sigs = arguments[1];
              };
            }(),
            lineno: 241
          }));

          __iced_deferrals._fulfill();
        };
      })(this)(function (_this) {
        return function () {
          return cb(err, sigs);
        };
      }(this));
    };

    PgpEngine.prototype._v_sign_subkey = function (_arg, cb) {
      var asp, err, lifespan, subkey, time, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      asp = _arg.asp, subkey = _arg.subkey, time = _arg.time;
      lifespan = lifespan_from_keywrapper_and_time({
        key_wrapper: subkey,
        time: time
      });
      (function (_this) {
        return function (__iced_k) {
          __iced_deferrals = new iced.Deferrals(__iced_k, {
            parent: ___iced_passed_deferral,
            filename: "/Users/max/src/keybase/kbpgp/src/openpgp/keymanager.iced",
            funcname: "PgpEngine._v_sign_subkey"
          });

          _this.key(_this.primary).sign_subkey({
            subkey: _this.key(subkey),
            lifespan: lifespan
          }, __iced_deferrals.defer({
            assign_fn: function () {
              return function () {
                return err = arguments[0];
              };
            }(),
            lineno: 248
          }));

          __iced_deferrals._fulfill();
        };
      })(this)(function (_this) {
        return function () {
          return cb(err);
        };
      }(this));
    };

    PgpEngine.prototype.clear_psc = function () {
      var s, u, _i, _j, _len, _len1, _ref5, _ref6, _results;

      this.key(this.primary).clear_psc();
      _ref5 = this.userids;

      for (_i = 0, _len = _ref5.length; _i < _len; _i++) {
        u = _ref5[_i];
        u.clear_psc();
      }

      _ref6 = this.subkeys;
      _results = [];

      for (_j = 0, _len1 = _ref6.length; _j < _len1; _j++) {
        s = _ref6[_j];

        _results.push(this.key(s).clear_psc());
      }

      return _results;
    };

    PgpEngine.prototype.set_passphrase = function (pp) {
      var k, _i, _len, _ref5, _results;

      this.primary.passphrase = pp;
      _ref5 = this.subkeys;
      _results = [];

      for (_i = 0, _len = _ref5.length; _i < _len; _i++) {
        k = _ref5[_i];

        _results.push(k.passphrase = pp);
      }

      return _results;
    };

    PgpEngine.prototype._export_keys_to_binary = function (opts) {
      var packets, subkey, userid, _i, _j, _len, _len1, _ref5, _ref6;

      packets = [this.key(this.primary).export_framed(opts)];
      _ref5 = this.userids;

      for (_i = 0, _len = _ref5.length; _i < _len; _i++) {
        userid = _ref5[_i];
        packets.push(userid.write(), userid.get_framed_signature_output());
      }

      opts.subkey = true;
      _ref6 = this.subkeys;

      for (_j = 0, _len1 = _ref6.length; _j < _len1; _j++) {
        subkey = _ref6[_j];
        packets.push(this.key(subkey).export_framed(opts), this.key(subkey).get_subkey_binding_signature_output());
      }

      assert_no_nulls(packets);
      return Buffer.concat(packets);
    };

    PgpEngine.prototype.export_keys = function (opts) {
      var msg, mt, type;
      mt = C.message_types;
      type = opts["private"] ? mt.private_key : mt.public_key;
      msg = this._export_keys_to_binary(opts);
      return encode(type, msg);
    };

    PgpEngine.prototype.export_to_p3skb = function () {
      var priv_clear, pub;
      pub = this._export_keys_to_binary({
        "private": false
      });
      priv_clear = this._export_keys_to_binary({
        "private": true
      });
      return new P3SKB({
        pub: pub,
        priv_clear: priv_clear
      });
    };

    PgpEngine.prototype.find_key = function (key_id) {
      var k, _i, _len, _ref5;

      _ref5 = this._all_keys();

      for (_i = 0, _len = _ref5.length; _i < _len; _i++) {
        k = _ref5[_i];

        if (bufeq_secure(this.key(k).get_key_id(), key_id)) {
          return k;
        }
      }

      return null;
    };

    PgpEngine.prototype.find_key_material = function (key_id) {
      var key;
      key = this.find_key(key_id);

      if (key != null) {
        return this.key(key);
      } else {
        return null;
      }
    };

    PgpEngine.prototype.get_key_id = function () {
      return this.key(this.primary).get_key_id();
    };

    PgpEngine.prototype.get_short_key_id = function () {
      return this.key(this.primary).get_short_key_id();
    };

    PgpEngine.prototype.get_fingerprint = function () {
      return this.key(this.primary).get_fingerprint();
    };

    PgpEngine.prototype.get_ekid = function () {
      return this.key(this.primary).ekid();
    };

    PgpEngine.prototype.get_all_key_ids = function () {
      var k, _i, _len, _ref5, _results;

      _ref5 = this._all_keys();
      _results = [];

      for (_i = 0, _len = _ref5.length; _i < _len; _i++) {
        k = _ref5[_i];

        _results.push(this.key(k).get_key_id());
      }

      return _results;
    };

    PgpEngine.prototype.get_designated_revocations = function () {
      return this.key(this.primary).get_designated_revocations();
    };

    PgpEngine.prototype.validity_check = function (cb) {
      var err, k, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      err = null;
      (function (_this) {
        return function (__iced_k) {
          var _i, _len, _ref5, _results, _while4;

          _ref5 = _this._all_keys();
          _len = _ref5.length;
          _i = 0;

          _while4 = function _while(__iced_k) {
            var _break, _continue, _next;

            _break = __iced_k;

            _continue = function _continue() {
              return iced.trampoline(function () {
                ++_i;
                return _while4(__iced_k);
              });
            };

            _next = _continue;

            if (!(_i < _len)) {
              return _break();
            } else {
              k = _ref5[_i];

              (function (__iced_k) {
                __iced_deferrals = new iced.Deferrals(__iced_k, {
                  parent: ___iced_passed_deferral,
                  filename: "/Users/max/src/keybase/kbpgp/src/openpgp/keymanager.iced",
                  funcname: "PgpEngine.validity_check"
                });

                _this.key(k).validity_check(__iced_deferrals.defer({
                  assign_fn: function () {
                    return function () {
                      return err = arguments[0];
                    };
                  }(),
                  lineno: 328
                }));

                __iced_deferrals._fulfill();
              })(function () {
                (function (__iced_k) {
                  if (err != null) {
                    (function (__iced_k) {
                      _break();
                    })(__iced_k);
                  } else {
                    return __iced_k();
                  }
                })(_next);
              });
            }
          };

          _while4(__iced_k);
        };
      })(this)(function (_this) {
        return function () {
          return cb(err);
        };
      }(this));
    };

    PgpEngine.prototype.find_best_key = function (flags, need_priv) {
      var best, check, k, _i, _len, _ref5;

      if (need_priv == null) {
        need_priv = false;
      }

      best = null;

      check = function (_this) {
        return function (k) {
          var km, ok1, ok2, ok3;
          km = _this.key(k);
          ok1 = km.fulfills_flags(flags) || (k.flags & flags) === flags;
          ok2 = !need_priv || km.has_private();
          ok3 = !km.is_revoked();
          return ok1 && ok2 && ok3;
        };
      }(this);

      _ref5 = this.subkeys;

      for (_i = 0, _len = _ref5.length; _i < _len; _i++) {
        k = _ref5[_i];

        if (check(k)) {
          if (best == null) {
            best = k;
          } else if (this.key(k).is_preferable_to(this.key(best))) {
            best = k;
          }
        }
      }

      if (best == null && check(this.primary)) {
        best = this.primary;
      }

      return best != null ? this.key(best) : null;
    };

    PgpEngine.prototype.fetch = function (key_ids, op_mask, cb) {
      var err, flags, i, key, kid, ret, ret_i, _i, _len, _ref5, _ref6;

      flags = ops_to_keyflags(op_mask);
      err = key = ret = null;
      key = null;
      ret_i = null;

      for (i = _i = 0, _len = key_ids.length; _i < _len; i = ++_i) {
        kid = key_ids[i];

        if (!(key == null)) {
          continue;
        }

        key = this.find_key(kid);

        if (key != null) {
          ret_i = i;
        }
      }

      if (key == null) {
        err = new Error("No keys match the given key IDs");
      } else if (this.key(key).is_revoked() || this.is_revoked()) {
        err = new errors.RevokedKeyError();
        err.km = this;
      } else if (!this.key(key).fulfills_flags(flags)) {
        err = new Error("We don't have a key for the requested PGP ops (flags = " + flags + ")");
      } else if (!((_ref5 = this.key(key)) != null ? (_ref6 = _ref5.key) != null ? _ref6.can_perform(op_mask) : void 0 : void 0)) {
        err = new Error("can't peform the operation -- maybe no secret key material (op_mask=" + op_mask + ")");
      } else {
        ret = this.key(key);
      }

      return cb(err, this.key_manager, ret_i);
    };

    PgpEngine.prototype.is_revoked = function () {
      return this.key(this.primary).is_revoked();
    };

    return PgpEngine;
  }(Engine);

  KeyManager = function (_super) {
    __extends(KeyManager, _super);

    function KeyManager(_arg) {
      var signed;
      this.primary = _arg.primary, this.subkeys = _arg.subkeys, this.userids = _arg.userids, this.armored_pgp_public = _arg.armored_pgp_public, this.armored_pgp_private = _arg.armored_pgp_private, this.user_attributes = _arg.user_attributes, signed = _arg.signed;
      this.pgp = new PgpEngine({
        primary: this.primary,
        subkeys: this.subkeys,
        userids: this.userids,
        user_attributes: this.user_attributes,
        key_manager: this
      });
      this.engines = [this.pgp];
      this._signed = signed != null ? signed : false;
      this.p3skb = null;
    }

    KeyManager.generate = function (_arg, cb) {
      var F, KEY_FLAGS_PRIMARY, KEY_FLAGS_STD, asp, bundle, curve_name, ecc, err, esc, expire_in, flags, gen, generated, i, nbits, nsubs, primary, primary_flags, s, sub_flags, subkey, subkeys, subkeys_out, u, userid, userids, ___iced_passed_deferral, __iced_deferrals, __iced_k, _i, _len;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      asp = _arg.asp, userid = _arg.userid, userids = _arg.userids, primary = _arg.primary, subkeys = _arg.subkeys, ecc = _arg.ecc, sub_flags = _arg.sub_flags, nsubs = _arg.nsubs, primary_flags = _arg.primary_flags, nbits = _arg.nbits, expire_in = _arg.expire_in, generated = _arg.generated, curve_name = _arg.curve_name;
      asp = ASP.make(asp);
      F = C.key_flags;
      KEY_FLAGS_STD = F.sign_data | F.encrypt_comm | F.encrypt_storage | F.auth;
      KEY_FLAGS_PRIMARY = KEY_FLAGS_STD | F.certify_keys;
      primary || (primary = {});
      primary.flags || (primary.flags = primary_flags || KEY_FLAGS_PRIMARY);
      primary.expire_in || (primary.expire_in = (expire_in != null ? expire_in.primary : void 0) || K.key_defaults.primary.expire_in);
      primary.algo || (primary.algo = ecc ? ECDSA : RSA);
      primary.nbits || (primary.nbits = nbits || K.key_defaults.primary.nbits[primary.algo.klass_name]);

      if (curve_name) {
        primary.curve_name = curve_name;
      }

      if (nsubs != null && sub_flags == null) {
        sub_flags = function () {
          var _i, _results;

          _results = [];

          for (i = _i = 0; 0 <= nsubs ? _i < nsubs : _i > nsubs; i = 0 <= nsubs ? ++_i : --_i) {
            _results.push(KEY_FLAGS_STD);
          }

          return _results;
        }();
      }

      subkeys || (subkeys = function () {
        var _i, _len, _results;

        _results = [];

        for (_i = 0, _len = sub_flags.length; _i < _len; _i++) {
          flags = sub_flags[_i];

          _results.push({
            flags: flags
          });
        }

        return _results;
      }());

      for (_i = 0, _len = subkeys.length; _i < _len; _i++) {
        subkey = subkeys[_i];
        subkey.expire_in || (subkey.expire_in = (expire_in != null ? expire_in.subkey : void 0) || K.key_defaults.sub.expire_in);
        subkey.flags || (subkey.flags = KEY_FLAGS_STD);
        subkey.algo || (subkey.algo = primary.algo.subkey_algo(subkey.flags));
        subkey.nbits || (subkey.nbits = nbits || K.key_defaults.sub.nbits[subkey.algo.klass_name]);
      }

      generated || (generated = unix_time());
      esc = make_esc(cb, "KeyManager::generate");

      if (userid != null) {
        userids = [userid];
      }

      (function (_this) {
        return function (__iced_k) {
          if (userids != null && Array.isArray(userids)) {
            return __iced_k(userids = function () {
              var _j, _len1, _results;

              _results = [];

              for (_j = 0, _len1 = userids.length; _j < _len1; _j++) {
                u = userids[_j];

                _results.push(new opkts.UserID(u));
              }

              return _results;
            }());
          } else {
            err = new Error("Need either 'userid' or 'userids' specified as an array");

            (function (__iced_k) {
              __iced_deferrals = new iced.Deferrals(__iced_k, {
                parent: ___iced_passed_deferral,
                filename: "/Users/max/src/keybase/kbpgp/src/openpgp/keymanager.iced",
                funcname: "KeyManager.generate"
              });
              athrow(err, esc(__iced_deferrals.defer({
                lineno: 474
              })));

              __iced_deferrals._fulfill();
            })(__iced_k);
          }
        };
      })(this)(function (_this) {
        return function () {
          gen = function gen(_arg1, cb) {
            var err, key, klass, lifespan, my_generated, params, primary, section, wrapper, ___iced_passed_deferral1, __iced_deferrals, __iced_k;

            __iced_k = __iced_k_noop;
            ___iced_passed_deferral1 = iced.findDeferral(arguments);
            klass = _arg1.klass, section = _arg1.section, params = _arg1.params, primary = _arg1.primary;
            asp.section(section);
            (function (_this) {
              return function (__iced_k) {
                __iced_deferrals = new iced.Deferrals(__iced_k, {
                  parent: ___iced_passed_deferral1,
                  filename: "/Users/max/src/keybase/kbpgp/src/openpgp/keymanager.iced"
                });
                params.algo.generate({
                  asp: asp,
                  nbits: params.nbits,
                  curve_name: params.curve_name
                }, __iced_deferrals.defer({
                  assign_fn: function () {
                    return function () {
                      err = arguments[0];
                      return key = arguments[1];
                    };
                  }(),
                  lineno: 478
                }));

                __iced_deferrals._fulfill();
              };
            })(this)(function (_this) {
              return function () {
                if (typeof err === "undefined" || err === null) {
                  my_generated = params.generated || generated;
                  lifespan = new Lifespan({
                    generated: my_generated,
                    expire_in: params.expire_in
                  });
                  wrapper = new klass({
                    key: key,
                    lifespan: lifespan,
                    flags: params.flags,
                    primary: primary
                  });
                }

                return cb(err, wrapper);
              };
            }(this));
          };

          (function (__iced_k) {
            __iced_deferrals = new iced.Deferrals(__iced_k, {
              parent: ___iced_passed_deferral,
              filename: "/Users/max/src/keybase/kbpgp/src/openpgp/keymanager.iced",
              funcname: "KeyManager.generate"
            });
            gen({
              klass: Primary,
              section: "primary",
              params: primary
            }, esc(__iced_deferrals.defer({
              assign_fn: function () {
                return function () {
                  return primary = arguments[0];
                };
              }(),
              lineno: 485
            })));

            __iced_deferrals._fulfill();
          })(function () {
            subkeys_out = [];

            (function (__iced_k) {
              var _j, _len1, _ref5, _results, _while5;

              _ref5 = subkeys;
              _len1 = _ref5.length;
              i = 0;

              _while5 = function _while(__iced_k) {
                var _break, _continue, _next;

                _break = __iced_k;

                _continue = function _continue() {
                  return iced.trampoline(function () {
                    ++i;
                    return _while5(__iced_k);
                  });
                };

                _next = _continue;

                if (!(i < _len1)) {
                  return _break();
                } else {
                  subkey = _ref5[i];

                  (function (__iced_k) {
                    __iced_deferrals = new iced.Deferrals(__iced_k, {
                      parent: ___iced_passed_deferral,
                      filename: "/Users/max/src/keybase/kbpgp/src/openpgp/keymanager.iced",
                      funcname: "KeyManager.generate"
                    });
                    gen({
                      klass: Subkey,
                      section: "subkey " + (i + 1),
                      params: subkey,
                      primary: primary
                    }, esc(__iced_deferrals.defer({
                      assign_fn: function () {
                        return function () {
                          return s = arguments[0];
                        };
                      }(),
                      lineno: 488
                    })));

                    __iced_deferrals._fulfill();
                  })(function () {
                    return _next(subkeys_out.push(s));
                  });
                }
              };

              _while5(__iced_k);
            })(function () {
              bundle = new KeyManager({
                primary: primary,
                subkeys: subkeys_out,
                userids: userids
              });
              return cb(null, bundle);
            });
          });
        };
      }(this));
    };

    KeyManager.generate_rsa = function (_arg, cb) {
      var F, asp, primary, subkeys, userid, userids;
      asp = _arg.asp, userid = _arg.userid, userids = _arg.userids;
      F = C.key_flags;
      primary = {
        flags: F.certify_keys,
        nbits: 4096
      };
      subkeys = [{
        flags: F.encrypt_storage | F.encrypt_comm,
        nbits: 2048
      }, {
        flags: F.sign_data | F.auth,
        nbits: 2048
      }];
      return KeyManager.generate({
        asp: asp,
        userid: userid,
        userids: userids,
        primary: primary,
        subkeys: subkeys
      }, cb);
    };

    KeyManager.generate_ecc = function (_arg, cb) {
      var F, asp, generated, primary, subkeys, userid, userids;
      asp = _arg.asp, userid = _arg.userid, userids = _arg.userids, generated = _arg.generated;
      F = C.key_flags;
      primary = {
        flags: F.certify_keys,
        nbits: 384,
        algo: ECDSA
      };
      subkeys = [{
        flags: F.encrypt_storage | F.encrypt_comm,
        nbits: 256
      }, {
        flags: F.sign_data | F.auth,
        nbits: 256
      }];
      return KeyManager.generate({
        asp: asp,
        userid: userid,
        userids: userids,
        primary: primary,
        subkeys: subkeys,
        generated: generated
      }, cb);
    };

    KeyManager.prototype.set_enc = function (e) {
      return this.tsenc = e;
    };

    KeyManager.import_from_armored_pgp = function (_arg, cb) {
      var armored, asp, binary, decode_func, err, msg, opts, packets, raw, ret, warnings, ___iced_passed_deferral, __iced_deferrals, __iced_k, _ref5, _ref6;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      armored = _arg.armored, raw = _arg.raw, binary = _arg.binary, asp = _arg.asp, opts = _arg.opts;
      msg = binary;
      err = null;

      if (msg == null) {
        raw || (raw = armored);
        asp = ASP.make(asp);
        warnings = null;
        ret = null;
        decode_func = (opts != null ? opts.decode_strict : void 0) ? decode_strict : decode;
        _ref5 = decode_func(raw), err = _ref5[0], msg = _ref5[1];

        if (err == null) {
          if (!((_ref6 = msg.type) === C.message_types.public_key || _ref6 === C.message_types.private_key)) {
            err = new Error("Wanted a public or private key; got: " + msg.type);
          }
        }
      }

      (function (_this) {
        return function (__iced_k) {
          if (err == null) {
            (function (__iced_k) {
              __iced_deferrals = new iced.Deferrals(__iced_k, {
                parent: ___iced_passed_deferral,
                filename: "/Users/max/src/keybase/kbpgp/src/openpgp/keymanager.iced",
                funcname: "KeyManager.import_from_armored_pgp"
              });
              KeyManager.import_from_pgp_message({
                msg: msg,
                asp: asp,
                opts: opts
              }, __iced_deferrals.defer({
                assign_fn: function () {
                  return function () {
                    err = arguments[0];
                    ret = arguments[1];
                    warnings = arguments[2];
                    return packets = arguments[3];
                  };
                }(),
                lineno: 567
              }));

              __iced_deferrals._fulfill();
            })(__iced_k);
          } else {
            return __iced_k();
          }
        };
      })(this)(function (_this) {
        return function () {
          (function (__iced_k) {
            if (!(err != null)) {
              (function (__iced_k) {
                __iced_deferrals = new iced.Deferrals(__iced_k, {
                  parent: ___iced_passed_deferral,
                  filename: "/Users/max/src/keybase/kbpgp/src/openpgp/keymanager.iced",
                  funcname: "KeyManager.import_from_armored_pgp"
                });
                ret.simple_unlock({}, __iced_deferrals.defer({
                  assign_fn: function () {
                    return function () {
                      return err = arguments[0];
                    };
                  }(),
                  lineno: 573
                }));

                __iced_deferrals._fulfill();
              })(__iced_k);
            } else {
              return __iced_k();
            }
          })(function () {
            return cb(err, ret, warnings, packets);
          });
        };
      }(this));
    };

    KeyManager.prototype.simple_unlock = function (opts, cb) {
      var err, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      err = null;
      (function (_this) {
        return function (__iced_k) {
          if (_this.has_pgp_private() && !_this.is_pgp_locked()) {
            (function (__iced_k) {
              __iced_deferrals = new iced.Deferrals(__iced_k, {
                parent: ___iced_passed_deferral,
                filename: "/Users/max/src/keybase/kbpgp/src/openpgp/keymanager.iced",
                funcname: "KeyManager.simple_unlock"
              });

              _this.unlock_pgp({}, __iced_deferrals.defer({
                assign_fn: function () {
                  return function () {
                    return err = arguments[0];
                  };
                }(),
                lineno: 585
              }));

              __iced_deferrals._fulfill();
            })(__iced_k);
          } else {
            return __iced_k();
          }
        };
      })(this)(function (_this) {
        return function () {
          return cb(err);
        };
      }(this));
    };

    KeyManager.import_from_p3skb = function (_arg, cb) {
      var armored, asp, err, km, msg, no_check_keys, now, opts, p3skb, raw, time_travel, warnings, ___iced_passed_deferral, __iced_deferrals, __iced_k, _ref5;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      raw = _arg.raw, armored = _arg.armored, asp = _arg.asp, no_check_keys = _arg.no_check_keys, time_travel = _arg.time_travel, now = _arg.now;
      armored || (armored = raw);
      asp = ASP.make(asp);
      km = null;
      warnings = null;
      _ref5 = katch(function () {
        return P3SKB.alloc(unseal(read_base64(armored)));
      }), err = _ref5[0], p3skb = _ref5[1];
      (function (_this) {
        return function (__iced_k) {
          if (err == null) {
            msg = new Message({
              body: p3skb.pub,
              type: C.message_types.public_key
            });
            opts = {
              no_check_keys: no_check_keys,
              time_travel: time_travel,
              now: now
            };

            (function (__iced_k) {
              __iced_deferrals = new iced.Deferrals(__iced_k, {
                parent: ___iced_passed_deferral,
                filename: "/Users/max/src/keybase/kbpgp/src/openpgp/keymanager.iced",
                funcname: "KeyManager.import_from_p3skb"
              });
              KeyManager.import_from_pgp_message({
                msg: msg,
                asp: asp,
                opts: opts
              }, __iced_deferrals.defer({
                assign_fn: function () {
                  return function () {
                    err = arguments[0];
                    km = arguments[1];
                    return warnings = arguments[2];
                  };
                }(),
                lineno: 601
              }));

              __iced_deferrals._fulfill();
            })(function () {
              return __iced_k(km != null ? km.p3skb = p3skb : void 0);
            });
          } else {
            return __iced_k();
          }
        };
      })(this)(function (_this) {
        return function () {
          return cb(err, km, warnings);
        };
      }(this));
    };

    KeyManager.prototype.unlock_p3skb = function (_arg, cb) {
      var asp, err, km, msg, no_check_keys, now, opts, passphrase, passphrase_generation, time_travel, tsenc, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      asp = _arg.asp, tsenc = _arg.tsenc, passphrase = _arg.passphrase, passphrase_generation = _arg.passphrase_generation, no_check_keys = _arg.no_check_keys, time_travel = _arg.time_travel, now = _arg.now;
      asp = ASP.make(asp);

      if (tsenc == null && passphrase != null) {
        tsenc = new Encryptor({
          key: bufferify(passphrase)
        });
      }

      (function (_this) {
        return function (__iced_k) {
          __iced_deferrals = new iced.Deferrals(__iced_k, {
            parent: ___iced_passed_deferral,
            filename: "/Users/max/src/keybase/kbpgp/src/openpgp/keymanager.iced",
            funcname: "KeyManager.unlock_p3skb"
          });

          _this.p3skb.unlock({
            tsenc: tsenc,
            asp: asp,
            passphrase_generation: passphrase_generation
          }, __iced_deferrals.defer({
            assign_fn: function () {
              return function () {
                return err = arguments[0];
              };
            }(),
            lineno: 611
          }));

          __iced_deferrals._fulfill();
        };
      })(this)(function (_this) {
        return function () {
          (function (__iced_k) {
            if (err == null) {
              msg = new Message({
                body: _this.p3skb.priv.data,
                type: C.message_types.private_key
              });
              opts = {
                no_check_keys: no_check_keys,
                time_travel: time_travel,
                now: now
              };

              (function (__iced_k) {
                __iced_deferrals = new iced.Deferrals(__iced_k, {
                  parent: ___iced_passed_deferral,
                  filename: "/Users/max/src/keybase/kbpgp/src/openpgp/keymanager.iced",
                  funcname: "KeyManager.unlock_p3skb"
                });
                KeyManager.import_from_pgp_message({
                  msg: msg,
                  asp: asp,
                  opts: opts
                }, __iced_deferrals.defer({
                  assign_fn: function () {
                    return function () {
                      err = arguments[0];
                      return km = arguments[1];
                    };
                  }(),
                  lineno: 615
                }));

                __iced_deferrals._fulfill();
              })(__iced_k);
            } else {
              return __iced_k();
            }
          })(function () {
            if (typeof err === "undefined" || err === null) {
              err = _this.pgp.merge_private(km.pgp);
            }

            (function (__iced_k) {
              if (err == null) {
                passphrase = Buffer.from([]);

                (function (__iced_k) {
                  __iced_deferrals = new iced.Deferrals(__iced_k, {
                    parent: ___iced_passed_deferral,
                    filename: "/Users/max/src/keybase/kbpgp/src/openpgp/keymanager.iced",
                    funcname: "KeyManager.unlock_p3skb"
                  });

                  _this.unlock_pgp({
                    passphrase: passphrase
                  }, __iced_deferrals.defer({
                    assign_fn: function () {
                      return function () {
                        return err = arguments[0];
                      };
                    }(),
                    lineno: 624
                  }));

                  __iced_deferrals._fulfill();
                })(__iced_k);
              } else {
                return __iced_k();
              }
            })(function () {
              return cb(err);
            });
          });
        };
      }(this));
    };

    KeyManager.import_from_pgp_message = function (_arg, cb) {
      var asp, bundle, err, k, kb, msg, opts, packets, warnings, ___iced_passed_deferral, __iced_deferrals, __iced_k, _ref5;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      msg = _arg.msg, asp = _arg.asp, opts = _arg.opts;
      asp = ASP.make(asp);
      bundle = null;
      warnings = null;

      if (typeof err === "undefined" || err === null) {
        _ref5 = parse(msg.body), err = _ref5[0], packets = _ref5[1];
      }

      (function (_this) {
        return function (__iced_k) {
          if (err == null) {
            kb = new KeyBlock(packets, opts);

            (function (__iced_k) {
              __iced_deferrals = new iced.Deferrals(__iced_k, {
                parent: ___iced_passed_deferral,
                filename: "/Users/max/src/keybase/kbpgp/src/openpgp/keymanager.iced",
                funcname: "KeyManager.import_from_pgp_message"
              });
              kb.process(__iced_deferrals.defer({
                assign_fn: function () {
                  return function () {
                    return err = arguments[0];
                  };
                }(),
                lineno: 639
              }));

              __iced_deferrals._fulfill();
            })(function () {
              return __iced_k(warnings = kb.warnings);
            });
          } else {
            return __iced_k();
          }
        };
      })(this)(function (_this) {
        return function () {
          if (err == null) {
            bundle = new KeyManager({
              primary: KeyManager._wrap_pgp(Primary, kb.primary),
              subkeys: function () {
                var _i, _len, _ref6, _results;

                _ref6 = kb.subkeys;
                _results = [];

                for (_i = 0, _len = _ref6.length; _i < _len; _i++) {
                  k = _ref6[_i];

                  _results.push(KeyManager._wrap_pgp(Subkey, k));
                }

                return _results;
              }(),
              user_attributes: kb.user_attributes,
              userids: kb.userids,
              signed: true
            });

            if (bundle.has_pgp_private()) {
              bundle.armored_pgp_private = msg.raw();
            } else {
              bundle.armored_pgp_public = msg.raw();
            }
          }

          (function (__iced_k) {
            if (err == null) {
              (function (__iced_k) {
                __iced_deferrals = new iced.Deferrals(__iced_k, {
                  parent: ___iced_passed_deferral,
                  filename: "/Users/max/src/keybase/kbpgp/src/openpgp/keymanager.iced",
                  funcname: "KeyManager.import_from_pgp_message"
                });
                bundle.check_pgp_validity(__iced_deferrals.defer({
                  assign_fn: function () {
                    return function () {
                      return err = arguments[0];
                    };
                  }(),
                  lineno: 657
                }));

                __iced_deferrals._fulfill();
              })(__iced_k);
            } else {
              return __iced_k();
            }
          })(function () {
            return cb(err, bundle, warnings, packets);
          });
        };
      }(this));
    };

    KeyManager.prototype.merge_pgp_private = function (_arg, cb) {
      var armored, asp, b2, err, esc, import_opts, raw, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      armored = _arg.armored, raw = _arg.raw, asp = _arg.asp, import_opts = _arg.import_opts;
      asp = ASP.make(asp);
      esc = make_esc(cb, "merge_pgp_private");
      (function (_this) {
        return function (__iced_k) {
          __iced_deferrals = new iced.Deferrals(__iced_k, {
            parent: ___iced_passed_deferral,
            filename: "/Users/max/src/keybase/kbpgp/src/openpgp/keymanager.iced",
            funcname: "KeyManager.merge_pgp_private"
          });
          KeyManager.import_from_armored_pgp({
            armored: armored,
            raw: raw,
            asp: asp,
            opts: import_opts
          }, esc(__iced_deferrals.defer({
            assign_fn: function () {
              return function () {
                return b2 = arguments[0];
              };
            }(),
            lineno: 668
          })));

          __iced_deferrals._fulfill();
        };
      })(this)(function (_this) {
        return function () {
          err = _this.pgp.merge_private(b2.pgp);

          (function (__iced_k) {
            if (err != null) {
              return __iced_k();
            } else {
              (function (__iced_k) {
                if (!_this.has_pgp_private()) {
                  return __iced_k(err = new Error("no private key material found after merge"));
                } else {
                  (function (__iced_k) {
                    __iced_deferrals = new iced.Deferrals(__iced_k, {
                      parent: ___iced_passed_deferral,
                      filename: "/Users/max/src/keybase/kbpgp/src/openpgp/keymanager.iced",
                      funcname: "KeyManager.merge_pgp_private"
                    });

                    _this.simple_unlock({}, esc(__iced_deferrals.defer({
                      lineno: 675
                    })));

                    __iced_deferrals._fulfill();
                  })(__iced_k);
                }
              })(__iced_k);
            }
          })(function () {
            return cb(err);
          });
        };
      }(this));
    };

    KeyManager.prototype.check_pgp_public_eq = function (km2) {
      return this.pgp.check_eq(km2.pgp);
    };

    KeyManager.prototype.unlock_pgp = function (_arg, cb) {
      var err, passphrase, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      passphrase = _arg.passphrase;
      (function (_this) {
        return function (__iced_k) {
          __iced_deferrals = new iced.Deferrals(__iced_k, {
            parent: ___iced_passed_deferral,
            filename: "/Users/max/src/keybase/kbpgp/src/openpgp/keymanager.iced",
            funcname: "KeyManager.unlock_pgp"
          });

          _this.pgp.unlock_keys({
            passphrase: passphrase
          }, __iced_deferrals.defer({
            assign_fn: function () {
              return function () {
                return err = arguments[0];
              };
            }(),
            lineno: 688
          }));

          __iced_deferrals._fulfill();
        };
      })(this)(function (_this) {
        return function () {
          return cb(err);
        };
      }(this));
    };

    KeyManager.prototype.is_pgp_locked = function () {
      return this.pgp.is_locked();
    };

    KeyManager.prototype.is_keybase_locked = function () {
      return this.keybase.is_locked();
    };

    KeyManager.prototype.has_pgp_private = function () {
      return this.pgp.has_private();
    };

    KeyManager.prototype.has_p3skb_private = function () {
      var _ref5;

      return (_ref5 = this.p3skb) != null ? _ref5.has_private() : void 0;
    };

    KeyManager.prototype.has_keybase_private = function () {
      return this.keybase.has_private();
    };

    KeyManager.prototype.is_p3skb_locked = function () {
      var _ref5;

      return (_ref5 = this.p3skb) != null ? _ref5.is_locked() : void 0;
    };

    KeyManager.prototype.unlock_keybase = function (_arg, cb) {
      var asp, err, tsenc, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      tsenc = _arg.tsenc, asp = _arg.asp;
      asp = ASP.make(asp);
      (function (_this) {
        return function (__iced_k) {
          __iced_deferrals = new iced.Deferrals(__iced_k, {
            parent: ___iced_passed_deferral,
            filename: "/Users/max/src/keybase/kbpgp/src/openpgp/keymanager.iced",
            funcname: "KeyManager.unlock_keybase"
          });

          _this.keybase.unlock_keys({
            tsenc: tsenc,
            asp: asp
          }, __iced_deferrals.defer({
            assign_fn: function () {
              return function () {
                return err = arguments[0];
              };
            }(),
            lineno: 706
          }));

          __iced_deferrals._fulfill();
        };
      })(this)(function (_this) {
        return function () {
          return cb(err);
        };
      }(this));
    };

    KeyManager.prototype.export_private_to_server = function (_arg, cb) {
      var asp, err, p3skb, passphrase_generation, ret, tsenc, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      tsenc = _arg.tsenc, asp = _arg.asp, passphrase_generation = _arg.passphrase_generation;
      asp = ASP.make(asp);
      err = ret = null;
      (function (_this) {
        return function (__iced_k) {
          if ((err = _this._assert_signed()) == null) {
            p3skb = _this.pgp.export_to_p3skb();

            (function (__iced_k) {
              __iced_deferrals = new iced.Deferrals(__iced_k, {
                parent: ___iced_passed_deferral,
                filename: "/Users/max/src/keybase/kbpgp/src/openpgp/keymanager.iced",
                funcname: "KeyManager.export_private_to_server"
              });
              p3skb.lock({
                tsenc: tsenc,
                asp: asp,
                passphrase_generation: passphrase_generation
              }, __iced_deferrals.defer({
                assign_fn: function () {
                  return function () {
                    return err = arguments[0];
                  };
                }(),
                lineno: 719
              }));

              __iced_deferrals._fulfill();
            })(__iced_k);
          } else {
            return __iced_k();
          }
        };
      })(this)(function (_this) {
        return function () {
          if (err == null) {
            ret = p3skb.frame_packet_armored({
              dohash: true
            });
          }

          return cb(err, ret);
        };
      }(this));
    };

    KeyManager.prototype.export_pgp_private_to_client = function (_arg, cb) {
      var err, msg, passphrase, regen;
      passphrase = _arg.passphrase, regen = _arg.regen;
      err = null;

      if (passphrase != null) {
        passphrase = bufferify(passphrase);
      }

      if (regen || (msg = this.armored_pgp_private) == null) {
        if ((err = this._assert_signed()) == null) {
          this.armored_pgp_private = msg = this.pgp.export_keys({
            "private": true,
            passphrase: passphrase
          });
        }
      }

      return cb(err, msg);
    };

    KeyManager.prototype.export_pgp_private = function () {
      var args;
      args = 1 <= arguments.length ? __slice.call(arguments, 0) : [];
      return this.export_pgp_private_to_client.apply(this, args);
    };

    KeyManager.prototype.export_pgp_public = function (_arg, cb) {
      var err, msg, regen;
      regen = _arg.regen;
      err = null;

      if (regen || (msg = this.armored_pgp_public) == null) {
        if ((err = this._assert_signed()) == null) {
          this.armored_pgp_public = msg = this.pgp.export_keys({
            "private": false
          });
        }
      }

      return cb(err, msg);
    };

    KeyManager.prototype.export_public = function (_arg, cb) {
      var asp, err, msg, regen, ___iced_passed_deferral, __iced_deferrals, __iced_k, _ref5;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      _ref5 = _arg != null ? _arg : {}, asp = _ref5.asp, regen = _ref5.regen;

      if (cb == null) {
        cb = null;
      }

      (function (_this) {
        return function (__iced_k) {
          __iced_deferrals = new iced.Deferrals(__iced_k, {
            parent: ___iced_passed_deferral,
            filename: "/Users/max/src/keybase/kbpgp/src/openpgp/keymanager.iced",
            funcname: "KeyManager.export_public"
          });

          _this.export_pgp_public({
            asp: asp,
            regen: regen
          }, __iced_deferrals.defer({
            assign_fn: function () {
              return function () {
                err = arguments[0];
                return msg = arguments[1];
              };
            }(),
            lineno: 753
          }));

          __iced_deferrals._fulfill();
        };
      })(this)(function (_this) {
        return function () {
          return cb(err, msg);
        };
      }(this));
    };

    KeyManager.prototype.export_private = function (_arg, cb) {
      var asp, err, p3skb, passphrase, passphrase_generation, regen, res, tsenc, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      passphrase = _arg.passphrase, p3skb = _arg.p3skb, asp = _arg.asp, regen = _arg.regen, passphrase_generation = _arg.passphrase_generation;
      (function (_this) {
        return function (__iced_k) {
          if (p3skb) {
            tsenc = new Encryptor({
              key: bufferify(passphrase)
            });

            (function (__iced_k) {
              __iced_deferrals = new iced.Deferrals(__iced_k, {
                parent: ___iced_passed_deferral,
                filename: "/Users/max/src/keybase/kbpgp/src/openpgp/keymanager.iced",
                funcname: "KeyManager.export_private"
              });

              _this.export_private_to_server({
                tsenc: tsenc,
                asp: asp,
                passphrase_generation: passphrase_generation
              }, __iced_deferrals.defer({
                assign_fn: function () {
                  return function () {
                    err = arguments[0];
                    return res = arguments[1];
                  };
                }(),
                lineno: 761
              }));

              __iced_deferrals._fulfill();
            })(__iced_k);
          } else {
            (function (__iced_k) {
              __iced_deferrals = new iced.Deferrals(__iced_k, {
                parent: ___iced_passed_deferral,
                filename: "/Users/max/src/keybase/kbpgp/src/openpgp/keymanager.iced",
                funcname: "KeyManager.export_private"
              });

              _this.export_pgp_private_to_client({
                passphrase: passphrase,
                asp: asp,
                regen: regen
              }, __iced_deferrals.defer({
                assign_fn: function () {
                  return function () {
                    err = arguments[0];
                    return res = arguments[1];
                  };
                }(),
                lineno: 763
              }));

              __iced_deferrals._fulfill();
            })(__iced_k);
          }
        };
      })(this)(function (_this) {
        return function () {
          return cb(err, res);
        };
      }(this));
    };

    KeyManager.prototype.pgp_full_hash = function (opts, cb) {
      var armored, esc, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      esc = make_esc(cb, "get_pgp_full_hash");
      (function (_this) {
        return function (__iced_k) {
          __iced_deferrals = new iced.Deferrals(__iced_k, {
            parent: ___iced_passed_deferral,
            filename: "/Users/max/src/keybase/kbpgp/src/openpgp/keymanager.iced",
            funcname: "KeyManager.pgp_full_hash"
          });

          _this.export_pgp_public(opts, esc(__iced_deferrals.defer({
            assign_fn: function () {
              return function () {
                return armored = arguments[0];
              };
            }(),
            lineno: 770
          })));

          __iced_deferrals._fulfill();
        };
      })(this)(function (_this) {
        return function () {
          return cb(null, new SHA256(Buffer.from(armored.trim())).toString("hex"));
        };
      }(this));
    };

    KeyManager.prototype.sign_pgp = function (_arg, cb) {
      var asp, time;
      asp = _arg.asp, time = _arg.time;
      return this.pgp.sign({
        asp: asp,
        time: time
      }, cb);
    };

    KeyManager.prototype.sign = function (_arg, cb) {
      var asp, err, time, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      asp = _arg.asp, time = _arg.time;
      asp = ASP.make(asp);
      asp.section("sign");
      asp.progress({
        what: "sign PGP",
        total: 1,
        i: 0
      });
      (function (_this) {
        return function (__iced_k) {
          __iced_deferrals = new iced.Deferrals(__iced_k, {
            parent: ___iced_passed_deferral,
            filename: "/Users/max/src/keybase/kbpgp/src/openpgp/keymanager.iced",
            funcname: "KeyManager.sign"
          });

          _this.sign_pgp({
            asp: asp,
            time: time
          }, __iced_deferrals.defer({
            assign_fn: function () {
              return function () {
                return err = arguments[0];
              };
            }(),
            lineno: 783
          }));

          __iced_deferrals._fulfill();
        };
      })(this)(function (_this) {
        return function () {
          asp.progress({
            what: "sign PGP",
            total: 1,
            i: 1
          });

          if (typeof err === "undefined" || err === null) {
            _this._signed = true;
          }

          return cb(err);
        };
      }(this));
    };

    KeyManager.prototype.get_userids = function () {
      return this.userids;
    };

    KeyManager.prototype.get_userids_mark_primary = function () {
      var do_insert, i, k, max, max_s, mymax, obj, pair, prev, primary_time, ret, s, tab, userid, _i, _len, _ref5;

      max = null;
      max_s = null;
      tab = {};

      mymax = function mymax(a, b) {
        if (a == null && b == null) {
          return null;
        } else if (a == null) {
          return b;
        } else if (b == null) {
          return a;
        } else if (a > b) {
          return a;
        } else {
          return b;
        }
      };

      _ref5 = this.userids;

      for (i = _i = 0, _len = _ref5.length; _i < _len; i = ++_i) {
        userid = _ref5[i];

        if (!(userid != null)) {
          continue;
        }

        s = userid.utf8();
        pair = userid.time_primary_pair();
        obj = {
          userid: userid,
          pair: pair,
          i: i
        };
        do_insert = false;

        if ((prev = tab[s]) != null) {
          primary_time = mymax(prev.pair[1], pair[1]);

          if (!(prev.pair[0] != null) || pair[0] && prev.pair[0] < pair[0]) {
            do_insert = true;
          }
        } else {
          primary_time = pair[1];
          do_insert = true;
        }

        if (do_insert) {
          tab[s] = obj;
        }

        if (primary_time != null && (max == null || max < primary_time)) {
          max_s = s;
          max = primary_time;
        }
      }

      if (max_s != null) {
        tab[max_s].userid.primary = true;
      }

      ret = [];

      for (k in tab) {
        obj = tab[k];
        obj.userid.most_recent_sig = obj.pair[0];
        ret.push(obj.userid);
      }

      return ret;
    };

    KeyManager.prototype.fetch = function (key_ids, flags, cb) {
      return this.pgp.fetch(key_ids, flags, cb);
    };

    KeyManager.prototype.find_pgp_key = function (key_id) {
      return this.pgp.find_key(key_id);
    };

    KeyManager.prototype.find_pgp_key_material = function (key_id) {
      return this.pgp.find_key_material(key_id);
    };

    KeyManager.prototype.find_best_pgp_key = function (flags, need_priv) {
      return this.pgp.find_best_key(flags, need_priv);
    };

    KeyManager.prototype.find_signing_pgp_key = function () {
      return this.find_best_pgp_key(C.key_flags.sign_data, true);
    };

    KeyManager.prototype.find_verifying_pgp_key = function () {
      return this.find_best_pgp_key(C.key_flags.sign_data, false);
    };

    KeyManager.prototype.find_crypt_pgp_key = function (need_priv) {
      if (need_priv == null) {
        need_priv = false;
      }

      return this.find_best_pgp_key(C.key_flags.encrypt_comm, need_priv);
    };

    KeyManager.prototype.can_verify = function () {
      return this.find_verifying_pgp_key() != null;
    };

    KeyManager.prototype.can_sign = function () {
      return this.find_signing_pgp_key() != null;
    };

    KeyManager.prototype.can_encrypt = function () {
      return this.find_crypt_pgp_key(false) != null;
    };

    KeyManager.prototype.can_decrypt = function () {
      return this.find_crypt_pgp_key(true) != null;
    };

    KeyManager.prototype.is_pgp_revoked = function () {
      return this.pgp.is_revoked();
    };

    KeyManager.prototype.get_pgp_designated_revocations = function () {
      return this.pgp.get_designated_revocations();
    };

    KeyManager.prototype.get_primary_keypair = function () {
      return this.primary.key;
    };

    KeyManager.prototype.get_all_pgp_key_materials = function () {
      return this.pgp.get_all_key_materials();
    };

    KeyManager.prototype.export_pgp_keys_to_keyring = function () {
      return this.pgp.export_keys_to_keyring(this);
    };

    KeyManager.prototype.get_pgp_key_id = function () {
      return this.pgp.get_key_id();
    };

    KeyManager.prototype.get_pgp_short_key_id = function () {
      return this.pgp.get_short_key_id();
    };

    KeyManager.prototype.get_pgp_fingerprint = function () {
      return this.pgp.get_fingerprint();
    };

    KeyManager.prototype.get_pgp_fingerprint_str = function () {
      var _ref5;

      return (_ref5 = this.get_pgp_fingerprint()) != null ? _ref5.toString('hex') : void 0;
    };

    KeyManager.prototype.get_ekid = function () {
      return this.pgp.get_ekid();
    };

    KeyManager.prototype.clear_pgp_internal_sigs = function () {
      return this.pgp.clear_psc();
    };

    KeyManager.prototype.get_all_pgp_key_ids = function () {
      return this.pgp.get_all_key_ids();
    };

    KeyManager.prototype.get_ekid_b64_str = function () {
      var k;

      if ((k = this.get_ekid()) != null) {
        return base64u.encode(k);
      } else {
        return null;
      }
    };

    KeyManager.prototype.get_fp2 = function () {
      return this.get_pgp_fingerprint();
    };

    KeyManager.prototype.get_fp2_formatted = function (opts) {
      var p;

      if ((p = this.get_fp2()) != null) {
        return format_pgp_fingerprint_2(p, opts);
      } else {
        return null;
      }
    };

    KeyManager.prototype.get_type = function () {
      return "pgp";
    };

    KeyManager.prototype.check_pgp_validity = function (cb) {
      return this.pgp.validity_check(cb);
    };

    KeyManager.prototype.make_sig_eng = function () {
      return new SignatureEngine({
        km: this
      });
    };

    KeyManager.prototype._apply_to_engines = function (_arg, cb) {
      var args, e, err, meth, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      args = _arg.args, meth = _arg.meth;
      err = null;
      (function (_this) {
        return function (__iced_k) {
          var _i, _len, _ref5, _results, _while6;

          _ref5 = _this.engines;
          _len = _ref5.length;
          _i = 0;

          _while6 = function _while(__iced_k) {
            var _break, _continue, _next;

            _break = __iced_k;

            _continue = function _continue() {
              return iced.trampoline(function () {
                ++_i;
                return _while6(__iced_k);
              });
            };

            _next = _continue;

            if (!(_i < _len)) {
              return _break();
            } else {
              e = _ref5[_i];

              if (!err) {
                (function (__iced_k) {
                  __iced_deferrals = new iced.Deferrals(__iced_k, {
                    parent: ___iced_passed_deferral,
                    filename: "/Users/max/src/keybase/kbpgp/src/openpgp/keymanager.iced",
                    funcname: "KeyManager._apply_to_engines"
                  });
                  meth.call(e, args, __iced_deferrals.defer({
                    assign_fn: function () {
                      return function () {
                        return err = arguments[0];
                      };
                    }(),
                    lineno: 923
                  }));

                  __iced_deferrals._fulfill();
                })(_next);
              } else {
                return _continue();
              }
            }
          };

          _while6(__iced_k);
        };
      })(this)(function (_this) {
        return function () {
          return cb(err);
        };
      }(this));
    };

    KeyManager.prototype._assert_signed = function () {
      if (this._signed) {
        return null;
      } else {
        return new Error("need to sign before export");
      }
    };

    KeyManager._wrap_pgp = function (klass, kmp) {
      var _ref5;

      return new klass({
        key: kmp.key,
        lifespan: new Lifespan({
          generated: kmp.timestamp,
          expire_in: (_ref5 = kmp.get_expire_time()) != null ? _ref5.expire_in : void 0
        }),
        _pgp: kmp
      });
    };

    KeyManager.prototype.merge_all_subkeys_omitting_revokes = function (km2) {
      if (this.pgp != null && km2.pgp != null) {
        return this.pgp.merge_all_subkeys_omitting_revokes(km2.pgp);
      }
    };

    KeyManager.prototype.pgp_check_not_expired = function (_arg) {
      var now, subkey_material;
      subkey_material = _arg.subkey_material, now = _arg.now;
      return this.pgp.check_not_expired({
        subkey_material: subkey_material,
        now: now
      });
    };

    KeyManager.prototype.merge_public_omitting_revokes = function (km2) {
      if (this.pgp != null && km2.pgp != null) {
        return this.pgp.merge_public_omitting_revokes(km2.pgp);
      }
    };

    KeyManager.prototype.merge_userids = function (km2) {
      var candidate_userid, existing_userid, existing_utf8_strings, _i, _j, _len, _len1, _ref5, _ref6, _results;

      if (this.pgp == null || km2.pgp == null) {
        return;
      }

      existing_utf8_strings = {};
      _ref5 = this.userids;

      for (_i = 0, _len = _ref5.length; _i < _len; _i++) {
        existing_userid = _ref5[_i];
        existing_utf8_strings[existing_userid.utf8()] = true;
      }

      _ref6 = km2.get_userids_mark_primary();
      _results = [];

      for (_j = 0, _len1 = _ref6.length; _j < _len1; _j++) {
        candidate_userid = _ref6[_j];

        if (!(candidate_userid.utf8() in existing_utf8_strings)) {
          _results.push(this.userids.push(candidate_userid));
        } else {
          _results.push(void 0);
        }
      }

      return _results;
    };

    KeyManager.prototype.merge_everything = function (km2) {
      this.merge_public_omitting_revokes(km2);
      return this.merge_userids(km2);
    };

    KeyManager.prototype.find_verified_designated_revoke = function (fetcher, cb) {
      var err, i, key_id, keymat, km, sig, sigs, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);

      if (this.pgp == null) {
        return cb();
      }

      sigs = this.get_pgp_designated_revocations();

      if (sigs == null || sigs.length === 0) {
        return cb();
      }

      (function (_this) {
        return function (__iced_k) {
          var _i, _len, _ref5, _results, _while7;

          _ref5 = sigs;
          _len = _ref5.length;
          _i = 0;

          _while7 = function _while(__iced_k) {
            var _break, _continue, _next;

            _break = __iced_k;

            _continue = function _continue() {
              return iced.trampoline(function () {
                ++_i;
                return _while7(__iced_k);
              });
            };

            _next = _continue;

            if (!(_i < _len)) {
              return _break();
            } else {
              sig = _ref5[_i];
              key_id = sig.get_issuer_key_id();

              (function (__iced_k) {
                __iced_deferrals = new iced.Deferrals(__iced_k, {
                  parent: ___iced_passed_deferral,
                  filename: "/Users/max/src/keybase/kbpgp/src/openpgp/keymanager.iced",
                  funcname: "KeyManager.find_verified_designated_revoke"
                });
                fetcher.fetch([key_id], konst.ops.verify, __iced_deferrals.defer({
                  assign_fn: function () {
                    return function () {
                      err = arguments[0];
                      km = arguments[1];
                      return i = arguments[2];
                    };
                  }(),
                  lineno: 995
                }));

                __iced_deferrals._fulfill();
              })(function () {
                (function (__iced_k) {
                  if (err || typeof km === "undefined" || km === null || km.pgp == null) {
                    (function (__iced_k) {
                      _continue();
                    })(__iced_k);
                  } else {
                    return __iced_k();
                  }
                })(function () {
                  keymat = km.find_pgp_key_material(key_id);

                  (function (__iced_k) {
                    if (keymat == null) {
                      (function (__iced_k) {
                        _continue();
                      })(__iced_k);
                    } else {
                      return __iced_k();
                    }
                  })(function () {
                    (function (__iced_k) {
                      __iced_deferrals = new iced.Deferrals(__iced_k, {
                        parent: ___iced_passed_deferral,
                        filename: "/Users/max/src/keybase/kbpgp/src/openpgp/keymanager.iced",
                        funcname: "KeyManager.find_verified_designated_revoke"
                      });

                      sig._third_party_verify(keymat, __iced_deferrals.defer({
                        assign_fn: function () {
                          return function () {
                            return err = arguments[0];
                          };
                        }(),
                        lineno: 1003
                      }));

                      __iced_deferrals._fulfill();
                    })(function () {
                      if (!err) {
                        return cb(sig);
                      }

                      return _next();
                    });
                  });
                });
              });
            }
          };

          _while7(__iced_k);
        };
      })(this)(function (_this) {
        return function () {
          return cb();
        };
      }(this));
    };

    return KeyManager;
  }(KeyManagerInterface);

  exports.KeyManager = KeyManager;
  exports.opkts = opkts;
}).call(this);