import * as React from 'react';
import {Component, Fragment} from 'react';

import './RecipientSelectorView.scss';
import {t} from '../../i18n';
import TextField from '@material-ui/core/TextField';

interface RecipientSelectorViewProps {
    recipientAddr: string;
    error: string;
    updateRecipientAddr: (value:string)=>void;
}

export class RecipientSelectorView extends Component<RecipientSelectorViewProps,{}>{
    constructor(props: RecipientSelectorViewProps){
        super(props);
    }

    updateRecipientAddr = (value:string)=>{
        let addr = value.replace(/\s/g, '');
        this.props.updateRecipientAddr(addr);
    }

    render(){
        let error = this.props.error? <div className='input-error-message'>{this.props.error}</div>: null;
        return (
            <div className='recipient-input-view'>
                <TextField value={this.props.recipientAddr} spellCheck={false} label={t('收方地址')} required
                           onChange={(e)=>{this.updateRecipientAddr(e.target.value)}}
                           className='recipient-input'/>
                { error }
            </div>
        )
    }
}