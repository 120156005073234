import * as React from 'react';
import {Component, Fragment} from 'react';
import Highlighter from 'react-highlight-words';
import { useDoubleTap } from 'use-double-tap';

/**
 * locate {#term} in text
 * @param text 
 */
function parseTerms(text:string):{type:'text'|'term', value:string}[]{
    if(!text) text = '';
    let result: {type:'text'|'term', value:string}[] = [];
    let state: 'text'|'term' = 'text';
    let buf = '';
    for(let i=0; i<text.length; i++){
        let ch = text.charAt(i);
        switch(state){
            case 'text': 
                if(ch === '{' && text.charAt(i+1) === '#'){
                    result.push({type:'text', value: buf});
                    buf = '';
                    state = 'term';
                    i++;
                }else{
                    buf += ch;
                }
                break;
            case 'term':
                if(ch === '}'){
                    result.push({type:'term', value: buf});
                    buf = '';
                    state = 'text';
                }else{
                    buf += ch;
                }
        }
    }
    if(buf){
        result.push({type:'text', value: buf});
    }

    return result;
}

class TermLink extends Component<{term:string, highlightWords?:string[], onClickTerm?:(term:string)=>void },
                                  {}> {
    render(){
        let isHighlighted = (this.props.highlightWords||[]).some(x=> x === this.props.term);
        let className = 'shuyu' + (isHighlighted?' highlight':'');
        return <span className={className} onClick={()=>{ this.props.onClickTerm && this.props.onClickTerm(this.props.term); }}>{this.props.term}</span>;
    }
}

// multiline line paragraph support double tap to switch font size
function MultilineFieldText(props: {lines:JSX.Element[], fontSize:'normal'|'large', onDoubleClick: ()=>void}) {
    const bind = useDoubleTap((event) => {
        props.onDoubleClick();
      });

    return  <div {...bind} className={'multiline-field-label-text font-'+props.fontSize}>{props.lines}</div>

}

interface MultilineFieldProps {
    label?:string, 
    lines:string, 
    extraClasses?:string,
    highlightWords?:string[],
    onClickTerm:(term:string)=>void,
    skipShuyu?: boolean; 
}

export class MultilineField extends Component<MultilineFieldProps,{ fontSize:'normal'|'large' }> {
    constructor(props:MultilineFieldProps){
        super(props);
        this.state = {fontSize: 'normal'}
    }
    render() {
        // info(`MultilineField render ${JSON.stringify(this.props)}`);
        if(typeof (this.props.lines||'') !== 'string'){
            return <div>{JSON.stringify(this.props)}</div>
        }
        let lines = (this.props.lines||'').split('\n').map(line=> {
            let textItems = this.props.skipShuyu ? [{type:'text', value:line}]: parseTerms(line);

            return this.props.highlightWords && this.props.highlightWords.length>0? 
                    <div className='line'>
                        {textItems.map(item=>{
                            if(item.type === 'text'){
                                return <Highlighter searchWords={this.props.highlightWords||[]} 
                                                    textToHighlight={item.value} 
                                                    highlightClassName='highlight'/>
                            }else{
                                return <TermLink term={item.value} highlightWords={this.props.highlightWords} 
                                                 onClickTerm={this.props.onClickTerm}/>
                            }
                        })}
                    </div>:
                    <div className='line'>
                        {textItems.map(item=>{
                            if(item.type === 'text'){
                                return item.value;
                            }else{
                                return <TermLink term={item.value} onClickTerm={this.props.onClickTerm}/>
                            }
                        })}
                    </div>;
        })
        return <div className={'multiline-field '+(this.props.extraClasses || '')}>
                    {!!this.props.label && <div className='multiline-field-label'>
                        <strong>{this.props.label}</strong> 
                    </div>}
                    <MultilineFieldText lines={lines} fontSize={this.state.fontSize} onDoubleClick={()=>{ this.switchFont() }}/>
                </div>

    }

    switchFont() {
        if(this.state.fontSize==='normal'){
            this.setState({fontSize:'large'});
        }else{
            this.setState({fontSize:'normal'});
        }
    }
}
