import * as React from 'react';
import {Component} from 'react';
import { connect } from 'react-redux';
import { t } from '../../i18n';
import { WalletAppState } from '../../redux-store';
import { NoticeDialog } from '../../view/NoticeDialog';
import { NutritionistInfo, ShopUserInfo } from '../ajax-data/ajax-data-types';
import { getMyShopUserInfo, retrieveMyNutritionist, updateShopUserInfo } from '../ajax-data/shopping-server-api';
import { doAJAXCall } from '../common/ajax-call';
import { BaseShoppingPageProps, } from '../common/shopping-page-common';
import { NutritionistDetailRender } from './nutritionist';

import { enCA, zhCN, zhTW } from 'date-fns/locale'
import { formatRelative } from 'date-fns'

import './my-nutritionist.scss';
import { gotoShoppingPathNSavePrevPath } from '../history/shopping-history';
import * as _ from 'lodash';
import { Toast } from '../../view/Toast';
import { BaseUnishellPageState, renderInitErrorUnishellPage, renderInitUnishellPage, renderUnishellPage } from '../../unishell-page-common/unishell-page-common';
import { loadExternContatnsIfNecessary } from '../../external-constants/extern-constants';
import { UButton } from '../../widget/button/ubutton';
import { Dialog, DialogActions, DialogContent, Divider, TextField } from '@material-ui/core';
import { ConfirmDialogView } from '../../view/confirm-dialog/ConfirmDialogView';
import { formatDateTime } from '../../util/Util';

declare function scanNutritionistQRCode():void;

interface MyNutritionistProps extends BaseShoppingPageProps{

}

interface MyNutritionistState extends BaseUnishellPageState{
    myShopUserInfo?: ShopUserInfo;

    nutritionist?:NutritionistInfo;
    setTimestamp?: number;

    renderCancel?: boolean;
    cancelComment?: string;
}

class MyNutritionistView extends Component<MyNutritionistProps, MyNutritionistState>{
    private errorDialog:NoticeDialog|undefined;
    private toast: Toast | undefined;
    private confirmDialog:ConfirmDialogView | undefined;

    constructor(props:MyNutritionistProps){
        super(props);
        this.state = {pageInitState:'PAGE_INIT'};
    }
    getTitle(){
        return t('专业服务');
    }
    render(){
        if(this.state.pageInitState === 'PAGE_INIT'){
            return <React.Fragment>
                        {renderInitUnishellPage(
                            {
                                title: this.getTitle(), 
                                className:'shopping-my-nutritionist',
                                message:t('初始化...')
                            })
                        }
                        <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                        <Toast close={true} duration={2500} message='' ref={e=>{this.toast=e!}} toastType='info'/>
                    </React.Fragment>
        }
        if(this.state.pageInitState === 'PAGE_LOAD_FAILED'){
            return <React.Fragment>
                        {renderInitErrorUnishellPage(
                            {
                                title: this.getTitle(), 
                                className:'shopping-my-nutritionist',
                                errorMessage: this.state.initErrorMessage||t('初始化失败'),
                                onTryAgain: ()=>{ this.initializeAgain();    }
                            })
                        }
                        <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                        <Toast close={true} duration={2500} message='' ref={e=>{this.toast=e!}} toastType='info'/>
                    </React.Fragment>
        }
        // loading data succeeded
        return renderUnishellPage({
            title: this.getTitle(), 
            className:'shopping-my-nutritionist',
            content:(
                <React.Fragment>
                    { this.renderSucceedContent() }
                    <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                    <Toast close={true} duration={2500} message='' ref={e=>{this.toast=e!}} toastType='info'/>
                    <ConfirmDialogView open={false} ref={e=>this.confirmDialog=e!} />
                </React.Fragment>
            )
        })
    }

    renderCancelSection () {
        if(!this.state.renderCancel) return null;

        const {cancelComment} = this.state;

        return <Dialog open={true} className='cancel-nutritionist-dialog' fullWidth={true} maxWidth='xl'>
                <DialogContent>
                        <p>{t('请告知取消原因,以便我们尽快处理')}*</p>
                        <TextField value={this.state.cancelComment} variant='outlined' size='small'
                                   multiline minRows={2} maxRows={5}
                                   onChange={evt=>{ this.setState({cancelComment: evt.currentTarget.value}) }}
                        />
                </DialogContent>
                <DialogActions>
                        <UButton variant='outlined' color='secondary' size='small' disabled={!cancelComment || cancelComment.trim().length<1}
                                 onClick={()=>{ this.doCancel() }}
                        >{t('确认')}</UButton> 
                        <UButton variant='outlined' color='primary' size='small'
                                 onClick={()=>{ this.setState({renderCancel: false}) }}
                        >{t('保留营养师')}</UButton>
                </DialogActions>
                </Dialog>
    }

    doCancel(){
        this.setState({renderCancel: false}, ()=>{
                let myShopUserInfo = _.cloneDeep(this.state.myShopUserInfo!);
                let nutritionist = this.state.nutritionist!;
                delete myShopUserInfo.nutritionist;
                delete myShopUserInfo.nutritionistLinkTime;
                myShopUserInfo.comment = this.state.cancelComment;
        
                doAJAXCall({
                    whatIsFor: t('取消我的执业营养师'),
                    errorDialog: this.errorDialog!,
                    ajaxCall: async (accessToken:string)=>{
                        let replyUserInfo = await updateShopUserInfo(accessToken, myShopUserInfo);
                        if(!replyUserInfo.nutritionist){
                            this.setState({myShopUserInfo, nutritionist: undefined}, ()=>{
                                this.toast!.sendMessage(t('取消营养师')+' '+nutritionist.name, 'info');
                            });    
                        }else{
                            this.errorDialog!.show('', 
                                                   <div>
                                                       <p>{t('收到取消营养师')+' '+nutritionist.name+' '+t('请求')}</p>
                                                       <p>{formatDateTime(new Date())}</p>
                                                   </div>, 
                                                   ()=>{})
                        }
                    }
                })                    
        })
    }

    unsetMyNutritionist(){
        this.setState({renderCancel: true});
    }
    renderSucceedContent():JSX.Element {
        if(this.state.nutritionist){
            const langcode = this.props.languageCode;
            let locale = zhCN;
            switch(langcode){
                case 'zh-TW':
                case 'zh-HW':
                case 'zh-MO':
                    locale = zhTW;
                    break;
                case 'en':{
                    locale = enCA;
                }    
            }

            let relativeTime = formatRelative(new Date(this.state.setTimestamp!), new Date(), {locale})
            return (
                <div className='shopping-my-nutritionist-main-pane'>
                    <h4>{t('我的执业营养师')}</h4>
                    <p className='set-nutritionist-time'>
                        {t('设为营养师时间')}: {relativeTime}
                    </p>
                    <UButton variant='outlined' size='small' color='secondary' id='unset-nutritionist-button'
                            onClick={()=>{
                                this.unsetMyNutritionist();
                            }}
                    >{t('取消我的执业营养师')}</UButton>
                    <NutritionistDetailRender detail={this.state.nutritionist!} />
                    {this.renderCancelSection()}
                </div>
            )
        }else{
            return (
                <div className='shopping-my-nutritionist-main-pane'>
                    <h3>{t('用下列任一方式设置营养师')}</h3>
                    <Divider/>
                    <UButton variant='contained' color='primary' className='scan-nutritionist-qrcode-button'
                            onClick={()=>{
                                scanNutritionistQRCode();
                            }}
                    >
                        {t('扫码选择服务')}
                    </UButton>
                    <Divider/>
                    <a href='#' className='goto-nutritionist-list' onClick={this.clickSearchNutritionist}>{t('专业服务查询')}</a>                    
                </div>
            )
        }
    }

    clickSearchNutritionist = ()=>{
        gotoShoppingPathNSavePrevPath('main/tools/nutritionist-list');
    }

    componentDidMount(){
        this.loadData();
    }

    initializeAgain(){
        this.setState({pageInitState:'PAGE_INIT', initErrorMessage:undefined},
                      ()=>{
                          this.loadData();
                      })
    }

    loadData(){
        doAJAXCall({
            whatIsFor: t('下载营养师'),
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken)=>{ 
                await loadExternContatnsIfNecessary();
                let myShopUserInfo = await getMyShopUserInfo(accessToken);
                let nutritionist = myShopUserInfo && myShopUserInfo.nutritionist? 
                                    await retrieveMyNutritionist(accessToken, myShopUserInfo.nutritionist): undefined;
                let setTimestamp = myShopUserInfo? myShopUserInfo.nutritionistLinkTime : undefined;
                this.setState({myShopUserInfo, nutritionist, setTimestamp, pageInitState:'PAGE_LOAD_SUCCESS'});
            },
            onError: (errorMessage:string)=>{ this.setState({pageInitState:'PAGE_LOAD_FAILED', initErrorMessage:errorMessage})}                                           
        })        
    }
}

export const MyNutritionist = connect(
    (state:WalletAppState)=>({languageCode: state.language.langCode})
)(MyNutritionistView);