import * as React from 'react';
import {Component, Fragment} from 'react';
import { PagedResponse } from '../../api/transaction';
import { t } from '../../i18n';
import { BaseUnishellPageState, renderInitErrorUnishellPage, renderInitUnishellPage, renderUnishellPage } from '../../unishell-page-common/unishell-page-common';
import { ConfirmDialogView } from '../../view/confirm-dialog/ConfirmDialogView';
import { NoticeDialog } from '../../view/NoticeDialog';
import { Toast } from '../../view/Toast';
import { SimplePhoneNumber } from '../../widget/phone-number/phone-number';
import { AddressInfo2StrArray, ShoppingPickupAddress } from '../ajax-data/ajax-data-types';
import { getPickupAddresses } from '../ajax-data/shopping-server-api';
import { doAJAXCall } from '../common/ajax-call';
import { Pagination } from '../common/render-pagination';
import './pickup-location.scss';

interface MyState extends BaseUnishellPageState{
    page:number;
    pageSize: number;

    searchResult?:PagedResponse<ShoppingPickupAddress>;
}

export class PickupLocations extends Component<{}, MyState> {
    private errorDialog:NoticeDialog|undefined;

    constructor(props: {}){
        super(props);
        this.state = {
                        pageInitState:'PAGE_INIT', 
                        page: 0, 
                        pageSize: 10,
                     }
    }

    getTitle(){
        return t('取货地点信息');
    }

    render(){
        if(this.state.pageInitState === 'PAGE_INIT'){
            return <React.Fragment>
                        {renderInitUnishellPage(
                            {
                                title: this.getTitle(), 
                                className:'pickup-locations',
                                message:t('初始化...')
                            })
                        }
                        <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                    </React.Fragment>
        }
        if(this.state.pageInitState === 'PAGE_LOAD_FAILED'){
            return <React.Fragment>
                        {renderInitErrorUnishellPage(
                            {
                                title: this.getTitle(), 
                                className:'pickup-locations',
                                errorMessage: this.state.initErrorMessage||t('初始化失败'),
                                onTryAgain: ()=>{ this.initializeAgain();    }
                            })
                        }
                        <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                    </React.Fragment>
        }

        return renderUnishellPage({
            title: this.getTitle(), 
            className:'pickup-locations',
            content:(
                <React.Fragment>
                    { this.renderSucceedContent() }
                    <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                </React.Fragment>
            )
        }) 
    }

    renderSucceedContent():JSX.Element {
        return (
            <div className='pickup-location-content'>
                <Pagination data={this.state.searchResult!} page={this.state.page} pageSize={this.state.pageSize} 
                            loadData={this.loadData}
                            changePageSize={(newPageSize, newPage)=>{
                                this.loadData(newPage, newPageSize);
                            }}
                >
                { this.renderLocationList(this.state.searchResult!.content) }
                </Pagination>
            </div>
        )
    }

    renderLocationList(content: ShoppingPickupAddress[]) {
        return (
            content.map(x=>{
                let addressLines = AddressInfo2StrArray(x);
                return <div className='pickup-location'>
                            {addressLines.map(l=>{
                                return <div>{l}</div>
                            })}
                            <div>{x.name}</div>
                            <div><SimplePhoneNumber phoneNumber={x.phoneNumber}/></div>
                            <div>{x.comment}</div>
                       </div>
            })
        )
    }


    initializeAgain(){
        this.setState({pageInitState:'PAGE_INIT', initErrorMessage:undefined},
                      ()=>{
                        this.loadData(this.state.page, this.state.pageSize, true);
                    })
    }


    loadData(page:number, pageSize: number, isInit?: boolean){
        doAJAXCall({
            whatIsFor: t('下载取货地点信息'),
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken)=>{
                let searchResult = await getPickupAddresses(accessToken, {page, pageSize});
                this.setState({searchResult, page: searchResult.page, pageSize:searchResult.size, pageInitState:'PAGE_LOAD_SUCCESS'});
            },
            onError: (errorMessage:string)=>{ 
                if(isInit){
                    this.setState({pageInitState:'PAGE_LOAD_FAILED', initErrorMessage:errorMessage})
                }
            }                                           
        })
    }

    componentDidMount(){
        this.loadData(this.state.page, this.state.pageSize, true);
    }
}