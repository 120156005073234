import * as React from 'react';
import {Component, Fragment} from 'react';

import './Terms.scss';

export class TermsView extends Component<{dispatch:(x:any)=>void, terms: string},{}>{
    constructor(props: any){
        super(props);
        this.state = {}
    }

    render (){
        return (
            <div className='unishell-club-terms'>
               <div dangerouslySetInnerHTML={{__html: this.props.terms}} />                
            </div>
        )
    }
}