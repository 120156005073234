import * as React from 'react';
import {Component} from 'react';
import classnames from 'classnames';
import './property-list.scss';
import { debug } from '../../globals';
import { calcRationClass } from '../../util/Util';


export interface  PropertyListDataItem {
    /** property name */
    name:string|JSX.Element, 
    /** property value */
    value?: string|number|boolean|JSX.Element|null,
    /** key used by react list */
    key?: string
    /** property value div class name */
    valueClassName?: string;
    /** merge name col and value col into a single col */
    mergeCol?: boolean;

    ignore?: boolean;
}
interface MyProps {
    className?: string;
    min_name_width?: string;
    max_name_width?: string;
    list: PropertyListDataItem[];
    showBorder?: boolean;
    
    /** force merge cols if screew-width/letter-with < mergeColScreenLetterRatio */
    mergeColScreenLetterRatio?: number;
} 

interface MyState {
    nameWidth?: string;
    valueWidth?: string;
}

/**
 * Display a simple property list on mobile devices:
 * 1. property name on the left and takes minimum spaces.
 * 2. property value on the right and takes the remaining spaces
 * 3. the whole property list is the within its parent.
 * 4. break and wrap long property text value by default
 * 
 * It replaces html table, which is hard to control its column size.
 */
export class PropertyListWidget extends Component<MyProps, MyState> {
    private rootDiv: HTMLDivElement|undefined;

    constructor(props:MyProps){
        super(props);
        this.state = {}
    }

    render(){
        let forceMergeCol = false;
        if(this.props.mergeColScreenLetterRatio){
            const {ratioClass, vw,  mw, ratio} = calcRationClass();
            if(ratio < this.props.mergeColScreenLetterRatio){
                forceMergeCol=true;
            }
        }
        const className = classnames('property-list-widget', this.props.className, {"show-border": !!this.props.showBorder});
        const filteredList = this.props.list.filter(x=>!x.ignore);
        return (
            <div className={className} ref={(e)=>{ this.rootDiv = e!}}>
            {
                filteredList.map((p,idx)=>{
                    const key = p.key || idx;
                    const nameStyle:React.CSSProperties = {};
                    const valueClassName = 'property-value'+(p.valueClassName? ` ${p.valueClassName}`:'')

                    if(forceMergeCol || p.mergeCol){
                        return <div className={classnames('property-list-row-merge-cols', 
                                                          {first: idx===0},
                                                          {last: idx === filteredList.length-1})
                                              }>
                                    <div className='property-name-wrap'>
                                        <div className='property-name'>{p.name}</div>
                                    </div>
                                    <div className='property-value-wrap'>
                                        <div className={valueClassName}>{p.value}</div>
                                    </div>
                                </div>
                    }else{
                        if (this.state.nameWidth) { // we have calculated property name width
                            nameStyle.width = this.state.nameWidth;
                            if(this.props.max_name_width){ 
                                // since we set max name width so we need to reset white-space in case property name exceeds property name width
                                nameStyle.whiteSpace = 'normal';
                            }
                        }
                        if(this.props.min_name_width){
                            nameStyle.minWidth = this.props.min_name_width;
                        }
                        if(this.props.max_name_width){
                            nameStyle.maxWidth = this.props.max_name_width;
                        }
    
                        const valueStyle:React.CSSProperties = {};
                        if(this.state.valueWidth){
                            valueStyle.width = this.state.valueWidth;
                        }    
                        /*
                        * put property name in its own div so that we can control the width of flex item (property-name-wrap).
                        * If we put property name under property-name-wrap directly, flex render could determine the width of 
                        * property-name-wrap based on number of chars of property name.
                        */
                        return  <div className={classnames('property-list-row',
                                                    {first: idx===0},
                                                    {last: idx === filteredList.length-1})
                                                } key={key}>
                                    <div className='property-name-wrap'>
                                        <div className='property-name' style={nameStyle}>{p.name}</div>
                                    </div>
                                    <div className='property-value-wrap' style={valueStyle}>
                                        <div className={valueClassName}>{p.value}</div>
                                    </div>
                                </div>

                    }
                })
            }
            </div>
        )
    }

    componentDidMount(){
        this.adjustNameWidth();
    }

    componentDidUpdate(){
        // skip it for now to prevent infinite loop
        // this.adjustNameWidth();
    }

    adjustNameWidth(){
        let maxNameWidth = 0;
        let minValueWidth = 10000;

        // give name enough space because its flex-grow is 0
        const nameDivList = this.rootDiv!.querySelectorAll('.property-list-widget .property-list-row .property-name');
        for(let i=0; i<nameDivList.length; i++){
            const nameDiv = nameDivList.item(i) as HTMLDivElement;
            maxNameWidth = Math.max(maxNameWidth, nameDiv.getBoundingClientRect().width);
        }

        // prevent value overflow because its flow-grow is 1 (takes remaining space)
        const valueDivList = this.rootDiv!.querySelectorAll('.property-list-widget .property-list-row .property-value-wrap');
        for(let i=0; i<valueDivList.length; i++){
            const valueDiv = valueDivList.item(i) as HTMLDivElement;
            minValueWidth = Math.min(minValueWidth, valueDiv.getBoundingClientRect().width);
        }

        if(this.state.nameWidth !== maxNameWidth+'px' || this.state.valueWidth !== minValueWidth+'px'){
            debug(`setState ${JSON.stringify({nameWidth: maxNameWidth+'px', valueWidth: minValueWidth+'px'})}`)
            this.setState({nameWidth: maxNameWidth+'px', valueWidth: minValueWidth+'px'});
        }
    }
}