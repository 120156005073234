import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import classnames from 'classnames';
import * as React from 'react';
import {Component, Fragment} from 'react';
import { t } from '../../i18n';
import { calcRationClass, RatioMD, RatioSM, RatioXS } from '../../util/Util';
import { NutritionistClient } from '../ajax-data/ajax-data-types';

import './search-health-advice-form.scss';
import { SearchTextInput } from './search-text-input';

export interface ClientSearchHealthAdviceFilter {
    text?: string,
    state?: string;
}

/**
 * search health advice filters issued by a nutritionist.
 */
export interface SearchHealthAdviceFilter extends ClientSearchHealthAdviceFilter {
}

interface SearchHealthAdviceFormProps extends SearchHealthAdviceFilter{
    statusOptions:{key:string, text:string}[],
    doSearch: (filter: SearchHealthAdviceFilter)=> void;
} 

export class SearchHealthAdviceForm extends Component<SearchHealthAdviceFormProps,SearchHealthAdviceFilter>{
    private searchInput: SearchTextInput | undefined;

    constructor(props: SearchHealthAdviceFormProps){
        super(props);
        let status = props.state;
        if(this.props.statusOptions.every(x=> x.key !== props.state)){
            status = '';            
        }
        this.state = {...props, state: status}
    }
    render(){
        const {ratioClass, vw,  mw, ratio} = calcRationClass();
        {
            let xs = 12;
            let twoCols = false;
            if(ratio >= RatioXS*3){
                xs = 6;
                twoCols = true;
            }
            return (
                <div className={classnames('search-client-health-advice-form', {'multi-cols': twoCols})}>
                    <Grid container spacing={0}>
                        <Grid item xs={xs as 6|12}>
                            <SearchTextInput 
                                placeholder={t('关键词')} 
                                initialValue={this.state.text} 
                                ref={x=>{this.searchInput=x!}} 
                                onSearch={()=>{this.props.doSearch(this.getSearchHealthAdviceFilter()) }}/>
                        </Grid>
                        <Grid item xs={xs as 6|12}>
                            <div className='health-advice-state-wrapper'>
                                <div className='label'>{t(`建议书状态`)}</div>
                                <FormControl variant="outlined" size='small' className='status-select'>
                                    <Select value={this.state.state} onChange={evt=>{ this.setState({state: evt.target.value as string})  }} 
                                    >
                                        {this.props.statusOptions.map(x=>{
                                            return <MenuItem value={x.key}>{x.text}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            )
        }
    }

    getSearchHealthAdviceFilter():SearchHealthAdviceFilter{
        return {...this.state, text: this.searchInput!.getValue()};
    }
}
