import * as React from 'react';
import {Component} from 'react';
import classNames from 'classnames';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button } from '@material-ui/core';
import {t} from '../../i18n';
import './ConfirmDialogView.scss';
import { UButton } from '../../widget/button/ubutton';

/**
 * @param ok true if user clicks Ok button; false if user clicks Cancel Button
 */
export type OnCloseFn = (ok:boolean, clientData?: any)=>void;

interface ConfirmDialogProps {
    title?: string;
    message?:JSX.Element;
    handleClose?: OnCloseFn;
    yesLabel?: string;
    noLabel?: string;
    disableNoButton?: boolean;
    open: boolean;
    id?:string
}


interface ConfirmDialogState {
    open: boolean;
    title?: string;
    message?:JSX.Element;
    yesLabel?: string;
    noLabel?: string;
    disableNoButton?: boolean;

    handleClose?: OnCloseFn;
    clientData?: any;  // passed to handleClass callback
}

/**
 * A small stateful dialog.
 * 
 * Caller can call show() method to show the dialog and ask user to confirm or cancel an action. Or
 * caller can change key in <ConfirmDialog open={true} tile='...' messag={...} key='...'/> to cause ConfirmDialog to
 * re-render.
 */
export class ConfirmDialogView extends Component<ConfirmDialogProps,ConfirmDialogState>{
    constructor(props:ConfirmDialogProps){
        super(props);
        let {open, title, message} = {...this.props};
        this.state = {open, title, message}
    }

    /**
     * caller can call it directly to show the dialog and ask user to confirm or cancel an action.
     * 
     * @param title 
     * @param message 
     */
    public show(args:{
                        title:string, 
                        message: JSX.Element, 
                        handleClose?: OnCloseFn, 
                        clientData?:any,
                        yesLabel?: string;
                        noLabel?: string;
                        disableNoButton?: boolean;                    
                    })
    {
        this.setState({...args, open:true});
    }

    private handleClose=(ok:boolean)=>{
        this.setState({open: false});
        if(this.state.handleClose || this.props.handleClose){
            (this.state.handleClose ? this.state.handleClose! : this.props.handleClose!)(ok, this.state.clientData);
        }
    }
        
    render(){
        return (
            <Dialog  open={this.state.open} aria-labelledby="alert-dialog-title" className='confirm-dialog'
                     aria-describedby="alert-dialog-description"
                     fullWidth={true} maxWidth='xl'>
                <DialogTitle id="alert-dialog-title">{this.state.title||this.props.title||t('确认')}</DialogTitle>
                <DialogContent>
                    <div id="alert-dialog-description">
                        {this.state.message || this.props.message || t('<没有信息>')}
                    </div>
                </DialogContent>
                <DialogActions>
                    <UButton onClick={()=>{this.handleClose(true)}} color="primary">
                        {this.state.yesLabel||this.props.yesLabel||t('确认')}
                    </UButton>
                    {(this.state.disableNoButton!==undefined && !!this.state.disableNoButton) || this.state.disableNoButton===undefined && !!this.props.disableNoButton ? null :
                        <UButton onClick={()=>{this.handleClose(false)}} color="primary">
                            {this.state.noLabel || this.props.noLabel||t('取消')}
                        </UButton>
                    }
                </DialogActions>
            </Dialog>
        )
    }
}
