import * as React from 'react';
import {Component, Fragment} from 'react';
import classNames from 'classnames';
import TextField from '@material-ui/core/TextField';

import { t } from '../i18n';
import './SignupView.scss';
import {SignupRequest, getEmptySignupRequest} from '../api/signup-request';
import { Copywright } from './copywright/Copywright';
import * as EmailValidator from 'email-validator';
import { Header,  } from './header/HeaderView2';
import { Paper, FormControlLabel } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { getLanguageCode } from '../local-storage/local-storage';
import { ClubTermsView } from './club-terms/ClubTerms';
import { stripNonPrintableChars } from '../util/Util';
import { info } from '../globals';
import { UButton } from '../widget/button/ubutton';

interface SignupProp {
    signup: (signupRequest:SignupRequest)=>void;
    gotoLoginPage: ()=>void;
    dispatch: (x:any)=>void;
}

interface SignupState {
    signupRequest: SignupRequest;
    nameError?: string;
    emailError?: string;
    phoneNumberError?: string;
    passwordError?: string;
    confirmedPasswordError?:string;

    renderUnishellClubTerms?: boolean;
}

export class SignupView extends Component<SignupProp,SignupState> {
    private encoder = new TextEncoder;

    constructor(props:SignupProp){
        super(props);
        this.state = {signupRequest:getEmptySignupRequest()}
    }

    updateName = (value:string)=>{
        const signupRequest = {...this.state.signupRequest}
        signupRequest.name = value.substring(0,40);
        this.setState({signupRequest, nameError:undefined})
    }
    updateEmail = (value:string)=>{
        const signupRequest = {...this.state.signupRequest}
        signupRequest.email = stripNonPrintableChars(value).trim().substring(0,40);
        this.setState({signupRequest, emailError:undefined})
    }
    updatePhoneNumer = (value:string)=>{
        const signupRequest = {...this.state.signupRequest}
        signupRequest.phoneNumber = value.replace(/[^0-9.()-]/g,'').substring(0,20);
        this.setState({signupRequest, phoneNumberError:undefined})
    }
    updatePassword = (value:string)=>{
        const signupRequest = {...this.state.signupRequest}
        signupRequest.password = value.substring(0,20);
        this.setState({signupRequest,passwordError:undefined})
    }
    updateConfirmedPassword = (value:string)=>{
        const signupRequest = {...this.state.signupRequest}
        signupRequest.confirmPassword = value.substring(0,20);
        this.setState({signupRequest, confirmedPasswordError:undefined})
    }

    onClickSignup = ()=>{
        console.debug(`validate sign up form`);
        //TODO: validation
        const request = {...this.state.signupRequest};
        let hasError = false;
        let nameError = undefined, emailError=undefined, phoneNumberError=undefined, passwordError=undefined, confirmedPasswordError=undefined;
        let acceptTermsError = undefined;
        if(request.name.length<2){
            hasError = true;
            nameError = t('姓名太短');
        }else{
            let x = this.encoder.encode(request.name);
            info(x);
            if(x.length<6){
                hasError = true;
                nameError = t('姓名太短');    
            }
        }
        if(!EmailValidator.validate(request.email)){
            hasError = true;
            emailError = t('电邮地址错误')+`[${request.email}]`;
        }
        if(request.phoneNumber.replace(/[^0-9]/g,'').length<10){
            hasError = true;
            phoneNumberError = t('电话号码太短');
        }
        if(request.password.length<8){
            hasError = true;
            passwordError = t('密码太短');
        }
        if(request.confirmPassword !== request.password){
            hasError = true;
            confirmedPasswordError = t('重复密码不匹配')
        }
        if(hasError){
            this.setState({nameError,emailError,phoneNumberError,passwordError,confirmedPasswordError})
        }else{
            delete request.confirmPassword;
            this.setState({nameError,emailError,phoneNumberError,passwordError,confirmedPasswordError},()=>{
                request.language = getLanguageCode();
                this.props.signup(request);
            })
        }
    }

    render(){
        if(this.state.renderUnishellClubTerms){
            return this.renderTerms();
        }
        return (
            <div className='signup-root'>
                <Header title={t('会员注册')} noMenuIcon={true}/>
                <div className={'signup-page app-page '+getLanguageCode()}>
                    <div className='signup-view'>
                        <div className="input-wrapper">
                            <TextField className='signup-input' label={t('用户名')} margin='normal' variant='outlined'
                                    value={this.state.signupRequest.name} 
                                    name='name' spellCheck={false}
                                    onChange={(e)=>{ this.updateName(e.target.value) }}
                                    required />
                        </div>
                        <div className="input-error-message">
                        {this.state.nameError}
                        </div>

                        <div className="input-wrapper">
                            <TextField className='signup-input' label={t('电子邮箱')} margin='normal' variant='outlined'
                                    onChange={(e)=>{ this.updateEmail(e.target.value) }}
                                    name='email' spellCheck={false}
                                    value={this.state.signupRequest.email}  required/>
                        </div>
                        <div className="input-error-message">
                        {this.state.emailError}
                        </div>

                        <div className="input-wrapper">
                            <TextField className='signup-input' label={t('电话')} type='' margin='normal' variant='outlined'
                                    onChange={(e)=>{ this.updatePhoneNumer(e.target.value) }}
                                    name='phoneNumber' spellCheck={false}
                                    value={this.state.signupRequest.phoneNumber}  required/>
                        </div>
                        <div className="input-error-message">
                        {this.state.phoneNumberError}
                        </div>

                        <div className="input-wrapper">
                            <TextField className='signup-input' label={t('密码')} type='password' margin='normal' variant='outlined'
                                    onChange={(e)=>{ this.updatePassword(e.target.value) }}
                                    name='password'
                                    value={this.state.signupRequest.password}  required/>
                        </div>
                        <div className="input-error-message">
                        {this.state.passwordError}
                        </div>

                        <div className="input-wrapper">
                            <TextField className='signup-input' label={t('确认密码')} type='password' margin='normal' variant='outlined'
                                    onChange={(e)=>{ this.updateConfirmedPassword(e.target.value) }}
                                    name='confirmPassword'
                                    value={this.state.signupRequest.confirmPassword}  required/>
                        </div>
                        <div className="input-error-message">
                        {this.state.confirmedPasswordError}
                        </div>

                        {/* <div className='input-wrapper'>
                            <div className='terms' onClick={()=>{ this.setState({renderUnishellClubTerms: true}) }}>{t('用户规则')}</div>
                            <FormControlLabel
                                control={
                                <Checkbox
                                    checked={!!this.state.acceptTerms}
                                    onChange={ event =>{ this.updateAcceptTerms(event.target.checked) }}
                                    color="primary"
                                />
                                }
                                label={t('我已阅读并接受用户规则')}
                            />
                            <div className="input-error-message">
                            {this.state.acceptTermsError}
                            </div>
                        </div> */}
                    </div>
                    <div className='input-wrapper'>
                            <UButton variant="contained" color="primary" className='signup-btn'
                                    onClick={this.onClickSignup}>{t('注册')}</UButton>
                    </div>
                    <div className='login-link'>
                        <UButton variant='contained' color='secondary' className='login-link-btn'  onClick={(e)=>{ this.props.gotoLoginPage() }}>
                            {t('我有账号')}
                        </UButton>
                    </div>
                    <Copywright/>
                </div>
            </div>
        )    
    }

    renderTerms = ()=>{
        let closeTerms = ()=>{
            this.setState({renderUnishellClubTerms: false})
        }
        return (
            <ClubTermsView dispatch={this.props.dispatch} onClickBack={closeTerms} noLoginSession={true}/>
        )
    }
}
