import * as React from 'react';
import {Component, Fragment} from 'react';
import './langView.scss';

import {getLanguageCode, setLanguageCode, readLoginSession} from '../../local-storage/local-storage';
import { ArrowDropDown, Check } from '@material-ui/icons';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { updateMyUserProfile } from '../../wallet-server-api/wallet-server-api';
import { LANG_CODE_EN, LANG_CODE_ZH_CN } from '../../external-constants/extern-constants';
import { doAJAXCall } from '../../shopping/common/ajax-call';
import { NoticeDialog } from '../NoticeDialog';
import { t } from '../../i18n';
import { debug } from '../../globals';

interface LangViewProp {
    className?: string;
    color?: 'white' | 'black';
    updateLanguage: (newLangCode:string)=>void;
}

interface LangViewState {
    isOpen:boolean;
}

export class LangView extends Component<LangViewProp, LangViewState> {
    private anchorEl?:HTMLDivElement;
    private errorDialog: NoticeDialog | undefined;

    constructor(props:LangViewProp){
        super(props);
        this.state = {isOpen: false}
    }

    openMenu = ()=>{
        this.setState({isOpen: true});
    }

    handleClose = (newLangCode?: string)=>{
        if(newLangCode && newLangCode !== getLanguageCode()){
            setLanguageCode(newLangCode); 
            this.props.updateLanguage(newLangCode);
        }
        this.setState({isOpen: false});
    }

    render(){
        let currLangCode = getLanguageCode();
        let label = currLangCode !== LANG_CODE_EN ? 'Language' : '语言';
        let color = this.props.color||'white';

        return (
            <div className={color+' lang-view '+(this.props.className||'')}>
                <div className='language' ref={e=>{ this.anchorEl=e!}} onClick={ ()=>{ this.openMenu()} }>{label} <ArrowDropDown/></div>
                <Menu
                    id="simple-menu"
                    anchorEl={this.anchorEl!}
                    keepMounted
                    open={this.state.isOpen}
                    // onChange={evt=>{ debug(`change lang ${evt.currentTarget.value}`) }}
                    onClose={()=>{this.handleClose()}}
                >
                    <MenuItem onClick={()=>{this.handleClose(LANG_CODE_EN)}}>English {currLangCode==LANG_CODE_EN?<Check/>:null}</MenuItem>
                    <MenuItem onClick={()=>{this.handleClose(LANG_CODE_ZH_CN)}}>简体中文 {currLangCode==LANG_CODE_ZH_CN?<Check/>:null}</MenuItem>
                </Menu>
                <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
            </div>
        )
    }
}
