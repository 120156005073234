import React from 'react';
import './App.scss';
import {connect, batch} from 'react-redux';

import {WalletAppState} from './redux-store';
import {Component, Fragment} from 'react';

import {ScreenOverlayView} from './view/ScreenOverlayView'
import { Login } from './container/Login';
import { Signup } from './container/Signup';
import { getPathParm, PagePathState } from './redux-store/path/types';
import { Receive } from './container/Receive';
import { ManualTransferView } from './view/transfer/ManualTransferView';
import uuidv4 from 'uuid/v4';
import { t } from './i18n';
import { readLoginSession, readPersistData, saveLoginSession, getValidLoginSession, setLanguageCodeFromUserProfile,  } from './local-storage/local-storage';
import jwt_decode from 'jwt-decode';
import { LoginSessionState } from './redux-store/session';
import { setPointsRecord } from './redux-store/points';
import { retrieveUserProfile, retrieveQRCode, retrieveUnishellPoints, webInvokedUnishellApp } from './wallet-server-api/wallet-server-api';
import { getAjaxErrorMessage } from ".//wallet-server-api/ajax-common";
import { UserProfile, QRCodeResponse } from './api/user';
import { startAjaxCall, failedAjaxCall, succAjaxCall } from './redux-store/ajax-call/actions';
import { SettingsView } from './view/settings/SettingsView';
import { ScanTransferView } from './view/transfer/ScanTransferView';
import { TransferListView } from './view/transfer-list/TransferListView';
import {getCordova, info, } from './globals';
import {ConfirmDialogView} from './view/confirm-dialog/ConfirmDialogView';
import { upgradeApp } from './upgrade';
import { LostPassword } from './view/lost-password/LostPasswordView';
import { WalletPageView } from './view/wallet/WalletPageView';
import { AssetView } from './view/asset/AssetView';
import { MyProfileView } from './view/profile/MyProfileView';
import { PurchasePointsView } from './view/purchase-points/PurchasePointsView';

import { UnishellPayView, TUnishellPayCaller } from './view/unishell-pay/UnishellPayView';
import { calcRationClass, getTextWidthUsingCanvas, getViewportSize, replaceURLHistory, _allRatioClassNames } from './util/Util';
import { LanguageRecord } from './redux-store/language';
import { ClubTermsView } from './view/club-terms/ClubTerms';
import { PaymentOrderListView } from './view/payment-order-list/PaymentOrderListView';
import { CommissionView } from './view/commission/CommissionView';
import { SearchUsersView, SearchUsersViewFilter } from './view/search-users/SearchUsersView';
import { SearchProductsView } from './view/search-products/SearchProductsView';
import { ShoppingSearchProduct } from './shopping/search-product/search-product';
import { ShoppingProduct } from './shopping/product-detail/product-detail';
import { SearchNutritionist, SearchNutritionistFilter, SearchNutritionistProps } from './shopping/search-nutritionist/search-nutritionist';
import { EditNutritionistProfile } from './shopping/nutritionist/nutritionist-profile';
import { EditLicence } from './shopping/edit-licence/edit-licence';
import { SearchClientList } from './shopping/nutritionist/client-list';
import { CreateHealthAdvice } from './shopping/nutritionist/create-health-advice';
import { SearchHealthAdviceByMe, SearchHealthAdviceByMeProps } from './shopping/nutritionist/search-health-advice-by-me';
import { SearchClientHealthAdvice, SearchClientHealthAdviceFilter, SearchClientHealthAdviceState } from './shopping/my-health-advice/my-health-advice';
import { MyShoppingCart } from './shopping/shopping-cart/my-shopping-cart';
import { ShoppingCheckout } from './shopping/checkout/checkout';
import { ShoppingPayment } from './shopping/checkout/payment';
import { ShopOrderSearchFilter, ShoppingOrderList } from './shopping/my-orders/shopping-order-list';
import { gotoLoginPage, clearStackNGotoShoppingPath, ShoppingProductSearchProps } from './shopping/history/shopping-history';
import { MyNutritionist } from './shopping/my-nutritionist/my-nutritionist';
import { ProductType, ShopPaymentRequestData } from './shopping/ajax-data/ajax-data-types';

import debounce from 'debounce';
import { MyShopOrderCommission } from './shopping/commission/shop-order-commission';
import { OrderDashboard, OrderDashboardFilter, OrderDashboardProps } from './shopping/order-dashboard/order-dashboard';
import { PharmacyDashboard, PharmacyDashboardFilter } from './shopping/pharmacy-dashboard/pharmacy-dashboard';
import { ToolsView } from './view/tools/ToolsView';
import { Doc } from './api/docs';
import { SetMyNutritionistView } from './shopping/set-nutritionist/set-nutritionist';
import { MyBookMarks } from './view/bookmark/bookmark';
import { SwitchCurrency } from './shopping/switch-currency/switch-currency';
import { store } from '.';
import { NoticeDialog } from './view/NoticeDialog';
import { UpdateProdIndex } from './view/update-product-index/update-prod-index';
import { NutritionistProfilePortrait } from './shopping/nutritionist/profile/profile-portrait';
import { SendUsMessage } from './view/send-message/send-message';
import { PickupLocations } from './shopping/pickup-location/pickup-location';
import { UpgradeApp } from './view/send-message/upgrade/upgrade-app';
import { AllShopOrderCommission } from './shopping/commission-admin/shop-order-commission-admin';
import { DistributeCommission } from './shopping/commission-admin/distribute-commission';
import { PrivatePolicyView } from './view/privacy/privacy-policy';

const ScreenOverlay = connect(
    (appState:WalletAppState)=>({ajaxCallState: appState.ajaxCall})
)(ScreenOverlayView);


interface Props {
    pagePath:PagePathState, 
    language:LanguageRecord,
}

interface States {
    error?:any, 
    errorInfo?:any,
    innerWidth: number;
}

class AppView extends Component<Props, States>{
    reloadTranslationTableHandler: number|undefined;

    constructor(props: any){
        super(props);
        this.state = {innerWidth: window.innerWidth}
    }

    doUpgrade = (confirm:boolean)=>{
      if(confirm){
        upgradeApp('dummy');
      }
    }

    redirectMainPage (savedSessionState: LoginSessionState ){
        info(`login redirect`);
        const whatIsFor = t(`读取登录信息`);
        const uuid = uuidv4();

        let userProfile:UserProfile,qrCode:QRCodeResponse;
        let points = 0;

        // read latest user info
        Promise.all([retrieveUserProfile(savedSessionState.token!.accessToken), 
                     retrieveQRCode(savedSessionState.token!.accessToken),
                     retrieveUnishellPoints(savedSessionState.token!.accessToken)],
        ).then(([a1,a2,a3])=>{
            // throw Error('TEST failure');

            userProfile=a1;
            qrCode=a2;
            points=a3;

            setLanguageCodeFromUserProfile(userProfile.language);
            const sessionData: LoginSessionState = {token: savedSessionState.token!, profile:{...userProfile,...qrCode}}
            // save user info. TODO: check if both session are the same?
            saveLoginSession(sessionData);

            store.dispatch(succAjaxCall(whatIsFor, uuid));
            store.dispatch(setPointsRecord(points));
            clearStackNGotoShoppingPath('main/shopping/search', {});
        }).catch((error)=>{
            const errMsg = getAjaxErrorMessage(error);
            store.dispatch(failedAjaxCall(whatIsFor, uuid, errMsg));
            alert(errMsg);
            gotoLoginPage();
        });
        store.dispatch(startAjaxCall(whatIsFor, uuid));
    }

    render(){
        // measure screen width vs letter width ratio so we can do better response layout
        {
            const {ratioClass, vw,  mw, ratio} = calcRationClass();
            info(`ratio is ${vw}/${mw}= ${ratio} ratioClass=${ratioClass}`);
            
            const rootDiv = document.getElementById('root')!;
            if(!rootDiv.classList.contains(ratioClass)){ // set className in one shot
                let prev = rootDiv.className;
                info(`curr root class=${prev}`);
                for(let clsName of _allRatioClassNames){
                    /*
                    (?:^|\s) # Match the start of the string or any single whitespace character

                    MyClass  # The literal text for the classname to remove

                    (?!\S)   # Negative lookahead to verify the above is the whole classname
                            # Ensures there is no non-space character following
                            # (i.e. must be the end of the string or space)
                     */
                    let regexp = new RegExp(`(?:^|\\s)${clsName}(?!\\S)`, 'g');
                    prev = prev.replace(regexp , '');
                }                     
                prev +=` ${ratioClass}`;
                info(`new root class=${prev}`);
                rootDiv.className = prev;
            }
            // not working on mobile phones
            // let newFontSize:string|undefined;
            // if(ratio < 26*0.75){
            //     // reduce rem size
            //     newFontSize = (16*ratio/(26*0.75)).toFixed(3)+'px';
            //     const htmlTag = document.documentElement;
            //     if(htmlTag.style.fontSize !== newFontSize){
            //         htmlTag.style.fontSize = newFontSize;
            //     }
            // }
        }
        const pagePath = this.props.pagePath.currentPage;
        let parms = this.props.pagePath.parms || {};

        if(this.state.error){
            return <div style={{overflow:'auto'}}>
                    <h3>{t('系统错误')}</h3>
                    {this.state.error && this.state.error.toString()}
                    <br/>
                    <p>
                        {this.state.errorInfo && this.state.errorInfo.componentStack && this.state.errorInfo.componentStack}
                    </p>
                </div>
        }
        let loginSession = getValidLoginSession();
        if(!loginSession && pagePath !== 'login/register-user' && pagePath !== 'lost-password'){
            saveLoginSession(null);
            return <Login/>; 
        }

        /* desktop sample
          {"href":"http://localhost/merchantOrder/TXlvz1A5lab4x-N7iEtknLe7dP0",
            "ancestorOrigins":{},
            "origin":"http://localhost",
            "protocol":"http:","host":"localhost","hostname":"localhost","port":"",
            "pathname":"/merchantOrder/TXlvz1A5lab4x-N7iEtknLe7dP0",
            "search":"",
            "hash":""}
         */
        // TODO: check if user logged in
        // info(JSON.stringify(window.location));
        if(!getCordova()){
            // support testing merchant web page on desktop
            const regexp = /^[/]merchantOrder[/](.+)/;
            const matched = regexp.exec(window.location.pathname);
            if(matched && matched![1]){
                const orderCode = matched[1];
                webInvokedUnishellApp(orderCode)
                replaceURLHistory({}, 'Unishell', '/');
                return <UnishellPayView orderCode={orderCode} dispatch={store.dispatch} caller='merchant' key={uuidv4()}/>
            }
        }else{
            // @ts-ignore
            if(window.merchantOrder){
                // @ts-ignore
                const orderCode = window.merchantOrder.orderCode;
                // @ts-ignore
                const caller = window.merchantOrder.caller as TUnishellPayCaller;
                // @ts-ignore
                window.merchantOrder = undefined; 
                replaceURLHistory({}, 'Unishell', '/');
                return <UnishellPayView orderCode={orderCode} dispatch={store.dispatch} caller={caller} key={uuidv4()}/>
            }
        }


        if(pagePath === 'unishell-pay'){ // handle user scan payment qrcode
            let orderCode = this.props.pagePath.parms!.orderCode;
            let caller = this.props.pagePath.parms!.caller as TUnishellPayCaller;
            return <UnishellPayView orderCode={orderCode} dispatch={store.dispatch} caller={caller} key={uuidv4()}/>
        }

        let slideDir = 'left';
        if(this.props.pagePath.prevPage && this.props.pagePath.prevPage.startsWith(this.props.pagePath.currentPage)){
           slideDir = 'right';
        }

        let appPage:JSX.Element;

        switch(pagePath){
            case 'login': {
                let savedSession = readLoginSession();
                if(savedSession && savedSession.token){
                    const jwt = (jwt_decode(savedSession.token.accessToken)) as {exp:number};
                    if(Date.now()/1000+60*30 < jwt.exp){ // jwf not expires yet in 30 min, which is enough for clock shift
                        if(readPersistData(savedSession.profile!.username)){
                            // TODO: check jwt token is still valid on server side
                            setTimeout(() => {
                                info('goto main page w/ saved session')
                                this.redirectMainPage(savedSession!);
                            }, 0);
                            return <div className='redirect-message'>{t(`读取登录信息`)} ...</div>;
                        }
                    }
                }else{
                    info('no login session');
                }
                return <Login/>; 
            };
            case 'lost-password': appPage = <LostPassword key={uuidv4()}/>; break;
            case 'login/register-user': appPage = <Signup  key={uuidv4()}/>; break;
            case 'main': // deprecated
                appPage = <ShoppingSearchProduct key={uuidv4()}/>;
                break;
            case 'main/tools': appPage = <ToolsView  key={uuidv4()}/>; break;
            case 'main/wallet': appPage = <WalletPageView  key={uuidv4()}/>; break;
            case 'main/wallet/asset': appPage = <AssetView dispatch={store.dispatch}  key={uuidv4()}/>; break;
            case 'main/wallet/receive-transfer/receive': appPage = <Receive  key={uuidv4()}/>; break;
            case 'main/wallet/receive-transfer/scan-transfer': 
                  appPage = <ScanTransferView key={uuidv4()} 
                                            name={parms!['result'].name} 
                                            username={parms!['result'].username}
                                           userEmail={parms!['result'].userEmail} 
                                           dispatch={store.dispatch}
                         />; 
                  break;
            case 'main/wallet/receive-transfer/manual-transfer': appPage = <ManualTransferView  key={uuidv4()} dispatch={store.dispatch}/>; break;
            case 'main/wallet/transfer-list': 
            case 'main/wallet/receive-transfer/transfer-list':
                  appPage = <TransferListView key={uuidv4()} dispatch={store.dispatch}/>; break;
            case 'main/wallet/payment-order-list':
                  appPage = <PaymentOrderListView key={uuidv4()} dispatch={store.dispatch}/>; break;
              case 'main/wallet/purchase-points': 
                {
                    appPage = <PurchasePointsView key={uuidv4()} dispatch={store.dispatch}
                                               returnPath={parms.returnPath as string}
                                               returnPathLabel={parms.returnPathLabel as string}
                           />; 
                    break;
                }
            case 'main/my-profile': 
                    appPage = <MyProfileView/>; 
                    break;
            case 'main/my-profile/settings': 
                    appPage = <SettingsView dispatch={store.dispatch}/>; 
                    break;
            case 'main/tools/upgrade-app': 
                  appPage = <UpgradeApp />
                  break;
            case 'main/tools/terms': 
                  appPage = <ClubTermsView dispatch={store.dispatch}  key={uuidv4()}/>; 
                  break;
            case 'main/tools/privacy':
                  appPage = <PrivatePolicyView dispatch={store.dispatch}  key={uuidv4()}/>;
                  break;      
            case 'main/tools/send-message': 
                  appPage = <SendUsMessage key={uuidv4()}/>; 
                  break;
            case 'main/tools/pickup-location':
                  appPage = <PickupLocations key={uuidv4()}/>
                  break;      
            case 'main/tools/update-prod-index':
                  appPage = <UpdateProdIndex />; 
                  break;
            case 'main/tools/commission':
                 appPage = <CommissionView dispatch={store.dispatch} />; 
                 break;      
            case 'main/tools/search-users':
                 const {page, pageSize, nameKeyword, emailKeyword, selectedRoles} = parms as SearchUsersViewFilter;

                 appPage = <SearchUsersView dispatch={store.dispatch} page={page} pageSize={pageSize}
                                            nameKeyword={nameKeyword} emailKeyword={emailKeyword} selectedRoles={selectedRoles}
                           />; 
                 break;      
            case 'main/tools/search-products':
                 const currentProd = parms!['currentProd'] as Doc;
                 appPage = <SearchProductsView dispatch={store.dispatch} currentProd={currentProd} key={uuidv4()}/>; 
                 break;
            case 'main/tools/my-bookmarks':
                appPage = <MyBookMarks key={uuidv4()}/>
                break;
            case 'main/shopping/search':
            {
                const props = (parms as ShoppingProductSearchProps);
                // generate a unique key to force create ShoppingSearchProduct from scratch
                appPage = <ShoppingSearchProduct   initialSearchText={props.initialSearchText} 
                                                categoryName={props.categoryName} 
                                                page={props.page}
                                                pageSize={props.pageSize}
                                                key={uuidv4()}/>;
                break;
            }
            case 'main/shopping/product':
            {
                const productCode = parms!['productCode'] as string;
                appPage = <ShoppingProduct productCode={productCode} key={uuidv4()}/>
                break;
            }
            case 'main/tools/my-nutritionist':
            {
                appPage = <MyNutritionist key={uuidv4()}/>;
                break;
            }    
            case 'main/tools/my-health-advice':
            {
                let {state, text, page, pageSize} = parms as SearchClientHealthAdviceFilter;
                appPage = <SearchClientHealthAdvice key={uuidv4()} state={state} text={text} page={page} pageSize={pageSize} />;
                break;    
            }
            case 'main/tools/nutritionist-list':
            {    
                let {initialSearchText, initialGender, initialPage, initialPageSize} = parms as SearchNutritionistFilter;
                appPage = <SearchNutritionist key={uuidv4()} initialSearchText={initialSearchText}  initialGender={initialGender}
                                           initialPage={initialPage} initialPageSize={initialPageSize}/>;
                break;
            }    
            case 'main/tools/set-nutritionist':
            {
                const  nutritionistUsername = parms!['nutritionistUsername'] as string;
                appPage = <SetMyNutritionistView key={uuidv4()} nutritionistUsername={nutritionistUsername}/>;
                break;
            }    
            case 'main/shopping/nutritionist-profile':
            {
                const nutritionistId = (parms||{})!['nutritionistId'] as string;
                appPage = <NutritionistProfilePortrait key={uuidv4()} nutritionistId={nutritionistId}/>;
                break;
            }    
            case 'main/shopping/edit-nutritionist-profile':
            {
                const nutritionistId = (parms||{})!['nutritionistId'] as string;
                const editMode = (parms||{})!['editMode'] as boolean;
                appPage = <EditNutritionistProfile key={uuidv4()} nutritionistId={nutritionistId} editMode={editMode}/>;
                break;    
            }
            case 'main/shopping/edit-licence':
            {
                const nutritionistId = (parms||{})!['nutritionistId'] as string;
                appPage = <EditLicence key={uuidv4()} nutritionistId={nutritionistId}/>;
                break;    
            }
            case 'main/tools/client-list':
                {    
                    let initialSearchText = getPathParm(parms, 'initialSearchText', undefined as string|undefined);
                    let initialPage = getPathParm(parms, 'initialPage', undefined as number|undefined);
                    let initialPageSize = getPathParm(parms, 'initialPageSize', undefined as number|undefined);
    
                    // let {initialSearchText, initialPage, initialPageSize} = 
                    appPage = <SearchClientList key={uuidv4()} initialSearchText={initialSearchText}  
                                                initialPage={initialPage} initialPageSize={initialPageSize}/>;
                    break;
                }    
            case 'main/shopping/create-health-advice':
                {
                    const  clientUsername = parms!['clientUsername'] as string;
                    const  adviceType:'test'|'chanpin' = parms!['adviceType'] || 'chanpin';
                    appPage = <CreateHealthAdvice clientUsername={clientUsername} adviceType={adviceType} key={uuidv4()}/>
                    break;
                }
            case 'main/tools/health-advice-by-me':
                {
                    const {client, state, text} = parms! as SearchHealthAdviceByMeProps;
                    appPage = <SearchHealthAdviceByMe key={uuidv4()} text={text} state={state} client={client} />
                    break;
                }
            case 'main/shopping/my-shopping-cart':
                {
                    appPage = <MyShoppingCart key={uuidv4()}/>
                    break;
                }
            case 'main/shopping/checkout':
                {
                    appPage = <ShoppingCheckout key={uuidv4()}/>
                    break;
                }  
            case 'main/shopping/payment':
                {
                    const paymentData = parms!['paymentData'] as ShopPaymentRequestData;
                    appPage = <ShoppingPayment key={uuidv4()} paymentData={paymentData}/>;
                    break;
                }  
            case 'main/shopping/my-orders':
                {
                    let {page, pageSize, status, fromTime, toTime, text} = parms as ShopOrderSearchFilter;
                    appPage = <ShoppingOrderList key={uuidv4()} page={page} pageSize={pageSize} text={text}
                                                 status={status} fromTime={fromTime} toTime={toTime}/>;
                    break;
                }                      
            case 'main/shopping/order-dashboard':
            {
                let {
                    page,
                    pageSize,
                    orderStatus,
                    fromTime,
                    toTime,
                } = parms as OrderDashboardFilter;
                appPage = <OrderDashboard key={uuidv4()} page={page} pageSize={pageSize} 
                                          orderStatus={orderStatus} fromTime={fromTime} toTime={toTime}/>;
                break;
            }                      
            case 'main/shopping/pharmacy-dashboard':
                {
                    const {status, fromTime, toTime, page, pageSize} = parms as PharmacyDashboardFilter;
                    appPage = <PharmacyDashboard key={uuidv4()}
                                                status={status} fromTime={fromTime} toTime={toTime} page={page} pageSize={pageSize}
                              />;
                    break;
                }                      
            case 'main/shopping/my-commissions':
            {
                appPage = <MyShopOrderCommission key={uuidv4()}/>;
                break;
            }                      
            case 'main/shopping/all-commissions':
            {
                appPage = <AllShopOrderCommission key={uuidv4()}/>;
                break;
            }                      
            case 'main/shopping/distribute-commissions':
            {
                appPage = <DistributeCommission key={uuidv4()}/>;
                break;
            }                      

            case 'main/shopping/switch-currency':
            {
                appPage = <SwitchCurrency key={uuidv4()}/>;
                break;
            }                      

            default: appPage = <div>{t('错误页面')} Page:{pagePath}</div>; break;
        }

        return <Fragment>
                    { appPage }
                    <ScreenOverlay></ScreenOverlay>
                </Fragment>
    }

    componentDidMount(){   
        // @ts-ignore
        window.BOOTSTRAP_OK = true;
        // comment it out to support ios
        // if(getCordova()){
        //     checkUpgrade((hasNewRelease:boolean)=>{
        //         if(hasNewRelease){
        //             this.confirmDialogInstance!.show(t('软件更新'), <p>{t('检测有新版软件，是否升级？')}</p>)
        //         }
        //     },
        //     ()=>{
        //         // ignore error
        //     });
        // }

        // { // test AES256 encoding
        //   let key = genPaymentKey("123456789");
        //   let message = "hello";
        //   let encrypted = encryptPayment(message, key);
        //   info("test AES256 encryption", encrypted, key);
        // }
        if(!getCordova()){
            window.onresize = debounce((evt:UIEvent)=>{
                // innerHeight is not stable in mobile browser thus cause flicking
                if(Math.abs(window.innerWidth - this.state.innerWidth) >= 5){
                    this.setState({innerWidth: window.innerWidth});
                }
            }, 1000);    
        }
    }
    componentWillUnmount(){
        if(this.reloadTranslationTableHandler){
            clearInterval(this.reloadTranslationTableHandler);
        }
    }

    componentDidCatch(error:any, info:any){
        this.setState({error, errorInfo: info});
    }
}

export const App = connect(
    (state:WalletAppState)=>({pagePath: state.pagePath, language: state.language}),
)(AppView);
