import * as React from 'react';
import {Component, Fragment} from 'react';

import './CommissionView.scss';
import { readLastLoginEmail, readLoginSession, readPersistData } from '../../local-storage/local-storage';
import { getNaturalZenithLastMonthCommission, postCommission } from '../../wallet-server-api/wallet-server-api';
import { NoticeDialog } from '../NoticeDialog';
import { CommissionRequest } from '../../api/commission';
import { Paper, } from '@material-ui/core';
import { encryptPayment, genPaymentKey, pointingProdServer, RatioXXS } from '../../util/Util';
import { Toast } from '../Toast';
import { PasswordDialogView } from '../password-dialog/PasswordDialogView';
import { loadPrivateKey, signMessage } from '../../cypto/crypto';
import { BaseUnishellPageState, renderInitErrorUnishellPage, renderInitUnishellPage, renderUnishellPage } from '../../unishell-page-common/unishell-page-common';
import { gotoLoginPage, shoppingGoBack } from '../../shopping/history/shopping-history';
import { doAJAXCall } from '../../shopping/common/ajax-call';
import { loadExternContatnsIfNecessary } from '../../external-constants/extern-constants';
import { UButton } from '../../widget/button/ubutton';
import { PropertyListDataItem, PropertyListWidget } from '../../widget/property-list/property-list';

interface CommissionViewProps {
    dispatch: (x:any)=>void;
}
interface CommissionViewState extends BaseUnishellPageState{
    data?: CommissionRequest;
    page: number;
    pageSize: number;
}

function noTranslation(s:string){
    return s;
}
export class CommissionView extends Component<CommissionViewProps,CommissionViewState> {
    private errorDialog: NoticeDialog|undefined;
    private toast: Toast | undefined;
    private passwordDialog: PasswordDialogView | undefined;


    constructor(props: CommissionViewProps){
        super(props);
        this.state = {pageInitState: 'PAGE_INIT',pageSize: 10, page: -1};
    }

    submitCommission (password:string, confirmed:boolean) {
        if(!confirmed) return;

        const whatIsFor:string = noTranslation('发放咨询费');
        doAJAXCall({
            whatIsFor,
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken, username)=>{
                const localData = readPersistData(username);
                if(!localData){
                    this.toast!.sendMessage(noTranslation('数据出错。请联系Unishell客服'), 'error', false);
                    return;
                }
        
                const paymentKey = genPaymentKey(readLastLoginEmail()!.toLowerCase()+password);
                const keyManager = await loadPrivateKey(localData!.keyPair.priKey);
                const {signedMessage} = await signMessage(keyManager, this.state.data!);
                let encryptedData = encryptPayment(signedMessage, paymentKey);
                await postCommission(accessToken, 
                                    {keyId: localData.pkeyHash, data: encryptedData});    
                this.toast!.sendMessage(whatIsFor+noTranslation(' 成功'), 'success', false, 
                                    ()=>{ 
                                        shoppingGoBack();
                                    });
    
            },
            onError: (errorMessage)=>{
                
            }
        })
    }

    render() {
        if(this.state.pageInitState === 'PAGE_INIT'){
            return (
                <Fragment>
                { renderInitUnishellPage({
                      title:noTranslation('上个月咨询费'),
                      className: 'commission-page'                    
                })}
                <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                </Fragment>
            )
        }
        if(this.state.pageInitState === 'PAGE_LOAD_FAILED'){
            return (
                <Fragment>
                { renderInitErrorUnishellPage({
                      title:noTranslation('上个月咨询费'),
                      className: 'commission-page',
                      errorMessage: this.state.initErrorMessage!,
                      onTryAgain: ()=>{
                          this.init();
                      }                    
                })}
                <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                </Fragment>
            )
        }

        const content = (
            <Fragment>
                {this.renderCommissionList()}
                <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                <Toast close={true} message='' toastType='info' onClose={()=>{}} ref={(e)=>{this.toast=e!}} duration={2500}/>

                <PasswordDialogView confirmLabel={noTranslation('同意')} cancelLabel={noTranslation('取消')} 
                    onClose={this.submitCommission.bind(this)}
                    ref={e=>{this.passwordDialog=e!}} 
                />

            </Fragment>
        );

        return renderUnishellPage({
            title:noTranslation('上个月咨询费'),
            className: 'commission-page',                    
            content
        })
    }

    renderCommissionList() {
        if(!this.state.data) return null;

        if(!this.state.data.items){
            return <div className='notice'>{noTranslation('上个月无咨询费记录')}</div>
        }

        const loginSession = readLoginSession();
        if(!loginSession){
            gotoLoginPage();
            return;
        }




        let total = 0;
        this.state.data.items.forEach(x=> total+=x.points);
        let yearMonth = this.state.data.items[0].year+"-"+this.state.data.items[0].month;

        const totalPages = Math.ceil(this.state.data.items.length/this.state.pageSize);

        let headerFooter = <div className='commision-record-list-footer'>
                        {this.state.page!>0?
                            <span className='clickable' onClick={()=>{this.setState({page: this.state.page-1}) }}>{noTranslation('上页 ')}</span>:null
                        }
                        <span>{noTranslation('共 ')+this.state.data!.items.length+noTranslation(' 条')}</span>
                        <span>{(this.state.page!+1)}/{totalPages}</span>
                        {this.state.page<totalPages-1?
                            <span className='clickable' onClick={ ()=>{this.setState({page: this.state.page+1}) } }>{noTranslation('下页')}</span>:null
                        }
                    </div>

         
        let commissionRecordList = this.state.data!.items.filter((item, idx)=> idx>=this.state.page*this.state.pageSize && idx< (this.state.page+1)*this.state.pageSize)
                                   .map((item, idx)=>
            {
                const commissionRecordProps:PropertyListDataItem[] = [
                    {name: noTranslation('电邮'), value:item.emailId},
                    {name: noTranslation('姓名'), value:item.name},
                    {name: noTranslation('点数'), value:<strong style={{color:'rgb(199,0,0)'}}>{item.points}</strong>},
                ];
                return (
                    <div className={"commission-record "+(idx%2==0?"old":"even")}>
                        <PropertyListWidget list={commissionRecordProps}
                                            mergeColScreenLetterRatio={RatioXXS}
                                            showBorder={true}/>
                    </div>
                )
            })

        const updateRequestId = (event:React.ChangeEvent<HTMLInputElement>)=>{
            this.state.data!.requestId = event.target.value;
            this.setState({data: this.state.data});
        }    

        const briefTableProps:PropertyListDataItem[] = [
            {name: noTranslation('发方'), value: loginSession!.profile!.userEmail},
            {name: noTranslation('年月'), value: yearMonth},
            {
                name: noTranslation('ID'), 
                value: pointingProdServer()? 
                        <span>{loginSession!.profile!.userEmail}</span> : 
                        <Fragment>
                            <input type="text" value={this.state.data!.requestId} onChange={updateRequestId}></input>
                            <div>You can type a new unique request id to test (in UAT only)</div>
                        </Fragment>
            },
            {name: noTranslation('总点数'), value: <strong style={{color:'rgb(199,0,0)'}}>{total.toFixed(2)}</strong>},
        ];

        const submitButton = this.state.data!.items ? 
                                <div className='submit-button'>
                                    <UButton variant='contained' color='primary'
                                            onClick={()=>{
                                                if(!this.state.data!.requestId){
                                                    alert('缺报告ID');
                                                    return;
                                                }                                    
                                                this.passwordDialog!.show(<Fragment>{noTranslation(`确认发放咨询费`)}</Fragment>);
                                            }}
                                    >
                                        {noTranslation('提交')}
                                    </UButton>
                                </div>: null;

        return (
            <div className='content'>
                <PropertyListWidget list={briefTableProps} className='brief-table' showBorder={true} 
                                    mergeColScreenLetterRatio={RatioXXS}/>

                {headerFooter}
                {commissionRecordList}
                {headerFooter}
                {submitButton}
            </div>
        )
    }

    init(){
        this.loadData();
    }

    componentDidMount(){
        this.init();
    }

    loadData = ()=>{
        let whatIsFor:string = noTranslation('读上个月咨询费');
        doAJAXCall({
            whatIsFor,
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken)=>{
                await loadExternContatnsIfNecessary();
                const data = await getNaturalZenithLastMonthCommission(accessToken);
                this.setState({data, page: 0, pageInitState: 'PAGE_LOAD_SUCCESS'});
            },
            onError: (errorMsg)=>{
                this.setState({pageInitState:'PAGE_LOAD_FAILED', initErrorMessage: errorMsg});
            }
        })
    }

}