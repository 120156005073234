// Generated by IcedCoffeeScript 108.0.11
(function () {
  var C,
      Compressed,
      CompressionParser,
      Packet,
      asymmetric,
      bzipDeflate,
      bzip_inflate,
      fake_zip_inflate,
      fix_zip_deflate,
      iced,
      uint_to_buffer,
      zlib,
      __iced_k,
      __iced_k_noop,
      __hasProp = {}.hasOwnProperty,
      __extends = function __extends(child, parent) {
    for (var key in parent) {
      if (__hasProp.call(parent, key)) child[key] = parent[key];
    }

    function ctor() {
      this.constructor = child;
    }

    ctor.prototype = parent.prototype;
    child.prototype = new ctor();
    child.__super__ = parent.prototype;
    return child;
  };

  iced = require('iced-runtime');

  __iced_k = __iced_k_noop = function __iced_k_noop() {};

  Packet = require('./base').Packet;
  C = require('../../const').openpgp;
  asymmetric = require('../../asymmetric');
  zlib = require('zlib');
  uint_to_buffer = require('../../util').uint_to_buffer;
  bzipDeflate = require('bzip-deflate');

  fake_zip_inflate = function fake_zip_inflate(buf, cb) {
    var bufs, call_end, err, inflater, ___iced_passed_deferral, __iced_deferrals, __iced_k;

    __iced_k = __iced_k_noop;
    ___iced_passed_deferral = iced.findDeferral(arguments);
    buf = Buffer.concat([Buffer.from([0x78, 0x9c]), buf]);
    inflater = zlib.createInflate({
      flush: zlib.Z_FULL_FLUSH
    });
    bufs = [];

    call_end = function call_end(err) {
      var ret, tmp;

      if ((tmp = cb) != null) {
        if (err != null && err.code === "Z_BUF_ERROR") {
          err = null;
        }

        cb = null;

        if (err != null) {
          ret = null;
        } else {
          ret = Buffer.concat(bufs);
        }

        return tmp(err, ret);
      }
    };

    inflater.on('readable', function () {
      var read_buf;
      read_buf = inflater.read();

      if (read_buf != null) {
        return bufs.push(read_buf);
      }
    });
    inflater.on('end', function () {
      return call_end(null);
    });
    inflater.on('error', function (e) {
      return call_end(e);
    });
    (function (_this) {
      return function (__iced_k) {
        __iced_deferrals = new iced.Deferrals(__iced_k, {
          parent: ___iced_passed_deferral,
          filename: "/Users/max/src/keybase/kbpgp/src/openpgp/packet/compressed.iced"
        });
        inflater.write(buf, __iced_deferrals.defer({
          assign_fn: function () {
            return function () {
              return err = arguments[0];
            };
          }(),
          lineno: 53
        }));

        __iced_deferrals._fulfill();
      };
    })(this)(function (_this) {
      return function () {
        (function (__iced_k) {
          if (typeof err === "undefined" || err === null) {
            (function (__iced_k) {
              __iced_deferrals = new iced.Deferrals(__iced_k, {
                parent: ___iced_passed_deferral,
                filename: "/Users/max/src/keybase/kbpgp/src/openpgp/packet/compressed.iced"
              });
              inflater.end(err);

              __iced_deferrals._fulfill();
            })(__iced_k);
          } else {
            return __iced_k();
          }
        })(function () {
          if (typeof err !== "undefined" && err !== null) {
            return call_end(err);
          }
        });
      };
    }(this));
  };

  fix_zip_deflate = function fix_zip_deflate(buf, cb) {
    var err, ret, ___iced_passed_deferral, __iced_deferrals, __iced_k;

    __iced_k = __iced_k_noop;
    ___iced_passed_deferral = iced.findDeferral(arguments);
    (function (_this) {
      return function (__iced_k) {
        __iced_deferrals = new iced.Deferrals(__iced_k, {
          parent: ___iced_passed_deferral,
          filename: "/Users/max/src/keybase/kbpgp/src/openpgp/packet/compressed.iced"
        });
        zlib.deflate(buf, __iced_deferrals.defer({
          assign_fn: function () {
            return function () {
              err = arguments[0];
              return ret = arguments[1];
            };
          }(),
          lineno: 62
        }));

        __iced_deferrals._fulfill();
      };
    })(this)(function (_this) {
      return function () {
        return cb(err, ret);
      };
    }(this));
  };

  bzip_inflate = function bzip_inflate(buf, cb) {
    var e, err, ret;
    err = null;

    try {
      ret = bzipDeflate(buf);
    } catch (_error) {
      e = _error;
      err = e;
    }

    return cb(err, ret);
  };

  Compressed = function (_super) {
    __extends(Compressed, _super);

    function Compressed(_arg) {
      this.algo = _arg.algo, this.compressed = _arg.compressed, this.inflated = _arg.inflated;
    }

    Compressed.parse = function (slice) {
      return new CompressionParser(slice).parse();
    };

    Compressed.prototype.inflate = function (cb) {
      var err, ret, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      err = ret = null;
      (function (_this) {
        return function (__iced_k) {
          switch (_this.algo) {
            case C.compression.none:
              return __iced_k(ret = _this.compressed);

            case C.compression.zlib:
              (function (__iced_k) {
                __iced_deferrals = new iced.Deferrals(__iced_k, {
                  parent: ___iced_passed_deferral,
                  filename: "/Users/max/src/keybase/kbpgp/src/openpgp/packet/compressed.iced",
                  funcname: "Compressed.inflate"
                });
                zlib.inflate(_this.compressed, __iced_deferrals.defer({
                  assign_fn: function () {
                    return function () {
                      err = arguments[0];
                      return ret = arguments[1];
                    };
                  }(),
                  lineno: 95
                }));

                __iced_deferrals._fulfill();
              })(__iced_k);

              break;

            case C.compression.zip:
              (function (__iced_k) {
                __iced_deferrals = new iced.Deferrals(__iced_k, {
                  parent: ___iced_passed_deferral,
                  filename: "/Users/max/src/keybase/kbpgp/src/openpgp/packet/compressed.iced",
                  funcname: "Compressed.inflate"
                });
                fake_zip_inflate(_this.compressed, __iced_deferrals.defer({
                  assign_fn: function () {
                    return function () {
                      err = arguments[0];
                      return ret = arguments[1];
                    };
                  }(),
                  lineno: 97
                }));

                __iced_deferrals._fulfill();
              })(__iced_k);

              break;

            case C.compression.bzip:
              (function (__iced_k) {
                __iced_deferrals = new iced.Deferrals(__iced_k, {
                  parent: ___iced_passed_deferral,
                  filename: "/Users/max/src/keybase/kbpgp/src/openpgp/packet/compressed.iced",
                  funcname: "Compressed.inflate"
                });
                bzip_inflate(_this.compressed, __iced_deferrals.defer({
                  assign_fn: function () {
                    return function () {
                      err = arguments[0];
                      return ret = arguments[1];
                    };
                  }(),
                  lineno: 99
                }));

                __iced_deferrals._fulfill();
              })(__iced_k);

              break;

            default:
              return __iced_k(err = new Error("no known inflation -- algo: " + _this.algo));
          }
        };
      })(this)(function (_this) {
        return function () {
          return cb(err, ret);
        };
      }(this));
    };

    Compressed.prototype.deflate = function (cb) {
      var err, ret, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      err = ret = null;
      (function (_this) {
        return function (__iced_k) {
          switch (_this.algo) {
            case C.compression.none:
              return __iced_k(ret = _this.inflated);

            case C.compression.zlib:
              (function (__iced_k) {
                __iced_deferrals = new iced.Deferrals(__iced_k, {
                  parent: ___iced_passed_deferral,
                  filename: "/Users/max/src/keybase/kbpgp/src/openpgp/packet/compressed.iced",
                  funcname: "Compressed.deflate"
                });
                zlib.deflate(_this.inflated, __iced_deferrals.defer({
                  assign_fn: function () {
                    return function () {
                      err = arguments[0];
                      return ret = arguments[1];
                    };
                  }(),
                  lineno: 111
                }));

                __iced_deferrals._fulfill();
              })(__iced_k);

              break;

            case C.compression.zip:
              (function (__iced_k) {
                __iced_deferrals = new iced.Deferrals(__iced_k, {
                  parent: ___iced_passed_deferral,
                  filename: "/Users/max/src/keybase/kbpgp/src/openpgp/packet/compressed.iced",
                  funcname: "Compressed.deflate"
                });
                fake_zip_deflate(_this.inflated, __iced_deferrals.defer({
                  assign_fn: function () {
                    return function () {
                      err = arguments[0];
                      return ret = arguments[1];
                    };
                  }(),
                  lineno: 113
                }));

                __iced_deferrals._fulfill();
              })(__iced_k);

              break;

            default:
              return __iced_k(err = new Error("no known deflation -- algo: " + _this.algo));
          }
        };
      })(this)(function (_this) {
        return function () {
          return cb(err, ret);
        };
      }(this));
    };

    Compressed.prototype.write_unframed = function (cb) {
      var bufs, err, ret, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      err = ret = null;
      (function (_this) {
        return function (__iced_k) {
          __iced_deferrals = new iced.Deferrals(__iced_k, {
            parent: ___iced_passed_deferral,
            filename: "/Users/max/src/keybase/kbpgp/src/openpgp/packet/compressed.iced",
            funcname: "Compressed.write_unframed"
          });

          _this.deflate(__iced_deferrals.defer({
            assign_fn: function (__slot_1) {
              return function () {
                err = arguments[0];
                return __slot_1.compressed = arguments[1];
              };
            }(_this),
            lineno: 122
          }));

          __iced_deferrals._fulfill();
        };
      })(this)(function (_this) {
        return function () {
          if (err == null) {
            bufs = [uint_to_buffer(8, _this.algo), _this.compressed];
            ret = Buffer.concat(bufs);
          }

          return cb(err, ret);
        };
      }(this));
    };

    Compressed.prototype.write = function (cb) {
      var err, ret, unframed, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      err = ret = null;
      (function (_this) {
        return function (__iced_k) {
          __iced_deferrals = new iced.Deferrals(__iced_k, {
            parent: ___iced_passed_deferral,
            filename: "/Users/max/src/keybase/kbpgp/src/openpgp/packet/compressed.iced",
            funcname: "Compressed.write"
          });

          _this.write_unframed(__iced_deferrals.defer({
            assign_fn: function () {
              return function () {
                err = arguments[0];
                return unframed = arguments[1];
              };
            }(),
            lineno: 132
          }));

          __iced_deferrals._fulfill();
        };
      })(this)(function (_this) {
        return function () {
          if (err == null) {
            ret = _this.frame_packet(C.packet_tags.compressed, unframed);
          }

          return cb(err, ret);
        };
      }(this));
    };

    return Compressed;
  }(Packet);

  CompressionParser = function () {
    function CompressionParser(slice) {
      this.slice = slice;
    }

    CompressionParser.prototype.parse = function () {
      var algo, compressed;
      algo = this.slice.read_uint8();
      compressed = this.slice.consume_rest_to_buffer();
      return new Compressed({
        algo: algo,
        compressed: compressed
      });
    };

    return CompressionParser;
  }();

  exports.Compressed = Compressed;
}).call(this);