import * as React from 'react';
import {Component, Fragment} from 'react';
import './password-dialog.scss';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField, Button } from '@material-ui/core';
import { t } from '../../i18n';

interface MyProps {
    confirmLabel: string;
    cancelLabel: string;
    onClose: (password: string, confirm: boolean)=>void;
}

interface MyState {
    title?: string | JSX.Element;
    open: boolean;
    password: string;
    passwordError?: string;
    onClose?: (password: string, confirm: boolean)=>void
}

export class PasswordDialogView extends Component<MyProps, MyState>{
    constructor(props: MyProps){
        super(props);
        this.state = {password:'', open: false}
    }

    show(title:string | JSX.Element, onClose?: (password: string, confirm: boolean)=>void){
        this.setState({title, password:'', open: true, onClose});
    }

    validatePassword = (password:string)=>{
        return password ? undefined: t('输入登录密码');
    }


    onClose(confirm: boolean){
        let password = this.state.password;
        let passwordError = this.validatePassword(password);
        if(passwordError){
            this.setState({passwordError});
        }
        if(confirm && passwordError){
            return;
        }

        const onClose = this.state.onClose || this.props.onClose;
        this.setState({password:'', open: false, passwordError, onClose: undefined}, ()=>{
            onClose(password, confirm);
        });
    }

    updatePassword = (value:string)=>{
        this.setState({password:value, passwordError: !!value ? undefined: t('输入登录密码')});
    }

    render(){
        let error = this.state.passwordError? <div className='input-error-message'>{this.state.passwordError}</div>: null;
        return (

            <Dialog aria-labelledby="password-dialog-title" scroll='paper' maxWidth='md'
                    open={this.state.open} className='password-dialog'>
                <DialogTitle id="password-dialog-title">{this.state.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText className='error-dialog-body'>
                        <div className="password-input-view">
                            <TextField value={this.state.password} spellCheck={false} label={t('登录密码')} type='password'
                                    onChange={(e)=>{ this.updatePassword(e.target.value) } }
                                    className='password-input' required={true}
                                    onKeyDown={evt=>{
                                        if(evt.key === 'Enter'){
                                            evt.preventDefault();
                                            this.onClose(true);
                                        }
                                    }}
                            />
                            {error}
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button size='medium' onClick={()=>{ this.onClose(false) }} color='default'><strong>{this.props.cancelLabel}</strong></Button>
                    <Button size='medium' onClick={()=>{ this.onClose(true) }} color='primary'><strong>{this.props.confirmLabel}</strong></Button>
                </DialogActions>
            </Dialog>

        )
    }
}