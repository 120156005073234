import * as React from 'react';
import {Component, Fragment} from 'react';

import './MyProfileView.scss';
import {t} from '../../i18n';
import { ReactReduxContext } from 'react-redux';


import { readLoginSession, saveLoginSession } from '../../local-storage/local-storage';
import { Toast } from '../Toast';
import {asyncDelay, pointingProdServer} from '../../util/Util';
import { hasRole, hasRoleAdmin, ROLE_NUTRITIONIST } from '../../api/user';
import { clearShoppingPathRecordStack, gotoLoginPage, gotoShoppingPathNSavePrevPath } from '../../shopping/history/shopping-history';
import { BaseUnishellPageState, renderInitUnishellPage, renderUnishellPage } from '../../unishell-page-common/unishell-page-common';
import { loadExternContatnsIfNecessary } from '../../external-constants/extern-constants';
import { ListMenu } from '../list-menu/list-menu';
import { UButton } from '../../widget/button/ubutton';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { NoticeDialog } from '../NoticeDialog';
import { doAJAXCall } from '../../shopping/common/ajax-call';
import { unregister } from '../../wallet-server-api/wallet-server-api';

interface State {
    showDeleteAccountDialog?: boolean;
}
export class MyProfileView extends Component<{},BaseUnishellPageState & State> {
    private toast: Toast | undefined;
    private errorDialog: NoticeDialog|undefined;

    constructor(props: {}){
        super(props);
        this.state = {pageInitState:'PAGE_INIT'}
    }

    toImplement = ()=>{
        this.toast!.sendMessage(t('新功能即将上线'), 'info')
    }

    render() {
        if(this.state.pageInitState === 'PAGE_INIT'){
            return <Fragment>
                {
                    renderInitUnishellPage({
                        title: t('我的账户'),
                        className: 'my-profile-page',
                        message: t('初始化...')    
                    })
                }
            </Fragment>
        }

        return <ReactReduxContext.Consumer>
            { ({store}) => {
                const logout = ()=>{
                    gotoLoginPage();
                }
                const loginSession = readLoginSession()!;
                if(!loginSession) logout();
                
                const userEmail = loginSession.profile!.userEmail;
                const permittedUATEmails = ["shop@gnginc.com", "handong.wang.aurora@gmail.com", "davxie@gmail.com"];
                const isNaturalZenith = pointingProdServer() ? 
                                        "shop@gnginc.com" === userEmail.trim() : 
                                        permittedUATEmails.some(x=>x===userEmail);
                const isAdmin = hasRoleAdmin(loginSession.profile!.roles);

                const isNutritionist = hasRole(loginSession.profile!.roles, ROLE_NUTRITIONIST);

                const gotoSettings = ()=>{
                    gotoShoppingPathNSavePrevPath('main/my-profile/settings');
                }
                const gotoSwitchCurrency = ()=>{
                    gotoShoppingPathNSavePrevPath('main/shopping/switch-currency');
                }
                const gotoTerms = ()=>{
                    gotoShoppingPathNSavePrevPath('main/tools/terms');
                }
                const gotoPrivacy = ()=>{
                    gotoShoppingPathNSavePrevPath('main/tools/privacy');
                }

                const content = (
                    <Fragment>
                        <div className='my-profile-menu-list'>
                            <ListMenu label={t('账户信息')} onClick={gotoSettings}/>
                            <ListMenu label={t('计价货币')} onClick={gotoSwitchCurrency}/>
                            <ListMenu label={t('用户规则')} onClick={gotoTerms}/>
                            <ListMenu label={t('隐私政策')} onClick={gotoPrivacy}/>
                            {isNutritionist ?
                                <ListMenu label={t('执业信息')} 
                                            onClick={()=>{
                                            gotoShoppingPathNSavePrevPath('main/shopping/nutritionist-profile');
                                            }}/>: 
                                null
                            }
                            <ListMenu label=' ' isActionButton onClick={()=>{}}/>
                            <ListMenu label={t('注销账号')} isActionButton 
                                      onClick={()=>{ this.setState({showDeleteAccountDialog: true}) }}/>

                            <UButton variant='outlined' color='primary' size='medium' className='logout-button' onClick={logout} >
                                    {t('登出')}
                            </UButton>
                        </div>
                        {this.renderDeleteAccountDialog()}
                        <NoticeDialog open={false} ref={e=> {this.errorDialog=e!}} title='' message=''/>
                        <Toast close={true} duration={2500} message='' ref={e=>{this.toast=e!}} toastType='info'/>
                    </Fragment>
                );

                return renderUnishellPage(
                    {
                        title: t('我的账户'),
                        className: 'my-profile-page',
                        content
                    }
                )
            }}
        </ReactReduxContext.Consumer>
    }

    unregisterAccount(){
        this.setState({showDeleteAccountDialog: false}, ()=>{
            doAJAXCall({
                whatIsFor: t('注销账号'),
                errorDialog: this.errorDialog!,
                ajaxCall: async (accessToken)=>{
                    // await asyncDelay('abc', 500); // for testing ui
                    await unregister(accessToken);
                    saveLoginSession(null);
                    clearShoppingPathRecordStack();                
                    this.toast!.sendMessage(t('账号已注销'), 'warning', false, ()=>{
                        gotoLoginPage();
                    });
                }
            })    
        })
    }

    renderDeleteAccountDialog():React.ReactNode {
        const {showDeleteAccountDialog} = this.state;

        return <Dialog open={!!showDeleteAccountDialog} maxWidth='xl' className='delete-account-dialog'>
                    <DialogContent>
                        <div dangerouslySetInnerHTML={{__html: t('delete_account_msg')}} />
                    </DialogContent>
                    <DialogActions>
                        <UButton variant='contained' color='danger'
                                 onClick={()=>{ this.unregisterAccount() }}
                        >{t('同意')}</UButton>
                        <UButton variant='contained' color='primary'
                                 onClick={()=>{ this.setState({showDeleteAccountDialog: false})}}
                        >{t('取消')}</UButton>
                    </DialogActions>
               </Dialog>
    }

    async componentDidMount(){
        await loadExternContatnsIfNecessary();
        this.setState({pageInitState:'PAGE_LOAD_SUCCESS'});
    }
}