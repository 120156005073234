import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import * as React from 'react';
import {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import { PagedResponse } from '../../api/transaction';
import { t } from '../../i18n';
import { WalletAppState } from '../../redux-store';
import { formatDate, RatioXXS, scrollBodyToTop } from '../../util/Util';
import { NoticeDialog } from '../../view/NoticeDialog';
import { AddressInfo2Str, getHealthAdviceProductType, NutritionistClient, NutritionistHealthAdvice } from '../ajax-data/ajax-data-types';
import { searchHealthAdviceByMe, cancelHealthAdvice, getHealthAdviceStatuOptions, NutritionistPrescriptionFilter } from '../ajax-data/shopping-server-api';
import { doAJAXCall } from '../common/ajax-call';
import { Pagination } from '../common/render-pagination';
import { SearchHealthAdviceFilter, SearchHealthAdviceForm } from '../common/search-health-advice-form';
import { BaseShoppingPageProps, } from '../common/shopping-page-common';
import './search-health-advice-by-me.scss';
import * as _ from 'lodash';
import { Toast } from '../../view/Toast';
import { BaseUnishellPageState, renderInitErrorUnishellPage, renderInitUnishellPage, renderUnishellPage } from '../../unishell-page-common/unishell-page-common';
import { loadExternContatnsIfNecessary } from '../../external-constants/extern-constants';
import { gotoShoppingPathNSavePrevPath } from '../history/shopping-history';
import { PropertyListDataItem, PropertyListWidget } from '../../widget/property-list/property-list';
import { SimplePhoneNumber } from '../../widget/phone-number/phone-number';
import { SimpleEmail } from '../../widget/email/email';
import { UButton } from '../../widget/button/ubutton';
import { HTMLDialog } from '../../wallet-server-api/html-dialog/html-dialog';
import { readLabTestInstruction } from '../../wallet-server-api/wallet-server-api';

export interface SearchHealthAdviceByMeProps extends BaseShoppingPageProps, SearchHealthAdviceFilter {
    client?: NutritionistClient
}

interface SearchHealthAdviceByMeState extends BaseUnishellPageState, SearchHealthAdviceFilter{
    statusOptions?:{key:string, text:string}[];
    page: number;
    pageSize: number;
    searchResult?:PagedResponse<NutritionistHealthAdvice>;
}

class SearchHealthAdviceByMeView extends Component<SearchHealthAdviceByMeProps, SearchHealthAdviceByMeState>{
    private errorDialog:NoticeDialog|undefined;
    private searchFilterForm: SearchHealthAdviceForm|undefined;
    private toast: Toast | undefined;

    constructor(props: SearchHealthAdviceByMeProps){
        super(props);
        const {text, state } = this.props;
        this.state = {pageInitState:'PAGE_INIT', page:0, pageSize:10}
    }

    getTitle(){
        return t('发出的健康建议');
    }

    render(){
        if(this.state.pageInitState === 'PAGE_INIT'){
            return <React.Fragment>
                        {renderInitUnishellPage(
                            {
                                title: this.getTitle(), 
                                className:'shopping-health-advice-by-me',
                                message:t('初始化...')
                            })
                        }
                        <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                    </React.Fragment>
        }
        if(this.state.pageInitState === 'PAGE_LOAD_FAILED'){
            return <React.Fragment>
                        {renderInitErrorUnishellPage(
                            {
                                title: this.getTitle(), 
                                className:'shopping-health-advice-by-me',
                                errorMessage: this.state.initErrorMessage||t('初始化失败'),
                                onTryAgain: ()=>{ this.initializeAgain();    }
                            })
                        }
                        <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                        <Toast close={true} duration={2500} message='' ref={e=>{this.toast=e!}} toastType='info'/>
                    </React.Fragment>
        }
        // loading data succeeded
        return renderUnishellPage({
            title: this.getTitle(), 
            className:'shopping-health-advice-by-me',
            underTopHeader: this.renderSearchForm(),
            content: (
                <React.Fragment>
                    {this.renderSucceedContent()}
                    <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                    <Toast close={true} duration={2500} message='' ref={e=>{this.toast=e!}} toastType='info'/>
                </React.Fragment>
            )
        })
    }

    cancelHealthAdvice(advice:NutritionistHealthAdvice, idx:number){

        doAJAXCall(
            {
                whatIsFor:t(`取消健康建议书`),
                errorDialog: this.errorDialog!,
                ajaxCall: async (accessToken:string)=>{
                    await cancelHealthAdvice(accessToken, advice.prescriptionCode!);
                    advice.state = 'VOIDED';
                    this.forceUpdate();
                }
            }
        )
    }

    renderSearchForm(){
        return (
            <div className='shopping-health-advice-by-me-main-pane-search-form-wrapper'>
                <SearchHealthAdviceForm statusOptions={this.state.statusOptions!}
                                        text={this.state.text} state={this.state.state}
                                        ref={e => { this.searchFilterForm = e! }}
                                        doSearch={(filters)=>{
                                            this.loadPaginationData(this.state.page, this.state.pageSize)
                                        } }
                />
            </div>)
    }

    renderSucceedContent():JSX.Element {
        const {client} = this.props;

        return (
            <div className='shopping-health-advice-by-me-main-pane'>
                {client? 
                     <div className='client-name'>{t('客户')} {client.client}</div>
                     :null
                }
                <Pagination data={this.state.searchResult!} page={this.state.page} pageSize={this.state.pageSize} 
                            loadData={this.loadPaginationData}
                            changePageSize={(newPageSize, newPage)=>{
                                this.loadPaginationData(newPage, newPageSize);
                            }}
                >
                { this.renderHealthAdviceList() }
                </Pagination>

            </div>
        )
    }

    renderHealthAdviceList():JSX.Element {
        return (
            <div className='health-advice-list'>
            {
                this.state.searchResult!.content.map((x:NutritionistHealthAdvice, idx)=>{
                    const client = x.client;
                    const expire = new Date(x.expiration);
                    const created = new Date(x.createdDtm);
                    const products = x.item||[];
                    const productType = getHealthAdviceProductType(x);

                    let option = this.state.statusOptions!.find(option=>option.key === x.state);
                    let status = option? option.text : x.state;

                    const adviceData:PropertyListDataItem[] = [];

                    adviceData.push({name:t('编号'), value: x.prescriptionCode});
                    adviceData.push({name:'类型', value:productType==='test'? t('健康检测'): t('一般产品')});
                    if(productType === 'test'){
                        adviceData.push({
                            name: '',
                            value: <HTMLDialog 
                                        title={t('健康检测操作指南')}
                                        errorDialog={this.errorDialog!}
                                        loadHTMLFn={readLabTestInstruction}
                                    />,
                            mergeCol: true,        
                        });
                    };
                    adviceData.push({name:t('标题'), value: x.title});
                    adviceData.push({name:t('描述'), value: x.description});
                    adviceData.push({name:t('状态'), value: status});
                    adviceData.push({name:t('创建日期'), value: formatDate(created)});
                    adviceData.push({name:t('有效日期'), value: formatDate(expire)});

                    adviceData.push({
                        name:t('姓名'), 
                        value:  <div>
                                    {client.client}
                                    {client.active?
                                    <div className='create-advice-for-the-client'>
                                        <a href='#' onClick={evt=>{
                                                evt.stopPropagation();
                                                evt.preventDefault();
                                                gotoShoppingPathNSavePrevPath('main/shopping/create-health-advice', 
                                                                              {clientUsername: x.clientUsername,
                                                                               adviceType:'product'})
                                            }}>{t('开新健康建议书')}</a>

                                        </div>: null
                                    }
                                </div>
                    });
                    adviceData.push({name:t('电邮'), value: <SimpleEmail email={client.email}/>});
                    adviceData.push({name:t('电话'), value: <SimplePhoneNumber phoneNumber={client.phoneNumber}/>});
                    adviceData.push({name:t('地址'), value: AddressInfo2Str(client.address)});
                    adviceData.push({name:t('备注'), value: client.notes||''});

                    adviceData.push({
                        name:t('产品'), 
                        mergeCol: true,
                        value: <Fragment>
                                {
                                    products.map((p, idx)=>{
                                        return <div>
                                                       <div className='product-name'
                                                            onClick={()=>{
                                                                gotoShoppingPathNSavePrevPath(`main/shopping/product`, {productCode: p.productCode})
                                                            }}
                                                       >
                                                           {t(p.productName)} ({p.count})
                                                       </div>
                                                       <ol>
                                                        {p.keyIngredient.map((v,idx2)=>{
                                                           return  <li className='key-ingredients-paragraph'>{`${v.cName}/${v.name}/${v.cpName} (${v.amount})`}</li>;
                                                         })
                                                        }
                                                       </ol>
                                               </div>
                                    })
                                }
                        </Fragment>
                    });
                    if(x.state === 'READY'){
                        adviceData.push({
                            name:'', 
                            mergeCol: true,
                            valueClassName:'cancel-button',
                            value: <UButton variant='contained' color='alert' size='small'
                                            onClick={()=>{ this.cancelHealthAdvice(x, idx) }}
                                    >
                                        {t('取消')}
                                    </UButton>
                        });    
                    }

                    return <PropertyListWidget list={adviceData} 
                                               className='health-advice-readonly' 
                                               showBorder={true}
                                               mergeColScreenLetterRatio={RatioXXS}
                                               />
                })
            }
            </div>
        )
    }

    searchHealthAdvice(page:number, pageSize:number, filters: NutritionistPrescriptionFilter){
        doAJAXCall({
            whatIsFor:t(`下载数据`),
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken)=>{
                let searchResult = await searchHealthAdviceByMe(accessToken, page, pageSize, filters);
                this.setState({searchResult, page: searchResult.page, pageSize:searchResult.size});                                                
            },
            onError: (errorMessage)=>{
            }
        })

    }

    loadPaginationData = (page:number, pageSize:number) => {
        const {text, state} = this.searchFilterForm!.getSearchHealthAdviceFilter();
        const {client} = this.props;

        this.searchHealthAdvice(page, pageSize, {text, state, clientUsername: client? client.clientUsername: undefined})
    }

    initializeAgain(){
        this.setState({pageInitState:'PAGE_INIT', initErrorMessage:undefined},
                      ()=>{
                          this.loadData();
                      })
    }


    componentDidMount(){
        this.loadData();
    }
    
    componentDidUpdate(prevProps:any, prevState:SearchHealthAdviceByMeState){
        if(prevState.page !== this.state.page){
            scrollBodyToTop();
        }
    }

    loadData(){
        doAJAXCall({
            whatIsFor:t(`下载数据`),
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken)=>{
                await loadExternContatnsIfNecessary();

                let statusOptions = getHealthAdviceStatuOptions(accessToken, this.props.languageCode!);
                let searchResult = await searchHealthAdviceByMe(accessToken, this.state.page, this.state.pageSize, 
                                                                {
                                                                    text: this.props.text,
                                                                    state: this.props.state,
                                                                    clientUsername: this.props.client? this.props.client.clientUsername : undefined
                                                                });
                this.setState({pageInitState:'PAGE_LOAD_SUCCESS', statusOptions, searchResult,
                               page: searchResult.page});                                                
            },
            onError: (errorMessage)=>{
                this.setState({pageInitState:'PAGE_LOAD_FAILED', initErrorMessage: errorMessage});
            }
        })
    }
}

export const SearchHealthAdviceByMe = connect(
    (state:WalletAppState)=>({languageCode: state.language.langCode})
)(SearchHealthAdviceByMeView);