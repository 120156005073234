// Generated by IcedCoffeeScript 108.0.11
(function () {
  var AES, WordArray, ciphers, decrypt, encrypt, _ref;

  _ref = require('triplesec'), ciphers = _ref.ciphers, WordArray = _ref.WordArray;
  AES = ciphers.AES;

  encrypt = function encrypt(_arg) {
    var block_cipher_class, block_size, c, cipher, e, iv, key, list, out, plaintext, pos;
    block_cipher_class = _arg.block_cipher_class, key = _arg.key, cipher = _arg.cipher, plaintext = _arg.plaintext, iv = _arg.iv;
    block_cipher_class || (block_cipher_class = AES);
    cipher || (cipher = new block_cipher_class(WordArray.from_buffer(key)));
    block_size = cipher.blockSize;
    c = WordArray.from_buffer(iv.slice(0, block_size));
    pos = 0;

    list = function () {
      var _results;

      _results = [];

      while (plaintext.length > pos) {
        cipher.encryptBlock(c.words, 0);
        e = c;
        c = WordArray.from_buffer(plaintext.slice(pos, pos + block_size));
        e.xor(c, {
          n_words: c.words.length
        });
        pos += block_size;
        c = e;

        _results.push(e.to_buffer());
      }

      return _results;
    }();

    out = Buffer.concat(list);
    return out.slice(0, plaintext.length);
  };

  decrypt = function decrypt(_arg) {
    var b, block_cipher_class, block_size, cipher, ciphertext, d, i, iv, key, list, out, pos;
    block_cipher_class = _arg.block_cipher_class, key = _arg.key, cipher = _arg.cipher, ciphertext = _arg.ciphertext, iv = _arg.iv;
    block_cipher_class || (block_cipher_class = AES);
    cipher || (cipher = new block_cipher_class(WordArray.from_buffer(key)));
    block_size = cipher.blockSize;
    iv || (iv = Buffer.from(function () {
      var _i, _results;

      _results = [];

      for (i = _i = 0; 0 <= block_size ? _i < block_size : _i > block_size; i = 0 <= block_size ? ++_i : --_i) {
        _results.push(0);
      }

      return _results;
    }()));
    b = WordArray.from_buffer(iv.slice(0, block_size));
    pos = 0;

    list = function () {
      var _results;

      _results = [];

      while (ciphertext.length > pos) {
        cipher.encryptBlock(b.words, 0);
        d = b;
        b = WordArray.from_buffer(ciphertext.slice(pos, pos + block_size));
        d.xor(b, {});
        pos += block_size;

        _results.push(d.to_buffer());
      }

      return _results;
    }();

    out = Buffer.concat(list);
    return out.slice(out, ciphertext.length);
  };

  exports.encrypt = encrypt;
  exports.decrypt = decrypt;
}).call(this);