import { StartAjaxCallAction, SuccAjaxCallAction, FailedAjaxCallAction, ErrorDialogAjaxCallAction } from './types';

export function startAjaxCall(whatIsFor:string, uuid:string):StartAjaxCallAction{
    return {
        type: 'AJAX_CALL_STARTED', whatIsFor, uuid
    }
}

export function succAjaxCall(whatIsFor:string, uuid:string):SuccAjaxCallAction{
    return {
        type: 'AJAX_CALL_SUCC', whatIsFor, uuid
    }
}

export function failedAjaxCall(whatIsFor:string, uuid:string, error:string):FailedAjaxCallAction{
    return {
        type: 'AJAX_CALL_FAILED', whatIsFor, uuid, error
    }
}

export function errorDialogAjaxCall(whatIsFor:string, uuid:string, error:string):ErrorDialogAjaxCallAction{
    return {
        type: 'AJAX_CALL_ERROR_DIALOG', whatIsFor, uuid, error
    }
}
