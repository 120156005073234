// Generated by IcedCoffeeScript 108.0.11
(function () {
  var Base,
      Collection,
      Data,
      SelfSig,
      SubkeyBinding,
      __hasProp = {}.hasOwnProperty,
      __extends = function __extends(child, parent) {
    for (var key in parent) {
      if (__hasProp.call(parent, key)) child[key] = parent[key];
    }

    function ctor() {
      this.constructor = child;
    }

    ctor.prototype = parent.prototype;
    child.prototype = new ctor();
    child.__super__ = parent.prototype;
    return child;
  };

  Base = function () {
    function Base(_arg) {
      this.sig = _arg.sig, this.key_expiration = _arg.key_expiration;
    }

    Base.prototype.typ = function () {
      return "none";
    };

    Base.prototype.get_key_flags = function () {
      return this.sig.get_key_flags();
    };

    Base.prototype.push = function (lookup) {
      return lookup[this.typ()].push(this);
    };

    return Base;
  }();

  SelfSig = function (_super) {
    __extends(SelfSig, _super);

    function SelfSig(_arg) {
      var key_expiration, sig, sig_expiration;
      this.userid = _arg.userid, this.user_attribute = _arg.user_attribute, this.type = _arg.type, sig = _arg.sig, this.options = _arg.options, key_expiration = _arg.key_expiration, sig_expiration = _arg.sig_expiration;

      SelfSig.__super__.constructor.call(this, {
        sig: sig,
        key_expiration: key_expiration,
        sig_expiration: sig_expiration
      });
    }

    SelfSig.prototype.typ = function () {
      return "self_sig";
    };

    SelfSig.prototype.push = function (lookup) {
      var key, v;
      lookup.self_sig.push(this);
      key = this.userid != null ? this.userid.utf8() || "" : void 0;

      if ((v = lookup.self_sigs_by_uid[key]) == null) {
        v = [];
        lookup.self_sigs_by_uid[key] = v;
      }

      return v.push(this);
    };

    return SelfSig;
  }(Base);

  SubkeyBinding = function (_super) {
    __extends(SubkeyBinding, _super);

    SubkeyBinding.UP = 1;
    SubkeyBinding.DOWN = 2;

    function SubkeyBinding(_arg) {
      var key_expiration, sig, sig_expiration;
      this.primary = _arg.primary, sig = _arg.sig, this.direction = _arg.direction, sig_expiration = _arg.sig_expiration, key_expiration = _arg.key_expiration;

      SubkeyBinding.__super__.constructor.call(this, {
        sig: sig,
        key_expiration: key_expiration,
        sig_expiration: sig_expiration
      });
    }

    SubkeyBinding.prototype.typ = function () {
      return "subkey_binding";
    };

    SubkeyBinding.prototype.is_down = function () {
      return this.direction === SubkeyBinding.DOWN;
    };

    return SubkeyBinding;
  }(Base);

  Data = function (_super) {
    __extends(Data, _super);

    function Data(_arg) {
      var key_expiration, sig, sig_expiration;
      this.key = _arg.key, sig = _arg.sig, key_expiration = _arg.key_expiration, sig_expiration = _arg.sig_expiration;

      Data.__super__.constructor.call(this, {
        sig: sig,
        key_expiration: key_expiration,
        sig_expiration: sig_expiration
      });
    }

    Data.prototype.typ = function () {
      return "data";
    };

    Data.prototype.get_key_manager = function () {
      var _ref;

      return (_ref = this.sig) != null ? _ref.key_manager : void 0;
    };

    return Data;
  }(Base);

  Collection = function () {
    function Collection() {
      this.clear();
    }

    Collection.prototype.clear = function () {
      this.all = [];
      return this.lookup = {
        self_sig: [],
        self_sigs_by_uid: {},
        subkey_binding: [],
        data: []
      };
    };

    Collection.prototype.push = function (ps) {
      this.all.push(ps);
      return ps.push(this.lookup);
    };

    Collection.prototype.is_signed_subkey_of = function (primary, need_upwards_sig) {
      var down, skb, up, _i, _len, _ref;

      up = down = false;
      _ref = this.lookup.subkey_binding;

      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        skb = _ref[_i];

        if (skb.primary.equal(primary)) {
          if (skb.is_down()) {
            down = true;
          } else {
            up = true;
          }

          if (down && (up || !need_upwards_sig)) {
            return true;
          }
        }
      }

      return false;
    };

    Collection.prototype.get_all_key_flags = function () {
      var p, ret, _i, _len, _ref;

      ret = 0;
      _ref = this.all;

      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        p = _ref[_i];
        ret |= p.get_key_flags();
      }

      return ret;
    };

    Collection.prototype.get_signed_userids = function () {
      var p, u, _i, _len, _ref, _results;

      _ref = this.lookup.self_sig;
      _results = [];

      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        p = _ref[_i];

        if ((u = p.userid) != null) {
          _results.push(u);
        }
      }

      return _results;
    };

    Collection.prototype.get_signed_user_attributes = function () {
      var p, u, _i, _len, _ref, _results;

      _ref = this.lookup.self_sig;
      _results = [];

      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        p = _ref[_i];

        if ((u = p.user_attribute) != null) {
          _results.push(u);
        }
      }

      return _results;
    };

    Collection.prototype.get_self_sig = function () {
      if (this.lookup.self_sig.length) {
        return this.lookup.self_sig[0];
      } else {
        return null;
      }
    };

    Collection.prototype.get_self_sigs = function () {
      return this.lookup.self_sig;
    };

    Collection.prototype.is_self_signed = function () {
      return this.get_signed_userids().length > 0;
    };

    Collection.prototype.get_data_signer = function () {
      if (this.lookup.data.length > 0) {
        return this.lookup.data[0];
      } else {
        return null;
      }
    };

    Collection.prototype.get_data_signers = function () {
      return this.lookup.data;
    };

    Collection.prototype.get_subkey_binding = function () {
      if (this.lookup.subkey_binding.length) {
        return this.lookup.subkey_binding[0];
      } else {
        return null;
      }
    };

    return Collection;
  }();

  exports.SelfSig = SelfSig;
  exports.SubkeyBinding = SubkeyBinding;
  exports.Data = Data;
  exports.Collection = Collection;
}).call(this);