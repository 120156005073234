import * as React from 'react';
import {Component, Fragment} from 'react'
import { CountryOption, NutritionistFormData, NutritionistInfo, BillingAddress, ShoppingAddressSpec, AddressInfo2Str, toImgSrc, NutritionistTestimonial, NutritionistServiceTimeInfo } from '../ajax-data/ajax-data-types';
import './edit-nutritionist.scss';
import classNames from 'classnames';
import * as _ from 'lodash';
import { t } from '../../i18n';
import { Button, Checkbox, FormControl, FormControlLabel, InputLabel, Select, TextField } from '@material-ui/core';
import { ShoppingAddressForm } from '../common/address-form';
import { NutritionLicenceForm } from '../nutritionist/licence-form';
import { Toast } from '../../view/Toast';
import { amIAdmin, amIUserWithUsername } from '../../local-storage/local-storage';
import sha1 from 'sha1';
import { displayServiceTime, ServiceTime } from '../common/service-time';
import { getExternContantsInMemory } from '../../external-constants/extern-constants';
import { UButton } from '../../widget/button/ubutton';
import { ThreeStateCheckbox } from '../../widget/three-state/three-state-checkbox';
import { ImageUploader, UploadedImage } from '../../widget/image-uploader/image-uploader';
import { ListEditor } from '../../widget/list-editor/list-editor';
import { MarkdownEditor } from '../../widget/markdown/markdown-editor';

interface EditNutritionistProps {
    readonly: boolean;
    languageCode: string;
    countryOptons:CountryOption[];
    addressSpecMap:{[key:string]:ShoppingAddressSpec};

    nutritionist: NutritionistInfo;
}

interface EditNutritionistState {
    nutritionist: NutritionistInfo;
    uploadedAvatar?: UploadedImage;
    errors: {[key:string]:string};
}


export class EditNutritionist extends React.Component<EditNutritionistProps, EditNutritionistState>{
    private addrForm:ShoppingAddressForm|undefined;
    private toast:Toast|undefined;

    private socialMediaEditor: ListEditor<string> | undefined;
    private testimonialTimeEditor: ListEditor<NutritionistTestimonial> | undefined;
    private serviceTimeTimeEditor: ListEditor<NutritionistServiceTimeInfo> | undefined;
    private resumeEditor: MarkdownEditor | undefined;



    constructor(props: EditNutritionistProps){
        super(props);
        this.state = {nutritionist: _.cloneDeep(this.props.nutritionist),errors:{}}
    }

    updateNutritionistName(s:string){
        this.state.nutritionist.name = s;
        this.forceUpdate();
    }

    updateTitles(s:string){
        this.state.nutritionist.titles = (s||'').split('|');
        this.forceUpdate();
    }

    updateSocialMediaLinks(s:string){
        this.state.nutritionist.socialMediaLinks = (s||'').split('\n');
        this.forceUpdate();
    }

    updateOfficeEmail(s:string){
        this.state.nutritionist.officeEmail = s;
        this.state.errors.officeEmail = '';
        this.forceUpdate();
    }
    
    updateOfficePhone(s:string){
        this.state.nutritionist.officePhone = s;
        this.state.errors.officePhone = '';
        this.forceUpdate();
    }

    updateWebsite(s:string){
        this.state.nutritionist.website = s;
        this.state.errors.website = '';
        this.forceUpdate();
    }
    updateWebsiteName(s:string){
        this.state.nutritionist.websiteName = s;
        this.state.errors.websiteName = '';
        this.forceUpdate();
    }

    updateWechatId(s:string){
        this.state.nutritionist.wechatId = s;
        this.state.errors.wechatId = '';
        this.forceUpdate();
    }
    updateWechatPublicId(s:string){
        this.state.nutritionist.wechatPublicId = s;
        this.state.errors.wechatPublicId = '';
        this.forceUpdate();
    }

    updateExpertise(s:string){
        this.state.nutritionist.expertIn = s;
        this.forceUpdate();
    }

    validateAndGetNutritionistInfo(): {data:NutritionistFormData, hasError:boolean}{
        const isAdmin = amIAdmin();
        const amIOwner = amIUserWithUsername(this.state.nutritionist.username) || isAdmin;

        let addressForm = this.addrForm!.validateAndGetAddress();
        let address = addressForm.addr;

        let n = _.cloneDeep(this.state.nutritionist) as NutritionistFormData;
        n.uploadedAvatar = this.state.uploadedAvatar;
        n.socialMediaLinks = this.socialMediaEditor!.getList();
        n.testimonial = this.testimonialTimeEditor!.getList();
        n.serviceTime = this.serviceTimeTimeEditor!.getList();
        n.resume = this.resumeEditor!.getDoc();

        let errors: {[key:string]:string} = {};

        if(n.name){
            n.name = n.name.trim();
        }
        if(n.officeEmail){
            n.officeEmail = n.officeEmail.trim();
        }
        if(!n.officeEmail){
            errors.officeEmail = t('请输入电邮')
        }
        if(n.officePhone){
            n.officePhone = n.officePhone.trim();
        }
        if(!n.officePhone){
            errors.officePhone = t('请输入电话');
        }

        if(n.expertIn){
            n.expertIn = n.expertIn.trim();
        }
        if(n.gender){
            if(n.gender !== 'female' && n.gender !== 'male'){
                n.gender = undefined;
            }
        }
        n.businessAddress = address;

        const hasError = addressForm.hasError || Object.keys(errors).length>0;

        this.setState({errors});
        return {data:n, hasError}
    }

    renderSocialMediaLink =  (arg:{item: string}, readonly:boolean, onChange: (s:string)=>void):JSX.Element =>  {
        return <div className='social-media-link'>
                    <TextField
                        label={t('社交账号')} 
                        value={arg.item}
                        variant='outlined'
                        size='small'
                        disabled={readonly}
                        onChange={evt=>{
                            arg.item = evt.currentTarget.value;
                            onChange(arg.item);
                        }}
                    />
               </div>
    }

    renderTestimonial = (arg:{item: NutritionistTestimonial}, 
                         readonly:boolean,
                         onChange: (item:NutritionistTestimonial)=>void):JSX.Element =>  {
        return (
            <div className='testimonial'>
                <TextField 
                    label={t('客户姓名')} 
                    value={arg.item.clientName}
                    variant='outlined'
                    size='small'
                    disabled={readonly}
                    onChange={evt=>{
                        arg.item.clientName = evt.currentTarget.value;
                        onChange(arg.item);
                    }}
                />
                <TextField
                    label={t('客户信息')}
                    value={arg.item.clientInfo}
                    variant='outlined'
                    size='small'
                    disabled={readonly}
                    onChange={evt=>{
                        arg.item.clientInfo = evt.currentTarget.value;
                        onChange(arg.item);
                    }}
                />
                <TextField
                    label={t('案例')}
                    value={arg.item.testimonial}
                    variant='outlined'
                    disabled={readonly}
                    size='small'
                    multiline
                    minRows={4}
                    maxRows={20}
                    onChange={evt=>{
                        arg.item.testimonial = evt.currentTarget.value;
                        onChange(arg.item);
                    }}
                />
            </div>
        )
    }

    render(){
        let n = this.state.nutritionist;
        let errors = this.state.errors;
        const {readonly} = this.props;
        const isAdmin = amIAdmin();
        const amIOwner = amIUserWithUsername(n.username) || isAdmin;
        const spokenLanguageOptions = getExternContantsInMemory().spokenLanguageOptions;

        const activation = <FormControlLabel 
                                control={<Checkbox 
                                            checked={n.status === 'active'}  
                                            color='primary' 
                                            onClick={()=>{   
                                                        if(n.status === 'active'){
                                                            n.status = 'inactive';
                                                        }else{
                                                            n.status = 'active';
                                                        }
                                                        this.forceUpdate();
                                                    }
                                            }/>
                                        } 
                                label={t('已经激活?')}/>;

        let avatarSrc = '';
        if(this.state.nutritionist!.avatar){
            avatarSrc = toImgSrc(this.state.nutritionist!.avatar.url);
        }                                
        return (
            <div className='edit-nutritionist'>
                {activation}

                <div>
                    <p>{t('头像图片')}</p>
                    <ImageUploader
                        minImgHeight={128}
                        minImgWidth={128}
                        className='avatar-image-loader'
                        currentImg={avatarSrc}
                        genImageName={()=>{ return 'avatar' }}
                        done={(uploadedImg?: UploadedImage)=>{
                            this.setState({uploadedAvatar: uploadedImg});
                        }}
                    />
                </div>

                <div className='text-input'>
                    <TextField variant='outlined' size='small' value={n.name} disabled={!amIOwner || readonly}
                                           label={t('姓名')}
                                           onChange={(evt)=>{ this.updateNutritionistName(evt.currentTarget.value)}}
                    />
                    <div className='error-msg'>{errors.name}</div>                    
                </div>
                <div className='text-input'>
                    <TextField variant='outlined' size='small' value={(n.titles||[]).join('|')} disabled={!amIOwner || readonly}
                                           label={t('头衔|职称|证书|学历 等等')}
                                           multiline={true}
                                           minRows={2}
                                           maxRows={4}
                                           onChange={(evt)=>{ this.updateTitles(evt.currentTarget.value)}}
                    />
                    <div className='error-msg'>{errors.titles}</div>                    
                </div>
                <div className='text-input'>
                    <TextField variant='outlined' size='small' value={n.officeEmail}  disabled={!amIOwner || readonly}
                                label={t('电邮')+'*'}
                                onChange={(evt)=>{ this.updateOfficeEmail(evt.currentTarget.value)}}
                                />
                    <div className='error-msg'>{errors.officeEmail}</div>                    
                </div>
                <div className='text-input'>
                    <TextField variant='outlined' size='small' value={n.officePhone}  disabled={!amIOwner || readonly}
                                label={t('电话')+'*'}
                                onChange={(evt)=>{ this.updateOfficePhone(evt.currentTarget.value)}}
                                />
                    <div className='error-msg'>{errors.officePhone}</div>                    
                </div>
                <div className='text-input'>
                    <TextField variant='outlined' size='small' multiline minRows={2} maxRows={8} value={n.expertIn}
                                label={t('擅长项目')}
                                onChange={(evt)=>{ this.updateExpertise(evt.currentTarget.value)}}  
                                disabled={!amIOwner || readonly}
                    />
                    <div className='error-msg'>{errors.expertIn}</div>                    
                </div>

                <div className='service-languages'>
                    <p>{t('服务语种')}</p>
                    {(spokenLanguageOptions).map(x=>{
                        const checked = (n.language||[]).some(v => v === x.key);

                        return <FormControlLabel label={t(x.label)} key={x.key}
                                    control={
                                        <Checkbox checked={checked} key={x.key} color='default' disabled={!amIOwner || readonly}
                                                    onChange={()=>{
                                                        this.state.nutritionist.language = this.state.nutritionist.language||[];
                                                        const checked = this.state.nutritionist.language.some(v=>v===x.key);
                                                        if(checked){
                                                            // unckeck it
                                                        this.state.nutritionist.language = this.state.nutritionist.language.filter(v=>v !== x.key);
                                                        }else{
                                                            // check it
                                                        this.state.nutritionist.language.push(x.key);
                                                        }
                                                        this.forceUpdate();
                                                    }}
                                        />
                                    }
                                />
                        })
                    }                                                                
                </div>
                
                <div>
                    <ThreeStateCheckbox 
                        trueLabel={t('male', 'sex')} falseLabel={t('female', 'sex')}
                        status={n.gender==='male'? true : (n.gender==='female'? false : undefined)}
                        color='primary'
                        onChange={status=>{
                            if(status === undefined){
                                this.state.nutritionist.gender=undefined;
                            }else if(!!status){
                                this.state.nutritionist.gender='male';
                            }else{
                                this.state.nutritionist.gender='female';
                            }
                            this.forceUpdate();    
                        }}
                    />
                </div>

                <div className='text-input'>
                    <TextField variant='outlined' size='small' value={n.website}  disabled={!amIOwner || readonly}
                                label={t('网页网址')}
                                onChange={(evt)=>{ this.updateWebsite(evt.currentTarget.value)}}
                                />
                    <div className='error-msg'>{errors.website}</div>                    
                </div>
                <div className='text-input'>
                    <TextField variant='outlined' size='small' value={n.websiteName}  disabled={!amIOwner || readonly}
                                label={t('网页显示名')}
                                onChange={(evt)=>{ this.updateWebsiteName(evt.currentTarget.value)}}
                                />
                    <div className='error-msg'>{errors.websiteName}</div>                    
                </div>
                <div className='text-input'>
                    <TextField variant='outlined' size='small' value={n.wechatId}  disabled={!amIOwner || readonly}
                                label={t('微信号')}
                                onChange={(evt)=>{ this.updateWechatId(evt.currentTarget.value)}}
                                />
                    <div className='error-msg'>{errors.wechatId}</div>                    
                </div>
                <div className='text-input'>
                    <TextField variant='outlined' size='small' value={n.wechatPublicId}  disabled={!amIOwner || readonly}
                                label={t('微信公众号')}
                                onChange={(evt)=>{ this.updateWechatPublicId(evt.currentTarget.value)}}
                                />
                    <div className='error-msg'>{errors.wechatPublicId}</div>                    
                </div>
                
                <ListEditor
                    className='social-medias'
                    maxItems={10}
                    list={n.socialMediaLinks||[]}
                    dataRender={this.renderSocialMediaLink}
                    newData={()=> ''}
                    title={('社交账号列表')}
                    done={(socialMediaLinks)=>{
                        this.state.nutritionist.socialMediaLinks = socialMediaLinks;
                        this.forceUpdate();
                    }}
                    ref={e=>{ this.socialMediaEditor = e!}}
                />

                <ListEditor
                    className='testimonials'
                    maxItems={5}
                    list={(n.testimonial||[]) as NutritionistTestimonial[]}
                    dataRender={this.renderTestimonial}
                    newData={()=>({clientName:'', clientInfo:'', testimonial:''})}
                    title={t('案例分析列表')}
                    done={ testimonial=>{
                        this.state.nutritionist.testimonial = testimonial;
                        this.forceUpdate();
                    }}
                    ref={e=>{ this.testimonialTimeEditor = e!}}
                />

                <MarkdownEditor
                    storageId='nutritionist-resume'
                    title={t('简历')}
                    className='resume'
                    doc={n.resume||''}
                    done={(doc)=>{
                        n.resume = doc;
                        this.forceUpdate();
                    }}
                    ref={e=> { this.resumeEditor = e! }}
                />    

                <p className='address-label'>{t('地址')}</p>
                <ShoppingAddressForm addressType='AddressInfo' countryOptons={this.props.countryOptons}
                                     addressSpecMap={this.props.addressSpecMap}
                                     initialAddress={this.state.nutritionist.businessAddress as BillingAddress}
                                     ref={e=>{this.addrForm = e!}} readonly={!amIOwner || readonly}
                                     key={sha1(JSON.stringify(this.state.nutritionist.businessAddress))}
                />

                <ListEditor
                    title={t('服务时间列表')}
                    className='service-time-list'
                    list={n.serviceTime||[]}
                    maxItems={7}
                    newData={()=>{ 
                        let newTtime: NutritionistServiceTimeInfo = {
                            from:9, to:17, timeZone: (new Date()).getTimezoneOffset()/-60,
                            dayOfWeek: [1,2,3,4,5], notes:'',
                            online: true, inOffice: false
                        }
                        return newTtime; 
                    }}
                    dataRender={(arg, readonly)=> {
                        if(readonly){
                            return displayServiceTime(arg.item);
                        }
                        return <ServiceTime readonly={readonly} 
                                            serviceTime={arg.item} 
                                />
                    }}
                    done={(serviceTime)=>{
                        n.serviceTime = serviceTime;
                        this.forceUpdate();
                    }}
                    ref={e=>{ this.serviceTimeTimeEditor = e!}}
                />                                    


                <p className='address-label'>{t('营养师执照')+t('(只读)')}</p>
                <NutritionLicenceForm username={n.username} licence={n.license} 
                                      readonly={true} 
                                      key={sha1(n.username+JSON.stringify(n.license))}
                                      languageCode={this.props.languageCode}
                                      />
 
                {activation}

                <Toast  close={true} 
                        message='' 
                        toastType='info' 
                        onClose={()=>{}} 
                        ref={(e)=>{this.toast=e!}}/>
            </div>
        )
    }
}