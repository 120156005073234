import * as React from 'react';
import {Component, Fragment} from 'react';
import { t } from '../../i18n';
import { NoticeDialog } from '../../view/NoticeDialog';
import { getShuyu } from '../../wallet-server-api/wallet-server-api';
import { isMarkTermTab, isShoppingProductFieldGroup, isShoppingProductTableData, isSimpleShoppingProductField, isTapToEnlargeTab, isWarningTab, ShoppingProductDetail, ShoppingProductDetailTab, ShoppingProductFieldGroup, ShoppingProductTableData, SimpleShoppingProductField } from '../ajax-data/ajax-data-types';
import { getCurrencyOptionsMap } from '../ajax-data/shopping-server-api';
import { doAJAXCall } from '../common/ajax-call';
import { BaseShoppingPageProps,  } from '../common/shopping-page-common';

import './base-product-detail.scss';
import { ProductFieldText } from './product-field-text';
import classnames from 'classnames';
import { ImageOverlay } from '../common/image-overlay';
import { Accordion, AccordionDetails, AccordionSummary, Button } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { LANG_CODE_ZH_CN } from '../../external-constants/extern-constants';
import { PropertyListDataItem, PropertyListWidget } from '../../widget/property-list/property-list';
import { RatioXXS } from '../../util/Util';

export interface BaseProductDetailProps extends BaseShoppingPageProps{
    product: ShoppingProductDetail;
    currencyCode:string;
    languageCode:string;
    highlightWords:string[];
}

export interface BaseProductDetailState{
    showImageOverlay?: boolean;
}

export class BaseProductDetail<P extends BaseProductDetailProps,T extends BaseProductDetailState> extends Component<BaseProductDetailProps, BaseProductDetailState>{
    private errorDialog:NoticeDialog|undefined;
    private termDialog: NoticeDialog|undefined;

    constructor(props: P){
        super(props);
        this.state = this.getInitialState(props);
    }

    getInitialState(props: P): BaseProductDetailState {
        return {showImageOverlay: false};
    }

    getCurrencyOptions(){
        let currencyOptionsMap = getCurrencyOptionsMap();
        let currencyOptions = currencyOptionsMap[this.props.languageCode] || currencyOptionsMap[LANG_CODE_ZH_CN];
        return currencyOptions;
    }

    getCurrency(){
        let currencyOptions = this.getCurrencyOptions();
        let currency = currencyOptions.find(x=> x.code === this.props.currencyCode);
        if(!currency){
            currency = {code:'CAD', symbol:'$', label:'加元'}
        }
        return currency;
    }

    renderSimpleShoppingProductField(tab:SimpleShoppingProductField):JSX.Element{
        if(tab.value === null || tab.value === undefined)  return <div></div>;

        if(typeof tab.value === 'number'){
            return <div className='simple-product-field'>{tab.value}</div>
        }
        if(typeof tab.value === 'boolean'){
            return <div className='simple-product-field'>{tab.value}</div>
        }
        // assume tab.value is string
        let markTerms = isMarkTermTab(tab)||tab.name==='field_yingyongfanwei' || tab.name==='field_chanpintedian';
        let tapToEnlarge = isTapToEnlargeTab(tab);
        let lines = tab.value;

        if(Array.isArray(tab.value)){
            lines = (tab.value as string[]).join(', ');
        }

        return <ProductFieldText supportDoubleTap={tapToEnlarge} skipShuyu={!markTerms} lines={lines}
                                 onClickTerm={(term)=>{ this.showTerm(term) }}
                                 extraClasses={'simple-product-field'}
                                 highlightWords={this.props.highlightWords}
               />
    }

    async showTerm(term:string){
        await doAJAXCall({
            whatIsFor:t('读取术语'),
            ajaxCall: async (accessToken:string)=>{
                let termDef = await getShuyu(term, accessToken);
                this.termDialog!.show(term, 
                                  <ProductFieldText lines={termDef.fields.field_mingci} 
                                                    supportDoubleTap={false}
                                                    skipShuyu={true} 
                                                    onClickTerm={()=>{}}/> , 
                                  ()=>{});
            },
            errorDialog: this.errorDialog!,
            onError: (errorMessage:string)=>{}
        });
    }

    renderShoppingProductFieldGroup(tab:ShoppingProductFieldGroup):JSX.Element{
        return <div className='product-field-group'>
              {tab.fieldList.map((x,idx)=>{
                let classes = classnames('product-field-group-item', {short:x.label.length+(x.value+'').length<=20});  
                return  <div key={idx+'-'+x.name} className={classes}>
                            <p className='field-label'>{x.label}</p>
                            {this.renderSimpleShoppingProductField(x)}
                        </div>
      
              })}
        </div>
    }
    renderShoppingProductTableData(tab:ShoppingProductTableData):JSX.Element{
        return <div className='product-field-table'>
               {tab.tableData.map((row, idx)=>{
                        const props = row.map((col, idx)=>{
                            const ret:PropertyListDataItem = {name: col.label, value: this.renderSimpleShoppingProductField(col)}
                            return ret;
                        })
                        return <PropertyListWidget list={props} mergeColScreenLetterRatio={RatioXXS}
                        />
                    })
               }
               </div>
    }
    renderTabContent(tab:ShoppingProductDetailTab):JSX.Element {
        if(isSimpleShoppingProductField(tab)){
            return this.renderSimpleShoppingProductField(tab as SimpleShoppingProductField);
        }else if(isShoppingProductFieldGroup(tab)){
            return this.renderShoppingProductFieldGroup(tab as ShoppingProductFieldGroup);
        }else if(isShoppingProductTableData(tab)){
            return this.renderShoppingProductTableData(tab as ShoppingProductTableData);
        }else{
            return <div></div> // shouldn't happen
        }
    }

    renderImageHeader():JSX.Element{
        const product = this.props.product!;
        const currency = this.getCurrency();
        
        const productHeaders:PropertyListDataItem[] = product.headers.map(x=>{
                                                                            const ret:PropertyListDataItem = {
                                                                                name: x.label,
                                                                                value: x.value,
                                                                                key: x.label
                                                                            }
                                                                            return ret;
                                                                        });

        return (
            <div className='product-image-n-headers'>
                {this.state.showImageOverlay && <ImageOverlay imageList={product.productImageList} close={()=>{ this.setState({showImageOverlay: false}) }}/> }
                <img className='product-image' src={product.productImageList[0]} onClick={()=>{ this.setState({showImageOverlay: true}) }}/>
                <div className='product-header'>
                    <div className='product-price'>
                        {currency.symbol+product.price.toFixed(2)} <span className='currency-label'>{currency.label}</span>
                    </div>
                    {product.originalPrice && product.originalPrice >0 ?
                     <div className='original-price'> 
                        {currency.symbol+product.originalPrice.toFixed(2)} <span className='currency-label'>{currency.label}</span>
                     </div>: null
                    }
                    <PropertyListWidget list={productHeaders} 
                                        className='product-header-table'
                                        max_name_width='8ch'
                                        mergeColScreenLetterRatio={RatioXXS}
                    />
                </div>
                { product.originalPrice && product.originalPrice > 0 ? <div className='special-price-ribbon'>{t('特价')}</div>: null}
             </div>
       )
    }

    renderContentTabs():JSX.Element {
        const product = this.props.product!;
        const currency = this.getCurrency();

        return <Fragment>
                {product.tabs.map((x, idx)=>{
                    let summaryClasses = classnames({'is_warning':isWarningTab(x)});

                    return (
                        <div className={classnames('accordion-wrapper', x.name, {'warning': isWarningTab(x)})}>
                            <Accordion key={idx+'-'+x.name}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                    <strong className={summaryClasses}>{x.label}</strong> 
                                </AccordionSummary>
                                <AccordionDetails className='accordion-detail-product'>
                                    {this.renderTabContent(x)}
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    )
                })}
            </Fragment>

    }
    renderProductDetail():JSX.Element {
        const product = this.props.product!;

        return (
            <div className='shopping-product-detail-main-pane'>
                <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                <NoticeDialog open={false} ref={e=>this.termDialog=e!}/>

                <h2 className='product-name'>{product.productName}</h2>
                {this.renderImageHeader()}

                {this.props.children}
                
                {this.renderContentTabs()}
            </div>
        )
    }

    render(){
        return this.renderProductDetail();
    }
}