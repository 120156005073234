import * as React from 'react';
import { NutritionistLicenceFormData, NutritionistLicenceInfo } from '../ajax-data/ajax-data-types';
import './licence-form.scss';
import * as _ from 'lodash';
import { Button, MenuItem, TextField } from '@material-ui/core';
import { t } from '../../i18n';
import {ImageWithOverlay} from '../common/image-with-overlay';
import { getServerURL } from '../../util/Util';
import { getLanguageCode } from '../../local-storage/local-storage';
import { UButton } from '../../widget/button/ubutton';
import { ImageWithRotation } from '../../widget/image-rotate/image-rotate';
import { getLicenceCategoryOptions } from '../ajax-data/shopping-server-api';
import { UDatePicker } from '../../widget/date-picker/date-picker';

interface LicenceFormProps {
    languageCode: string;
    readonly: boolean; // is current user has ADMIN role?
    username:string; // nutritionist username/id
    licence?:NutritionistLicenceInfo;
}

interface LicenceFormState {
    licence:NutritionistLicenceInfo;
    errors: {[key:string]: string};

    uploadLicenceImg?: {
        licenceImgFile?: File;
        licenceImgPreviewSrc?: string;
        transformedImg?: string;
    }
}

export class NutritionLicenceForm extends React.Component<LicenceFormProps, LicenceFormState>{
    constructor(props: LicenceFormProps){
        super(props);
        
        let errors = {}
        if(this.props.licence){            
            this.state = {licence: _.cloneDeep(this.props.licence), errors}
        }else{
            // create an empty licence
            let licence:NutritionistLicenceInfo = {nutritionistUsername: this.props.username,
                                                   employer:'', 
                                                   regNumber: '', 
                                                   insurancePolicyNumber: '',
                                                   category:'', 
                                                   name:'',
                                                   expiration: 0, licenceImage:undefined
                                                   }
            this.state = {licence, errors}
        }
    }

    updateLicenceImgFile(f:File){
        const reader = new FileReader();
        reader.addEventListener("load", ()=>{
            this.setState({
                uploadLicenceImg:{
                    licenceImgPreviewSrc: reader.result as string, 
                }});
        });
        reader.readAsDataURL(f);
    }

    removeUploadLicenceImgFile(){
        this.setState({
            uploadLicenceImg: undefined
        });        
    }

    removeCurrentLicenceImg(){
        delete this.state.licence.licenceImage;
        this.forceUpdate();
    }

    getLicenceData(): NutritionistLicenceFormData {
        const l = _.cloneDeep(this.state.licence);
        return {...l, 
                uploadLicenceImgFile: this.state.uploadLicenceImg? this.state.uploadLicenceImg!.licenceImgFile: undefined, 
                hasError: false
            }
    }

    validateAndGetLicenceData(): NutritionistLicenceFormData {
        const l = _.cloneDeep(this.state.licence);
        
        l.name = (l.name || '').trim();
        l.employer = (l.employer || '').trim();
        l.regNumber = (l.regNumber || '').trim();
        l.insurancePolicyNumber = (l.insurancePolicyNumber || '').trim();
        l.category = (l.category || '').trim();

        const errors: {[key:string]: string} = {};

        if(!l.name){
            errors.name = t(`请输入姓名`);
        }
        if(!l.regNumber){
            errors.regNumber = t(`请输入执照号码`);
        }
        if(!l.insurancePolicyNumber){
            errors.insurancePolicyNumber = t(`请输入保险号`);
        }
        if(!l.category){
            errors.category = t(`请选择类型`);
        }
        if(l.expiration<1){
            errors.expiration = t(`请输入有效日期`);
        }
        let hasError = false;
        if(Object.keys(errors).length>0){
            this.setState({errors});
            hasError = true;
        }

        return {...l, 
                uploadLicenceImgFile: this.state.uploadLicenceImg? this.state.uploadLicenceImg.licenceImgFile:undefined, 
                hasError
            }
    }

    updateName(s:string){
        this.state.licence.name = s;
        delete this.state.errors.name;
        this.forceUpdate();
    }
    updateEmployer(s:string){
        this.state.licence.employer = s;
        delete this.state.errors.employer;
        this.forceUpdate();
    }
    updateRegNumber(s:string){
        this.state.licence.regNumber = s;
        delete this.state.errors.regNumber;
        this.forceUpdate();
    }
    updateInsuranceNumber(s:string){
        this.state.licence.insurancePolicyNumber = s;
        delete this.state.errors.insurancePolicyNumber;
        this.forceUpdate();
    }
    updateCategory(s:string){
        this.state.licence.category = s;
        delete this.state.errors.category;
        this.forceUpdate();
    }
    updateExpiration(d?:Date){
        this.state.licence.expiration = d? d.getTime() : 0;
        delete this.state.errors.expiration;
        this.forceUpdate();
    }

    render(){
        const {readonly} = this.props;
        const serverURL = getServerURL();
        const l = this.state.licence;
        const e = this.state.errors;

        let expireDate: Date|undefined;
        if(l.expiration>0){
            expireDate = new Date();
            expireDate.setTime(l.expiration);
        }

        const categoryOptions = getLicenceCategoryOptions(this.props.languageCode);
        const today = new Date();

        return (
            <div className='nutritionist-licence-form'>
               <TextField variant='outlined' label={t(`姓名`)+'*'} value={l.name} size='small' disabled={readonly}
                          onChange={evt=>{ this.updateName(evt.target.value) }}/>
               <div className='error-msg'>&nbsp;{e.name}</div>
               
               <TextField variant='outlined' label={t(`公司`)} value={l.employer} size='small' disabled={readonly}
                           onChange={evt=>{ this.updateEmployer(evt.target.value) }}/>
               <div className='error-msg'>&nbsp;{e.employer}</div>
               
               <TextField variant='outlined' label={t(`执照号码`)+'*'} value={l.regNumber} size='small' disabled={readonly}
                          onChange={evt=>{ this.updateRegNumber(evt.target.value) }}/>
               <div className='error-msg'>&nbsp;{e.regNumber}</div>

               <TextField variant='outlined' label={t(`保险号`)+'*'} value={l.insurancePolicyNumber} size='small' disabled={readonly}
                          onChange={evt=>{ this.updateInsuranceNumber(evt.target.value) }}/>
               <div className='error-msg'>&nbsp;{e.insurancePolicyNumber}</div>

               <TextField select variant='outlined' label={t(`执照类型`)+'*'} value={l.category} size='small' disabled={readonly}
                          onChange={evt=>{ this.updateCategory(evt.target.value) }}>
                    {categoryOptions.map(x=>{
                        return <MenuItem key={x.key} value={x.key}>{x.text}</MenuItem> 
                    })}          
               </TextField>
               <div className='error-msg'>&nbsp;{e.category}</div>
               
               <div className='expire-date'>
                   <label>{t('失效日期')+'*'}</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                   <UDatePicker value={expireDate}  disabled={readonly}
                                minYear={2022}
                                maxYear={today.getFullYear()+50}                   
                               onChange={(value?:Date)=>{ 
                                    this.updateExpiration(value);
                               }}
                    />
               </div>
               <div className='error-msg'>&nbsp;{e.expiration}</div>

               <p>{t('执照图片')}</p>
               {this.state.uploadLicenceImg?
                (this.state.uploadLicenceImg!.transformedImg ?
                <ImageWithOverlay imageList={[this.state.uploadLicenceImg!.transformedImg]} classes='licence-img transformed-image'/> 
                 :
                this.state.uploadLicenceImg!.licenceImgPreviewSrc?
                <ImageWithRotation imgsrc={this.state.uploadLicenceImg!.licenceImgPreviewSrc} className='licence-img'
                                    minImgWidth={400} minImgHeight={200}
                                    cancel={()=>{ this.setState({uploadLicenceImg: undefined}) }}
                                    done={async (transformedImg)=>{
                                        let data = await fetch(transformedImg);
                                        let blob = await data.blob();
                                        const licenceImgFile = new File([blob], "licence-"+Date.now(), {type: "image/png"}); 
                                        this.setState({uploadLicenceImg: {
                                                                            transformedImg,
                                                                            licenceImgFile,
                                                                            licenceImgPreviewSrc:'' 
                                                        }})
                                    }}
                                    key={Date.now()}
                />: null)
                :
                (l.licenceImage? 
                    <React.Fragment>
                        <div className='paragraph'>&nbsp;&nbsp;&nbsp;
                          { this.props.readonly? null : 
                            <UButton size='small' variant='outlined' color='secondary'  disabled={readonly}
                                  onClick={()=>{this.removeCurrentLicenceImg()}}>{t('删除')}
                            </UButton>
                          }
                        </div>
                        <ImageWithOverlay imageList={[serverURL+l.licenceImage.url]} classes='licence-img'/> 
                    </React.Fragment>
                  : 
                  null
                )                
               }
                { this.props.readonly ? null :                
                    <div>
                        <label htmlFor='nutritionist-licence-image-input' id='nutritionist-licence-image-label'>{t('取相册图片')}</label>
                        <input type='file' accept='image/png, image/jpg, image/jpeg' id='nutritionist-licence-image-input' style={{visibility:'hidden'}}
                                onChange={(evt)=>{ this.updateLicenceImgFile(evt.currentTarget.files![0]) }} disabled={readonly}
                        />
                    </div>
                }
            </div>
        )

    }
}