// Generated by IcedCoffeeScript 108.0.11
(function () {
  var Encryption, K, P3SKB, Signature, katch;
  K = require('../../const').kb;
  P3SKB = require('./p3skb').P3SKB;
  Signature = require('./signature').Signature;
  Encryption = require('./encryption').Encryption;

  katch = function katch(f) {
    var e;

    try {
      return [null, f()];
    } catch (_error) {
      e = _error;
      return [e, null];
    }
  };

  exports.alloc = function (_arg) {
    var body, err, ret, tag, _ref;

    tag = _arg.tag, body = _arg.body;
    _ref = function () {
      switch (tag) {
        case K.packet_tags.p3skb:
          return katch(function () {
            return P3SKB.alloc({
              tag: tag,
              body: body
            });
          });

        case K.packet_tags.signature:
          return katch(function () {
            return Signature.alloc({
              tag: tag,
              body: body
            });
          });

        case K.packet_tags.encryption:
          return katch(function () {
            return Encryption.alloc({
              tag: tag,
              body: body
            });
          });

        default:
          err = new Error("unknown packet tag: " + tag);
          return [err, null];
      }
    }(), err = _ref[0], ret = _ref[1];
    return [err, ret];
  };
}).call(this);