import axios from 'axios';
import { SignupRequest } from '../api/signup-request';
import { LoginRequest, JwtAuthenticationResponse, UserProfile, QRCodeResponse, RefreshRequest, ResetPasswordRequest, ProfileRequest, SearchUserParms, UserUpdateRequest, UserSummary, ROLE_SHOP_GNGINC } from '../api/user';
import { AddCertificationRequest } from '../api/certificate';
import { TransferRequest, PagedResponse, TransferSummary, MerchantOrderBrief } from '../api/transaction';
import { BrainTreeCheckoutRequest, CheckoutResponse, SnappayPrepareRequest, SnappayPrepareResponse, SnappayCompleteRequest, 
         StripePrepareRequest, StripePrepareResponse, StripeCompleteRequest, PaymentResponse } from '../api/purchase-points';
import { PaymentOrder, UnishelPayRequest} from '../api/unishell-pay';
import { PaymentConfig, AppError, CheckUpgradeRequest, CheckUpgradeResponse} from '../api/common';
import { getLanguageCode } from '../local-storage/local-storage';
import { asyncDelay, getServerURL, pointingProdServer } from '../util/Util';
import { CommissionDistributionRequest, CommissionRequest } from '../api/commission';
import { DocSpecResponse, Doc, DocSearchRequest, DocSearchResponse, UpdateProductIndexResponse} from '../api/docs';
import { processHttpOK, ServerApiResponse } from './ajax-common';
import { getManifest, getDevice } from '../globals';




export async function signup(request:SignupRequest) {
    const serverURL = getServerURL();

    return processHttpOK<string>(axios.post(`${serverURL}/server/v1/auth/signup`, request, {headers:{"accept-language":getLanguageCode()}}));
}

export async function unregister(accessToken:string){
    const serverURL = getServerURL();
    return processHttpOK<any>(axios.post(`${serverURL}/server/v1/user/deleteAccountRequest`, 
                                         {reason:'n/a'}, 
                                         {headers:{
                                            'Authorization': "Bearer " + accessToken,
                                            "accept-language":getLanguageCode()
                                          }
                                         }
                                        )
                             );
}

export async function signin(request:LoginRequest) {
    const serverURL = getServerURL();

    return processHttpOK<JwtAuthenticationResponse>(axios.post(`${serverURL}/server/v1/auth/signin`, request, {headers: {"accept-language":getLanguageCode()}}));
}

export async function retrieveUserProfile(accessToken:string) {
    const serverURL = getServerURL();

    const profile = await processHttpOK<UserProfile>(
        axios.get(`${serverURL}/server/v1/user/me`,{headers: {'Authorization': "Bearer " + accessToken,"accept-language":getLanguageCode()}})
        );

    const permittedUATEmails = ["shop@gnginc.com", "handong.wang.aurora@gmail.com", "davxie@gmail.com"];
    const isNaturalZenith = pointingProdServer() ? 
                            "shop@gnginc.com" === profile.userEmail.trim() : 
                            permittedUATEmails.some(x=>x===profile.userEmail);
    if(isNaturalZenith){
        profile.roles.push(ROLE_SHOP_GNGINC);
    }                        

    return profile;
}

export async function retrieveUserSummaryByQRCode(accessToken:string, qrcode:string){
    const serverURL = getServerURL();
    let languageCode = getLanguageCode();

    let user = await processHttpOK<UserSummary>( 
            axios.get(`${serverURL}/server/v1/qrcode/${encodeURIComponent(qrcode)}/user`, 
                        {headers: {'Authorization': "Bearer " + accessToken,
                                    "accept-language":languageCode
                                    }
                        })
            );
    return user;        
}


export async function retrieveQRCode(accessToken:string) {
    const serverURL = getServerURL();
    return processHttpOK<QRCodeResponse>(
        axios.get(`${serverURL}/server/v1/user/qrCode`,{headers: {'Authorization': "Bearer " + accessToken,"accept-language":getLanguageCode()}})
    )
}

export async function registerPubKey(accessToken:string, request:AddCertificationRequest){
    const serverURL = getServerURL();

    return processHttpOK<ServerApiResponse>(
        axios.post(`${serverURL}/server/v1/certificate`, request, {headers: {'Authorization': "Bearer " + accessToken,"accept-language":getLanguageCode()}})
    )
}

export async function retrieveUnishellPoints(accessToken:string) {
    const serverURL = getServerURL();
    return processHttpOK<number>(
        axios.get(`${serverURL}/server/v1/user/point`,{headers: {'Authorization': "Bearer " + accessToken,"accept-language":getLanguageCode()}})
    )
}

export async function transferUnishellPoints(accessToken:string, request:TransferRequest){
    const serverURL = getServerURL();

    return processHttpOK<ServerApiResponse>(
        axios.post(`${serverURL}/server/v1/transfer`, request, {headers: {'Authorization': "Bearer " + accessToken,"accept-language":getLanguageCode()}})
    )
}

export async function retrieveUserSummaryByUsername(accessToken:string, userName:string) {
    const serverURL = getServerURL();
    return processHttpOK<UserSummary>(
        axios.get(`${serverURL}/server/v1/users/${encodeURIComponent(userName)}`,{headers: {'Authorization': "Bearer " + accessToken,"accept-language":getLanguageCode()}})
    )
}

export async function retrieveTransferList (accessToken:string, page:number, pageSize:number) {
    ///server/v1/transfer
    const serverURL = getServerURL();
    return processHttpOK< PagedResponse <TransferSummary> > (
        axios.get(`${serverURL}/server/v1/transfer/all?page=${page}&size=${pageSize}`,{headers: {'Authorization': "Bearer " + accessToken,"accept-language":getLanguageCode()}})
    )
}

export async function retrievePaymentOrderList (accessToken:string, page:number, pageSize:number) {
    ///server/v1/transfer
    const serverURL = getServerURL();
    return processHttpOK< PagedResponse <MerchantOrderBrief> > (
        axios.get(`${serverURL}/server/v1/payment/all?page=${page}&size=${pageSize}`,{headers: {'Authorization': "Bearer " + accessToken,"accept-language":getLanguageCode()}})
    )
}

export async function refreshToken(accessToken:string, request:RefreshRequest) {
    const serverURL = getServerURL();

    return processHttpOK<JwtAuthenticationResponse>(axios.post(`${serverURL}/server/v1/user/refreshToken`, request,
                                                    {headers: {'Authorization': "Bearer " + accessToken,"accept-language":getLanguageCode()}}));
}

export async function updateMyUserProfile(accessToken:string, request:ProfileRequest){
    const serverURL = getServerURL();

    return processHttpOK<UserProfile>(axios.post(`${serverURL}/server/v1/user/profile`, request,
                                                    {headers: {'Authorization': "Bearer " + accessToken,"accept-language":getLanguageCode()}}));    
}

export async function setLostPasswordEmail(request:ResetPasswordRequest) {
    const serverURL = getServerURL();

    return processHttpOK<any>(axios.post(`${serverURL}/server/v1/auth/resetPassword`, request,{headers:{"accept-language":getLanguageCode()}}));
}



export async function getPaymentConfig(accessToken:string){
    const serverURL = getServerURL();
    return processHttpOK<PaymentConfig>(axios.get(`${serverURL}/server/v1/settings`, 
                                                {headers: {'Authorization': "Bearer " + accessToken,"accept-language":getLanguageCode()}}));
}


export async function getStripeKey(accessToken:string){
    const serverURL = getServerURL();
    return processHttpOK<string>(axios.post(`${serverURL}/server/v1/payment/getStripeKey`, {}, 
                                        {headers: {'Authorization': "Bearer " + accessToken,"accept-language":getLanguageCode()}}));
}

export async function prepareStripePayment(accessToken:string, request:StripePrepareRequest){
    const serverURL = getServerURL();
    return processHttpOK<StripePrepareResponse>(axios.post(`${serverURL}/server/v1/payment/prepareStripe`, request, 
                                                            {headers: {'Authorization': "Bearer " + accessToken,"accept-language":getLanguageCode()}}));
}

export async function completeStripePayment(accessToken:string, request:StripeCompleteRequest){
    const serverURL = getServerURL();
    return processHttpOK<PaymentResponse>(axios.post(`${serverURL}/server/v1/payment/completeStripe`, request, 
                                                    {headers: {'Authorization': "Bearer " + accessToken,"accept-language":getLanguageCode()}}));
}

/**
 * @deprecated
 * @param accessToken 
 */
export async function getBrainTreeClientToken(accessToken:string) {
    const serverURL = getServerURL();
    return processHttpOK<string>(axios.get(`${serverURL}/server/v1/payment/token`, 
                                            {headers: {'Authorization': "Bearer " + accessToken,"accept-language":getLanguageCode()}}));    
}

/**
 * @deprecated
 * @param accessToken 
 * @param request 
 */
export async function createBrainTreeTransaction(accessToken:string, request:BrainTreeCheckoutRequest) {
    const serverURL = getServerURL();
    return processHttpOK<CheckoutResponse>(axios.post(`${serverURL}/server/v1/payment/checkout`, request, 
                                            {headers: {'Authorization': "Bearer " + accessToken,"accept-language":getLanguageCode()}}));    
}

export async function getPaymentOrder(accessToken:string, orderCode:string) {
    const serverURL = getServerURL();
    if(orderCode === 'TEST1111'){
        let order:PaymentOrder = {
            amount: 345.60,
            currency: 'UNIS',
            merhantName:'Natural Zenith',
            orderCode,
            orderDescription:'asdfsafas',
            orderId: 'NZ5432542',
            status: 'CREATED',
            expiredBy: (new Date()).getTime()+441343
        };
        return asyncDelay(order, 400);
    }else if(orderCode === 'TEST1112'){
        let order:PaymentOrder = {
            amount: 345.60,
            currency: 'UNIS',
            merhantName:'Natural Zenith',
            orderCode,
            orderDescription:'asdfsafas',
            orderId: 'NZ5432542',
            status: 'PAID',
            expiredBy: (new Date()).getTime()+441343
        };
        return asyncDelay(order, 400);
    }
    return processHttpOK<PaymentOrder>(axios.get(`${serverURL}/server/v1/order/merchant/${encodeURIComponent(orderCode)}`, 
                                        {headers: {'Authorization': "Bearer " + accessToken,"accept-language":getLanguageCode()}}));    
}

export async function cancelPaymentOrder(accessToken:string, orderCode:string) {
    const serverURL = getServerURL();
    if(orderCode==='TEST1111'){
        return asyncDelay(true, 400);
    }
    return processHttpOK<any>(axios.post(`${serverURL}/server/v1/payment/cancel`, 
                                                  {orderCode, reason:'user cancelled'},
                                                 {headers: {'Authorization': "Bearer " + accessToken,"accept-language":getLanguageCode()}}));    
}

export async function unishellPay(accessToken:string, request:UnishelPayRequest, orderCode:string) {
    const serverURL = getServerURL();
    if(orderCode==='TEST1111'){
        let response:ServerApiResponse = {
            success: true,
            message: 'payment done'
        }
        return asyncDelay(response, 400);
    }
    return processHttpOK<ServerApiResponse>(axios.post(`${serverURL}/server/v1/payment`, request, 
                                                        {headers: {'Authorization': "Bearer " + accessToken,"accept-language":getLanguageCode()}}));
}

export async function prepareSnappay(accessToken:string, request: SnappayPrepareRequest){
    const serverURL = getServerURL();

    return processHttpOK<SnappayPrepareResponse>(
        axios.post(`${serverURL}/server/v1/payment/prepareSnappay`, request, 
                    {headers: {'Authorization': "Bearer " + accessToken,"accept-language":getLanguageCode()}})
    )
}

export async function completeSnappay(accessToken:string, request:SnappayCompleteRequest){
    const serverURL = getServerURL();
    return processHttpOK<PaymentResponse>(
        axios.post(`${serverURL}/server/v1/payment/completeSnappay`, request, 
                    {headers: {'Authorization': "Bearer " + accessToken,"accept-language":getLanguageCode()}})
    )
}

export async function getCheckoutBrief(accessToken:string, orderCode: string){
    const serverURL = getServerURL();
    return processHttpOK<PaymentResponse>(
        axios.get(`${serverURL}/server/v1/payment/checkoutBrief/${orderCode}`, 
                    {headers: {'Authorization': "Bearer " + accessToken,"accept-language":getLanguageCode()}})
    )    
}

/** @deprecated */
export async function readPUblicNotice(version:string){
    return processHttpOK<string> (axios.get("https://unishell-notice.s3-ap-southeast-1.amazonaws.com/unishell-notice-1.1.yaml?ver="+version,
                                            {headers:{"accept-language":getLanguageCode()}}));
}

export async function readTerms(langCode: string, version:string){
    return processHttpOK<string> (axios.get(`https://app.cpnos.cn/app/unishell-terms-${langCode}.html?ver=${version}`,
                                            {headers:{"accept-language":getLanguageCode()}}));
}
export async function readPrivacy(langCode: string, version:string){
    return processHttpOK<string> (axios.get(`https://app.cpnos.cn/app/privacy-policy-${langCode}.html?ver=${version}`,
                                            {headers:{"accept-language":getLanguageCode()}}));
}

export async function readSetNutritionistNotice(langCode: string, version:string){
    return processHttpOK<string> (axios.get(`https://app.cpnos.cn/app/set-nutritionist-notice-${langCode}.html?ver=${version}`,
                                            {headers:{"accept-language":getLanguageCode()}}));
}

export async function readLabTestInstruction(langCode: string, version:string){
    return processHttpOK<string> (axios.get(`https://app.cpnos.cn/app/lab-test-instruction-${langCode}.html?ver=${version}`,
                                            {headers:{"accept-language":getLanguageCode()}}));
}

/** @deprecated */
export async function readADArray(version: string){
    return processHttpOK<string> (axios.get("https://unishell-ad.s3-ap-southeast-1.amazonaws.com/unishell-ad.yaml?ver="+version,
                                            {headers:{"accept-language":getLanguageCode()}}));
}

// mobile web invoked unishell app
export async function webInvokedUnishellApp(orderCode: string){
    const serverURL = getServerURL();
    return processHttpOK<AppError>(
        axios.post(`${serverURL}/server/v1/merchantOrder/schemeCalled`, orderCode, 
                    {headers: {'Content-Type': 'application/json',"accept-language":getLanguageCode()}})
    )
}

export async function checkAppUpgrade(request?:CheckUpgradeRequest) {
    const serverURL = getServerURL();
    let _request: CheckUpgradeRequest = {
        build:getManifest().buildVersion,
        appVersion:getManifest().appVersion,
        devicePlatform:getDevice().platform,
        deviceModel:getDevice().model,
        deviceUUID:getDevice().uuid,
        deviceSerial:getDevice().serial,
        deviceVersion:getDevice().version,
    };
    return processHttpOK<CheckUpgradeResponse>(
        axios.post(`${serverURL}/device/v1/checkUpgrade`,request||_request,{})
    );

}

export async function getNaturalZenithLastMonthCommission(accessToken:string) {
    const serverURL = getServerURL();
    return processHttpOK<CommissionRequest>(axios.get(`${serverURL}/server/v1/commission/naturalZenithLastMonthCommissions`, 
                                                {headers: {'Authorization': "Bearer " + accessToken,"accept-language":getLanguageCode()}}));

}

export async function postCommission(accessToken:string, request: CommissionDistributionRequest) {
    const serverURL = getServerURL();
    return processHttpOK<CommissionRequest>(axios.post(`${serverURL}/server/v1/commission/distribute`, request,
                                                {headers: {'Authorization': "Bearer " + accessToken,"accept-language":getLanguageCode()}}));

}

export async function searchUsers(accessToken:string, searchParms: SearchUserParms) {
    let queryParms:string[] = [];
    if(searchParms.nameKeyword){
        queryParms.push('name='+encodeURIComponent(searchParms.nameKeyword));
    }
    if(searchParms.emailKeyword){
        queryParms.push('email='+encodeURIComponent(searchParms.emailKeyword));
    }
    if(searchParms.roles){
        queryParms.push('role='+encodeURIComponent(searchParms.roles.join(',')));
    }
    const serverURL = getServerURL();
    let url = `${serverURL}/server/v1/users/search?page=${searchParms.page}&size=${searchParms.pageSize}`;
    if(queryParms.length>0){
        url += '&'+queryParms.join('&');
    }
    // alert('url='+url+' search parms='+JSON.stringify(searchParms));


    return processHttpOK<PagedResponse<UserProfile>>(axios.get(url, {headers: {'Authorization': "Bearer " + accessToken,"accept-language":getLanguageCode()}}));
}

export async function getRoleList(accessToken:string) {
    const serverURL = getServerURL();

    return processHttpOK<string[]>(axios.get(`${serverURL}/server/v1/users/role`, {headers: {'Authorization': "Bearer " + accessToken,"accept-language":getLanguageCode()}}));
}

export async function updateUserProfile(accessToken:string, user:UserUpdateRequest){
    const serverURL = getServerURL();

    return processHttpOK<any>(axios.post(`${serverURL}/server/v1/users/profile`, user,
                                         { headers: {'Authorization': "Bearer " + accessToken,"accept-language":getLanguageCode()} }));
     
}

export async function removeNutritionistRole(accessToken:string, username:string){
    const serverURL = getServerURL();
    return processHttpOK<any>(axios.delete(`${serverURL}/server/v1/nutritionist/${encodeURIComponent(username)}`, {headers: {'Authorization': "Bearer " + accessToken,"accept-language":getLanguageCode()}}));
}


export async function getDocSpecs(accessToken:string) {
    const serverURL = getServerURL();
    return processHttpOK<DocSpecResponse>(axios.get(`${serverURL}/server/v1/docs/specs`, {headers: {'Authorization': "Bearer " + accessToken,"accept-language":getLanguageCode()}}));
}

export async function getDoc(accessToken:string, docType: string, docUuid: string){
    const serverURL = getServerURL();
    return processHttpOK<Doc>(axios.get(`${serverURL}/server/v1/docs/${docType}/${docUuid}`, {headers: {'Authorization': "Bearer " + accessToken,"accept-language":getLanguageCode()}}));
}

export async function searchProductAPI(accessToken:string, request:DocSearchRequest){
    const serverURL = getServerURL();

    return processHttpOK<PagedResponse<DocSearchResponse>>(axios.post(`${serverURL}/server/v1/docs/search`, request, 
                                                                    {headers: {'Authorization': "Bearer " + accessToken,"accept-language":getLanguageCode()}}));
}

export async function getBookmarkedProducts(accessToken:string, page:number, pageSize:number){
    const serverURL = getServerURL();
    return processHttpOK<PagedResponse<DocSearchResponse>>(
                axios.get(`${serverURL}/server/v1/docs/bookmark/chanpin?page=${page}&size=${pageSize}`, 
                          {headers: {'Authorization': "Bearer " + accessToken,"accept-language":getLanguageCode()}}
                         )
        );    
}

export async function removeBookmark(bookmarkId:number, accessToken:string){
    const serverURL = getServerURL();
    return processHttpOK<any>(axios.delete(`${serverURL}/server/v1/docs/bookmark/${bookmarkId}`,{headers: {'Authorization': "Bearer " + accessToken,"accept-language":getLanguageCode()}}));
}

export async function bookmarkProduct(docId:string, accessToken:string){
    const serverURL = getServerURL();
    let request = {entityType:'chanpin', entityId:docId}
    return processHttpOK<string>(axios.post(`${serverURL}/server/v1/docs/bookmark`, request, 
                             {headers: {'Authorization': "Bearer " + accessToken,"accept-language":getLanguageCode()}}));
}

export async function updateProductIndex (accessToken: string) {
    const serverURL = getServerURL();
    return processHttpOK<UpdateProductIndexResponse>(axios.post(`${serverURL}/server/v1/docs/sync`, {headers: {'Authorization': "Bearer " + accessToken,"accept-language":getLanguageCode()}}));
} 
export async function showLatestProductIndexTask (accessToken: string) {
    const serverURL = getServerURL();
    return processHttpOK<UpdateProductIndexResponse>(axios.get(`${serverURL}/server/v1/docs/sync/-1`, {headers: {'Authorization': "Bearer " + accessToken,"accept-language":getLanguageCode()}}));
} 

export async function getShuyu(term:string, accessToken:string){
    const serverURL = getServerURL();
    return processHttpOK<{title:string, fields:{field_mingci:string}}>(
        axios.get(`${serverURL}/server/v1/docs/term/zh-hans/${encodeURIComponent(term)}`, 
                  {headers: {'Authorization': "Bearer " + accessToken,"accept-language":getLanguageCode()}})
        );
}

export async function sendUsMessage(accessToken:string, message: string){
    const serverURL = getServerURL();
    let request = {message}
    return processHttpOK<void>(axios.post(`${serverURL}/server/v1/userMessage`, request, 
                             {headers: {'Authorization': "Bearer " + accessToken,"accept-language":getLanguageCode()}}));
}
