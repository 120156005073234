import * as React from 'react';
import {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import { PagedResponse } from '../../api/transaction';
import { t } from '../../i18n';
import { WalletAppState } from '../../redux-store';
import { NoticeDialog } from '../../view/NoticeDialog';
import { AddressInfo2StrArray, NutritionistBrief, NutritionistInfo, ShopUserInfo, toImgSrc } from '../ajax-data/ajax-data-types';
import { getMyShopUserInfo,  searchNutritionists, NutritionistSearchFilter, updateShopUserInfo } from '../ajax-data/shopping-server-api';
import { doAJAXCall } from '../common/ajax-call';
import { SearchTextInput } from '../common/search-text-input';
import { Pagination } from '../common/render-pagination';
import { BaseShoppingPageProps, } from '../common/shopping-page-common';
import { gotoShoppingPathNSavePrevPath, updateCurrentPathParms } from '../history/shopping-history';

import './search-nutritionist.scss';
import { amIAdmin, amINutritionist, getLanguageCode } from '../../local-storage/local-storage';
import { Avatar, Button, Grid } from '@material-ui/core';
import * as _ from 'lodash';
import { Toast } from '../../view/Toast';
import { BaseUnishellPageState, renderInitErrorUnishellPage, renderInitUnishellPage, renderUnishellPage } from '../../unishell-page-common/unishell-page-common';
import { getExternContantsInMemory, loadExternContatnsIfNecessary } from '../../external-constants/extern-constants';
import { SimplePhoneNumber } from '../../widget/phone-number/phone-number';
import { SimpleEmail } from '../../widget/email/email';
import { UButton } from '../../widget/button/ubutton';
import { calcRationClass, RatioXXS, RatioXXXS, scrollBodyToTop } from '../../util/Util';

import {ReactComponent as FilterIcon} from '../../images/filter-fill.svg';

import {ReactComponent as PhoneIcon} from '../../images/phone.svg';
import {ReactComponent as EmailIcon} from '../../images/email.svg';
import {ReactComponent as AddrIcon} from '../../images/pin.svg';
import {ReactComponent as LanguageIcon} from '../../images/language-choice.svg';

import { ConfirmDialogView } from '../../view/confirm-dialog/ConfirmDialogView';
import { ThreeStateCheckbox } from '../../widget/three-state/three-state-checkbox';
import { ReadMore } from '../../widget/read-more/read-more';
import { readSetNutritionistNotice } from '../../wallet-server-api/wallet-server-api';


export interface SearchNutritionistFilter {
    initialSearchText?: string;
    initialGender?: string;
    initialPage?: number;
    initialPageSize?: number;
}

export interface SearchNutritionistProps extends SearchNutritionistFilter, BaseShoppingPageProps {
}

interface SearchNutritionistState extends BaseUnishellPageState {
    hasNutritionistRole: boolean;
    myShopUserInfo?: ShopUserInfo;

    page:number;
    pageSize: number;
    gender?: string;

    searchResult?:PagedResponse<NutritionistBrief>;

    expendSearchForm?: boolean;
}

class SearchNutritionistView extends Component<SearchNutritionistProps, SearchNutritionistState>{
    private errorDialog:NoticeDialog|undefined;
    private searchInput:SearchTextInput|undefined;
    private toast: Toast | undefined;
    private confirmDialog:ConfirmDialogView | undefined;

    constructor(props: SearchNutritionistProps){
        super(props);
        const hasNutritionistRole = amINutritionist();
        this.state = {
                        pageInitState:'PAGE_INIT', 
                        page:this.props.initialPage||0, 
                        pageSize: this.props.initialPageSize||10,
                        gender: this.props.initialGender,
                        hasNutritionistRole
                     }
    }

    getTitle(){
        return t('专业服务查询');
    }
    render(){
        if(this.state.pageInitState === 'PAGE_INIT'){
            return <React.Fragment>
                        {renderInitUnishellPage(
                            {
                                title: this.getTitle(), 
                                className:'shopping-search-nutritionist',
                                message:t('初始化...')
                            })
                        }
                        <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                        <Toast close={true} duration={2500} message='' ref={e=>{this.toast=e!}} toastType='info'/>
                    </React.Fragment>
        }
        if(this.state.pageInitState === 'PAGE_LOAD_FAILED'){
            return <React.Fragment>
                        {renderInitErrorUnishellPage(
                            {
                                title: this.getTitle(), 
                                className:'shopping-search-nutritionist',
                                errorMessage: this.state.initErrorMessage||t('初始化失败'),
                                onTryAgain: ()=>{ this.initializeAgain();    }
                            })
                        }
                        <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                        <Toast close={true} duration={2500} message='' ref={e=>{this.toast=e!}} toastType='info'/>
                    </React.Fragment>
        }
        // loading data succeeded
        return renderUnishellPage({
            title: this.getTitle(), 
            className:'shopping-search-nutritionist',
            underTopHeader: this.renderSearchForm(),
            content:(
                <React.Fragment>
                    { this.renderSucceedContent() }
                    <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                    <Toast close={true} duration={2500} message='' ref={e=>{this.toast=e!}} toastType='info'/>
                    <ConfirmDialogView ref={e=>this.confirmDialog=e!} open={false} />
                </React.Fragment>
            )
        }) 
    }

    renderSearchForm():JSX.Element {
        const {gender} = this.state;

        return (
            <div className='search-nutritionist-form'>
                <div className='keyword-input'>
                    <FilterIcon className='filter-icon' 
                                onClick={()=>{ this.setState({expendSearchForm: !this.state.expendSearchForm })  }}/>
                    <SearchTextInput 
                        placeholder={t('搜索执业营养师')} 
                        initialValue={this.props.initialSearchText} 
                        ref={x=>{this.searchInput=x!}} 
                        onSearch={this.doSearch}/>
                </div>

                {this.state.expendSearchForm? 
                <div className='sex-filter'>
                    <div className='label'>{t('性别')}</div>
                    <ThreeStateCheckbox 
                        trueLabel={t('male', 'sex')} falseLabel={t('female', 'sex')}
                        status={gender==='male'? true : (gender==='female'? false : undefined)}
                        color='primary'
                        onChange={status=>{
                            if(status === undefined){
                                this.setState({gender: undefined})
                            }else if(!!status){
                                this.setState({gender: 'male'})
                            }else{
                                this.setState({gender: 'female'})
                            }
                        }}
                    />
                </div>
                :null
                }
            </div>)
    }

    renderSucceedContent():JSX.Element {
        return (
            <div className='shopping-search-nutritionist-main-pane'>
                <Pagination data={this.state.searchResult!} page={this.state.page} pageSize={this.state.pageSize} 
                            loadData={this.loadPaginationData}
                            changePageSize={(newPageSize, newPage)=>{
                                this.loadPaginationData(newPage, newPageSize);
                            }}
                >
                { this.renderNutritionistBriefList(this.state.searchResult!.content) }
                </Pagination>

            </div>
        )
    }

    gotoViewNutritionist(nutritionistId:string){
        gotoShoppingPathNSavePrevPath('main/shopping/nutritionist-profile', {nutritionistId})
    }

    setMyNutritionist(nutritionistUsername:string){
        let myShopUserInfo = _.cloneDeep(this.state.myShopUserInfo!);
        myShopUserInfo.nutritionist = nutritionistUsername;
        myShopUserInfo.nutritionistLinkTime = Date.now();
        
        const langCode = getLanguageCode();

        let whatIsFor = t('读取健康管理告知书'); 
        doAJAXCall({
            whatIsFor: t('设为我的执业营养师'),
            whatIsForFn: ()=>{
                return whatIsFor;
            },
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken:string)=>{
                const envNoticeTimeout = process.env.REACT_APP_NOTICE_TIMEOUT || '60'; // seconds
                const timeout = parseInt(envNoticeTimeout)*1000;
                let version = Math.floor((Date.now() / timeout))+'';
        
                const noticeHtml = await readSetNutritionistNotice(langCode, version);
                this.confirmDialog!.show({
                    title: t('健康管理告知书'),
                    message: <div dangerouslySetInnerHTML={{__html: noticeHtml}} />,
                    handleClose: async (ok)=>{
                        if(!ok) return;

                        whatIsFor = t('设为我的执业营养师');
                        await updateShopUserInfo(accessToken, myShopUserInfo);
                        this.setState({myShopUserInfo});        
                    }
                })

            }
        })        
    }

    unsetMyNutritionist(){
        let myShopUserInfo = _.cloneDeep(this.state.myShopUserInfo!);
        delete myShopUserInfo.nutritionist;
        delete myShopUserInfo.nutritionistLinkTime;
        
        doAJAXCall({
            whatIsFor: t('取消我的执业营养师'),
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken:string)=>{
                await updateShopUserInfo(accessToken, myShopUserInfo);
                this.setState({myShopUserInfo}, ()=>{
                    this.toast!.sendMessage('取消我的执业营养师', 'info');
                });
            }
        })
    }

    renderAvatar(nutritionist:NutritionistBrief):JSX.Element{
        let avatarurl = nutritionist!.avatar? toImgSrc(nutritionist!.avatar.url) : undefined;

        return <div className='avatar-section'>
                    <Avatar className='nutritionist-avatar' 
                            variant='rounded'
                            src={avatarurl}/>
                    <div>
                        {this.renderName(nutritionist)}
                        {this.renderTitles(nutritionist)}
                        {this.renderGender(nutritionist)}
                    </div>        
               </div>
    }

    renderNutritionistBrief(nutritionist:NutritionistBrief):JSX.Element {
        const {ratioClass, vw,  mw, ratio} = calcRationClass();
        let xs = 12;

        if(ratio >= RatioXXXS*3){
            xs = 6;
        }
        if(ratio >= RatioXXXS*5){
            xs = 4;
        }
        if(ratio >= RatioXXXS*7){
            xs = 3;
        }
        if(ratio >= RatioXXXS*9){
            xs = 2;
        } 
        if(ratio >= RatioXXXS*14){
            xs = 1
        }

        return (
            <Grid item xs={xs as 12}>
                <div className='nutritionist-brief2'>
                    {this.renderAvatar(nutritionist)}
                    {this.renderExpertice(nutritionist)}
                    {this.renderSvcLanguages(nutritionist)}
                    {this.renderAddress(nutritionist)}
                    {this.renderActions(nutritionist)}
                </div>
            </Grid>
        )
    }
    renderName(nutritionist:NutritionistBrief):JSX.Element {
        return <div className='nutritionist-name'>{nutritionist.name}</div>
    }

    renderTitles(nutritionist:NutritionistBrief):JSX.Element {
        const titles = (nutritionist.titles || []).map(x=>x.trim()).filter(x=>!!x);

        return <div className='titles'>
               {titles.map((x,idx)=>{
                    if(idx<titles.length-1)
                        return <Fragment>{x},&nbsp;</Fragment>
                    else
                        return <Fragment>{x}.</Fragment>
                })}
               </div>
    }

    renderExpertice(nutritionist:NutritionistBrief){
        if(!nutritionist.expertIn) return null;

        return <ReadMore className='nutritionist-brief2-expertice' title={t('特长')} threshold={8}>
                    {nutritionist.expertIn}
                </ReadMore>
    }
    renderGender(nutritionist:NutritionistBrief){
        let sex = t(nutritionist.gender||'', 'sex');
        return <div className='gender-section'>
                {t('性别')}:&nbsp;
                {sex}
               </div>
    }

    renderInstitution(nutritionist:NutritionistBrief):JSX.Element {
        let institution = nutritionist.businessAddress? nutritionist.businessAddress.institution : '';
        return <div className='institution'>{institution}</div>
    }

    renderPhone(nutritionist:NutritionistBrief):JSX.Element {
        return <div className='phone-section'>
                <PhoneIcon className='phone-icon'/>
                <SimplePhoneNumber phoneNumber={nutritionist.officePhone}/>
               </div>
    }
    renderEmail(nutritionist:NutritionistBrief):JSX.Element {
        return <div className='email-section'>
                <EmailIcon className='email-icon'/>
                <SimpleEmail email={nutritionist.officeEmail}/>
               </div>
    }
    renderSvcLanguages(nutritionist:NutritionistBrief):JSX.Element{
        const spokenLanguageOptions = getExternContantsInMemory().spokenLanguageOptions;
        
        const langList = (nutritionist!.language||[]).map(x=> {
            let l = spokenLanguageOptions.find(v=>v.key === x);
            return l? l.label : '';
        }).filter(x=> !!x);

        return <div className='language-section'>
                <LanguageIcon className='language-icon'/>
                <div>
                {langList.join(' ')}    
                </div>
        </div>

    }

    renderAddress(nutritionist:NutritionistBrief):JSX.Element{
        const addrLines = AddressInfo2StrArray(nutritionist.businessAddress);
        return <div className='address-section'>
                <AddrIcon className='address-icon'/>
                <div className='address'>
                    {addrLines.filter((item,idx) => idx >= addrLines.length-2).map(x=>{
                        return <div className='address-line'>{x}</div>
                    })}
                </div>
               </div>
    }

    renderActions(nutritionist:NutritionistBrief):JSX.Element {
        let myNutritionistId = this.state.myShopUserInfo!.nutritionist;
        let isMyNutritionist = nutritionist.username === myNutritionistId;
        let setMyNutritionistButton = (!myNutritionistId && !this.state.hasNutritionistRole) ?
                                      <UButton variant='contained' size='small' color='primary'
                                              onClick={()=>{
                                                  this.setMyNutritionist(nutritionist.username);
                                              }}
                                      >{t('设为我的执业营养师')}</UButton> : null;
        let unsetMyNutritionistButton = myNutritionistId && myNutritionistId === nutritionist.username? 
                                        <UButton variant='contained' size='small' color='alert'
                                                onClick={()=>{
                                                    this.unsetMyNutritionist();
                                                }}
                                        >{t('取消我的执业营养师')}</UButton> : null;

        return <div className='action-section'>
                {setMyNutritionistButton} {unsetMyNutritionistButton} 
                <UButton variant='contained' size='small' color='secondary'
                         onClick={()=>{
                             this.gotoViewNutritionist(nutritionist.username);
                         }}
                >{t('详细')}</UButton>
               </div>                                
    }


    renderNutritionistBriefList(list:NutritionistBrief[]){
        return (
            <Grid container spacing={0}>
            {list.map(x=>{
                return this.renderNutritionistBrief(x);
            })}
            </Grid>
        )
    }

    getSearchNutritionistsFilter():NutritionistSearchFilter{

        let filter:NutritionistSearchFilter = {
            text: this.getSearchText(),
            status: amIAdmin() ? undefined : 'active',
            gender: this.state.gender
        }
        return filter;
    }

    loadPaginationData = async (page:number, pageSize:number) => {
        await this.loadData(this.getSearchNutritionistsFilter(), page, pageSize);
    }

    doSearch = ()=>{
        this.loadData(this.getSearchNutritionistsFilter(), this.state.page, this.state.pageSize);
    }
    getSearchText(){
        if(this.searchInput){
            return this.searchInput.getValue() || '';
        }else{
            return this.props.initialSearchText||'';
        }
    }


    componentDidMount(){
        this.loadData(this.getSearchNutritionistsFilter(), this.state.page, this.state.pageSize);
    }

    componentDidUpdate(prevProps:any, prevState:SearchNutritionistState){
        if(prevState.page !== this.state.page){
            scrollBodyToTop();
        }
        this.updatePathParms();
    }


    initializeAgain(){
        this.setState({pageInitState:'PAGE_INIT', initErrorMessage:undefined},
                      ()=>{
                        this.loadData(this.getSearchNutritionistsFilter(), this.state.page, this.state.pageSize);
                    })

    }

    loadData(filter:NutritionistSearchFilter, page: number, pageSize:number){
        doAJAXCall({
            whatIsFor: t('专业服务查询'),
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken)=>{ 
                await loadExternContatnsIfNecessary();

                let myShopUserInfo = await getMyShopUserInfo(accessToken);
                let searchResult = await searchNutritionists(accessToken, page, pageSize, filter)
                this.setState({searchResult, page:searchResult.page, pageSize:searchResult.size, pageInitState:'PAGE_LOAD_SUCCESS',myShopUserInfo});
            },
            onError: (errorMessage:string)=>{ this.setState({pageInitState:'PAGE_LOAD_FAILED', initErrorMessage:errorMessage})}                                           
        })        
    }

    updatePathParms(){
        let parms:SearchNutritionistFilter = {
            initialSearchText: this.getSearchText(),
            initialGender: this.state.gender,
            initialPage: this.state.page,
            initialPageSize: this.state.pageSize
           };
    
        updateCurrentPathParms('main/tools/nutritionist-list', parms)
    }
    
}

export const SearchNutritionist = connect(
    (state:WalletAppState)=>({languageCode: state.language.langCode})
)(SearchNutritionistView);
