import * as React from 'react';
import {Component, Fragment} from 'react';

import './WalletPageView.scss';
import {t} from '../../i18n';
import { ReactReduxContext } from 'react-redux';

import { ConfirmDialogView } from '../confirm-dialog/ConfirmDialogView';
import { BaseUnishellPageState, renderInitErrorUnishellPage, renderInitUnishellPage, renderUnishellPage } from '../../unishell-page-common/unishell-page-common';
import { gotoShoppingPathNSavePrevPath } from '../../shopping/history/shopping-history';
import { loadExternContatnsIfNecessary } from '../../external-constants/extern-constants';
import { doAJAXCall } from '../../shopping/common/ajax-call';
import { NoticeDialog } from '../NoticeDialog';
import { ListMenu } from '../list-menu/list-menu';
import { PromptDialogView } from '../prompt-dialog/PromptDialogView';

interface States extends BaseUnishellPageState{
    walletMenuItemHeight?:number
}

// plain old global js function to call phone qr code scanning function
declare function scanQRCode():void;
declare function scanOrderCode():void;

export class WalletPageView extends Component<{},States> {
    private openUrlDialog:ConfirmDialogView | undefined;
    private errorDialog: NoticeDialog|undefined;
    private orderCodeDialog: PromptDialogView | undefined;

    constructor(props:any){
        super(props);
        this.state = {pageInitState:'PAGE_INIT'};
    }
    
    render(){
        return  <ReactReduxContext.Consumer>
                {({store})=>{
                    if(this.state.pageInitState==='PAGE_INIT'){
                        return <Fragment>
                                    {
                                        renderInitUnishellPage(
                                            {
                                                title: t('积分'),
                                                className: 'wallet-page',
                                                message: t('初始化...')    
                                            }
                                        )
                                    
                                    }
                                    <NoticeDialog ref={(e)=>{this.errorDialog=e!}} open={false} />
                                </Fragment> 
                    }
                    if(this.state.pageInitState === 'PAGE_LOAD_FAILED'){
                        return <Fragment>
                                {
                                    renderInitErrorUnishellPage({
                                        title: t('积分'),
                                        className: 'wallet-page',
                                        errorMessage: this.state.initErrorMessage!
                                    })
                                }
                                </Fragment>
                    }
            

                    const gotoMyAsset = ()=>{
                        gotoShoppingPathNSavePrevPath('main/wallet/asset');
                    }
                    // const gotoMyTransfer = ()=>{
                    //     gotoShoppingPath('main/wallet/receive-transfer');
                    // }
                    const gotoPurchase = ()=>{
                        gotoShoppingPathNSavePrevPath('main/wallet/purchase-points');
                    }
                    // const gotoFinance = ()=>{
                    //     let link = 'https://unishell.com/index.php?route=information/information&information_id=13';
                    //     confirmOpenURL(link, this.openUrlDialog!);
                
                    // }
                    const goto3rdPartyOrderList = ()=>{
                        gotoShoppingPathNSavePrevPath('main/wallet/payment-order-list');
                    }
                    // const gotoMarketTrend = ()=>{
                    //     let link = 'https://unishell.com/index.php?route=information/information&information_id=12';
                    //     confirmOpenURL(link, this.openUrlDialog!);
                    // }
                    const manualUnishellPay = ()=> {
                        this.orderCodeDialog!.show(t('手动U点支付'), <span>{t('请输入或复制Unishell订单号')}</span>);
                    }
                    const orderCodeDialogCallback = (ok:boolean, value?:string)=>{
                        if(ok && value){
                            // @ts-ignore
                            let orderCode = window.getPastedOrderCode(value, false);
                            if(orderCode){
                                gotoShoppingPathNSavePrevPath('unishell-pay', {orderCode, caller:'manualUnishellPay'});
                            }else{
                                alert(t('无效订单号'));
                            }
                        }
                    }
                    const unishellPay = ()=>{
                        if(scanOrderCode) scanOrderCode();
                        else manualUnishellPay();
                    }
    
                    const gotoScanTransfer = ()=>{
                        scanQRCode();
                    }
                    const gotoManualTransfer = ()=>{
                        gotoShoppingPathNSavePrevPath('main/wallet/receive-transfer/manual-transfer')
                    }
                    const gotoReceiveUnishell = ()=>{
                        gotoShoppingPathNSavePrevPath('main/wallet/receive-transfer/receive')
                    }
                    const gotoTransferList = ()=>{
                        gotoShoppingPathNSavePrevPath('main/wallet/transfer-list')
                    }
    
    
                    const content =(
                        <Fragment>
                            <div className='wallet-menu-list'>
                                <ListMenu  label={t('会员积分')} onClick={gotoMyAsset} isActionButton={true}></ListMenu>
                                <ListMenu label={t('会员充值','WalletPageView')} onClick={gotoPurchase} isActionButton={true}/>
                                <ListMenu label={t('积分管理')}>
                                    <ListMenu label={t('扫码转出')} isActionButton={true} onClick={gotoScanTransfer}/>
                                    <ListMenu label={t('积分转出')} isActionButton={false} onClick={gotoManualTransfer}/>
                                    <ListMenu label={t('积分转入')} isActionButton={false} onClick={gotoReceiveUnishell}/>
                                    <ListMenu label={t('积分交易记录')} isActionButton={false} onClick={gotoTransferList}/>
                                </ListMenu>
                                <ListMenu label={t('商业用户')}>
                                    <ListMenu label={t('第三方订单扫码支付','unishellPay')} 
                                              isActionButton={true} onClick={unishellPay}/>
                                    <ListMenu label={t('第三方订单手动支付','unishellPay')} 
                                              isActionButton={false} onClick={manualUnishellPay}/>
                                    <ListMenu label={t('商业用户订单记录','paymentOrderList')} 
                                               onClick={goto3rdPartyOrderList} isActionButton={false}/>
                                </ListMenu>
                            </div>
                            <ConfirmDialogView ref={e=>this.openUrlDialog=e!} open={false} />
                            <NoticeDialog ref={(e)=>{this.errorDialog=e!}} open={false} />
                            <PromptDialogView open={false} yesLabel={t('确认')} handleClose={orderCodeDialogCallback} ref={dialog=>{this.orderCodeDialog=dialog!}}/>
                        </Fragment>
                    )
    

                    return renderUnishellPage({
                        title: t('积分'),
                        className: 'wallet-page',
                        content
                    });
                }}
                </ReactReduxContext.Consumer>
    }

    componentDidMount(){
        doAJAXCall({
            whatIsFor: t('初始化'),
            errorDialog: this.errorDialog!,
            ajaxCall: async ()=>{
                await loadExternContatnsIfNecessary();
                this.setState({pageInitState: 'PAGE_LOAD_SUCCESS'})
            },
            onError: (errorMsg)=>{
                this.setState({pageInitState: 'PAGE_LOAD_FAILED', initErrorMessage: errorMsg})
            }
        })
    }
}