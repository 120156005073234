import * as React from 'react';
import {Component, Fragment} from 'react';
import {batch, ReactReduxContext} from 'react-redux';
import {WalletAppState} from '../redux-store';
import { Action, Dispatch } from "redux";
import {t} from '../i18n';
import './Login.scss';
import { startAjaxCall,succAjaxCall,failedAjaxCall} from '../redux-store/ajax-call/actions';
import {signin,retrieveUserProfile,retrieveQRCode, registerPubKey, retrieveUnishellPoints, setLostPasswordEmail, checkAppUpgrade} from '../wallet-server-api/wallet-server-api';
import { getAjaxErrorMessage } from "..//wallet-server-api/ajax-common";
import uuidv4 from 'uuid/v4';
import {LoginView} from '../view/LoginView';
import { LoginRequest, UserProfile, QRCodeResponse, ResetPasswordRequest, ROLE_ADMIN, ROLE_USER } from '../api/user';
import {setCurrentPathPath} from '../redux-store/path/actions';
import {SessionToken,Profile,LoginSessionState} from '../redux-store/session';
import { NoticeDialog } from '../view/NoticeDialog';
import { genECCPair } from '../cypto/crypto';
import { readPersistData, PersistData, savePersistData, saveLastLoginEmail, saveLoginSession, setLanguageCode, setLanguageCodeFromUserProfile } from '../local-storage/local-storage';
import { sha256 } from 'js-sha256';
import { setPointsRecord } from '../redux-store/points';
import jwt_decode from 'jwt-decode';
import { setLanguageRecord } from '../redux-store/language';
import { clearStackNGotoShoppingPath } from '../shopping/history/shopping-history';
import { getCordova, info } from '../globals';
import {ReactComponent as ShoppingCartIcon} from '../images/shopping-cart.svg';
import {ReactComponent as HeartIcon} from '../images/nutritionist-fill.svg';
import {ReactComponent as AnnouceIcon} from '../images/high-volume.svg';
import {ReactComponent as CheckIcon} from '../images/draw-check-mark.svg';
import {ReactComponent as UpgradeIcon} from '../images/upgrade.svg';

import { UButton } from '../widget/button/ubutton';
import { CheckUpgradeResponse } from '../api/common';
import { simulate_upgrade, simulate_upgradeResponse } from '../view/send-message/upgrade/upgrade-app';
import { SimpleNote } from '../widget/note/note';
import { getMyShopUserInfo } from '../shopping/ajax-data/shopping-server-api';
import { store } from '..';
import { setShopUserInfoRecord } from '../redux-store/shop-user';
import { setShopUserInfo } from '../view/header/HeaderView2';
import { hideGlobalSpinner, showGlobalSpinner } from '../util/Util';

interface MyState {
    whatIsFor?:string, 
    error?:string,
    loginSucceeded?: boolean;
    upgradeResponse?:CheckUpgradeResponse;
}

const simulate = true;

export class Login extends Component<{},MyState> {
    private errorDialog: NoticeDialog|undefined;

    constructor(props:any){
        super(props);
        this.state = {whatIsFor: undefined, error: undefined}
    }

    login = async (dispatch:Dispatch, request:LoginRequest) => {
        info(`sign in`);
        const whatIsFor = t(`用户登录`);
        const uuid = uuidv4();

        try{
            let authResponse = await signin(request);
            const jwt = (jwt_decode(authResponse.accessToken)) as {exp:number};
            let token:SessionToken = {...authResponse, expireTimestamp: jwt.exp, createdDtm: Date.now()/1000};

            let [userProfile, qrCode, points, shopUserInfo] = await Promise.all([retrieveUserProfile(token.accessToken), retrieveQRCode(token.accessToken),
                                                                    retrieveUnishellPoints(token.accessToken),
                                                                    getMyShopUserInfo(token.accessToken),
                                                                ]);
            setLanguageCodeFromUserProfile(userProfile.language);
            let olddata = readPersistData(userProfile.username);

            let pair = !olddata ? await genECCPair() : olddata.keyPair;
            const pkeyHash=sha256(pair.pubKey);
            if(!olddata){
                savePersistData(userProfile.username, {version:'1.0', keyPair:pair, pkeyHash, username: userProfile.username})
            }
            await registerPubKey(token.accessToken, {certificate:pair.pubKey, keyId:pkeyHash});

            saveLastLoginEmail(request.usernameOrEmail);
            const sessionData: LoginSessionState = {token, profile:{...userProfile,...qrCode}}
            saveLoginSession(sessionData);

            await setShopUserInfo();

            let upgradeResponse:CheckUpgradeResponse|undefined;
            if(!!getCordova()){
                upgradeResponse = await checkAppUpgrade();
            }else if(simulate_upgrade){
                upgradeResponse = simulate_upgradeResponse;
            }
            dispatch(setPointsRecord(points));

            let  memberOnly = userProfile.roles.length === 1 && userProfile.roles[0] === ROLE_USER;
            if(memberOnly || upgradeResponse && (upgradeResponse.needUpgrade || upgradeResponse.newRelease)){
                this.setState({loginSucceeded: true, upgradeResponse});
            }else{
                clearStackNGotoShoppingPath('main/shopping/search');
            }
        }catch(error){
            this.setState({whatIsFor, error: getAjaxErrorMessage(error)});
        }finally{
            hideGlobalSpinner();
        }
    }

    clearState = ()=>{
        this.setState({whatIsFor:undefined, error: undefined});
    }

    updateLanguage = (dispatch:Dispatch, langCode:string) => {
        dispatch(setLanguageRecord(langCode));
    }

    render(){
        return ( /* use redux context directly to simplify code assuming Login will be recreated when switch page.
                    Otherwise, we need to use cumbersome connect and thunk 
                  */
            <ReactReduxContext.Consumer>
            { ({store})=>{
                const state:WalletAppState = store.getState();
                const notice = state.pagePath.parms? state.pagePath.parms['notice']: undefined;
                const password = state.pagePath.parms? state.pagePath.parms['password']: undefined;

                const gotoLostPassword = (email: string)=>{
                    store.dispatch(setCurrentPathPath('lost-password', {email}));
                }
                return <Fragment>
                        <LoginView 
                            login={(request:LoginRequest)=>{
                                    // show overlay immediately to block user from click login twice
                                    showGlobalSpinner();
                                    setTimeout(()=>{
                                        this.setState({ whatIsFor:undefined, 
                                            error: undefined, 
                                            loginSucceeded: false, 
                                            upgradeResponse: undefined}, 
                                            ()=>{
                                                this.login(store.dispatch, request);                            
                                            });
                                    }, 50);
                                }
                            }
                            gotoRegisterAccount={()=>{ 
                                        store.dispatch(setCurrentPathPath('login/register-user')); 
                                    } }
                            notice={notice} password={password}
                            gotoLostPassword={gotoLostPassword}
                            updateLanguage={(langCode:string)=>{this.updateLanguage(store.dispatch, langCode)}}
                         />
                        {
                            this.state.error? 
                                <NoticeDialog open={true} title={this.state.whatIsFor+t(' 失败')} message={this.state.error} onClose={this.clearState}/>: null
                        }
                        <NoticeDialog open={false} ref={e=> {this.errorDialog=e!}} title='' message=''/>
                        {this.state.loginSucceeded? this.renderPostLogin(): null}
                    </Fragment>
                }
            }
            </ReactReduxContext.Consumer>
        )
    }

    renderPostLogin(){
        let upgradeButton:JSX.Element | null = null;

        if(this.state.upgradeResponse){
            if(this.state.upgradeResponse.needUpgrade){
                upgradeButton = <Fragment>
                    <SimpleNote noticeType='warn' message={t('当前版本太旧')}/>
                    {/* <UButton variant='contained' color='secondary' size='medium'
                                onClick={()=>{
                                clearStackNGotoShoppingPath('main/tools/upgrade-app');
                                }}
                    >
                        <UpgradeIcon className='icon'/> {t('升级新版本')}
                    </UButton> */}
                </Fragment>
            }else if(this.state.upgradeResponse.newRelease){
                upgradeButton=<Fragment>
                    <SimpleNote noticeType='info' message={t('检测到新版本')}/>
                    {/* <UButton variant='contained' color='secondary' size='medium'
                                onClick={()=>{
                                clearStackNGotoShoppingPath('main/tools/upgrade-app');
                                }}
                    >
                        <UpgradeIcon className='icon'/> {t('升级新版本')}
                    </UButton> */}
                </Fragment>
            }else{
                upgradeButton = null;
            }
        }
        return (
            <div className='annoucement-wrapper'>
                <div className='annoucement-panel'>
                    <div className='login-success'>
                        <CheckIcon className='check-icon'/> 
                        <div className='header'>{t('登录成功！')} </div>
                    </div>
                    <div className='annoucement'>
                        {/* <div className='annouce-header'><AnnouceIcon className='annouce-icon'/> {t('平台公告')}</div> */}
                        <p className='annouce'>{t('annoucement', 'login')}</p>
                    </div>
                    <div className='buttons'>
                        {upgradeButton}

                        <UButton variant='contained' color='primary' size='medium'
                                 onClick={()=>{
                                    clearStackNGotoShoppingPath('main/shopping/search');
                                 }}
                        >
                            <ShoppingCartIcon className='icon'/> {t('去逛商城')}
                        </UButton>
                        <UButton variant='contained' color='primary' size='medium'
                                 onClick={()=>{
                                    clearStackNGotoShoppingPath('main/tools/nutritionist-list');
                                 }}
                        >
                            <HeartIcon className='icon'/> {t('专业咨询')}
                        </UButton>
                    </div>
                </div>
            </div>    
        )
    }
}
