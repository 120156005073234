import * as React from 'react';
import {Component} from 'react';
import { connect } from 'react-redux';
import { PagedResponse } from '../../api/transaction';
import { t } from '../../i18n';
import { WalletAppState } from '../../redux-store';
import { calcRationClass, formatDateTime, formatPrice, getCurrencyOption, getMaxThumbnailImgSize, getServerURL, RatioXXS, scrollBodyToTop } from '../../util/Util';
import { NoticeDialog } from '../../view/NoticeDialog';
import { ShopOrderCommissionStatus, CurrencyOption, ProductCandidateBrief, ShopOrderCommissionInfo } from '../ajax-data/ajax-data-types';
import { getCommissionStatuOptions, getCurrencyOptionsMap, searchAllShopOrderCommissions } from '../ajax-data/shopping-server-api';
import { doAJAXCall } from '../common/ajax-call';
import { ImageWithOverlay } from '../common/image-with-overlay';
import { Pagination } from '../common/render-pagination';
import { BaseShoppingPageProps} from '../common/shopping-page-common';


import './shop-order-commission-admin.scss';
import { FormControl, Grid, MenuItem, Select } from '@material-ui/core';
import { loadExternContatnsIfNecessary } from '../../external-constants/extern-constants';
import { BaseUnishellPageState, renderInitErrorUnishellPage, renderInitUnishellPage, renderUnishellPage } from '../../unishell-page-common/unishell-page-common';
import { PropertyListDataItem, PropertyListWidget } from '../../widget/property-list/property-list';
import { UDatePicker } from '../../widget/date-picker/date-picker';

interface AllShopOrderCommissionProps extends BaseShoppingPageProps{
}

interface AllShopOrderCommissionState extends BaseUnishellPageState{
    currencyOptionsMap?: {[key:string]:CurrencyOption[]};
    commissionStatusOptions?:{key:string, text:string}[];


    fromTime?: number;
    toTime?: number;
    status?: ShopOrderCommissionStatus|'';

    page:number;
    pageSize: number;

    searchResult?:PagedResponse<ShopOrderCommissionInfo>;
}

class AllShopOrderCommissionView extends Component<AllShopOrderCommissionProps, AllShopOrderCommissionState>{
    private errorDialog:NoticeDialog|undefined;

    constructor(props: AllShopOrderCommissionProps){
        super(props);
        this.state = {pageInitState:'PAGE_INIT',page:0, pageSize:20, status: ''}
    }

    getTitle(){
        return t('商城咨询费');
    }
    render(){
        if(this.state.pageInitState === 'PAGE_INIT'){
            return <React.Fragment>
                        {renderInitUnishellPage({title: this.getTitle(), message:t('初始化...')})}
                        <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                    </React.Fragment>
        }
        if(this.state.pageInitState === 'PAGE_LOAD_FAILED'){
            return <React.Fragment>
                        {renderInitErrorUnishellPage({title: this.getTitle(), 
                                                      errorMessage: this.state.initErrorMessage||t('初始化失败'),
                                                      onTryAgain: ()=>{ this.initializeAgain();    }
                                                      })}
                        <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                    </React.Fragment>
        }
        // loading data succeeded
        let content = this.renderSucceedContent();
        return (
            <React.Fragment>
                { renderUnishellPage({title: this.getTitle(), className:'all-shop-order-commissions', content})}
                <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
            </React.Fragment>
        )   
    }

    renderSucceedContent():JSX.Element {
        let startDate = this.state.fromTime? new Date(this.state.fromTime) : undefined;
        let endDate = this.state.toTime? new Date(this.state.toTime) : undefined;
        
        const {ratioClass, vw,  mw, ratio} = calcRationClass();
        let xs1 = 12;
        let xs2 = 12;
        if(ratio >= RatioXXS){
            xs1 = 4;
            xs2 = 8;
        }

        const today = new Date();

        return (
            <div className='shop-order-commission-main-pane'>
                <Grid container spacing={0}>
                    <Grid item xs={xs1 as 1}>
                        <div className='date-label'>{t('起始日期')}</div>
                    </Grid>
                    <Grid item xs={xs2 as 1}>
                    <UDatePicker 
                        value={startDate}
                        minYear={2000}
                        maxYear={today.getFullYear()+1}
                        className='start-date'
                        key={Date.now()}
                        onChange={(value?:Date)=>{ 
                            this.setState({fromTime: value? value.getTime() : undefined});
                        }}
                    />
                    </Grid>
                    
                    <Grid item xs={xs1 as 1}>
                        <div className='date-label'>{t('截止日期')}</div>
                    </Grid>
                    <Grid item xs={xs2 as 1}>
                    <UDatePicker value={endDate}
                                minYear={2000}
                                maxYear={today.getFullYear()+1}
                                className='end-date'
                                onChange={(value?:Date)=>{ 
                                    this.setState({toTime: value? value.getTime() : undefined});
                               }}
                    />
                    </Grid>

                    <Grid item xs={xs1 as 1}>
                        <div className='status-label'>{t('订单状态')}</div>
                    </Grid>
                    <Grid item xs={xs2 as 1}>
                    <FormControl variant="outlined" size='small' className='status-select'>
                        <Select 
                            value={this.state.status}
                            onChange={(evt)=>{
                                this.setState({status: evt.target.value as ShopOrderCommissionStatus});
                            }}
                        >
                        {this.state.commissionStatusOptions!.map(v=>{
                            return <MenuItem value={v.key} key={v.key}>{v.text}</MenuItem>
                        })}
                        </Select>
                    </FormControl>
                    </Grid>
                </Grid>

                <Pagination data={this.state.searchResult!} page={this.state.page} pageSize={this.state.pageSize} 
                            loadData={this.loadPaginationData}
                            changePageSize={(newPageSize, newPage)=>{
                                this.loadPaginationData(newPage, newPageSize);
                            }}
                >
                { this.renderCommissions(this.state.searchResult!.content) }
                </Pagination>
            </div>
        )
    }

    renderCommissions(content:ShopOrderCommissionInfo[]){
        const serverURL = getServerURL();

        return (
            content.map(x=>{
                let status = '';
                let statusOpt = this.state.commissionStatusOptions!.find(o=> o.key === x.commissionStatus);
                if(statusOpt){
                    status = statusOpt.text;
                }

                const currencyOption = getCurrencyOption(this.state.currencyOptionsMap!, 
                                                         this.props.languageCode!, 
                                                         'UNIS');
                let productBriefs:ProductCandidateBrief[] = [];
                x.commissionItem.forEach(item=>{
                    productBriefs.push(item.product);
                })                                                             

                const orderProps:PropertyListDataItem[]=[
                    {name: t('咨询费'), valueClassName:'price', value: formatPrice(x.commissionTotal, currencyOption.symbol, currencyOption.label)},
                    {name: t('状态'), value: status},
                    {name: t('订单号'), value: x.orderCode},
                    {name: t('营养师'), value: x.nutritionistName},
                    {name: t('客户'), value: x.orderUserName},
                    {name: t('更新'), value: formatDateTime(new Date(x.orderModifiedDtm))},
                ];
                x.commissionItem.forEach((item, index)=>{
                    orderProps.push({
                        name: <div>
                                <p>{t('产品')}#{index+1}</p>
                                <ImageWithOverlay imageList={[item.product.thumbImage.startsWith('http')? item.product.thumbImage : serverURL+item.product.thumbImage]} classes='prod-thumb-img'/>
                            </div>,
                        value: <div>
                                    <p>{t('名称')}:{item.product.productName} x {item.product.count}</p>
                                    <p>{t('咨询费')}:<span className='price'>{formatPrice(item.commissionSubTotal, currencyOption.symbol, currencyOption.label)}</span></p>
                               </div>                            
                    })
                })
          

                return <div key={x.orderCode} className='shop-order-commission'>
                           <PropertyListWidget list={orderProps} showBorder={true} mergeColScreenLetterRatio={RatioXXS}/>
                        </div>
            })
        )
    }

    getFromTime() {
        if(!this.state.fromTime) return undefined;

        let d = new Date(this.state.fromTime);
        d.setHours(0);
        d.setMinutes(0);
        d.setSeconds(0);

        return d.getTime();
    }
    getToTime() {
        if(!this.state.toTime) return undefined;

        let d = new Date(this.state.toTime);
        d.setHours(23);
        d.setMinutes(59);
        d.setSeconds(59);

        return d.getTime();
    }
    getStatus() {
        if(!this.state.status) return undefined;
        return this.state.status as ShopOrderCommissionStatus;
    }

    loadPaginationData = async (page:number, pageSize:number) => {
        await this.loadData(page, pageSize, false,
                            {
                                commissionStatus: this.getStatus(), 
                                fromTime: this.getFromTime(),
                                toTime: this.getToTime()
                            });
    }

    componentDidMount(){
        this.loadData(this.state.page, this.state.pageSize, true,
                      {
                        commissionStatus: this.getStatus(), 
                        fromTime: this.getFromTime(),
                        toTime: this.getToTime()
                  });
    }

    initializeAgain(){
        this.loadData(this.state.page, this.state.pageSize, true,
                        {
                            commissionStatus: this.getStatus(), 
                            fromTime: this.getFromTime(),
                            toTime: this.getToTime()
                    });
    }

    loadData(page: number, pageSize:number, 
             isInitialization: boolean,
             filters?: {
                    commissionStatus?: ShopOrderCommissionStatus,
                    fromTime?: number,
                    toTime?: number
                }){
        const {maxImgWidth} = getMaxThumbnailImgSize();

        doAJAXCall({
            whatIsFor: t('下载营养师商城咨询费'),
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken)=>{ 
                    let searchResult = await searchAllShopOrderCommissions(
                                                accessToken, page, pageSize, maxImgWidth, filters);
                    if(isInitialization){
                        await loadExternContatnsIfNecessary();
                        let currencyOptionsMap = getCurrencyOptionsMap();
                        let commissionStatusOptions = await getCommissionStatuOptions(accessToken, this.props.languageCode!);
    
                        this.setState({searchResult, page: searchResult.page, pageSize: searchResult.size, pageInitState:'PAGE_LOAD_SUCCESS', currencyOptionsMap, commissionStatusOptions});    
                    }else{
                        this.setState({searchResult, page: searchResult.page, pageSize:searchResult.size});    
                    }                                                
            },
            onError: (errorMessage:string)=>{ this.setState({pageInitState:'PAGE_LOAD_FAILED', initErrorMessage:errorMessage})}                                           
        })        
    }

    componentDidUpdate(prevProps:any, prevState:AllShopOrderCommissionState){
        if(prevState.page !== this.state.page){
            scrollBodyToTop();
        }
    }

}

export const AllShopOrderCommission = connect(
    (state:WalletAppState)=>({languageCode: state.language.langCode})
)(AllShopOrderCommissionView);
