// Generated by IcedCoffeeScript 108.0.11
(function () {
  var BaseBurner,
      Burner,
      C,
      Compressed,
      CreationTime,
      Issuer,
      Literal,
      OnePassSignature,
      PKESK,
      SEIPD,
      SHA512,
      SRF,
      Signature,
      WordArray,
      clearsign,
      detachsign,
      dummy_key_id,
      encode,
      export_key_pgp,
      get_cipher,
      iced,
      make_esc,
      make_simple_literals,
      scrub_buffer,
      triplesec,
      unix_time,
      __iced_k,
      __iced_k_noop,
      _ref,
      _ref1,
      _ref2,
      __hasProp = {}.hasOwnProperty,
      __extends = function __extends(child, parent) {
    for (var key in parent) {
      if (__hasProp.call(parent, key)) child[key] = parent[key];
    }

    function ctor() {
      this.constructor = child;
    }

    ctor.prototype = parent.prototype;
    child.prototype = new ctor();
    child.__super__ = parent.prototype;
    return child;
  };

  iced = require('iced-runtime');

  __iced_k = __iced_k_noop = function __iced_k_noop() {};

  make_esc = require('iced-error').make_esc;
  OnePassSignature = require('./packet/one_pass_sig').OnePassSignature;
  _ref = require('./packet/signature'), Signature = _ref.Signature, CreationTime = _ref.CreationTime, Issuer = _ref.Issuer;
  Compressed = require('./packet/compressed').Compressed;
  Literal = require('./packet/literal').Literal;
  unix_time = require('../util').unix_time;
  SRF = require('../rand').SRF;
  triplesec = require('triplesec');
  _ref1 = require('../symmetric'), export_key_pgp = _ref1.export_key_pgp, get_cipher = _ref1.get_cipher;
  scrub_buffer = triplesec.util.scrub_buffer;
  WordArray = triplesec.WordArray;
  _ref2 = require('./packet/sess'), SEIPD = _ref2.SEIPD, PKESK = _ref2.PKESK;
  C = require('../const').openpgp;
  SHA512 = require('../hash').SHA512;
  encode = require('./armor').encode;
  clearsign = require('./clearsign');
  detachsign = require('./detachsign');
  BaseBurner = require('./baseburner').BaseBurner;
  dummy_key_id = Buffer.from(function () {
    var _i, _results;

    _results = [];

    for (_i = 0; _i < 16; _i++) {
      _results.push(0);
    }

    return _results;
  }());

  Burner = function (_super) {
    __extends(Burner, _super);

    function Burner(_arg) {
      var asp, encrypt_for, encryption_key, sign_with, signing_key;
      this.literals = _arg.literals, this.opts = _arg.opts, sign_with = _arg.sign_with, encrypt_for = _arg.encrypt_for, signing_key = _arg.signing_key, encryption_key = _arg.encryption_key, asp = _arg.asp;

      Burner.__super__.constructor.call(this, {
        sign_with: sign_with,
        encrypt_for: encrypt_for,
        signing_key: signing_key,
        encryption_key: encryption_key,
        asp: asp
      });

      this.packets = [];
      this.opts || (this.opts = {});
      this.signed_payload = null;
    }

    Burner.prototype._frame_literals = function (cb) {
      var esc, l, p, sp, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      esc = make_esc(cb, "Burner::_frame_literals");
      sp = [];
      (function (_this) {
        return function (__iced_k) {
          var _i, _len, _ref3, _results, _while2;

          _ref3 = _this.literals;
          _len = _ref3.length;
          _i = 0;

          _while2 = function _while(__iced_k) {
            var _break, _continue, _next;

            _break = __iced_k;

            _continue = function _continue() {
              return iced.trampoline(function () {
                ++_i;
                return _while2(__iced_k);
              });
            };

            _next = _continue;

            if (!(_i < _len)) {
              return _break();
            } else {
              l = _ref3[_i];
              sp.push(l.to_signature_payload());

              (function (__iced_k) {
                __iced_deferrals = new iced.Deferrals(__iced_k, {
                  parent: ___iced_passed_deferral,
                  filename: "/Users/max/src/keybase/kbpgp/src/openpgp/burner.iced",
                  funcname: "Burner._frame_literals"
                });
                l.write(esc(__iced_deferrals.defer({
                  assign_fn: function () {
                    return function () {
                      return p = arguments[0];
                    };
                  }(),
                  lineno: 51
                })));

                __iced_deferrals._fulfill();
              })(function () {
                return _next(_this.packets.push(p));
              });
            }
          };

          _while2(__iced_k);
        };
      })(this)(function (_this) {
        return function () {
          _this.signed_payload = Buffer.concat(sp);
          return cb(null);
        };
      }(this));
    };

    Burner.prototype._sign = function (cb) {
      var esc, fp, hasher, ops, ops_framed, sig, ___iced_passed_deferral, __iced_deferrals, __iced_k, _ref3, _ref4;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      esc = make_esc(cb, "Burner::_sign");
      hasher = (_ref3 = (_ref4 = this.opts) != null ? _ref4.hasher : void 0) != null ? _ref3 : SHA512;
      ops = new OnePassSignature({
        sig_type: C.sig_types.binary_doc,
        hasher: hasher,
        sig_klass: this.signing_key.get_klass(),
        key_id: this.signing_key.get_key_id(),
        is_final: 1
      });
      (function (_this) {
        return function (__iced_k) {
          __iced_deferrals = new iced.Deferrals(__iced_k, {
            parent: ___iced_passed_deferral,
            filename: "/Users/max/src/keybase/kbpgp/src/openpgp/burner.iced",
            funcname: "Burner._sign"
          });
          ops.write(esc(__iced_deferrals.defer({
            assign_fn: function () {
              return function () {
                return ops_framed = arguments[0];
              };
            }(),
            lineno: 68
          })));

          __iced_deferrals._fulfill();
        };
      })(this)(function (_this) {
        return function () {
          var _ref5;

          sig = new Signature({
            type: C.sig_types.binary_doc,
            hasher: hasher,
            key: _this.signing_key.key,
            hashed_subpackets: [new CreationTime(((_ref5 = _this.opts) != null ? _ref5.now : void 0) || unix_time())],
            unhashed_subpackets: [new Issuer(_this.signing_key.get_key_id())]
          });

          (function (__iced_k) {
            __iced_deferrals = new iced.Deferrals(__iced_k, {
              parent: ___iced_passed_deferral,
              filename: "/Users/max/src/keybase/kbpgp/src/openpgp/burner.iced",
              funcname: "Burner._sign"
            });

            _this.asp.progress({
              what: 'sign',
              i: 0,
              total: 1
            }, esc(__iced_deferrals.defer({
              lineno: 76
            })));

            __iced_deferrals._fulfill();
          })(function () {
            (function (__iced_k) {
              __iced_deferrals = new iced.Deferrals(__iced_k, {
                parent: ___iced_passed_deferral,
                filename: "/Users/max/src/keybase/kbpgp/src/openpgp/burner.iced",
                funcname: "Burner._sign"
              });
              sig.write(_this.signed_payload, esc(__iced_deferrals.defer({
                assign_fn: function () {
                  return function () {
                    return fp = arguments[0];
                  };
                }(),
                lineno: 77
              })));

              __iced_deferrals._fulfill();
            })(function () {
              (function (__iced_k) {
                __iced_deferrals = new iced.Deferrals(__iced_k, {
                  parent: ___iced_passed_deferral,
                  filename: "/Users/max/src/keybase/kbpgp/src/openpgp/burner.iced",
                  funcname: "Burner._sign"
                });

                _this.asp.progress({
                  what: 'sign',
                  i: 1,
                  total: 1
                }, esc(__iced_deferrals.defer({
                  lineno: 78
                })));

                __iced_deferrals._fulfill();
              })(function () {
                _this.packets.unshift(ops_framed);

                _this.packets.push(fp);

                return cb(null);
              });
            });
          });
        };
      }(this));
    };

    Burner.prototype.collect_packets = function () {
      var ret;
      ret = Buffer.concat(this.packets);
      this.packets = [];
      return ret;
    };

    Burner.prototype._compress = function (cb) {
      var esc, inflated, opkt, pkt, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      esc = make_esc(cb, "Burner::_compress");
      inflated = this.collect_packets();
      pkt = new Compressed({
        algo: C.compression.zlib,
        inflated: inflated
      });
      (function (_this) {
        return function (__iced_k) {
          __iced_deferrals = new iced.Deferrals(__iced_k, {
            parent: ___iced_passed_deferral,
            filename: "/Users/max/src/keybase/kbpgp/src/openpgp/burner.iced",
            funcname: "Burner._compress"
          });

          _this.asp.progress({
            what: 'compress',
            i: 0,
            total: 1
          }, esc(__iced_deferrals.defer({
            lineno: 96
          })));

          __iced_deferrals._fulfill();
        };
      })(this)(function (_this) {
        return function () {
          (function (__iced_k) {
            __iced_deferrals = new iced.Deferrals(__iced_k, {
              parent: ___iced_passed_deferral,
              filename: "/Users/max/src/keybase/kbpgp/src/openpgp/burner.iced",
              funcname: "Burner._compress"
            });
            pkt.write(esc(__iced_deferrals.defer({
              assign_fn: function () {
                return function () {
                  return opkt = arguments[0];
                };
              }(),
              lineno: 97
            })));

            __iced_deferrals._fulfill();
          })(function () {
            (function (__iced_k) {
              __iced_deferrals = new iced.Deferrals(__iced_k, {
                parent: ___iced_passed_deferral,
                filename: "/Users/max/src/keybase/kbpgp/src/openpgp/burner.iced",
                funcname: "Burner._compress"
              });

              _this.asp.progress({
                what: 'compress',
                i: 1,
                total: 1
              }, esc(__iced_deferrals.defer({
                lineno: 98
              })));

              __iced_deferrals._fulfill();
            })(function () {
              _this.packets.push(opkt);

              return cb(null);
            });
          });
        };
      }(this));
    };

    Burner.prototype._make_session_key = function (cb) {
      var ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      this._cipher_algo = C.symmetric_key_algorithms.AES256;
      this._cipher_info = get_cipher(this._cipher_algo);
      (function (_this) {
        return function (__iced_k) {
          __iced_deferrals = new iced.Deferrals(__iced_k, {
            parent: ___iced_passed_deferral,
            filename: "/Users/max/src/keybase/kbpgp/src/openpgp/burner.iced",
            funcname: "Burner._make_session_key"
          });
          SRF().random_bytes(_this._cipher_info.key_size, __iced_deferrals.defer({
            assign_fn: function (__slot_1) {
              return function () {
                return __slot_1._session_key = arguments[0];
              };
            }(_this),
            lineno: 107
          }));

          __iced_deferrals._fulfill();
        };
      })(this)(function (_this) {
        return function () {
          _this._cipher = new _this._cipher_info.klass(WordArray.from_buffer(_this._session_key));
          return cb(null);
        };
      }(this));
    };

    Burner.prototype.scrub = function () {
      if (this._cipher != null) {
        this._cipher.scrub();
      }

      if (this._session_key != null) {
        return scrub_buffer(this._session_key);
      }
    };

    Burner.prototype._encrypt_session_key_once = function (encryption_key, cb) {
      var ekey, esc, fingerprint, key_id, payload, pkesk, pkt, pub_k, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      esc = make_esc(cb, "_encrypt_session_key_once");
      payload = export_key_pgp(this._cipher_algo, this._session_key);
      pub_k = encryption_key.key;
      fingerprint = encryption_key.get_fingerprint();
      (function (_this) {
        return function (__iced_k) {
          __iced_deferrals = new iced.Deferrals(__iced_k, {
            parent: ___iced_passed_deferral,
            filename: "/Users/max/src/keybase/kbpgp/src/openpgp/burner.iced",
            funcname: "Burner._encrypt_session_key_once"
          });

          _this.asp.progress({
            what: 'session key encrypt',
            i: 0,
            total: 1
          }, esc(__iced_deferrals.defer({
            lineno: 124
          })));

          __iced_deferrals._fulfill();
        };
      })(this)(function (_this) {
        return function () {
          (function (__iced_k) {
            __iced_deferrals = new iced.Deferrals(__iced_k, {
              parent: ___iced_passed_deferral,
              filename: "/Users/max/src/keybase/kbpgp/src/openpgp/burner.iced",
              funcname: "Burner._encrypt_session_key_once"
            });
            pub_k.pad_and_encrypt(payload, {
              fingerprint: fingerprint
            }, esc(__iced_deferrals.defer({
              assign_fn: function () {
                return function () {
                  return ekey = arguments[0];
                };
              }(),
              lineno: 125
            })));

            __iced_deferrals._fulfill();
          })(function () {
            (function (__iced_k) {
              __iced_deferrals = new iced.Deferrals(__iced_k, {
                parent: ___iced_passed_deferral,
                filename: "/Users/max/src/keybase/kbpgp/src/openpgp/burner.iced",
                funcname: "Burner._encrypt_session_key_once"
              });

              _this.asp.progress({
                what: 'session key encrypt',
                i: 1,
                total: 1
              }, esc(__iced_deferrals.defer({
                lineno: 126
              })));

              __iced_deferrals._fulfill();
            })(function () {
              (function (__iced_k) {
                if (_this.opts.hide) {
                  key_id = dummy_key_id;

                  (function (__iced_k) {
                    __iced_deferrals = new iced.Deferrals(__iced_k, {
                      parent: ___iced_passed_deferral,
                      filename: "/Users/max/src/keybase/kbpgp/src/openpgp/burner.iced",
                      funcname: "Burner._encrypt_session_key_once"
                    });

                    _this.asp.progress({
                      what: 'hide encryption',
                      i: 0,
                      total: 1
                    }, esc(__iced_deferrals.defer({
                      lineno: 129
                    })));

                    __iced_deferrals._fulfill();
                  })(function () {
                    (function (__iced_k) {
                      var _ref3, _ref4;

                      __iced_deferrals = new iced.Deferrals(__iced_k, {
                        parent: ___iced_passed_deferral,
                        filename: "/Users/max/src/keybase/kbpgp/src/openpgp/burner.iced",
                        funcname: "Burner._encrypt_session_key_once"
                      });
                      ekey.hide({
                        max: (_ref3 = _this.opts.hide) != null ? _ref3.max : void 0,
                        slosh: (_ref4 = _this.opts.hide) != null ? _ref4.slosh : void 0,
                        key: pub_k
                      }, esc(__iced_deferrals.defer({
                        lineno: 130
                      })));

                      __iced_deferrals._fulfill();
                    })(function () {
                      (function (__iced_k) {
                        __iced_deferrals = new iced.Deferrals(__iced_k, {
                          parent: ___iced_passed_deferral,
                          filename: "/Users/max/src/keybase/kbpgp/src/openpgp/burner.iced",
                          funcname: "Burner._encrypt_session_key_once"
                        });

                        _this.asp.progress({
                          what: 'hide encryption',
                          i: 1,
                          total: 1
                        }, esc(__iced_deferrals.defer({
                          lineno: 131
                        })));

                        __iced_deferrals._fulfill();
                      })(__iced_k);
                    });
                  });
                } else {
                  return __iced_k(key_id = encryption_key.get_key_id());
                }
              })(function () {
                pkt = new PKESK({
                  crypto_type: pub_k.type,
                  key_id: key_id,
                  ekey: ekey
                });

                (function (__iced_k) {
                  __iced_deferrals = new iced.Deferrals(__iced_k, {
                    parent: ___iced_passed_deferral,
                    filename: "/Users/max/src/keybase/kbpgp/src/openpgp/burner.iced",
                    funcname: "Burner._encrypt_session_key_once"
                  });
                  pkt.write(esc(__iced_deferrals.defer({
                    assign_fn: function () {
                      return function () {
                        return pkesk = arguments[0];
                      };
                    }(),
                    lineno: 139
                  })));

                  __iced_deferrals._fulfill();
                })(function () {
                  return cb(null, pkesk);
                });
              });
            });
          });
        };
      }(this));
    };

    Burner.prototype._encrypt_session_key = function (cb) {
      var esc, k, pkesk, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      esc = make_esc(cb, "_encrypt_session_key");
      this._pkesks = [];
      (function (_this) {
        return function (__iced_k) {
          var _i, _len, _ref3, _results, _while3;

          _ref3 = _this.encryption_keys;
          _len = _ref3.length;
          _i = 0;

          _while3 = function _while(__iced_k) {
            var _break, _continue, _next;

            _break = __iced_k;

            _continue = function _continue() {
              return iced.trampoline(function () {
                ++_i;
                return _while3(__iced_k);
              });
            };

            _next = _continue;

            if (!(_i < _len)) {
              return _break();
            } else {
              k = _ref3[_i];

              (function (__iced_k) {
                __iced_deferrals = new iced.Deferrals(__iced_k, {
                  parent: ___iced_passed_deferral,
                  filename: "/Users/max/src/keybase/kbpgp/src/openpgp/burner.iced",
                  funcname: "Burner._encrypt_session_key"
                });

                _this._encrypt_session_key_once(k, esc(__iced_deferrals.defer({
                  assign_fn: function () {
                    return function () {
                      return pkesk = arguments[0];
                    };
                  }(),
                  lineno: 148
                })));

                __iced_deferrals._fulfill();
              })(function () {
                return _next(_this._pkesks.push(pkesk));
              });
            }
          };

          _while3(__iced_k);
        };
      })(this)(function (_this) {
        return function () {
          return cb(null);
        };
      }(this));
    };

    Burner.prototype._encrypt_payload = function (cb) {
      var asp, esc, pkt, plaintext, prefixrandom, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      esc = make_esc(cb, "Burner::_encrypt_payload");
      plaintext = this.collect_packets();
      (function (_this) {
        return function (__iced_k) {
          __iced_deferrals = new iced.Deferrals(__iced_k, {
            parent: ___iced_passed_deferral,
            filename: "/Users/max/src/keybase/kbpgp/src/openpgp/burner.iced",
            funcname: "Burner._encrypt_payload"
          });
          SRF().random_bytes(_this._cipher.blockSize, __iced_deferrals.defer({
            assign_fn: function () {
              return function () {
                return prefixrandom = arguments[0];
              };
            }(),
            lineno: 157
          }));

          __iced_deferrals._fulfill();
        };
      })(this)(function (_this) {
        return function () {
          pkt = new SEIPD({});
          asp = _this.asp.section('encrypt payload');

          (function (__iced_k) {
            __iced_deferrals = new iced.Deferrals(__iced_k, {
              parent: ___iced_passed_deferral,
              filename: "/Users/max/src/keybase/kbpgp/src/openpgp/burner.iced",
              funcname: "Burner._encrypt_payload"
            });
            pkt.encrypt({
              cipher: _this._cipher,
              plaintext: plaintext,
              prefixrandom: prefixrandom,
              asp: asp
            }, esc(__iced_deferrals.defer({
              lineno: 160
            })));

            __iced_deferrals._fulfill();
          })(function () {
            (function (__iced_k) {
              __iced_deferrals = new iced.Deferrals(__iced_k, {
                parent: ___iced_passed_deferral,
                filename: "/Users/max/src/keybase/kbpgp/src/openpgp/burner.iced",
                funcname: "Burner._encrypt_payload"
              });
              pkt.write(esc(__iced_deferrals.defer({
                assign_fn: function () {
                  return function () {
                    return pkt = arguments[0];
                  };
                }(),
                lineno: 161
              })));

              __iced_deferrals._fulfill();
            })(function () {
              scrub_buffer(plaintext);
              _this.packets = _this._pkesks.concat([pkt]);
              return cb(null);
            });
          });
        };
      }(this));
    };

    Burner.prototype._encrypt = function (cb) {
      var esc, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      esc = make_esc(cb, "Burner::_encrypt");
      (function (_this) {
        return function (__iced_k) {
          __iced_deferrals = new iced.Deferrals(__iced_k, {
            parent: ___iced_passed_deferral,
            filename: "/Users/max/src/keybase/kbpgp/src/openpgp/burner.iced",
            funcname: "Burner._encrypt"
          });

          _this._make_session_key(esc(__iced_deferrals.defer({
            lineno: 170
          })));

          __iced_deferrals._fulfill();
        };
      })(this)(function (_this) {
        return function () {
          (function (__iced_k) {
            __iced_deferrals = new iced.Deferrals(__iced_k, {
              parent: ___iced_passed_deferral,
              filename: "/Users/max/src/keybase/kbpgp/src/openpgp/burner.iced",
              funcname: "Burner._encrypt"
            });

            _this._encrypt_session_key(esc(__iced_deferrals.defer({
              lineno: 171
            })));

            __iced_deferrals._fulfill();
          })(function () {
            (function (__iced_k) {
              __iced_deferrals = new iced.Deferrals(__iced_k, {
                parent: ___iced_passed_deferral,
                filename: "/Users/max/src/keybase/kbpgp/src/openpgp/burner.iced",
                funcname: "Burner._encrypt"
              });

              _this._encrypt_payload(esc(__iced_deferrals.defer({
                lineno: 172
              })));

              __iced_deferrals._fulfill();
            })(function () {
              return cb(null);
            });
          });
        };
      }(this));
    };

    Burner.prototype.scrub = function () {};

    Burner.prototype.burn = function (cb) {
      var esc, output, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      esc = make_esc(cb, "Burner::burn");
      (function (_this) {
        return function (__iced_k) {
          __iced_deferrals = new iced.Deferrals(__iced_k, {
            parent: ___iced_passed_deferral,
            filename: "/Users/max/src/keybase/kbpgp/src/openpgp/burner.iced",
            funcname: "Burner.burn"
          });

          _this._find_keys(esc(__iced_deferrals.defer({
            lineno: 183
          })));

          __iced_deferrals._fulfill();
        };
      })(this)(function (_this) {
        return function () {
          (function (__iced_k) {
            __iced_deferrals = new iced.Deferrals(__iced_k, {
              parent: ___iced_passed_deferral,
              filename: "/Users/max/src/keybase/kbpgp/src/openpgp/burner.iced",
              funcname: "Burner.burn"
            });

            _this._frame_literals(esc(__iced_deferrals.defer({
              lineno: 184
            })));

            __iced_deferrals._fulfill();
          })(function () {
            (function (__iced_k) {
              if (_this.signing_key != null) {
                (function (__iced_k) {
                  __iced_deferrals = new iced.Deferrals(__iced_k, {
                    parent: ___iced_passed_deferral,
                    filename: "/Users/max/src/keybase/kbpgp/src/openpgp/burner.iced",
                    funcname: "Burner.burn"
                  });

                  _this._sign(esc(__iced_deferrals.defer({
                    lineno: 186
                  })));

                  __iced_deferrals._fulfill();
                })(__iced_k);
              } else {
                return __iced_k();
              }
            })(function () {
              (function (__iced_k) {
                __iced_deferrals = new iced.Deferrals(__iced_k, {
                  parent: ___iced_passed_deferral,
                  filename: "/Users/max/src/keybase/kbpgp/src/openpgp/burner.iced",
                  funcname: "Burner.burn"
                });

                _this._compress(esc(__iced_deferrals.defer({
                  lineno: 187
                })));

                __iced_deferrals._fulfill();
              })(function () {
                (function (__iced_k) {
                  if (_this.encryption_keys != null) {
                    (function (__iced_k) {
                      __iced_deferrals = new iced.Deferrals(__iced_k, {
                        parent: ___iced_passed_deferral,
                        filename: "/Users/max/src/keybase/kbpgp/src/openpgp/burner.iced",
                        funcname: "Burner.burn"
                      });

                      _this._encrypt(esc(__iced_deferrals.defer({
                        lineno: 189
                      })));

                      __iced_deferrals._fulfill();
                    })(__iced_k);
                  } else {
                    return __iced_k();
                  }
                })(function () {
                  output = Buffer.concat(_this.packets);
                  return cb(null, output);
                });
              });
            });
          });
        };
      }(this));
    };

    return Burner;
  }(BaseBurner);

  exports.Burner = Burner;

  exports.make_simple_literals = make_simple_literals = function make_simple_literals(msg) {
    return [new Literal({
      data: Buffer.from(msg),
      format: C.literal_formats.utf8,
      date: unix_time()
    })];
  };

  exports.clearsign = clearsign.sign;
  exports.detachsign = detachsign.sign;

  exports.burn = function (_arg, cb) {
    var aout, asp, b, encrypt_for, encryption_key, err, literals, msg, opts, raw, sign_with, signing_key, ___iced_passed_deferral, __iced_deferrals, __iced_k;

    __iced_k = __iced_k_noop;
    ___iced_passed_deferral = iced.findDeferral(arguments);
    msg = _arg.msg, literals = _arg.literals, sign_with = _arg.sign_with, encrypt_for = _arg.encrypt_for, signing_key = _arg.signing_key, encryption_key = _arg.encryption_key, asp = _arg.asp, opts = _arg.opts;

    if (msg != null && literals == null) {
      literals = make_simple_literals(msg);
    }

    b = new Burner({
      literals: literals,
      sign_with: sign_with,
      encrypt_for: encrypt_for,
      signing_key: signing_key,
      encryption_key: encryption_key,
      asp: asp,
      opts: opts
    });
    (function (_this) {
      return function (__iced_k) {
        __iced_deferrals = new iced.Deferrals(__iced_k, {
          parent: ___iced_passed_deferral,
          filename: "/Users/max/src/keybase/kbpgp/src/openpgp/burner.iced",
          funcname: "burn"
        });
        b.burn(__iced_deferrals.defer({
          assign_fn: function () {
            return function () {
              err = arguments[0];
              return raw = arguments[1];
            };
          }(),
          lineno: 249
        }));

        __iced_deferrals._fulfill();
      };
    })(this)(function (_this) {
      return function () {
        b.scrub();

        if (typeof raw !== "undefined" && raw !== null && (typeof err === "undefined" || err === null)) {
          aout = encode(C.message_types.generic, raw);
        }

        return cb(err, aout, raw);
      };
    }(this));
  };
}).call(this);