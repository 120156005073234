import * as React from 'react';
import {Component, Fragment} from 'react';
import './tree-menu.scss';
import classnames from 'classnames';
import * as _ from 'lodash';
import { t } from '../i18n';
import { getMenuData, MenuItemData, MenuItemDirData,  } from './common';
import { UnishellTreePane } from './menu-pane';
import { gotoShoppingPathNSavePrevPath } from '../shopping/history/shopping-history';
import uuidv4 from 'uuid/v4';

import {ReactComponent as CloseIcon} from '../images/close.svg';
import { doIHaveOneOfRoles } from '../api/user';
import ReactDOM from 'react-dom';

interface MyProps {
    // languageCode: string;
}

type STATE = 'init'|'open'|'close';

interface MyState {
    st: STATE;
    firstTimeOpen?:boolean,
    menuData: MenuItemDirData;
    currentMenuIdxArray: {idxOfActiveMenuDirItem:number}[];
    /** session starts when it open and ends with it closed */
    sessionId: string;
}

export class UnishellTreeMenus extends Component<MyProps, MyState> {
    private wrapperId = 'unishell-tree-menu-root-'+Date.now();

    constructor(props: MyProps){
        super(props);
        this.state = {
            st: 'init',
            menuData: {type:'dir', label:t('主菜单'), children: getMenuData()},
            currentMenuIdxArray: [],
            sessionId: uuidv4()
        }
    }

    render(){
        if(this.state.st === 'init'){
            return null;
        }

        // tabIndex and onMouseDown: to support scroll of long menus
        return ReactDOM.createPortal(
            <div className={classnames('unishell-tree-menu-root', 
                                        this.state.st, 
                                        {"first-time-open": this.state.firstTimeOpen},
                                        {"regular-open": !this.state.firstTimeOpen},
                                        )}
                 id={this.wrapperId} tabIndex={0}
                 onMouseDown={()=>{
                     document.getElementById(this.wrapperId)!.focus();
                 }}
                 onClick={()=>{ this.close();}}
            >
                <CloseIcon className='close-icon' onClick={(evt)=>{
                    evt.stopPropagation();
                    this.close();
                }}/>
                <div className='unishell-tree-menu-pane'>
                {this.renderMenuItemDirData()}
                </div>
            </div>,
            document.getElementById('root')!
        )
    }

    open(){
        switch(this.state.st){
            case 'open': break;
            case 'close': 
                this.setState({st:'open',currentMenuIdxArray:[], sessionId: uuidv4(), firstTimeOpen: false});
                break;
            case 'init':
                this.setState({st:'open',currentMenuIdxArray:[], sessionId: uuidv4(), firstTimeOpen: true});
                break;
        }
    }
    close(){
        switch(this.state.st){
            case 'init': break;
            case 'close': break;
            case 'open': 
                this.setState({st:'close', currentMenuIdxArray:[]});
        }
    }
    
    clickLeafMenu(menuData:MenuItemData){
        if(!menuData.placeholder){
            this.setState({st: 'close'}, ()=>{
                setTimeout(()=>{
                    if(menuData.menuPath){
                        gotoShoppingPathNSavePrevPath(menuData.menuPath, menuData.params);
                    }else if(menuData.onClick){
                        menuData.onClick();
                    }
                }, 500)
            });    
        }
    }

    renderMenuItemDirData():JSX.Element[] {
        let level = 0;
        let menuDirData = this.state.menuData;
        let currentMenuIdxArray = _.cloneDeep(this.state.currentMenuIdxArray);
        let done = false;

        const ret:JSX.Element[] = [];

        do{
            if(menuDirData.permitRoles !== undefined){
                if(!doIHaveOneOfRoles(menuDirData.permitRoles)){
                    break; // skip this menuDirData
                }
            }
            // if(menuDirData.negatePermitRoles !== undefined){
            //     if(doIHaveOneOfRoles(menuDirData.negatePermitRoles)){
            //         break; // skip this menuDirData
            //     }
            // }

            let currentMenuIdx: number|undefined;
            if(currentMenuIdxArray.length>0){
                currentMenuIdx = currentMenuIdxArray[0].idxOfActiveMenuDirItem;
            }
            let menuPane = <UnishellTreePane menuDirData={menuDirData} key={this.state.sessionId+menuDirData.label}
                                             level={level} 
                                             active={currentMenuIdxArray.length===0} 
                                             currentMenuIdx={currentMenuIdx}
                                             currentMenuIdxArray={currentMenuIdxArray.slice(0, level)}
                                             selectMenuDirFn={(level, menuDirIdx)=>{
                                                let newcurrentMenuIdxArray = _.cloneDeep(this.state.currentMenuIdxArray);
                                                newcurrentMenuIdxArray.push({idxOfActiveMenuDirItem: menuDirIdx});
                                                // alert(`update menu idx `+JSON.stringify(newcurrentMenuIdxArray));
                                                this.setState({currentMenuIdxArray: newcurrentMenuIdxArray})
                                             }}
                                             backMenuDirFn={()=>{
                                                 if(this.state.currentMenuIdxArray.length===0){
                                                     this.close();
                                                 }else{
                                                    let newcurrentMenuIdxArray = _.cloneDeep(this.state.currentMenuIdxArray);
                                                    newcurrentMenuIdxArray.pop();
                                                    this.setState({currentMenuIdxArray: newcurrentMenuIdxArray})    
                                                 }
                                             }}
                                             clickLeafMenu={(menuData)=>{ this.clickLeafMenu(menuData)} }
                                             />;
            ret.push(menuPane);                                 
            if(currentMenuIdxArray.length>0){
                let nextMenuDir = menuDirData.children[currentMenuIdx!];
                if(nextMenuDir.type === 'leaf'){
                    done = true;
                    break;
                }else{
                    menuDirData = nextMenuDir; // move to next menu dir panel
                }
                currentMenuIdxArray = currentMenuIdxArray.slice(1);
                level ++;
            }else{
                done = true;
                break;
            }                                             
        }while(!done);

        return ret;
    }

    postRender(){
        if(this.state.st === 'open' && this.state.firstTimeOpen){
            setTimeout(()=>{
                this.setState({firstTimeOpen: false});
            }, 100);
        }
    }

    componentDidMount(){
    }
    componentDidUpdate(){
        this.postRender();
    }
}
