import * as React from 'react';
import {Component} from 'react';
import { t } from '../../i18n';
import './image-rotate.scss';
import uuidv4 from 'uuid/v4';
import { debug } from '../../globals';
import classnames from 'classnames';
import ReactCrop,{
    centerCrop,
    PixelCrop,
    PercentCrop,
    convertToPixelCrop
  } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { getElementInnerSize, realImgDimension } from '../../util/Util';
import { imgPreview } from './canvas-preview';
import { NoticeDialog } from '../../view/NoticeDialog';
import { SimpleNote } from '../note/note';


const resizeImage = require('resize-image');

// https://www.npmjs.com/package/resize-image
// https://www.npmjs.com/package/react-image-crop#how-to-filter-rotate-and-annotate

interface MyProps {
    imgsrc: string;
    minImgWidth: number;
    minImgHeight: number;
    className?: string;
    readonly?: boolean;
    done: (imgURL: string)=>void;
    cancel: ()=>void;
}

interface MyState {
    initialized?: boolean;

    origWidth?: number;
    origHeight?: number;

    newWidth?: number;
    newHeight?: number;

    /** crop contaner width */
    containerWidth?: number;
    /** crop contaner height */
    containerHeight?: number;

    /** rotatation of output image */
    degrees: number;
    /** image is scaled to fit in react crop. output image is not scaled */
    scale: number;    

    imageWrapperId: string;

    imageId:string;

    resizedImg?: string;

    /* crops are not transformed */
    /** use percent crop for debugging purpose */
    percentCrop?:PercentCrop;
    crop?: PixelCrop;
}

export class ImageWithRotation extends Component<MyProps, MyState>{
    private imageDialog:NoticeDialog | undefined;

    constructor(props: MyProps){
        super(props);

        this.state = {
            degrees: 0, 
            scale:1,
            imageWrapperId: uuidv4(), 
            imageId: uuidv4(),
        }
    }

    getRotageDegree(){
        return this.state.degrees;
    }

    rotateImage(delta:number){
        let degrees = this.state.degrees + delta;
        if( Math.abs(Math.abs(degrees)-360) < 1/128 ){
            degrees = 0;
        }
        this.setState({degrees});
    }

    render(){
        if(this.state.initialized){
            // handle rotated images
            const omax = Math.max(this.state.origWidth!, this.state.origHeight!);
            const omin = Math.min(this.state.origWidth!, this.state.origHeight!);

            const max = Math.max(this.props.minImgWidth, this.props.minImgHeight);
            const min = Math.min(this.props.minImgWidth, this.props.minImgHeight);

            if(omax<max || omin < min){
                return <div className={classnames('image-rotate', 
                                        this.props.className, 
                                        {init:!this.state.initialized, normal: this.state.initialized }
                                    )
                        }>
                            <SimpleNote noticeType='warn' message={`${t('图片太小')} ${this.state.origWidth} X ${this.state.origHeight}`}/>
                        </div>
            }
        }

        let imageStyle:React.CSSProperties = {};
        if(this.state.initialized && (Math.abs(this.state.degrees)>0 || this.state.scale < 1)){
            imageStyle.transform = `scale(${this.state.scale}) rotate(${this.state.degrees}deg)`; 
        }
        //    style={wrapperStyle}

        return (
            <div className={classnames('image-rotate', 
                                        this.props.className, 
                                        {init:!this.state.initialized, normal: this.state.initialized }
                                      )
                           }>
                <div className='image-wrapper' id={this.state.imageWrapperId}>
                    {this.state.initialized ?
                        <ReactCrop crop={this.state.crop!}
                                   onChange={(crop, percentCrop)=> this.setState({crop, percentCrop})}
                        >
                            <img src={this.state.resizedImg || this.props.imgsrc}
                                   style={imageStyle}
                                   id={this.state.imageId}/>        
                        </ReactCrop>:
                        <img src={this.props.imgsrc}
                            id={this.state.imageId}
                            onLoad={ ()=>{ 
                                if(this.state.initialized) return;

                                const {naturalWidth,naturalHeight} = realImgDimension(document.getElementById(this.state.imageId)! as HTMLImageElement);
                                this.imageLoaded(naturalWidth, naturalHeight);
                            }}/>                   
                    }
                </div>
                { this.props.readonly || !this.state.initialized ? null :
                    <div className='rotate-actions'>
                        <span className='label' onClick={()=>{ this.rotateImage(-90) }}>{t('左旋')}</span>
                        <span  className='label'  onClick={()=>{ this.rotateImage(90) }}>{t('右旋')}</span>
                        <span className='label' onClick={()=>{ this.props.cancel() }}>{t('取消')}</span>
                        <span className='label' onClick={()=>{ this.cropDone() }}>{t('确认')}</span>
                    </div>
                }
                <NoticeDialog open={false} ref={e=>this.imageDialog=e!}/>
            </div>
        )
    }

    async cropDone(){
        let newImageUrl = await imgPreview(document.getElementById(this.state.imageId)! as HTMLImageElement, 
                                          this.state.crop!, this.state.scale, this.state.degrees);
        // this.imageDialog!.show('Preview', <img src={newImageUrl} className='cropped-image'/>, ()=>{});                                          
        this.props.done(newImageUrl);                                                  
    }

    imageLoaded(origWidth: number, origHeight: number){
        let newWidth = 0, newHeight = 0;
        let resizedImg:string = '';
        let scale = 1;

        // shrink image if it is too big
        if(origHeight>origWidth && origHeight>1200+256){
            newHeight = 1200;
            newWidth = Math.floor(origWidth*1200/origHeight);
            const img = document.getElementById(this.state.imageId)!;
            // e.g data:image/png;base64,....
            resizedImg = resizeImage.resize(img, newWidth, newHeight);
        }else if(origWidth>origHeight && origWidth>1200+256){
            newWidth = 1200;
            newHeight = Math.floor(origHeight*1200/origWidth);
            const img = document.getElementById(this.state.imageId)!;

            resizedImg = resizeImage.resize(img, newWidth, newHeight);
        }else{
            newHeight = origHeight;
            newWidth = origWidth;
        }

        // we need to make sure rotated image stay within the wrapper div
        let {innerWidth,innerHeight} = getElementInnerSize(document.getElementById(this.state.imageWrapperId)!);

        let containerWidth = 0, containerHeight = 0;
        if(newWidth<= innerWidth){ // image is narrow
            containerWidth = newWidth;
            containerHeight = newHeight;
        }else{ // image is wide
            containerWidth = innerWidth;
            containerHeight = newHeight*innerWidth/newWidth;
        }
        // scale down to support rotate with boundary
        if(newHeight>newWidth){
            scale = newWidth/newHeight;
        }else if(newHeight<newWidth){
            scale = newHeight/newWidth;
        }
        // initial crop, to be changed
        let crop:PixelCrop = {
            unit:'px',
            x: containerWidth/2*(1-scale),
            y: containerHeight/2*(1-scale),
            width: containerWidth*(scale),
            height:containerHeight*(scale)
        }

        this.setState({scale, 
                       initialized: true, 
                       origWidth, 
                       origHeight, 
                       newWidth,
                       newHeight,
                       containerWidth,
                       containerHeight,
                       resizedImg, 
                       crop});
    }
}
