import { detect } from 'detect-browser';
import * as React from 'react';
import { Component } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { CheckUpgradeRequest, CheckUpgradeResponse } from '../../../api/common';
import { hasRoleDev } from '../../../api/user';
import { LANG_CODE_ZH_CN } from '../../../external-constants/extern-constants';
import { checkUpgrade, getCordova, getDevice, getManifest, info, openUrl } from '../../../globals';
import { t } from '../../../i18n';
import { getLanguageCode, readLoginSession } from '../../../local-storage/local-storage';
import { doAJAXCall } from '../../../shopping/common/ajax-call';
import { BaseUnishellPageState, renderInitErrorUnishellPage, renderInitUnishellPage, renderUnishellPage } from '../../../unishell-page-common/unishell-page-common';
import { upgradeApp } from '../../../upgrade';
import { calcRationClass, getViewportSize, RatioXXS } from '../../../util/Util';
import { checkAppUpgrade } from '../../../wallet-server-api/wallet-server-api';
import { UButton } from '../../../widget/button/ubutton';
import { SimpleNote } from '../../../widget/note/note';
import { PropertyListDataItem, PropertyListWidget } from '../../../widget/property-list/property-list';
import { NoticeDialog } from '../../NoticeDialog';
import { SimpleURL } from '../../open-url/open-url';
import { Toast } from '../../Toast';
import './upgrade-app.scss';


class MeasureFont extends Component<{},{ready: boolean}> {
    constructor(props:{}){
        super(props);
        this.state = {ready: false}
    }
    render(){
        const id1 = 'measure-font-span-16px';
        const id2 = 'measure-font-span-1rem';
        const span = document.getElementById(id1);
        const rect = span? span!.getBoundingClientRect(): undefined;
        const {vw, vh} = getViewportSize();
        const htmlTag = document.documentElement;
        const {ratioClass, mw, ratio, mwOriginal} = calcRationClass();

        // const w = getTextWidth('M', 'normal 16px Verdana');
        return (
            <div>
                <p>html font-size={htmlTag.style.fontSize||''}</p>
                <p>{JSON.stringify({ratioClass, mw:mw.toFixed(2),mworigin:mwOriginal.toFixed(2), ratio:ratio.toFixed(2)})}</p>
                <span id={id1} style={{fontFamily:'Verdana', fontSize:'16px'}}>M</span>
                <span id={id2} style={{fontFamily:'Verdana', fontSize:'1rem'}}>M in 1rem</span>&nbsp;&nbsp;
                {this.state.ready? <span>{`vw/W: ${vw.toFixed(2)}/${rect!.width.toFixed(2)}=${(vw/rect!.width).toFixed(2)} vh/H: ${vh.toFixed(2)}/${rect!.height.toFixed(2)}=${(vh/rect!.height).toFixed(2)}`}</span>:null
                }
            </div>
        )
    }
    componentDidMount(){
        this.setState({ready: true})
    }
}

interface MyState extends BaseUnishellPageState{
    request: CheckUpgradeRequest;
    upgradeResponse?:CheckUpgradeResponse;
}

export const simulate_upgrade = false;
export const simulate_upgradeResponse = {
    newRelease: false,
    needUpgrade: false,
    downloadUrl: 'https://unishell-html.s3-us-west-2.amazonaws.com/files/app/Unishell-110.apk?lang=en',
    downloadUrlZHCN: 'https://unishell-html.s3-us-west-2.amazonaws.com/files/app/Unishell-110.apk?lang=zh-CN',
    currentVersion: '1.0.4',
    currentBuild:1413,
    devicePlatform: 'Android'
};

export class UpgradeApp extends Component<{}, MyState> {
    private errorDialog:NoticeDialog|undefined;
    private toast:Toast|undefined;

    constructor(props: {}){
        super(props);
        let request: CheckUpgradeRequest|undefined = getCordova()? {
            build:getManifest().buildVersion,
            appVersion:getManifest().appVersion,
            devicePlatform:getDevice().platform,
            deviceModel:getDevice().model,
            deviceUUID:getDevice().uuid,
            deviceSerial:getDevice().serial,
            deviceVersion:getDevice().version,
        }:{
            build:'',
            appVersion:'',
            devicePlatform:'',
            deviceModel:'',
            deviceUUID:'',
            deviceSerial:'',
            deviceVersion:'',
        };
        
        this.state = {
                        pageInitState:'PAGE_INIT', 
                        request,
                     }
    }

    getTitle(){
        return t('版本信息');
    }

    render(){
        if(this.state.pageInitState === 'PAGE_INIT'){
            return <React.Fragment>
                        {renderInitUnishellPage(
                            {
                                title: this.getTitle(), 
                                className:'upgrade-app',
                                message:t('初始化...')
                            })
                        }
                        <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                        <Toast close={true} message='' toastType='info' onClose={()=>{}} ref={(e)=>{this.toast=e!}}/>
                    </React.Fragment>
        }
        if(this.state.pageInitState === 'PAGE_LOAD_FAILED'){
            return <React.Fragment>
                        {renderInitErrorUnishellPage(
                            {
                                title: this.getTitle(), 
                                className:'upgrade-app',
                                errorMessage: this.state.initErrorMessage||t('初始化失败'),
                                onTryAgain: ()=>{ this.initializeAgain();    }
                            })
                        }
                        <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                        <Toast close={true} message='' toastType='info' onClose={()=>{}} ref={(e)=>{this.toast=e!}}/>
                    </React.Fragment>
        }

        return renderUnishellPage({
            title: this.getTitle(), 
            className:'upgrade-app',
            content:(
                <React.Fragment>
                    { this.renderSucceedContent() }
                    <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                    <Toast close={true} message='' toastType='info' onClose={()=>{}} ref={(e)=>{this.toast=e!}}/>
                </React.Fragment>
            )
        }) 
    }

    renderSucceedContent():JSX.Element {
        const dev = this.state.request;

        const propData:PropertyListDataItem[] = [
            {name: t('应用版本'), value: dev.appVersion},
            {name: t('版本编号'), value: dev.build},
            {name: t('设备型号'), value: dev.deviceModel},
            {name: t('设备序列号'), value: dev.devicePlatform},
            {name: t('设备编号'), value: dev.deviceSerial},
            {name: t('设备版本'), value: dev.deviceUUID},
        ];

        
        propData.push({name: t('浏览器'), value: JSON.stringify(detect()).replace('","', '", "')});
        propData.push({name: t('屏幕尺寸'), value: JSON.stringify(getViewportSize()).replace('","', '", "')});
        propData.push({name:'', value: <div>Measure 1rem(16px by default) M letter in Verdana font. <MeasureFont/></div>, mergeCol: true});

        const session = readLoginSession()!;


        {
            return (
                <div className='pickup-location-content'>
                    {getCordova() && !this.state.upgradeResponse ?
                        <SimpleNote noticeType='info' message={t('没有检测到新版本')}/>: null
                    }

                    {this.state.upgradeResponse? this.renderUpgradeInfo(): null}

                    {getCordova() && hasRoleDev(session.profile!.roles) ? <div className='upgrade-btn' onClick={()=>{ upgradeApp('testing') }}>
                                <UButton variant='contained' color='primary'  onClick={this.detectInAppUpgrade}>{t('开发版升级')}</UButton>
                            </div>: null
                    }
                    {!getCordova()?<SimpleNote noticeType='info' message={t('台式机无手机应用版本信息')}/>:null}

                    {this.state.upgradeResponse && this.state.upgradeResponse!.needUpgrade?
                        <SimpleNote noticeType='warn' message={t('已不支持当前版本！')}/>: null
                    }


                    <div className='device-properties'>
                        <PropertyListWidget list={propData} 
                                            showBorder={true} 
                                            mergeColScreenLetterRatio={RatioXXS}
                        />
                    </div>

                </div>
            )    
        }
    }

    clearSelection()
    {
        if (window.getSelection) {
            // @ts-ignore
            window.getSelection() && window.getSelection().removeAllRanges();
            // @ts-ignore
        } else if (document.selection) { 
            // @ts-ignore
            document.selection.empty();
        }
    }

    copyDownloadURL = ()=>{
        let copyText = document.getElementById("download-url")! as HTMLTextAreaElement;

        /* Select the text field */
        copyText.select();
        copyText.setSelectionRange(0, 99999); /*For mobile devices*/
      
        try {
            let successful = document.execCommand('copy');
            let msg = successful ? 'successful' : 'unsuccessful';
            info('Copying text command was ' + msg);
            this.clearSelection();
            if(successful){
                this.toast!.sendMessage(t('拷贝到剪贴板'), 'info', false);
            }else{
                this.toast!.sendMessage(t('拷贝失败'), 'error', false);
            }
        } catch (err) {
            info('Oops, unable to copy', err);
            this.toast!.sendMessage(t('拷贝失败'), 'error', false);
        }
    }

    downloadNewApp = (lang:string)=>{
        openUrl(LANG_CODE_ZH_CN===lang? this.state.upgradeResponse!.downloadUrlZHCN : this.state.upgradeResponse!.downloadUrl);
    }

    renderUpgradeInfo(){
        const upgradeInfo = this.state.upgradeResponse!;
        const lang = getLanguageCode();

        const propData:PropertyListDataItem[] = [
            {name:t('新版本'), value: upgradeInfo.currentVersion+"#"+upgradeInfo.currentBuild},
            {
                name:t('新版本下载地址'), 
                valueClassName: 'download-address',
                value : <TextareaAutosize 
                            id='download-url' 
                            readOnly={true} 
                            value={LANG_CODE_ZH_CN===lang? upgradeInfo.downloadUrlZHCN : upgradeInfo.downloadUrl}/>,
                mergeCol: true                                
            },
            {
                name: '',
                mergeCol: true,
                value: <div className='download-div'>
                            <label>{t('使用系统浏览器')}</label> &nbsp;
                            <SimpleURL url={LANG_CODE_ZH_CN===lang? upgradeInfo.downloadUrlZHCN : upgradeInfo.downloadUrl}
                                       label={('下载新版本')}
                            />
                            <div>
                                <br/>
                                {t('或')}
                                <UButton variant='outlined' color='primary' onClick={this.copyDownloadURL}>{t('拷贝下载地址')}</UButton>
                                &nbsp;<label>{t('再用其他浏览器下载')}</label>
                            </div>
                        </div>
            }
        ];

        return <PropertyListWidget list={propData} showBorder={true} className='upgrade-info'/>
    }

    detectInAppUpgrade = ()=>{
        if(getCordova()){
            checkUpgrade();
        }
    }


    initializeAgain(){
        this.setState({pageInitState:'PAGE_INIT', initErrorMessage:undefined},
                      ()=>{
                        this.loadData(true);
                    })
    }


    loadData(isInit?: boolean){
        doAJAXCall({
            whatIsFor: t('下载版本信息'),
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken)=>{
                if(getCordova()){
                    let upgradeResponse = await checkAppUpgrade(this.state.request);
                    if(upgradeResponse.newRelease  || upgradeResponse.needUpgrade){
                        this.setState({upgradeResponse, pageInitState:'PAGE_LOAD_SUCCESS'});
                    }else{
                        this.setState({pageInitState:'PAGE_LOAD_SUCCESS'});
                    }
                }else if(simulate_upgrade){
                    let upgradeResponse = simulate_upgradeResponse;
                    if(upgradeResponse.newRelease || upgradeResponse.needUpgrade){
                        this.setState({upgradeResponse, pageInitState:'PAGE_LOAD_SUCCESS'});
                    }else{
                        this.setState({pageInitState:'PAGE_LOAD_SUCCESS'});
                    }
                }else{
                    this.setState({pageInitState:'PAGE_LOAD_SUCCESS'});
                }
    
            },
            onError: (errorMessage:string)=>{ 
                if(isInit){
                    this.setState({pageInitState:'PAGE_LOAD_FAILED', initErrorMessage:errorMessage})
                }
            }                                           
        })
    }

    componentDidMount(){
        this.loadData(true);
    }
}