// Generated by IcedCoffeeScript 108.0.11
(function () {
  var C,
      Ch,
      CreationTime,
      Issuer,
      Literal,
      Signature,
      Signer,
      Verifier,
      VerifierBase,
      WordArray,
      encode,
      hash_obj_to_fn,
      iced,
      konst,
      make_esc,
      packetsigs,
      streamers,
      unix_time,
      __iced_k,
      __iced_k_noop,
      _ref,
      __hasProp = {}.hasOwnProperty,
      __extends = function __extends(child, parent) {
    for (var key in parent) {
      if (__hasProp.call(parent, key)) child[key] = parent[key];
    }

    function ctor() {
      this.constructor = child;
    }

    ctor.prototype = parent.prototype;
    child.prototype = new ctor();
    child.__super__ = parent.prototype;
    return child;
  };

  iced = require('iced-runtime');

  __iced_k = __iced_k_noop = function __iced_k_noop() {};

  make_esc = require('iced-error').make_esc;
  _ref = require('./packet/signature'), Signature = _ref.Signature, CreationTime = _ref.CreationTime, Issuer = _ref.Issuer;
  unix_time = require('../util').unix_time;
  WordArray = require('triplesec').WordArray;
  konst = require('../const');
  C = konst.openpgp;
  Ch = require('../header');
  streamers = require('../hash').streamers;
  encode = require('./armor').encode;
  Literal = require("./packet/literal").Literal;
  VerifierBase = require('./verifier').Base;
  packetsigs = require('./packet/packetsigs');

  hash_obj_to_fn = function hash_obj_to_fn(obj) {
    var fn;

    fn = function fn(buf) {
      return obj.finalize(WordArray.from_buffer(buf)).to_buffer();
    };

    return fn.algname = buf.algname;
  };

  Signer = function () {
    function Signer(_arg) {
      this.data = _arg.data, this.hash_streamer = _arg.hash_streamer, this.signing_key = _arg.signing_key;
    }

    Signer.prototype.run = function (cb) {
      var encoded, esc, signature, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      esc = make_esc(cb, "Signer::run");
      (function (_this) {
        return function (__iced_k) {
          __iced_deferrals = new iced.Deferrals(__iced_k, {
            parent: ___iced_passed_deferral,
            filename: "/Users/max/src/keybase/kbpgp/src/openpgp/detachsign.iced",
            funcname: "Signer.run"
          });

          _this._run_hash(esc(__iced_deferrals.defer({
            lineno: 39
          })));

          __iced_deferrals._fulfill();
        };
      })(this)(function (_this) {
        return function () {
          (function (__iced_k) {
            __iced_deferrals = new iced.Deferrals(__iced_k, {
              parent: ___iced_passed_deferral,
              filename: "/Users/max/src/keybase/kbpgp/src/openpgp/detachsign.iced",
              funcname: "Signer.run"
            });

            _this._sign(esc(__iced_deferrals.defer({
              assign_fn: function () {
                return function () {
                  return signature = arguments[0];
                };
              }(),
              lineno: 40
            })));

            __iced_deferrals._fulfill();
          })(function () {
            (function (__iced_k) {
              __iced_deferrals = new iced.Deferrals(__iced_k, {
                parent: ___iced_passed_deferral,
                filename: "/Users/max/src/keybase/kbpgp/src/openpgp/detachsign.iced",
                funcname: "Signer.run"
              });

              _this._encode(esc(__iced_deferrals.defer({
                assign_fn: function () {
                  return function () {
                    return encoded = arguments[0];
                  };
                }(),
                lineno: 41
              })));

              __iced_deferrals._fulfill();
            })(function () {
              return cb(null, encoded, signature);
            });
          });
        };
      }(this));
    };

    Signer.prototype.scrub = function () {};

    Signer.prototype._sign = function (cb) {
      var emptybuf, err, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      this.sig = new Signature({
        sig_type: C.sig_types.canonical_text,
        key: this.signing_key.key,
        hashed_subpackets: [new CreationTime(unix_time())],
        unhashed_subpackets: [new Issuer(this.signing_key.get_key_id())],
        hasher: this.hash_streamer
      });
      emptybuf = Buffer.from([]);
      (function (_this) {
        return function (__iced_k) {
          __iced_deferrals = new iced.Deferrals(__iced_k, {
            parent: ___iced_passed_deferral,
            filename: "/Users/max/src/keybase/kbpgp/src/openpgp/detachsign.iced",
            funcname: "Signer._sign"
          });

          _this.sig.write(emptybuf, __iced_deferrals.defer({
            assign_fn: function (__slot_1) {
              return function () {
                err = arguments[0];
                return __slot_1._sig_output = arguments[1];
              };
            }(_this),
            lineno: 59
          }));

          __iced_deferrals._fulfill();
        };
      })(this)(function (_this) {
        return function () {
          return cb(err, _this._sig_output);
        };
      }(this));
    };

    Signer.prototype._encode = function (cb) {
      var err, ret;
      err = null;
      ret = encode(C.message_types.signature, this._sig_output);
      return cb(err, ret);
    };

    Signer.prototype._run_hash = function (cb) {
      var err;
      err = null;

      if (this.hash_streamer != null) {} else if (this.data != null) {
        this.hash_streamer = streamers.SHA512();
        this.hash_streamer.update(this.data);
      } else {
        err = new Error("Need either a hasher or data");
      }

      return cb(err);
    };

    return Signer;
  }();

  Verifier = function (_super) {
    __extends(Verifier, _super);

    function Verifier(_arg) {
      var keyfetch, packets;
      packets = _arg.packets, this.data = _arg.data, this.data_fn = _arg.data_fn, keyfetch = _arg.keyfetch, this.now = _arg.now, this.assert_pgp_hash = _arg.assert_pgp_hash;

      Verifier.__super__.constructor.call(this, {
        packets: packets,
        keyfetch: keyfetch
      });
    }

    Verifier.prototype._consume_data = function (cb) {
      var buf_hasher, done, err, go, klass, streamer, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      err = null;
      (function (_this) {
        return function (__iced_k) {
          if (_this.data_fn != null) {
            err = null;
            klass = _this._sig.hasher.klass;
            streamer = streamers[_this._sig.hasher.algname]();

            buf_hasher = function buf_hasher(buf) {
              return streamer.update(buf);
            };

            go = true;

            (function (__iced_k) {
              var _while2;

              _while2 = function _while(__iced_k) {
                var _break, _continue, _next;

                _break = __iced_k;

                _continue = function _continue() {
                  return iced.trampoline(function () {
                    return _while2(__iced_k);
                  });
                };

                _next = _continue;

                if (!go) {
                  return _break();
                } else {
                  (function (__iced_k) {
                    __iced_deferrals = new iced.Deferrals(__iced_k, {
                      parent: ___iced_passed_deferral,
                      filename: "/Users/max/src/keybase/kbpgp/src/openpgp/detachsign.iced",
                      funcname: "Verifier._consume_data"
                    });

                    _this.data_fn(buf_hasher, __iced_deferrals.defer({
                      assign_fn: function () {
                        return function () {
                          err = arguments[0];
                          return done = arguments[1];
                        };
                      }(),
                      lineno: 101
                    }));

                    __iced_deferrals._fulfill();
                  })(function () {
                    return _next(err != null || done ? go = false : void 0);
                  });
                }
              };

              _while2(__iced_k);
            })(function () {
              return __iced_k(_this._sig.hasher = streamer);
            });
          } else {
            return __iced_k();
          }
        };
      })(this)(function (_this) {
        return function () {
          return cb(err);
        };
      }(this));
    };

    Verifier.prototype._verify = function (cb) {
      var data, err, opts, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      data = this.data ? [new Literal({
        data: this.data
      })] : [];
      this.literals = data;
      opts = {
        now: this.now,
        assert_pgp_hash: this.assert_pgp_hash
      };
      (function (_this) {
        return function (__iced_k) {
          __iced_deferrals = new iced.Deferrals(__iced_k, {
            parent: ___iced_passed_deferral,
            filename: "/Users/max/src/keybase/kbpgp/src/openpgp/detachsign.iced",
            funcname: "Verifier._verify"
          });

          _this._sig.verify(data, __iced_deferrals.defer({
            assign_fn: function () {
              return function () {
                return err = arguments[0];
              };
            }(),
            lineno: 113
          }), opts);

          __iced_deferrals._fulfill();
        };
      })(this)(function (_this) {
        return function () {
          return cb(err);
        };
      }(this));
    };

    Verifier.prototype._make_literals = function (cb) {
      if (!this.literals.length) {
        this.literals.push(new Literal({
          data: Buffer.from([])
        }));
      }

      this.literals[0].push_sig(new packetsigs.Data({
        sig: this._sig
      }));
      return cb(null);
    };

    Verifier.prototype.run = function (cb) {
      var esc, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      esc = make_esc(cb, "Verifier::run");
      (function (_this) {
        return function (__iced_k) {
          __iced_deferrals = new iced.Deferrals(__iced_k, {
            parent: ___iced_passed_deferral,
            filename: "/Users/max/src/keybase/kbpgp/src/openpgp/detachsign.iced",
            funcname: "Verifier.run"
          });

          _this._find_signature(esc(__iced_deferrals.defer({
            lineno: 128
          })));

          __iced_deferrals._fulfill();
        };
      })(this)(function (_this) {
        return function () {
          (function (__iced_k) {
            __iced_deferrals = new iced.Deferrals(__iced_k, {
              parent: ___iced_passed_deferral,
              filename: "/Users/max/src/keybase/kbpgp/src/openpgp/detachsign.iced",
              funcname: "Verifier.run"
            });

            _this._fetch_key(esc(__iced_deferrals.defer({
              lineno: 129
            })));

            __iced_deferrals._fulfill();
          })(function () {
            (function (__iced_k) {
              __iced_deferrals = new iced.Deferrals(__iced_k, {
                parent: ___iced_passed_deferral,
                filename: "/Users/max/src/keybase/kbpgp/src/openpgp/detachsign.iced",
                funcname: "Verifier.run"
              });

              _this._consume_data(esc(__iced_deferrals.defer({
                lineno: 130
              })));

              __iced_deferrals._fulfill();
            })(function () {
              (function (__iced_k) {
                __iced_deferrals = new iced.Deferrals(__iced_k, {
                  parent: ___iced_passed_deferral,
                  filename: "/Users/max/src/keybase/kbpgp/src/openpgp/detachsign.iced",
                  funcname: "Verifier.run"
                });

                _this._verify(esc(__iced_deferrals.defer({
                  lineno: 131
                })));

                __iced_deferrals._fulfill();
              })(function () {
                (function (__iced_k) {
                  __iced_deferrals = new iced.Deferrals(__iced_k, {
                    parent: ___iced_passed_deferral,
                    filename: "/Users/max/src/keybase/kbpgp/src/openpgp/detachsign.iced",
                    funcname: "Verifier.run"
                  });

                  _this._make_literals(esc(__iced_deferrals.defer({
                    lineno: 132
                  })));

                  __iced_deferrals._fulfill();
                })(function () {
                  return cb(null, _this.literals);
                });
              });
            });
          });
        };
      }(this));
    };

    return Verifier;
  }(VerifierBase);

  exports.sign = function (_arg, cb) {
    var data, encoded, err, hash_streamer, now, s, signature, signing_key, ___iced_passed_deferral, __iced_deferrals, __iced_k;

    __iced_k = __iced_k_noop;
    ___iced_passed_deferral = iced.findDeferral(arguments);
    data = _arg.data, hash_streamer = _arg.hash_streamer, signing_key = _arg.signing_key, now = _arg.now;
    s = new Signer({
      data: data,
      hash_streamer: hash_streamer,
      signing_key: signing_key,
      now: now
    });
    (function (_this) {
      return function (__iced_k) {
        __iced_deferrals = new iced.Deferrals(__iced_k, {
          parent: ___iced_passed_deferral,
          filename: "/Users/max/src/keybase/kbpgp/src/openpgp/detachsign.iced",
          funcname: "sign"
        });
        s.run(__iced_deferrals.defer({
          assign_fn: function () {
            return function () {
              err = arguments[0];
              encoded = arguments[1];
              return signature = arguments[2];
            };
          }(),
          lineno: 139
        }));

        __iced_deferrals._fulfill();
      };
    })(this)(function (_this) {
      return function () {
        s.scrub();
        return cb(err, encoded, signature);
      };
    }(this));
  };

  exports.verify = function (_arg, cb) {
    var assert_pgp_hash, data, data_fn, err, keyfetch, literals, now, packets, v, ___iced_passed_deferral, __iced_deferrals, __iced_k;

    __iced_k = __iced_k_noop;
    ___iced_passed_deferral = iced.findDeferral(arguments);
    data = _arg.data, data_fn = _arg.data_fn, packets = _arg.packets, keyfetch = _arg.keyfetch, now = _arg.now, assert_pgp_hash = _arg.assert_pgp_hash;
    v = new Verifier({
      data: data,
      data_fn: data_fn,
      packets: packets,
      keyfetch: keyfetch,
      now: now,
      assert_pgp_hash: assert_pgp_hash
    });
    (function (_this) {
      return function (__iced_k) {
        __iced_deferrals = new iced.Deferrals(__iced_k, {
          parent: ___iced_passed_deferral,
          filename: "/Users/max/src/keybase/kbpgp/src/openpgp/detachsign.iced",
          funcname: "verify"
        });
        v.run(__iced_deferrals.defer({
          assign_fn: function () {
            return function () {
              err = arguments[0];
              return literals = arguments[1];
            };
          }(),
          lineno: 147
        }));

        __iced_deferrals._fulfill();
      };
    })(this)(function (_this) {
      return function () {
        return cb(err, literals);
      };
    }(this));
  };
}).call(this);