import { Button } from '@material-ui/core';
import React from 'react';
import {Component, Fragment} from 'react';
import { loadExternContatnsIfNecessary } from '../../external-constants/extern-constants';
import { t } from '../../i18n';
import { BaseUnishellPageState, renderInitErrorUnishellPage, renderInitUnishellPage, renderUnishellPage } from '../../unishell-page-common/unishell-page-common';
import { NoticeDialog } from '../../view/NoticeDialog';
import { Toast } from '../../view/Toast';
import { AddressInfo2Str, NutritionistInfo, ShopUserInfo } from '../ajax-data/ajax-data-types';
import { getMyShopUserInfo, retrieveNutritionist, updateShopUserInfo } from '../ajax-data/shopping-server-api';
import { doAJAXCall } from '../common/ajax-call';
import { displayServiceTime } from '../common/service-time';
import { gotoShoppingPathNSavePrevPath, gotoShoppingPathWOSavingPrevPath } from '../history/shopping-history';
import * as _ from 'lodash';

import './set-nutritionist.scss';
import { SimplePhoneNumber } from '../../widget/phone-number/phone-number';
import { SimpleEmail } from '../../widget/email/email';
import { UButton } from '../../widget/button/ubutton';
import { PropertyListDataItem, PropertyListWidget } from '../../widget/property-list/property-list';
import { RatioXXS } from '../../util/Util';
import { ConfirmDialogView } from '../../view/confirm-dialog/ConfirmDialogView';
import { getLanguageCode } from '../../local-storage/local-storage';
import { readSetNutritionistNotice } from '../../wallet-server-api/wallet-server-api';

interface MyProps{
    nutritionistUsername:string;
}

interface MyState extends BaseUnishellPageState {
    myShopUserInfo?: ShopUserInfo;
    nutritionistInfo?: NutritionistInfo;
    currNutritionistInfo?: NutritionistInfo;
}

export class SetMyNutritionistView extends Component<MyProps, MyState>{
    private errorDialog:NoticeDialog|undefined;
    private confirmDialog:ConfirmDialogView|undefined;
    private toast: Toast | undefined;

    constructor(props:MyProps){
        super(props);
        this.state = {pageInitState:'PAGE_INIT'}
    }
    getTitle(){
        return t('设置我的执业营养师')
    }

    gotoViewNutritionist(nutritionistId:string){        
        gotoShoppingPathNSavePrevPath('main/shopping/nutritionist-profile', {nutritionistId})
    }

    renderNutritionist(x:NutritionistInfo){
        let sex = t(x.gender||'', 'sex');

        const nutritionistProps:PropertyListDataItem[] = [
            {name: t('姓名'), value: <a href='#' onClick={evt=>{ evt.stopPropagation(); evt.preventDefault(); this.gotoViewNutritionist(x.username) }}>{x.name}</a>},
            {name: t('电邮'), value: <SimpleEmail email={x.officeEmail}/>},
            {name: t('电话'), value: <SimplePhoneNumber phoneNumber={x.officePhone}/>},
            {name: t('性别'), value: sex},
            {name: t('地址'), value: AddressInfo2Str(x.businessAddress)},
            {name: t('擅长项目'), value: x.expertIn||''},
            {
                name: t('服务时间'), 
                mergeCol: true,
                value: <div>
                            {x.serviceTime!.map(v=>{
                                return displayServiceTime(v)
                            })}
                        </div>
            },
        ];

        return <PropertyListWidget list={nutritionistProps} showBorder={true} mergeColScreenLetterRatio={RatioXXS} className='nutritionist-brief'/>
    }

    setMyNutritionist(nutritionistUsername:string){
        let myShopUserInfo = _.cloneDeep(this.state.myShopUserInfo!);
        myShopUserInfo.nutritionist = nutritionistUsername;
        myShopUserInfo.nutritionistLinkTime = Date.now();

        const langCode = getLanguageCode();
        const envNoticeTimeout = process.env.REACT_APP_NOTICE_TIMEOUT || '60'; // seconds
        const timeout = parseInt(envNoticeTimeout)*1000;
        let version = Math.floor((Date.now() / timeout))+'';

        doAJAXCall({
            whatIsFor: this.getTitle(),
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken:string)=>{
                const noticeHtml = await readSetNutritionistNotice(langCode, version);
                this.confirmDialog!.show({
                    title: t('健康管理告知书'),
                    message: <div dangerouslySetInnerHTML={{__html: noticeHtml}} />,
                    handleClose: async (ok)=>{
                        if(!ok) return;

                        await updateShopUserInfo(accessToken, myShopUserInfo);
                        gotoShoppingPathWOSavingPrevPath('main/tools/my-nutritionist');
                    }
                })

            },
            onError: ()=>{
                gotoShoppingPathWOSavingPrevPath('main/tools/my-nutritionist');
            }
        })                        
    }


    render(){
        if(this.state.pageInitState === 'PAGE_INIT'){
            return <Fragment>
                        {renderInitUnishellPage(
                            {
                                title: this.getTitle(), 
                                className:'set-nutritionist',
                                message:t('初始化...')
                            })
                        }
                        <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                        <Toast close={true} duration={2500} message='' ref={e=>{this.toast=e!}} toastType='info'/>
                    </Fragment>
        }
        if(this.state.pageInitState === 'PAGE_LOAD_FAILED'){
            return <Fragment>
                        {renderInitErrorUnishellPage(
                            {
                                title: this.getTitle(), 
                                className:'set-nutritionist',
                                errorMessage: this.state.initErrorMessage||t('初始化失败'),
                                onTryAgain: ()=>{ this.init();    }
                            })
                        }
                        <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                        <Toast close={true} duration={2500} message='' ref={e=>{this.toast=e!}} toastType='info'/>
                    </Fragment>
        }
        const content =(
            <Fragment>
                <div className='set-nutritionist-content'>
                    <h4>{t('执业营养师')}</h4>
                    {this.renderNutritionist(this.state.nutritionistInfo!)}
                    <UButton variant='contained' color='primary' 
                            onClick={()=>{
                                this.setMyNutritionist(this.props.nutritionistUsername);
                            }}
                    >{t('设为我的执业营养师')}</UButton> 
                </div>
                <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                <Toast close={true} duration={2500} message='' ref={e=>{this.toast=e!}} toastType='info'/>
                <ConfirmDialogView ref={e=>this.confirmDialog=e!} open={false} />
            </Fragment>);

        return renderUnishellPage({
            title: this.getTitle(), 
            className:'set-nutritionist',
            content
        })
    }

    init(){
        doAJAXCall({
            whatIsFor: this.getTitle(),
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken)=>{
                await loadExternContatnsIfNecessary();

                let myShopUserInfo = await getMyShopUserInfo(accessToken);
                let currNutritionistInfo: NutritionistInfo|undefined;

                if(myShopUserInfo.nutritionist){
                    currNutritionistInfo = await retrieveNutritionist(accessToken, myShopUserInfo.nutritionist);
                }
                let nutritionistInfo = await retrieveNutritionist(accessToken, this.props.nutritionistUsername);
                
                this.setState({pageInitState:'PAGE_LOAD_SUCCESS', myShopUserInfo, nutritionistInfo, currNutritionistInfo},
                               ()=>{
                                    if(myShopUserInfo.nutritionist === this.props.nutritionistUsername){
                                        this.toast!.sendMessage(t('该营养师已经是我的执业营养师'), 
                                                                'info',
                                                                true,
                                                                ()=>{
                                                                    gotoShoppingPathWOSavingPrevPath('main/tools/my-nutritionist');
                                                                });                    
                                    }                                                
                               })
            },
            onError: (errorMessage)=>{
                this.setState({pageInitState:'PAGE_LOAD_FAILED', initErrorMessage: errorMessage});
            }
        })
    }

    componentDidMount(){
        this.init();
    }
}