// Generated by IcedCoffeeScript 108.0.11
(function () {
  var C, KeyBlock, Message, OPS, Warnings, armor, athrow, bufeq_secure, do_message, hashmod, iced, import_key_pgp, katch, konst, make_esc, parse, unix_time, util, verify_clearsign, verify_detached, __iced_k, __iced_k_noop, _ref;

  iced = require('iced-runtime');

  __iced_k = __iced_k_noop = function __iced_k_noop() {};

  make_esc = require('iced-error').make_esc;
  OPS = require('../keyfetch').OPS;
  konst = require('../const');
  C = konst.openpgp;
  _ref = require('../util'), katch = _ref.katch, unix_time = _ref.unix_time, athrow = _ref.athrow, Warnings = _ref.Warnings, bufeq_secure = _ref.bufeq_secure;
  parse = require('./parser').parse;
  import_key_pgp = require('../symmetric').import_key_pgp;
  util = require('util');
  armor = require('./armor');
  hashmod = require('../hash');
  verify_clearsign = require('./clearsign').verify;
  verify_detached = require('./detachsign').verify;

  KeyBlock = function () {
    function KeyBlock(packets, opts) {
      this.packets = packets;
      this.verified_signatures = [];
      this.subkeys = [];
      this.primary = null;
      this.userids = [];
      this.user_attributes = [];
      this.warnings = new Warnings();
      this.opts = opts || {};

      if (this.opts.strict == null) {
        this.opts.strict = true;
      }
    }

    KeyBlock.prototype.to_obj = function () {
      return {
        subkeys: this.subkeys,
        primary: this.primary,
        userids: this.userids
      };
    };

    KeyBlock.prototype._extract_keys = function () {
      var err, i, p, _i, _len, _ref1;

      err = null;

      if (!this.packets.length) {
        err = new Error("No packets; cannot extract a key");
      } else if (!(this.primary = this.packets[0]).is_primary()) {
        err = new Error("First packet must be the primary key");
      } else {
        _ref1 = this.packets.slice(1);

        for (i = _i = 0, _len = _ref1.length; _i < _len; i = ++_i) {
          p = _ref1[i];

          if (p.is_key_material() && err == null) {
            if (p.key.is_toxic()) {
              this.warnings.push("Ignoring toxic subkey (ElGamal Encrypt+Sign)");
            } else if (!p.is_primary()) {
              this.subkeys.push(p);
            } else if (bufeq_secure(p.get_fingerprint(), this.primary.get_fingerprint())) {
              p.set_duplicate_primary();
            } else {
              err = new Error("cannot have 2 primary keys");
            }
          }
        }
      }

      return err;
    };

    KeyBlock.prototype._check_keys = function () {
      return this._check_primary() || this._check_subkeys();
    };

    KeyBlock.prototype._check_primary = function () {
      var err;
      return err = !this.primary.is_self_signed() ? new Error("no valid primary key self-signature or key(s) have expired") : (this.userids = this.primary.get_signed_userids()).length === 0 ? new Error("no valid Userid signed into key") : (this.user_attributes = this.primary.get_signed_user_attributes(), null);
    };

    KeyBlock.prototype._check_subkeys = function () {
      var err, i, k, msg, subkeys, _i, _len;

      subkeys = this.subkeys;
      err = null;
      this.subkeys = [];

      for (i = _i = 0, _len = subkeys.length; _i < _len; i = ++_i) {
        k = subkeys[i];

        if (err == null) {
          if (k.is_signed_subkey_of(this.primary, this.opts)) {
            this.subkeys.push(k);
          } else {
            msg = "Subkey " + i + " was invalid; discarding";
            this.warnings.push(msg);
          }
        }
      }

      return err;
    };

    KeyBlock.prototype.process = function (cb) {
      var err, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      err = this._extract_keys();
      (function (_this) {
        return function (__iced_k) {
          if (err == null) {
            (function (__iced_k) {
              __iced_deferrals = new iced.Deferrals(__iced_k, {
                parent: ___iced_passed_deferral,
                filename: "/Users/max/src/keybase/kbpgp/src/openpgp/processor.iced",
                funcname: "KeyBlock.process"
              });

              _this._verify_sigs(__iced_deferrals.defer({
                assign_fn: function () {
                  return function () {
                    return err = arguments[0];
                  };
                }(),
                lineno: 87
              }));

              __iced_deferrals._fulfill();
            })(__iced_k);
          } else {
            return __iced_k();
          }
        };
      })(this)(function (_this) {
        return function () {
          var _ref1;

          if ((_ref1 = _this.opts) != null ? _ref1.no_check_keys : void 0) {
            if (err == null) {
              _this.userids = _this.primary.get_signed_userids();
            }
          } else {
            if (err == null) {
              err = _this._check_keys();
            }
          }

          return cb(err);
        };
      }(this));
    };

    KeyBlock.prototype._get_issuer = function (i, packet, primary) {
      var fp, iid;

      if ((fp = packet.get_issuer_fingerprint()) != null) {
        return [fp, bufeq_secure(fp, this.primary.get_fingerprint()), this.primary.get_fingerprint()];
      }

      if ((iid = packet.get_issuer_key_id()) != null) {
        return [iid, bufeq_secure(iid, this.primary.get_key_id()), this.primary.get_key_id()];
      }

      this.warnings.push("Signature is missing an issuer (at packet=" + i + ")");
      return [null, false];
    };

    KeyBlock.prototype._verify_sigs = function (cb) {
      var err, expected_ours, i, issuer_id, matches_ours, msg, n_sigs, p, tmp, working_set, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      err = null;
      working_set = [];
      n_sigs = 0;
      (function (_this) {
        return function (__iced_k) {
          var _i, _len, _ref1, _results, _while2;

          _ref1 = _this.packets.slice(1);
          _len = _ref1.length;
          i = 0;

          _while2 = function _while(__iced_k) {
            var _break, _continue, _next;

            _break = __iced_k;

            _continue = function _continue() {
              return iced.trampoline(function () {
                ++i;
                return _while2(__iced_k);
              });
            };

            _next = _continue;

            if (!(i < _len)) {
              return _break();
            } else {
              p = _ref1[i];

              if (err == null) {
                (function (__iced_k) {
                  if (!p.is_signature()) {
                    if (n_sigs > 0) {
                      n_sigs = 0;
                      working_set = [];
                    }

                    if (!p.is_duplicate_primary()) {
                      working_set.push(p);
                    }

                    (function (__iced_k) {
                      _continue();
                    })(__iced_k);
                  } else {
                    return __iced_k();
                  }
                })(function () {
                  var _ref2;

                  n_sigs++;
                  _ref2 = _this._get_issuer(i, p, _this.primary), issuer_id = _ref2[0], matches_ours = _ref2[1], expected_ours = _ref2[2];

                  (function (__iced_k) {
                    if (issuer_id != null) {
                      (function (__iced_k) {
                        if (!(matches_ours || p.type === C.sig_types.key_revocation)) {
                          _this.warnings.push("Skipping signature by another issuer: " + (issuer_id != null ? issuer_id.toString('hex') : void 0) + " != " + (expected_ours != null ? expected_ours.toString('hex') : void 0));

                          (function (__iced_k) {
                            _continue();
                          })(__iced_k);
                        } else {
                          return __iced_k();
                        }
                      })(function () {
                        p.key = _this.primary.key;
                        p.primary = _this.primary;
                        p.is_third_party = !matches_ours;

                        (function (__iced_k) {
                          __iced_deferrals = new iced.Deferrals(__iced_k, {
                            parent: ___iced_passed_deferral,
                            filename: "/Users/max/src/keybase/kbpgp/src/openpgp/processor.iced",
                            funcname: "KeyBlock._verify_sigs"
                          });
                          p.verify(working_set, __iced_deferrals.defer({
                            assign_fn: function () {
                              return function () {
                                return tmp = arguments[0];
                              };
                            }(),
                            lineno: 137
                          }), _this.opts);

                          __iced_deferrals._fulfill();
                        })(function () {
                          return __iced_k(typeof tmp !== "undefined" && tmp !== null ? (msg = "Signature failure in packet " + i + ": " + tmp.message + " (" + issuer_id.toString('hex') + ")", _this.warnings.push(msg)) : _this.verified_signatures.push(p));
                        });
                      });
                    } else {
                      return __iced_k();
                    }
                  })(_next);
                });
              } else {
                return _continue();
              }
            }
          };

          _while2(__iced_k);
        };
      })(this)(function (_this) {
        return function () {
          return cb(err);
        };
      }(this));
    };

    return KeyBlock;
  }();

  Message = function () {
    function Message(_arg) {
      this.keyfetch = _arg.keyfetch, this.data_fn = _arg.data_fn, this.data = _arg.data, this.strict = _arg.strict, this.now = _arg.now, this.assert_pgp_hash = _arg.assert_pgp_hash;
      this.literals = [];
      this.enc_data_packet = null;
      this.warnings = new Warnings();
    }

    Message.prototype._get_session_key = function (cb) {
      var enc, err, esk_packets, fingerprint, index, key_ids, key_material, km, p, packet, pkcs5, privk, sesskey, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      key_ids = [];
      esk_packets = [];
      err = null;
      pkcs5 = false;

      key_ids = function () {
        var _results;

        _results = [];

        while (this.packets.length && (p = this.packets[0].to_esk_packet())) {
          esk_packets.push(p);
          this.packets.shift();

          _results.push(p.get_key_id());
        }

        return _results;
      }.call(this);

      (function (_this) {
        return function (__iced_k) {
          if (key_ids.length) {
            enc = true;

            (function (__iced_k) {
              __iced_deferrals = new iced.Deferrals(__iced_k, {
                parent: ___iced_passed_deferral,
                filename: "/Users/max/src/keybase/kbpgp/src/openpgp/processor.iced",
                funcname: "Message._get_session_key"
              });

              _this.keyfetch.fetch(key_ids, konst.ops.decrypt, __iced_deferrals.defer({
                assign_fn: function () {
                  return function () {
                    err = arguments[0];
                    km = arguments[1];
                    return index = arguments[2];
                  };
                }(),
                lineno: 178
              }));

              __iced_deferrals._fulfill();
            })(function () {
              (function (__iced_k) {
                if (err == null) {
                  packet = esk_packets[index];
                  key_material = km.find_pgp_key_material(key_ids[index]);
                  fingerprint = key_material.get_fingerprint();
                  privk = key_material.key;

                  (function (__iced_k) {
                    __iced_deferrals = new iced.Deferrals(__iced_k, {
                      parent: ___iced_passed_deferral,
                      filename: "/Users/max/src/keybase/kbpgp/src/openpgp/processor.iced",
                      funcname: "Message._get_session_key"
                    });
                    privk.decrypt_and_unpad(packet.ekey, {
                      fingerprint: fingerprint
                    }, __iced_deferrals.defer({
                      assign_fn: function () {
                        return function () {
                          err = arguments[0];
                          sesskey = arguments[1];
                          return pkcs5 = arguments[2];
                        };
                      }(),
                      lineno: 184
                    }));

                    __iced_deferrals._fulfill();
                  })(function () {
                    return __iced_k(err == null ? _this.encryption_subkey = key_material : void 0);
                  });
                } else {
                  return __iced_k();
                }
              })(__iced_k);
            });
          } else {
            return __iced_k(enc = false);
          }
        };
      })(this)(function (_this) {
        return function () {
          return cb(err, enc, sesskey, pkcs5);
        };
      }(this));
    };

    Message.prototype._find_encrypted_data = function (cb) {
      var err, ret;
      err = ret = null;

      if (this.packets.length && (ret = this.packets[0].to_enc_data_packet())) {
        this.packets.pop();
      } else {
        err = new Error("Could not find encrypted data packet");
      }

      return cb(err, ret);
    };

    Message.prototype._decrypt_with_session_key = function (sesskey, edat, pkcs5, cb) {
      var cipher, err, ret, ___iced_passed_deferral, __iced_deferrals, __iced_k, _ref1;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      _ref1 = katch(function () {
        return import_key_pgp(sesskey, pkcs5);
      }), err = _ref1[0], cipher = _ref1[1];
      (function (_this) {
        return function (__iced_k) {
          if (err == null) {
            (function (__iced_k) {
              __iced_deferrals = new iced.Deferrals(__iced_k, {
                parent: ___iced_passed_deferral,
                filename: "/Users/max/src/keybase/kbpgp/src/openpgp/processor.iced",
                funcname: "Message._decrypt_with_session_key"
              });
              edat.decrypt({
                cipher: cipher
              }, __iced_deferrals.defer({
                assign_fn: function () {
                  return function () {
                    err = arguments[0];
                    return ret = arguments[1];
                  };
                }(),
                lineno: 206
              }));

              __iced_deferrals._fulfill();
            })(__iced_k);
          } else {
            return __iced_k();
          }
        };
      })(this)(function (_this) {
        return function () {
          return cb(err, ret);
        };
      }(this));
    };

    Message.prototype._parse = function (raw, cb) {
      var err, packets, _ref1;

      _ref1 = parse(raw), err = _ref1[0], packets = _ref1[1];
      return cb(err, packets);
    };

    Message.prototype._decrypt = function (cb) {
      var edat, esc, is_enc, packets, pkcs5, plaintext, sesskey, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      esc = make_esc(cb, "Message::decrypt");
      (function (_this) {
        return function (__iced_k) {
          __iced_deferrals = new iced.Deferrals(__iced_k, {
            parent: ___iced_passed_deferral,
            filename: "/Users/max/src/keybase/kbpgp/src/openpgp/processor.iced",
            funcname: "Message._decrypt"
          });

          _this._get_session_key(esc(__iced_deferrals.defer({
            assign_fn: function () {
              return function () {
                is_enc = arguments[0];
                sesskey = arguments[1];
                return pkcs5 = arguments[2];
              };
            }(),
            lineno: 219
          })));

          __iced_deferrals._fulfill();
        };
      })(this)(function (_this) {
        return function () {
          (function (__iced_k) {
            if (is_enc) {
              (function (__iced_k) {
                __iced_deferrals = new iced.Deferrals(__iced_k, {
                  parent: ___iced_passed_deferral,
                  filename: "/Users/max/src/keybase/kbpgp/src/openpgp/processor.iced",
                  funcname: "Message._decrypt"
                });

                _this._find_encrypted_data(esc(__iced_deferrals.defer({
                  assign_fn: function () {
                    return function () {
                      return edat = arguments[0];
                    };
                  }(),
                  lineno: 221
                })));

                __iced_deferrals._fulfill();
              })(function () {
                (function (__iced_k) {
                  __iced_deferrals = new iced.Deferrals(__iced_k, {
                    parent: ___iced_passed_deferral,
                    filename: "/Users/max/src/keybase/kbpgp/src/openpgp/processor.iced",
                    funcname: "Message._decrypt"
                  });

                  _this._decrypt_with_session_key(sesskey, edat, pkcs5, esc(__iced_deferrals.defer({
                    assign_fn: function () {
                      return function () {
                        return plaintext = arguments[0];
                      };
                    }(),
                    lineno: 222
                  })));

                  __iced_deferrals._fulfill();
                })(function () {
                  (function (__iced_k) {
                    __iced_deferrals = new iced.Deferrals(__iced_k, {
                      parent: ___iced_passed_deferral,
                      filename: "/Users/max/src/keybase/kbpgp/src/openpgp/processor.iced",
                      funcname: "Message._decrypt"
                    });

                    _this._parse(plaintext, esc(__iced_deferrals.defer({
                      assign_fn: function () {
                        return function () {
                          return packets = arguments[0];
                        };
                      }(),
                      lineno: 223
                    })));

                    __iced_deferrals._fulfill();
                  })(function () {
                    return __iced_k(_this.packets = packets.concat(_this.packets));
                  });
                });
              });
            } else {
              return __iced_k();
            }
          })(function () {
            return cb(null);
          });
        };
      }(this));
    };

    Message.prototype._inflate = function (cb) {
      var esc, inflated, p, packets, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      packets = [];
      esc = make_esc(cb, "Message::_inflate");
      (function (_this) {
        return function (__iced_k) {
          var _i, _len, _ref1, _results, _while3;

          _ref1 = _this.packets;
          _len = _ref1.length;
          _i = 0;

          _while3 = function _while(__iced_k) {
            var _break, _continue, _next;

            _break = __iced_k;

            _continue = function _continue() {
              return iced.trampoline(function () {
                ++_i;
                return _while3(__iced_k);
              });
            };

            _next = _continue;

            if (!(_i < _len)) {
              return _break();
            } else {
              p = _ref1[_i];

              (function (__iced_k) {
                __iced_deferrals = new iced.Deferrals(__iced_k, {
                  parent: ___iced_passed_deferral,
                  filename: "/Users/max/src/keybase/kbpgp/src/openpgp/processor.iced",
                  funcname: "Message._inflate"
                });
                p.inflate(esc(__iced_deferrals.defer({
                  assign_fn: function () {
                    return function () {
                      return inflated = arguments[0];
                    };
                  }(),
                  lineno: 233
                })));

                __iced_deferrals._fulfill();
              })(function () {
                (function (__iced_k) {
                  if (typeof inflated !== "undefined" && inflated !== null) {
                    (function (__iced_k) {
                      __iced_deferrals = new iced.Deferrals(__iced_k, {
                        parent: ___iced_passed_deferral,
                        filename: "/Users/max/src/keybase/kbpgp/src/openpgp/processor.iced",
                        funcname: "Message._inflate"
                      });

                      _this._parse(inflated, esc(__iced_deferrals.defer({
                        assign_fn: function () {
                          return function () {
                            return p = arguments[0];
                          };
                        }(),
                        lineno: 235
                      })));

                      __iced_deferrals._fulfill();
                    })(function () {
                      return __iced_k(packets.push.apply(packets, p));
                    });
                  } else {
                    return __iced_k(packets.push(p));
                  }
                })(_next);
              });
            }
          };

          _while3(__iced_k);
        };
      })(this)(function (_this) {
        return function () {
          _this.packets = packets;
          return cb(null);
        };
      }(this));
    };

    Message.prototype._frame_signatures = function () {
      var o, p, payload, ret, stack, _i, _j, _len, _len1, _ref1;

      ret = [];
      stack = [];
      payload = [];
      _ref1 = this.packets;

      for (_i = 0, _len = _ref1.length; _i < _len; _i++) {
        p = _ref1[_i];

        if (p.tag === C.packet_tags.one_pass_sig) {
          stack.push({
            open: p
          });
        } else if (!stack.length) {} else if (p.tag === C.packet_tags.signature) {
          o = stack.pop();
          o.close = p;
          ret.push(o);
        } else {
          payload.push(p);
        }
      }

      for (_j = 0, _len1 = ret.length; _j < _len1; _j++) {
        o = ret[_j];
        o.payload = payload;
      }

      return ret;
    };

    Message.prototype._verify_sig = function (sig, cb) {
      var a, b, err, i, key_material, km, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      err = null;

      if (!bufeq_secure(a = sig.open.key_id, b = sig.close.get_key_id())) {
        err = new Error("signature mismatch open v close: " + (a != null ? a.toString('hex') : void 0) + " != " + (b != null ? b.toString('hex') : void 0));
      }

      (function (_this) {
        return function (__iced_k) {
          if (err == null) {
            (function (__iced_k) {
              __iced_deferrals = new iced.Deferrals(__iced_k, {
                parent: ___iced_passed_deferral,
                filename: "/Users/max/src/keybase/kbpgp/src/openpgp/processor.iced",
                funcname: "Message._verify_sig"
              });

              _this.keyfetch.fetch([a], konst.ops.verify, __iced_deferrals.defer({
                assign_fn: function () {
                  return function () {
                    err = arguments[0];
                    km = arguments[1];
                    return i = arguments[2];
                  };
                }(),
                lineno: 274
              }));

              __iced_deferrals._fulfill();
            })(function () {
              return __iced_k(err != null ? err = new Error("Can't find a key for " + a.toString('hex') + ": " + err.message) : void 0);
            });
          } else {
            return __iced_k();
          }
        };
      })(this)(function (_this) {
        return function () {
          (function (__iced_k) {
            if (err == null) {
              key_material = km.find_pgp_key_material(a);
              sig.close.key = key_material.key;
              sig.close.subkey_material = key_material;
              sig.close.key_manager = km;

              (function (__iced_k) {
                __iced_deferrals = new iced.Deferrals(__iced_k, {
                  parent: ___iced_passed_deferral,
                  filename: "/Users/max/src/keybase/kbpgp/src/openpgp/processor.iced",
                  funcname: "Message._verify_sig"
                });
                sig.close.verify(sig.payload, __iced_deferrals.defer({
                  assign_fn: function () {
                    return function () {
                      return err = arguments[0];
                    };
                  }(),
                  lineno: 289
                }), {
                  now: _this.now,
                  assert_pgp_hash: _this.assert_pgp_hash
                });

                __iced_deferrals._fulfill();
              })(__iced_k);
            } else {
              return __iced_k(!_this.strict ? (_this.warnings.push("Problem fetching key " + a.toString('hex') + ": " + err.toString()), err = null) : void 0);
            }
          })(function () {
            return cb(err);
          });
        };
      }(this));
    };

    Message.prototype._verify = function (cb) {
      var esc, sig, sigs, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      esc = make_esc(cb, "Message::_verify_sigs");
      sigs = this._frame_signatures();
      (function (_this) {
        return function (__iced_k) {
          var _i, _len, _ref1, _results, _while4;

          _ref1 = sigs;
          _len = _ref1.length;
          _i = 0;

          _while4 = function _while(__iced_k) {
            var _break, _continue, _next;

            _break = __iced_k;

            _continue = function _continue() {
              return iced.trampoline(function () {
                ++_i;
                return _while4(__iced_k);
              });
            };

            _next = _continue;

            if (!(_i < _len)) {
              return _break();
            } else {
              sig = _ref1[_i];

              (function (__iced_k) {
                __iced_deferrals = new iced.Deferrals(__iced_k, {
                  parent: ___iced_passed_deferral,
                  filename: "/Users/max/src/keybase/kbpgp/src/openpgp/processor.iced",
                  funcname: "Message._verify"
                });

                _this._verify_sig(sig, esc(__iced_deferrals.defer({
                  lineno: 303
                })));

                __iced_deferrals._fulfill();
              })(_next);
            }
          };

          _while4(__iced_k);
        };
      })(this)(function (_this) {
        return function () {
          return cb(null);
        };
      }(this));
    };

    Message.prototype.collect_literals = function () {
      var p, _i, _len, _ref1, _results;

      _ref1 = this.packets;
      _results = [];

      for (_i = 0, _len = _ref1.length; _i < _len; _i++) {
        p = _ref1[_i];

        if (p.tag === C.packet_tags.literal) {
          _results.push(p);
        }
      }

      return _results;
    };

    Message.prototype._process_generic = function (_arg, cb) {
      var esc, packets, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      packets = _arg.packets;
      this.packets = packets;
      esc = make_esc(cb, "Message:process");
      (function (_this) {
        return function (__iced_k) {
          __iced_deferrals = new iced.Deferrals(__iced_k, {
            parent: ___iced_passed_deferral,
            filename: "/Users/max/src/keybase/kbpgp/src/openpgp/processor.iced",
            funcname: "Message._process_generic"
          });

          _this._decrypt(esc(__iced_deferrals.defer({
            lineno: 316
          })));

          __iced_deferrals._fulfill();
        };
      })(this)(function (_this) {
        return function () {
          (function (__iced_k) {
            __iced_deferrals = new iced.Deferrals(__iced_k, {
              parent: ___iced_passed_deferral,
              filename: "/Users/max/src/keybase/kbpgp/src/openpgp/processor.iced",
              funcname: "Message._process_generic"
            });

            _this._inflate(esc(__iced_deferrals.defer({
              lineno: 317
            })));

            __iced_deferrals._fulfill();
          })(function () {
            (function (__iced_k) {
              __iced_deferrals = new iced.Deferrals(__iced_k, {
                parent: ___iced_passed_deferral,
                filename: "/Users/max/src/keybase/kbpgp/src/openpgp/processor.iced",
                funcname: "Message._process_generic"
              });

              _this._verify(esc(__iced_deferrals.defer({
                lineno: 318
              })));

              __iced_deferrals._fulfill();
            })(function () {
              return cb(null, _this.collect_literals());
            });
          });
        };
      }(this));
    };

    Message.prototype._verify_clearsign = function (_arg, cb) {
      var clearsign, err, literal, packets, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      packets = _arg.packets, clearsign = _arg.clearsign;
      (function (_this) {
        return function (__iced_k) {
          if (clearsign == null) {
            return __iced_k(err = new Error("no clearsign data found"));
          } else {
            (function (__iced_k) {
              __iced_deferrals = new iced.Deferrals(__iced_k, {
                parent: ___iced_passed_deferral,
                filename: "/Users/max/src/keybase/kbpgp/src/openpgp/processor.iced",
                funcname: "Message._verify_clearsign"
              });
              verify_clearsign({
                packets: packets,
                clearsign: clearsign,
                keyfetch: _this.keyfetch,
                now: _this.now,
                assert_pgp_hash: _this.assert_pgp_hash
              }, __iced_deferrals.defer({
                assign_fn: function () {
                  return function () {
                    err = arguments[0];
                    return literal = arguments[1];
                  };
                }(),
                lineno: 327
              }));

              __iced_deferrals._fulfill();
            })(__iced_k);
          }
        };
      })(this)(function (_this) {
        return function () {
          return cb(err, [literal]);
        };
      }(this));
    };

    Message.prototype.parse_and_inflate = function (body, cb) {
      var esc, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      esc = make_esc(cb, "Message::parse_and_inflate");
      (function (_this) {
        return function (__iced_k) {
          __iced_deferrals = new iced.Deferrals(__iced_k, {
            parent: ___iced_passed_deferral,
            filename: "/Users/max/src/keybase/kbpgp/src/openpgp/processor.iced",
            funcname: "Message.parse_and_inflate"
          });

          _this._parse(body, esc(__iced_deferrals.defer({
            assign_fn: function (__slot_1) {
              return function () {
                return __slot_1.packets = arguments[0];
              };
            }(_this),
            lineno: 334
          })));

          __iced_deferrals._fulfill();
        };
      })(this)(function (_this) {
        return function () {
          (function (__iced_k) {
            __iced_deferrals = new iced.Deferrals(__iced_k, {
              parent: ___iced_passed_deferral,
              filename: "/Users/max/src/keybase/kbpgp/src/openpgp/processor.iced",
              funcname: "Message.parse_and_inflate"
            });

            _this._inflate(esc(__iced_deferrals.defer({
              lineno: 335
            })));

            __iced_deferrals._fulfill();
          })(function () {
            return cb(null, _this.collect_literals());
          });
        };
      }(this));
    };

    Message.prototype.parse_and_process = function (msg, cb) {
      var esc, literals, packets, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      esc = make_esc(cb, "Message::parse_and_process");
      (function (_this) {
        return function (__iced_k) {
          __iced_deferrals = new iced.Deferrals(__iced_k, {
            parent: ___iced_passed_deferral,
            filename: "/Users/max/src/keybase/kbpgp/src/openpgp/processor.iced",
            funcname: "Message.parse_and_process"
          });

          _this._parse(msg.body, esc(__iced_deferrals.defer({
            assign_fn: function () {
              return function () {
                return packets = arguments[0];
              };
            }(),
            lineno: 342
          })));

          __iced_deferrals._fulfill();
        };
      })(this)(function (_this) {
        return function () {
          (function (__iced_k) {
            __iced_deferrals = new iced.Deferrals(__iced_k, {
              parent: ___iced_passed_deferral,
              filename: "/Users/max/src/keybase/kbpgp/src/openpgp/processor.iced",
              funcname: "Message.parse_and_process"
            });

            _this._process({
              msg: msg,
              packets: packets
            }, esc(__iced_deferrals.defer({
              assign_fn: function () {
                return function () {
                  return literals = arguments[0];
                };
              }(),
              lineno: 343
            })));

            __iced_deferrals._fulfill();
          })(function () {
            return cb(null, literals);
          });
        };
      }(this));
    };

    Message.prototype._verify_signature = function (_arg, cb) {
      var err, literals, packets, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      packets = _arg.packets;
      (function (_this) {
        return function (__iced_k) {
          if (!(_this.data != null || _this.data_fn != null)) {
            return __iced_k(err = new Error("Cannot verify detached signature without data input"));
          } else {
            (function (__iced_k) {
              __iced_deferrals = new iced.Deferrals(__iced_k, {
                parent: ___iced_passed_deferral,
                filename: "/Users/max/src/keybase/kbpgp/src/openpgp/processor.iced",
                funcname: "Message._verify_signature"
              });
              verify_detached({
                packets: packets,
                data: _this.data,
                data_fn: _this.data_fn,
                keyfetch: _this.keyfetch,
                now: _this.now,
                assert_pgp_hash: _this.assert_pgp_hash
              }, __iced_deferrals.defer({
                assign_fn: function () {
                  return function () {
                    err = arguments[0];
                    return literals = arguments[1];
                  };
                }(),
                lineno: 352
              }));

              __iced_deferrals._fulfill();
            })(__iced_k);
          }
        };
      })(this)(function (_this) {
        return function () {
          return cb(err, literals);
        };
      }(this));
    };

    Message.prototype._process = function (_arg, cb) {
      var err, literals, msg, packets, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      msg = _arg.msg, packets = _arg.packets;
      msg.type || (msg.type = C.message_types.generic);
      (function (_this) {
        return function (__iced_k) {
          switch (msg.type) {
            case C.message_types.generic:
              (function (__iced_k) {
                __iced_deferrals = new iced.Deferrals(__iced_k, {
                  parent: ___iced_passed_deferral,
                  filename: "/Users/max/src/keybase/kbpgp/src/openpgp/processor.iced",
                  funcname: "Message._process"
                });

                _this._process_generic({
                  packets: packets
                }, __iced_deferrals.defer({
                  assign_fn: function () {
                    return function () {
                      err = arguments[0];
                      return literals = arguments[1];
                    };
                  }(),
                  lineno: 361
                }));

                __iced_deferrals._fulfill();
              })(__iced_k);

              break;

            case C.message_types.clearsign:
              (function (__iced_k) {
                __iced_deferrals = new iced.Deferrals(__iced_k, {
                  parent: ___iced_passed_deferral,
                  filename: "/Users/max/src/keybase/kbpgp/src/openpgp/processor.iced",
                  funcname: "Message._process"
                });

                _this._verify_clearsign({
                  packets: packets,
                  clearsign: msg.clearsign
                }, __iced_deferrals.defer({
                  assign_fn: function () {
                    return function () {
                      err = arguments[0];
                      return literals = arguments[1];
                    };
                  }(),
                  lineno: 363
                }));

                __iced_deferrals._fulfill();
              })(__iced_k);

              break;

            case C.message_types.signature:
              (function (__iced_k) {
                __iced_deferrals = new iced.Deferrals(__iced_k, {
                  parent: ___iced_passed_deferral,
                  filename: "/Users/max/src/keybase/kbpgp/src/openpgp/processor.iced",
                  funcname: "Message._process"
                });

                _this._verify_signature({
                  packets: packets
                }, __iced_deferrals.defer({
                  assign_fn: function () {
                    return function () {
                      err = arguments[0];
                      return literals = arguments[1];
                    };
                  }(),
                  lineno: 365
                }));

                __iced_deferrals._fulfill();
              })(__iced_k);

              break;

            default:
              return __iced_k(err = new Error("Needed a 'generic', 'clearsign', or 'signature' PGP message, got " + msg.type));
          }
        };
      })(this)(function (_this) {
        return function () {
          return cb(err, literals);
        };
      }(this));
    };

    return Message;
  }();

  exports.KeyBlock = KeyBlock;
  exports.Message = Message;

  exports.do_message = do_message = function do_message(_arg, cb) {
    var armored, data, data_fn, err, esk, keyfetch, literals, msg, msg_type, now, proc, raw, strict, warnings, ___iced_passed_deferral, __iced_deferrals, __iced_k, _ref1;

    __iced_k = __iced_k_noop;
    ___iced_passed_deferral = iced.findDeferral(arguments);
    armored = _arg.armored, raw = _arg.raw, msg_type = _arg.msg_type, keyfetch = _arg.keyfetch, data_fn = _arg.data_fn, data = _arg.data, strict = _arg.strict, now = _arg.now;
    literals = null;
    err = msg = warnings = esk = null;

    if (armored != null) {
      _ref1 = armor.decode(armored), err = _ref1[0], msg = _ref1[1];
    } else if (raw != null) {
      msg_type || (msg_type = C.message_types.generic);
      msg = {
        body: raw,
        type: msg_type
      };
    } else {
      err = new Error("No input to do_message; need either 'armored' or 'raw' input");
    }

    (function (_this) {
      return function (__iced_k) {
        if (err == null) {
          if (strict == null) {
            strict = true;
          }

          proc = new Message({
            keyfetch: keyfetch,
            data_fn: data_fn,
            data: data,
            strict: strict,
            now: now
          });

          (function (__iced_k) {
            __iced_deferrals = new iced.Deferrals(__iced_k, {
              parent: ___iced_passed_deferral,
              filename: "/Users/max/src/keybase/kbpgp/src/openpgp/processor.iced"
            });
            proc.parse_and_process(msg, __iced_deferrals.defer({
              assign_fn: function () {
                return function () {
                  err = arguments[0];
                  return literals = arguments[1];
                };
              }(),
              lineno: 413
            }));

            __iced_deferrals._fulfill();
          })(function () {
            warnings = proc.warnings;
            return __iced_k(esk = proc.encryption_subkey);
          });
        } else {
          return __iced_k();
        }
      };
    })(this)(function (_this) {
      return function () {
        return cb(err, literals, warnings, esk);
      };
    }(this));
  };
}).call(this);