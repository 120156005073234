export const AJAX_CALL_STARTED = 'AJAX_CALL_STARTED';
export const AJAX_CALL_SUCC = 'AJAX_CALL_SUCC';
export const AJAX_CALL_FAILED = 'AJAX_CALL_FAILED';
export const AJAX_CALL_ERROR_DIALOG = 'AJAX_CALL_ERROR_DIALOG';

export interface AjaxCallState {
    state: typeof AJAX_CALL_STARTED|typeof AJAX_CALL_SUCC |typeof AJAX_CALL_FAILED | typeof AJAX_CALL_ERROR_DIALOG;
    whatIsFor: string;
    uuid: string; // match request and response
    error?: string;
}

export interface StartAjaxCallAction {
    type: typeof AJAX_CALL_STARTED;
    whatIsFor: string;
    uuid: string; // match request and response
}

export interface SuccAjaxCallAction{
    type: typeof AJAX_CALL_SUCC;
    whatIsFor: string;
    uuid: string; // match request and response
}

export interface FailedAjaxCallAction{
    type: typeof AJAX_CALL_FAILED;
    whatIsFor: string;
    uuid: string; // match request and response
    error: string;
}

export interface ErrorDialogAjaxCallAction{
    type: typeof AJAX_CALL_ERROR_DIALOG;
    whatIsFor: string;
    uuid: string; // match request and response
    error: string;
}

export type AjaxCallActionTypes = StartAjaxCallAction|SuccAjaxCallAction|FailedAjaxCallAction|ErrorDialogAjaxCallAction;