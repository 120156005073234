// Generated by IcedCoffeeScript 108.0.11
(function () {
  var K, SHA256, UnsealError, alloc, bufeq_secure, katch, null_hash, obj_extract, pack, purepack, read_base64, seal, unpack, unseal, _ref, _ref1;

  K = require('../const').kb;
  _ref = require('../hash'), alloc = _ref.alloc, SHA256 = _ref.SHA256;
  purepack = require('purepack');
  _ref1 = require('../util'), katch = _ref1.katch, obj_extract = _ref1.obj_extract, bufeq_secure = _ref1.bufeq_secure;
  UnsealError = require('../errors').errors.UnsealError;
  null_hash = Buffer.alloc(0);

  pack = function pack(x) {
    return purepack.pack(x, {
      sort_keys: true
    });
  };

  unpack = function unpack(x) {
    return purepack.unpack(x, {
      strict: true
    });
  };

  seal = function seal(_arg) {
    var dohash, hasher, obj, oo, packed;
    obj = _arg.obj, dohash = _arg.dohash;
    hasher = SHA256;
    oo = {
      version: K.versions.V1,
      tag: obj.tag,
      body: obj.body
    };

    if (dohash) {
      oo.hash = {
        type: hasher.type,
        value: null_hash
      };
      packed = pack(oo);
      oo.hash.value = hasher(packed);
    }

    return pack(oo);
  };

  read_base64 = function read_base64(raw) {
    var parts;
    parts = raw.split(/\s+/).join('');
    return Buffer.from(parts, 'base64');
  };

  unseal = function unseal(buf, _arg) {
    var h, hasher, hv, oo, strict, t, _ref2;

    strict = (_arg != null ? _arg : {}).strict;
    oo = unpack(buf);

    if ((hv = oo != null ? (_ref2 = oo.hash) != null ? _ref2.value : void 0 : void 0) != null) {
      oo.hash.value = null_hash;
      hasher = alloc(t = oo.hash.type);

      if (hasher == null) {
        throw new UnsealError("unknown hash algo: " + t);
      }

      h = hasher(pack(oo));

      if (!bufeq_secure(h, hv)) {
        throw new UnsealError("hash mismatch");
      }

      if (oo.version !== K.versions.V1) {
        throw new UnsealError("unknown version");
      }
    } else if (strict) {
      throw new UnsealError("need a hash in strict mode");
    }

    return obj_extract(oo, ['tag', 'body']);
  };

  exports.seal = seal;
  exports.pack = pack;
  exports.unseal = unseal;
  exports.unpack = unpack;
  exports.read_base64 = read_base64;
}).call(this);