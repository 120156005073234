export function info(...x:any[]){
    (x||[]).forEach(v=>{ console.log(v)});
}

export function warn(...x:any[]){
    (x||[]).forEach(v=>{ console.warn(v)});
}

export function debug(...x:any[]){
    (x||[]).forEach(v=>{ console.debug(v)});
}

/**
 * get cordova on the fly using function in case windo.cordova is not initialized at the start up time in ios app
 */
//@ts-ignore
export function getCordova () {return window.cordova;}

// @ts-ignore
export const checkUpgrade: (()=>void) =  window.phoneGapCallback;

// @ts-ignore
export const checkPaymentAppAvailability: (aliPay:(installed:boolean)=>void, wechatPay:(installed:boolean)=>void)=>void = window.checkPaymentAppAvailability;

// @ts-ignore
export function getWechat(){ return window.Wechat; }

// @ts-ignore
export function getManifest(){ return window.Manifest;}

// @ts-ignore
export function getDevice(){return window.device;}

// @ts-ignore
export function openUrl(url:string){
  if (getCordova()) {
    const target="_system";
    getCordova().InAppBrowser.open(url, target, 'location=yes');
  }
  else{
    window.open(url,'_system');
  }
}


/**
 * extran top padding to handle top notch of iphone x
 * @returns the number of px
 */
// @ts-ignore
export function getTopPadding() { 
  if(!getDevice()) return 0;

  var model=getDevice().model;
  var topPadding=0;
  info("window.device.model="+model);
  if (model.startsWith("iPhone")) {
    let modelStr=model.replace("iPhone","").replace(",","");
    let modelNumber=parseInt(modelStr);
    if (modelNumber>=106)
      topPadding= 44;
    else
      topPadding=20;
  }
  else if (model.startsWith("iPad"))
    topPadding=20;
  info("return "+topPadding);
  return topPadding;
}

// @ts-ignore
export function getBottomPadding() { 
  if(!getDevice()) return 0;

  return 5 
}

export function getViewPortSize(){
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

    return {vw, vh}
}