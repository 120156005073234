import { Paper } from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp, ChevronRight } from '@material-ui/icons';
import * as React from 'react';
import {Component, Fragment} from 'react';
import './list-menu.scss';

interface MyProps {
    label:string, 
    className?:string, 
    onClick?:()=>void, 
    isActionButton?: boolean
}

interface MyState {
    expended?: boolean;
}

export class ListMenu extends Component<MyProps, MyState>{
    constructor(props: MyProps){
        super(props);
        this.state = {}
    }

    render(){
        const hasChildren = !!this.props.children;

        return(
            <Fragment>
              <div className={'list-menu '+(this.props.className||'')} 
                       onClick={()=>{
                            if(hasChildren){//toggle children
                                this.setState({expended: !this.state.expended});
                            }else if(this.props.onClick){
                                this.props.onClick()
                            }
                        }}>
                    <span className='list-menu-label'>{this.props.label}&nbsp;</span>
                    { hasChildren ? 
                      <Fragment>
                      {this.state.expended ? <ArrowDropUp className='expand-icon'/> : <ArrowDropDown className='expand-icon'/>}
                      </Fragment> 
                      : 
                      <Fragment>
                      {!this.props.isActionButton? <ChevronRight className='chevron-right-icon'/>: null}    
                      </Fragment>
                    }    
                </div>
                {hasChildren && this.state.expended ? 
                    <div className='list-menu-children'>
                        {this.props.children}
                    </div>: null
                }    
            </Fragment>
        )
    }
}