import * as React from 'react';
import {Component, Fragment} from 'react';
import classNames from 'classnames';
import { AjaxCallState, AJAX_CALL_STARTED, AJAX_CALL_ERROR_DIALOG } from '../redux-store/ajax-call/types';

import  './ScreenOverlayView.scss';
import { NoticeDialog } from './NoticeDialog';

interface ScreenOverlayViewProp {
    ajaxCallState: AjaxCallState
}

export class ScreenOverlayView extends Component<ScreenOverlayViewProp,{}>{
    constructor(props: ScreenOverlayViewProp){
        super(props);
    }

    render(){
        // info('ScreenOverlayView render '+this.props.ajaxCallState.state);
        switch(this.props.ajaxCallState.state){
            case AJAX_CALL_STARTED: return <div className="loading"></div>; 
            case AJAX_CALL_ERROR_DIALOG: return <NoticeDialog open={true} title={this.props.ajaxCallState.whatIsFor} message={this.props.ajaxCallState.error}/>
            default: return null;
        }
    }
}