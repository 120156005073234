import * as React from 'react';
import {Component, Fragment} from 'react';

import {t} from '../../i18n';
import { ReactReduxContext, batch } from 'react-redux';
import './ScanTransferView.scss';
import { Divider, TextField, Button } from '@material-ui/core';
import { Points } from '../../container/Points';
import {WalletAppState} from '../../redux-store';
import { PointsInputView } from './PointsInputView';
import { Toast } from '../Toast';
import { NoticeDialog } from '../NoticeDialog';
import { Store } from 'redux';
import { TransferDetail } from '../../api/transaction';
import { readPersistData, readLoginSession, readLastLoginEmail } from '../../local-storage/local-storage';
import { loadPrivateKey, signMessage } from '../../cypto/crypto';
import { transferUnishellPoints, retrieveUnishellPoints, getPaymentConfig } from '../../wallet-server-api/wallet-server-api';
import { setPointsRecord } from '../../redux-store/points';
import { FooterView } from '../footer/footer';
import { genPaymentKey, encryptPayment } from '../../util/Util';
import {PasswordDialogView} from '../password-dialog/PasswordDialogView';
import { PaymentConfig } from '../../api/common';
import { BaseUnishellPageState, renderInitUnishellPage, renderUnishellPage } from '../../unishell-page-common/unishell-page-common';
import { gotoLoginPage, shoppingGoBack } from '../../shopping/history/shopping-history';
import { doAJAXCall } from '../../shopping/common/ajax-call';
import { loadExternContatnsIfNecessary } from '../../external-constants/extern-constants';
import { info } from '../../globals';
import { UButton } from '../../widget/button/ubutton';

export interface ScanQRCodeResult {
    text:string; // qr code text
    format:string; // QR_CODE
    cancelled:boolean;
    message: string;
    username?: string;  // query from backend server
    name?:string;
    userEmail?: string;
}


interface ScanTransferViewProps {
    name: string;
    username: string;
    userEmail:string;
    dispatch: (x:any)=>void;
}

interface ScanTransferViewState extends BaseUnishellPageState{
    paymentConfig?: PaymentConfig;

    points: string;
    pointsError:string;
    memo:string;
    transferDone: boolean;
}

const TOAST_DURATION = 2000;

export class ScanTransferView extends Component<ScanTransferViewProps, ScanTransferViewState>{
    private toast: Toast | undefined;
    private passwordDialog: PasswordDialogView | undefined;
    private errorDialog: NoticeDialog|undefined;

    constructor(props: ScanTransferViewProps){
        super(props);
        this.state = {pageInitState:'PAGE_INIT', points:'', pointsError: '', memo: '', transferDone: false}

    }

    updateMemo = (value:string)=>{
        let v = value.substring(0,120);
        let lnCnt = 0;
        for (let i = 0; i < v.length; i++) {
            if(v.charAt(i)==='\n'){
                lnCnt ++;
                if(lnCnt>=3){
                    this.setState({memo: v.substring(0,i)});
                    return;
                }
            }
        }
        this.setState({memo:v})
    }

    doTransfer = (password:string, store:Store, recipientAddr:string)=>{
        const session = readLoginSession()!;
        if(!session){
            gotoLoginPage();
            return;
        }
        const paymentKey = genPaymentKey(readLastLoginEmail()!.toLowerCase()+password);

        const nonce = ''+Math.random();
        const transferDetail:TransferDetail = {points:Number.parseFloat(this.state.points), 
                                               toUsername:recipientAddr, 
                                               memo:this.state.memo, nonce, time:Date.now()}
        const localData = readPersistData(session.profile!.username);
        info(`read local storage`);
        if(!localData){
            this.toast!.sendMessage(t('数据出错。请联系Unishell客服'), 'error', false);
            return;
        }

        let whatIsFor:string = t('转移Unishell');
        doAJAXCall(
            {
                whatIsFor,
                errorDialog: this.errorDialog!,
                ajaxCall: async (accessToken) => {
                    const keyManager = await loadPrivateKey(localData!.keyPair.priKey);
                    const {signedMessage} = await signMessage(keyManager, transferDetail);
                    let encryptedData = encryptPayment(signedMessage, paymentKey);
                    await transferUnishellPoints(accessToken, 
                        {keyId: localData.pkeyHash, data: encryptedData}); 
                    const newPoints = await  retrieveUnishellPoints(accessToken);                       
                    store.dispatch(setPointsRecord(newPoints));
                    this.setState({transferDone: true}, ()=>{
                        this.toast!.sendMessage(whatIsFor+t(' 成功'), 'success', false, 
                                                ()=>{  
                                                    shoppingGoBack();
                                                });
                    })
                }
            }
        )
    }

    render(){
        if(this.state.pageInitState === 'PAGE_INIT'){
            return <Fragment>
                {
                    renderInitUnishellPage({
                        title: t('扫码转出'),
                        className: 'scan-transfer-page',
                        message: t('初始化...')    
                    })
                }
                <Toast close={true} message='' toastType='info' onClose={()=>{}} ref={(e)=>{this.toast=e!}} duration={TOAST_DURATION}/>
                <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>                   
            </Fragment>;
        }
        const session = readLoginSession()!;

        return (
            <ReactReduxContext.Consumer>
            { ({store})=>{
                const state:WalletAppState = store.getState();

                const validatePoints = (points:string)=>{
                    // @ts-ignore
                    if(!points || isNaN(points)){
                        return t('错误数量格式');
                    }else{
                        const pts = Number.parseFloat(points);
                        if(pts<=0){
                            return t('转移数量不够');
                        }else if(pts>this.state.paymentConfig!.transferMax){
                            return t('转移数量不超过'+this.state.paymentConfig!.transferMax);
                        }else if(!session.profile!.roles.some(v=>v==='ROLE_ADMIN') && state.points.points<pts){
                            return t('账户数量不够');
                        }else{
                            return '';
                        }
                    }
                }
            
                const pointsValidator = (points:string)=> {
                    this.setState({points, pointsError:validatePoints(points)})                        
                }

                const validatePassword = (password:string)=>{
                    return password ? undefined: t('输入登录密码');
                }
                
                const handleConfirmation = (password: string, confirmed:boolean)=>{
                    if(confirmed){
                        this.doTransfer(password, store, this.props.username!);
                    }
                }

                const clickSubmit = ()=>{
                    const pointsError = validatePoints(this.state.points);
                    if(pointsError){
                        this.setState({pointsError});
                        return;
                    }
                    this.passwordDialog!.show(<Fragment>{t(`确认转移`)} {this.state.points} {t('点给')} <br/>
                                                        {this.props.name}
                                            </Fragment>);
                }


                let formBody = <Fragment>
                                    <div className='scan-transfer-recipient'>
                                         <span className='label'>{t('收方姓名')}</span>
                                         <span className='value'>{this.props.name}</span>
                                         <span className='value'>{this.props.userEmail}</span>
                                    </div>
                                    <div className='scan-transfer-recipient'>
                                         <span className='label'>{t('收方地址')}</span>
                                         <span className='value' style={{fontFamily:'monospace'}}>{this.props.username}</span>
                                    </div>
                                    <Divider/>

                                    <PointsInputView points={this.state.points} error={this.state.pointsError} updatePoints={pointsValidator} />
                                    <div className='memo-input-view'>
                                        <TextField value={this.state.memo} spellCheck={false} label={t('备注')} 
                                                onChange={(e)=>{ this.updateMemo(e.target.value) } }
                                                multiline rows={1} rowsMax={3} className='memo-input'/>
                                    </div>
                                    <div className='scan-transfer-action'>
                                        <UButton className='manual-transfer-submit-btn' 
                                                variant='contained' color='primary' 
                                                onClick={clickSubmit} disabled={this.state.transferDone}>{t('提交')}</UButton>
                                    </div>
                               </Fragment>

                const content =(
                        <Fragment>
                            <Points/>
                            <Divider/>

                            <div className='scan-transfer-form'>
                            {formBody}
                            </div>

                            <FooterView/>
                            <Toast close={true} message='' toastType='info' onClose={()=>{}} ref={(e)=>{this.toast=e!}} duration={TOAST_DURATION}/>

                            <PasswordDialogView confirmLabel={t('同意')} cancelLabel={t('取消')} 
                                                    onClose={handleConfirmation}
                                                    ref={e=>{this.passwordDialog=e!}} 
                            />
                            <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>                   
                        </Fragment>
                );
                return renderUnishellPage({
                    title: t('扫码转出'),
                    className: 'scan-transfer-page',
                    content
                })        
             }  
            }
            </ReactReduxContext.Consumer>
        )
    }

    componentDidMount(){
        let whatIsFor = t("初始化");
        doAJAXCall({
            whatIsFor,
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken)=>{
                await loadExternContatnsIfNecessary();
               const paymentConfig = await getPaymentConfig(accessToken); 
               this.setState({pageInitState:'PAGE_LOAD_SUCCESS', paymentConfig});
            },
            onError: (errorMessage)=>{
                shoppingGoBack();
            }
        })
    }
}
