import classnames from 'classnames';
import * as React from 'react';
import {Component, Fragment} from 'react';
import {ReactComponent as EditIcon} from '../../images/edit.svg'
import {ReactComponent as DoneIcon} from '../../images/done.svg'
import {ReactComponent as SourceIcon} from '../../images/coding.svg'

import Editor from "rich-markdown-editor";
import TextareaAutosize from 'react-textarea-autosize';

import './markdown-editor.scss';
import { t } from '../../i18n';


interface MyProps {
    storageId: string;
    title: string;
    className?:string;
    doc: string;
    done: (doc:string)=>void;
}

interface MyState {
    doc:string;
    editMode?: boolean;
    sourceMode?: boolean;
}

export class MarkdownEditor extends Component<MyProps, MyState> {
    private editor:Editor|undefined;

    constructor(props: MyProps){
        super(props);
        this.state = {doc: this.props.doc}
    }

    getDoc(){
        return this.editor!.value();
    }
    
    render(){
        const {editMode, sourceMode, doc} = this.state;
        let icons:JSX.Element|null = null;
        let editor:JSX.Element|null = null;

        if(editMode){
            icons = <Fragment>
                        <SourceIcon className={classnames('source-icon', {active: sourceMode})}
                                    onClick={()=>{
                                        if(sourceMode){
                                            // back to normal editor mode
                                            this.setState({sourceMode: false});
                                        }else{
                                            // show source
                                            let doc = this.editor!.value();
                                            this.setState({sourceMode: true, doc});
                                        }
                                    }}
                        />
                        <DoneIcon 
                                className='edit-icon' 
                                onClick={()=>{ 
                                    this.setState({editMode: false, doc: this.editor!.value()}, ()=>{
                                        this.props.done(this.state.doc);
                                    })  
                                }}/>
                    </Fragment>;        
        }else{
            icons = <EditIcon className='edit-icon'  onClick={()=>{ this.setState({editMode: true})  }}/>
        }

        if(editMode && sourceMode){
            editor =<TextareaAutosize 
                        className='markdown-source'
                        minRows={5} 
                        value={doc}
                        onChange={(evt)=>{
                            this.setState({doc: evt.currentTarget.value});
                        }}
                    />

        }else{
            editor = <Editor
                            id={this.props.storageId}
                            value={doc}
                            readOnly={!editMode}
                            placeholder={this.props.title+'...'}
                            ref={e=> this.editor = e! }
                    />
        }
        

        return <div className={classnames('markdown-editor', this.props.className, {edit:this.state.editMode}, {readonly: !this.state.editMode})}>
                   <div className='title'>{this.props.title}</div>
                    {icons}
                    {editor}
              </div>
    }
    
}