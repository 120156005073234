import {PagePathState, SetPagePathAction, SET_PAGE_PATH} from './types';
import deepEqual from 'deep-equal';

const initState:PagePathState = {
    currentPage:'login'
}

export function pathPathReducer(state = initState, action:SetPagePathAction):PagePathState {
    switch(action.type){
        // unishell-pay path: could be called again and again by merchant web site
        // login path: could be called 
        case SET_PAGE_PATH: if(state.currentPage === 'login' || 
                               state.currentPage==='unishell-pay' || 
                               state.currentPage !== action.currentPage || 
                               !deepEqual(action.parms, state.parms)){
                                const prevPage = state.currentPage;
                                const prevParms = state.parms;
                                // alert(`path reducer: apply action ${JSON.stringify(action)}`);
                                return {currentPage: action.currentPage, parms:action.parms, prevPage, prevParms};
                            } 
        default: return state;
    }
}