import TextField from '@material-ui/core/TextField';
import * as EmailValidator from 'email-validator';
import * as React from 'react';
import { Component, Fragment } from 'react';

import { LoginRequest } from '../api/user';
import { t } from '../i18n';
import { getLanguageCode, readLastLoginEmail } from '../local-storage/local-storage';
import { Copywright } from './copywright/Copywright';
import './LoginView.scss';

import welcomeImg from '../images/start-from-here.png';

import { loadExternContatnsIfNecessary } from '../external-constants/extern-constants';
import { getCordova } from '../globals';
import { BaseUnishellPageState, renderInitUnishellPage } from '../unishell-page-common/unishell-page-common';
import { getServerURL, hideGlobalSpinner, pointingProdServer, showGlobalSpinner, stripNonPrintableChars } from '../util/Util';
import { UButton } from '../widget/button/ubutton';
import { SimpleNote } from '../widget/note/note';
import { LangView } from './language/LangView';
import * as _ from 'lodash';
import { NoticeDialog } from './NoticeDialog';
import { doAJAXCall } from '../shopping/common/ajax-call';
import { readPrivacy, readTerms } from '../wallet-server-api/wallet-server-api';
import { Accordion, AccordionDetails, AccordionSummary, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

interface LoginProp {
    login: (request:LoginRequest)=>void;
    gotoLostPassword: (email:string)=>void;
    gotoRegisterAccount: ()=>void;
    notice?:string;
    password?: string;
    updateLanguage: (newLangCode:string)=>void;
}

interface LoginState extends BaseUnishellPageState{
    request: LoginRequest;
    emailError?: string;
    passwordError?:string;

    termsAndPrivacy?: {
        termsHtml: string;
        privacyHtml: string;
    }
}

export class LoginView extends Component<LoginProp,LoginState> {
    private errorDialog: NoticeDialog|undefined;

    constructor(props:LoginProp){
        super(props);
        this.state = {request:{usernameOrEmail: readLastLoginEmail()||'', password:this.props.password||''},
                      pageInitState:'PAGE_INIT'}
    }


    setEmail = (value:string)=>{
        const request = {...this.state.request}
        request.usernameOrEmail = stripNonPrintableChars(value).trim().substring(0,40);
        this.setState({request, emailError: undefined})
    }
    setPassword = (value:string)=>{
        const request = {...this.state.request}
        request.password = value.substring(0,40);
        this.setState({request})
    }

    onClickLogin = ()=>{
        const emailError = EmailValidator.validate(this.state.request.usernameOrEmail)?undefined:t('电邮地址错误')+` [${this.state.request.usernameOrEmail}]`;
        const passwordError = this.state.request.password? undefined: t('请输入密码');

        if(emailError || passwordError){
            this.setState({emailError, passwordError});
        }else{
            this.setState({emailError, passwordError}, ()=>{
                this.props.login(this.state.request);
            });
        }
    }


    render(){
        if(this.state.pageInitState === 'PAGE_INIT'){
            return (
                <Fragment>
                { renderInitUnishellPage({
                      title:t('登录'),
                      className: 'login-page',
                      noMenuIcon: true                    
                })}
                </Fragment>
            )
        }

        return (
            <div className={'login-page app-page '+getLanguageCode()}>
                {/* <UnishellLogo className='logo'/> */}
                <div className='name-zh'>欢迎来到<span className='unishell'>予健寰宇</span></div>
                {/* <div className='name-en'>Welcome To <span className='unishell'>Unishell</span></div> */}

                <img src={welcomeImg} alt='start from here' className='start-from-here-img'/>

                {this.props.notice? 
                   <SimpleNote noticeType='info' message={this.props.notice} className='notice'/>: null
                //   <Paper className='notice'>{this.props.notice}</Paper>:null
                }


                <div className='language-register'>
                    <LangView updateLanguage={this.props.updateLanguage} color='black'/>
                    <div className='register-account-link' 
                         onClick={()=>{ this.readTermsAndPrivacyPolicy() }}>
                        {t('注册')}
                    </div>
                </div>

                {getCordova()? null:
                 <SimpleNote noticeType='warn' message={t('警告：不要在公共或他人电脑上使用予健寰宇.')}/>
                }
                <form className='login-view'>
                    <div className="input-wrapper">
                        <TextField 
                            className='login-input' name='unishell-email' label={t('电子邮箱')} margin='normal' 
                            variant='outlined'
                            value={this.state.request.usernameOrEmail} autoComplete='on'
                            onChange={(evt)=>{ this.setEmail(evt.target.value)}}/>
                    </div>
                    <div className="input-error-message">
                     {this.state.emailError}
                    </div>
                    <div className="input-wrapper">
                        <TextField 
                            className='login-input' label={t('密码')} type='password' margin='normal'
                            variant='outlined'
                            value={this.state.request.password}
                            onChange={(evt)=>{ this.setPassword(evt.target.value)}}
                            onKeyDown={evt=>{
                                if(evt.key === 'Enter' && this.state.request.password && 
                                   this.state.request.usernameOrEmail){
                                    evt.preventDefault();
                                    this.onClickLogin();
                                }
                            }}
                        />
                    </div>
                    <div className="input-error-message">
                     {this.state.passwordError}
                    </div>

                </form>
                <div className='login-btn-wrapper'>
                    <UButton variant="contained" color="primary" className='login-btn'
                            onClick={this.onClickLogin}>{t('登录')}</UButton>
                </div>

                <div className='lost-password-wrapper'>
                    <UButton variant='contained' color='secondary' className='lost-password-btn' 
                            onClick={()=>{this.props.gotoLostPassword(this.state.request.usernameOrEmail)}}>
                        {t('忘记密码')+'?'}
                    </UButton>
                </div>
                {
                    pointingProdServer()? null : <div className='server-url'>
                        <div>{getServerURL()}</div>
                    </div>
                }
                <Copywright/>
                {this.renderTermsAndPrivacyDialog()}
                <NoticeDialog open={false} ref={e=> {this.errorDialog=e!}} title='' message=''/>
        </div>
        )    
    }

    readTermsAndPrivacyPolicy() {
        let theWhatIsFor = t(`读取会员条款`);
        const envNoticeTimeout = process.env.REACT_APP_NOTICE_TIMEOUT || '60'; // seconds
        const timeout = parseInt(envNoticeTimeout)*1000;
        let version = Math.floor((Date.now() / timeout))+'';
        let langCode = getLanguageCode();


        showGlobalSpinner();
        setTimeout(()=>{
            doAJAXCall({
                withoutLoginSession: true,
                whatIsFor: theWhatIsFor,
                whatIsForFn: ()=> theWhatIsFor,
                errorDialog: this.errorDialog!,
                ajaxCall: async () => {
                    let termsHtml = await readTerms(langCode, version);
                    theWhatIsFor = t('读取隐私政策');
                    let privacyHtml = await readPrivacy(langCode, version);
                    hideGlobalSpinner();
                    this.setState({termsAndPrivacy: {termsHtml, privacyHtml}}, ()=>{  });    
                },
                onError:()=>{
                    hideGlobalSpinner();
                }
            })    
        }, 50);
    }

    renderTermsAndPrivacyDialog(): React.ReactNode {
        const {termsAndPrivacy} = this.state;
        
        if(!termsAndPrivacy) return null;

        return (
            <Dialog maxWidth='xl' open={!!termsAndPrivacy} className='terms-and-privacy-dialog'>
                <DialogTitle>{t('隐私政策和会员条款')}</DialogTitle>
                <DialogContent>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>{t('隐私政策')}</AccordionSummary>
                        <AccordionDetails>
                            <div className='policy'>
                                <div dangerouslySetInnerHTML={{__html: termsAndPrivacy.privacyHtml}} />
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>{t('会员条款')}</AccordionSummary>
                        <AccordionDetails>
                        <div className='unishell-club-terms'>
                                <div dangerouslySetInnerHTML={{__html: termsAndPrivacy.termsHtml}} />
                            </div>                                
                        </AccordionDetails>
                    </Accordion>
                </DialogContent>
                <DialogActions>
                    <UButton variant='contained' size='medium' color='primary'
                             onClick={()=>{ this.props.gotoRegisterAccount() }}
                    >
                        {t('我同意隐私政策和会员条款')}
                    </UButton>
                    <UButton variant='contained' size='medium' color='secondary'
                             onClick={()=>{ this.setState({termsAndPrivacy: undefined })}}
                    >
                        {t('取消注册')}
                    </UButton>
                </DialogActions>
            </Dialog>
        )
    }
    


    componentDidMount(){
        // loadExternContatnsIfNecessary()
        // .then(()=>{
            
        // })
        this.setState({pageInitState:'PAGE_LOAD_SUCCESS'});
    }
}

