// Generated by IcedCoffeeScript 108.0.11
(function () {
  var AES,
      C,
      Packet,
      SHA1,
      SHA256,
      UserAttribute,
      bufferify,
      encrypt,
      native_rng,
      parse,
      triplesec,
      uint_to_buffer,
      _ref,
      _ref1,
      __hasProp = {}.hasOwnProperty,
      __extends = function __extends(child, parent) {
    for (var key in parent) {
      if (__hasProp.call(parent, key)) child[key] = parent[key];
    }

    function ctor() {
      this.constructor = child;
    }

    ctor.prototype = parent.prototype;
    child.prototype = new ctor();
    child.__super__ = parent.prototype;
    return child;
  };

  C = require('../../const').openpgp;
  triplesec = require('triplesec');
  _ref = triplesec.hash, SHA1 = _ref.SHA1, SHA256 = _ref.SHA256;
  AES = triplesec.ciphers.AES;
  native_rng = triplesec.prng.native_rng;
  _ref1 = require('../../util'), bufferify = _ref1.bufferify, uint_to_buffer = _ref1.uint_to_buffer;
  encrypt = require('../cfb').encrypt;
  Packet = require('./base').Packet;
  parse = require('pgp-utils').userid.parse;

  UserAttribute = function (_super) {
    __extends(UserAttribute, _super);

    function UserAttribute(data) {
      this.data = data;

      UserAttribute.__super__.constructor.call(this);
    }

    UserAttribute.prototype.write = function () {
      return this.frame_packet(C.packet_tags.user_attribute, this.data);
    };

    UserAttribute.parse = function (slice) {
      return new UserAttribute(slice.consume_rest_to_buffer());
    };

    UserAttribute.prototype.to_user_attribute = function () {
      return this;
    };

    UserAttribute.prototype.to_signature_payload = function () {
      return Buffer.concat([Buffer.from([C.signatures.user_attribute]), uint_to_buffer(32, this.data.length), this.data]);
    };

    return UserAttribute;
  }(Packet);

  exports.UserAttribute = UserAttribute;
}).call(this);