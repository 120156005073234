// Generated by IcedCoffeeScript 108.0.11
(function () {
  var ASP,
      BaseKey,
      BaseKeyPair,
      C,
      K,
      MRF,
      Pair,
      Priv,
      Pub,
      SRF,
      bn,
      bufeq_secure,
      eme_pkcs1_decode,
      eme_pkcs1_encode,
      konst,
      make_esc,
      _ref,
      _ref1,
      _ref2,
      _ref3,
      __hasProp = {}.hasOwnProperty,
      __extends = function __extends(child, parent) {
    for (var key in parent) {
      if (__hasProp.call(parent, key)) child[key] = parent[key];
    }

    function ctor() {
      this.constructor = child;
    }

    ctor.prototype = parent.prototype;
    child.prototype = new ctor();
    child.__super__ = parent.prototype;
    return child;
  };

  bn = require('./bn');
  _ref = require('./util'), bufeq_secure = _ref.bufeq_secure, ASP = _ref.ASP;
  make_esc = require('iced-error').make_esc;
  konst = require('./const');
  C = konst.openpgp;
  K = konst.kb;
  _ref1 = require('./basekeypair'), BaseKeyPair = _ref1.BaseKeyPair, BaseKey = _ref1.BaseKey;
  _ref2 = require('./rand'), SRF = _ref2.SRF, MRF = _ref2.MRF;
  _ref3 = require('./pad'), eme_pkcs1_encode = _ref3.eme_pkcs1_encode, eme_pkcs1_decode = _ref3.eme_pkcs1_decode;

  Pub = function (_super) {
    __extends(Pub, _super);

    Pub.type = C.public_key_algorithms.ELGAMAL_SIGN_AND_ENCRYPT;
    Pub.prototype.type = Pub.type;
    Pub.ORDER = [];
    Pub.prototype.ORDER = Pub.ORDER;

    function Pub(raw) {
      this.raw = raw;
    }

    Pub.alloc = function (raw) {
      return BaseKey.alloc(Pub, raw);
    };

    Pub.prototype.encrypt = function (m, cb) {
      return cb(null);
    };

    return Pub;
  }(BaseKey);

  Priv = function (_super) {
    __extends(Priv, _super);

    Priv.ORDER = [];
    Priv.prototype.ORDER = Priv.ORDER;

    function Priv(raw) {
      this.raw = raw;
    }

    Priv.prototype.serialize = function () {
      return null;
    };

    Priv.alloc = function (raw, pub) {
      return BaseKey.alloc(Priv, raw, {
        pub: pub
      });
    };

    Priv.prototype.decrypt = function (c, cb) {
      return cb(null);
    };

    return Priv;
  }(BaseKey);

  Pair = function (_super) {
    __extends(Pair, _super);

    Pair.Pub = Pub;
    Pair.prototype.Pub = Pub;
    Pair.Priv = Priv;
    Pair.prototype.Priv = Priv;
    Pair.type = C.public_key_algorithms.ELGAMAL_SIGN_AND_ENCRYPT;
    Pair.prototype.type = Pair.type;

    Pair.prototype.fulfills_flags = function (flags) {
      return false;
    };

    Pair.prototype.is_toxic = function () {
      return true;
    };

    function Pair(_arg) {
      var priv, pub;
      pub = _arg.pub, priv = _arg.priv;

      Pair.__super__.constructor.call(this, {
        pub: pub,
        priv: priv
      });
    }

    Pair.prototype.can_sign = function () {
      return false;
    };

    Pair.prototype.can_decrypt = function () {
      return false;
    };

    Pair.prototype.err = function () {
      return new Error("refusing to use ElGamal Sign+Encrypt");
    };

    Pair.parse = function (pub_raw) {
      var ret;
      ret = BaseKeyPair.parse(Pair, pub_raw);
      return ret;
    };

    Pair.prototype.pad_and_encrypt = function (data, cb) {
      return cb(this.err(), null);
    };

    Pair.prototype.decrypt_and_unpad = function (ciphertext, params, cb) {
      return cb(this.err(), null);
    };

    Pair.parse_output = function (buf) {
      return null;
    };

    Pair.prototype.export_output = function (args) {
      return null;
    };

    return Pair;
  }(BaseKeyPair);

  exports.ElGamalSignEncrypt = exports.Pair = Pair;
}).call(this);