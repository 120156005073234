// Generated by IcedCoffeeScript 108.0.11
(function () {
  var K, Packet, seal, util;
  util = require('../../util');
  seal = require('../encode').seal;
  K = require('../../const').kb;

  Packet = function () {
    function Packet() {}

    Packet.prototype.frame_packet = function () {
      return {
        tag: this.tag(),
        body: this.get_packet_body()
      };
    };

    Packet.prototype.frame_packet_armored = function (_arg) {
      var dohash, obj;
      dohash = _arg.dohash;
      obj = this.frame_packet();
      return seal({
        obj: obj,
        dohash: dohash
      }).toString('base64');
    };

    Packet.prototype.is_signature = function () {
      return false;
    };

    Packet.prototype.is_p3skb = function () {
      return false;
    };

    Packet.alloc = function (_arg) {
      var P3SKB, Signature, body, err, ret, tag;
      tag = _arg.tag, body = _arg.body;
      ret = err = null;

      ret = function () {
        switch (tag) {
          case K.packet_tags.p3skb:
            P3SKB = require('./p3skb').P3SKB;
            return P3SKB.alloc({
              tag: tag,
              body: body
            });

          case K.packet_tags.signature:
            Signature = require('./signature').Signature;
            return Signature.alloc({
              tag: tag,
              body: body
            });

          default:
            err = new Error("unknown packet tag: " + tag);
            return null;
        }
      }();

      return [err, ret];
    };

    Packet.prototype.unbox = function (params, cb) {
      return cb(new Error("unbox() unimplemented for tag=" + this.tag));
    };

    return Packet;
  }();

  exports.Packet = Packet;
}).call(this);