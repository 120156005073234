import * as React from 'react';
import {Component, Fragment} from 'react';
import { ProductCategory } from '../ajax-data/ajax-data-types';
import * as _ from 'lodash';
import { ArrowDropDown, ArrowDropUp, ChevronRight } from '@material-ui/icons';

import './category-tree.scss';
import classnames from 'classnames';

interface TreeNode extends ProductCategory{
    expended?: boolean;
}

interface MyProps {
    wholeCategory: ProductCategory[];
    currentPath: ProductCategory[];
    selectCategory: (categroy:ProductCategory)=>void;
}

interface MyState {
    wholeTree: TreeNode[];
}

export class CategoryTree extends Component<MyProps, MyState> {
    constructor(props: MyProps){
        super(props);

        let wholeTree = _.cloneDeep(this.props.wholeCategory) as TreeNode[];
        this.props.currentPath.forEach(element => {
            wholeTree.every(node=>{
                if(node.name === element.name){
                    node.expended = true;
                    return false;
                }
            })
        });

        this.state = {wholeTree}
    }

    render(){
        return (
            <div className='product-category-tree'>
            {this.state.wholeTree.map(t=>{
                return this.renderNode(t, 0);
            })}
            </div>
        )
    }

    toggleExpanded(n: TreeNode){
        n.expended = !n.expended;
        this.forceUpdate();
    }

    renderNode(node:TreeNode, level:number):JSX.Element {
        let styles:React.CSSProperties = {}
        styles.marginLeft = (level*20)+'px';

        let inPath = this.props.currentPath.some(n=>n.name === node.name);

        return (
            <Fragment>
                <div className={classnames('product-category-tree-node',
                                           {dir:node.children},
                                           {"in-path": inPath}  )} 
                     style={styles}
                >
                    <div className='label'
                         onClick={()=>{ this.props.selectCategory(node) } }
                    >{node.label}</div>
                    {node.children && node.expended? 
                     <ArrowDropUp onClick={()=>{ this.toggleExpanded(node) }}/>: null}
                    {node.children && !node.expended? 
                     <ArrowDropDown onClick={()=>{ this.toggleExpanded(node) }}/>: null}
                </div>
                {node.children && node.expended?
                 node.children.map(n=>{
                     return this.renderNode(n, level+1)
                 })
                 :null
                }
            </Fragment>
        )
    }
}
