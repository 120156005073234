import classNames from 'classnames';
import * as React from 'react';
import {Component, Fragment, MouseEvent} from 'react';
import './ubutton.scss';


interface MyProps {
    id?: string;
    className? : string;
    variant?: 'contained'|'outlined';
    color?: 'primary'|'secondary'|'default'|'alert'|'danger';
    size?: 'small'|'medium';
    disabled?: boolean;
    onClick?: (evt: MouseEvent)=>void;
}

export class UButton extends Component<MyProps, {disabled:boolean}>{
    constructor(props: MyProps){
        super(props);
        this.state = {disabled: false}
    }

    handleClick = (evt:MouseEvent<HTMLButtonElement>)=>{
        const {onClick} = this.props;
        if(!onClick){
            return; // do nothing without onClick callback
        }

        if(this.state.disabled){
            return; // prevent double click
        }

        this.setState({disabled: true});
        setTimeout(()=>{
            this.setState({disabled: false}, ()=>{
                onClick(evt);
            });
        }, 500);
    }

    render(){
        const {variant, color, size} = this.props;
        const disabled = this.props.disabled || this.state.disabled;

        return (
            <button className={classNames(  'ubutton', 
                                            this.props.className, 
                                            variant||'outlined', 
                                            color||'default', 
                                            size||'small', 
                                            {disabled})}
                    id={this.props.id}
                    onClick={this.handleClick}
                    disabled={disabled}
            >
            {this.props.children}
            </button>
        )
    }
}

