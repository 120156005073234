import * as React from 'react';
import {Component, Fragment} from 'react';
import { t } from '../../i18n';
import { ProductCategory, ProductCandidateBrief } from '../ajax-data/ajax-data-types';
import { findProductCategory} from '../common/shopping-page-common';
import { NoticeDialog } from '../../view/NoticeDialog';

import './search-product.scss';
import { getCurrencyOptionsMap, getProductCategories, searchShoppingProducts } from '../ajax-data/shopping-server-api';
import { gotoShoppingPathNSavePrevPath, gotoShoppingPathAndSavingGivenPath, ShoppingProductSearchProps, updateCurrentPathParms } from '../history/shopping-history';
import { PagedResponse } from '../../api/transaction';
import { Grid } from '@material-ui/core';
import { Pagination } from '../common/render-pagination';
import { calcRationClass, formatPrice, getCurrencyOption, getMaxThumbnailImgSize, RatioLG, RatioMD, RatioSM, RatioXXS, RatioXXXS, scrollBodyToTop } from '../../util/Util';
import { connect } from 'react-redux';
import { WalletAppState } from '../../redux-store';
import { doAJAXCall } from '../common/ajax-call';
import { loadExternContatnsIfNecessary } from '../../external-constants/extern-constants';
import { BaseUnishellPageState, renderInitErrorUnishellPage, renderInitUnishellPage, renderUnishellPage } from '../../unishell-page-common/unishell-page-common';
import { SimpleShopSearchForm } from '../common/simple-shop-search-form';
import { ShoppingProductBrief } from '../common/product-brief';



interface ProductSearchState extends BaseUnishellPageState{
    productCategories?:ProductCategory[];
    currentCategory?: ProductCategory;
    page:number;
    pageSize: number;

    searchResult?:PagedResponse<ProductCandidateBrief>;
}

class ShoppingSearchProductView extends Component<ShoppingProductSearchProps,ProductSearchState>{
    private errorDialog:NoticeDialog|undefined;
    private inputForm:SimpleShopSearchForm | undefined;

    constructor(props:ShoppingProductSearchProps){
        super(props);
        this.state = {pageInitState:'PAGE_INIT', page:this.props.page||0, pageSize:this.props.pageSize||10}
    }

    getTitle(){
        return this.state.currentCategory? this.state.currentCategory.label : t('商城');
    }

    render(){
        if(this.state.pageInitState === 'PAGE_INIT'){
            return <Fragment>
                        {renderInitUnishellPage({title: this.getTitle(), message:t('初始化...')})}
                        <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                    </Fragment>
        }
        if(this.state.pageInitState === 'PAGE_LOAD_FAILED'){
            return <Fragment>
                        <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                        {renderInitErrorUnishellPage({title: this.getTitle(), 
                                                      errorMessage: this.state.initErrorMessage||t('初始化失败'),
                                                      onTryAgain: ()=>{ this.initializeAgain();    }
                                                      })}
                    </Fragment>
        }

        return (
            <React.Fragment>
                <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                { renderUnishellPage({
                    title: this.getTitle(), 
                    className:'shopping-search-products', 
                    content: this.renderSucceedContent(),
                    underTopHeader: this.renderSearchWrapper(),
                })}                
            </React.Fragment>
        )
    }

    getSearchText() {
        return this.inputForm? this.inputForm!.getSearchText() : this.props.initialSearchText || '';
    }

    updatePathParms(){
        let parms:ShoppingProductSearchProps = {
            initialSearchText: this.getSearchText(),
            categoryName: this.props.categoryName,
            page: this.state.page,
            pageSize: this.state.pageSize,
       };
        updateCurrentPathParms('main/shopping/search', parms)        
    }

    renderSearchWrapper(){
        // render search form under top header bar so that it skick to the top too when scroll products
        return (
            <div className='shopping-product-search-wrapper'>
                <SimpleShopSearchForm 
                    initialSearchText={this.props.initialSearchText} 
                    categoryName={this.props.categoryName}
                    productCategories={this.state.productCategories!}
                    initPageSize={this.state.pageSize}
                    doSearch={(text)=>{
                        this.loadData(text, this.state.page, this.state.pageSize);
                    }}
                    selectCategory={(text, category)=>{
                        gotoShoppingPathNSavePrevPath('main/shopping/search', 
                                        {
                                            initialSearchText: text,
                                            categoryName: category.name
                                        });
                    }}
                    changePageSize={(newPageSize)=>{
                        let newPage = Math.floor(this.state.page *this.state.pageSize / newPageSize);
                        this.loadPaginationData(newPage, newPageSize);                                            
                    }}
                    ref={e=> { this.inputForm=e! } }
                />
            </div>)
    }
    renderSucceedContent():JSX.Element {
        return (
        <div className='shopping-search-products-main-pane'>           
            <Pagination 
                data={this.state.searchResult!} 
                page={this.state.page} 
                pageSize={this.state.pageSize} 
                changePageSize={(newPageSize, newPage)=>{
                    this.loadPaginationData(newPage, newPageSize);                                            
                }}
                loadData={this.loadPaginationData}
            >
            { this.renderProductBriefList(this.state.searchResult!.content) }
            </Pagination>
        </div>
        )
    }

    renderProductBriefList = (list:ProductCandidateBrief[]):JSX.Element => {
        const {maxImgWidth, maxImgHeight} = getMaxThumbnailImgSize();
        const {ratioClass, vw,  mw, ratio} = calcRationClass();
        let xs = 12;

        if(ratio >= RatioXXXS*2){
            xs = 6;
        }
        if(ratio >= RatioXXXS*3){
            xs = 4;
        }
        if(ratio >= RatioXXXS*4){
            xs = 3;
        }
        if(ratio >= RatioXXXS*6){
            xs = 2;
        } 
        if(ratio >= RatioXXXS*12){
            xs = 1
        }

        const currencyOptionsMap = getCurrencyOptionsMap();

        // set Grid spacing=0 to avoid overflow-x which causing horizontal bar to appear!
        return (
            <Grid container spacing={0}>
            {
                list.map((prod, idx)=>{
                    const currencyOption = getCurrencyOption(currencyOptionsMap, 
                                                                this.props.languageCode!, 
                                                                prod.currencyCode);

                    return  <Grid item xs={xs as 6} key={idx+'-'+prod.productName}>
                                <ShoppingProductBrief
                                    prod={prod}
                                    currencyOption={currencyOption}
                                    onClickImage={()=>{
                                        gotoShoppingPathNSavePrevPath('main/shopping/product', {productCode:prod.productCode});
                                    }}
                                />
                            </Grid>
                })
            }
            </Grid>
        )
    }

    loadPaginationData = (page:number, pageSize:number) => {
        this.loadData(this.getSearchText(), page, pageSize);
    }

    doSearch = ()=>{
        this.loadData(this.getSearchText(), this.state.page, this.state.pageSize);
    }

    componentDidMount(){
        this.loadData(this.getSearchText(), this.state.page, this.state.pageSize);
    }
    private async loadData(searchText:string, page:number, pageSize:number){
        const {maxImgWidth} = getMaxThumbnailImgSize();

        doAJAXCall({
            whatIsFor: t('初始化'),
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken)=>{
                let category:ProductCategory|undefined;
                let searchResult:PagedResponse<ProductCandidateBrief>|undefined;

                await loadExternContatnsIfNecessary();

                let productCategories = await getProductCategories(accessToken, this.props.languageCode!);
                if(this.props.categoryName){
                    category = findProductCategory(this.props.categoryName, productCategories); 
                }
                searchResult = await searchShoppingProducts(accessToken, page, pageSize, maxImgWidth,
                                              {
                                                  searchText,
                                                  categoryName: this.props.categoryName
                                              },
                                              this.props.currencyCode!);
              
                this.setState({pageInitState:'PAGE_LOAD_SUCCESS', productCategories, currentCategory: category, searchResult, 
                              page:searchResult.page, pageSize:searchResult.size})
    
            },
            onError: (errorMsg)=>{
                this.setState({pageInitState:'PAGE_LOAD_FAILED', initErrorMessage:errorMsg});
            }
        })
    }

    initializeAgain() {
        this.setState({pageInitState:'PAGE_INIT', initErrorMessage:undefined},
        ()=>{
            this.loadData(this.getSearchText(), this.state.page, this.state.pageSize);
        })
    }

    componentDidUpdate(prevProps:any, prevState:ProductSearchState){
        if(prevState.page !== this.state.page){
            scrollBodyToTop();
        }
        this.updatePathParms();
    }
} 

export const ShoppingSearchProduct = connect(
   (state:WalletAppState)=>({languageCode: state.language.langCode, currencyCode:state.currency.currencyCode})
)(ShoppingSearchProductView);