import { TextField } from '@material-ui/core';
import React, {Component} from 'react';
import { CountryOption, NutritionistClient, BillingAddress, ShoppingAddressSpec } from '../ajax-data/ajax-data-types';
import './nutritionist-client-form.scss';
import * as _ from 'lodash';
import { t } from '../../i18n';
import { ShoppingAddressForm } from '../common/address-form';
import { Toast } from '../../view/Toast';

interface FormProps {
    languageCode: string;
    countryOptons:CountryOption[];
    addressSpecMap:{[key:string]:ShoppingAddressSpec};

    inputClient: NutritionistClient;
}

interface FormState {
    client: NutritionistClient;
    errors: {[key:string]: string};
}



export class NutritionistClientForm extends Component<FormProps, FormState>{
    private addressForm: ShoppingAddressForm | undefined;
    private toast: Toast | undefined;


    constructor(props: FormProps){
        super(props);
        this.state = {client: _.cloneDeep(this.props.inputClient), errors: {}}
    }

    updateName(s:string){
        this.state.client.client = s;
        delete this.state.errors.client;
        this.forceUpdate();
    }
    updateEmail(s:string){
        this.state.client.email = s;
        delete this.state.errors.email;
        this.forceUpdate();
    }
    updatePhone(s:string){
        this.state.client.phoneNumber = s;
        delete this.state.errors.phoneNumber;
        this.forceUpdate();
    }

    updateNotes(s:string){
        this.state.client.notes = s;
        this.forceUpdate();
    }

    validateAndGetClient(){
        let {addr, hasError} = this.addressForm!.validateAndGetAddress();

        let c = _.cloneDeep(this.state.client);
        let errors:{[key:string]:string} = {};

        c.client = (c.client || '').trim();
        c.email = (c.email || '').trim();
        c.phoneNumber = (c.phoneNumber || '').trim();
        c.notes = (c.notes || '').trim();

        if(!c.client){
            errors.client = t(`请输入姓名`);
        }
        if(!c.email){
            errors.email = t(`请输入电邮`);
        }
        if(!c.phoneNumber){
            errors.phoneNumber = t(`请输入电话`);
        }

        if(Object.keys(errors).length > 0 || hasError || !addr){
            this.setState({client: c, errors}, ()=>{
                this.toast!.sendMessage(t(`请修正表格中的错误`), 'warning');
            });
            return undefined;
        }else{
            c.address = addr;
            return c;
        }
    }

    render(){
        const c = this.state.client;
        const err = this.state.errors;

        return (
            <div className='nutritionist-client-form'>
                <TextField label={t('姓名')+'*'} variant='outlined' size='medium' value={c.client}
                           disabled={true}
                           onChange={evt => { this.updateName(evt.target.value)} }/>
                <div className='error-msg'>{err.client || ' '}</div>

                <TextField label={t('电邮')+'*'} variant='outlined' size='medium' value={c.email}
                           onChange={evt => { this.updateEmail(evt.target.value)} }
                />
                <div className='error-msg'>{err.email || ' '}</div>

                <TextField label={t('电话')+'*'} variant='outlined' size='medium' value={c.phoneNumber}
                           onChange={evt => { this.updatePhone(evt.target.value)} }
                />
                <div className='error-msg'>{err.phoneNumber || ' '}</div>

                <TextField label={t('备注')} variant='outlined' size='medium' multiline maxRows={3}
                           value={c.notes}
                           onChange={evt => { this.updateNotes(evt.target.value)} }
                />
                <p>{t('地址')}</p>
                <ShoppingAddressForm addressType='AddressInfo' 
                                     initialAddress={this.state.client!.address as BillingAddress}
                                     countryOptons={this.props.countryOptons}
                                     addressSpecMap={this.props.addressSpecMap}
                                     readonly={false}
                                     ref={e => this.addressForm = e!}
                />
                <Toast close={true} message='' toastType='info' onClose={()=>{}} ref={(e)=>{this.toast=e!}} duration={2500}/>
            </div>
        )
    }
}
