import * as React from 'react';
import {Component, Fragment} from 'react';

import './SearchProductsView.scss';
import {t} from '../../i18n';
import { Doc, DocFieldSpec, DocSearchResponse, DocSpecResponse, FileDoc, ProductBrief } from '../../api/docs';
import { ReactReduxContext } from 'react-redux';
import { WalletAppState } from '../../redux-store';
import { Checkbox, FormControl, FormControlLabel, Grid, Icon, IconButton, InputBase, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, Table, TableBody, TableCell, TableRow, TextField } from '@material-ui/core';
import { NoticeDialog } from '../NoticeDialog';
import { readLoginSession } from '../../local-storage/local-storage';
import { getDocSpecs, getDoc, searchProductAPI, getShuyu, 
         bookmarkProduct, removeBookmark, getBookmarkedProducts } from '../../wallet-server-api/wallet-server-api';
import { ImageWithPlaceholder } from '../image-with-placeholder/ImageWithPlaceHolder';
import { PagedResponse } from '../../api/transaction';
import SearchIcon from '@material-ui/icons/Search';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { useDoubleTap } from 'use-double-tap';
import Highlighter from "react-highlight-words";
import {ReactComponent as BookmarkIcon} from '../../images/bookmark.svg';
import * as _ from 'lodash';
import { BaseUnishellPageState, renderInitErrorUnishellPage, renderInitUnishellPage, renderUnishellPage } from '../../unishell-page-common/unishell-page-common';
import { gotoLoginPage } from '../../shopping/history/shopping-history';
import { doAJAXCall } from '../../shopping/common/ajax-call';
import { loadExternContatnsIfNecessary } from '../../external-constants/extern-constants';
import { info } from '../../globals';
import { MultilineField } from './multiline-field';
import { Pagination } from '../../shopping/common/render-pagination';
import { UButton } from '../../widget/button/ubutton';
import { calcRationClass, RatioXS, scrollBodyToTop } from '../../util/Util';

declare global {
    interface Window {
    }
}

class ProdFieldBlock extends Component<{}, {}> {
    render () {
        if(this.props.children){
            return <Paper className='product-field-paper'>
                {this.props.children}
            </Paper>
        }else{
            return null;
        }
    }
}

// main search product view
interface SearchProductsViewProps {
    dispatch: (x:any)=>void;
    /** initial product to show */
    currentProd?: Doc;
}

interface SearchProductsViewState extends BaseUnishellPageState{
    docSpecs?: DocSpecResponse;

    symptomKeywords:string;
    professionalChoice: any;
    functionalChoice: any;
    // manufacturerChoice: any;
    // priceLimit?: string;
    productName?:string;
    ingredient?:string;
    fullTextKeywords?: string;
    fuzziness:boolean;
    expandSearchForm: boolean;

    data?: PagedResponse<DocSearchResponse>;
    page: number;
    pageSize: number;
    
    /** production being viewed by user */
    currentProd?: Doc;
    /** current prod bookmark flag before rendering product detail */
    isCurrentProdBookmarked?:boolean;
}

type LoadProductSummaryFn = (dispatch:(x:any)=>void, page:number, pageSize: number)=>void;

export class SearchProductsView extends Component<SearchProductsViewProps, SearchProductsViewState>{
    private errorDialog: NoticeDialog|undefined;
    private termDialog: NoticeDialog|undefined;

    private touchStartTime: number = 0;
    private touchStartX: number = 0;
    private touchStartY: number = 0;

    /** scroll position before switching to current production view or bookmark view */
    private scrollY: number|undefined = undefined;

    constructor(props: SearchProductsViewProps){
        super(props);
        this.state ={
                        pageInitState: 'PAGE_INIT', 
                        page: 0, pageSize:10, 
                        symptomKeywords:'', 
                        expandSearchForm: false,
                        professionalChoice:'', 
                        functionalChoice: '', 
                        fuzziness:true,
                        currentProd: this.props.currentProd
                    }
    }

    searchProductBackFn(){
        let isCurrentProdBookmarkedPrev = this.state.isCurrentProdBookmarked;
        let isCurrentProdBookmarkedAfter = !!this.state.currentProd!.bookmarkId;
        this.setState({currentProd: undefined},()=>{
        });
    }

    render() {
        if(this.state.pageInitState === 'PAGE_INIT'){
            return (
                <Fragment>
                { renderInitUnishellPage({
                    title: t('检索产品'),
                    className: 'search-products-page',
                })}
                <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                <NoticeDialog open={false} ref={e=>this.termDialog=e!}/>
                </Fragment>
            )
        }
        if(this.state.pageInitState === 'PAGE_LOAD_FAILED'){
            return (
                <Fragment>
                { renderInitErrorUnishellPage({
                    title: t('检索产品'),
                    className: 'search-products-page',
                      errorMessage: this.state.initErrorMessage!,
                      onTryAgain: ()=>{
                          this.init();
                      }                    
                })}
                <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                <NoticeDialog open={false} ref={e=>this.termDialog=e!}/>
                </Fragment>
            )
        }

        return <ReactReduxContext.Consumer>
        { ({store})=>{
                const state:WalletAppState = store.getState();
                const myPath = state.pagePath.currentPage;

                if(this.state.currentProd){ // render current product in detail
                    return renderUnishellPage({
                        title: t('检索产品'),
                        className: 'search-products-page',
                        content:(
                            <div className='search-products-page-content'>
                                {this.renderCurrentProduct()}
                                <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                                <NoticeDialog open={false} ref={e=>this.termDialog=e!}/>
                            </div>
                        )
                    })    
                }

                const content =(
                     <div className='search-products-page-content'>
                            {this.renderSearchForm()}
                            {this.state.data?
                                <Pagination data={this.state.data} page={this.state.page} pageSize={this.state.pageSize}
                                            loadData={this.searchProducts}
                                            changePageSize={(newPageSize, newPage)=>{
                                                this.searchProducts(newPage, newPageSize);
                                            }}
                                >
                                {this.renderProdSummaries(this.state.data)}                                                    
                                </Pagination> : null                           
                             }
                            <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                            <NoticeDialog open={false} ref={e=>this.termDialog=e!}/>
                        </div>
                )
                return renderUnishellPage({
                    title: t('检索产品'),
                    className: 'search-products-page',
                    content
                })        

        }}
        </ReactReduxContext.Consumer>
    }

    componentDidUpdate(prevProps:any, prevState:SearchProductsViewState){
        if(prevState.page !== this.state.page){
            scrollBodyToTop();
        }

        if(!this.state.currentProd){
            if(this.scrollY !== undefined){
                // restore scroll position after return from product or bookmark view
                window.scrollTo(0,this.scrollY||0);
                // back to normal product list view
                this.scrollY = undefined;
            }    
        }
    }

    renderSearchForm():JSX.Element {
        let extraSearchFilter:JSX.Element|null=null;
        if(this.state.expandSearchForm){
            const zhuanyefenleiSpec = this.state.docSpecs!.content.find(x=>x.name==='chanpin')!.fieldSpecs['field_zhuanyefenlei'];
            let professionalChoice = this.state.expandSearchForm && zhuanyefenleiSpec? 
                                    <FormControl className='extra-search-form-control'>
                                        <InputLabel htmlFor="select-zhuanyefenlei">{t('专业分类')}</InputLabel>
                                        {/* use native select on mobile */}
                                        <Select value={this.state.professionalChoice||''} 
                                                onChange={(evt)=>{ this.setState({professionalChoice: evt.target.value})}} 
                                                inputProps={{name: 'select-zhuanyefenlei', id: 'select-zhuanyefenlei'}}>
                                        {(()=>{
                                            let options = Object.keys(zhuanyefenleiSpec.valueMappings!).map(key=>{
                                                                return <MenuItem key={key} value={key}>{zhuanyefenleiSpec.valueMappings![key]}</MenuItem>
                                                            });
                                            options.unshift(<MenuItem key='' value="" >{t('任何分类')}</MenuItem>);
                                            return options;
                                        })()}            
                                        </Select>
                                    </FormControl>: 
                                    null;
    
                const gongnengfenleiSpec = this.state.docSpecs!.content.find(x=>x.name==='chanpin')!.fieldSpecs['field_gongnengfenlei'];
                let functionalChoice = <FormControl className='extra-search-form-control'>
                                            <InputLabel htmlFor="select-gongnengfenlei">{t('功能分类')}</InputLabel>
                                            <Select value={this.state.functionalChoice||''} 
                                                    onChange={(evt)=>{ this.setState({functionalChoice: evt.target.value})}} 
                                                    inputProps={{name: 'select-gongnengfenlei', id: 'select-gongnengfenlei'}}>
                                            {(()=>{
                                                let options = Object.keys(gongnengfenleiSpec.valueMappings!).map(key=>{
                                                                    return <MenuItem key={key} value={key}>{gongnengfenleiSpec.valueMappings![key]}</MenuItem>
                                                                });
                                                options.unshift(<MenuItem aria-label="None" key="" value="" >{t('任何功能')}</MenuItem>);
                                                return options;
                                            })()}                                                        
                                            </Select>
                                        </FormControl>;
    
                let productNameSearch = <TextField label={t('产品名称关键词')} className='extra-search-form-control'
                                                   value={this.state.productName}
                                                   onChange={(evt)=>{ this.setState({productName: evt.target.value}) }}
                                                   onKeyPress={(evt)=>{
                                                    if(evt.key === 'Enter'){
                                                        // new search, page index starts from 0
                                                        this.searchProducts(0, this.state.pageSize);
                                                    }
                                                }}
                                                placeholder={t('关键词1 关键词2 ...')}
                                        />
                let ingredientSearch = <TextField label={t('原料名称关键词')} className='extra-search-form-control'
                                        value={this.state.ingredient}
                                        onChange={(evt)=>{ this.setState({ingredient: evt.target.value}) }}
                                        onKeyPress={(evt)=>{
                                         if(evt.key === 'Enter'){
                                             // new search, page index starts from 0
                                             this.searchProducts(0, this.state.pageSize);
                                         }
                                     }}
                                     placeholder={t('关键词1 关键词2 ...')}
                             />
                let fullTextSearch = <TextField label={t('全文搜索')} className='extra-search-form-control fulltext-search'
                                                value={this.state.fullTextKeywords}
                                                onChange={(evt)=>{ this.setState({fullTextKeywords: evt.target.value})}} 
                                                onKeyPress={(evt)=>{
                                                    if(evt.key === 'Enter'){
                                                        // new search, page index starts from 0
                                                        this.searchProducts(0, this.state.pageSize);
                                                    }
                                                }}
                                                placeholder={t('关键词1 关键词2 ...')}></TextField>
                let fuzziness = <RadioGroup>
                                    <FormControlLabel 
                                        control={<Checkbox checked={!this.state.fuzziness} color='primary'
                                                           onChange={(e)=>{ this.setState({fuzziness: !e.target.checked}) }} />} 
                                        label={t('更精确匹配关键词')}
                                    />
                                </RadioGroup>;
            const {ratioClass, vw,  mw, ratio} = calcRationClass();
            let xs1 = ratio >= RatioXS ? 6 : 12;
            let xs2 = ratio >= RatioXS*2? 6 : 12;

            extraSearchFilter = (
                <Paper className='extra-search-form'>
                    <Grid container spacing={2}>
                        <Grid item xs={xs1 as 6}>{professionalChoice}</Grid>
                        <Grid item xs={xs1 as 6}>{functionalChoice}</Grid>
                        <Grid item xs={xs2 as 6}>{productNameSearch}</Grid>
                        <Grid item xs={xs2 as 6}>{ingredientSearch}</Grid>
                        <Grid item xs={12}>{fullTextSearch}</Grid>
                        <Grid item xs={12}>{fuzziness}</Grid>
                    </Grid>
                </Paper>
            )
        }
        return (
            <Fragment>
                <Paper className='search-form'>
                    <InputBase  className='search-input' placeholder={t('应用范围关键词1 关键词2 ...')} 
                                value={this.state.symptomKeywords}
                                onChange={(evt)=>{ this.setState({symptomKeywords: evt.target.value})}}

                                onKeyPress={(evt)=>{
                                    if(evt.key === 'Enter'){
                                        // new search, page index starts from 0
                                        this.searchProducts(0, this.state.pageSize);
                                    }
                                }}/>
                    <IconButton type='submit' onClick={()=>{
                                                    // new search, page index starts from 0
                                                    this.searchProducts(0, this.state.pageSize);
                                                }}>
                        <SearchIcon ></SearchIcon>
                    </IconButton>
                    <IconButton type='submit' onClick={()=>{
                                                this.setState({expandSearchForm: !this.state.expandSearchForm})
                                            }}>
                    {this.state.expandSearchForm? <ExpandLessIcon/>:<ExpandMoreIcon/>}
                    </IconButton>
                </Paper>
                {extraSearchFilter}
            </Fragment>    
        )
    }

    shortenString(s:string, maxLength:number){
        if(!s) return s;
        if(s.length<=maxLength){
            return s;
        }
        return s.substr(0, maxLength)+' ...';
    }

    renderProdSummaries(data: PagedResponse<DocSearchResponse>):JSX.Element {
        // this table is responsive
        let prodTable = <div className='product-summary-table'>
            {
                data.content.map(p=> {
                    const clickItem = ()=>{
                        this.loadProduct(this.props.dispatch, p.id);
                    }
                    let brief:ProductBrief = JSON.parse(p.brief);

                    return  <div className='product-summary-row'>
                                <div className='product-thumbnail-td' onClick={clickItem}>
                                    <ImageWithPlaceholder imgSrc={brief.fields.field_tupian?(brief.fields.field_tupian[0].url+'&width=128'):undefined} 
                                                            imgClassName='product-thumbnail'                                                            
                                                      />                                                 
                                </div>
                                <div className='product-overview'>
                                    <div className='product-brief-name' onClick={clickItem}>
                                        {brief.fields.field_zhongwenming||''} / {brief.fields.field_yingwenming||''}
                                    </div>
                                    <div className='product-brief-paragraph'>
                                        <MultilineField highlightWords={this.getSymptomHighlightKeywords()} 
                                                        label={this.getContentFieldLabel('chanpin', 'field_yingyongfanwei')}
                                                        lines={this.shortenString(brief.fields.field_yingyongfanwei, 256)}
                                                        onClickTerm={this.displayShuyu}
                                                        />                              
                                    {brief.fields.field_chanpintedian && 
                                        <Fragment>
                                        <MultilineField highlightWords={this.getFullTextHighlightKeywords()} 
                                                        label={this.getContentFieldLabel('chanpin', 'field_chanpintedian')}
                                                        lines={this.shortenString(brief.fields.field_chanpintedian, 256)}
                                                        onClickTerm={this.displayShuyu}
                                                        />
                                        </Fragment>}
                                    </div>
                                </div>    
                            </div>
                })
            }
        </div>;

        return  <div className='product-list'>
                {prodTable}
                </div>
    }

    bookmark(docId:string){
        let whatIsFor:string = t('收藏产品');
        doAJAXCall({
            whatIsFor,
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken)=>{
                const text = await bookmarkProduct(docId, accessToken)
                let regexp = /([0-9]+)/;
                let m = regexp.exec(text)!;
                if(m && m.length>1){
                    let bookmarkId = parseFloat(m[1]);
                    const product = this.state.currentProd!;
                    product.bookmarkId = bookmarkId;
                    this.forceUpdate();
                }
            }
        })
    }

    unbookmark(bookmarkId:number, onSucc?:()=>void){
        let whatIsFor:string = t('取消收藏产品');
        doAJAXCall({
            whatIsFor,
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken)=>{
                await removeBookmark(bookmarkId, accessToken);
                if(onSucc){
                    onSucc();
                }
            }
        })
    }

    renderCurrentProduct(): JSX.Element {
        const product = this.state.currentProd!;
        let field_tupian = product.fields.field_tupian as FileDoc[];
        // todo: use carosoul if there are multiple images
        let images = field_tupian? 
                     <Fragment>
                    {field_tupian.map(x=>{
                        return <ImageWithPlaceholder imgSrc={x.url.startsWith('http')? x.url : 'http://docs.unishell.com'+x.url} imgClassName='product-image'/>
                    })} 
                     </Fragment>: null;
        let field_zhongwenming = product.fields['field_zhongwenming'];
        let title = (product.fields.field_yingwenming||'');

        let bookmark = product.bookmarkId? 
                       <UButton size='medium' variant='contained' color='primary'
                               onClick={()=>{
                                              this.unbookmark(product.bookmarkId!, 
                                                              ()=>{
                                                                product.bookmarkId = undefined;
                                                                this.forceUpdate();
                                                              })
                                            }
                                        }>{t('取消收藏')}</UButton>: 
                       <UButton size='medium' variant='contained' color='primary'
                                onClick={()=>{this.bookmark(product.id)}}>{t('收藏')}</UButton>

        const actionButtons =(
                <div className='action-buttons'>
                    <UButton size='medium' variant='contained' color='secondary'
                            onClick={ ()=>{ this.setState({currentProd: undefined}) }}
                    >{t('返回检索产品')}</UButton>
                    {bookmark}
                </div>)
   
        return (
            <div className='product'>
                <h3 className='product-title'>{title}</h3>
                {field_zhongwenming && <h3>{field_zhongwenming}</h3>}
                {actionButtons}

                {images}
                <ProdFieldBlock>
                    {this.renderMultilineField(product, 'field_yingyongfanwei', {keywords: this.state.symptomKeywords})}
                    {this.renderMultilineField(product, 'field_chanpintedian')}
                    {this.renderMultilineField(product, 'field_teshushanshishuoming')}
                    {this.renderMultilineField(product, 'field_xueshushengcheng')}
                    {this.renderMultilineField(product, 'field_xueshugongxiaozhushi')}
                </ProdFieldBlock>
                <ProdFieldBlock>
                    {this.renderMultilineField(product, 'field_jinji', {extraClasses: 'warn'}) }
                    {this.renderMultilineField(product, 'field_yizhibuliangfanying', {extraClasses: 'warn'}) }
                    {this.renderMultilineField(product, 'field_zhuanyejianyi', {extraClasses: 'warn'}) }
                    {this.renderMultilineField(product, 'field_zhuanyetishi', {extraClasses: 'warn'})}
                    {this.renderMultilineField(product, 'field_fuyongqixian', {extraClasses: 'warn'})}
                    {this.renderMultilineField(product, 'field_chucangtiaojian', {extraClasses: 'warn'})}
                </ProdFieldBlock>
                <ProdFieldBlock>
                    {this.renderMultilineField(product, 'field_chanpinbianhao')}
                    {this.renderMultilineField(product, 'field_shengchanshang')}
                    {this.renderMultilineField(product, 'field_yuanchande')}
                    {this.renderMultilineField(product, 'field_piwenhaoma')}
                    {this.renderMultilineField(product, 'field_pizhunjigou')}
                </ProdFieldBlock>
                <ProdFieldBlock>
                    {this.renderMultilineField(product, 'field_chanpinyangshi')}
                    {this.renderMultilineField(product, 'field_baozhuangyangshi')}
                    {this.renderMultilineField(product, 'field_jixingxiangqing', 
                                               {derivedValue: (product.fields.field_jixingxiangqing as string[]||[]).join('. ')  }) 
                    }
                    {this.renderMultilineField(product, 'field_zhongliang', {derivedValue: (product.fields.field_zhongliang as string)+product.fields.field_zhongliangdanwei})}
                    { product.fields.field_chanpinfenlei && 
                      this.renderMultilineField((product.fields.field_chanpinfenlei as Doc[])[0], 'field_zhuanyefenlei', {contentType: 'chanpinfenlei'})
                    }
                    { product.fields.field_chanpinfenlei && 
                      this.renderMultilineField((product.fields.field_chanpinfenlei as Doc[])[0], 'field_gongnengfenlei', {contentType: 'chanpinfenlei'})
                    }
                    {this.renderMultilineField(product, 'field_tianchongji')}
                </ProdFieldBlock>
                <ProdFieldBlock>    
                    {this.renderMultilineField(product, 'field_peifangzhishichanquan')}
                    {this.renderMultilineField(product, 'field_yizhuceshangbiao')}
                    {this.renderMultiOptionField(product, 'field_qitarenzheng')}
                    {this.renderMultiOptionField(product, 'field_renzhengxiangqing')}
                </ProdFieldBlock>
                <ProdFieldBlock>
                    {product.fields.field_xiaoshouxinxi && (product.fields.field_xiaoshouxinxi as Doc[]).length>0 &&
                     this.renderPrices(product.fields.field_xiaoshouxinxi as Doc[])}
                </ProdFieldBlock>

                { product.fields.field_xueshugongxiaochengfen && (product.fields.field_xueshugongxiaochengfen as Doc[]).length>0?
                  this.renderIngredients(product.fields.field_xueshugongxiaochengfen as Doc[], 'field_xueshugongxiaochengfen') :
                  null
                }
                {actionButtons}
            </div>
        )
    }

    renderPrices(xiaoshouxinxi:Doc[]): JSX.Element {
        return <Fragment>
              { xiaoshouxinxi.map(priceField=>{
                    return this.renderMultilineField(priceField, 'field_jiage', 
                                                     {
                                                        contentType:'xiaoshouxinxi', 
                                                        derivedValue: `${priceField.fields.field_jiage} ${priceField.fields.field_huobizhonglei} (${(priceField.fields.field_shiyongquyu as string[]).join(', ')})`, 
                                                        extraClasses: ''
                                                     })
                })
              }
              </Fragment> 
    }

    renderIngredients(ingredients:Doc[], fieldName:string):JSX.Element | null {
        if(ingredients.length<=0) return null;

        let label = this.getContentFieldLabel('chanpin', fieldName);
        const fullTextKeywords = this.getFullTextHighlightKeywords();

        const renderValueCell = (s:string, className?:string) =>  {
            return <TableCell className={className}><Highlighter searchWords={fullTextKeywords} textToHighlight={s}/></TableCell>
        }

        return  <div className='ingredient-table'>
                    <div className='label-wrapper'><label>{label}</label></div>
                        {
                            ingredients.filter(x=> !!x).map(v => {
                                return (
                                <Paper>    
                                  <Table size='small'>
                                    <TableBody>
                                        <TableRow>
                                           <TableCell className='ingredient-tr-property'>{this.getContentFieldLabel('peifang', 'field_yuanliaomingcheng')}</TableCell>
                                           {renderValueCell(v.fields.field_yuanliaomingcheng as string, 'ingredient-tr-value')}
                                       </TableRow>
                                       <TableRow>
                                           <TableCell className='ingredient-tr-property'>{this.getContentFieldLabel('peifang', 'field_yuanliaoladingwenming')}</TableCell>
                                           {renderValueCell(v.fields.field_yuanliaoladingwenming as string, 'ingredient-tr-value')}
                                       </TableRow>
                                       <TableRow>
                                            <TableCell className='ingredient-tr-property'>{this.getContentFieldLabel('peifang', 'field_yuanliaohanliang')}</TableCell>
                                            <TableCell className='ingredient-tr-value'>{v.fields.field_yuanliaohanliang}</TableCell>
                                       </TableRow>
                                       <TableRow>
                                            <TableCell className='ingredient-tr-property'>{this.getContentFieldLabel('peifang', 'field_jianyishuchengfen')}</TableCell>
                                            <TableCell className='ingredient-tr-value'>{v.fields.field_jianyishuchengfen}</TableCell>
                                       </TableRow>
                                       {v.fields.field_yuanliaofujiaxinxi && 
                                            <TableRow>
                                                <TableCell className='ingredient-tr-property'>{this.getContentFieldLabel('peifang', 'field_yuanliaofujiaxinxi')}</TableCell>
                                                {renderValueCell(v.fields.field_yuanliaofujiaxinxi as string, 'ingredient-tr-value')}
                                            </TableRow>
                                       }
                                    </TableBody>
                                  </Table>
                                </Paper>
                                )
                            })
                        }
                </div>

    }

    getSymptomHighlightKeywords () {
        let highlightWords:string[] = [];
        if(this.state.symptomKeywords){
            this.state.symptomKeywords.trim().split(/[ ]+/g).forEach(s=>{
                if(s){
                    highlightWords.push(s);
                }
            })
        }
        if(this.state.fullTextKeywords){
            this.state.fullTextKeywords.trim().split(/[ ]+/g).forEach(s=>{
                if(s) highlightWords.push(s);
            })
        }
        return highlightWords;
    }
    getFullTextHighlightKeywords() {
        let highlightWords:string[] = [];
        if(this.state.fullTextKeywords){
            this.state.fullTextKeywords.trim().split(/[ ]+/g).forEach(s=>{
                if(s) highlightWords.push(s);
            })
        }
        return highlightWords;
    }

    renderMultilineField(product:Doc, fieldName:string, 
                         options?: {extraClasses?:string, 
                                    derivedValue?: string, 
                                    contentType?:string,
                                    keywords?:string
                                   }): JSX.Element|null {
        let field = product.fields[fieldName];
        if(!field) return null;
        let fieldSpec = this.getContentFieldSpec(options && options.contentType? options.contentType : 'chanpin', fieldName);
        let label  = this.getContentFieldLabel(options && options.contentType? options.contentType : 'chanpin', fieldName);
        let extraClasses:string|undefined;
        if(options && options.extraClasses){
            extraClasses = options.extraClasses;
        }
        let lines:string|undefined;
        if(options && options.derivedValue){
            lines = options.derivedValue;
        }else{
            if(_.isArray(field)){
                lines = (field||[]).join(', ');
            }else{
                lines = field as string;
            }
        }
        let highlightWords:string[] = [];
        if(options && options.keywords){
            options.keywords.trim().split(/[ ]+/g).forEach(s=>{
                if(s) highlightWords.push(s);
            })
        }
        if(this.state.fullTextKeywords){
            this.state.fullTextKeywords.trim().split(/[ ]+/g).forEach(s=>{
                if(s) highlightWords.push(s);
            })
        }

        return <MultilineField label={label} lines={lines} extraClasses={extraClasses} highlightWords={highlightWords} 
                               onClickTerm={this.displayShuyu}/>;
    }

    renderMultiOptionField(product:Doc, fieldName:string, extraClasses?:string): JSX.Element|null {
        let field = (product.fields[fieldName]) as string[];
        if(!field) return null;
        let label  = this.getContentFieldLabel('chanpin', fieldName);
        return <MultilineField label={label} lines={field.join(', ')} extraClasses={extraClasses}
                               onClickTerm={this.displayShuyu}/>;
    }

    getContentFieldSpec(contentName:string, fieldName:string): DocFieldSpec|null {
        let node = this.state.docSpecs!.content.find(x=> x.name === contentName);
        if(node){
            let fldSpec = node.fieldSpecs[fieldName];
            if(fldSpec){
                return fldSpec;
            }
        }
        node = this.state.docSpecs!.paragraph.find(x=>x.name === contentName);
        if(node){
            let fldSpec = node.fieldSpecs[fieldName];
            if(fldSpec){
                return fldSpec;
            }
        }
        return null;
    }

    getContentFieldLabel(contentName:string, fieldName:string): string {
        let fieldSpec = this.getContentFieldSpec(contentName, fieldName);
        if(fieldSpec){
            return fieldSpec.label;
        }else{
            return fieldName;
        }
    }

    loadProduct(dispatch:(x:any)=>void, id:string){
        let whatIsFor:string = t('读取产品');
        doAJAXCall({
            whatIsFor,
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken)=>{
                const product = await getDoc(accessToken, 'chanpin', id);
                // @ts-ignore
                this.scrollY = window.scrollY || window.scrollTop || document.getElementsByTagName("html")[0].scrollTop;
                window.scrollTo(0,0); // display product at beginning
                this.setState({currentProd: product, isCurrentProdBookmarked: !!product.bookmarkId});
            }
        })
    }

    searchProducts = (page: number, size:number):void => {
        let whatIsFor:string = t('检索产品');
        doAJAXCall({
            whatIsFor,
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken)=>{
                const data = await searchProductAPI(accessToken, 
                                                    {
                                                        type:'chanpin', 
                                                        application: this.state.symptomKeywords, 
                                                        classification: this.state.professionalChoice,
                                                        declaration: this.state.functionalChoice,
                                                        name: this.state.productName, 
                                                        ingredient:this.state.ingredient,
                                                        fullText: this.state.fullTextKeywords, 
                                                        fuzziness: this.state.fuzziness,
                                                        page, 
                                                        size});
            this.setState({data, page:data.page, pageSize: data.size}, ()=>{
                window.scrollTo(0, 0); // make next/prev page button is always in viewport so user can click prev/next quickly
            });                                            
          }
        })
    }

    init(){
        this.loadDocSpecs ();        
    }
    componentDidMount() {
        this.init();
    }


    loadDocSpecs() {
        let whatIsFor:string = t('读取产品结构');
        doAJAXCall({
            whatIsFor,
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken)=>{
                await loadExternContatnsIfNecessary();
                const docSpecs = await getDocSpecs(accessToken);
                this.setState({pageInitState:'PAGE_LOAD_SUCCESS', docSpecs});
            },
            onError: (errorMessage)=>{
                this.setState({pageInitState:'PAGE_LOAD_FAILED', initErrorMessage:errorMessage})
            }
        })
    }

    logout() {
        gotoLoginPage();
    }

    displayShuyu = async (term:string)=>{
        let whatIsFor:string = t('读取术语');
        doAJAXCall({
            whatIsFor,
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken)=>{
                let termDef = await getShuyu(term, accessToken);
                this.termDialog!.show(term, 
                    <MultilineField label='' lines={termDef.fields.field_mingci} skipShuyu={true} onClickTerm={()=>{}}/> , 
                    ()=>{})
            }
        })
    }
}


