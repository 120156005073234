import * as React from 'react';
import {Component, Fragment} from 'react';

import './UnishellPayView.scss';

import {t} from '../../i18n';
import { ReactReduxContext, batch } from 'react-redux';
import { Button, Paper, Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import { readLoginSession, readPersistData, readLastLoginEmail } from '../../local-storage/local-storage';
import { cancelPaymentOrder, getPaymentOrder, retrieveUnishellPoints, unishellPay } from '../../wallet-server-api/wallet-server-api';
import { is401Error } from "../../wallet-server-api/ajax-common";
import { Toast } from '../Toast';
import { NoticeDialog } from '../NoticeDialog';
import { getCordova } from '../../globals';
import { PaymentOrder, UnishellPayDetail } from '../../api/unishell-pay';
import { Points } from '../../container/Points';
import { round, genPaymentKey, encryptPayment } from '../../util/Util';
import { loadPrivateKey, signMessage } from '../../cypto/crypto';
import {PasswordDialogView} from '../password-dialog/PasswordDialogView';
import { BaseUnishellPageState, renderInitErrorUnishellPage, renderInitUnishellPage, renderUnishellPage } from '../../unishell-page-common/unishell-page-common';
import { gotoLoginPage, shoppingGoBack } from '../../shopping/history/shopping-history';
import { doAJAXCall } from '../../shopping/common/ajax-call';
import { loadExternContatnsIfNecessary } from '../../external-constants/extern-constants';
import { UButton } from '../../widget/button/ubutton';
import { PropertyListDataItem, PropertyListWidget } from '../../widget/property-list/property-list';

export interface ScanOrderCodeResult {
    text:string; // qr code text
    format:string; // QR_CODE
    cancelled:boolean;
    message: string;
}

export type TUnishellPayCaller = 'merchant' | 'buyer';


interface MyProps {
    caller: TUnishellPayCaller,
    orderCode:string, 
    dispatch:(action:any)=>void
}

interface MyState extends BaseUnishellPageState{
    order?:PaymentOrder;
}

export class UnishellPayView extends Component<MyProps,MyState>{
    private toast: Toast | undefined;
    private passwordDialog: PasswordDialogView | undefined;
    private errorDialog: NoticeDialog|undefined;

    constructor(props:MyProps){
        super(props);
        this.state = {pageInitState:'PAGE_INIT'}
    }

    quit = (error: any)=>{
        if(this.props.caller === 'merchant'){
            if(!getCordova()){
                window.open('','_self')!.close();
            }else{
                // @ts-ignore
                navigator.app.exitApp();
            }
        }else{
            if(error && is401Error(error)){
                gotoLoginPage();
            }else{
                shoppingGoBack();
                // this.props.dispatch(setCurrentPathPath('main/wallet/receive-transfer'));
            }
        }
    }


    render() {
        if(this.state.pageInitState === 'PAGE_INIT'){
            return <React.Fragment>
                        {renderInitUnishellPage({
                            title: t('U点支付'), 
                            className: 'unishell-pay-page',
                        })}
                        <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                    </React.Fragment>
        }
        if(this.state.pageInitState === 'PAGE_LOAD_FAILED'){
            return <React.Fragment>
                        {renderInitErrorUnishellPage({
                            title: t('U点支付'), 
                            className: 'unishell-pay-page',
                            errorMessage: this.state.initErrorMessage||t('初始化失败'),
                        })}
                        <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                    </React.Fragment>
        }


        return <ReactReduxContext.Consumer>
            { ({store})=>{
                const orderCode:string = this.props.orderCode;


                const cancelPayment = ()=>{
                    let whatIsFor:string = t('取消U点支付');
                    doAJAXCall({
                        whatIsFor,
                        errorDialog: this.errorDialog!,
                        ajaxCall: async (accessToken) => {
                            await cancelPaymentOrder(accessToken, orderCode);
                            this.toast!.sendMessage(whatIsFor+t(' 成功'), 'success', false, ()=>{ this.quit(null)  });
                        }
                    })
                }

                const clickPay = ()=>{
                    this.passwordDialog!.show(<Fragment>{t(`确认支付`)}{round(this.state.order!.amount,2)}{t('点给')}
                                                <span >{this.state.order!.merhantName}</span>
                                            </Fragment>);

                }

                

                const handleConfirmation = (password:string, confirmed:boolean)=>{
                    if(confirmed){
                        const session = readLoginSession();
                        if(!session){
                            gotoLoginPage();
                            return;
                        }
                        const paymentKey = genPaymentKey(readLastLoginEmail()!.toLowerCase()+password);
                        
                        const nonce = ''+Math.random();
                        const payDetail:UnishellPayDetail = {orderCode: this.state.order!.orderCode, time:Date.now(), nonce}
                        const localData = readPersistData(session!.profile!.username);
                        if(!localData){
                            this.toast!.sendMessage(t('数据出错。请联系Unishell客服'), 'error', false);
                            return;
                        }
                
                        let whatIsFor:string = t('U点支付');
                        doAJAXCall({
                            whatIsFor,
                            errorDialog: this.errorDialog!,
                            ajaxCall: async (accessToken) => {
                                const keyManager = await loadPrivateKey(localData!.keyPair.priKey);
                                const {signedMessage} = await signMessage(keyManager, payDetail);
                                let encryptedData = encryptPayment(signedMessage, paymentKey);
                                await unishellPay(accessToken, 
                                    {keyId: localData.pkeyHash, data: encryptedData},
                                    this.props.orderCode);                                
                                    this.toast!.sendMessage(whatIsFor+t(' 成功'), 'success', false, ()=>{ this.quit(null)  });
                            }
                        })
                    }
                }

                let orderProps: PropertyListDataItem[] = [];
                if(this.state.order){                    
                    orderProps = [
                        {name: t('订单'), value: this.state.order.orderCode},
                        {name: t('商家订单'), value: this.state.order.orderId},
                        {name: t('商家'), value: this.state.order.merhantName},
                        {name: t('商品服务'), value: this.state.order.orderDescription},
                        {name: t('U点'), value: round (this.state.order.amount, 2)},
                        {name: t('状态'), value: this.state.order.status},
                    ]
                }

                const orderView = this.state.order? 
                                  <div className='payment-order'>
                                      <PropertyListWidget list={orderProps} showBorder={true} />
                                  </div>:null;

                const content = (
                    <div className='unishell-pay-page-content'>
                          <Points/>
                          {orderView}
                         
                          <UButton   className='pay-btn' variant='contained' color='primary' 
                                    disabled={this.state.pageInitState!=='PAGE_LOAD_SUCCESS'} 
                                    onClick={clickPay}>{t('支付')}</UButton>
                          <UButton className='cancel-btn' variant='contained' color='alert' onClick={cancelPayment}>{t('取消订单')}</UButton>
                          <UButton className='cancel-btn' variant='contained' color='secondary' onClick={()=>{ this.quit(null) }}>{t('返回')}</UButton>
                          <Toast close={true} message='' toastType='info' onClose={()=>{}} ref={(e)=>{this.toast=e!}} duration={2500}/>

                            <PasswordDialogView confirmLabel={t('同意')} cancelLabel={t('取消')} 
                                                onClose={handleConfirmation}
                                                ref={e=>{this.passwordDialog=e!}} 
                            />
                            <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>                   
                    </div>
                )
                return renderUnishellPage(
                    {
                        title: t('U点支付'),
                        className: 'unishell-pay-page',
                        content
                    }
                )
            }}
        </ReactReduxContext.Consumer>
    }

    componentDidMount(){
        let whatIsFor:string = t('读取订单');
        doAJAXCall({
            whatIsFor,
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken) => {
                await loadExternContatnsIfNecessary();
                const pts = await retrieveUnishellPoints(accessToken);
                const points = pts;
                const order = await getPaymentOrder(accessToken, this.props.orderCode);
                
                let initErrorMessage:string = '';
                if(order.status !== 'CREATED'){
                    initErrorMessage = order.orderId +':'+ t('订单已经被处理');
                }else if(order.amount>points){
                    initErrorMessage = t('U点数量不足');
                }
                if(!initErrorMessage){
                    this.setState({pageInitState:'PAGE_LOAD_SUCCESS', order});
                }else{
                    this.setState({order, pageInitState:'PAGE_LOAD_FAILED', initErrorMessage}, ()=>{
                        if(initErrorMessage) this.errorDialog!.show(whatIsFor, initErrorMessage, ()=>{})
                    });        
                }
            },
            onError: (errorMessage)=>{
                shoppingGoBack();
            }
        })
    }
}