import * as React from 'react';
import {Component} from 'react';
import { connect } from 'react-redux';
import { t } from '../../i18n';
import { WalletAppState } from '../../redux-store';
import { NoticeDialog } from '../../view/NoticeDialog';
import { doAJAXCall } from '../common/ajax-call';
import { BaseShoppingPageProps} from '../common/shopping-page-common';
import { CountryOption, NutritionistInfo, ShoppingAddressSpec, toImgSrc } from '../ajax-data/ajax-data-types';
import { getAddressSpecMap, getCountryOptions, retrieveNutritionist, saveNutritionist, saveNutritionistLicence, savePhoto} from '../ajax-data/shopping-server-api';
import './nutritionist-profile.scss';
import { EditNutritionist } from '../edit-nutritionist/edit-nutritionist';
import { Button } from '@material-ui/core';
import { Toast } from '../../view/Toast';
import sha1 from 'sha1';
import { ImageWithOverlay } from '../common/image-with-overlay';
import { BaseUnishellPageState, renderInitErrorUnishellPage, renderInitUnishellPage, renderUnishellPage } from '../../unishell-page-common/unishell-page-common';
import { loadExternContatnsIfNecessary } from '../../external-constants/extern-constants';
import { isAdmin } from '../../api/user';
import { UButton } from '../../widget/button/ubutton';
import { NutritionistProfilePortrait } from './profile/profile-portrait';

interface NutritionistProfileProps extends BaseShoppingPageProps{
    /** default is current username */
    nutritionistId?: string;
    editMode?: boolean;
}
interface NutritionistProfileState extends BaseUnishellPageState {
    countryOptions?:CountryOption[];
    shoppingAddressspecMap?: {[key:string]:ShoppingAddressSpec};

    nutritionist?:NutritionistInfo;
    editMode?: boolean;
}

class NutritionistProfileView extends Component<NutritionistProfileProps, NutritionistProfileState>{
    private nutritionistEditor:EditNutritionist|undefined;
    private toast: Toast | undefined;
    private errorDialog:NoticeDialog|undefined;

    constructor(props:NutritionistProfileProps){
        super(props);
        this.state = {pageInitState:'PAGE_INIT', editMode: this.props.editMode}
    }
    getTitle(){
        return isAdmin() ? t('编辑营养师信息') : t('营养师信息');
    }

    saveNutritionistInfo(){
        let {data, hasError} = this.nutritionistEditor!.validateAndGetNutritionistInfo();
        {
            let succMsgs: string[] = [];

            let title = t('保存营养师信息');

            doAJAXCall({
                whatIsFor: t('保存营养师信息'),
                whatIsForFn: ()=> { return title},
                errorDialog: this.errorDialog!,
                ajaxCall: async (accessToken, username)=>{ 
                    if(data.uploadedAvatar && data.uploadedAvatar){
                        title = t('保存头像');
                        let photoInfo = await savePhoto(accessToken, data.uploadedAvatar.file!, 'photo',
                                                        JSON.stringify({t:'avatar',username}));  
                        data.avatar = photoInfo;
                        delete data.uploadedAvatar;                                                      
                    }

                    title = t('保存营养师信息');
                    let nutritionist = await saveNutritionist(accessToken, data!);
                    succMsgs.push(title);
                    this.setState({nutritionist}, 
                                ()=>{ 
                                    this.toast!.sendMessage(succMsgs.join('. '), 'success');
                                    if(hasError){
                                        setTimeout(()=>{
                                            this.toast!.sendMessage(t('表格中还有错误'), 'warning');
                                        }, 3500)
                                    }                                    
                                });
                },
                onError: (errorMessage:string)=>{ }                                           
            })        
        }
    }

    render(){
        if(this.state.pageInitState === 'PAGE_INIT'){
            return <React.Fragment>
                        {renderInitUnishellPage({
                            title: this.getTitle(), 
                            className:'shopping-nutritionist-profile',
                        })}
                        <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                    </React.Fragment>
        }
        if(this.state.pageInitState === 'PAGE_LOAD_FAILED'){
            return <React.Fragment>
                        {renderInitErrorUnishellPage({title: this.getTitle(),
                                                      className:'shopping-nutritionist-profile', 
                                                      errorMessage: this.state.initErrorMessage||t('初始化失败'),
                                                      onTryAgain: ()=>{ this.initializeAgain();    }
                                                      })}
                        <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                    </React.Fragment>
        }

        if(!isAdmin())  return <NutritionistProfilePortrait {...this.props}/>

        // loading data succeeded
        let content = this.renderSucceedContent();
        return (
            <React.Fragment>
                { renderUnishellPage(
                    {
                        title: this.getTitle(), 
                        className:'shopping-nutritionist-profile', 
                        content
                    }
                  )
                }
                <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                <Toast close={true} message='' toastType='info' onClose={()=>{}} ref={(e)=>{this.toast=e!}} duration={3000}/>
            </React.Fragment>
        )   
    }
    renderSucceedContent():JSX.Element {
        let buttons = this.state.editMode?  <p className='action-buttons'>
                                                <UButton variant='contained' color='secondary' 
                                                        onClick={()=>{ this.setState({editMode: false}) }}>{t('取消')}</UButton>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <UButton variant='contained' color='primary'
                                                        onClick={()=>{ this.saveNutritionistInfo() }}
                                                >{t('保存')}</UButton>
                                            </p>: 
                                            <p className='action-buttons'>
                                                <UButton variant='contained' color='primary' 
                                                       onClick={()=>{this.setState({editMode: true})}}>
                                                {t('编辑')}
                                                </UButton>
                                            </p>;
        return (
            <div className='shopping-nutritionist-profile-content'>
            <React.Fragment>
                <p>{t(`* 营养师二维码`)}</p>
                <ImageWithOverlay classes='qr-code' imageList={[toImgSrc(this.state.nutritionist!.qrCode.base64Image)]}/>
                {buttons}
                <EditNutritionist   languageCode={this.props.languageCode!}
                                        countryOptons={this.state.countryOptions!}
                                        addressSpecMap={this.state.shoppingAddressspecMap!} 
                                        nutritionist={this.state.nutritionist!}
                                        ref={e=>{this.nutritionistEditor = e!}}
                                        key={sha1(JSON.stringify(this.state.nutritionist))} 
                                        readonly={!this.state.editMode}
                />
                {buttons} 
            </React.Fragment>               
            </div>
        )
    }

    initializeAgain(){
        this.setState({pageInitState:'PAGE_INIT', initErrorMessage:undefined},
                      ()=>{
                          this.loadData();
                      })
    }

    componentDidMount(){
        this.loadData();
    }

    loadData(){
        doAJAXCall({
            whatIsFor: t('营养师信息'),
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken, username)=>{ 
                                    await loadExternContatnsIfNecessary();
                                    let countryOptions = getCountryOptions(accessToken, this.props.languageCode!);
                                    let shoppingAddressspecMap = getAddressSpecMap(accessToken, this.props.languageCode!);

                                    let nutritionist = await retrieveNutritionist(accessToken, this.props.nutritionistId || username);
                                    this.setState({nutritionist,countryOptions, shoppingAddressspecMap, pageInitState:'PAGE_LOAD_SUCCESS'});
                                },
            onError: (errorMessage:string)=>{ this.setState({pageInitState:'PAGE_LOAD_FAILED', initErrorMessage:errorMessage})}                                           
        })        
    }

}

export const EditNutritionistProfile = connect(
    (state:WalletAppState)=>({languageCode: state.language.langCode})
)(NutritionistProfileView);