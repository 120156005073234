// Generated by IcedCoffeeScript 108.0.11
(function () {
  var AES,
      C,
      Packet,
      SHA1,
      SHA256,
      UserID,
      bufferify,
      encrypt,
      native_rng,
      parse,
      triplesec,
      uint_to_buffer,
      _ref,
      _ref1,
      __hasProp = {}.hasOwnProperty,
      __extends = function __extends(child, parent) {
    for (var key in parent) {
      if (__hasProp.call(parent, key)) child[key] = parent[key];
    }

    function ctor() {
      this.constructor = child;
    }

    ctor.prototype = parent.prototype;
    child.prototype = new ctor();
    child.__super__ = parent.prototype;
    return child;
  };

  C = require('../../const').openpgp;
  triplesec = require('triplesec');
  _ref = triplesec.hash, SHA1 = _ref.SHA1, SHA256 = _ref.SHA256;
  AES = triplesec.ciphers.AES;
  native_rng = triplesec.prng.native_rng;
  _ref1 = require('../../util'), bufferify = _ref1.bufferify, uint_to_buffer = _ref1.uint_to_buffer;
  encrypt = require('../cfb').encrypt;
  Packet = require('./base').Packet;
  parse = require('pgp-utils').userid.parse;

  UserID = function (_super) {
    __extends(UserID, _super);

    function UserID(userid, components) {
      this.components = components != null ? components : null;
      this.userid = bufferify(userid);

      if (this.compontents == null) {
        this._parse();
      }

      UserID.__super__.constructor.call(this);

      this._time_primary_pair = null;
      this.primary = false;
      this.most_recent_sig = null;
    }

    UserID.prototype.utf8 = function () {
      return this.userid.toString('utf8');
    };

    UserID.prototype.write = function () {
      return this.frame_packet(C.packet_tags.userid, this.userid);
    };

    UserID.parse = function (slice) {
      return new UserID(slice.consume_rest_to_buffer());
    };

    UserID.prototype.to_userid = function () {
      return this;
    };

    UserID.prototype.cmp = function (b) {
      var x, y;
      x = this.utf8();
      y = b.utf8();

      if (x < y) {
        return -1;
      } else if (x === y) {
        return 0;
      } else {
        return 1;
      }
    };

    UserID.prototype.to_signature_payload = function () {
      return Buffer.concat([Buffer.from([C.signatures.userid]), uint_to_buffer(32, this.userid.length), this.userid]);
    };

    UserID.prototype._parse = function () {
      var c;

      if ((c = parse(this.utf8())) != null) {
        return this.components = c;
      }
    };

    UserID.prototype.get_username = function () {
      var _ref2;

      return (_ref2 = this.components) != null ? _ref2.username : void 0;
    };

    UserID.prototype.get_comment = function () {
      var _ref2;

      return (_ref2 = this.components) != null ? _ref2.comment : void 0;
    };

    UserID.prototype.get_email = function () {
      var _ref2;

      return (_ref2 = this.components) != null ? _ref2.email : void 0;
    };

    UserID.prototype.time_primary_pair = function () {
      var max, p, pairs, ret, s, _i, _len;

      if (this._time_primary_pair == null) {
        pairs = function () {
          var _i, _len, _ref2, _ref3, _results;

          _ref2 = this.get_psc().get_self_sigs();
          _results = [];

          for (_i = 0, _len = _ref2.length; _i < _len; _i++) {
            s = _ref2[_i];

            _results.push(s != null ? (_ref3 = s.sig) != null ? _ref3.time_primary_pair() : void 0 : void 0);
          }

          return _results;
        }.call(this);

        max = null;
        ret = [null, null];

        for (_i = 0, _len = pairs.length; _i < _len; _i++) {
          p = pairs[_i];

          if (!(p != null)) {
            continue;
          }

          if (p[0] && (ret[0] == null || ret[0] < p[0])) {
            ret[0] = p[0];
          }

          if (p[1] && (ret[1] == null || ret[1] < p[0])) {
            ret[1] = p[0];
          }
        }

        this._time_primary_pair = ret;
        this.most_recent_sig = ret[0];
      }

      return this._time_primary_pair;
    };

    UserID.make = function (components) {
      var c, comment, userid;
      comment = (c = components.comment) != null ? "(" + c + ") " : "";
      userid = "" + components.username + " " + comment + "<" + components.email + ">";
      return new UserID(userid, components);
    };

    UserID.prototype.get_framed_signature_output = function () {
      var _ref2, _ref3, _ref4;

      return (_ref2 = this.get_psc()) != null ? (_ref3 = _ref2.get_self_sig()) != null ? (_ref4 = _ref3.sig) != null ? _ref4.get_framed_output() : void 0 : void 0 : void 0;
    };

    UserID.prototype.mark_revoked = function (sig) {
      return this.revocation = sig;
    };

    UserID.prototype.is_revoked = function () {
      return this.revocation != null;
    };

    return UserID;
  }(Packet);

  exports.UserID = UserID;
}).call(this);