import * as React from 'react';
import {Component, Fragment} from 'react';
import { DocFieldSpec, DocSearchResponse, DocSpecResponse, ProductBrief } from '../../api/docs';
import { PagedResponse } from '../../api/transaction';
import { t } from '../../i18n';
import { doAJAXCall } from '../../shopping/common/ajax-call';
import { Pagination } from '../../shopping/common/render-pagination';
import { BaseUnishellPageState, renderInitErrorUnishellPage, renderInitUnishellPage, renderUnishellPage } from '../../unishell-page-common/unishell-page-common';
import { getBookmarkedProducts, getDoc, getDocSpecs, getShuyu } from '../../wallet-server-api/wallet-server-api';
import { ImageWithPlaceholder } from '../image-with-placeholder/ImageWithPlaceHolder';
import { NoticeDialog } from '../NoticeDialog';
import { Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import { MultilineField } from '../search-products/multiline-field';
import { loadExternContatnsIfNecessary } from '../../external-constants/extern-constants';

import './bookmark.scss'
import { gotoShoppingPathNSavePrevPath } from '../../shopping/history/shopping-history';
import { scrollBodyToTop } from '../../util/Util';

interface MyState extends BaseUnishellPageState {
    docSpecs?: DocSpecResponse;

    searchResult?: PagedResponse<DocSearchResponse>;
    page: number;
    pageSize: number;
}

export class MyBookMarks extends Component<{}, MyState> {
    private errorDialog: NoticeDialog | undefined;
    private termDialog: NoticeDialog|undefined;

    constructor(props: {}){
        super(props);
        this.state = {pageInitState:'PAGE_INIT', page:0, pageSize: 10}
    }

    getTitle(){
        return t('我的收藏');
    }

    render(){
        if(this.state.pageInitState === 'PAGE_INIT'){
            return (
                <Fragment>
                { renderInitUnishellPage({
                    title: t('我的收藏'),
                    className: 'my-bookmarks-page',
                })}
                <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                <NoticeDialog open={false} ref={e=>this.termDialog=e!}/>
                </Fragment>
            )
        }
        if(this.state.pageInitState === 'PAGE_LOAD_FAILED'){
            return (
                <Fragment>
                { renderInitErrorUnishellPage({
                    title: t('我的收藏'),
                    className: 'my-bookmarks-page',
                      errorMessage: this.state.initErrorMessage!,
                      onTryAgain: ()=>{
                          this.init();
                      }                    
                })}
                <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                <NoticeDialog open={false} ref={e=>this.termDialog=e!}/>
                </Fragment>
            )
        }

        const content = <Fragment>
                        {this.renderSucceedContent()}
                        <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                        <NoticeDialog open={false} ref={e=>this.termDialog=e!}/>
                        </Fragment>

        return renderUnishellPage({
            title: t('我的收藏'),
            className: 'my-bookmarks-page',
            content
        })        


        // const content = this.renderProdSummaries(this.state.bookmarks, this.loadBookmarkedProds, true);
    }

    renderSucceedContent():JSX.Element {
        return (
            <div className='my-bookmarks-main-pane'>
                <Pagination data={this.state.searchResult!} page={this.state.page} pageSize={this.state.pageSize} 
                            loadData={this.loadBookmarkedProds}
                            changePageSize={(newPageSize, newPage)=>{
                                this.loadBookmarkedProds(newPage, newPageSize);
                            }}
                >
                {this.renderProductList()}                                
                </Pagination>
            </div>
        )
    }

    loadProduct(id:string){
        let whatIsFor:string = t('读取产品');
        doAJAXCall({
            whatIsFor,
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken)=>{
                const product = await getDoc(accessToken, 'chanpin', id);
                gotoShoppingPathNSavePrevPath('main/tools/search-products', {currentProd:product})
            }
        })
    }


    renderProductList(){
        // this table is responsive
        let prodTable = <div className='product-summary-table'>
            {
                this.state.searchResult!.content.map(p=> {
                    const clickItem = ()=>{
                        this.loadProduct(p.id);
                    }
                    let brief:ProductBrief = JSON.parse(p.brief);
                    return  <div className='product-summary-row'>
                                <div className='product-thumbnail-td' onClick={clickItem}>
                                    <ImageWithPlaceholder imgSrc={brief.fields.field_tupian?(brief.fields.field_tupian[0].url+'&width=128'):undefined} 
                                                            imgClassName='product-thumbnail'                                                            
                                                    />                                                 
                                </div>
                                <div className='product-overview'>
                                    <div className='product-brief-name' onClick={clickItem}>
                                        {brief.fields.field_zhongwenming||''} / {brief.fields.field_yingwenming||''}
                                    </div>
                                    <div className='product-brief-paragraph'>
                                        <MultilineField highlightWords={[]} 
                                                        label={this.getContentFieldLabel('chanpin', 'field_yingyongfanwei')}
                                                        lines={this.shortenString(brief.fields.field_yingyongfanwei, 256)}
                                                        onClickTerm={this.displayShuyu}
                                                        />                              
                                    {brief.fields.field_chanpintedian && 
                                        <Fragment>
                                        <MultilineField highlightWords={[]} 
                                                        label={this.getContentFieldLabel('chanpin', 'field_chanpintedian')}
                                                        lines={this.shortenString(brief.fields.field_chanpintedian, 256)}
                                                        onClickTerm={this.displayShuyu}
                                                        />
                                        </Fragment>}
                                    </div>
                                </div>    
                            </div>

                    return <TableRow>
                        <TableCell className='product-thumbnail-td' onClick={clickItem}>
                            <Fragment>
                                <ImageWithPlaceholder imgSrc={brief.fields.field_tupian?(brief.fields.field_tupian[0].url+'&width=48'):undefined} 
                                                      imgClassName='product-thumbnail'/>                                                 
                            </Fragment>
                        </TableCell>
                        <TableCell>
                            <div className='product-brief-name' onClick={clickItem}>
                                {brief.fields.field_zhongwenming||''} / {brief.fields.field_yingwenming||''}
                            </div>
                            <div className='product-brief-paragraph'>
                                <MultilineField highlightWords={[]} 
                                                label={this.getContentFieldLabel('chanpin', 'field_yingyongfanwei')}
                                                lines={this.shortenString(brief.fields.field_yingyongfanwei, 256)}
                                                onClickTerm={this.displayShuyu}
                                                />                              
                               {brief.fields.field_chanpintedian && 
                                <Fragment>
                                   <MultilineField highlightWords={[]} 
                                                   label={this.getContentFieldLabel('chanpin', 'field_chanpintedian')}
                                                   lines={this.shortenString(brief.fields.field_chanpintedian, 256)}
                                                   onClickTerm={this.displayShuyu}
                                                   />
                                </Fragment>}
                            </div>
                        </TableCell>
                    </TableRow>
                })
            }
        </div>;

        return <div className='product-list'>{prodTable}</div>    
    }
    
    getContentFieldSpec(contentName:string, fieldName:string): DocFieldSpec|null {
        let node = this.state.docSpecs!.content.find(x=> x.name === contentName);
        if(node){
            let fldSpec = node.fieldSpecs[fieldName];
            if(fldSpec){
                return fldSpec;
            }
        }
        node = this.state.docSpecs!.paragraph.find(x=>x.name === contentName);
        if(node){
            let fldSpec = node.fieldSpecs[fieldName];
            if(fldSpec){
                return fldSpec;
            }
        }
        return null;
    }

    getContentFieldLabel(contentName:string, fieldName:string): string {
        let fieldSpec = this.getContentFieldSpec(contentName, fieldName);
        if(fieldSpec){
            return fieldSpec.label;
        }else{
            return fieldName;
        }
    }
    shortenString(s:string, maxLength:number){
        if(!s) return s;
        if(s.length<=maxLength){
            return s;
        }
        return s.substr(0, maxLength)+' ...';
    }

    componentDidMount() {
        this.init();
    }

    componentDidUpdate(prevProps:any, prevState:MyState){
        if(prevState.page !== this.state.page){
            scrollBodyToTop();
        }
    }

    init(){
        this.loadBookmarkedProds(this.state.page, this.state.pageSize)
    }

    loadBookmarkedProds = (page: number, pageSize:number):void => {
        let whatIsFor:string = t('下载收藏产品');
        doAJAXCall({
            whatIsFor,
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken)=>{
                await loadExternContatnsIfNecessary();
                const docSpecs = await getDocSpecs(accessToken);
                const searchResult = await getBookmarkedProducts(accessToken, page, pageSize);
                this.setState({docSpecs, searchResult, page: searchResult.page, pageSize:searchResult.size, 
                               pageInitState: 'PAGE_LOAD_SUCCESS'}, ()=>{
                    window.scrollTo(0, 0); // make next/prev page button is always in viewport
                });    
            },
            onError: (errorMsg)=>{
                this.setState({pageInitState:'PAGE_LOAD_FAILED', initErrorMessage: errorMsg});
            }
        })
    }
    displayShuyu = async (term:string)=>{
        let whatIsFor:string = t('读取术语');
        doAJAXCall({
            whatIsFor,
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken)=>{
                let termDef = await getShuyu(term, accessToken);
                this.termDialog!.show(term, 
                    <MultilineField label='' lines={termDef.fields.field_mingci} skipShuyu={true} onClickTerm={()=>{}}/> , 
                    ()=>{})
            }
        })
    }

}