import * as React from 'react';
import {Component} from 'react';
import { getValidReactChildren } from '../../util/Util';
import './v-equal-size.scss';
import classNames from 'classnames';
import uuidv4 from 'uuid/v4';
import classnames from 'classnames';


/**
 * place N number of children horizontally with equal width.
 * 
 */
interface MyProps {
    className?: string;
    justifyContent: 'space-around'|'space-between';
    spacing?: string;
}

interface MyState {
    id: string;
    maxChildWidth?: number;
}

export class VEqualSize extends Component<MyProps, MyState>{
    constructor(props: MyProps){
        super(props);
        this.state = {id: uuidv4()}
    }

    render(){
        const {className, spacing} = this.props;
        const style:React.CSSProperties = {}
        
        if(spacing){
            style.paddingTop = spacing;
            style.paddingBottom = spacing;
            style.boxSizing = 'border-box';
            style.rowGap = spacing;
        }
        
        const children = getValidReactChildren(this.props.children)

        return (
            <div className={classNames('v-equal-size', className)} style={style} id={this.state.id}>
                {children.map(c=>{
                    const childStyle:React.CSSProperties = {};
                    if(this.state.maxChildWidth){
                        childStyle.width=`${this.state.maxChildWidth}px`;
                    }
                    return <div className={classnames('v-equal-size-child', {initialized: !!this.state.maxChildWidth})} 
                                style={childStyle}>{c}</div>
                })}    
            </div>
        )
    }

    checkChildrenWidth(){
        let childArray = document.getElementById(this.state.id)!.querySelectorAll(`.v-equal-size-child`);
        let maxChildWidth = 0;
        
        for(let i=0; i<childArray.length; i++){
            let c = childArray.item(i) as HTMLDivElement;
            maxChildWidth = Math.max(maxChildWidth, c.getBoundingClientRect().width);
        }
        maxChildWidth = parseFloat(maxChildWidth.toFixed(2));
        if(maxChildWidth !== this.state.maxChildWidth){
            this.setState({maxChildWidth})
        }
    }

    componentDidMount(){
        this.checkChildrenWidth();
    }
}