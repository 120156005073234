import * as React from 'react';
import {Component, Fragment} from 'react';

import './PointsInputView.scss';
import {t} from '../../i18n';
import TextField from '@material-ui/core/TextField';

interface PointsInputViewProps {
    points:string;
    error: string;
    updatePoints: (value:string)=>void;
}
export class PointsInputView extends Component<PointsInputViewProps, {}>{
    constructor(props: PointsInputViewProps){
        super(props);
    }

    updatePoints = (value:string)=>{
        let points = value.replace(/[^0-9.]/g, '');
        this.props.updatePoints(points);
    }

    render(){
        let error = this.props.error? <div className='input-error-message'>{this.props.error}</div>: null;

        return (
            <div className='points-input-view'>
                <TextField value={this.props.points} spellCheck={false} label={t('转账数量')} required
                           onChange={(e)=>{this.updatePoints(e.target.value)}}
                           className='points-input' type='number'/>
                {error}
            </div>
        )
    }
}