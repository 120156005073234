import * as React from 'react';
import {Component} from 'react';
import * as _ from 'lodash';
import { NutritionistServiceTimeInfo } from '../ajax-data/ajax-data-types';
import { FormControlLabel, Button, TextField, Checkbox, FormControl, InputLabel, Select, Grid, MenuItem} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import './service-time.scss';
import { t } from '../../i18n';
import { UButton } from '../../widget/button/ubutton';
import { calcRationClass, RatioXXS } from '../../util/Util';

interface Props {
    readonly: boolean;
    serviceTime: NutritionistServiceTimeInfo;
}

interface State {
    closed?: boolean;
}

const dayOfWeekOptions = [
    {key:1, label:'周一'},
    {key:2, label:'周二'},
    {key:3, label:'周三'},
    {key:4, label:'周四'},
    {key:5, label:'周五'},
    {key:6, label:'周六'},
    {key:0, label:'周日'},
];

const timeOfDayOptions = [
    {key:0, label:'凌晨零点'},
    {key:1, label:'凌晨一点'},
    {key:2, label:'凌晨二点'},
    {key:3, label:'凌晨三点'},
    {key:4, label:'凌晨四点'},
    {key:5, label:'早上五点'},
    {key:6, label:'早上六点'},
    {key:7, label:'早上七点'},
    {key:8, label:'上午八点'},
    {key:9, label:'上午九点'},
    {key:10, label:'上午十点'},
    {key:11, label:'上午十一点'},
    {key:12, label:'上午十二点'},
    {key:13, label:'下午一点'},
    {key:14, label:'下午二点'},
    {key:15, label:'下午三点'},
    {key:16, label:'下午四点'},
    {key:17, label:'下午五点'},
    {key:18, label:'下午六点'},
    {key:19, label:'晚上七点'},
    {key:20, label:'晚上八点'},
    {key:21, label:'晚上九点'},
    {key:22, label:'晚上十点'},
    {key:23, label:'晚上十一点'},
];

function convertServiceTimeOfDay(time: number){
    return t(timeOfDayOptions[time]? timeOfDayOptions[time].label : '');
}
function convertDayOfWeek(day: number){
    day = day % 7; // in case day is 7

    let d = dayOfWeekOptions.find(x=>x.key === day);
    return t(d? d.label:  '');
}

const _to = ` ${t('至','service-time')} `;

export function formatDayOfWeeks(days: number[]):string[]{
    let daysOfWeek = _.cloneDeep(days); // do not touch old data
    if(!daysOfWeek) return [];
    if(daysOfWeek.length === 1) return [convertDayOfWeek(daysOfWeek[0])];

    const list:string[] = [];

    // sort from monday to sunday
    daysOfWeek = daysOfWeek.map(x=> x===0? 7 : x); 
    daysOfWeek.sort((a,b)=>{return a - b});

    do{
        let day1 = daysOfWeek[0];
        let day2 = day1;
        
        for(let i=1; i<daysOfWeek.length; i++){
            const n = daysOfWeek[i];
            if(n === day2 + 1){ // n is consecutive of previous day
                day2 = n;
                if(i === daysOfWeek.length-1){
                    // reach the end of array
                    list.push(convertDayOfWeek(day1)+_to+ convertDayOfWeek(day2));
                    daysOfWeek = [];
                    break;    
                }
            }else{
                // n is not consecutive
                list.push(convertDayOfWeek(day1)+_to+ convertDayOfWeek(day2));
                daysOfWeek = daysOfWeek.slice(i);
                break;
            }
        }    
    }while(daysOfWeek.length>=2);

    if(daysOfWeek.length === 1){
        list.push(convertDayOfWeek(daysOfWeek[0]));
    }

    return list;    
}

export function convertServiceTime(serviceTime: NutritionistServiceTimeInfo){
    const {from, to, dayOfWeek, online, inOffice, notes} = serviceTime;

    return {
        online, inOffice, notes,
        time: convertServiceTimeOfDay(from)+_to+convertServiceTimeOfDay(to),
        daysOfWeek: formatDayOfWeeks(dayOfWeek||[])
    }
}

export class ServiceTime extends Component<Props, State>{
    constructor(props:Props){
        super(props);
        this.state = { }
    }

    renderCollapsed(){
        return (
        <div className='service-time'>
            <KeyboardArrowUpIcon className='expended-icon' onClick={()=>{ this.setState({closed: false})}}/>
            <div>{t('服务时间')}...</div>
            <p>{this.props.serviceTime.notes}</p>
        </div>
        )
    }

    render(){
        if(this.state.closed){
            return this.renderCollapsed();
        }


        const {ratioClass, vw,  mw, ratio} = calcRationClass();
        let xs = 6;
        if(ratio < RatioXXS){
            xs = 12;
        }

        return (
            <div className='service-time'>
                <KeyboardArrowDownIcon className='expended-icon' onClick={()=>{ this.setState({closed: true})}}/>
                <div className='day-of-time'>
                    <Grid container spacing={0}>
                        <Grid item xs={xs as 1}>
                            <FormControl className='day-of-time-form-control'>
                                <InputLabel>{t('起始时间')}</InputLabel>
                                <Select value={this.props.serviceTime.from}
                                        label={t('起始时间')} disabled={this.props.readonly}
                                        onChange={(evt)=>{
                                            this.props.serviceTime.from = parseFloat(evt.target.value as string);
                                            this.forceUpdate();
                                        }}
                                >
                                {timeOfDayOptions.map(x=>{
                                    return <MenuItem key={x.key} value={x.key}>{t(x.label, 'service-time')}</MenuItem>
                                })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={xs as 1}>
                            <FormControl className='day-of-time-form-control'>
                                <InputLabel>{t('结束时间')}</InputLabel>
                                <Select value={this.props.serviceTime.to}
                                        label={t('结束时间')} disabled={this.props.readonly}
                                        onChange={(evt)=>{
                                            this.props.serviceTime.to = parseFloat(evt.target.value as string);
                                            this.forceUpdate();
                                        }}
                                >
                                {timeOfDayOptions.map(x=>{
                                    return <MenuItem key={x.key} value={x.key}>{t(x.label)}</MenuItem>
                                })}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </div>
                <div className='day-of-week'>
                {dayOfWeekOptions.map(x=>{
                    const checked = (this.props.serviceTime.dayOfWeek||[]).some(v=> v===x.key);
                    return <FormControlLabel label={t(x.label, 'service-time')} key={x.key}
                              control={
                                  <Checkbox checked={checked} key={x.key} color='default'
                                            disabled={this.props.readonly}
                                            onChange={(evt)=>{
                                                this.props.serviceTime.dayOfWeek = this.props.serviceTime.dayOfWeek||[];
                                                const checked = this.props.serviceTime.dayOfWeek.some(v=>v===x.key);
                                                if(checked){
                                                    this.props.serviceTime.dayOfWeek = this.props.serviceTime.dayOfWeek.filter(v=>v!==x.key);
                                                }else{
                                                    this.props.serviceTime.dayOfWeek.push(x.key);
                                                }
                                                this.forceUpdate();
                                            }}
                                  />
                              }
                            />
                 })
                }
                </div>

                <div className='location'>
                    <FormControlLabel label={t('办公时间')}
                              control={
                                  <Checkbox checked={!!this.props.serviceTime.inOffice} color='default'
                                            disabled={this.props.readonly}
                                            onChange={evt=>{
                                                this.props.serviceTime.inOffice = evt.target.checked;
                                                this.forceUpdate();
                                            }}
                                  />
                              }
                    />
                    <FormControlLabel label={t('在线服务')}
                              control={
                                  <Checkbox checked={!!this.props.serviceTime.online} color='default'
                                            disabled={this.props.readonly}
                                            onChange={evt=>{
                                                this.props.serviceTime.online = evt.target.checked;
                                                this.forceUpdate();
                                            }}
                                  />
                              }
                    />                            
                </div>

                <div className='notes'>
                    <TextField variant='outlined' size='small' multiline maxRows={3} value={this.props.serviceTime.notes}
                                label={t('备注')} disabled={this.props.readonly}
                                onChange={(evt)=>{
                                    this.props.serviceTime.notes = evt.target.value;
                                    this.forceUpdate();
                                 }}
                    />
                </div>
            </div>
        )
    }
}

export function displayServiceTime(x:NutritionistServiceTimeInfo):JSX.Element {
    const svctime = convertServiceTime(x);

    return <div className='service-time-rec'>
            <div className='service-time-part'>{svctime.daysOfWeek.join(', ')}</div>
            <div className='service-time-part'>{svctime.time}</div>
            <div className='tag-section'>
            {svctime.online? <div className='service-time-part tag'>{t('在线服务')}</div>:null}
            {svctime.inOffice? <div className='service-time-part tag'>{t('办公时间')}</div>:null}     
            </div>
            <div className='service-time-part note'>{svctime.notes}</div>                                                       
        </div>
}
