import * as React from 'react';
import {Component} from 'react';
import { getCordova } from '../../globals';
import './phone-number.scss';

interface MyProps {
    phoneNumber: string;
}

declare function placeCall(number:string):void;

/**
 * simple phone number render.
 */
export class SimplePhoneNumber extends Component<MyProps, {}> {
    constructor(props:MyProps){
        super(props);
    }

    render(){
        return <div className='simple-phone-number' 
                  onClick={ (evt)=>{
                    evt.stopPropagation();
                    evt.preventDefault();
                    if(getCordova()){
                        placeCall(this.props.phoneNumber);
                    }
                  }}>
                {this.props.phoneNumber}
            </div>;
    }
}

