import { dflt_CommissionStatusOptions, dflt_countryOptionsMap, dflt_currencyOptionsMap, dflt_HealthAdviceStatusOptions, dflt_LastNDaysOptions, dflt_LicenceCategoryOptions, dflt_paymentMethodOptionsMap, dflt_ShippingRecordStatusOptions, dflt_ShoppingAddressSpecMap, dflt_ShoppingOrderStatusOptions, dflt_weightOptionsMap } from "../shopping/ajax-data/samples/sample-data";
import { getServerURL } from "../util/Util";
import axios from 'axios';
import { getAjaxErrorMessage } from "../wallet-server-api/ajax-common";
import semver from 'semver';
import { debug, info } from "../globals";

export type Translation = {[key:string]:string|Translation};

export type I18NResource = {[key:string]: {translation:Translation}};

const dflt_translations:I18NResource = {
  "en": {
    translation: {
      // generic
      "系统错误": "System Error",
      "失败": "Failed",
      " 失败": " Failed",
      " 成功": " Succeeded",
      "登录失败": "Authentication Failed",
      "返回状态码": "Status Code",
      "关闭": "Close",
      "同意": "Agree",
      "取消": "Cancel",
      "初始化": "Initialize",
      "数据出错。请联系Unishell客服": "System error. Please contact Customer Support.",
      "提交": "Submit",
      "下载数据": "Loading data",

      // index.tsx
      "验证二维码失败:": "verify QR code failed:",
      "您取消了转账操作": "You cancelled transfer operation",
      "您扫描了其他应用程序二维码": "You scanned unknown QR code",
      "扫描二维码失败": "scan QR code failed",
      "请用 U点支付 功能扫描支付二维码": "Please use Unishell Pay to scan payment QR code",
      "您取消了支付操作": "You cancelled payment operation",
      "请用 扫码转出 功能扫描转账二维码": "Plase use Scan Transfer to scan transfer QR code",
      "读取订单": "Retrieve Order",
      "您取消了转点操作": "You cancelled transfer operation",
      "您取消了Unishell Pay": "You cancelled Unishell Pay",

      // app
      "读取登录信息": "Retrieve Authentication Info",
      "需要重新登录": "Please sign in again",
      "软件更新": "Upgrade Software",
      "检测有新版软件，是否升级？": "Found new version, do you want to upgrade?",
      "错误页面": "Page Error",

      // login page
      "登录": "Sign In",
      "注册": "Register",
      "电子邮箱": "Email",
      "密码":"Password",
      "忘记密码":"Forgot Password",
      "版权所有": "Copyright",

      "电邮地址错误":"invalid email",
      "请输入密码": "missing password",
      "用户登录": "Sign In",

      // registration page
      "会员注册": "Register Membership",
      "用户名": "User Name",
      "电话":"Phone Number",
      "确认密码":"Confirm Password",
      "用户规则": "Terms & Conditions",
      "我已阅读并接受用户规则":"I have read and accept membership terms & conditions",
      "我有账号": "I have an account",

      "姓名太短":"user name is too short",
      "电话号码太短":"phone number is too short",
      "密码太短":"password is too short",
      "重复密码不匹配":"passwords do not match",
      "请阅读并接受用户规则": "Please read and accept terms & conditions",
      "返回": "Back",

      "请点击电邮里账号激活链接后，再登录": "Click account activation link in your email, then Sign In again.",
      "请点击电邮里账号激活链接" : "Click account activation link in your email",

      // forgot password
      "发送新密码链接":"send change password link",
      "请点击电邮里重设密码链接，设置新密码后，重新登录": "Click reset password link in email, then set new password, and sign in again",
      "请填写注册用电子邮箱，点击 发送邮件": "fill in sign up email, the click Send Email button",
      "发送邮件": "Send Email",
      "系统将发送重设密码链接到您的电子邮箱": "System will send reset password link email",
      "注册用电子邮箱": "Sign up Email",
      "返回登录": "Back to Sign In",

      // open url dialog
      "离开Unishell应用": "Leave 予健寰宇 App",
      "跳转到": "open link",
      "您可以关闭浏览器后返回予健寰宇应用": "You can go back to 予健寰宇 App after closing browser",

      // main menu bar
      "积分": "Wallet",
      "商业": "Business",
      "工具": "Tools",
      "我的": "Account",
      "新功能即将上线": "coming soon...",
      
      // wallet page
      "我的资产":"My Assets",
      "积分管理": "Manage Points",
      "会员充值": "Purchase Points",
      "增值服务": "Value-added Services",
      "订单记录": "Orders",
      "市场行情": "Market Trends",
      "会员积分": "Club Points",

      "WalletPageView": {
        "会员充值": "Top Up",
        "增值服务": "DigiBank",
        "市场行情": "Market",
      },
  

      // asset page
      "更新数量": "Refresh Points",

      // merchant order list
      "读订单记录": "Read merchant orders",
      "暂无订单记录": "No merchant orders yet",
      
      paymentOrderList: {
        "创建": "CREATED",
        "已付": "PAID",
        "取消": "CANCELLED",
        "过期": "EXPIRED",
        "订单记录": "Merchant Orders",
      },
      "商品": "Product",


      // receive & transfer page
      unishellPay:{
        "U点快速支付": "Quick Pay",
        "扫码支付": "Unishell Pay",
        "手动支付": "Manual Unishell Pay",  
      },
      "快速转账": "Quick Transfer",
      "扫码转出": "Scan Transfer",
      "积分转出": "Manual Transfer",
      "积分转入": "Collection",
      "交易记录": "Transactions",

      "其他资产": "Other Settlements",
      "一般转账": "Withdrawal",
      "一般接收": "Deposit",

      "请输入或复制Unishell订单号": "Please type or paste Unishell Order",
      "确认": "Confirm",

      // manual transfer
      "收方地址": "Receiver Address",
      "转账数量": "Transfer Points",
      "备注": "Notes",
      "无法核实地址": "can not verify address",
      "确认转移": "Confirm Transfer",
      "点给": "points to",
      "核实地址": "Verify Address",
      "输入登录密码": "Type SignIn Password",
      "登录密码": "Sign In Password",
      "错误地址格式":"invalid Address",
      "不能给自己发点": "can not transfer to self",
      "账户数量不够": "you have insufficient points",
      "转移数量不超过": "transfer points no more than",
      "转移数量不够": "transfer points too small",
      "错误数量格式": 'invalid points',
      "转移Unishell": "Transfer Unishell Points",

      // scan transfer
      "收方姓名": "Receiver User Name",

      // receive transfer points
      "拷贝到剪贴板": "copied to clip board",
      "拷贝失败": "copy to clip board failed",
      "请复制地址给转账方": "Copy & send address to receiver",
      "拷贝地址": "Copy Address to Clip Board",
      "请对方扫描下面的二维码": "Let receiver scan the QR code",

      // transfer list 
      "读交易记录": "Retrieve transactions",
      "暂无交易记录": "No transaction yet",
      "充值": "purchase points",
      "转账": "transfer points",
      "支付": "Unishell pay",
      "时间": "Time",
      "对方": "Other",
      "数量": "Points",
      "类型": "Type",
      " 条": " items",
      "共 ": "Total ",
      "上页": "Prev",
      "下页": "Next",
      "刷新": "Refresh",
      "页": "Page",

      // unishell pay
      "取消U点支付": "cancel Unishell pay",
      "订单": "Order",
      "商家订单":"Merchant Order",
      "商家": "Merchant",
      "商品服务":"Goods & Service",
      "U点": "Unishell Points",
      "状态": "Status",
      "取消订单": "Cancel Order",
      "订单已经被处理": "Order was cancelled",
      "U点数量不足": "Insufficient Unishell Points",
      "确认支付": "Confirm Pay",

      // purchase points
      "充值数量": "Number of Points",
      "下一步": "Next Step",
      "完成付费": "Complete Pay",
      "付费失败": "Pay Failed",
      "不支持支付宝": "Alipay Not Supported",
      "初始化支付宝": "Initialize Alipay",
      "不支持微信支付": "WechatPay Not Supported",
      "初始化微信支付": "Initialized WechatPay",
      "初始化Stripe接口": "Initialize Stripe Pay",
      "信用卡支付": "Credit Card Pay",
      "等待支付系统确认": "Waiting for payment confirmation",
      "订单号": "Order ID",
      "数值格式不对":"Invalid Number Format",
      "请输入 ": "Please input ",
      " 至 ": " to ",
      " 加元": " CAD",
      "加元": "CAD",
      "步骤1": "Step 1",
      "a) 选择充值数量 (1 UNISHELL/加元)": "a) Select Purchase Points (1 Point/CAD)",
      "b) 或输入充值数量": "b) or Type Purchase Points",
      "步骤1 充值金额(加元)": "Step 1 Purchase Points Amount(CAD)",
      "步骤2 选择付款方式": "Step 2 Select Payment Method",
      "手机未设置": "not supported in your phone",
      "支付宝": "Alipay",
      "微信支付": "Wechat Pay",
      "信用卡，Stripe":"Credit Card，Stripe",
      "步骤2 付款方式": "Step 2 Payment Method",
      "步骤3 初始化支付宝": "Step 3 initialize Alipay",
      "步骤4 调用支付宝": "Step 4 call Alipay",
      "步骤3 初始化微信支付": "Step 3 initialize Wechat Pay",
      "步骤4 调用微信支付": "Step 4 call Wechat Pay",
      "步骤3 初始化支付接口": "Step 3 initialize payment API",
      "步骤4 填写信用卡信息, 点击付费": "Step 4 fill in credit card data, Click Pay button",
      "信用卡号": "Card Number",
      "失效 月/年": "Expire Month/Year",
      "验证码": "CVV",
      "付费": "Pay",
      "用户支付": "Clicked Pay button",
      "充值成功": "Purchase Point Succeeded",
      "充值记录发送到您的电子邮箱":"Sent purchase point record email",
      "金额": "Amount",
      "支付方式": "Payment Method",
      "支付时间": "Payment Time",
      "等待支付系统确认超时": "Waiting for payment confirm timed out",

      // stripe error
      "连接出错": "Connection Error",
      "支付接口出错": "Pay API Error",
      "认证出错": "Cerrtificate Error",
      "信用卡错误": "Credit Card Error",
      "错误请求": "Invalid Request",
      "支付接口太忙": "Pay API busy",
      "信用卡验证错误": "Invalid Credit Card",
      "支付金额超出上限": "amount is greater than the maximum amount allowed",
      "支付金额低于下限": "amount is less than the minimum amount allowed",
      "接口密钥过期。请联系客服": "API key expired. Please contact customer service",
      "信用卡过期": "Card expired",
      "错误验证码": "Invalid card’s security code",
      "错误信用卡号": "Invalid card number",
      "错误信用卡失效年月": "Invalid card expire Month/Year",      
      "信用卡支付请求太忙": "Too many requests hit the API too quickly",

      // my account
      "我的账户": "My Account",
      "账户信息": "Account Settings",
      "我的证书": "My Certificates",
      "支付绑定": "Payment Methods",
      "联系客服": "Customer Service",
      "系统设置": "System Settings",
      "登出": "Sign Out",
      "上个月服务费":"Last Month Commission",
      "更新搜索引擎":"Update Product Search Engine",
      "查询用户":"Search Users",

      "更新":"Update",
      "产品":"Product",
      "术语":"Terminology",

      // manage users
      "用户名关键字":"User Name Keyword",
      "电邮关键字":"Email Keyword",
      "过滤角色":"Filter Role",
      "查询":"Search",
      "暂无符合条件用户":"No Matching Users",
      "取消营养师":"Cancel Nutrionist Role",
      "设为营养师":"Set Nutriionist Role",
      "电邮":"Email",
      searchUser:{
        "用户名":"Name",
      },
      "角色":"Role",
      "操作":"Action",
      "更新用户":"Update User",
      "读取角色列表":"Download Role List",

      // search products
      "检索产品":"Search Product",
      "我的收藏":"Product Bookmarks",
      "收藏":"Bookmark",
      "专业分类":"Professional Choice",
      "功能分类":"Functional Choice",
      "产品名称关键词":"Product Keywords",
      "关键词1 关键词2 ...":"keyword1 keyword2 ...",
      "原料名称关键词":"Ingredient keywords",
      "全文搜索":"Search Whole Document",
      "更精确匹配关键词":"More Precise Match",
      "应用范围关键词1 关键词2 ...":"Purpose Keyword1 Keyword2 ...",
      "取消收藏产品":"Unbookmark Product",
      "取消收藏":"Unbookmark",
      "下载收藏产品":"Download Bookmakred Prodcuts",
      "读取产品结构":"Download Document Spec",
      "读取术语":"Download Terminologies",

      
      // settings
      "我的账号": "Account Settings",
      "语言": "Language",
      "服务器": "Server",
      "身份": "Role",
      "系统版本": "Version",
      "设备语言": "Device Language",
      "点击升级新版软件": "Upgrade New Version",
      "检测是否有新版软件":"Check New Version",
      "会员": "Member",
      "管理员": "Admin",
      "开发者": "Developer",
      "营养师":"Nutritionist",
      "版本信息": "Version",
      "检测有新版软件": "Found New Version",

      "新版本": "New version",
      "新版本下载地址": "New version download URL",
      "使用系统浏览器下载": "Download using system default browser",
      "下载新版本": "Download new version",
      "拷贝下载地址,再用其他浏览器下载": "Copy download URL, then download it using non-system browser",
      "拷贝下载地址": "Copy download URL",
      "当前版本": "Current Version",
      "开发版升级": "Upgrade Developer Release",
      "应用升级": "Check New Release",
      "检测到新版本": "Found New Release",
      "没有检测到新版本": "Didn't Found New Release",
      "检测到新版软件": "Found New Developer Release",

      // index.js
      "正在下载中": "Downloading ...",
      "暂无更新": "No upgrade yet",
      "应用管理": "Manage App",
      "无法查询应用更新状态,请检查网络连接.": "Can not check app upgrade. You may need to check network connection.",
      "下载完毕，即将切换重新启动到新版本.": "Downloaded upgrade. will switch to new version",
      "查询更新...": "checking if there is app upgrade ...",
      "无相机访问权限": "No camera access permission",
      "请把二维码调整到扫描区域内":"put QR code inside scanning region",
      "未能检测到设备，请稍后再试": "Found no mobile device. try later",
      "无有效网络连接": "no network connection",
      "无法清除旧版数据": "can not clean data of old version",    
      
      // shopping
      "菜单":"Menu",
      "恩及商城": "NaturalZenith",
      "商城":"Shopping",


      iso_country:{
          CHN: 'China',
          CAN: 'Canada',
          HKG: 'Hong Kong',
          MAC: 'Macao',
          TWN: 'Taiwan',
          USA: 'USA'        
      },
      apply_health_advice_msg:
      `You will be connected to Natural Zenith, a professional mall that sells wellness care related products operated by General Nutrition Group of Canada.\n
       Please check shopping cart to see the details. If you want to know more about the quality, price, logistics and other information of the
       products, please contact the customer service of the Mall.`,

      login: {
            annoucement: `Welcome to Unishell, a unique platform to
            connect wellness care service and professional
            products.`
      },
      delete_account_msg:
     `<!DOCTYPE html>
      <html>
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
      <body>
          <h2 style="text-align: center;">注销须知</h2>
          <p>尊敬的会员：</p>
          <p>当您点击注销账户之前，您需要了解以下事项。</p>
          <ol>
              <li>点击注销账户后，您将无法再登录系统。</li>
              <li>请在注销账户之前兑换剩余的积分。</li>
              <li>请在注销账户之前检查是否有未完成的订单。</li>
              <li>请在注销账户之前确认是否尚有其他未完成事项。</li>
              <li>您的信息将在系统里面保留30个工作日。以便我们可以
                  处理您可能的未尽事宜。之后系统中关于您的信息将会
                  被彻底删除。</li>
              <li>您注销账户后，如有任何问题请联络客服处理。</li>
          </ol>
      </body>
      </html>`,
    }
  },
  "zh-CN":{
      translation: {
            iso_country:{
                CHN: '中国大陆',
                CAN: '加拿大',
                HKG: '香港',
                MAC: '澳门',
                TWN: '台湾',
                USA: '美国'          
            },
            sex: {
                'male': "男",
                'female': "女"
            },
            shop_order_status: {
                CREATED: '创建',
                PAYMENT_PENDING: '付费中',
                PAYMENT_REJECTED: '付费失败',
                PAID: '已付费',
                SHIPPED: '已发货',
                READY_FOR_PICKUP: '可以取件',
                PICKED_UP: '已取件',
                CANCELLED: '取消',
                EXPIRED: '订单失'
            },
            apply_health_advice_msg: 
            `您将被连接到恩及商城，这是由独立第三方加拿大全息营养集团营运的，专业销售健康管理相关产品的线上商城。当您决定执行健康建议后，商城的系统会根据营养师开具的功效成分自动转化为一个产品订单放进购物车里，请去商城购物车中查看详情。\n
            如果您希望了解有关产品的质量、价格、物流等信息，请联络商城客服。`,
            
            login: {
                annoucement: `予健寰宇，您的专属健康管理服务平台。`
            },
            delete_account_msg:
            `<!DOCTYPE html>
             <html>
             <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
             <body>
                 <h2 style="text-align: center;">注销须知</h2>
                 <p>尊敬的会员：</p>
                 <p>当您点击注销账户之前，您需要了解以下事项。</p>
                 <ol>
                     <li>点击注销账户后，您将无法再登录系统。</li>
                     <li>请在注销账户之前兑换剩余的积分。</li>
                     <li>请在注销账户之前检查是否有未完成的订单。</li>
                     <li>请在注销账户之前确认是否尚有其他未完成事项。</li>
                     <li>您的信息将在系统里面保留30个工作日。以便我们可以
                         处理您可能的未尽事宜。之后系统中关于您的信息将会
                         被彻底删除。</li>
                     <li>您注销账户后，如有任何问题请联络客服处理。</li>
                 </ol>
             </body>
             </html>`,           
      }
  },
};

export const LANG_CODE_ZH_CN = 'zh-CN';
export const LANG_CODE_EN = 'en';

export interface LanguageOption {
    code: string;
    /** label in native language */
    label:string;
}
const dflt_LanguageOptions:LanguageOption[] = [
    {
        code:LANG_CODE_ZH_CN,
        label:'简体中文',
    },
    {
        code:LANG_CODE_EN,
        label:'English',
    }
];

// https://en.wikipedia.org/wiki/List_of_ISO_639-3_codes
const dflt_spokenLanguageOptions = [
    {key:'eng', label:'English/英语'},
    {key:'cmn', label:'国语'},
    {key:'yue', label:'粤语'},
    {key: "nan", label: "闽南语"},
    {key: "wuu", label: "上海话"},
    {key:'fra', label:'français'}
]


const __dflt_constants = {
    version: '3.0.3',
    translations: dflt_translations,
    languageOptions: dflt_LanguageOptions,
    currencyOptionsMap: dflt_currencyOptionsMap,
    paymentMethodOptionsMap: dflt_paymentMethodOptionsMap,
    weightOptionsMap: dflt_weightOptionsMap,
    countryOptionsMap: dflt_countryOptionsMap,
    shoppingAddressSpecMap: dflt_ShoppingAddressSpecMap,
    healthAdviceStatusOptions: dflt_HealthAdviceStatusOptions,
    shoppingOrderStatusOptions: dflt_ShoppingOrderStatusOptions,
    lastNDaysOptions: dflt_LastNDaysOptions,
    commissionStatusOptions: dflt_CommissionStatusOptions,
    shippingRecordStatusOptions: dflt_ShippingRecordStatusOptions,
    spokenLanguageOptions: dflt_spokenLanguageOptions,
    licenceCategoryOptions:dflt_LicenceCategoryOptions,
}

// info(`dflt_constants`);
// info(JSON.stringify(__dflt_constants, null, 4));


export const ConstantStaleTimeout = 1000*60*10;

/** constants to be used */
let __externConstants: typeof __dflt_constants | undefined;
/** constants fetched from server */
let __data: typeof __dflt_constants | undefined;
/** modified-since returned from server */
let __modifiedSince = '';
/** timestamp of last loadExternConstants call */
let __lastCallTimestamp = 0;

/**
 * get external constants that is is in memory at the moment.
 */
export function getExternContantsInMemory(){
    return __externConstants || __dflt_constants;        
}

export async function loadExternContatnsIfNecessary(){
    let currtimestamp = Date.now();
    if(true || currtimestamp - __lastCallTimestamp > ConstantStaleTimeout){
        await loadExternConstants(__modifiedSince, 
                                  (data, newModifiedSince)=>{
                                        // debug(data);
                                        if(semver.lt(getExternContantsInMemory().version, data.version)){
                                            debug(`update newer version of constants`);
                                            // data is newer version
                                            __externConstants = data;
                                        }
                                        debug(`newModifiedSince=${newModifiedSince}`);
                                        __data = data;                            
                                        __modifiedSince = newModifiedSince;
                                  }
                                );
        __lastCallTimestamp = currtimestamp;
    }
    return __externConstants || __dflt_constants;
}

let __loadStartTime = 0;

async function loadExternConstants (modifiedSince:string, 
                                    hasData: (data:typeof __dflt_constants, newModifiedSince:string)=> void) 
{
    const currTimestamp = Date.now();
    if(currTimestamp - __loadStartTime < 1000*60){
        debug(`skip redundant loadExternConstants`);
        return;
    }

    const serverURL = getServerURL();
    const header = modifiedSince?
                    {
                        headers: {
                            "If-Modified-Since":modifiedSince
                        }
                    }: undefined;

    try{
        __loadStartTime = currTimestamp;
        const response = await axios.get(`${serverURL}/server/v1/file/unishell-constants.json`, header);
        if(response.status === 200 && response.data){
            // todo: verify response.data
            const data = response.data as typeof __dflt_constants;
            for(let prop of ["version", "translations"]){
                // @ts-ignore
                if(!data[prop]){
                    console.error(response);
                    throw Error(`unishell-constants.json missing ${prop}`);
                }
            } 
            hasData(data, response.headers['last-modified']);
        }                                            
    }catch(error){
        // @ts-ignore
        if(error && error.response && error.response.status === 304){
            // no change since last read
        }else{
            const errorMessage = getAjaxErrorMessage(error);
            // alert('Read app settings:'+errorMessage);
            // ignore it for now
            debug(`loadExternConstants:`+errorMessage);    
        }
    }finally{
        __loadStartTime = 0;
    }
}

debug(`init extern-constants.ts`);
// load initial external contants
loadExternContatnsIfNecessary();