import * as React from 'react';
import {Component, Fragment} from 'react';
import { t } from '../../i18n';
import { BaseShoppingPageProps, } from '../common/shopping-page-common';
import './product-detail.scss';
import {doAJAXCall} from '../common/ajax-call';
import { NoticeDialog } from '../../view/NoticeDialog';
import { addProductToShoppingCard, retreiveShoppingProductDetail } from '../ajax-data/shopping-server-api';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { connect } from 'react-redux';
import { WalletAppState } from '../../redux-store';
import { BaseProductDetail } from './base-product-detail';
import { appendIngredientTabData, getProductCode, ShoppingProductDetail } from '../ajax-data/ajax-data-types';
import { Toast } from '../../view/Toast';
import { loadExternContatnsIfNecessary } from '../../external-constants/extern-constants';
import { gotoShoppingPathNSavePrevPath } from '../history/shopping-history';
import { debug } from '../../globals';
import { getAjaxErrorMessage, is404Error } from '../../wallet-server-api/ajax-common';
import { BaseUnishellPageState, renderInitErrorUnishellPage, renderInitUnishellPage, renderUnishellPage } from '../../unishell-page-common/unishell-page-common';
import { isNutritionist } from '../../api/user';
import { UButton } from '../../widget/button/ubutton';
import { store } from '../..';
import _ from 'lodash';
import { setShopUserInfoRecord } from '../../redux-store/shop-user';

interface ProductDetailProps extends BaseShoppingPageProps {
    languageCode: string;
    currencyCode:string;
    productCode:string;
}

interface ProductDetailState extends BaseUnishellPageState {
    product?: ShoppingProductDetail;
    showImageOverlay?: boolean;
    count:number;
}

class ShoppingProductDetailView extends Component<ProductDetailProps,ProductDetailState>{
    private errorDialog:NoticeDialog|undefined;
    private toast: Toast | undefined;

    constructor(props:ProductDetailProps){
        super(props);
        this.state = {pageInitState:'PAGE_INIT',count:1}
    }

    getTitle(){
        return t('产品细节');
    }
    render(){
        if(this.state.pageInitState === 'PAGE_INIT'){
            return <Fragment>
                        {renderInitUnishellPage({title: this.getTitle(), message:t('初始化...')})}
                        <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                        <Toast close={true} duration={2500} message='' ref={e=>{this.toast=e!}} toastType='info'/>
                    </Fragment>
        }
        if(this.state.pageInitState === 'PAGE_LOAD_FAILED'){
            return <Fragment>
                        {renderInitErrorUnishellPage({title: this.getTitle(), 
                                                      errorMessage: this.state.initErrorMessage||t('初始化失败'),
                                                      onTryAgain: ()=>{ this.initializeAgain();    }
                                                      })}
                        <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                        <Toast close={true} duration={2500} message='' ref={e=>{this.toast=e!}} toastType='info'/>
                    </Fragment>
        }
        // loading data succeeded
        let content = this.renderSucceedContent();
        return (
            <React.Fragment>
                <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                <Toast close={true} duration={2500} message='' ref={e=>{this.toast=e!}} toastType='info'/>
                { renderUnishellPage({title: this.getTitle(), className:'shopping-product-detail', content})}
            </React.Fragment>
        )
    }

    addToShoppingCard(){
        doAJAXCall(
            {
                whatIsFor: t('添加到购物车'),
                errorDialog: this.errorDialog!,
                ajaxCall: async (accessToken, username)=>{
                    let shoppingCart = await addProductToShoppingCard(accessToken, 
                                                                      username, 
                                                                      getProductCode(this.state.product!), 
                                                                      this.state.count,
                                                                      this.props.currencyCode);
                    let shopUserInfo = _.cloneDeep(store.getState().shopUserInfo);
                    shopUserInfo.shoppingCartItemCount = shoppingCart.unitCount;
                    store.dispatch(setShopUserInfoRecord(shopUserInfo));
                                                                                                              
                    this.toast!.sendMessage(t('购物车')+t(`商品数量`)+':'+shoppingCart.unitCount, 'success');
                }
            }
        )
    }
    renderSucceedContent():JSX.Element {
        const product = this.state.product!;

        const actions = (
            !!product.requireHealthAdviceMessage ? 
                <div className='health-advice'>
                    <p className='health-advice-message'>{product.requireHealthAdviceMessage}</p>
                    <UButton className='my-nutritionist' size='small' variant='contained' 
                            color='primary'
                            onClick={()=>{
                                gotoShoppingPathNSavePrevPath('main/tools/my-nutritionist');
                            }}
                    >
                        {t('我的执业营养师')}                                
                    </UButton>
                </div>:
                <div className='add-to-shopping-cart'>
                    <label>{t('数量')}&nbsp;</label> 
                    <RemoveIcon onClick={()=>{
                        this.setState({count: Math.max(1, this.state.count-1)})
                    }}/>
                    <input id='num-of-products-added-to-shopping-cart' type='number' value={this.state.count} disabled={true}/> 
                    <AddIcon onClick={()=>{
                        this.setState({count: Math.min(999, this.state.count+1)})
                    }}/>
                    <UButton size='small' variant='contained' color='primary'
                            onClick={()=>{ 
                                this.addToShoppingCard() 
                            }}
                    >{t('添加到购物车')}</UButton>
                </div>
            )

        return (
            <Fragment>
                <BaseProductDetail languageCode={this.props.languageCode} currencyCode={this.props.currencyCode}
                                  product={this.state.product!} highlightWords={[]}>
                    {actions}
                </BaseProductDetail>
                <div className='actions-wrapper'>
                    {actions}
                </div>
            </Fragment>
        )
    }

    loadData(){
        debug(`product detail: reload product detail`);
        doAJAXCall({
            whatIsFor: t('下载产品细节'),
            errorDialog: this.errorDialog!,
            errorMessageFromatter: (error:any):string|JSX.Element => {
                if(is404Error(error)){
                    return t('没有找到该产品')+':'+this.props.productCode;
                }else{
                    return getAjaxErrorMessage(error);
                }
            },
            ajaxCall: async (accessToken)=>{ 
                await loadExternContatnsIfNecessary();
                let product = await retreiveShoppingProductDetail(accessToken, 
                                                                this.props.productCode, 
                                                                this.props.currencyCode);
                appendIngredientTabData(product, isNutritionist());

                this.setState({product, pageInitState:'PAGE_LOAD_SUCCESS'});
            },
            onError: (errorMessage:string)=>{ this.setState({pageInitState:'PAGE_LOAD_FAILED', initErrorMessage:errorMessage})}                                           
        })
    }

    componentDidMount(){
        this.loadData();
    }

    initializeAgain(){
        this.setState({pageInitState:'PAGE_INIT', initErrorMessage:undefined},
                      ()=>{
                          this.loadData();
                      })
    }

    componentDidUpdate(){
        if(this.state.pageInitState==='PAGE_LOAD_SUCCESS' && this.state.product){
            if(this.state.product.currencyCode !== this.props.currencyCode){
                this.loadData();
            }
        }
    }
}

export const ShoppingProduct = connect(
    (state:WalletAppState) => ({languageCode: state.language.langCode, currencyCode: state.currency.currencyCode})
)(ShoppingProductDetailView);