import * as React from 'react';
import {Component} from 'react';
import {ShoppingCartHealthAdvice, ShoppingCartInfo, ShoppingCartItemInfo, ShoppingCartProduct, ShoppingCheckoutResponse, ShoppingOrderSummary, ProductCandidateBrief, WeightOption} from '../ajax-data/ajax-data-types';
import classNames from 'classnames';
import './render-order-items.scss';
import { t } from '../../i18n';
import { formatPrice, RatioXXS } from '../../util/Util';
import { Button, Checkbox, TextField } from '@material-ui/core';
import * as _ from 'lodash';
import { PropertyListDataItem, PropertyListWidget } from '../../widget/property-list/property-list';
import { gotoLoginPage, gotoShoppingPathNSavePrevPath } from '../history/shopping-history';
import { UButton } from '../../widget/button/ubutton';

class RenderShoppingProductBrief extends Component<{
                                                        shoppingCartItem:ShoppingCartItemInfo,
                                                        oneProduct:ProductCandidateBrief,
                                                        currencySymbol: string, currencyLabel:string, weightOptions:WeightOption[], 
                                                        skipAction:boolean,
                                                        // updateItemCount?: (shoppingCartItem:ShoppingCartItemInfo)=>void,
                                                    },{}>{
    constructor(props:any){
        super(props)
    }

    render(){
        const {currencySymbol, currencyLabel, weightOptions, skipAction, oneProduct} = this.props;
        const productProps:PropertyListDataItem[] = [
            {name: t('名称'), value: oneProduct.productName, valueClassName:'product-name'},
            {
                name: t('编号'), 
                value: <a href='#' onClick={(evt)=>{
                            evt.stopPropagation();
                            evt.preventDefault();
                            gotoShoppingPathNSavePrevPath('main/shopping/product',{productCode: oneProduct.productCode})
                        }}>{oneProduct.productCode}</a>
            },
        ];
        if(oneProduct.originalPrice){
            productProps.push({
                name: t('原价'), 
                value: formatPrice(oneProduct.originalPrice, currencySymbol, currencyLabel),
                valueClassName: 'original-price price',
            })
        }
        productProps.push({
            name: t('单价'), 
            valueClassName: 'price',
            value: formatPrice(oneProduct.price, currencySymbol, currencyLabel)
        });
        productProps.push({
            name: t('数量'), 
            value: oneProduct.count+''
        });

        return (
            <div className={classNames('shopping-cart-product', {standalone: !skipAction})}>
                <div className='product-image'>
                    <img src={oneProduct.thumbImage}></img>
                </div>
                <div className='product-summary'>
                    <PropertyListWidget list={productProps} className='product-summary-table'
                                        mergeColScreenLetterRatio={RatioXXS}/>
                </div>
                {this.props.children}
            </div>
        )
    
    }
}


export function RenderSingleShoppingCartProduct(args:{shoppingCartItem:ShoppingCartItemInfo,
                                            currencySymbol: string, 
                                            currencyLabel:string, 
                                            weightOptions:WeightOption[], 
                                            skipAction:boolean,
                                            updateItemCount?: (shoppingCartItem:ShoppingCartItemInfo)=>void,
                                        }):JSX.Element{
    const {currencySymbol, currencyLabel, weightOptions, skipAction, shoppingCartItem, updateItemCount} = args;
    const oneProduct = shoppingCartItem.product[0];                                                   
                                        

    return <RenderShoppingProductBrief shoppingCartItem={shoppingCartItem}
                                        currencySymbol={currencySymbol}
                                        currencyLabel={currencyLabel}
                                        weightOptions={weightOptions}
                                        skipAction={skipAction}
                                        oneProduct={oneProduct}

           >
            {!skipAction ? <div className='product-actions'>
                                <UButton variant='outlined' color='danger' size='small'
                                        onClick={()=>{
                                            if(args.updateItemCount){
                                                let newItem = _.cloneDeep(shoppingCartItem);
                                                newItem.count = 0;
                                                args.updateItemCount(newItem);
                                            } 
                                        }}
                                >{t('删除')}</UButton>

                                <UButton variant='outlined' color='default' size='small'
                                        onClick={()=>{
                                            if(args.updateItemCount){
                                                let newItem = _.cloneDeep(shoppingCartItem);
                                                newItem.count = newItem.count+1;
                                                args.updateItemCount(newItem);
                                            } 
                                        }}
                                >{t('增加')}</UButton>

                                <UButton variant='outlined' color='default' size='small'
                                        onClick={()=>{
                                            if(args.updateItemCount){
                                                let newItem = _.cloneDeep(shoppingCartItem);
                                                newItem.count = Math.max(0, newItem.count-1);
                                                args.updateItemCount(newItem);
                                            } 
                                        }}
                                >{t('减少')}</UButton>

                            </div>: null
            }
            </RenderShoppingProductBrief>;                                            
}

export function RenderShoppingCartHealthAdvice2(args:{
                                                        shoppingCartItem:ShoppingCartItemInfo,
                                                        currencySymbol: string, 
                                                        currencyLabel:string, 
                                                        weightOptions:WeightOption[], 
                                                        updateItemCount?: (shoppingCartItem:ShoppingCartItemInfo)=>void,
                                                    }){
    const {currencySymbol, currencyLabel, weightOptions, shoppingCartItem} = args;

    // this table is responsive
    return (
        <div className='shopping-cart-health-advice'>
            <table className='shopping-cart-health-advice-table'>
                <tr className='health-card-code'>
                    <td>
                    {t('健康建议代码')} {shoppingCartItem.prescriptionCode!}
                    </td>
                </tr>
                {shoppingCartItem.product.map(p=>{
                    return <tr>
                                <RenderShoppingProductBrief shoppingCartItem={shoppingCartItem}
                                        currencySymbol={currencySymbol}
                                        currencyLabel={currencyLabel}
                                        weightOptions={weightOptions}
                                        skipAction={true}
                                        oneProduct={p}
                                />
                           </tr>
                })
                }
                <tr className='health-advice-actions'>
                {args.updateItemCount?
                    <UButton variant='outlined' color='danger' size='small'
                        onClick={()=>{
                            if(args.updateItemCount){
                                let newItem = _.cloneDeep(shoppingCartItem);
                                newItem.count = 0;
                                args.updateItemCount(newItem);
                            } 
                        }}>{t('删除')}
                    </UButton> : null             
                }
                </tr>
            </table>
        </div>
    )

}


export function RenderShoppingCardSummary(args:{summary:ShoppingCartInfo,
                                                currencySymbol: string, 
                                                currencyLabel:string, 
                                                weightOptions:WeightOption[]
                                        }):JSX.Element{
    const {summary, currencySymbol, currencyLabel, weightOptions} = args;                                                   
    // let weightOption = weightOptions.find(x=>x.code === summary.weightCode);
    // let weightLabel = weightOption ? weightOption.label : '';

    const summaryProps: PropertyListDataItem[] = [
        {name: t('商品数量'), value: summary.unitCount},
        {name: t('小计'), valueClassName:'price', value: formatPrice(summary.totalPrice, currencySymbol, currencyLabel)}
    ];

    return <PropertyListWidget list={summaryProps} showBorder={true} className='shopping-cart-summary'/>
}


export function RenderShoppingOrderSummary(args:{summary:ShoppingCheckoutResponse,
                                                 currencySymbol: string, currencyLabel:string,
                                                 getCurrencyAndWeightOptionFn: (overrideCurrency?: string)=>{currencySymbol:string,currencyLabel:string}
                                                }):JSX.Element {
    const {summary, currencySymbol, currencyLabel, getCurrencyAndWeightOptionFn} = args;                                                   

    const summaryProps:PropertyListDataItem[] = [
        {name: t('小计'), valueClassName:'price', value: formatPrice(summary.shoppingCart.totalPrice, currencySymbol, currencyLabel)}
    ];
    summary.fee && summary.fee.forEach((x, idx)=>{
        const cwo = getCurrencyAndWeightOptionFn(x.currencyCode);
        summaryProps.push({
            name: x.name,
            value: formatPrice(x.amount, cwo.currencySymbol, cwo.currencyLabel),
            valueClassName: 'fee'
        })
    });
    summary.discount && summary.discount.forEach((x, idx)=>{
        const cwo = getCurrencyAndWeightOptionFn(x.currencyCode);
        summaryProps.push({
            name: x.discount,
            value: '-'+formatPrice(x.amount, cwo.currencySymbol, cwo.currencyLabel),
            valueClassName: 'discount'
        })
    });
    summaryProps.push({
        name: t('合计'),
        value: formatPrice(summary.finalPrice, currencySymbol, currencyLabel),
        valueClassName: 'price'
    })

    return (
        <div className='shopping-cart-summary'>
            <PropertyListWidget list={summaryProps} showBorder={true}/>
        </div>
    )
}

/**
 * 
 * @deprecated
 */
export function RenderUseCoupon(args:{couponCode?: string, useCoupon?: boolean}):JSX.Element {
        return (
            <div className='use-coupon'>
                <TextField size='small' placeholder={t('优惠券代码')} className='coupon-input' variant='outlined'
                           value={args.couponCode}></TextField>
                <div>
                    <Checkbox checked={args.useCoupon} 
                            color='primary'></Checkbox> 
                    <label>{t('使用优惠券')}</label>                
                </div>                           
            </div>
        )

}