import * as React from 'react';
import {Component} from 'react';
import { Points } from '../../container/Points';
import { loadPrivateKey, signMessage } from '../../cypto/crypto';
import { t } from '../../i18n';
import { readLastLoginEmail, readLoginSession, readPersistData,  } from '../../local-storage/local-storage';
import { encryptPayment, genPaymentKey, round } from '../../util/Util';
import { setShopUserInfo } from '../../view/header/HeaderView2';
import { NoticeDialog } from '../../view/NoticeDialog';
import { PasswordDialogView } from '../../view/password-dialog/PasswordDialogView';
import { Toast } from '../../view/Toast';
import { UButton } from '../../widget/button/ubutton';
import { CurrencyOption, PaymentMethodOption, ShopPaymentRequestData} from '../ajax-data/ajax-data-types';
import { ShoppingUnisPay } from '../ajax-data/shopping-server-api';
import { doAJAXCall } from '../common/ajax-call';
import { gotoLoginPage, gotoShoppingPathNSavePrevPath } from '../history/shopping-history';
import { PaymentSuccess } from './payment-success';
import './unis-payment.scss';

interface UnisPaymentProps {
    languageCode: string;
    currencyOptionsMap: {[key:string]:CurrencyOption[]};
    paymentMethodOptionsMap:{[key:string]:PaymentMethodOption[]};
    paymentData: ShopPaymentRequestData;
}

interface UnisPaymentState {
    paymentDone: boolean;
    orderId?: string;
}

export class UnisPayment extends Component<UnisPaymentProps, UnisPaymentState>{
    private toast: Toast | undefined;
    private passwordDialog: PasswordDialogView | undefined;
    private errorDialog: NoticeDialog|undefined;

    constructor(props: UnisPaymentProps){
        super(props);
        this.state = {paymentDone: false}
    }

    clickPay =  () => {
        this.passwordDialog!.show(<div>{t(`确认支付`)+' '}{round(this.props.paymentData.totalAmount,2)}{t('点')}
                                    
                                  </div>);
    }
    handleConfirmation = (password:string, confirmed:boolean)=>{
        if(confirmed){
            const session = readLoginSession();
            if(!session){
                gotoLoginPage();
                return;
            }
            const paymentKey = genPaymentKey(readLastLoginEmail()!.toLowerCase()+password);
            
            const nonce = ''+Math.random();
            this.props.paymentData.timestamp = Date.now();
            this.props.paymentData.nonce = nonce;
            // const payDetail:ShoppingUnisPayDetail = {amount: this.props.order.summary.total,  time:Date.now(), nonce};
            const localData = readPersistData(session!.profile!.username);
            if(!localData){
                this.toast!.sendMessage(t('数据出错。请联系Unishell客服'), 'error', false);
                return;
            }
            doAJAXCall({
                whatIsFor:t('U点支付'),
                errorDialog: this.errorDialog!,
                ajaxCall: async (accessToken)=>{
                    let keyManager = await loadPrivateKey(localData!.keyPair.priKey);
                    let {signedMessage} = await signMessage(keyManager, this.props.paymentData);
                    let encryptedData = encryptPayment(signedMessage, paymentKey);
                    let response = await ShoppingUnisPay(session.token!.accessToken, 
                                                    {keyId: localData.pkeyHash, data: encryptedData}); 
                    await setShopUserInfo();                                                    
                    this.setState({paymentDone: true, orderId: response.orderCode});
                }
            })
        }
    }

    render(){
        if(this.state.paymentDone){
            return (
                <div className='unis-payment'>
                    <PaymentSuccess languageCode={this.props.languageCode} amount={this.props.paymentData.totalAmount}
                                    currencyCode='UNIS'
                                    orderId={this.state.orderId!}
                                    currencyOptionsMap={this.props.currencyOptionsMap}
                                    paymentMethodCode='Wallet'
                                    paymentMethodOptionsMap={this.props.paymentMethodOptionsMap} 
                    />
                    <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                </div>
            )
        }
        return (
            <div className='unis-payment'>
                <div className='header'>
                    <h3>{t('U点支付')}</h3>
                </div>
                <Points/>
                <table className='order-info'>
                    <tbody>
                        <tr>
                            <td className='label'>{t('金额')}</td>
                            <td className='amount'>{this.props.paymentData.totalAmount.toFixed(2)} {t('U点')}</td>
                        </tr>
                    </tbody>
                </table>
                <div className='action-buttons'>
                    <UButton className='pay-btn' variant='contained' color='primary' onClick={()=>{this.clickPay()}}>{t('支付')}</UButton>
                    <UButton variant='contained' color='secondary' id='shopping-button'
                            onClick={()=>{
                                gotoShoppingPathNSavePrevPath('main/shopping/search', {});
                            }}
                    >{t('继续购物')}</UButton>
                </div>

                
                <Toast close={true} message='' toastType='info' onClose={()=>{}} ref={(e)=>{this.toast=e!}} duration={2500}/>

                <PasswordDialogView confirmLabel={t('同意')} cancelLabel={t('取消')} 
                                    onClose={this.handleConfirmation}
                                    ref={e=>{this.passwordDialog=e!}} 
                />
                <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
            </div>
        )
    }
}