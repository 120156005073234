// Generated by IcedCoffeeScript 108.0.11
(function () {
  var Decryptor,
      K,
      P3SKB,
      Packet,
      SHA512,
      box,
      bufeq_secure,
      bufferify,
      iced,
      make_esc,
      native_rng,
      pack,
      rsa,
      triplesec,
      __iced_k,
      __iced_k_noop,
      _ref,
      _ref1,
      __hasProp = {}.hasOwnProperty,
      __extends = function __extends(child, parent) {
    for (var key in parent) {
      if (__hasProp.call(parent, key)) child[key] = parent[key];
    }

    function ctor() {
      this.constructor = child;
    }

    ctor.prototype = parent.prototype;
    child.prototype = new ctor();
    child.__super__ = parent.prototype;
    return child;
  };

  iced = require('iced-runtime');

  __iced_k = __iced_k_noop = function __iced_k_noop() {};

  K = require('../../const').kb;
  triplesec = require('triplesec');
  SHA512 = require('../../hash').SHA512;
  Decryptor = triplesec.Decryptor;
  native_rng = triplesec.prng.native_rng;
  Packet = require('./base').Packet;
  _ref = require('../encode'), pack = _ref.pack, box = _ref.box;
  make_esc = require('iced-error').make_esc;
  rsa = require('../../rsa');
  _ref1 = require('../../util'), bufeq_secure = _ref1.bufeq_secure, bufferify = _ref1.bufferify;

  P3SKB = function (_super) {
    __extends(P3SKB, _super);

    P3SKB.tag = function () {
      return K.packet_tags.p3skb;
    };

    P3SKB.prototype.tag = function () {
      return P3SKB.tag();
    };

    function P3SKB(_arg) {
      var priv, priv_clear;
      this.pub = _arg.pub, priv_clear = _arg.priv_clear, priv = _arg.priv, this.type = _arg.type;

      P3SKB.__super__.constructor.call(this);

      this.priv = priv != null ? priv : priv_clear != null ? {
        data: priv_clear,
        encryption: K.key_encryption.none
      } : void 0;
    }

    P3SKB.prototype.get_packet_body = function () {
      var ret;
      ret = {
        pub: this.pub,
        priv: this.priv
      };

      if (this.type != null) {
        ret.type = this.type;
      }

      return ret;
    };

    P3SKB.prototype.lock = function (_arg, cb) {
      var asp, ct, err, passphrase_generation, tsenc, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      asp = _arg.asp, tsenc = _arg.tsenc, passphrase_generation = _arg.passphrase_generation;
      (function (_this) {
        return function (__iced_k) {
          __iced_deferrals = new iced.Deferrals(__iced_k, {
            parent: ___iced_passed_deferral,
            filename: "/Users/max/src/keybase/kbpgp/src/keybase/packet/p3skb.iced",
            funcname: "P3SKB.lock"
          });
          tsenc.run({
            data: _this.priv.data,
            progress_hook: asp != null ? asp.progress_hook() : void 0
          }, __iced_deferrals.defer({
            assign_fn: function () {
              return function () {
                err = arguments[0];
                return ct = arguments[1];
              };
            }(),
            lineno: 30
          }));

          __iced_deferrals._fulfill();
        };
      })(this)(function (_this) {
        return function () {
          if (typeof err === "undefined" || err === null) {
            _this.priv.data = ct;
            _this.priv.encryption = K.key_encryption.triplesec_v3;

            if (passphrase_generation != null) {
              _this.priv.passphrase_generation = passphrase_generation;
            }
          }

          return cb(err);
        };
      }(this));
    };

    P3SKB.prototype.unlock = function (_arg, cb) {
      var a, asp, b, dec, err, passphrase_generation, progress_hook, raw, tsenc, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      asp = _arg.asp, tsenc = _arg.tsenc, passphrase_generation = _arg.passphrase_generation;
      (function (_this) {
        return function (__iced_k) {
          switch (_this.priv.encryption) {
            case K.key_encryption.triplesec_v3:
            case K.key_encryption.triplesec_v2:
            case K.key_encryption.triplesec_v1:
              dec = new Decryptor({
                enc: tsenc
              });
              progress_hook = asp != null ? asp.progress_hook() : void 0;

              (function (__iced_k) {
                __iced_deferrals = new iced.Deferrals(__iced_k, {
                  parent: ___iced_passed_deferral,
                  filename: "/Users/max/src/keybase/kbpgp/src/keybase/packet/p3skb.iced",
                  funcname: "P3SKB.unlock"
                });
                dec.run({
                  data: _this.priv.data,
                  progress_hook: progress_hook
                }, __iced_deferrals.defer({
                  assign_fn: function () {
                    return function () {
                      err = arguments[0];
                      return raw = arguments[1];
                    };
                  }(),
                  lineno: 42
                }));

                __iced_deferrals._fulfill();
              })(function () {
                dec.scrub();
                return __iced_k(typeof err === "undefined" || err === null ? (_this.priv.data = raw, _this.priv.encryption = K.key_encryption.none) : (a = passphrase_generation) != null && (b = _this.priv.passphrase_generation) != null && a !== b ? err = new Error("Decryption failed, likely due to old passphrase (wanted v" + a + " but got v" + b + ") [" + err.toString() + "]") : void 0);
              });

              break;

            case K.key_encryption.none:
              return __iced_k();

            default:
              return __iced_k(err = new Error("Unknown key encryption type: " + k.encryption));
          }
        };
      })(this)(function (_this) {
        return function () {
          return cb(err);
        };
      }(this));
    };

    P3SKB.alloc = function (_arg) {
      var body, tag;
      tag = _arg.tag, body = _arg.body;

      if (tag === P3SKB.tag()) {
        return new P3SKB(body);
      } else {
        throw new Error("wrong tag found: " + tag);
      }
    };

    P3SKB.prototype.has_private = function () {
      return this.priv != null;
    };

    P3SKB.prototype.is_locked = function () {
      return this.priv.encryption !== K.key_encryption.none;
    };

    P3SKB.prototype.get_private_data = function () {
      var _ref2;

      return (_ref2 = this.priv) != null ? _ref2.data : void 0;
    };

    P3SKB.prototype.get_public_data = function () {
      return this.pub;
    };

    P3SKB.prototype.get_key_type = function () {
      return this.type;
    };

    P3SKB.prototype.is_p3skb = function () {
      return true;
    };

    return P3SKB;
  }(Packet);

  exports.P3SKB = P3SKB;
}).call(this);