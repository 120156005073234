// @ts-ignore
import uuidv4 from 'uuid/v4';
import {t} from '../i18n';
const kbpgp = require('kbpgp');

var F = kbpgp["const"].openpgp;

const opts = {
    userid: 'unishell-'+uuidv4(),
    ecc:    true,
    primary: {
      nbits: 384,
      flags: F.certify_keys | F.sign_data | F.auth | F.encrypt_comm | F.encrypt_storage,
      expire_in: 0  // never expire
    },
    subkeys: []
  };

export interface ECCKeyPair {
    priKey: string;
    pubKey: string;
}  

export function genECCPair(): Promise<ECCKeyPair> {
   return new Promise((resolve, reject)=>{
    kbpgp.KeyManager.generate(opts, function(err:any, alice:any) {
        let priKey:string;
        if (!err) {
            alice.sign({}, (signError:any)=>{
                if(signError){
                    reject('failed to sign sub keys:'+signError);
                }else{
                    alice.export_pgp_private({}, (err2:any, pgp_private:any)=>{
                        if(!err2){
                            priKey = ''+pgp_private;
                            alice.export_pgp_public({}, (err3:any, pgp_public:any)=>{
                                if(!err3){
                                    resolve({priKey, pubKey: ''+pgp_public});
                                }else{
                                    reject('failed to export public ecc key:'+err3);
                                }
                            })
                        }else{
                           reject('failed to export private ecc key:'+err2);
                        }
        
                   })
                }
            })
        }else{
            reject("failed to generate ecc pair:"+err);
        }
      });       
   })
}  

export function loadPrivateKey(prikey: string):Promise<any> {
    return new Promise((resolve, reject)=>{
        kbpgp.KeyManager.import_from_armored_pgp({armored: prikey}, function(err:any, alice:any) {
            if (!err) {
                if (alice.is_pgp_locked()) {
                    alice.unlock_pgp({passphrase: ''}, function(err2:any) {
                        if (err2) {
                            reject('failed to unlock private key:'+err2);
                        }else{
                            // info("Loaded private key with passphrase");
                            resolve(alice);
                        }
                    })
                }else{
                    // info("Loaded private key without passphrase");
                    resolve(alice);
                }
            }else{
                reject('failed to load ecc primary key:'+err);
            }
          });        
    })
}

export interface SignedMessageRecord {
    payload: any; // json object
    signedMessage: string; // sign(JSON.stringify({nonce:nonce,payload:payload}))
}

export function signMessage(keyManager:any, payload:any):Promise<SignedMessageRecord> {
    return new Promise((resolve, reject)=>{
        
        const params = {
            msg:        typeof payload === 'string'? payload: JSON.stringify(payload),
            sign_with:  keyManager
        };
          
        kbpgp.box (params, function(err:any, result_string:string, result_buffer:any) {
            if(err){
                reject('failed to sign message:'+err);
            }else{
                resolve({payload, signedMessage: result_string})
            }
        });
    })
}

