// Generated by IcedCoffeeScript 108.0.11
(function () {
  var KeyFetcher;

  KeyFetcher = function () {
    function KeyFetcher() {}

    KeyFetcher.prototype.fetch = function (ids, ops, cb) {
      return cb(new Error("not implemented"));
    };

    return KeyFetcher;
  }();

  exports.KeyFetcher = KeyFetcher;
}).call(this);