// Generated by IcedCoffeeScript 108.0.11
(function () {
  var Base, b2u, u2b;

  exports.b2u = b2u = function b2u(b) {
    return new Uint8Array(b);
  };

  exports.u2b = u2b = function u2b(u) {
    return Buffer.from(u);
  };

  exports.Base = Base = function () {
    function Base(_arg) {
      this.publicKey = _arg.publicKey, this.secretKey = _arg.secretKey, this.lib = _arg.lib;
    }

    Base.prototype.genFromSeed = function (_arg) {
      var seed, tmp;
      seed = _arg.seed;
      tmp = this.lib.js.sign.keyPair.fromSeed(b2u(seed));
      this.secretKey = u2b(tmp.secretKey);
      this.publicKey = u2b(tmp.publicKey);
      return {
        secretKey: this.secretKey,
        publicKey: this.publicKey
      };
    };

    Base.prototype.genBoxPair = function () {
      var tmp;
      tmp = this.lib.js.box.keyPair();
      this.secretKey = u2b(tmp.secretKey);
      this.publicKey = u2b(tmp.publicKey);
      return {
        secretKey: this.secretKey,
        publicKey: this.publicKey
      };
    };

    Base.prototype.get_secret_key = function () {
      return this.secretKey;
    };

    Base.prototype.get_public_key = function () {
      return this.publicKey;
    };

    return Base;
  }();
}).call(this);