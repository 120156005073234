import * as React from 'react';
import {Component} from 'react';
import { CountryOption, BillingAddress, ShoppingAddressSpec, ShoppingAddressPropSpec, ShippingAddress } from '../ajax-data/ajax-data-types';
import * as _ from 'lodash';

import './address-form.scss';
import { Button, MenuItem, TextField } from '@material-ui/core';
import { t } from '../../i18n';
import { calcRationClass, getServerURL, RatioXS, RatioXXS } from '../../util/Util';
import { getCordova, info } from '../../globals';
import { HEqualSize } from '../../widget/equal-sizing/h-equal-size';
import { UButton } from '../../widget/button/ubutton';
import { VEqualSize } from '../../widget/equal-sizing/v-equal-size';
import { ImageWithRotation } from '../../widget/image-rotate/image-rotate';
import { ImageWithOverlay } from './image-with-overlay';

declare function getPictureFromCamera(inSource: 0|1|2, callback: (data:string)=>void):void;

interface ShoppingAddressFormProps {
    readonly: boolean;
    countryOptons:CountryOption[];
    addressSpecMap:{[key:string]:ShoppingAddressSpec};
    initialAddress?: BillingAddress;
    addressType: 'AddressInfo' | 'BillingAddress' | 'ShippingAddress';
}

interface ShoppingAddressState {
    uploadedFrontImg?: {
                        /** original image uploaded */
                        src:string, 
                        /** transformed image file  */
                        file?:File, 
                        /** transformed image */
                        transformedImg?: string
                    };
    uploadedBackImg?: {
                        /** original image uploaded */
                        src:string, 
                        /** transformed image file  */
                        file?:File, 
                        /** transformed image */
                        transformedImg?: string
                    };

    address:BillingAddress|ShippingAddress;
    errors:{[key:string]:string};
}

const _initAddress:BillingAddress = {
    addressId: '',
    name:'',
    countryOrRegion:'',
    address1:'',
    address2:'',
    address3:'',
    address4:'',
    username: '',
}

export class ShoppingAddressForm extends Component<ShoppingAddressFormProps, ShoppingAddressState> {
    constructor(props:ShoppingAddressFormProps){
        super(props);
        
        let address = this.props.initialAddress? _.cloneDeep(this.props.initialAddress) : _.cloneDeep(_initAddress);
        let errors = {};
        this.state = {address, errors}
    }

    updateCountry(country:string){
        this.state.address.countryOrRegion = country;
        delete this.state.errors.countryOrRegion;
        this.forceUpdate();
    }

    updateFullName(s:string){
        this.state.address.name = s;
        delete this.state.errors.name;
        this.forceUpdate();
    }
    updatePhoneNumber(s:string){
        this.state.address.phoneNumber = s;
        delete this.state.errors.phoneNumber;
        this.forceUpdate();
    }
    updateInstitution(s:string){
        this.state.address.institution = s;
        delete this.state.errors.institution;
        this.forceUpdate();
    }
    updateAddress1(s:string){
        this.state.address.address1 = s;
        delete this.state.errors.address1;
        this.forceUpdate();
    }
    updateAddress2(s:string){
        this.state.address.address2 = s;
        delete this.state.errors.address2;
        this.forceUpdate();
    }
    updateAddress3(s:string){
        this.state.address.address3 = s;
        delete this.state.errors.address3;
        this.forceUpdate();
    }
    updateAddress4(s:string){
        this.state.address.address4 = s;
        delete this.state.errors.address4;
        this.forceUpdate();
    }
    updatePostalCode(s:string){
        this.state.address.postalCode = s;
        delete this.state.errors.postalCode;
        this.forceUpdate();
    }

    private validateAddressProp(propSpec:ShoppingAddressPropSpec, propValue:any):string|undefined
    {
        info(`validate ${JSON.stringify(propSpec)} value=${JSON.stringify(propValue)}`);

        let propType = propSpec.type || 'trimed-text';

        if(propType === 'trimed-text'){
            let value = (propValue as string);
            if(value){
                propValue = value.trim();
            }
            if(propSpec.isMandatory && !propValue){
                if(propSpec.options){
                    return t(`请选择`)+propSpec.label;
                }else{
                    return t(`请输入`)+propSpec.label;
                }
            }
        }
        return undefined;
    }

    getFrontImg(addr:ShippingAddress){
        return (addr.identityPhoto||[]).find(p => (p.tag||'').indexOf('front')>0);
    }
    getBackImg(addr:ShippingAddress){
        return (addr.identityPhoto||[]).find(p => (p.tag||'').indexOf('back')>0);
    }

    validateAddress(addr:BillingAddress){
        let errors:{[key:string]:string} = {};

        if(!addr.countryOrRegion){
            errors.countryOrRegion = t(`请选择国家或地区`);
            return errors;
        }
        let addressSpec:ShoppingAddressSpec = this.props.addressSpecMap[this.state.address.countryOrRegion];
        if(!addressSpec){
            errors.countryOrRegion = t(`请选择国家或地区`);
        }

        if(this.props.addressType === 'BillingAddress'||this.props.addressType==='ShippingAddress'){
            let errmsg = this.validateAddressProp(addressSpec.name, addr.name);
            if(errmsg){
                errors.name = errmsg;
            }
            errmsg = this.validateAddressProp(addressSpec.phoneNumber, addr.phoneNumber);
            if(errmsg){
                errors.phoneNumber = errmsg;
            }
        }
        {
            let errmsg = this.validateAddressProp(addressSpec.institution, addr.institution);
            if(errmsg){
                errors.institution = errmsg;
            }
        }
        {
            let errmsg = this.validateAddressProp(addressSpec.address1, addr.address1);
            if(errmsg){
                errors.address1 = errmsg;
            }
        }
        {
            let errmsg = this.validateAddressProp(addressSpec.address2, addr.address2);
            if(errmsg){
                errors.address2 = errmsg;
            }
        }
        {
            let errmsg = this.validateAddressProp(addressSpec.address3, addr.address3);
            if(errmsg){
                errors.address3 = errmsg;
            }
        }
        {
            let errmsg = this.validateAddressProp(addressSpec.address4, addr.address4);
            if(errmsg){
                errors.address4 = errmsg;
            }
        }
        {
            let errmsg = this.validateAddressProp(addressSpec.postalCode, addr.postalCode);
            if(errmsg){
                errors.postalCode = errmsg;
            }
        }

        if(this.props.addressType==='ShippingAddress' && addr.countryOrRegion === 'CHN'){
            const frontImg = this.getFrontImg(addr as ShippingAddress);
            const backImg = this.getBackImg(addr as ShippingAddress);
            if(!(this.state.uploadedFrontImg  && this.state.uploadedFrontImg!.file) && !frontImg){
                errors.frontImage = t('请上传身份证正面照');
            }
            if(!(this.state.uploadedBackImg && this.state.uploadedBackImg!.file) && !backImg){
                errors.backImage = t('请上传身份证反面照');
            }
        }

        return errors;
    }

    validateAndGetAddress(){
        let addr = _.cloneDeep(this.state.address);
        let errors = this.validateAddress(addr);
        let hasError = false;
        if(Object.keys(errors).length > 0){
            this.setState({errors});
            hasError = true;
        }
 
        return {addr, uploadedFrontImg: this.state.uploadedFrontImg, uploadedBackImg: this.state.uploadedBackImg, hasError};
    }

    async updateTransformedImage(transformedImg:string, side:'front'|'back'){
        const data = await fetch(transformedImg);
        const blob = await data.blob();
        const errors = this.state.errors;

        if(side === 'front'){
            delete errors.frontImage;
            const file= new File([blob], "id-front-"+Date.now(), {type: "image/png"}); 
            this.setState({uploadedFrontImg:{src:'', file, transformedImg}, errors});                
        }else if(side === 'back'){
            delete errors.backImage;
            const file= new File([blob], "id-back-"+Date.now(), {type: "image/png"}); 
            this.setState({uploadedBackImg:{src:'', file, transformedImg}, errors});                
        }    
    }

    uploadImgCordova(args:{
                        side:'front'|'back',
                        camera: boolean,
                    }){
        const {side, camera} = args;

        getPictureFromCamera(camera? 1: 2, (data:string)=>{
            if(side === 'front'){
                this.setState({uploadedFrontImg:{src:data}});                
            }else if(side === 'back'){
                this.setState({uploadedBackImg:{src:data}});                
            }    
        })                        
    }

    updateFrontIDImgFile(f:File){
        const reader = new FileReader();
        reader.addEventListener("load", ()=>{
            this.setState({uploadedFrontImg:{src:reader.result as string}});
        });
        reader.readAsDataURL(f);
    }

    updateBackIDImgFile(f:File){
        const reader = new FileReader();
        reader.addEventListener("load", ()=>{
            this.setState({uploadedBackImg:{src:reader.result as string}});
        });
        reader.readAsDataURL(f);
    }


    render(){
        const {ratioClass, vw,  mw, ratio} = calcRationClass();
        const {readonly} = this.props;

        let addr = this.state.address;

        let countryOrRegion = this.state.address.countryOrRegion || 'n/a';
        let addressSpec:ShoppingAddressSpec = this.props.addressSpecMap[this.state.address.countryOrRegion||'CAN'];

        let idFrontPhotoUI:JSX.Element|null = null;
        if(this.props.addressType==='ShippingAddress' && countryOrRegion === 'CHN'){
            const IDFrontImage = this.getFrontImg(addr as ShippingAddress);

            const frontUploadBtns = [
                <UButton size='small' variant='contained' color='primary'
                        onClick={()=>{ this.uploadImgCordova({camera: true, side:'front'}) }}
                >{t('直接拍照')}</UButton>,
                <UButton size='small' variant='contained' color='primary'
                        onClick={()=>{ this.uploadImgCordova({camera: false, side:'front'}) }}
                >{t('取相册图片')}</UButton>
            ];

            idFrontPhotoUI = (
                <div className='id-photo'>
                    <div className='note'>{t('上传身份证正面照 * 印有国徽面')}</div>
                    {this.state.uploadedFrontImg ?
                     this.state.uploadedFrontImg.transformedImg ?
                        <ImageWithOverlay imageList={[this.state.uploadedFrontImg.transformedImg]} classes='id-image  transformed'/>
                        :
                        <ImageWithRotation imgsrc={this.state.uploadedFrontImg.src} 
                                            minImgWidth={400} minImgHeight={200}
                                            key={Date.now()}
                                           cancel={()=>{ this.setState({uploadedFrontImg: undefined})}}
                                           done={ async (imgUrl)=>{
                                                this.state.uploadedFrontImg!.transformedImg=imgUrl;
                                                this.updateTransformedImage(imgUrl, 'front');
                                           }}
                        />
                        :
                        IDFrontImage? <ImageWithOverlay imageList={[getServerURL()+IDFrontImage.url]} classes='id-image'/>: null
                    }

                    {!!getCordova()?
                        <div className='upload-buttons'>
                        {ratio <= RatioXXS?
                            <VEqualSize spacing='20px' justifyContent='space-around'>
                                {frontUploadBtns}
                            </VEqualSize>:
                            <HEqualSize spacing='20px' justifyContent='space-around'>
                                {frontUploadBtns}
                            </HEqualSize>
                        }
                        </div>:
                        <div className='upload-buttons'>
                            <label htmlFor='id-front-image-input' id='id-front-image-label'>{t('取相册图片')}</label>
                            <input type='file' accept='image/png, image/jpg, image/jpeg' 
                                    id='id-front-image-input' 
                                    style={{visibility:'hidden', width:'10px'}}
                                onChange={(evt)=>{ this.updateFrontIDImgFile(evt.currentTarget.files![0]) }} disabled={readonly}
                            />
                        </div>
                    }

                    <div className='error-msg'>{this.state.errors.frontImage}&nbsp;</div>
                </div>
            )
        }
        let idBackPhotoUI:JSX.Element|null = null;
        if(this.props.addressType==='ShippingAddress' && countryOrRegion === 'CHN'){
            const IDBackImage = this.getBackImg(addr as ShippingAddress);
            const backUploadBtns = [
                <UButton size='small' variant='contained' color='primary'
                        onClick={()=>{ this.uploadImgCordova({camera: true, side:'back'}) }}
                >{t('直接拍照')}</UButton>,
                <UButton size='small' variant='contained' color='primary'
                        onClick={()=>{ this.uploadImgCordova({camera: false, side:'back'}) }}
                >{t('取相册图片')}</UButton>
            ];

            idBackPhotoUI = <div className='id-photo'>
                                <div className='note'>{t('上传身份证反面照 * 印有持证人照片')}</div>
                                {this.state.uploadedBackImg ?
                                 this.state.uploadedBackImg!.transformedImg?
                                 <ImageWithOverlay imageList={[this.state.uploadedBackImg!.transformedImg]} classes='id-image transformed'/>
                                 :
                                 <ImageWithRotation imgsrc={this.state.uploadedBackImg.src}  key={Date.now()}
                                                    minImgWidth={400} minImgHeight={200}
                                                    cancel={()=>{ this.setState({uploadedBackImg: undefined})}}
                                                    done={imgURL=>{
                                                        this.state.uploadedBackImg!.transformedImg = imgURL;
                                                        this.updateTransformedImage(imgURL, 'back');
                                                     }}
                                 /> 
                                 :
                                 IDBackImage ? <ImageWithOverlay imageList={[getServerURL()+IDBackImage.url]} classes='id-image'/>: null
                                }

                    {!!getCordova()?
                        <div className='upload-buttons'>
                        {ratio <= RatioXXS?
                            <VEqualSize spacing='20px' justifyContent='space-around'>
                                {backUploadBtns}
                            </VEqualSize>:
                            <HEqualSize spacing='20px' justifyContent='space-around'>
                                {backUploadBtns}
                            </HEqualSize>
                        }
                        </div>:
                        <div className='upload-buttons'>
                                <label htmlFor='id-back-image-input' id='id-back-image-label'>{t('取相册图片')}</label>
                                <input type='file' accept='image/png, image/jpg, image/jpeg' 
                                        id='id-back-image-input' 
                                        style={{visibility:'hidden', width:'10px'}}
                                        onChange={(evt)=>{ 
                                            this.updateBackIDImgFile(evt.currentTarget.files![0]) 
                                        }} 
                                        disabled={readonly}
                                />
                        </div>
                    }                                
                    <div className='error-msg'>{this.state.errors.backImage}&nbsp;</div>
                </div>
        }



        return (
            <div className='shopping-address-form'>
                {this.props.addressType === 'BillingAddress'||this.props.addressType==='ShippingAddress'?
                     <React.Fragment>
                        <TextField label={addressSpec.name.label+(addressSpec.name.isMandatory?'*':'')} 
                                   variant='outlined' size='small' 
                                   placeholder={addressSpec.name.placeholder}
                                   value={addr.name}   disabled={readonly}
                                   onChange={(evt)=>{ this.updateFullName(evt.currentTarget.value) }}
                        />
                        <div className='error-msg'>{this.state.errors.phoneNumber}&nbsp;</div>
                        <TextField label={addressSpec.phoneNumber.label+(addressSpec.phoneNumber.isMandatory?'*':'')} 
                                   variant='outlined' size='small' 
                                   placeholder={addressSpec.phoneNumber.placeholder}
                                   value={addr.phoneNumber}   disabled={readonly}
                                   onChange={(evt)=>{ this.updatePhoneNumber(evt.currentTarget.value) }}
                        />
                        <div className='error-msg'>{this.state.errors.phoneNumber}&nbsp;</div>
                     </React.Fragment>: null
                }

               <TextField select label={t('国家/地区')+(addressSpec.countryOrRegion.isMandatory?'*':'')} 
                          value={countryOrRegion} variant='outlined' size='small'   disabled={readonly}
                          onChange={evt=>{ 
                              this.updateCountry(evt.target.value) 
                          }}>
                   {
                        this.props.countryOptons.map(x=>{
                            return <MenuItem key={x.code} value={x.code}>{x.label}</MenuItem>
                        })
                   }
               </TextField>
               <div className='error-msg'>{this.state.errors.countryOrRegion}&nbsp;</div>

               <TextField label={addressSpec.institution.label+(addressSpec.institution.isMandatory?'*':'')} 
                          variant='outlined' size='small'  disabled={readonly}
                          placeholder={addressSpec.institution.placeholder} 
                          value={addr.institution||''}
                          onChange={(evt)=>{ this.updateInstitution(evt.currentTarget.value) }}
                />
               <div className='error-msg'>{this.state.errors.institution}&nbsp;</div>
               
               <TextField label={addressSpec.address1.label+(addressSpec.address1.isMandatory?'*':'')} 
                          variant='outlined' size='small'  disabled={readonly}
                          placeholder={addressSpec.address1.placeholder} 
                          value={addr.address1}
                          onChange={(evt)=>{ this.updateAddress1(evt.currentTarget.value) }}
                />
               <div className='error-msg'>{this.state.errors.address1}&nbsp;</div>
               
               <TextField label={addressSpec.address2.label+(addressSpec.address2.isMandatory?'*':'')} 
                          variant='outlined' size='small'  disabled={readonly}
                          placeholder={addressSpec.address2.placeholder} 
                          value={addr.address2}
                          onChange={(evt)=>{ this.updateAddress2(evt.currentTarget.value) }}
                />
               <div className='error-msg'>{this.state.errors.address2}&nbsp;</div>
               
               <TextField label={addressSpec.address3.label+(addressSpec.address3.isMandatory?'*':'')} 
                          variant='outlined' size='small'  disabled={readonly}
                          placeholder={addressSpec.address3.placeholder} 
                          value={addr.address3}
                          onChange={(evt)=>{ this.updateAddress3(evt.currentTarget.value) }}
                />
               <div className='error-msg'>{this.state.errors.address3}&nbsp;</div>

               {addressSpec.address4.options ?
                    <TextField select label={addressSpec.address4.label+(addressSpec.address4.isMandatory?'*':'')} 
                               variant='outlined' size='small'  disabled={readonly}
                               value={addr.address4} 
                               onChange={evt=>{ 
                                   this.updateAddress4(evt.target.value) 
                               }}
     
                    >
                                {addressSpec.address4.options!.map(x=>{
                                    return <MenuItem key={x.key} value={x.key}>{x.label}</MenuItem>
                                })}
                    </TextField> :
                    <TextField label={addressSpec.address4.label+(addressSpec.address4.isMandatory?'*':'')} 
                               variant='outlined' size='small'  disabled={readonly}
                               placeholder={addressSpec.address4.placeholder}
                               value={addr.address4}
                               onChange={(evt)=>{ this.updateAddress4(evt.currentTarget.value) }}
                    />
               }
               <div className='error-msg'>{this.state.errors.address4}&nbsp;</div>

               <TextField label={addressSpec.postalCode.label+(addressSpec.address4.isMandatory?'*':'')} 
                          variant='outlined' size='small'  disabled={readonly}
                          placeholder={addressSpec.postalCode.placeholder} 
                          value={addr.postalCode}
                          onChange={(evt)=>{ this.updatePostalCode(evt.currentTarget.value) }}
           />
               <div className='error-msg'>{this.state.errors.postalCode}&nbsp;</div>

               {idFrontPhotoUI}
               {idBackPhotoUI}
               {countryOrRegion === 'CHN' && this.props.addressType==='ShippingAddress' ? 
                <p><strong>{t('* 货物进入中国海关需要居民身份证')}</strong></p>:null}
            </div>
        )
    }
}