// Generated by IcedCoffeeScript 108.0.11
(function () {
  var C,
      Ch,
      ClearSigner,
      CreationTime,
      Issuer,
      Literal,
      SHA512,
      SRF,
      Signature,
      Verifier,
      VerifierBase,
      WordArray,
      bufferify,
      clearsign_header,
      clearsign_to_sign,
      dash_escape,
      dash_unescape_line,
      dash_unescape_lines,
      encode,
      export_key_pgp,
      get_cipher,
      hashmod,
      iced,
      input_to_cleartext,
      input_to_cleartext_display,
      input_to_cleartext_sign,
      konst,
      make_esc,
      scrub_buffer,
      triplesec,
      unix_time,
      whitespace_strip,
      __iced_k,
      __iced_k_noop,
      _ref,
      _ref1,
      _ref2,
      __hasProp = {}.hasOwnProperty,
      __extends = function __extends(child, parent) {
    for (var key in parent) {
      if (__hasProp.call(parent, key)) child[key] = parent[key];
    }

    function ctor() {
      this.constructor = child;
    }

    ctor.prototype = parent.prototype;
    child.prototype = new ctor();
    child.__super__ = parent.prototype;
    return child;
  };

  iced = require('iced-runtime');

  __iced_k = __iced_k_noop = function __iced_k_noop() {};

  make_esc = require('iced-error').make_esc;
  _ref = require('./packet/signature'), Signature = _ref.Signature, CreationTime = _ref.CreationTime, Issuer = _ref.Issuer;
  _ref1 = require('../util'), bufferify = _ref1.bufferify, unix_time = _ref1.unix_time;
  SRF = require('../rand').SRF;
  triplesec = require('triplesec');
  _ref2 = require('../symmetric'), export_key_pgp = _ref2.export_key_pgp, get_cipher = _ref2.get_cipher;
  scrub_buffer = triplesec.util.scrub_buffer;
  WordArray = triplesec.WordArray;
  konst = require('../const');
  C = konst.openpgp;
  Ch = require('../header');
  hashmod = require('../hash');
  SHA512 = hashmod.SHA512;
  encode = require('./armor').encode;
  clearsign_header = require('pgp-utils').armor.clearsign_header;
  Literal = require("./packet/literal").Literal;
  VerifierBase = require('./verifier').Base;

  exports.input_to_cleartext = input_to_cleartext = function input_to_cleartext(raw) {
    var lines, ret;
    lines = raw.split(/\n/);
    ret = {
      show: bufferify(input_to_cleartext_display(lines)),
      sign: bufferify(input_to_cleartext_sign(lines))
    };
    return ret;
  };

  exports.dash_escape = dash_escape = function dash_escape(line) {
    if (line.length >= 1 && line[0] === '-') {
      return "- " + line;
    } else {
      return line;
    }
  };

  exports.dash_unescape_line = dash_unescape_line = function dash_unescape_line(line) {
    var m, out, warn, _ref3;

    warn = false;
    out = (m = line.match(/^-( )?(.*?)$/)) != null ? (((_ref3 = m[1]) != null ? _ref3.length : void 0) !== 1 ? warn = true : void 0, m[2]) : line;
    return [out, warn];
  };

  exports.dash_unescape_lines = dash_unescape_lines = function dash_unescape_lines(lines, warnings) {
    var i, l, line, ret, warn;

    if (warnings == null) {
      warnings = null;
    }

    ret = function () {
      var _i, _len, _ref3, _results;

      _results = [];

      for (i = _i = 0, _len = lines.length; _i < _len; i = ++_i) {
        line = lines[i];
        _ref3 = dash_unescape_line(line), l = _ref3[0], warn = _ref3[1];

        if (warn) {
          if (warnings != null) {
            warnings.push("Bad dash-encoding on line " + (i + 1));
          }
        }

        _results.push(l);
      }

      return _results;
    }();

    return ret;
  };

  exports.input_to_cleartext_display = input_to_cleartext_display = function input_to_cleartext_display(lines) {
    var line, out;

    out = function () {
      var _i, _len, _results;

      _results = [];

      for (_i = 0, _len = lines.length; _i < _len; _i++) {
        line = lines[_i];

        _results.push(dash_escape(line));
      }

      return _results;
    }();

    if (lines.length === 0 || lines.slice(-1)[0] !== '') {
      out.push('');
    }

    return out.join("\n");
  };

  exports.clearsign_to_sign = clearsign_to_sign = function clearsign_to_sign(lines, warnings) {
    lines = dash_unescape_lines(lines, warnings);
    return input_to_cleartext_sign(lines);
  };

  exports.input_to_cleartext_sign = input_to_cleartext_sign = function input_to_cleartext_sign(lines) {
    var line, num_trailing_newlines, t, tmp, _i;

    tmp = function () {
      var _i, _len, _results;

      _results = [];

      for (_i = 0, _len = lines.length; _i < _len; _i++) {
        line = lines[_i];

        _results.push(whitespace_strip(line));
      }

      return _results;
    }();

    num_trailing_newlines = 0;

    for (_i = tmp.length - 1; _i >= 0; _i += -1) {
      t = tmp[_i];

      if (t === '') {
        num_trailing_newlines++;
      } else {
        break;
      }
    }

    if (num_trailing_newlines > 0) {
      tmp.pop();
    }

    return tmp.join("\r\n");
  };

  exports.whitespace_strip = whitespace_strip = function whitespace_strip(line) {
    var m;
    line = line.replace(/\r/g, '');

    if (m = line.match(/^(.*?)([ \t]*)$/)) {
      return m[1];
    } else {
      return line;
    }
  };

  ClearSigner = function () {
    function ClearSigner(_arg) {
      this.msg = _arg.msg, this.signing_key = _arg.signing_key, this.now = _arg.now, this.hasher = _arg.hasher;
    }

    ClearSigner.prototype._fix_msg = function (cb) {
      this._cleartext = input_to_cleartext(this.msg.toString('utf8'));
      return cb(null);
    };

    ClearSigner.prototype._sign_msg = function (cb) {
      var err, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      this.sig = new Signature({
        hasher: this.hasher,
        sig_type: C.sig_types.canonical_text,
        key: this.signing_key.key,
        hashed_subpackets: [new CreationTime(this.now || unix_time())],
        unhashed_subpackets: [new Issuer(this.signing_key.get_key_id())]
      });
      (function (_this) {
        return function (__iced_k) {
          __iced_deferrals = new iced.Deferrals(__iced_k, {
            parent: ___iced_passed_deferral,
            filename: "/Users/max/src/keybase/kbpgp/src/openpgp/clearsign.iced",
            funcname: "ClearSigner._sign_msg"
          });

          _this.sig.write(_this._cleartext.sign, __iced_deferrals.defer({
            assign_fn: function (__slot_1) {
              return function () {
                err = arguments[0];
                return __slot_1._sig_output = arguments[1];
              };
            }(_this),
            lineno: 118
          }));

          __iced_deferrals._fulfill();
        };
      })(this)(function (_this) {
        return function () {
          return cb(err, _this._sig_output);
        };
      }(this));
    };

    ClearSigner.prototype.scrub = function () {};

    ClearSigner.prototype.hasher_name = function () {
      return this.sig.hasher.algname;
    };

    ClearSigner.prototype._encode = function (cb) {
      var body, hdr;
      hdr = clearsign_header(Ch, this._cleartext.show, this.hasher_name());
      body = encode(C.message_types.signature, this._sig_output);
      return cb(null, hdr + body);
    };

    ClearSigner.prototype.run = function (cb) {
      var encoded, esc, signature, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      esc = make_esc(cb, "ClearSigner::run");
      (function (_this) {
        return function (__iced_k) {
          __iced_deferrals = new iced.Deferrals(__iced_k, {
            parent: ___iced_passed_deferral,
            filename: "/Users/max/src/keybase/kbpgp/src/openpgp/clearsign.iced",
            funcname: "ClearSigner.run"
          });

          _this._fix_msg(esc(__iced_deferrals.defer({
            lineno: 140
          })));

          __iced_deferrals._fulfill();
        };
      })(this)(function (_this) {
        return function () {
          (function (__iced_k) {
            __iced_deferrals = new iced.Deferrals(__iced_k, {
              parent: ___iced_passed_deferral,
              filename: "/Users/max/src/keybase/kbpgp/src/openpgp/clearsign.iced",
              funcname: "ClearSigner.run"
            });

            _this._sign_msg(esc(__iced_deferrals.defer({
              assign_fn: function () {
                return function () {
                  return signature = arguments[0];
                };
              }(),
              lineno: 141
            })));

            __iced_deferrals._fulfill();
          })(function () {
            (function (__iced_k) {
              __iced_deferrals = new iced.Deferrals(__iced_k, {
                parent: ___iced_passed_deferral,
                filename: "/Users/max/src/keybase/kbpgp/src/openpgp/clearsign.iced",
                funcname: "ClearSigner.run"
              });

              _this._encode(esc(__iced_deferrals.defer({
                assign_fn: function () {
                  return function () {
                    return encoded = arguments[0];
                  };
                }(),
                lineno: 142
              })));

              __iced_deferrals._fulfill();
            })(function () {
              return cb(null, encoded, signature);
            });
          });
        };
      }(this));
    };

    return ClearSigner;
  }();

  Verifier = function (_super) {
    __extends(Verifier, _super);

    function Verifier(_arg) {
      var keyfetch, packets;
      packets = _arg.packets, this.clearsign = _arg.clearsign, keyfetch = _arg.keyfetch, this.now = _arg.now, this.assert_pgp_hash = _arg.assert_pgp_hash;

      Verifier.__super__.constructor.call(this, {
        packets: packets,
        keyfetch: keyfetch
      });
    }

    Verifier.prototype._reformat_text = function (cb) {
      var data;
      data = bufferify(clearsign_to_sign(this.clearsign.lines));
      this._literal = new Literal({
        data: data,
        format: C.literal_formats.utf8,
        date: this.now || unix_time()
      });
      return cb(null);
    };

    Verifier.prototype._make_hasher = function (cb) {
      var err, h;
      err = null;
      h = (this.clearsign.headers.hash || 'MD5').trim();

      if ((this._sig.hasher = hashmod[h]) == null) {
        err = new Error("Unknown hash algorithm: " + h);
      }

      return cb(err);
    };

    Verifier.prototype._verify = function (cb) {
      var err, opts, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      opts = {
        now: this.now,
        assert_pgp_hash: this.assert_pgp_hash
      };
      (function (_this) {
        return function (__iced_k) {
          __iced_deferrals = new iced.Deferrals(__iced_k, {
            parent: ___iced_passed_deferral,
            filename: "/Users/max/src/keybase/kbpgp/src/openpgp/clearsign.iced",
            funcname: "Verifier._verify"
          });

          _this._sig.verify([_this._literal], __iced_deferrals.defer({
            assign_fn: function () {
              return function () {
                return err = arguments[0];
              };
            }(),
            lineno: 184
          }), opts);

          __iced_deferrals._fulfill();
        };
      })(this)(function (_this) {
        return function () {
          return cb(err);
        };
      }(this));
    };

    Verifier.prototype._check_headers = function (cb) {
      var err, k, v, _ref3;

      err = null;
      _ref3 = this.clearsign.headers;

      for (k in _ref3) {
        v = _ref3[k];

        if (k !== 'hash') {
          err = new Error("Unallowed header: " + k);
          break;
        }
      }

      return cb(err);
    };

    Verifier.prototype.run = function (cb) {
      var esc, ___iced_passed_deferral, __iced_deferrals, __iced_k;

      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      esc = make_esc(cb, "Verifier::run");
      (function (_this) {
        return function (__iced_k) {
          __iced_deferrals = new iced.Deferrals(__iced_k, {
            parent: ___iced_passed_deferral,
            filename: "/Users/max/src/keybase/kbpgp/src/openpgp/clearsign.iced",
            funcname: "Verifier.run"
          });

          _this._check_headers(esc(__iced_deferrals.defer({
            lineno: 201
          })));

          __iced_deferrals._fulfill();
        };
      })(this)(function (_this) {
        return function () {
          (function (__iced_k) {
            __iced_deferrals = new iced.Deferrals(__iced_k, {
              parent: ___iced_passed_deferral,
              filename: "/Users/max/src/keybase/kbpgp/src/openpgp/clearsign.iced",
              funcname: "Verifier.run"
            });

            _this._find_signature(esc(__iced_deferrals.defer({
              lineno: 202
            })));

            __iced_deferrals._fulfill();
          })(function () {
            (function (__iced_k) {
              __iced_deferrals = new iced.Deferrals(__iced_k, {
                parent: ___iced_passed_deferral,
                filename: "/Users/max/src/keybase/kbpgp/src/openpgp/clearsign.iced",
                funcname: "Verifier.run"
              });

              _this._reformat_text(esc(__iced_deferrals.defer({
                lineno: 203
              })));

              __iced_deferrals._fulfill();
            })(function () {
              (function (__iced_k) {
                __iced_deferrals = new iced.Deferrals(__iced_k, {
                  parent: ___iced_passed_deferral,
                  filename: "/Users/max/src/keybase/kbpgp/src/openpgp/clearsign.iced",
                  funcname: "Verifier.run"
                });

                _this._fetch_key(esc(__iced_deferrals.defer({
                  lineno: 204
                })));

                __iced_deferrals._fulfill();
              })(function () {
                (function (__iced_k) {
                  __iced_deferrals = new iced.Deferrals(__iced_k, {
                    parent: ___iced_passed_deferral,
                    filename: "/Users/max/src/keybase/kbpgp/src/openpgp/clearsign.iced",
                    funcname: "Verifier.run"
                  });

                  _this._make_hasher(esc(__iced_deferrals.defer({
                    lineno: 205
                  })));

                  __iced_deferrals._fulfill();
                })(function () {
                  (function (__iced_k) {
                    __iced_deferrals = new iced.Deferrals(__iced_k, {
                      parent: ___iced_passed_deferral,
                      filename: "/Users/max/src/keybase/kbpgp/src/openpgp/clearsign.iced",
                      funcname: "Verifier.run"
                    });

                    _this._verify(esc(__iced_deferrals.defer({
                      lineno: 206
                    })));

                    __iced_deferrals._fulfill();
                  })(function () {
                    return cb(null, _this._literal);
                  });
                });
              });
            });
          });
        };
      }(this));
    };

    return Verifier;
  }(VerifierBase);

  exports.sign = function (_arg, cb) {
    var b, encoded, err, hasher, msg, now, signature, signing_key, ___iced_passed_deferral, __iced_deferrals, __iced_k;

    __iced_k = __iced_k_noop;
    ___iced_passed_deferral = iced.findDeferral(arguments);
    msg = _arg.msg, signing_key = _arg.signing_key, now = _arg.now, hasher = _arg.hasher;
    b = new ClearSigner({
      msg: msg,
      signing_key: signing_key,
      now: now,
      hasher: hasher
    });
    (function (_this) {
      return function (__iced_k) {
        __iced_deferrals = new iced.Deferrals(__iced_k, {
          parent: ___iced_passed_deferral,
          filename: "/Users/max/src/keybase/kbpgp/src/openpgp/clearsign.iced",
          funcname: "sign"
        });
        b.run(__iced_deferrals.defer({
          assign_fn: function () {
            return function () {
              err = arguments[0];
              encoded = arguments[1];
              return signature = arguments[2];
            };
          }(),
          lineno: 217
        }));

        __iced_deferrals._fulfill();
      };
    })(this)(function (_this) {
      return function () {
        b.scrub();
        return cb(err, encoded, signature);
      };
    }(this));
  };

  exports.verify = function (_arg, cb) {
    var assert_pgp_hash, clearsign, err, keyfetch, literal, now, packets, v, ___iced_passed_deferral, __iced_deferrals, __iced_k;

    __iced_k = __iced_k_noop;
    ___iced_passed_deferral = iced.findDeferral(arguments);
    packets = _arg.packets, clearsign = _arg.clearsign, keyfetch = _arg.keyfetch, now = _arg.now, assert_pgp_hash = _arg.assert_pgp_hash;
    v = new Verifier({
      packets: packets,
      clearsign: clearsign,
      keyfetch: keyfetch,
      now: now,
      assert_pgp_hash: assert_pgp_hash
    });
    (function (_this) {
      return function (__iced_k) {
        __iced_deferrals = new iced.Deferrals(__iced_k, {
          parent: ___iced_passed_deferral,
          filename: "/Users/max/src/keybase/kbpgp/src/openpgp/clearsign.iced",
          funcname: "verify"
        });
        v.run(__iced_deferrals.defer({
          assign_fn: function () {
            return function () {
              err = arguments[0];
              return literal = arguments[1];
            };
          }(),
          lineno: 225
        }));

        __iced_deferrals._fulfill();
      };
    })(this)(function (_this) {
      return function () {
        return cb(err, literal);
      };
    }(this));
  };
}).call(this);