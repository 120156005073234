import * as React from 'react';
import {Component} from 'react';
import Highlighter from 'react-highlight-words';
import { useDoubleTap } from 'use-double-tap';
import './product-field-text.scss';
import { parseTerms, TermLink } from './term-link';

// multiline line paragraph support double tap to switch font size
function DoubleTap(props: {lines:JSX.Element[], fontSize:'normal'|'large', onDoubleClick: ()=>void}) {
    const bind = useDoubleTap((event) => {
        props.onDoubleClick();
      });

    return  <div {...bind} className={'double-tap-font-'+props.fontSize}>{props.lines}</div>

}

interface ProductFieldTextProps {
    /** delimited by new line char '\n' */
    lines:string, 
    extraClasses?:string,
    highlightWords?:string[],
    onClickTerm:(term:string)=>void,
    skipShuyu?: boolean; 
    supportDoubleTap?: boolean;
}

export class ProductFieldText extends Component<ProductFieldTextProps,{ fontSize:'normal'|'large' }> {
    constructor(props:ProductFieldTextProps){
        super(props);
        this.state = {fontSize: 'normal'}
    }
    render() {
        if(typeof (this.props.lines||'') !== 'string'){
            return <div>{JSON.stringify(this.props)}</div>
        }
        let lines = (this.props.lines||'').split('\n').map((line, idx)=> {
            let textItems = this.props.skipShuyu ? [{type:'text', value:line}]: parseTerms(line);

            return this.props.highlightWords && this.props.highlightWords.length>0? 
                    <div className='line' key={idx}>
                        {textItems.map((item,idx)=>{
                            if(item.type === 'text'){
                                return <Highlighter searchWords={this.props.highlightWords||[]} 
                                                    textToHighlight={item.value} 
                                                    highlightClassName='highlight'
                                                    key={idx+item.value}
                                                    />
                            }else{
                                return <TermLink term={item.value} highlightWords={this.props.highlightWords} 
                                                 onClickTerm={this.props.onClickTerm}
                                                 key={idx+item.value}
                                                 />
                            }
                        })}
                    </div>:
                    <div className='line' key={idx}>
                        {textItems.map((item, idx)=>{
                            if(item.type === 'text'){
                                return item.value;
                            }else{
                                return <TermLink term={item.value} onClickTerm={this.props.onClickTerm} key={idx+item.value}/>
                            }
                        })}
                    </div>;
        })
        return <div className={'product-field-text '+(this.props.extraClasses || '')}>
                    {this.props.supportDoubleTap? 
                      <DoubleTap lines={lines} fontSize={this.state.fontSize} onDoubleClick={()=>{ this.switchFont() }}/>:
                      lines
                    }
                </div>

    }

    switchFont() {
        if(this.state.fontSize==='normal'){
            this.setState({fontSize:'large'});
        }else{
            this.setState({fontSize:'normal'});
        }
    }
}
