import {Component} from 'react';
import * as React from 'react';
import { t } from '../../i18n';
import { formatPrice, getCurrencyOption } from '../../util/Util';
import { NoticeDialog } from '../../view/NoticeDialog';
import { CurrencyOption, PaymentMethodOption, ShopPaymentRequestData, ShoppingOrder } from '../ajax-data/ajax-data-types';
import { PaymentSuccess } from './payment-success';
import './wechat-payment.scss';
import { Button } from '@material-ui/core';
import { doAJAXCall } from '../common/ajax-call';
import { completeShoppingSnappay, prepareShoppingPayment } from '../ajax-data/shopping-server-api';
import { getCordova, getWechat } from '../../globals';
import { gotoShoppingPathNSavePrevPath } from '../history/shopping-history';
import { UButton } from '../../widget/button/ubutton';
import { setShopUserInfo } from '../../view/header/HeaderView2';

interface WechatPaymentProps {
    languageCode: string;
    currencyOptionsMap: {[key:string]:CurrencyOption[]};
    paymentMethodOptionsMap:{[key:string]:PaymentMethodOption[]};
    order: ShoppingOrder;
    paymentData: ShopPaymentRequestData;
}

interface WechatPaymentState {
    paymentDone: boolean;
    orderId?: string;
}

export class WechatPayment extends Component<WechatPaymentProps, WechatPaymentState>{
    private errorDialog: NoticeDialog|undefined;

    constructor(props: WechatPaymentProps){
        super(props);
        this.state = {paymentDone: false};
    }

    render(){
        const currencyOption = getCurrencyOption(this.props.currencyOptionsMap, 
                                                 this.props.languageCode, 
                                                 this.props.order.summary.currencyCode);
        if(this.state.paymentDone){
            return (
                <div className='wechat-payment'>
                    <PaymentSuccess languageCode={this.props.languageCode} amount={this.props.order.summary.total}
                                    currencyCode={this.props.order.summary.currencyCode}
                                    orderId={this.state.orderId!}
                                    currencyOptionsMap={this.props.currencyOptionsMap}
                                    paymentMethodCode='WechatPay'
                                    paymentMethodOptionsMap={this.props.paymentMethodOptionsMap} 
                    />
                    <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>
                </div>
            )
        }

        return (
            <div className='wechat-payment'>
                <div className='header'>
                    <h3>{t('微信支付')}</h3>
                </div>
                <table className='order-info'>
                    <tbody>
                        {/* <tr>
                            <td className='label'>{t('订单号')}</td>
                            <td>{this.props.order.orderId}</td>
                        </tr> */}
                        <tr>
                            <td className='label'>{t('金额')}</td>
                            <td className='amount'>{formatPrice(this.props.order.summary.total, currencyOption.symbol, currencyOption.label)}</td>
                        </tr>
                    </tbody>
                </table>
                <div className='action-buttons'>
                    <UButton variant='contained' color='secondary' id='shopping-button'
                            onClick={()=>{
                                gotoShoppingPathNSavePrevPath('main/shopping/search', {});
                            }}
                    >{t('继续购物')}</UButton>
                    <UButton variant='contained' color='primary' onClick={()=>{ this.doPayment() }}>{t('支付')}</UButton>
                </div>
         
                <NoticeDialog open={false} ref={e=>this.errorDialog=e!}/>

            </div>
        )
    }

    doPayment(){
        const orderSummary = this.props.order.summary;

        doAJAXCall({
            whatIsFor:'微信支付',
            errorDialog: this.errorDialog!,
            ajaxCall: async (accessToken)=>{
                let response = await prepareShoppingPayment(accessToken, this.props.paymentData);
                let payInfo: string = JSON.parse(response.snappaySDKRequest!);
                let orderCode = response.orderCode;
                let method = response.paymentMethod;
                if(getCordova()){
                    getWechat().sendPaymentRequest(payInfo, 
                      async ()=>{ // success
                        await completeShoppingSnappay(accessToken, {isSuccess: true, orderCode, method, result: ''});
                        await setShopUserInfo();
                        this.setState({paymentDone: true, orderId:orderCode});
                      },
                      (error: string)=>{
                        completeShoppingSnappay(accessToken, {isSuccess: false, orderCode, method, result: error});
                        throw error;
                      });   
                }else{
                    const localTest = false;
                    if(localTest){
                        completeShoppingSnappay(accessToken, {isSuccess: true, orderCode, method, result: ''});
                        this.setState({paymentDone: true, orderId:orderCode});
                    }else{
                        completeShoppingSnappay(accessToken, {isSuccess: false, orderCode, method, result: t('不支持微信支付')});
                        throw t("不支持微信支付");
                    }
                }
            }
        })
    }
}
