import classnames from 'classnames';
import * as React from 'react';
import {Component, Fragment} from 'react';
import * as _ from 'lodash';

import './pagination-select.scss';
import { NoticeDialog } from '../../view/NoticeDialog';
import { PagedResponse } from '../../api/transaction';
import { Pagination } from '../../shopping/common/render-pagination';
import { ArrowDropDown, ArrowDropUp,  } from '@material-ui/icons';
import { Checkbox } from '@material-ui/core';
import { t } from '../../i18n';
import { UButton } from '../button/ubutton';

interface MyProps<T> {
    className?: string;
    page: number;
    pageSize: number;
    searchResult:PagedResponse<T>;
    currentSelect?: T;
    expanded: boolean;

    searchLabel: string;
    collapseLabel: string;

    loadPaginationData: (page:number, 
                        pageSize:number, 
                        onData:(searchResult:PagedResponse<T>)=>void
                        )=>void;

    renderItem: (item:T)=>JSX.Element;   
    isSame: (item1:T, item2:T)=>boolean;    
    
    select: (item:T)=>void;
    deselect: (item:T)=>void;
}

interface MyState<T> {
    page: number;
    pageSize: number;
    expanded: boolean;
    currentSelect?: T;

    searchResult:PagedResponse<T>;
}

/**
 * generic component to insert, delete and sort a list of items.
 */
export class PaginationSelect<T> extends Component< MyProps<T>, MyState<T> >{
    private errorDialog:NoticeDialog|undefined;

    constructor(props: MyProps<T>){
        super(props);
        this.state = {
            page:this.props.page, 
            pageSize: this.props.pageSize, 
            searchResult: this.props.searchResult, 
            expanded:this.props.expanded,
            currentSelect: this.props.currentSelect,
        }
    }

    render(){
        return (
            <div className={classnames('pagination-select', this.props.className, {expanded:this.state.expanded})}>
                {this.renderSelected()}
                {this.state.expanded?
                    <Pagination data={this.state.searchResult} page={this.state.page} pageSize={this.state.pageSize} 
                                loadData={this.loadPaginationData}
                    >
                        {this.renderList()}
                    </Pagination>
                    :null
                }
            </div>
        )
    }

    renderSelected(){
        return <div className='selected'>
                    <div className='item'>
                        {this.state.currentSelect? this.props.renderItem(this.state.currentSelect):t('还未选择')}
                    </div>
                    {this.state.expanded? 
                        <UButton variant='outlined' color='primary' size='small' className='expand-label' onClick={()=>{ this.setState({expanded: false})}}>{this.props.collapseLabel}</UButton>:
                        <UButton variant='outlined' color='primary' size='small' className='expand-label' onClick={()=>{ this.setState({expanded: true})}}>{this.props.searchLabel}</UButton>
                    }
               </div>
    }

    renderList(){
        return this.state.searchResult.content.map(x=>{
            const checked = this.state.currentSelect && this.props.isSame(x, this.state.currentSelect);
            return <div className='pagination-item'>
                    <Checkbox checked={checked}
                              onClick={()=>{
                                    if(!checked){
                                        this.setState({currentSelect: x, expanded: false}, ()=>{
                                            this.props.select(x);
                                        })
                                    }else{
                                        this.setState({currentSelect: undefined}, ()=>{
                                            this.props.deselect(x);
                                        })
                                    }
                              }}
                    />
                    <div className='item'>
                    {this.props.renderItem(x)}
                    </div>
                   </div>
        })
    }

    loadPaginationData = (page: number, pageSize: number) => {
        this.props.loadPaginationData(page, pageSize, 
            (searchResult:PagedResponse<T>)=>{
                this.setState({searchResult, page: searchResult.page, pageSize: searchResult.size});
            }
        )
    }

    componentDidMount(){

    }
}